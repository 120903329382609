import { DatePicker, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'shards-react';
import { leavesSettingAPI } from '../../apis/leavesSettingAPI';
import { LEAVE_STATUS } from '../../global/leave';
import { FORMAT, ROLE } from '../../helpers/const';
import { useLeave, useUser } from '../../hooks';

const initFilter = {
    search: '',
    type: '',
    from: null,
    to: null,
};

function FilterLeave({ tabSelected }) {
    const { RangePicker } = DatePicker;
    const { fetchLeaves, setFilterLeave } = useLeave();
    const [filter, setFilter] = useState(initFilter);
    const { Option } = Select;
    const { user, getMe } = useUser();
    const [type, setType] = useState();

    const onSearch = () => {
        fetchLeaves({
            ...filter,
            fromStart: new Date(filter.from).setHours(0),
            fromEnd: new Date(filter.from).setHours(23),
            toStart: new Date(filter.to).setHours(0),
            toEnd: new Date(filter.to).setHours(23),
            status: tabSelected === 0 ? LEAVE_STATUS.PENDING : LEAVE_STATUS.APPROVE + ',' + LEAVE_STATUS.REJECT,
        });
    };
    useEffect(() => {
        setFilterLeave(filter);
    }, [filter]);

    useEffect(() => {
        getType();
    }, [user]);

    const getType = async () => {
        if (!user) {
            getMe();
        }

        let id = '';
        if (user && user.company && user.company._id) {
            id = user.company._id;
        }
        if (user && user.employee && user.employee.company && user.employee.company._id) {
            id = user.employee.company._id;
        }

        if (id !== '') {
            const { data } = await leavesSettingAPI.getByCompany(id);
            setType(data);
        }
    };

    return (
        <div>
            <Row className="mt-1">
                <Col className="form-group" lg="3" md="3">
                    <div>
                        <Input
                            className="ct-input"
                            placeholder="Search by item name"
                            value={filter.search}
                            onChange={(e) => setFilter({ ...filter, search: e.target.value })}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    onSearch();
                                }
                            }}
                        />
                    </div>
                </Col>

                <Col className="form-group" md="3">
                    <div>
                        <Select
                            defaultValue=""
                            value={filter.type}
                            onChange={(e) => setFilter({ ...filter, type: e })}
                            style={{ width: '100%' }}
                        >
                            <Option value="">All</Option>
                            {type &&
                                type.map((item, index) => (
                                    <Option key={index} value={item._id}>
                                        {item.description}
                                    </Option>
                                ))}
                        </Select>
                    </div>
                </Col>

                <Col className="form-group" md="3">
                    <div>
                        <RangePicker
                            defaultValue={[filter.from, filter.to]}
                            onChange={(date) => {
                                setFilter({
                                    ...filter,
                                    from: date && date[0] ? date[0]._d : null,
                                    to: date && date[1] ? date[1]._d : null,
                                });
                            }}
                            format={FORMAT.DATE}
                        />
                    </div>
                </Col>
                <Col md="3" className="d-flex justify-content-end align-items-start">
                    <div>
                        <button type="submit" className="button__search" onClick={onSearch}>
                            Search
                        </button>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default FilterLeave;
