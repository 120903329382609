import axios from 'axios';

const path = 'auth/';

const register = async (payload) => {
    return await axios.post(path + 'register', payload);
};

const login = async (payload) => {
    return await axios.post(path + 'login', payload);
};

const loginFB = async (email) => {
    return await axios.get(path + 'login-fb/' + email);
};
const getUser = async () => {
    return await axios.post(path + 'me');
};

const resetPassword = async (email) => {
    return await axios.post(path + 'reset_request', { email });
};

const loginFacebook = async () => {
    return await axios.get(path + 'facebook');
};

const signInGoogle = async (email) => {
    return await axios.get(path + 'google/login/' + email);
};

export const defaultAPI = {
    register,
    login,
    getUser,
    resetPassword,
    signInGoogle,
    loginFacebook,
    loginFB,
};
