import React from 'react';
import { Redirect } from 'react-router-dom';

// Layout Types
import { DefaultLayout } from './layouts';

// Route Views
import Dashboard from './views/Dashboard';
import Helps from './views/Helps';
import ViewAttendance from './components/attendance/ViewAttendance';
import CompaniesDetail from './components/companies/CompaniesDetail';
import CreateEmployees from './components/employeer/CreateEmployees';
import Attendance from './views/Attendance';
import Companies from './views/Companies';
import Departments from './views/Departments';
import Employees from './views/Employees';
import ExpenseClaim from './views/ExpenseClaim';
import Leave from './views/Leave';
import Payroll from './views/Payroll';

import OnlyLogin from './layouts/OnlyLogin';
import Login from './views/Login/Login';
import MyInformation from './views/MyInformation';
import MyPayroll from './views/MyPayroll';
import PublicHoliday from './views/PublicHoliday';
import Register from './views/Register/Register';
import ResetPassword from './views/ResetPassword/ResetPassword';
import UserProfileLite from './views/UserProfileLite';
import Controls from './views/Controls';
// import NoticeTemplate from './views/NoticeTemplate';
import FileManagement from './views/FileManagement';
import NewPaySlip from './components/payroll/NewPaySlip';
import CompanyInfor from './views/CompanyInfor';
import Notice from './views/Notice';
import Certificate from './views/Certificate';
import Training from './views/Training';
import WorkDays from './views/WorkDays';
import JobPositionDetail from './components/employers/JobPositionDetail';
export default [
    {
        path: '/',
        exact: true,
        layout: DefaultLayout,
        component: () => <Redirect to="/dashboard" />,
    },
    //==================================== AUTH ====================================
    {
        path: '/login',
        layout: OnlyLogin,
        component: Login,
    },
    {
        path: '/passwordReset',
        layout: OnlyLogin,
        component: ResetPassword,
    },
    {
        path: '/register',
        layout: OnlyLogin,
        component: Register,
    },
    {
        path: '/dashboard',
        layout: DefaultLayout,
        component: Dashboard,
    },
    //==================================== COMPANY ====================================
    {
        path: '/create-company',
        layout: DefaultLayout,
        component: CompaniesDetail,
    },
    {
        path: '/edit-companies/:id',
        layout: DefaultLayout,
        component: CompaniesDetail,
    },
    {
        path: '/companies',
        layout: DefaultLayout,
        component: Companies,
    },
    //==================================== EMPLOYEE ====================================
    {
        path: '/create-employee',
        layout: DefaultLayout,
        component: CreateEmployees,
    },
    {
        path: '/edit-employee/:id',
        layout: DefaultLayout,
        component: CreateEmployees,
    },
    {
        path: '/view-employee/:id',
        layout: DefaultLayout,
        component: CreateEmployees,
    },
    {
        path: '/employees/:type',
        layout: DefaultLayout,
        component: Employees,
    },
    {
        path: '/my-information',
        layout: DefaultLayout,
        component: MyInformation,
    },

    //==================================== LEAVE ====================================
    {
        path: '/edit-leave-request/:id',
        layout: DefaultLayout,
        component: Leave,
    },
    {
        path: '/leave',
        layout: DefaultLayout,
        component: Leave,
    },
    {
        path: '/expense-claim',
        layout: DefaultLayout,
        component: ExpenseClaim,
    },
    {
        path: '/attendance-view/:id',
        layout: DefaultLayout,
        component: ViewAttendance,
    },
    {
        path: '/attendance',
        layout: DefaultLayout,
        component: Attendance,
    },

    {
        path: '/my-payroll',
        layout: DefaultLayout,
        component: MyPayroll,
    },
    {
        path: '/pay-slip/:id',
        layout: DefaultLayout,
        component: NewPaySlip,
    },
    // {
    //     path: '/voucher/:id',
    //     layout: DefaultLayout,
    //     component: PaymentVoucher,
    // },

    {
        path: '/payroll/:type',
        layout: DefaultLayout,
        component: Payroll,
    },

    {
        path: '/departments',
        layout: DefaultLayout,
        component: Departments,
    },

    //==================================== EMPLOYERS ====================================
    // {
    //     path: '/employers/infomation',
    //     layout: DefaultLayout,
    //     component: Infomation,
    // },
    // {
    //     path: '/employers/job-positions',
    //     layout: DefaultLayout,
    //     component: JobPossition,
    // },
    // {
    //     path: '/employers/add-job-position',
    //     layout: DefaultLayout,
    //     component: AddJobPosition,
    // },
    {
        path: '/job-position-setting/job-position-detail/:id',
        layout: DefaultLayout,
        component: JobPositionDetail,
    },
    // {
    //     path: '/employers/leave-settings',
    //     layout: DefaultLayout,
    //     component: LeaveSetting,
    // },
    // {
    //     path: '/employers/expense-approval',
    //     layout: DefaultLayout,
    //     component: ExpenApproval,
    // },
    // {
    //     path: '/employers/attendance-settings',
    //     layout: DefaultLayout,
    //     component: AttendanceSetting,
    // },
    // {
    //     path: '/employers/role-permissions',
    //     layout: DefaultLayout,
    //     component: RolePermisstion,
    // },
    // {
    //     path: '/employers/payroll-settings',
    //     layout: DefaultLayout,
    //     component: PayrollSetting,
    // },

    {
        path: '/employers/:type',
        layout: DefaultLayout,
        component: CompanyInfor,
    },
    //==================================== PUBLIC HOLIDAY ====================================
    {
        path: '/public-holiday',
        layout: DefaultLayout,
        component: PublicHoliday,
    },
    //==================================== HELPS ====================================
    {
        path: '/helps',
        layout: DefaultLayout,
        component: Helps,
    },
    //==================================== PROFILE ====================================
    {
        path: '/profile',
        layout: DefaultLayout,
        component: UserProfileLite,
    },
    //==================================== FILE MANAGEMENT ====================================
    {
        path: '/files-management',
        layout: DefaultLayout,
        component: FileManagement,
    },
    //==================================== CONTROLS ====================================
    {
        path: '/controls',
        layout: DefaultLayout,
        component: Controls,
    },

    //==================================== NOTICE TEMPLATE ====================================
    // {
    //     path: '/notice-template',
    //     layout: DefaultLayout,
    //     component: NoticeTemplate,
    // },

    //==================================== NOTICE ====================================
    {
        path: '/notice',
        layout: DefaultLayout,
        component: Notice,
    },

    //==================================== CERTIFICATE ====================================
    {
        path: '/certificate',
        layout: DefaultLayout,
        component: Certificate,
    },

    //==================================== TRAINING ====================================
    {
        path: '/training',
        layout: DefaultLayout,
        component: Training,
    },

    //==================================== TRAINING ====================================
    {
        path: '/workdays',
        layout: DefaultLayout,
        component: WorkDays,
    },
];
