import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useUser } from '../../../hooks';
import { FormGroup, FormInput, Col, Row } from 'shards-react';
import { cpfAPI } from '../../../apis/payroll-settings/cpfAPI';

const initValue = [
    {
        from: 0,
        to: 55,
        employer: 17,
        employee: 20,
    },
    {
        from: 56,
        to: 60,
        employer: 14.5,
        employee: 15,
    },
    {
        from: 61,
        to: 65,
        employer: 11,
        employee: 9.5,
    },
    {
        from: 66,
        to: 70,
        employer: 8.5,
        employee: 7,
    },
    {
        from: 71,
        to: 200,
        employer: 7.5,
        employee: 5,
    },
];

function CPF() {
    const { user, getMe } = useUser();
    const [dataCPF, setDataCPF] = useState(initValue);

    useEffect(() => {
        getData();
    }, [user]);

    const getData = async () => {
        if (!user) {
            getMe();
        }
        // if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
        //     const { data } = await cpfAPI.getByAuth();
        //     setDataCPF(data.cpf ? data.cpf : initValue);
        // }
        // if (user && user.role && user.role === ROLE.COMPANYSTAFF && user.employee && user.employee.company._id) {
        //     const { data } = await cpfAPI.getByCompany(user.employee.company._id);
        //     setDataCPF(data.cpf ? data.cpf : initValue);
        // }
    };

    const handleChange = async (from, to, title, value) => {
        setDataCPF((prev) => {
            return prev.map((item) => {
                if (item.from === from && item.to === to) {
                    return { ...item, [title]: +value };
                }
                return item;
            });
        });
    };

    const handleSubmit = async () => {
        let check = false;

        for await (const item of dataCPF) {
            if (+item.employer < 0 || +item.employer > 100) {
                check = true;
                toast.error(
                    `Please enter the employer's CPF number for ages ${item.from} to ${item.to}, greater than 0 and less than 100`,
                );
                break;
            }
            if (+item.employee < 0 || +item.employee > 100) {
                check = true;
                toast.error(
                    `Please enter the employee's CPF number for ages ${item.from} to ${item.to}, greater than 0 and less than 100`,
                );
                break;
            }
        }

        if (check === false) {
            try {
                await cpfAPI.updateByAuth({ cpf: dataCPF });
                toast.success('Update successful');
            } catch (error) {
                const message = error.response.data.message;
                if (message && typeof message === 'string') {
                    toast.error(<p className="text-capitalize mt-3">{message}</p>);
                } else if (message && typeof message === 'object') {
                    toast.error(<p className="text-capitalize mt-3">{message[0]}</p>);
                }
            }
        }
    };

    return (
        <div className="padding_100">
            <Row>
                <Col lg="12">
                    <div className="d-flex justify-content-between align-items-center mb-1 mt-4">
                        <h5 className="title-font">{'For age < 55'}</h5>
                    </div>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            Employer CPF (%)
                        </span>
                        <FormInput
                            id="1"
                            type="number"
                            min="0"
                            max="100"
                            value={dataCPF[0].employer}
                            onChange={(e) => {
                                if (e.target.value > 100) {
                                    toast.error('Please enter a number greater than 0 and less than 100');
                                }
                                handleChange(0, 55, 'employer', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            Employee CPF (%)
                        </span>
                        <FormInput
                            id="2"
                            type="number"
                            min="0"
                            max="100"
                            value={dataCPF[0].employee}
                            onChange={(e) => {
                                if (e.target.value > 100) {
                                    toast.error('Please enter a number greater than 0 and less than 100');
                                }
                                handleChange(0, 55, 'employee', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg="12" md="6" sm="12">
                    <div className="d-flex justify-content-between align-items-center mb-1 mt-4">
                        <h5 className="title-font">{'For age above 55 to 60'}</h5>
                    </div>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            Employer CPF (%)
                        </span>
                        <FormInput
                            id="3"
                            type="number"
                            min="0"
                            max="100"
                            value={dataCPF[1].employer}
                            onChange={(e) => {
                                if (e.target.value > 100) {
                                    toast.error('Please enter a number greater than 0 and less than 100');
                                }
                                handleChange(56, 60, 'employer', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            Employee CPF (%)
                        </span>
                        <FormInput
                            id="4"
                            type="number"
                            min="0"
                            max="100"
                            value={dataCPF[1].employee}
                            onChange={(e) => {
                                if (e.target.value > 100) {
                                    toast.error('Please enter a number greater than 0 and less than 100');
                                }
                                handleChange(56, 60, 'employee', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg="12" md="6" sm="12">
                    <div className="d-flex justify-content-between align-items-center mb-1 mt-4">
                        <h5 className="title-font">{'For age above 60 to 65'}</h5>
                    </div>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            Employer CPF (%)
                        </span>
                        <FormInput
                            id="5"
                            type="number"
                            min="0"
                            max="100"
                            value={dataCPF[2].employer}
                            onChange={(e) => {
                                if (e.target.value > 100) {
                                    toast.error('Please enter a number greater than 0 and less than 100');
                                }
                                handleChange(61, 65, 'employer', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            Employee CPF (%)
                        </span>
                        <FormInput
                            id="6"
                            type="number"
                            min="0"
                            max="100"
                            value={dataCPF[2].employee}
                            onChange={(e) => {
                                if (e.target.value > 100) {
                                    toast.error('Please enter a number greater than 0 and less than 100');
                                }
                                handleChange(61, 65, 'employee', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg="12" md="6" sm="12">
                    <div className="d-flex justify-content-between align-items-center mb-1 mt-4">
                        <h5 className="title-font">{'For age above 65 to 70'}</h5>
                    </div>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            Employer CPF (%)
                        </span>
                        <FormInput
                            id="7"
                            type="number"
                            min="0"
                            max="100"
                            value={dataCPF[3].employer}
                            onChange={(e) => {
                                if (e.target.value > 100) {
                                    toast.error('Please enter a number greater than 0 and less than 100');
                                }
                                handleChange(66, 70, 'employer', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            Employee CPF (%)
                        </span>
                        <FormInput
                            id="8"
                            type="number"
                            min="0"
                            max="100"
                            value={dataCPF[3].employee}
                            onChange={(e) => {
                                if (e.target.value > 100) {
                                    toast.error('Please enter a number greater than 0 and less than 100');
                                }
                                handleChange(66, 70, 'employee', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg="12" md="6" sm="12">
                    <div className="d-flex justify-content-between align-items-center mb-1 mt-4">
                        <h5 className="title-font">{'Above 70'}</h5>
                    </div>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            Employer CPF (%)
                        </span>
                        <FormInput
                            id="9"
                            type="number"
                            min="0"
                            max="100"
                            value={dataCPF[4].employer}
                            onChange={(e) => {
                                if (e.target.value > 100) {
                                    toast.error('Please enter a number greater than 0 and less than 100');
                                }
                                handleChange(71, 200, 'employer', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            Employee CPF (%)
                        </span>
                        <FormInput
                            id="10"
                            type="number"
                            min="0"
                            max="100"
                            value={dataCPF[4].employee}
                            onChange={(e) => {
                                if (e.target.value > 100) {
                                    toast.error('Please enter a number greater than 0 and less than 100');
                                }
                                handleChange(71, 200, 'employee', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            {/* {user && user.role && user.role === ROLE.COMPANY && (
                <div className="mt-2">
                    <button onClick={handleSubmit} className="ct_btn px-4 next none-border btn-middle" id="save">
                        <span>Save</span>
                    </button>
                </div>
            )} */}
        </div>
    );
}

export default CPF;
