import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ROLE } from '../../../helpers/const';
import { useUser } from '../../../hooks';
import { FormGroup, FormInput, Col, Row } from 'shards-react';
import { ecfAPI } from '../../../apis/payroll-settings/ecfAPI';

const initValue = [
    {
        from: 0,
        to: 1000,
        percent: 2,
    },
    {
        from: 1001,
        to: 1500,
        percent: 4,
    },
    {
        from: 1501,
        to: 2500,
        percent: 6,
    },
    {
        from: 2501,
        to: 4000,
        percent: 9,
    },
    {
        from: 4001,
        to: 7000,
        percent: 12,
    },
    {
        from: 7001,
        to: 10000,
        percent: 16,
    },
    {
        from: 10001,
        to: Math.pow(10, 20),
        percent: 20,
    },
];

function ECF() {
    const { user, getMe } = useUser();
    const [dataECF, setDataECF] = useState(initValue);
    const [data, setData] = useState({});

    useEffect(() => {
        getData();
    }, [user]);

    const getData = async () => {
        if (!user) {
            getMe();
        }
        if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
            const { data } = await ecfAPI.getByCompany(user.company._id);
            setDataECF(data.ecf ? data.ecf : initValue);
            setData(data);
        }
        if (user && user.role && user.role === ROLE.COMPANYSTAFF && user.employee && user.employee.company._id) {
            const { data } = await ecfAPI.getByCompany(user.employee.company._id);
            setDataECF(data.ecf ? data.ecf : initValue);
            setData(data);
        }
    };

    const handleChange = async (from, to, title, value) => {
        setDataECF((prev) => {
            return prev.map((item) => {
                if (item.from === from && item.to === to) {
                    return { ...item, [title]: +value };
                }
                return item;
            });
        });
    };

    const handleSubmit = async () => {
        try {
            await ecfAPI.updateByCompany(data._id.toString(), { ecf: dataECF });
            toast.success('Update successful');
        } catch (error) {
            const message = error.response.data.message;
            if (message && typeof message === 'string') {
                toast.error(<p className="text-capitalize mt-3">{message}</p>);
            } else if (message && typeof message === 'object') {
                toast.error(<p className="text-capitalize mt-3">{message[0]}</p>);
            }
        }
    };
    return (
        <div className="padding_100">
            <Row>
                <Col lg="12">
                    <div className="d-flex justify-content-between align-items-center mb-1 mt-4">
                        <h5 className="title-font">{'Eurasian Community Fund (ECF) (paid by staff)'}</h5>
                    </div>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            Gross Wages/Month
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            Monthly contribution ($)
                        </span>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            $1000 and below
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="0"
                            type="number"
                            min="0"
                            max="100"
                            value={dataECF[0].percent}
                            onChange={(e) => {
                                handleChange(0, 1000, 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            $1000 to $1500
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="1"
                            type="number"
                            min="0"
                            max="100"
                            value={dataECF[1].percent}
                            onChange={(e) => {
                                handleChange(1001, 1500, 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            $1500 to $2500
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="2"
                            type="number"
                            min="0"
                            max="100"
                            value={dataECF[2].percent}
                            onChange={(e) => {
                                handleChange(1501, 2500, 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            $2500 to $4000
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="3"
                            type="number"
                            min="0"
                            max="100"
                            value={dataECF[3].percent}
                            onChange={(e) => {
                                handleChange(2501, 4000, 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            $4000 to $7000
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="4"
                            type="number"
                            min="0"
                            max="100"
                            value={dataECF[4].percent}
                            onChange={(e) => {
                                handleChange(4001, 7000, 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            $7000 to $10000
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="5"
                            type="number"
                            min="0"
                            max="100"
                            value={dataECF[5].percent}
                            onChange={(e) => {
                                handleChange(7001, 10000, 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            Above $10000
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="6"
                            type="number"
                            min="0"
                            max="100"
                            value={dataECF[6].percent}
                            onChange={(e) => {
                                handleChange(10001, Math.pow(10, 20), 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            {user && user.role && user.role === ROLE.COMPANY && (
                <div className="mt-2">
                    <button onClick={handleSubmit} className="ct_btn px-4 next none-border btn-middle" id="save">
                        <span>Save</span>
                    </button>
                </div>
            )}
        </div>
    );
}

export default ECF;
