import { LEAVE_STATUS } from './leaveEnum';

export const LEAVE = {
    employee: '',
    //type: LEAVE_TYPE[0].value,
    from: new Date(new Date().setHours(6, 0, 0, 0)),
    to: new Date(new Date().setHours(22, 0, 0, 0)),
    reason: '',
    status: LEAVE_STATUS.PENDING,
    reasonApprove: '',
    type: '',
    fromSession: undefined,
    toSession: undefined,
};
