import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'shards-react';
import { ROLE } from '../helpers/const';
import { useUser } from '../hooks';
import PageTitle from './../components/common/PageTitle';
import PayrollList from './../components/payroll/PayrollList';

import { useHistory, useLocation } from 'react-router-dom';

function MyPayroll() {
    const { user, getMe } = useUser();
    const history = useHistory();
    const location = useLocation();
    const [tab, setTab] = useState(0);

    useEffect(() => {
        setTab(0);
    }, [location]);

    useEffect(() => {
        if (!user) {
            getMe();
        }
        user &&
            user.role &&
            user.role !== ROLE.COMPANY &&
            user.role !== ROLE.COMPANYSTAFF &&
            user.role !== ROLE.USER &&
            history.push('/login');
    }, [user]);

    useEffect(() => {
        (async () => {
            if (Object.keys(user).length === 0) await getMe();
        })();
    }, [user]);

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <Col lg="6">
                    <PageTitle
                        title="Payroll"
                        subtitle={<span className="route-main">Payroll</span>}
                        className="text-sm-left mb-3 "
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card>
                        <CardHeader className="border-bottom">
                            <span className="ct-title">Payroll List</span>
                        </CardHeader>
                        <CardBody>
                            <PayrollList tab={tab} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default MyPayroll;
