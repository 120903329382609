import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody } from 'shards-react';
import PageTitle from './../components/common/PageTitle';
import PayrollList from './../components/payroll/PayrollList';
import PayrollForm from '../components/payroll/PayrollForm';
import { useUser } from '../hooks';
import { ROLE } from '../helpers/const';

import { AiFillPlusCircle } from 'react-icons/ai';
import { useHistory, useLocation } from 'react-router-dom';
import { payRollAPI } from '../apis/payRollAPI';
import { usePayroll } from '../hooks/usePayRoll';
import Infomation from '../components/employers/Infomation';
import JobPossition from '../components/employers/JobPosition';
import AddJobPosition from '../components/employers/AddJobPosition';
import LeaveSetting from '../components/employers/LeaveSetting';
import ExpenApproval from '../components/employers/ExpenApproval';
import AttendanceSetting from '../components/employers/AttendanceSetting';
import RolePermisstion from '../components/employers/RolePermisstion';
import PayrollSetting from '../components/employers/PayrollSetting';
import JobPositionDetail from '../components/employers/JobPositionDetail';
import PermanentResidentsSetting from '../components/employers/PermanentResidentsSetting';

function CompanyInfor() {
    const { user, getMe } = useUser();
    const history = useHistory();
    const location = useLocation();
    const [pathname, setPathName] = useState('infomation');
    const [component, setComponent] = useState(<Infomation />);

    useEffect(() => {
        const arr = location.pathname.split('/');
        setPathName(arr[arr.length - 1 >= 0 ? arr.length - 1 : 0]);
    }, [location]);

    useEffect(() => {
        if (!user) {
            getMe();
        }
        user &&
            user.role &&
            user.role !== ROLE.COMPANY &&
            user.role !== ROLE.COMPANYSTAFF &&
            user.role !== ROLE.USER &&
            history.push('/login');
    }, [user]);

    useEffect(() => {
        // pathname === 'infomation'
        //     ? setComponent(<Infomation />)
        //     : pathname === 'job-positions'
        //     ? setComponent(<JobPossition />)
        //     : pathname === 'add-job-position'
        //     ? setComponent(<AddJobPosition />)
        //     : pathname === 'leave-settings'
        //     ? setComponent(<LeaveSetting />)
        //     : pathname === 'expense-approval'
        //     ? setComponent(<ExpenApproval />)
        //     : pathname === 'attendance-settings'
        //     ? setComponent(<AttendanceSetting />)
        //     : pathname === 'role-permissions'
        //     ? setComponent(<RolePermisstion />)
        //     : pathname === 'payroll-settings'
        //     ? setComponent(<PayrollSetting />)
        //     : pathname === 'permanent-residents-settings'
        //     ? setComponent(<PermanentResidentsSetting />)
        //     : setComponent(<JobPositionDetail />);
        switch (pathname) {
            case 'infomation':
                setComponent(<Infomation />);
                break;
            case 'job-positions':
                setComponent(<JobPossition />);
                break;
            case 'add-job-position':
                setComponent(<AddJobPosition />);
                break;
            case 'leave-settings':
                setComponent(<LeaveSetting />);
                break;
            case 'expense-approval':
                setComponent(<ExpenApproval />);
                break;
            case 'attendance-settings':
                setComponent(<AttendanceSetting />);
                break;
            case 'role-permissions':
                setComponent(<RolePermisstion />);
                break;
            case 'payroll-settings':
                setComponent(<PayrollSetting />);
                break;
            case 'permanent-residents-settings':
                setComponent(<PermanentResidentsSetting />);
                break;
            case 'job-position-detail':
                setComponent();
                break;
            default:
                setComponent();
            // code block
        }
    }, [pathname]);

    return <>{component}</>;
}

export default CompanyInfor;
