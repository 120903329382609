import React, { useEffect, useState } from 'react';

import { Switch } from 'antd';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, FormInput, Row } from 'shards-react';
import PageTitle from '../common/PageTitle';

import { ROLE } from '../../helpers/const';
import { useLoading, useUser } from '../../hooks';

import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { jobPositionAPI } from '../../apis/jobPositionAPI';

function JobPositionDetail() {
    let { id } = useParams();
    const { user, getMe } = useUser();
    const [payload, setPayload] = useState();
    const history = useHistory();
    const { setLoading } = useLoading();

    useEffect(() => {
        if (!user) {
            getMe();
        }
        user &&
            user.role &&
            user.role !== ROLE.COMPANY &&
            user.role !== ROLE.COMPANYSTAFF &&
            user.role !== ROLE.USER &&
            history.push('/login');
    }, [user]);

    useEffect(() => {
        getJobPosition();
    }, [user]);

    const getJobPosition = async () => {
        if (!user) {
            getMe();
        }
        if (id) {
            const { data } = await jobPositionAPI.getOne(id);
            setPayload(data);
        }
    };

    const handleClick = async () => {
        if (!user) {
            getMe();
        }
        if (user && user.role && user.role === ROLE.COMPANY && payload) {
            setLoading(true);
            if (payload.code === '') {
                toast.error('Job position code should not be empty!');
            } else if (payload.jobPositionName === '') {
                toast.error('Job position title not be empty!');
            }
            await jobPositionAPI.update(id, payload);
            setLoading(false);
            toast.success('Update success!');
            history.push(`/employers/job-positions`);
        }
    };

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle
                    title={payload && payload.jobPositionName && payload.jobPositionName}
                    subtitle="Employers/ Job Positions/"
                    className="text-sm-left mb-3"
                />
            </Row>
            <Row className="mt-2 w-100" lg="12">
                <Card className="h-100 w-100">
                    <CardBody className="col-xl-12 col-lg-12">
                        <Row className="d-flex justify-content-center align-items-center">
                            <Col lg="8" md="12">
                                <Form>
                                    <FormGroup className="mt-4">
                                        <label htmlFor="feCompanieName">Codeaaaaa</label>
                                        <FormInput
                                            id="feCompanieName"
                                            type="text"
                                            placeholder=""
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `Please Input company information settings`,
                                                },
                                            ]}
                                            value={payload && payload.code && payload.code}
                                            onChange={(e) => {
                                                if (
                                                    user &&
                                                    user.role &&
                                                    (user.role === ROLE.USER || user.role === ROLE.COMPANYSTAFF)
                                                ) {
                                                    toast.error('You are not authorized to update');
                                                    return;
                                                }
                                                if (user && user.role && user.role === ROLE.COMPANY) {
                                                    setPayload({ ...payload, code: e.target.value });
                                                }
                                            }}
                                        />
                                    </FormGroup>

                                    <FormGroup className="mt-4">
                                        <label htmlFor="feInputAddress">Title</label>
                                        <FormInput
                                            id="feInputAddress"
                                            placeholder=""
                                            value={payload && payload.jobPositionName && payload.jobPositionName}
                                            onChange={(e) => {
                                                if (
                                                    user &&
                                                    user.role &&
                                                    (user.role === ROLE.USER || user.role === ROLE.COMPANYSTAFF)
                                                ) {
                                                    toast.error('You are not authorized to update');
                                                    return;
                                                }
                                                if (user && user.role && user.role === ROLE.COMPANY) {
                                                    setPayload({ ...payload, jobPositionName: e.target.value });
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                    <Col lg="12" md="6" sm="12">
                                        <div className="d-flex justify-content-between align-items-center my-4">
                                            <h6>Active</h6>
                                            <Switch
                                                // value={payload && payload.active && payload.active}
                                                checked={
                                                    payload && payload.active && payload.active === true ? true : false
                                                }
                                                onChange={(e) => {
                                                    if (
                                                        user &&
                                                        user.role &&
                                                        (user.role === ROLE.USER || user.role === ROLE.COMPANYSTAFF)
                                                    ) {
                                                        toast.error('You are not authorized to update');
                                                        return;
                                                    }
                                                    if (user && user.role && user.role === ROLE.COMPANY) {
                                                        setPayload({ ...payload, active: e });
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <FormGroup className="mt-4">
                                        <label htmlFor="feInputUrl">Description</label>
                                        <FormInput
                                            id="feInputUrl"
                                            placeholder=""
                                            value={payload && payload.description && payload.description}
                                            onChange={(e) => {
                                                if (
                                                    user &&
                                                    user.role &&
                                                    (user.role === ROLE.USER || user.role === ROLE.COMPANYSTAFF)
                                                ) {
                                                    toast.error('You are not authorized to update');
                                                    return;
                                                }
                                                if (user && user.role && user.role === ROLE.COMPANY) {
                                                    setPayload({ ...payload, description: e.target.value });
                                                }
                                            }}
                                        />
                                    </FormGroup>

                                    {user && user.role && user.role === ROLE.COMPANY && (
                                        <Row className="d-flex btn-save-attendanceSetting justify-content-center align-items-center mt-5">
                                            <Button type="button" className=" btn-warning px-5" onClick={handleClick}>
                                                Save
                                            </Button>
                                        </Row>
                                    )}
                                </Form>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Row>
        </Container>
    );
}
export default JobPositionDetail;
