import axios from 'axios';

const path = 'sinda/';

const updateByCompany = async (id, payload) => {
    return await axios.patch(path + id, payload);
};

const getByCompany = async (id) => {
    return await axios.get(path + 'company/' + id);
};
export const sindaAPI = {
    updateByCompany,
    getByCompany,
};
