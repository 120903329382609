import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';

import { MdDeleteForever } from 'react-icons/md';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardHeader, Col, Container, Row } from 'shards-react';
import { fileManagementAPI } from '../apis/fileManagementAPI';
import StatisticsFile from '../components/files-management/StatisticsFile';
import TableFileManagement from '../components/files-management/TableFileManagement';
import { ROLE } from '../helpers/const';
import { getError } from '../helpers/getErrorServer';
import { useLoading, useUser } from '../hooks';
import PageTitle from './../components/common/PageTitle';

function FileManagement() {
    const { user, getMe } = useUser();
    const [fileList, setFileList] = useState([]);
    const [idCompany, setIdCompany] = useState();
    const [deleteList, setDeleteList] = useState([]);
    const [page, setPage] = useState(1);
    const history = useHistory();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const location = useLocation();
    const { setLoading } = useLoading();

    useEffect(() => {
        if (!user) {
            getMe();
        }
        user &&
            user.role &&
            user.role !== ROLE.COMPANY &&
            user.role !== ROLE.COMPANYSTAFF &&
            user.role !== ROLE.USER &&
            history.push('/login');

        if (user && user.company && user.company._id) {
            setIdCompany(user.company._id);
        } else if (user && user.employee && user.employee.company && user.employee.company._id) {
            setIdCompany(user.employee.company._id);
        }
    }, [user]);

    useEffect(() => {
        if (idCompany) {
            fetchData(idCompany);
        }
    }, [user, page, refresh, location]);

    const showModal = () => {
        if (deleteList.length > 0) {
            setIsModalOpen(true);
        } else {
            toast.error('Please select file');
        }
    };

    const handleOk = () => {
        handleDelete();
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleDelete = async () => {
        try {
            setLoading(true);
            for await (const item of deleteList) {
                await fileManagementAPI.softDelete(item);
            }
            setLoading(false);
            toast.success('Deleted successfully!');
            setDeleteList([]);
            setRefresh((prev) => prev + 1);
        } catch (error) {
            setLoading(false);
            toast.error(getError(error));
        }
    };

    const fetchData = async () => {
        try {
            if (idCompany && idCompany.length > 0) {
                const { data } = await fileManagementAPI.get({ company: idCompany, page });
                let rs = data;

                if (data.items.length < 1 && page > 1) {
                    setPage((prev) => prev - 1);
                    const { data: tmp } = await fileManagementAPI.get({ company: idCompany, page: page - 1 });
                    rs = tmp;
                }
                setFileList(rs);
            }
        } catch (error) {
            toast.error(getError(error));
        }
    };

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle
                    title="Files management"
                    subtitle={<span className="route-main">Files management</span>}
                    className="text-sm-left mb-3"
                />
            </Row>
            {/* <Row className="mt-2"> */}
            <StatisticsFile refresh={refresh} />
            <Card small className="h-100 mt-4 w-100">
                <CardHeader className="border-bottom">
                    <div>
                        <span className="ct-title">File List</span>
                    </div>
                </CardHeader>

                <div className="ct-box">
                    <div className="col-xl-12 col-lg-12">
                        <Row className="p-0" lg="12" sm="12" md="8" xl="12">
                            <Col xl="4" lg="12" md="12" sm="12" className="p-1"></Col>
                            <Col xl="2"></Col>
                            <Col xl="6" lg="12" md="12" sm="12">
                                <Row>
                                    <Col lg="3" md="3" sm="12" className="p-1"></Col>
                                    <Col lg="4" md="3" sm="12" className="p-1"></Col>
                                    <Col lg="5" md="6" sm="12" className="p-1">
                                        <button className="button_delete" onClick={() => showModal()}>
                                            <MdDeleteForever size={20} />
                                            <span className="text__red">Delete</span>
                                        </button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="p-0">
                                <TableFileManagement
                                    tableData={fileList}
                                    callback={fetchData}
                                    deleteList={deleteList}
                                    setDeleteList={setDeleteList}
                                    setPage={setPage}
                                    page={page}
                                    setReload={setRefresh}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </Card>
            {/* </Row> */}
            <Modal
                title={
                    <>
                        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.4167 0.833344H2.58333C1.56583 0.833344 0.75 1.65834 0.75 2.66668V15.5C0.75 16.5083 1.56583 17.3333 2.58333 17.3333H6.25L9 20.0833L11.75 17.3333H15.4167C16.425 17.3333 17.25 16.5083 17.25 15.5V2.66668C17.25 1.65834 16.425 0.833344 15.4167 0.833344ZM9.91667 15.5H8.08333V13.6667H9.91667V15.5ZM11.8142 8.39584L10.9892 9.23918C10.3292 9.90834 9.91667 10.4583 9.91667 11.8333H8.08333V11.375C8.08333 10.3667 8.49583 9.45001 9.15583 8.78084L10.2925 7.62584C10.6317 7.29584 10.8333 6.83751 10.8333 6.33334C10.8333 5.32501 10.0083 4.50001 9 4.50001C7.99167 4.50001 7.16667 5.32501 7.16667 6.33334H5.33333C5.33333 4.30751 6.97417 2.66668 9 2.66668C11.0258 2.66668 12.6667 4.30751 12.6667 6.33334C12.6667 7.14001 12.3367 7.87334 11.8142 8.39584Z"
                                fill="#FCBB0A"
                            />
                        </svg>

                        <p className="title-modal-custom">Are you sure delete this file?</p>
                    </>
                }
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p>By delete file</p>
            </Modal>
        </Container>
    );
}

export default FileManagement;
