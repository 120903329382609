export const EXPENSE_CLAIM_FILTER = {
    search: '',
    paidBy: '',
    status: '',
    from: '',
    to: '',
    page: 1,
    limit: 10,
    company: '',
};
