import React from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'shards-react';
import { ROLE } from '../helpers/const';
import { useUser } from '../hooks';
import PageTitle from './../components/common/PageTitle';
import ListCompanies from './../components/companies/ListCompanies';

function Companies() {
    const { user } = useUser();
    const history = useHistory();
    if (user.role !== ROLE.ADMIN && user.role !== ROLE.SYSTEMSTAFF) {
        history.push('/login');
    }

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle
                    title="Companies"
                    subtitle={<span className="route-main">Companies</span>}
                    className="text-sm-left mb-3"
                />
            </Row>
            <Row className="mt-2">
                <Col className="mb-4" lg="12" md="12" sm="12">
                    <ListCompanies />
                </Col>
            </Row>
        </Container>
    );
}

export default Companies;
