import React from 'react';

import { Nav } from 'shards-react';

import SidebarNavItem from './SidebarNavItem';
import { Store } from '../../../flux';
import { useUser } from '../../../hooks';
import { ROLE } from '../../../helpers/const';

const SidebarNavItems = () => {
    const { navItems: items } = {
        navItems: Store.getSidebarItems(),
    };
    const { user } = useUser();

    return (
        <div className="nav-wrapper">
            <Nav className="nav--no-borders flex-column">
                {items.map((item, idx) => {
                    const role = localStorage.getItem('role');
                    if (item.role) {
                        const arr = item.role.split(',');
                        if (arr.includes(role)) {
                            if (
                                item.title === 'Employees' &&
                                user &&
                                user.role &&
                                user.role === ROLE.USER &&
                                user.position &&
                                user.position.employee &&
                                user.position.employee.length < 1 &&
                                user.employee &&
                                (!user.employee.controls ||
                                    (user.employee.controls &&
                                        user.employee.controls.employee &&
                                        user.employee.controls.employee.length < 1))
                            ) {
                                return null;
                            }

                            if (
                                item.title === 'Payroll' &&
                                user &&
                                user.role &&
                                user.role === ROLE.USER &&
                                user.position &&
                                user.position.payroll &&
                                user.position.payroll.length < 1 &&
                                user.employee &&
                                (!user.employee.controls ||
                                    (user.employee.controls &&
                                        user.employee.controls.payroll &&
                                        user.employee.controls.payroll < 1))
                            ) {
                                return null;
                            }

                            return <SidebarNavItem key={idx} item={item} />;
                        } else {
                            return null;
                        }
                    }
                    return <SidebarNavItem key={idx} item={item} />;
                })}
            </Nav>
        </div>
    );
};

export default SidebarNavItems;
