import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { employeesAPI } from '../apis/employeesAPI';
import { Container, Row, Col, Card } from 'shards-react';
import { toast } from 'react-toastify';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import PersonIcon from '@mui/icons-material/Person';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import { Alert, Input, Radio, DatePicker, Select, Switch } from 'antd';

import PageTitle from '../components/common/PageTitle';
import moment from 'moment';

import { BsQuestionSquareFill } from 'react-icons/bs';

import {
    EMPLOYEES_TYPE,
    EMPLOYEES_TYPE_VALUE,
    EMPLOYEE_BLOOD_TYPE,
    EMPLOYEE_HAND_LEG,
    EMPLOYEE_HEALTH,
    EMPLOYEE_HEARING,
    EMPLOYEE_PAYMENT_METHOD,
    EMPLOYEE_PAYMENT_PAY,
    EMPLOYEE_PRIVACY_LEVEL,
    ROLE,
} from '../helpers/const';
import { useUser } from '../hooks';
import { getError } from '../helpers/getErrorServer';
import { countryAPI } from '../apis/countryAPI';
import { jobPositionAPI } from '../apis/jobPositionAPI';
import { departmentAPI } from '../apis/departmentAPI';

import { countriesAPI } from '../apis/countriesAPI';
import { uploadImageAPI } from '../apis/uploadImageAPI';
import { branchAPI } from '../apis/brachAPI';
import { levelAPI } from '../apis/levelAPI';
import { bankAPI } from '../apis/bankAPI';
import { ethnicityAPI } from '../apis/ethnicityAPI';
import { religionAPI } from '../apis/religionAPI';
import { leaveWordFlowAPI } from '../apis/leaveWordFlowAPI';
import { workDayAPI } from '../apis/workDayAPI';
import { jobHolidayAPI } from '../apis/jobHolidayAPI';
import ResetPasswordModal from '../components/layout/Modal/ResetPassword';
import { currencyAPI } from '../apis/currencyAPI';
let imageProfile = '/img/user.png';

const initSalary = {
    effectiveDate: null,
    basicSalary: '',
    currency: '',
    reviewDate: '',
    earning: '',
    deduction: '',
    bonus: '',
    statutoryContribution: '',
};

const initPayment = {
    bank: '',
    bankAccount: '',
    payPeriod: '',
    method: '',
};

const initEmploymentTerm = {
    effectiveDate: null,
    jobType: 'permanent',
    description: 'confirm',
    leaveWordflow: 'default',
    workDay: 'default',
    holiday: 'default',
    termStart: null,
    termEnd: null,
};

const initPlacement = {
    effectiveDate: '',
    jobPosition: '',
    lineManager: '',
    branch: '',
    level: '',
};

const initEmployee = {
    birthday: '',
    email: '',
    employeesId: '',
    firstName: '',
    gender: 'male',
    lastName: '',
    middleName: '',
    nationId: '',
    nationality: '',
    passport: '',
    companyID: '',
    type: '',
    family: {},
    salary: {},
    insurance: [],
    department: '',
    ethnicity: '',
    religion: '',
    remark: '',

    dateJoin: null,
    endProbation: null,
    payment: {},
    employmentTerm: {},
    placement: {},
    phone: {},
    address: {},
    emergency: {},
};

const initPhone = {
    email: '',
    blog: '',
    officePhone: '',
    mobilePhone: '',
    housePhone: '',
};

const initAddress = {
    address1: '',
    address2: '',
    city: '',
    postCode: '',
    state: '',
    country: '',
};

const initEmergency = {
    firstName: '',
    middleName: '',
    lastName: '',
    relationship: '',
    mobilePhone: '',
    housePhone: '',
    officePhone: '',
};

const initFamily = {
    maritalStatus: 'single',
    spouseWorking: false,
    firstName: '',
    middleName: '',
    lastName: '',
    birthday: null,
    nationality: '',
    nationId: '',
    passport: '',
    ethnicity: '',
    religion: '',
    children: '',
};

const initHealth = {
    physicalHeight: '',
    physicalWeight: '',
    physicalBloodType: '',
    visionLeft: '',
    visonRight: '',
    hearingLeft: '',
    hearingRight: '',
    handLeft: '',
    handRight: '',
    legLeft: '',
    legRight: '',
};

const initDerectory = {
    employeeRole: 'employee',
    email: 'employee',
    blog: 'employee',
    officePhone: 'employee',
    housePhone: 'employee',
    mobilePhone: 'employee',
    address: 'employee',
    emergency: 'employee',
    birthday: 'employee',
    familyBirthday: 'employee',
    anniversary: 'employee',
};

const initAddJobPosition = {
    code: '',
    jobPositionName: '',
};

function MyInformation() {
    const [employee, setEmployee] = useState(initEmployee);
    const [placement, setPlacement] = useState(initPlacement);
    const [employmentTerm, setEmploymentTerm] = useState(initEmploymentTerm);
    const [payment, setPayment] = useState(initPayment);
    const [phone, setPhone] = useState(initPhone);
    const [address, setAddress] = useState(initAddress);
    const [emergency, setEmergency] = useState(initEmergency);
    const [familys, setFamilys] = useState(initFamily);
    const [health, setHealth] = useState(initHealth);
    const [derectory, setDerectory] = useState(initDerectory);
    const [salary, setSalary] = useState(initSalary);
    const [isModalNationality, setIsModalNationality] = useState(false);
    const [isModalPosition, setIsModalPosition] = useState(false);
    const [countryName, setCountryName] = useState('');
    const [positionName, setPositionName] = useState('');
    const [countryNames, setCountryNames] = useState([]);
    const [companyId, setCompanyId] = useState('');

    const [jobPositions, setJobPositions] = useState([]);
    const [reload, setReload] = useState(0);
    const [valueTabs, setValueTabs] = useState(0);
    const [departments, setDepartments] = useState([]);
    const [countries, setCountries] = useState([]);
    const [timeClock, setTimeClock] = useState(false);
    const [addJobPosition, setAddJobPosition] = useState(initAddJobPosition);
    const [fileImage, setFileImage] = useState();
    const [imagePreviewUrl, setImagePreviewUrl] = useState();
    const [newIdEmployee, setNewIdEmployee] = useState();
    const [branches, setBranches] = useState([]);
    const [levelList, setLevelList] = useState([]);
    const [bankList, setBankList] = useState([]);
    const [ethnicityList, setEthnicityList] = useState([]);
    const [religionList, setReligionList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [leaveWordflowList, setAddLeaveWordFlowList] = useState([]);
    const [workDayList, setWorkDayList] = useState([]);
    const [jobHolidayList, setJobHolidayList] = useState([]);
    const [currency, setCurrency] = useState([]);

    const [showWP, setShowWP] = useState(true);
    const [showDateNRIC, setShowDateNRIC] = useState(true);
    const [showExpiryDateForWP_SP, setShowExpiryDateForWP_SP] = useState(false);

    const location = useLocation();
    const history = useHistory();
    // const { id } = useParams();
    const { user, getMe } = useUser();
    const { Option } = Select;
    const { TextArea } = Input;
    const url = useLocation();

    const dateFormat = 'YYYY-MM-DD';
    const [id, setId] = useState();

    const [titleNation, setTitleNation] = useState('FIN NO');

    useEffect(() => {
        if (employee.type === EMPLOYEES_TYPE_VALUE.LOCALSTAFF) {
            setTitleNation('NRIC');
            setShowWP(false);
            setShowDateNRIC(false);
            setShowExpiryDateForWP_SP(false);
        } else if (employee.type === EMPLOYEES_TYPE_VALUE.WORKPERMIT) {
            setTitleNation('FIN NO');
            setShowWP(true);
            setShowDateNRIC(false);
            setShowExpiryDateForWP_SP(true);
        } else {
            setTitleNation('FIN NO');
            setShowWP(true);
            setShowDateNRIC(true);
            setShowExpiryDateForWP_SP(false);
        }
    }, [employee.type]);

    useEffect(() => {
        if (!user) {
            getMe();
        }
        if (user && user.employee && user.employee._id) {
            setId(user.employee._id);
        }
    }, [user]);

    const handleChangeTabs = (event, newValue) => {
        setValueTabs(newValue);
    };

    const fetchEmployee = async (id) => {
        try {
            const { data } = await employeesAPI.getById(id);
            if (data) {
                setImagePreviewUrl(data[0].user.avatar);
                setEmployee(data[0]);
                setPlacement(data[0].placement);
                setPayment(data[0].payment);
                setPhone(data[0].phone);
                setAddress(data[0].address);
                setEmergency(data[0].emergency);
                setEmploymentTerm(data[0].employmentTerm);
                setFamilys(data[0].family);
                setHealth(data[0].health);
                setDerectory(data[0].derectory);
                setSalary(data[0].salary);
            }
        } catch (error) {
            console.log(error);
            toast.error(getError(error));
        }
    };

    useEffect(() => {}, [employmentTerm]);

    useEffect(() => {
        (async () => {
            const { data } = await currencyAPI.getCurrency();
            setCurrency(Object.keys(data));
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const { data } = await countriesAPI.getCountries();
            setCountries(data);
        })();
    }, []);

    useEffect(() => {}, []);

    useEffect(() => {
        if (id) {
            fetchEmployee(id);
        }
    }, [id]);

    useEffect(() => {
        (async () => {
            if (user && user.employee && user.employee.company) {
                getJobPosition(user.employee.company._id);
                getDepartment(user.employee.company._id);
                getBranch(user.employee.company._id);
                getLevelList(user.employee.company._id);
                getBankList(user.employee.company._id);
                getEthnicityList(user.employee.company._id);
                getReligionList(user.employee.company._id);
                getEmloyeeList(user.employee.company._id);
                getLeaveWordFlowList(user.employee.company._id);
                getWorkDayList(user.employee.company._id);
                getJobHolidayList(user.employee.company._id);
            }
            getCountry();
        })();
    }, [reload, companyId, user, location]);

    const getCountry = async () => {
        const { data } = await countryAPI.getAll();
        setCountryNames(data);
    };

    const getJobPosition = async (id) => {
        const { data } = await jobPositionAPI.getActive(id);
        setJobPositions(data);
    };

    const getDepartment = async (id) => {
        const { data } = await departmentAPI.getAllByCompany(id);
        setDepartments(data);
    };

    const getBranch = async (id) => {
        const { data } = await branchAPI.getByCompany(id);
        setBranches(data);
    };

    const getLevelList = async (id) => {
        const { data } = await levelAPI.getByCompany(id);
        setLevelList(data);
    };

    const getBankList = async (id) => {
        const { data } = await bankAPI.getByCompany(id);
        setBankList(data);
    };

    const getEthnicityList = async (id) => {
        const { data } = await ethnicityAPI.getByCompany(id);
        setEthnicityList(data);
    };

    const getReligionList = async (id) => {
        const { data } = await religionAPI.getByCompany(id);
        setReligionList(data);
    };

    const getEmloyeeList = async (id) => {
        const { data } = await employeesAPI.getAllCompany(id);
        setEmployeeList(data);
    };

    const getLeaveWordFlowList = async (id) => {
        const { data } = await leaveWordFlowAPI.getByCompany(id);
        setAddLeaveWordFlowList(data);
    };

    const getWorkDayList = async (id) => {
        const { data } = await workDayAPI.getByCompany(id);
        setWorkDayList(data);
    };

    const getJobHolidayList = async (id) => {
        const { data } = await jobHolidayAPI.getByCompany(id);
        setJobHolidayList(data);
    };

    return (
        <Container fluid className="main-content-container px-4">
            <ResetPasswordModal />
            <Row noGutters className="page-header py-4">
                <PageTitle
                    title={`My Profile`}
                    subtitle={
                        <>
                            <span className="route-extra">Employees / </span>

                            <span className="route-main">My Profile</span>
                        </>
                    }
                    className="text-sm-left mb-3"
                />
            </Row>
            <Card>
                <Row>
                    <Col>
                        <Tabs value={valueTabs} onChange={handleChangeTabs} aria-label="icon tabs example">
                            <Tab
                                className="slide slide-info"
                                label={valueTabs === 0 && <span>Quick Entry</span>}
                                icon={<FlashOnIcon />}
                                {...a11yProps(0)}
                                aria-label="Quick Entry"
                            />
                            <Tab
                                className="slide slide-info"
                                label={valueTabs === 1 && <span>Employee</span>}
                                icon={<PersonIcon />}
                                {...a11yProps(1)}
                                aria-label="employee"
                            />
                            <Tab
                                className="slide slide-info"
                                label={valueTabs === 2 && <span>Job</span>}
                                icon={<BusinessCenterIcon />}
                                {...a11yProps(2)}
                                aria-label="JOB"
                            />
                            <Tab
                                className="slide slide-info"
                                label={valueTabs === 3 && <span>Salary</span>}
                                icon={<MonetizationOnIcon />}
                                {...a11yProps(3)}
                                aria-label="SALARY"
                            />
                            <Tab
                                className="slide slide-info"
                                label={valueTabs === 4 && <span>Family</span>}
                                icon={<FamilyRestroomIcon />}
                                {...a11yProps(4)}
                                aria-label="Family"
                            />
                            <Tab
                                label={valueTabs === 5 && <span>Contract details</span>}
                                className="slide slide-info"
                                icon={<PhoneInTalkIcon />}
                                {...a11yProps(5)}
                                aria-label="Contacts"
                            />
                            <Tab
                                className="slide slide-info"
                                label={valueTabs === 6 && <span>Health</span>}
                                icon={<LocalHospitalIcon />}
                                {...a11yProps(6)}
                                aria-label="Phone"
                            />
                            <Tab
                                className="slide slide-info"
                                label={valueTabs === 7 && <span>Directory</span>}
                                icon={<PrivacyTipIcon />}
                                {...a11yProps(7)}
                                aria-label="Pyarnerson"
                            />
                        </Tabs>
                    </Col>
                </Row>

                <TabPanel value={valueTabs} index={0}>
                    <Row className="">
                        <Col>
                            <Alert
                                message="In a rush? Just fill up Quick entry and you are good to go."
                                type="info"
                                showIcon
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="employeeId" className="title">
                                <span>*</span> ID
                            </label>
                            <Input
                                placeholder="example"
                                id="employeeId"
                                className="ct-input"
                                value={employee.employeesId}
                                disabled
                            />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="firstName" className="title">
                                <span>*</span> First name
                            </label>
                            <Input
                                placeholder="example"
                                id="firstName"
                                className="ct-input"
                                value={employee.firstName}
                                disabled
                            />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="lastName" className="title">
                                <span>*</span> Last name
                            </label>
                            <Input
                                placeholder="example"
                                id="lastName"
                                className="ct-input"
                                value={employee.lastName}
                                disabled
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="gender" className="title">
                                <span>*</span> Gender
                            </label>
                            <div>
                                <Radio.Group value={employee.gender === 'male' ? 1 : 2} disabled id="gender">
                                    <Radio value={1} id="male">
                                        <p style={{ color: '#000' }}>Male</p>
                                    </Radio>
                                    <Radio value={2} id="female">
                                        <p style={{ color: '#000' }}>Female</p>
                                    </Radio>
                                </Radio.Group>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="birthday" className="title">
                                <span>*</span> Day of birth
                            </label>
                            {employee.birthday.length > 0 && (
                                <DatePicker
                                    defaultValue={employee.birthday && moment(employee.birthday, 'YYYY-MM-DD')}
                                    format={'YYYY-MM-DD'}
                                    disabled
                                    style={{ width: '100%' }}
                                    id="birthday"
                                />
                            )}

                            {!id && (
                                <DatePicker
                                    defaultValue={
                                        employee && employee.birthday
                                            ? moment(moment(new Date(employee.birthday)).format(dateFormat), dateFormat)
                                            : null
                                    }
                                    format={dateFormat}
                                    style={{ width: '100%' }}
                                    disabled
                                    id="birthday"
                                />
                            )}
                        </Col>

                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="nationality" className="title">
                                <span>*</span> Nationality
                            </label>

                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                value={employee.nationality}
                                id="nationality"
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                disabled
                            >
                                {countries &&
                                    countries.map((item, idx) => (
                                        <Option key={item._id} value={item.iso2}>
                                            {item.name}
                                        </Option>
                                    ))}
                            </Select>
                        </Col>

                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="type" className="title">
                                <span>*</span> Type Employee
                            </label>
                            <Select style={{ width: '100%' }} value={employee.type} id="type" disabled>
                                {EMPLOYEES_TYPE &&
                                    EMPLOYEES_TYPE.map((item) => <Option value={item.value}>{item.label}</Option>)}
                            </Select>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="nationId" className="title">
                                <span>*</span> {titleNation}
                            </label>
                            <Input
                                placeholder="example"
                                id="nationId"
                                className="ct-input"
                                value={employee.nationId}
                                disabled
                            />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="passport" className="title">
                                Passport
                            </label>
                            <Input placeholder="example" id="passport" value={employee.passport} disabled />
                        </Col>
                        {showWP && (
                            <Col md="4" className="form-group mt-2">
                                <label htmlFor="wpsp" className="title">
                                    WP No. / SP No.
                                </label>
                                <Input placeholder="example" id="wpsp" value={employee.wp} disabled />
                            </Col>
                        )}
                        {showExpiryDateForWP_SP && (
                            <Col md="4" className="form-group mt-2">
                                <label htmlFor="expiryOfWP_SP" className="title">
                                    Expiry Date for Expiry Date for WP/SP
                                </label>
                                {employee && employee.expiryOfWP_SP && employee.expiryOfWP_SP.length > 0 && (
                                    <DatePicker
                                        defaultValue={moment(employee.expiryOfWP_SP, 'YYYY-MM-DD')}
                                        format={'YYYY-MM-DD'}
                                        disabled={true}
                                        style={{ width: '100%' }}
                                        id="expiryOfWP_SP"
                                    />
                                )}

                                {(!id || !employee.expiryOfWP_SP) && (
                                    <DatePicker
                                        defaultValue={null}
                                        format={dateFormat}
                                        style={{ width: '100%' }}
                                        disabled={true}
                                        id="expiryOfWP_SP"
                                    />
                                )}
                            </Col>
                        )}
                        {/* </Row>

                    <Row> */}
                        {showDateNRIC && (
                            <Col md="4" className="form-group mt-2">
                                <label htmlFor="expiryOfNationId" className="title">
                                    Expiry Date for {titleNation}
                                </label>

                                {employee && employee.expiryOfNationId && employee.expiryOfNationId.length > 0 && (
                                    <>
                                        <DatePicker
                                            defaultValue={moment(employee.expiryOfNationId, 'YYYY-MM-DD')}
                                            format={'YYYY-MM-DD'}
                                            disabled={true}
                                            style={{ width: '100%' }}
                                            id="expiryOfNationId"
                                        />
                                    </>
                                )}

                                {(!id || !employee.expiryOfNationId) && (
                                    <DatePicker
                                        defaultValue={null}
                                        format={dateFormat}
                                        style={{ width: '100%' }}
                                        disabled={true}
                                        id="expiryOfNationId"
                                    />
                                )}
                            </Col>
                        )}
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="expiryOfPassport" className="title">
                                Expiry Date for Passport
                            </label>
                            {employee && employee.expiryOfPassport && employee.expiryOfPassport.length > 0 && (
                                <DatePicker
                                    defaultValue={moment(employee.expiryOfPassport, 'YYYY-MM-DD')}
                                    format={'YYYY-MM-DD'}
                                    disabled={true}
                                    style={{ width: '100%' }}
                                    id="expiryOfPassport"
                                />
                            )}

                            {(!id || !employee.expiryOfPassport) && (
                                <DatePicker
                                    defaultValue={null}
                                    format={dateFormat}
                                    style={{ width: '100%' }}
                                    disabled={true}
                                    id="expiryOfNationId"
                                />
                            )}
                        </Col>
                    </Row>

                    <Row>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="jobPosition" className="title">
                                <span>*</span> Job position
                            </label>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                value={employee.jobPosition}
                                id="jobPosition"
                                disabled
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {jobPositions &&
                                    jobPositions.map((item) => (
                                        <Option value={item.jobPositionName}>{item.jobPositionName}</Option>
                                    ))}
                            </Select>
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="email" className="title">
                                <span>*</span> Email
                            </label>
                            <Input
                                placeholder="For employee web account invitation"
                                id="email"
                                className="ct-input"
                                value={employee.email}
                                disabled
                            />
                        </Col>
                    </Row>
                </TabPanel>

                <TabPanel value={valueTabs} index={1}>
                    <Row>
                        <Col lg="12">
                            <div className="avatar_wrap">
                                <div className="img_box">
                                    <img src={imagePreviewUrl ? imagePreviewUrl : imageProfile} />
                                </div>
                                <p className="title_tabs">CHANGE PHOTO</p>
                                <input id="img_input" className="img_avatar" type="file" disabled />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Row>
                                <Col md="12" className="form-group mt-2">
                                    <label htmlFor="employeeId" className="title">
                                        <span>*</span> ID
                                    </label>
                                    <Input
                                        placeholder="example"
                                        id="employeeId"
                                        className="ct-input"
                                        value={employee.employeesId}
                                        disabled
                                    />
                                </Col>
                                <Col md="4" className="form-group mt-2">
                                    <label htmlFor="firstName" className="title">
                                        <span>*</span> First Name
                                    </label>
                                    <Input
                                        placeholder="example"
                                        id="firstName"
                                        className="ct-input"
                                        value={employee.firstName}
                                        disabled
                                    />
                                </Col>
                                <Col md="4" className="form-group mt-2">
                                    <label htmlFor="middleName" className="title">
                                        Middle Name
                                    </label>
                                    <Input
                                        placeholder="example"
                                        id="middleName"
                                        className="ct-input"
                                        value={employee.middleName}
                                        disabled
                                    />
                                </Col>
                                <Col md="4" className="form-group mt-2">
                                    <label htmlFor="lastName" className="title">
                                        <span>*</span> Last Name
                                    </label>
                                    <Input
                                        placeholder="example"
                                        id="lastName"
                                        className="ct-input"
                                        value={employee.lastName}
                                        disabled
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md="4" className="form-group mt-2">
                                    <label htmlFor="email" className="title">
                                        <span>*</span> Email
                                    </label>
                                    <Input
                                        placeholder="email"
                                        id="email"
                                        className="ct-input"
                                        value={employee.email}
                                        disabled
                                    />
                                </Col>
                                <Col md="4" className="form-group mt-2">
                                    <label htmlFor="gender" className="title">
                                        <span>*</span> Gender
                                    </label>
                                    <div>
                                        <Radio.Group value={employee.gender === 'male' ? 1 : 2} id="gender" disabled>
                                            <Radio value={1} id="male">
                                                <p style={{ color: '#000' }}>Male</p>
                                            </Radio>
                                            <Radio value={2} id="female">
                                                <p style={{ color: '#000' }}>Female</p>
                                            </Radio>
                                        </Radio.Group>
                                    </div>
                                </Col>
                                <Col md="4" className="form-group mt-2">
                                    <label htmlFor="birthday" className="title">
                                        <span>*</span> Day of birth
                                    </label>
                                    {employee.birthday.length > 0 && (
                                        <DatePicker
                                            defaultValue={employee.birthday && moment(employee.birthday, 'YYYY-MM-DD')}
                                            format={'YYYY-MM-DD'}
                                            style={{ width: '100%' }}
                                            id="birthday"
                                            disabled
                                        />
                                    )}
                                    {!id && (
                                        <DatePicker
                                            defaultValue={employee.birthday && moment(employee.birthday, 'YYYY-MM-DD')}
                                            format={'YYYY-MM-DD'}
                                            style={{ width: '100%' }}
                                            disabled
                                            id="birthday"
                                        />
                                    )}
                                </Col>
                            </Row>

                            <Row>
                                <Col md="4" className="form-group mt-2">
                                    <label htmlFor="nationality" className="title">
                                        <span>*</span> Nationality
                                    </label>
                                    <Select
                                        disabled
                                        showSearch
                                        style={{ width: '100%' }}
                                        value={employee.nationality}
                                        id="nationality"
                                        placeholder="Search to Select"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.includes(input)}
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                    >
                                        {countries &&
                                            countries.map((item, idx) => (
                                                <Option key={item._id} value={item.iso2}>
                                                    {item.name}
                                                </Option>
                                            ))}
                                    </Select>
                                </Col>
                                <Col md="4" className="form-group mt-2">
                                    <label htmlFor="nationId" className="title">
                                        <span>*</span> {titleNation}
                                    </label>
                                    <Input
                                        disabled
                                        placeholder="example"
                                        id="nationId"
                                        className="ct-input"
                                        value={employee.nationId}
                                    />
                                </Col>

                                <Col md="4" className="form-group mt-2">
                                    <label htmlFor="type" className="title">
                                        <span>*</span> Type Employee
                                    </label>
                                    <Select style={{ width: '100%' }} value={employee.type} id="type" disabled>
                                        {EMPLOYEES_TYPE &&
                                            EMPLOYEES_TYPE.map((item) => (
                                                <Option value={item.value}>{item.label}</Option>
                                            ))}
                                    </Select>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4" className="form-group mt-2">
                                    <label htmlFor="passport" className="title">
                                        Passport
                                    </label>
                                    <Input placeholder="example" id="passport" value={employee.passport} disabled />
                                </Col>
                                {showWP && (
                                    <Col md="4" className="form-group mt-2">
                                        <label htmlFor="wpsp" className="title">
                                            WP No. / SP No.
                                        </label>
                                        <Input placeholder="example" id="wpsp" value={employee.wp} disabled />
                                    </Col>
                                )}
                                {showExpiryDateForWP_SP && (
                                    <Col md="4" className="form-group mt-2">
                                        <label htmlFor="expiryOfWP_SP" className="title">
                                            Expiry Date for Expiry Date for WP/SP
                                        </label>
                                        {employee && employee.expiryOfWP_SP && employee.expiryOfWP_SP.length > 0 && (
                                            <DatePicker
                                                defaultValue={moment(employee.expiryOfWP_SP, 'YYYY-MM-DD')}
                                                format={'YYYY-MM-DD'}
                                                disabled={true}
                                                style={{ width: '100%' }}
                                                id="expiryOfWP_SP"
                                            />
                                        )}

                                        {(!id || !employee.expiryOfWP_SP) && (
                                            <DatePicker
                                                defaultValue={null}
                                                format={dateFormat}
                                                style={{ width: '100%' }}
                                                disabled={true}
                                                id="expiryOfWP_SP"
                                            />
                                        )}
                                    </Col>
                                )}
                                {showDateNRIC && (
                                    <Col md="4" className="form-group mt-2">
                                        <label htmlFor="expiryOfNationId" className="title">
                                            Expiry Date for {titleNation}
                                        </label>

                                        {employee && employee.expiryOfNationId && employee.expiryOfNationId.length > 0 && (
                                            <>
                                                <DatePicker
                                                    defaultValue={moment(employee.expiryOfNationId, 'YYYY-MM-DD')}
                                                    format={'YYYY-MM-DD'}
                                                    disabled={true}
                                                    style={{ width: '100%' }}
                                                    id="expiryOfNationId"
                                                />
                                            </>
                                        )}

                                        {(!id || !employee.expiryOfNationId) && (
                                            <DatePicker
                                                defaultValue={null}
                                                format={dateFormat}
                                                style={{ width: '100%' }}
                                                disabled={true}
                                                id="expiryOfNationId"
                                            />
                                        )}
                                    </Col>
                                )}
                                {/* </Row>
                            <Row> */}
                                <Col md="4" className="form-group mt-2">
                                    <label htmlFor="ethnicity" className="title">
                                        Ethnicity
                                    </label>

                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        value={employee.ethnicity}
                                        id="ethnicity"
                                        disabled
                                        placeholder="Search to Select"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.includes(input)}
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                    >
                                        {ethnicityList.map((item, index) => (
                                            <Option value={item._id} key={index}>
                                                {item.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>

                                <Col md="4" className="form-group mt-2">
                                    <label htmlFor="religion" className="title">
                                        Religion
                                    </label>

                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        value={employee.religion}
                                        disabled
                                        id="religion"
                                        placeholder="Search to Select"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.includes(input)}
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                    >
                                        {religionList.map((item, index) => (
                                            <Option value={item._id} key={index}>
                                                {item.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col md="4" className="form-group mt-2">
                                    <label htmlFor="expiryOfPassport" className="title">
                                        Expiry Date for Passport
                                    </label>
                                    {employee && employee.expiryOfPassport && employee.expiryOfPassport.length > 0 && (
                                        <DatePicker
                                            defaultValue={moment(employee.expiryOfPassport, 'YYYY-MM-DD')}
                                            format={'YYYY-MM-DD'}
                                            disabled={true}
                                            style={{ width: '100%' }}
                                            id="expiryOfPassport"
                                        />
                                    )}

                                    {(!id || !employee.expiryOfPassport) && (
                                        <DatePicker
                                            defaultValue={null}
                                            format={dateFormat}
                                            style={{ width: '100%' }}
                                            disabled={true}
                                            id="expiryOfNationId"
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </TabPanel>

                <TabPanel value={valueTabs} index={2}>
                    <Row>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="dateJoin" className="title">
                                <span>*</span> Date Join
                            </label>
                            <DatePicker
                                id="dateJoin"
                                name="dateJoin"
                                defaultValue={
                                    employee && employee.dateJoin
                                        ? moment(moment(new Date(employee.dateJoin)).format(dateFormat), dateFormat)
                                        : null
                                }
                                format={dateFormat}
                                disabled
                            />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="endProbation" className="title">
                                End of Probation
                            </label>
                            <DatePicker
                                id="endProbation"
                                name="endProbation"
                                defaultValue={
                                    employee && employee.dateJoin
                                        ? moment(moment(new Date(employee.dateJoin)).format(dateFormat), dateFormat)
                                        : null
                                }
                                format={dateFormat}
                                disabled
                            />
                        </Col>

                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="endProbation" className="title">
                                Time Clock Needed
                            </label>
                            <Switch defaultChecked={employee && employee.timeClock} disabled />
                        </Col>
                    </Row>
                    <p className="title_tabs">PLACEMENT</p>
                    <Row>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="effectiveDate" className="title">
                                <span>*</span> Effective Date
                            </label>
                            <DatePicker
                                id="effectiveDate"
                                name="effectiveDate"
                                defaultValue={
                                    placement && placement.effectiveDate
                                        ? moment(
                                              moment(new Date(placement.effectiveDate)).format(dateFormat),
                                              dateFormat,
                                          )
                                        : null
                                }
                                format={dateFormat}
                                disabled
                            />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="jobPosition" className="title">
                                <span>*</span> Job Position
                            </label>

                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                disabled
                                id="jobPosition"
                                value={employee.jobPosition}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {jobPositions &&
                                    jobPositions.map((item) => (
                                        <Option value={item.jobPositionName}>{item.jobPositionName}</Option>
                                    ))}
                            </Select>
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="lineManager" className="title">
                                Line Manager
                            </label>

                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                disabled
                                id="lineManager"
                                value={placement && placement.lineManager}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {employeeList.map((item, index) => (
                                    <Option value={item._id} key={index}>
                                        {item.firstName}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="department" className="title">
                                Department
                            </label>

                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                disabled
                                id="department"
                                value={employee && employee.department}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {departments &&
                                    departments.map((item) => <Option value={item._id}>{item.departmentName}</Option>)}
                            </Select>
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="branch" className="title">
                                Branch
                            </label>

                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                disabled
                                id="department"
                                value={placement && placement.branch}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {branches &&
                                    branches.map((item, index) => (
                                        <Option key={index} value={item._id}>
                                            {item.name}
                                        </Option>
                                    ))}
                            </Select>
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="level" className="title">
                                Level
                            </label>
                            <Select style={{ width: '100%' }} value={placement && placement.level} disabled>
                                {levelList &&
                                    levelList.map((item, index) => (
                                        <Option key={index} value={item._id}>
                                            {item.name}
                                        </Option>
                                    ))}
                            </Select>
                        </Col>
                    </Row>
                    <p className="title_tabs">EMPLOYMENT TERM</p>
                    <Row>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="effectiveDate" className="title">
                                <span>*</span> Effective Date
                            </label>
                            <DatePicker
                                id="effectiveDate"
                                name="effectiveDate"
                                defaultValue={
                                    employmentTerm && employmentTerm.effectiveDate
                                        ? moment(
                                              moment(new Date(employmentTerm.effectiveDate)).format(dateFormat),
                                              dateFormat,
                                          )
                                        : null
                                }
                                format={dateFormat}
                                disabled
                            />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="jobType" className="title">
                                Job Type
                            </label>
                            <Select style={{ width: '100%' }} value={employmentTerm && employmentTerm.jobType} disabled>
                                <Option value="permanent">Permanent</Option>
                                <Option value="contract">Contract</Option>
                                <Option value="parttime">Part Time</Option>
                                <Option value="internship">Internship</Option>
                            </Select>
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="description" className="title">
                                Description
                            </label>
                            <Select
                                style={{ width: '100%' }}
                                value={employmentTerm && employmentTerm.description}
                                disabled
                            >
                                <Option value="confirm">Confirmed</Option>
                                <Option value="probation">Probation</Option>
                                <Option value="resigned">Resigned</Option>
                                <Option value="dismissed">Dismissed</Option>
                                <Option value="contract">Contract </Option>
                                <Option value="retired">Retired</Option>
                                <Option value="retrened">Retrened</Option>
                                <Option value="deceased">Deceased</Option>
                            </Select>
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="leaveWordflow" className="title">
                                Leave Wordflow
                            </label>
                            <Select
                                style={{ width: '100%' }}
                                value={employmentTerm && employmentTerm.leaveWordflow}
                                disabled
                            >
                                {leaveWordflowList.map((item, index) => (
                                    <Option value={item._id} key={index}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="workDay" className="title">
                                Work Day
                            </label>
                            <Select
                                style={{ width: '100%' }}
                                value={employmentTerm && employmentTerm.workDay}
                                // disabled={urlView !== -1 && true}
                                // onChange={(value) => setEmploymentTerm({ ...employmentTerm, workDay: value })}
                                disabled
                            >
                                {workDayList.map((item, index) => (
                                    <Option value={item._id} key={index}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="holiday" className="title">
                                Holiday
                            </label>
                            <Select style={{ width: '100%' }} value={employmentTerm && employmentTerm.holiday} disabled>
                                {jobHolidayList.map((item, index) => (
                                    <Option value={item._id} key={index}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="holiday" className="title">
                                Term Start
                            </label>
                            <DatePicker
                                defaultValue={
                                    employmentTerm && employmentTerm.termStart
                                        ? moment(
                                              moment(new Date(employmentTerm.termStart)).format(dateFormat),
                                              dateFormat,
                                          )
                                        : null
                                }
                                format={dateFormat}
                                disabled
                                style={{ width: '100%' }}
                            />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="holiday" className="title">
                                Term End
                            </label>
                            <DatePicker
                                defaultValue={
                                    employmentTerm && employmentTerm.termEnd
                                        ? moment(
                                              moment(new Date(employmentTerm.termEnd)).format(dateFormat),
                                              dateFormat,
                                          )
                                        : null
                                }
                                format={dateFormat}
                                disabled
                                style={{ width: '100%' }}
                            />
                        </Col>
                    </Row>
                </TabPanel>

                <TabPanel value={valueTabs} index={3}>
                    <p className="title_tabs">SALARY</p>

                    <Row>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="effectiveDate" className="title">
                                <span>*</span> Effective Date
                            </label>
                            <DatePicker
                                id="effectiveDate"
                                name="effectiveDate"
                                defaultValue={
                                    salary && salary.effectiveDate
                                        ? moment(moment(new Date(salary.effectiveDate)).format(dateFormat), dateFormat)
                                        : null
                                }
                                format={dateFormat}
                                disabled
                            />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="basicsalary" className="title">
                                <span>*</span> Basic Salary
                            </label>
                            <Input
                                id="basicsalary"
                                className="ct-input"
                                value={salary && salary.basicSalary}
                                disabled
                            />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="currency" className="title">
                                <span>*</span> Currency
                            </label>
                            <Select style={{ width: '100%' }} value={salary && salary.currency} disabled id="currency">
                                {currency.length > 0 &&
                                    currency.map((item, idx) => {
                                        return (
                                            <Option key={idx} value={item}>
                                                {item}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </Col>

                        <Col md="12">
                            <div className="salary_box">
                                <BsQuestionSquareFill size={20} color="#cddc39" />{' '}
                                <span>For Hourly rate, you may use Earning.</span>
                            </div>
                        </Col>

                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="reviewDate" className="title">
                                Next Review Date
                            </label>
                            <DatePicker
                                id="reviewDate"
                                name="reviewDate"
                                defaultValue={
                                    salary && salary.reviewDate
                                        ? moment(moment(new Date(salary.reviewDate)).format(dateFormat), dateFormat)
                                        : null
                                }
                                format={dateFormat}
                                disabled
                            />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="earning" className="title">
                                Earning
                            </label>
                            <Input id="earning" className="ct-input" value={salary && salary.earning} disabled />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="deduction" className="title">
                                Deduction
                            </label>
                            <Input id="deduction" className="ct-input" value={salary && salary.deduction} disabled />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="bonus" className="title">
                                Bonus
                            </label>
                            <Input id="bonus" className="ct-input" value={salary && salary.bonus} disabled />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="statutoryContribution" className="title">
                                Statutory Contribution
                            </label>
                            <Input
                                id="statutoryContribution"
                                className="ct-input"
                                value={salary && salary.statutoryContribution}
                                disabled
                            />
                        </Col>
                    </Row>

                    <p className="title_tabs">PAYMENT</p>
                    <Row>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="bank" className="title">
                                Bank
                            </label>
                            <Select
                                style={{ width: '100%' }}
                                value={payment && payment.bank}
                                disabled
                                onChange={(value) => setPayment({ ...payment, bank: value })}
                            >
                                {bankList &&
                                    bankList.map((item, index) => (
                                        <Option key={index} value={item._id}>
                                            {item.name}
                                        </Option>
                                    ))}
                            </Select>
                        </Col>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="bankAccount" className="title">
                                IBAN / Bank Account
                            </label>
                            <Input
                                placeholder="IBAN / Bank Account"
                                id="bankAccount"
                                disabled
                                value={payment && payment.bankAccount}
                            />
                        </Col>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="payPeriod" className="title">
                                Pay Period
                            </label>
                            <Select style={{ width: '100%' }} value={payment && payment.payPeriod} disabled>
                                {EMPLOYEE_PAYMENT_PAY.map((item) => (
                                    <Option key={item.id} value={item.value}>
                                        {item.label}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="method" className="title">
                                Method
                            </label>
                            <Select style={{ width: '100%' }} disabled value={payment && payment.method}>
                                {EMPLOYEE_PAYMENT_METHOD.map((item) => (
                                    <Option key={item.id} value={item.value}>
                                        {item.label}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                </TabPanel>

                <TabPanel value={valueTabs} index={4}>
                    <p className="title_tabs">SPOUSE</p>
                    <Row>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="maritalStatus" className="title">
                                Married Status
                            </label>
                            <Select style={{ width: '100%' }} disabled value={familys && familys.maritalStatus}>
                                <Option value="single">Single</Option>
                                <Option value="married">Married</Option>
                                <Option value="divorced">Divorced</Option>
                                <Option value="wedowed">Widowed</Option>
                            </Select>
                        </Col>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="spouseworking" className="title">
                                Spouse Working
                            </label>
                            <Switch defaultChecked={familys && familys.spouseWorking} disabled />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="firstName" className="title">
                                First Name
                            </label>
                            <Input id="firstName" disabled value={familys && familys.firstName} />
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="middleName" className="title">
                                Middle Name
                            </label>
                            <Input id="middleName" disabled value={familys && familys.middleName} />
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="lastName" className="title">
                                Last Name
                            </label>
                            <Input id="lastName" disabled value={familys && familys.lastName} />
                        </Col>

                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="birthday" className="title">
                                Birthday
                            </label>
                            <DatePicker
                                id="birthday"
                                name="birthday"
                                defaultValue={
                                    familys && familys.birthday
                                        ? moment(moment(new Date(familys.birthday)).format(dateFormat), dateFormat)
                                        : null
                                }
                                format={dateFormat}
                                disabled
                            />
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="nationality" className="title">
                                Nationality
                            </label>
                            <Select style={{ width: '100%' }} disabled value={familys && familys.nationality}>
                                {countries &&
                                    countries.map((item, idx) => (
                                        <Option key={item._id} value={item.iso2}>
                                            {item.name}
                                        </Option>
                                    ))}
                            </Select>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="nationId" className="title">
                                National ID
                            </label>
                            <Input id="nationId" value={familys && familys.nationId} disabled />
                        </Col>

                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="passport" className="title">
                                Passport
                            </label>
                            <Input id="passport" value={familys && familys.passport} disabled />
                        </Col>

                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="ethnicity" className="title">
                                Ethnicity
                            </label>
                            <Select style={{ width: '100%' }} disabled>
                                <Option value="ethnicity1">Ethnicity 1</Option>
                                <Option value="ethnicity2">Ethnicity 2</Option>
                                <Option value="ethnicity3">Ethnicity 3</Option>
                                <Option value="ethnicity4">Ethnicity 4</Option>
                            </Select>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="religion" className="title">
                                Religion
                            </label>
                            <Select style={{ width: '100%' }} disabled>
                                <Option value="religion1">Religion 1</Option>
                                <Option value="religion2">Religion 2</Option>
                                <Option value="religion3">Religion 3</Option>
                                <Option value="religion4">Religion 4</Option>
                            </Select>
                        </Col>
                    </Row>
                    <p className="title_tabs">CHILDREN</p>
                    <Row>
                        <Col lg="12" className="form-group mt-2">
                            <label htmlFor="children" className="title">
                                Children
                            </label>
                            <Input id="children" value={familys && familys.children} disabled />
                        </Col>
                    </Row>
                </TabPanel>

                <TabPanel value={valueTabs} index={5}>
                    <p className="title_tabs">WEB</p>
                    <Row>
                        <Col md="6" className="form-group mt-2">
                            <label htmlFor="email" className="title">
                                Email
                            </label>
                            <Input id="email" value={phone && phone.email} disabled />
                        </Col>
                        {/* <Col md="6" className="form-group mt-2">
                            <label htmlFor="blog" className="title">
                                Blog
                            </label>
                            <Input id="blog" value={phone && phone.blog} disabled />
                        </Col> */}
                    </Row>
                    <p className="title_tabs">PHONE</p>
                    <Row>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="officePhone" className="title">
                                Office Phone
                            </label>
                            <Input id="officePhone" value={phone && phone.officePhone} disabled />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="mobilePhone" className="title">
                                Mobile phone
                            </label>

                            <Input id="mobilePhone" value={phone && phone.mobilePhone} disabled />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="housePhone" className="title">
                                House phone
                            </label>

                            <Input id="housePhone" value={phone && phone.housePhone} disabled />
                        </Col>
                    </Row>
                    <p className="title_tabs">ADDRESS</p>
                    <Row>
                        <Col md="6" className="form-group mt-2">
                            <label htmlFor="address1" className="title">
                                Address 1
                            </label>
                            <Input id="address1" value={address && address.address1} disabled />
                        </Col>
                        <Col md="6" className="form-group mt-2">
                            <label htmlFor="address2" className="title">
                                Address 2
                            </label>
                            <Input id="address2" value={address && address.address2} disabled />
                        </Col>
                        <Col md="6" className="form-group mt-2">
                            <label htmlFor="city" className="title">
                                City
                            </label>
                            <Input id="city" value={address && address.city} disabled />
                        </Col>
                        <Col md="6" className="form-group mt-2">
                            <label htmlFor="postCode" className="title">
                                Postcode
                            </label>
                            <Input id="postCode" value={address && address.postCode} disabled />
                        </Col>
                        <Col md="6" className="form-group mt-2">
                            <label htmlFor="state" className="title">
                                State
                            </label>
                            <Input id="state" value={address && address.state} disabled />
                        </Col>
                        <Col md="6" className="form-group mt-2">
                            <label htmlFor="country" className="title">
                                Country
                            </label>
                            <Select style={{ width: '100%' }} value={address && address.country} disabled>
                                {countries.map((item) => {
                                    return <Option value={item.iso2}>{item.name}</Option>;
                                })}
                            </Select>
                        </Col>
                    </Row>
                    <p className="title_tabs">IN CASE OF EMERGENCY</p>
                    <Row>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="firstName" className="title">
                                First Name
                            </label>
                            <Input id="firstName" value={emergency && emergency.firstName} disabled />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="middleName" className="title">
                                Middle Name
                            </label>
                            <Input id="middleName" value={emergency && emergency.middleName} disabled />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="lastName" className="title">
                                Last Name
                            </label>
                            <Input id="lastName" value={emergency && emergency.lastName} disabled />
                        </Col>
                        <Col md="6" className="form-group mt-2">
                            <label htmlFor="relationship" className="title">
                                Relationsip
                            </label>
                            <Input id="relationship" value={emergency && emergency.relationship} disabled />
                        </Col>
                        <Col md="6" className="form-group mt-2">
                            <label htmlFor="mobilePhone" className="title">
                                Mobile Phone
                            </label>
                            <Input id="mobilePhone" disabled />
                        </Col>
                        <Col md="6" className="form-group mt-2">
                            <label htmlFor="housePhone" className="title">
                                House Phone
                            </label>
                            <Input id="housePhone" disabled />
                        </Col>
                        <Col md="6" className="form-group mt-2">
                            <label htmlFor="officePhone" className="title">
                                Office Phone
                            </label>
                            <Input id="officePhone" disabled />
                        </Col>
                    </Row>
                </TabPanel>

                <TabPanel value={valueTabs} index={6}>
                    <p className="title_tabs">PHYSICAL</p>
                    <Row>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="physicalHeight" className="title">
                                Height
                            </label>
                            <div>
                                <Input
                                    placeholder="Height (cm)"
                                    id="physicalHeight"
                                    className="ct-input"
                                    value={health && health.physicalHeight}
                                    disabled
                                />
                            </div>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="physicalWeight" className="title">
                                Weight
                            </label>
                            <div>
                                <Input
                                    placeholder="Weight (kg)"
                                    id="physicalWeight"
                                    className="ct-input"
                                    value={health && health.physicalWeight}
                                    disabled
                                />
                            </div>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="physicalBloodType" className="title">
                                Blood Type
                            </label>
                            <div>
                                <Select value={health && health.physicalBloodType} style={{ width: '100%' }} disabled>
                                    {EMPLOYEE_BLOOD_TYPE.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                    </Row>
                    <p className="title_tabs">VISION</p>
                    <Row>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="visionLeft" className="title">
                                Left
                            </label>
                            <div>
                                <Select value={health && health.visionLeft} style={{ width: '100%' }} disabled>
                                    {EMPLOYEE_HEALTH.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="visonRight" className="title">
                                Right
                            </label>
                            <div>
                                <Select value={health && health.visonRight} style={{ width: '100%' }} disabled>
                                    {EMPLOYEE_HEALTH.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                    </Row>
                    <p className="title_tabs">HEARING</p>
                    <Row>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="hearingLeft" className="title">
                                Left
                            </label>
                            <div>
                                <Select value={health && health.hearingLeft} style={{ width: '100%' }} disabled>
                                    {EMPLOYEE_HEARING.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="hearingRight" className="title">
                                Right
                            </label>
                            <div>
                                <Select value={health && health.hearingRight} style={{ width: '100%' }} disabled>
                                    {EMPLOYEE_HEARING.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                    </Row>
                    <p className="title_tabs">HAND</p>
                    <Row>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="handLeft" className="title">
                                Left
                            </label>
                            <div>
                                <Select value={health && health.handLeft} style={{ width: '100%' }} disabled>
                                    {EMPLOYEE_HAND_LEG.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="handRight" className="title">
                                Right
                            </label>
                            <div>
                                <Select value={health && health.handRight} style={{ width: '100%' }} disabled>
                                    {EMPLOYEE_HAND_LEG.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                    </Row>
                    <p className="title_tabs">LEG</p>
                    <Row>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="legLeft" className="title">
                                Left
                            </label>
                            <div>
                                <Select value={health && health.legLeft} style={{ width: '100%' }} disabled>
                                    {EMPLOYEE_HAND_LEG.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="legRight" className="title">
                                Right
                            </label>
                            <div>
                                <Select value={health && health.legRight} style={{ width: '100%' }} disabled>
                                    {EMPLOYEE_HAND_LEG.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                    </Row>
                </TabPanel>

                <TabPanel value={valueTabs} index={7}>
                    <p className="title_tabs">ACCESS RIGHT</p>
                    <Row>
                        <Col lg="12" className="form-group mt-2">
                            <label htmlFor="employeeRole" className="title">
                                Employee Role
                            </label>
                            <div>
                                <Select value={derectory && derectory.employeeRole} style={{ width: '100%' }} disabled>
                                    {EMPLOYEE_PRIVACY_LEVEL.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="12">
                            <div className="access_box">
                                <BsQuestionSquareFill size={20} color="#cddc39" />
                                <p>Guest: No access to the Employee Directory.</p>
                                <p>Employee: Access to Privacy Level marked as Employee.</p>
                                <p>Manager: Access to Privacy Level marked as either Employee or Manager.</p>
                            </div>
                        </Col>
                    </Row>
                    <p className="title_tabs">PRIVACY LEVEL</p>
                    <Row>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="email" className="title">
                                Email
                            </label>
                            <div>
                                <Select value={derectory && derectory.email} style={{ width: '100%' }} disabled>
                                    {EMPLOYEE_PRIVACY_LEVEL.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="blog" className="title">
                                Blog / Homepage
                            </label>
                            <div>
                                <Select value={derectory && derectory.blog} style={{ width: '100%' }} disabled>
                                    {EMPLOYEE_PRIVACY_LEVEL.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="officePhone" className="title">
                                Office Phone
                            </label>
                            <div>
                                <Select value={derectory && derectory.officePhone} style={{ width: '100%' }} disabled>
                                    {EMPLOYEE_PRIVACY_LEVEL.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="housePhone" className="title">
                                House Phone
                            </label>
                            <div>
                                <Select value={derectory && derectory.housePhone} style={{ width: '100%' }} disabled>
                                    {EMPLOYEE_PRIVACY_LEVEL.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="mobilePhone" className="title">
                                Mobile Phone
                            </label>
                            <div>
                                <Select value={derectory && derectory.mobilePhone} style={{ width: '100%' }} disabled>
                                    {EMPLOYEE_PRIVACY_LEVEL.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="address" className="title">
                                Address
                            </label>
                            <div>
                                <Select value={derectory && derectory.address} style={{ width: '100%' }} disabled>
                                    {EMPLOYEE_PRIVACY_LEVEL.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="emergency" className="title">
                                In Case of Emergency
                            </label>
                            <div>
                                <Select value={derectory && derectory.emergency} style={{ width: '100%' }} disabled>
                                    {EMPLOYEE_PRIVACY_LEVEL.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="birthday" className="title">
                                Birthday
                            </label>
                            <div>
                                <Select value={derectory && derectory.birthday} style={{ width: '100%' }} disabled>
                                    {EMPLOYEE_PRIVACY_LEVEL.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="familyBirthday" className="title">
                                Family Birthday
                            </label>
                            <div>
                                <Select
                                    value={derectory && derectory.familyBirthday}
                                    style={{ width: '100%' }}
                                    disabled
                                >
                                    {EMPLOYEE_PRIVACY_LEVEL.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="anniversary" className="title">
                                Anniversary
                            </label>
                            <div>
                                <Select value={derectory && derectory.anniversary} style={{ width: '100%' }} disabled>
                                    {EMPLOYEE_PRIVACY_LEVEL.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                    </Row>
                </TabPanel>
            </Card>
        </Container>
    );
}

export default MyInformation;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
