import axios from 'axios';

const path = 'state/';
const getByCountry = async (ios2) => {
    return await axios.get(path + ios2);
};

export const stateAPI = {
    getByCountry,
};
