export const getError = (error) => {
    let message = 'Internal server!';
    if (error && error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
            message = error.response.data.message;
        }
        if (typeof error.response.data.message === 'object') {
            message = error.response.data.message[0];
        }
    }
    return message;
};
