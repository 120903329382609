import React, { useEffect, useState } from 'react';

import { Switch } from 'antd';
import { Card, CardBody, Col, Container, Form, FormGroup, FormInput, Row } from 'shards-react';
import PageTitle from '../common/PageTitle';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { jobPositionAPI } from '../../apis/jobPositionAPI';
import { ROLE } from '../../helpers/const';
import { useLoading, useUser } from '../../hooks';

export default function AddJobPosition() {
    const { user, getMe } = useUser();
    const history = useHistory();

    const [payload, setPayload] = useState();
    const { setLoading } = useLoading();
    useEffect(() => {
        if (!user) {
            getMe();
        }
        user && user.role && user.role !== ROLE.COMPANY && history.push('/login');
    }, [user]);

    const handleClick = async () => {
        if (!user) {
            getMe();
        }
        if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id && payload) {
            setLoading(true);
            if (payload.code === '') {
                toast.error('Job position code should not be empty!');
            } else if (payload.jobPositionName === '') {
                toast.error('Job position title not be empty!');
            }
            await jobPositionAPI.create({ companyID: user.company._id, ...payload });
            setLoading(false);
            toast.success('Update success!');
            history.push('/employers/job-positions');
        }
    };

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle
                    title="Add Job Position"
                    subtitle="Company settings / Job Positions / Add job position "
                    className="text-sm-left mb-3"
                />
            </Row>
            <Row className="mt-2 w-100" lg="12">
                <Card className="h-100 w-100">
                    <CardBody className="col-xl-12 col-lg-12">
                        <Row className="d-flex justify-content-center align-items-center">
                            <Col lg="8" md="12">
                                <Form>
                                    <FormGroup className="mt-4">
                                        <label htmlFor="feCompanieName">Code</label>
                                        <FormInput
                                            id="feCompanieName"
                                            type="text"
                                            placeholder=""
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `Please Input company information settings`,
                                                },
                                            ]}
                                            value={payload && payload.code && payload.code}
                                            onChange={(e) => setPayload({ ...payload, code: e.target.value })}
                                        />
                                    </FormGroup>

                                    <FormGroup className="mt-4">
                                        <label htmlFor="feInputAddress">Title</label>
                                        <FormInput
                                            id="feInputAddress"
                                            placeholder=""
                                            value={payload && payload.jobPositionName && payload.jobPositionName}
                                            onChange={(e) =>
                                                setPayload({ ...payload, jobPositionName: e.target.value })
                                            }
                                        />
                                    </FormGroup>
                                    <Col lg="12" md="6" sm="12">
                                        <div className="d-flex justify-content-between align-items-center my-4">
                                            <h6>Active</h6>
                                            <Switch
                                                defaultChecked
                                                onChange={(e) => setPayload({ ...payload, active: e })}
                                                checked={payload && payload.active && payload.active}
                                            />
                                        </div>
                                    </Col>
                                    <FormGroup className="mt-4">
                                        <label htmlFor="feInputUrl">Description</label>
                                        <FormInput
                                            id="feInputUrl"
                                            placeholder=""
                                            value={payload && payload.description && payload.description}
                                            onChange={(e) => setPayload({ ...payload, description: e.target.value })}
                                        />
                                    </FormGroup>
                                    <Row className="d-flex justify-content-center align-items-center mt-5">
                                        <div className="d-flex justify-content-center align-items-center my-3">
                                            <div
                                                onClick={handleClick}
                                                className="text-center btn-save-attendanceSetting rounded py-2 px-4 d-flex justify-content-center align-items-center button__warning"
                                            >
                                                <span className="text">Save</span>
                                            </div>
                                        </div>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Row>
        </Container>
    );
}
