import { Checkbox, Divider, Space, Switch, Table } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'shards-react';

function Administrator() {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
    }, []);

    const columns = [
        { title: '', dataIndex: 'name', key: 'name' },
        {
            title: 'Read',
            dataIndex: 'read',
            key: 'read',
            render: (_, record) => (
                <Space size="middle">
                    <Checkbox checked={true} />
                </Space>
            ),
        },
        {
            title: 'Write',
            dataIndex: 'write',
            key: 'write',
            render: (_, record) => (
                <Space size="middle">
                    <Checkbox checked={true} />
                </Space>
            ),
        },
        {
            title: 'Create',
            key: 'create',
            dataIndex: 'create',
            render: (_, record) => (
                <Space size="middle">
                    <Checkbox checked={true} />
                </Space>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (_, record) => (
                <Space size="middle">
                    <Checkbox checked={true} />
                </Space>
            ),
        },
        {
            title: 'Import',
            dataIndex: 'import',
            key: 'import',
            render: (_, record) => (
                <Space size="middle">
                    <Checkbox checked={true} />
                </Space>
            ),
        },
        {
            title: 'Export',
            key: 'export',
            dataIndex: 'export',
            render: (_, record) => (
                <Space size="middle">
                    <Checkbox checked={true} />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            name: 'Employee',
        },
        {
            key: '2',
            name: 'Leave',
        },
        {
            key: '3',
            name: 'Attendance',
        },
    ];

    const TableMB = ({ tableData, headingColumns, title, breakOn = 'medium' }) => {
        let tableClass = 'table-container__table';

        if (breakOn === 'small') {
            tableClass += ' table-container__table--break-sm';
        } else if (breakOn === 'medium') {
            tableClass += ' table-container__table--break-md';
        } else if (breakOn === 'large') {
            tableClass += ' table-container__table--break-lg';
        }

        const data = tableData.map((row, index) => {
            let rowData = [];
            let i = 0;

            for (const key in row) {
                rowData.push({
                    key: headingColumns[i],
                    val: row[key],
                });
                i++;
            }

            return (
                <tr key={index}>
                    {rowData.map((data, index) => (
                        <td key={index} data-heading={data.key}>
                            {data.val}
                        </td>
                    ))}
                </tr>
            );
        });

        return (
            <div className="table-container">
                <div className="table-container__title">
                    <h2>{title}</h2>
                </div>
                <table className={tableClass}>
                    <thead>
                        <tr>
                            {headingColumns.map((col, index) => (
                                <th key={index}>{col}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>{data}</tbody>
                </table>
            </div>
        );
    };

    TableMB.propTypes = {
        tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
        headingColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
        title: PropTypes.string.isRequired,
        breakOn: PropTypes.oneOf(['small', 'medium', 'large']),
    };

    const dt = [
        {
            number: 10,
            name: 'Pierre Gasly',
            role: 'Dob',
            email: '@qasdf',
            dob: '07/02/1996',
            salary: '$12344',
            payslip: '123',
            action: '121212',
        },
        {
            number: 10,
            name: 'Pierre Gasly',
            role: 'Dob',
            email: '@qasdf',
            dob: '07/02/1996',
            salary: '$12344',
            payslip: '123',
            action: '121212',
        },
        {
            number: 10,
            name: 'Pierre Gasly',
            role: 'Dob',
            email: '@qasdf',
            dob: '07/02/1996',
            salary: '$12344',
            payslip: '123',
            action: '121212',
        },
    ];

    return (
        <>
            <Row>
                <Col>
                    <h5 className="title-font">MODULE ACCESS</h5>
                    <ul className="list_admin">
                        <li>
                            <span>Employee</span>
                            <Switch checked={true} />
                        </li>
                        <li>
                            <span>Expense Claim</span>
                            <Switch checked={true} />
                        </li>
                        <li>
                            <span>Leave</span>
                            <Switch checked={true} />
                        </li>
                        <li>
                            <span>Attendance</span>
                            <Switch checked={true} />
                        </li>
                        <li>
                            <span>Payroll</span>
                            <Switch checked={true} />
                        </li>
                    </ul>
                </Col>
            </Row>
            <Divider style={{ borderTop: '1px solid rgb(0 0 0 / 24%)' }} />
            <Row>
                <Col>
                    <h5 className="title-font">MODULE PERMISSION</h5>

                    {width <= 600 ? (
                        <TableMB
                            tableData={dt}
                            headingColumns={[
                                'ID',
                                'Name',
                                'Role',
                                'E-mail',
                                'Join date',
                                'Salary',
                                'Payslip',
                                'Action',
                            ]}
                            title="My Attendance"
                        ></TableMB>
                    ) : (
                        <Table columns={columns} dataSource={data} pagination={false} />
                    )}
                    {/* <Button>Save</Button> */}
                </Col>
            </Row>
        </>
    );
}

export default Administrator;
