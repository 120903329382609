import axios from 'axios';

const path = 'users/';

const get = async () => {
    return await axios.get(path + 'all');
};

const me = async () => {
    return await axios.post('auth/me');
};

const resetPassword = async (payload) => {
    return await axios.post('auth/reset_password', payload);
};

const getById = async (id) => {
    return await axios.get(path + id);
};

const findByEmail = async (email) => {
    return await axios.get(path + 'find-by-email/' + email);
};

const getByFb = async (id) => {
    return await axios.get(path + 'facebook/' + id);
};

const linkToFb = async (id, fb) => {
    return await axios.get(path + 'link-to-fb/' + id + '/' + fb);
};

const getSystemStaff = async () => {
    return await axios.get(path + 'system-staff/');
};

const getByAccountAndPassword = async (payload) => {
    return await axios.post(path + 'login', payload);
};

const add = async (payload) => {
    return await axios.post(path, payload);
};

const update = async (id, payload) => {
    return await axios.patch(path + id, payload);
};

const _delete = async (id) => {
    return await axios.delete(path + id);
};

const addSystemStaff = async (payload) => {
    return await axios.post(path + 'add-system-staff', payload);
};

const addCompanyStaff = async (payload) => {
    return await axios.post(path + 'add-company-staff', payload);
};

export const userAPI = {
    get,
    me,
    getById,
    getByAccountAndPassword,
    add,
    update,
    _delete,
    resetPassword,
    addSystemStaff,
    addCompanyStaff,
    getByFb,
    findByEmail,
    linkToFb,
    getSystemStaff,
};
