import { ROLE } from '../helpers/const';

export default function() {
    return [
        {
            title: 'Dashboard',
            to: '/dashboard',
            htmlBefore: '<span class="material-icons">dashboard</span>',
            htmlAfter: '',
            role: ROLE.ADMIN + ',' + ROLE.SYSTEMSTAFF + ',' + ROLE.COMPANY + ',' + ROLE.COMPANYSTAFF,
        },
        {
            title: 'Companies',
            htmlBefore: '<span class="material-icons">maps_home_work</span>',
            to: '/companies',
            role: ROLE.ADMIN + ',' + ROLE.SYSTEMSTAFF,
        },
        {
            title: 'Employees',
            htmlBefore: '<span class="material-icons">groups</span>',
            to: '/employees/all',
            subNav: [
                { id: 1, title: 'Local Staff', to: '/employees/local-staff' },
                { id: 2, title: 'Work Permit', to: '/employees/work-permit' },
                { id: 3, title: 'S- Pass', to: '/employees/s-pass' },
                { id: 4, title: 'EP', to: '/employees/ep' },
            ],
            role: ROLE.COMPANY + ',' + ROLE.COMPANYSTAFF + ',' + ROLE.USER,
        },
        {
            title: 'My Information',
            htmlBefore: '<span class="material-icons">account_box</span>',
            to: '/my-information',
            role: ROLE.USER,
        },
        {
            title: 'Certificates',
            htmlBefore: '<span class="material-icons">business</span>',
            to: '/certificate',
            role: ROLE.COMPANY + ',' + ROLE.USER + ',' + ROLE.COMPANYSTAFF,
        },
        {
            title: 'Training',
            htmlBefore: '<span class="material-icons">school</span>',
            to: '/Training',
            role: ROLE.COMPANY + ',' + ROLE.USER + ',' + ROLE.COMPANYSTAFF,
        },
        {
            title: 'Leave',
            htmlBefore: '<span class="material-icons">offline_bolt</span>',
            to: '/leave',
            role: ROLE.COMPANY + ',' + ROLE.COMPANYSTAFF + ',' + ROLE.USER,
        },
        {
            title: 'Expense claim',
            htmlBefore: '<span class="material-icons">ballot</span>',
            to: '/expense-claim',
            role: ROLE.COMPANY + ',' + ROLE.COMPANYSTAFF + ',' + ROLE.USER,
        },
        {
            title: 'Attendance',
            htmlBefore: '<span class="material-icons">fact_check</span>',
            to: '/attendance',
            role: ROLE.COMPANY + ',' + ROLE.COMPANYSTAFF + ',' + ROLE.USER,
        },
        {
            title: 'My Payroll',
            htmlBefore: '<span class="material-icons">monetization_on</span>',
            to: '/my-payroll',
            role: ROLE.USER,
        },
        {
            title: 'Payroll',
            htmlBefore: '<span class="material-icons">monetization_on</span>',
            to: '/payroll/all',
            subNav: [
                { id: 1, title: 'Local Staff', to: '/payroll/local-staff' },
                { id: 2, title: 'Work Permit', to: '/payroll/work-permit' },
                { id: 3, title: 'S- Pass', to: '/payroll/s-pass' },
                { id: 4, title: 'EP', to: '/payroll/ep' },
            ],
            role: ROLE.COMPANY + ',' + ROLE.COMPANYSTAFF + ',' + ROLE.USER,
        },

        {
            title: 'Departments',
            htmlBefore: '<span class="material-icons">location_pin</span>',
            to: '/departments',
            role: ROLE.COMPANY + ',' + ROLE.COMPANYSTAFF + ',' + ROLE.USER,
        },
        {
            title: 'Company Settings',
            htmlBefore: '<span class="material-icons">supervisor_account</span>',
            to: '/employers/infomation',
            subNav: [
                { id: 1, title: 'Information', to: '/employers/infomation' },
                {
                    id: 2,
                    title: 'Job Positions',
                    to: '/employers/job-positions',
                },
                {
                    id: 3,
                    title: 'Leave Settings',
                    to: '/employers/leave-settings',
                },
                {
                    id: 4,
                    title: 'Attendance settings',
                    to: '/employers/attendance-settings',
                },
                {
                    id: 5,
                    title: 'Expense Approval',
                    to: '/employers/expense-approval',
                },
                {
                    id: 6,
                    title: 'Role & permissions',
                    to: '/employers/role-permissions',
                },
                {
                    id: 7,
                    title: 'Payroll settings',
                    to: '/employers/payroll-settings',
                },
                {
                    id: 8,
                    title: 'Permanent Residents Setting',
                    to: '/employers/permanent-residents-settings',
                },
            ],
            role: ROLE.COMPANY + ',' + ROLE.COMPANYSTAFF,
        },
        {
            title: 'Controls',
            htmlBefore: '<span class="material-icons">feed</span>',
            to: '/controls',
            role: ROLE.COMPANY + ',' + ROLE.COMPANYSTAFF,
        },

        {
            title: 'Public Holiday',
            htmlBefore: '<span class="material-icons">calendar_month</span>',
            to: '/public-holiday',
            role: ROLE.COMPANY + ',' + ROLE.COMPANYSTAFF,
        },
        // {
        //     title: 'Notice Templates',
        //     htmlBefore: '<span class="material-icons">chat</span>',
        //     to: '/notice-template',
        //     role: ROLE.COMPANY + ',' + ROLE.COMPANYSTAFF,
        // },

        {
            title: 'Messenger',
            htmlBefore: '<span class="material-icons">chat</span>',
            to: '/notice',
            role: ROLE.ADMIN + ',' + ROLE.COMPANY + ',' + ROLE.COMPANYSTAFF + ',' + ROLE.USER,
        },
        {
            title: 'Set Work Day',
            htmlBefore: '<span class="material-icons">fact_check</span>',
            to: '/workdays',
            role: ROLE.COMPANY + ',' + ROLE.COMPANYSTAFF + ',' + ROLE.USER,
        },
        {
            title: 'Helps',
            htmlBefore: '<span class="material-icons">help_center</span>',
            to: '/helps',
            role: ROLE.COMPANY + ',' + ROLE.COMPANYSTAFF + ',' + ROLE.USER,
        },
    ];
}
