export const covertMonth = (e) => {
    let a = '';
    switch (e) {
        case 0:
            a = 'January';
            break;
        case 1:
            a = 'February';
            break;
        case 2:
            a = 'March';
            break;
        case 3:
            a = 'April';
            break;
        case 4:
            a = 'May';
            break;
        case 5:
            a = 'June';
            break;
        case 6:
            a = 'July';
            break;
        case 7:
            a = 'August';
            break;
        case 8:
            a = 'September';
            break;
        case 9:
            a = 'October';
            break;
        case 10:
            a = 'November';
            break;
        case 11:
            a = 'December';
            break;

        default:
            break;
    }
    return a
};