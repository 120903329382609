import { Checkbox, Divider, Space, Switch, Table } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Row } from 'shards-react';
import { jobPositionAPI } from '../../../apis/jobPositionAPI';
import { ROLE } from '../../../helpers/const';
import { useUser } from '../../../hooks';

function CEO({ item }) {
    const [payload, setPayload] = useState();

    useEffect(() => {
        setPayload(item);
    }, [item]);
    const { user, getMe } = useUser();

    useEffect(() => {
        if (!user) {
            getMe();
        }
    }, [user]);

    const columns = [
        { title: '', dataIndex: 'name', key: 'name' },
        {
            title: 'Read',
            dataIndex: 'read',
            key: 'read',
            render: (_, record) => (
                <Space size="middle">
                    <Checkbox
                        className="ct-checkbox"
                        onChange={() => {
                            if (user && user.role && (user.role === ROLE.USER || user.role === ROLE.COMPANYSTAFF)) {
                                toast.error('You are not authorized to update');
                                return;
                            }
                            if (user && user.role && user.role === ROLE.COMPANY) {
                                handleCheck(record, '1');
                            }
                        }}
                        checked={
                            (payload && payload.employee && payload.employee.includes('1') && record.key === '1') ||
                            (payload && payload.leave && payload.leave.includes('1') && record.key === '2') ||
                            (payload && payload.attendance && payload.attendance.includes('1') && record.key === '3')
                        }
                        //disabled={user && user.role && user.role !== ROLE.COMPANY ? true : false}
                    />
                </Space>
            ),
        },
        {
            title: 'Write',
            dataIndex: 'write',
            key: 'write',
            render: (_, record) => (
                <Space size="middle">
                    <Checkbox
                        className="ct-checkbox"
                        onChange={() => {
                            if (user && user.role && (user.role === ROLE.USER || user.role === ROLE.COMPANYSTAFF)) {
                                toast.error('You are not authorized to update');
                                return;
                            }
                            if (user && user.role && user.role === ROLE.COMPANY) {
                                handleCheck(record, '2');
                            }
                        }}
                        checked={
                            (payload && payload.employee && payload.employee.includes('2') && record.key === '1') ||
                            (payload && payload.leave && payload.leave.includes('2') && record.key === '2') ||
                            (payload && payload.attendance && payload.attendance.includes('2') && record.key === '3')
                        }
                    />
                </Space>
            ),
        },
        {
            title: 'Create',
            key: 'create',
            dataIndex: 'create',
            render: (_, record) => (
                <Space size="middle">
                    <Checkbox
                        className="ct-checkbox"
                        onChange={() => {
                            if (user && user.role && (user.role === ROLE.USER || user.role === ROLE.COMPANYSTAFF)) {
                                toast.error('You are not authorized to update');
                                return;
                            }
                            if (user && user.role && user.role === ROLE.COMPANY) {
                                handleCheck(record, '3');
                            }
                        }}
                        checked={
                            (payload && payload.employee && payload.employee.includes('3') && record.key === '1') ||
                            (payload && payload.leave && payload.leave.includes('3') && record.key === '2') ||
                            (payload && payload.attendance && payload.attendance.includes('3') && record.key === '3')
                        }
                    />
                </Space>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (_, record) => (
                <Space size="middle">
                    <Checkbox
                        className="ct-checkbox"
                        onChange={() => {
                            if (user && user.role && (user.role === ROLE.USER || user.role === ROLE.COMPANYSTAFF)) {
                                toast.error('You are not authorized to update');
                                return;
                            }
                            if (user && user.role && user.role === ROLE.COMPANY) {
                                handleCheck(record, '4');
                            }
                        }}
                        checked={
                            (payload && payload.employee && payload.employee.includes('4') && record.key === '1') ||
                            (payload && payload.leave && payload.leave.includes('4') && record.key === '2') ||
                            (payload && payload.attendance && payload.attendance.includes('4') && record.key === '3')
                        }
                    />
                </Space>
            ),
        },
        {
            title: 'Import',
            dataIndex: 'import',
            key: 'import',
            render: (_, record) => (
                <Space size="middle">
                    <Checkbox
                        className="ct-checkbox"
                        onChange={() => {
                            if (user && user.role && (user.role === ROLE.USER || user.role === ROLE.COMPANYSTAFF)) {
                                toast.error('You are not authorized to update');
                                return;
                            }
                            if (user && user.role && user.role === ROLE.COMPANY) {
                                handleCheck(record, '5');
                            }
                        }}
                        checked={
                            (payload && payload.employee && payload.employee.includes('5') && record.key === '1') ||
                            (payload && payload.leave && payload.leave.includes('5') && record.key === '2') ||
                            (payload && payload.attendance && payload.attendance.includes('5') && record.key === '3')
                        }
                    />
                </Space>
            ),
        },
        {
            title: 'Export',
            key: 'export',
            dataIndex: 'export',
            render: (_, record) => (
                <Space size="middle">
                    <Checkbox
                        className="ct-checkbox"
                        onChange={() => {
                            if (user && user.role && (user.role === ROLE.USER || user.role === ROLE.COMPANYSTAFF)) {
                                toast.error('You are not authorized to update');
                                return;
                            }
                            if (user && user.role && user.role === ROLE.COMPANY) {
                                handleCheck(record, '6');
                            }
                        }}
                        checked={
                            (payload && payload.employee && payload.employee.includes('6') && record.key === '1') ||
                            (payload && payload.leave && payload.leave.includes('6') && record.key === '2') ||
                            (payload && payload.attendance && payload.attendance.includes('6') && record.key === '3')
                        }
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            name: 'Employee',
        },
        {
            key: '2',
            name: 'Leave',
        },
        {
            key: '3',
            name: 'Attendance',
        },
    ];

    const TableMB = ({ tableData, headingColumns, title, breakOn = 'medium' }) => {
        let tableClass = 'table-container__table';

        if (breakOn === 'small') {
            tableClass += ' table-container__table--break-sm';
        } else if (breakOn === 'medium') {
            tableClass += ' table-container__table--break-md';
        } else if (breakOn === 'large') {
            tableClass += ' table-container__table--break-lg';
        }

        const data = tableData.map((row, index) => {
            let rowData = [];
            let i = 0;

            for (const key in row) {
                rowData.push({
                    key: headingColumns[i],
                    val: row[key],
                });
                i++;
            }

            return (
                <tr key={index}>
                    {rowData.map((data, index) => (
                        <td key={index} data-heading={data.key}>
                            {data.val}
                        </td>
                    ))}
                </tr>
            );
        });

        return (
            <div className="table-container">
                <div className="table-container__title">
                    <h2 className="title-font">{title}</h2>
                </div>
                <table className={tableClass}>
                    <thead>
                        <tr>
                            {headingColumns.map((col, index) => (
                                <th key={index}>{col}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>{data}</tbody>
                </table>
            </div>
        );
    };

    TableMB.propTypes = {
        tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
        headingColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
        title: PropTypes.string.isRequired,
        breakOn: PropTypes.oneOf(['small', 'medium', 'large']),
    };

    const handleCheck = async (record, str) => {
        const array = [1, 2, 3, 4, 5, 6];

        if (record.key === '1' && payload && payload.employee) {
            if (payload.employee.includes(str)) {
                let arr = [];

                if (str !== '5' && str !== '6') {
                    const imp = payload.employee.includes('5');
                    const exp = payload.employee.includes('6');
                    arr = payload.employee.filter((item) => item < str);
                    imp && arr.push('5');
                    exp && arr.push('6');
                } else {
                    arr = payload.employee.filter((item) => item !== str);
                }

                payload.employee = arr;
            } else {
                let arr = [];
                let arr2 = [];
                if (str !== '5' && str !== '6') {
                    const imp = payload.employee.includes('5');
                    const exp = payload.employee.includes('6');
                    arr = array.filter((item) => item <= str);

                    arr2 = arr.map((item) => item.toString());
                    imp && arr2.push('5');
                    exp && arr2.push('6');
                    payload.employee = arr2;
                } else {
                    payload.employee.push(str);
                }
            }
        }

        if (record.key === '2' && payload && payload.leave) {
            if (payload.leave.includes(str)) {
                let arr = [];

                if (str !== '5' && str !== '6') {
                    const imp = payload.leave.includes('5');
                    const exp = payload.leave.includes('6');
                    arr = payload.leave.filter((item) => item < str);
                    imp && arr.push('5');
                    exp && arr.push('6');
                } else {
                    arr = payload.leave.filter((item) => item !== str);
                }

                payload.leave = arr;
            } else {
                let arr = [];
                let arr2 = [];
                if (str !== '5' && str !== '6') {
                    const imp = payload.leave.includes('5');
                    const exp = payload.leave.includes('6');
                    arr = array.filter((item) => item <= str);

                    arr2 = arr.map((item) => item.toString());
                    imp && arr2.push('5');
                    exp && arr2.push('6');
                    payload.leave = arr2;
                } else {
                    payload.leave.push(str);
                }
            }
        }

        if (record.key === '3' && payload && payload.attendance) {
            if (payload.attendance.includes(str)) {
                let arr = [];

                if (str !== '5' && str !== '6') {
                    const imp = payload.attendance.includes('5');
                    const exp = payload.attendance.includes('6');
                    arr = payload.attendance.filter((item) => item < str);
                    imp && arr.push('5');
                    exp && arr.push('6');
                } else {
                    arr = payload.attendance.filter((item) => item !== str);
                }

                payload.attendance = arr;
            } else {
                let arr = [];
                let arr2 = [];
                if (str !== '5' && str !== '6') {
                    const imp = payload.attendance.includes('5');
                    const exp = payload.attendance.includes('6');
                    arr = array.filter((item) => item <= str);

                    arr2 = arr.map((item) => item.toString());
                    imp && arr2.push('5');
                    exp && arr2.push('6');
                    payload.attendance = arr2;
                } else {
                    payload.attendance.push(str);
                }
            }
        }

        setPayload({ ...payload });
    };

    const handleSwitch = async (str) => {
        payload && payload[str] && payload[str].length === 6
            ? setPayload({ ...payload, [str]: [] })
            : setPayload({ ...payload, [str]: ['1', '2', '3', '4', '5', '6'] });
    };

    const handleSubmit = async () => {
        try {
            await jobPositionAPI.update(payload._id, payload);
            toast.success('Update successful');
        } catch (error) {
            const message = error.response.data.message;
            if (message && typeof message === 'string') {
                toast.error(<p className="text-capitalize mt-3">{message}</p>);
            } else if (message && typeof message === 'object') {
                toast.error(<p className="text-capitalize mt-3">{message[0]}</p>);
            }
        }
    };

    return (
        <>
            <Row>
                <Col>
                    <h5 className="title-font">MODULE ACCESS</h5>
                    <ul className="list_admin">
                        <li>
                            <span>Employee</span>
                            <Switch
                                checked={payload && payload.employee && payload.employee.length === 6}
                                onChange={(e) => {
                                    if (
                                        user &&
                                        user.role &&
                                        (user.role === ROLE.USER || user.role === ROLE.COMPANYSTAFF)
                                    ) {
                                        toast.error('You are not authorized to update');
                                        return;
                                    }
                                    if (user && user.role && user.role === ROLE.COMPANY) {
                                        handleSwitch('employee');
                                    }
                                }}
                            />
                        </li>
                        <li>
                            <span>Expense Claim</span>
                            <Switch
                                checked={payload && payload.expenseClaim && payload.expenseClaim.length === 6}
                                onChange={(e) => {
                                    if (
                                        user &&
                                        user.role &&
                                        (user.role === ROLE.USER || user.role === ROLE.COMPANYSTAFF)
                                    ) {
                                        toast.error('You are not authorized to update');
                                        return;
                                    }
                                    if (user && user.role && user.role === ROLE.COMPANY) {
                                        handleSwitch('expenseClaim');
                                    }
                                }}
                            />
                        </li>
                        <li>
                            <span>Leave</span>
                            <Switch
                                checked={payload && payload.leave && payload.leave.length === 6}
                                onChange={(e) => {
                                    if (
                                        user &&
                                        user.role &&
                                        (user.role === ROLE.USER || user.role === ROLE.COMPANYSTAFF)
                                    ) {
                                        toast.error('You are not authorized to update');
                                        return;
                                    }
                                    if (user && user.role && user.role === ROLE.COMPANY) {
                                        handleSwitch('leave');
                                    }
                                }}
                            />
                        </li>
                        <li>
                            <span>Attendance</span>
                            <Switch
                                checked={payload && payload.attendance && payload.attendance.length === 6}
                                onChange={(e) => {
                                    if (
                                        user &&
                                        user.role &&
                                        (user.role === ROLE.USER || user.role === ROLE.COMPANYSTAFF)
                                    ) {
                                        toast.error('You are not authorized to update');
                                        return;
                                    }
                                    if (user && user.role && user.role === ROLE.COMPANY) {
                                        handleSwitch('attendance');
                                    }
                                }}
                            />
                        </li>
                        <li>
                            <span>Payroll</span>
                            <Switch
                                checked={payload && payload.payroll && payload.payroll.length === 6}
                                onChange={(e) => {
                                    if (
                                        user &&
                                        user.role &&
                                        (user.role === ROLE.USER || user.role === ROLE.COMPANYSTAFF)
                                    ) {
                                        toast.error('You are not authorized to update');
                                        return;
                                    }
                                    if (user && user.role && user.role === ROLE.COMPANY) {
                                        handleSwitch('payroll');
                                    }
                                }}
                            />
                        </li>
                    </ul>
                </Col>
            </Row>
            <Divider style={{ borderTop: '1px solid rgb(0 0 0 / 24%)' }} />
            <Row>
                <Col>
                    <h5 className="title-font">MODULE PERMISSION</h5>

                    <Table columns={columns} dataSource={data} pagination={false} />
                    {user && user.role && user.role === ROLE.COMPANY && (
                        <div className="mt-4">
                            <button
                                onClick={handleSubmit}
                                className="ct_btn px-4 next none-border btn-middle"
                                id="save"
                            >
                                <span>Save</span>
                            </button>
                        </div>
                    )}
                </Col>
            </Row>
        </>
    );
}

export default CEO;
