import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Alert, Button, Form, Input, Modal } from 'antd';
import { signOut } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Dropdown, DropdownItem, DropdownMenu, FormInput } from 'shards-react';
import { employeesAPI } from '../../../../apis/employeesAPI';
import { ROLE } from '../../../../helpers/const';
import { jwtManager } from '../../../../helpers/jwtManager';
import { useEmployee, useUser } from '../../../../hooks';
import AVT from '../../../../images/avatars/1.jpg';
import { auth } from '../../../../views/Login/firebase';
import { userAPI } from './../../../../apis/userAPI';

import { Select } from 'antd';
import NoticeCreateForm from '../../../notice/NoticeCreateForm';
import { companiesAPI } from '../../../../apis/companiesAPI';

export default function UserActions() {
    const initAccount = {
        newPassword: '',
        confirmNewPassword: '',
    };
    const [visible, setVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [nameAction, setNameAction] = useState();
    const [account, setAccount] = useState(initAccount);
    const [imgAvatar, setImgAvatar] = useState('');
    const [position, setPosition] = useState('');

    const [isModalVisibleSystem, setIsModalVisibleSystem] = useState(false);
    const [isModalVisibleStaff, setIsModalVisibleStaff] = useState(false);

    const [systemStaff, setSystemStaff] = useState();
    const [companyStaff, setCompanyStaff] = useState();
    const [employeesDB, setEmployeesDB] = useState();
    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState();
    const [visibleCreateNotice, setVisibleCreateNotice] = useState(false);

    const { user } = useUser();

    const history = useHistory();

    const role = user.role;

    const { employees } = useEmployee();

    const setName = () => {
        if (user.avatar === undefined) {
            setImgAvatar(AVT);
        } else {
            setImgAvatar(user.avatar);
        }

        if (user && user.company && role === ROLE.COMPANY) {
            setNameAction(user.company.companyName);
            setPosition('Client super admin');
        } else if (user && role === ROLE.ADMIN) {
            setNameAction(user.firstName);
            setPosition('Software admin');
        } else if (user && user.employee && (role === ROLE.USER || role === ROLE.COMPANYSTAFF)) {
            setNameAction(user.employee.firstName);
            role === ROLE.USER ? setPosition('Client staff') : setPosition('Client HR');
        } else if (user && user.role === ROLE.SYSTEMSTAFF) {
            setNameAction(user.firstName);
            setPosition('Software staff');
        }
    };

    useEffect(() => {
        if (user) {
            setName();
        }
    }, [user]);

    const logout = async () => {
        await signOut(auth);
        jwtManager.clear();
        toast.success('Logout successfully!');
        history.push('/login');
    };

    const toggleUserActions = () => {
        setVisible(!visible);
    };

    const showModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    const handleOk = () => {
        setIsModalVisible(!isModalVisible);
    };

    const handleCancel = () => {
        setIsModalVisible(!isModalVisible);
    };

    const onFinishFailed = (errorInfo) => {};

    const handleSubmit = () => {
        const confirm = document.getElementById('confirmPassword').value;
        if (confirm !== account.newPassword) {
            toast.error('Your confirm password not match password field!');
        } else if (account.newPassword.length < 4) {
            toast.error('Password must be more than 4 characters!');
        } else {
            onFinish(account);
        }
    };

    const onFinish = async (values) => {
        try {
            const TOKEN = localStorage.getItem('token');
            await userAPI.resetPassword({
                token: TOKEN,
                userId: user._id,
                password: values.newPassword,
                confirmPassword: values.confirmNewPassword,
            });
            toast.success('Change password successfully');
            history.push('/login');
        } catch (error) {
            console.log(error);
            toast.error('ERR!');
        }
    };

    const showModalSystem = () => {
        setIsModalVisibleSystem(!isModalVisibleSystem);
    };

    const handleCancelSystem = () => {
        setIsModalVisibleSystem(false);
    };

    const handleOkSystem = () => {
        setIsModalVisibleSystem(!isModalVisibleSystem);
    };

    const handleSubmitSystem = async () => {
        try {
            if (!systemStaff || (systemStaff && systemStaff.email && systemStaff.email.length < 2)) {
                toast.error('Email must be more than 2 characters!');
            } else if (systemStaff && systemStaff.email && systemStaff.email.length > 100) {
                toast.error('Email must be less than 100 characters!');
            } else {
                const result = await userAPI.addSystemStaff({ email: systemStaff.email });
                if (result) {
                    toast.success('Add success');
                } else {
                    toast.error('Add fail');
                }
                history.push('/');
            }
        } catch (error) {
            const message = error.response.data.message;
            if (message && typeof message === 'string') {
                toast.error(<p className="text-capitalize mt-3">{message}</p>);
            } else if (message && typeof message === 'object') {
                toast.error(<p className="text-capitalize mt-3">{message[0]}</p>);
            }
        }
    };

    const showModalStaff = () => {
        setIsModalVisibleStaff(!isModalVisibleStaff);
    };

    const handleCancelStaff = () => {
        setIsModalVisibleStaff(false);
        setCompany();
        setEmployeesDB([]);
        setCompanyStaff();
    };

    const handleOkStaff = () => {
        setIsModalVisibleStaff(!isModalVisibleStaff);
        setCompany();
        setEmployeesDB([]);
        setCompanyStaff();
    };

    const getEmployees = async (id) => {
        const { data } = await employeesAPI.chooseStaff(id);
        setEmployeesDB(data);
    };

    useEffect(() => {
        setCompanyStaff();
        company && getEmployees(company);
    }, [company]);

    const handleSubmitStaff = async () => {
        if (companyStaff) {
            const result = await userAPI.addCompanyStaff({ id: companyStaff });
            if (result) {
                toast.success('Add successful');
            } else {
                toast.error('Add fail');
            }
            setCompany();
            setEmployeesDB([]);
            setCompanyStaff();
            history.push('/');
        } else {
            toast.error('Please choose one employee');
        }
    };

    const showCreateNotice = async () => {
        setVisibleCreateNotice(true);
    };

    useEffect(() => {
        isModalVisibleStaff && fetchCompanies();
    }, [isModalVisibleStaff]);

    const fetchCompanies = async () => {
        const { data } = await companiesAPI.getAll();
        if (data && data.length > 0) {
            setCompanies(data);
        }
    };
    return (
        <>
            <Dropdown open={visible} toggle={toggleUserActions}>
                <div
                    onClick={() => toggleUserActions()}
                    them="none"
                    caret="true"
                    className="text-nowrap px-3 btn_user-action"
                >
                    <div className="content_name">
                        <p className="d-none d-md-inline-block custom_username">{nameAction}</p>
                        <span>{position ? position : 'Position'}</span>
                    </div>
                    <img className="user_avt" src={imgAvatar} alt="Avt" /> <ArrowDropDownIcon />
                </div>
                <DropdownMenu className="dropdown-user">
                    <DropdownItem to="user-profile">
                        <Link to="/profile" className="fix-blue-color">
                            <i className="material-icons">&#xE7FD;</i> Profile
                        </Link>
                    </DropdownItem>
                    <DropdownItem to="user-edit-profile" className="fix-blue-color" onClick={showModal}>
                        <i className="material-icons">&#xE8B8;</i> Change Password
                    </DropdownItem>

                    {user && user.role && user.role === ROLE.ADMIN && (
                        <DropdownItem to="" onClick={showModalSystem}>
                            <i className="material-icons">&#xe7fe;</i> Add Software staff
                        </DropdownItem>
                    )}
                    {user && user.role && user.role === ROLE.ADMIN && (
                        <DropdownItem to="" onClick={showModalStaff}>
                            <i className="material-icons">&#xe7fe;</i> Add Client HR
                        </DropdownItem>
                    )}

                    {/* <DropdownItem to="" onClick={showCreateNotice}>
                        <i className="material-icons">&#xe163;</i> Send Notice
                    </DropdownItem> */}

                    {user && user.role && (user.role === ROLE.COMPANY || user.role === ROLE.COMPANYSTAFF) && (
                        <DropdownItem to="file-manager-list">
                            <Link to="/files-management" className="fix-blue-color">
                                <i className="material-icons">&#xE2C7;</i> Files
                            </Link>
                        </DropdownItem>
                    )}

                    {/* <DropdownItem to="transaction-history">
                        <i className="material-icons">&#xE896;</i> Transactions
                    </DropdownItem> */}
                    <DropdownItem divider />

                    <DropdownItem to="/" className="text-danger" onClick={logout}>
                        <i className="material-icons text-danger">&#xE879;</i> Logout
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>

            <Modal title="Change Password" open={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={false}>
                <Alert message="Enter your new password below!" type="info" showIcon />
                <br />
                <Form
                    layout=" vertical"
                    name="basic"
                    wrapperCol={{
                        span: 24,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    // onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="New Password"
                        name="newpass"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password
                            value={account.newPassword}
                            onChange={(e) =>
                                setAccount({
                                    ...account,
                                    newPassword: e.target.value,
                                })
                            }
                        />
                    </Form.Item>

                    <Form.Item
                        label="Confirm New Password"
                        name="cfnewpass"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password
                            id="confirmPassword"
                            value={account.confirmNewPassword}
                            onChange={(e) =>
                                setAccount({
                                    ...account,
                                    confirmNewPassword: e.target.value,
                                })
                            }
                        />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit" onClick={handleSubmit}>
                            Reset Password
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="Add new Software staff"
                open={isModalVisibleSystem}
                onOk={handleOkSystem}
                onCancel={handleCancelSystem}
                footer={false}
            >
                <Form
                    layout=" vertical"
                    name="basic"
                    wrapperCol={{
                        span: 24,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <FormInput
                        label="Address email"
                        name=""
                        type="email"
                        placeholder="Email Address"
                        autoComplete="email"
                        value={systemStaff && systemStaff.email && systemStaff.email}
                        onChange={(e) => setSystemStaff({ email: e.target.value })}
                        rules={[
                            {
                                required: true,
                                message: 'Please input email!',
                            },
                        ]}
                    ></FormInput>

                    <div className="mt-5">
                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit" onClick={handleSubmitSystem}>
                                Add System staff
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </Modal>

            <Modal
                title="Add new Client HR"
                open={isModalVisibleStaff}
                onOk={handleOkStaff}
                onCancel={handleCancelStaff}
                footer={false}
                zIndex={99}
            >
                <Form
                    layout=" vertical"
                    name="basic"
                    wrapperCol={{
                        span: 24,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        name="company"
                        label="Company"
                        rules={[
                            {
                                required: true,
                                message: 'Please choose Company!',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            id="company"
                            style={{ width: '100%', zIndex: 999 }}
                            value={company}
                            onChange={(e) => setCompany(e)}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {companies &&
                                companies.map((item) => (
                                    <Select.Option key={item._id} value={item._id}>
                                        {item.companyName}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="clientStaff"
                        label="Client Staff"
                        rules={[
                            {
                                required: true,
                                message: 'Please choose Client Staff!',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            id="clientStaff"
                            style={{ width: '100%', zIndex: 999 }}
                            value={companyStaff}
                            onChange={(e) => setCompanyStaff(e)}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {employeesDB &&
                                employeesDB.map((item) => (
                                    <Select.Option key={item._id} value={item.user._id}>
                                        {item.firstName}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                    <div className="mt-5">
                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit" onClick={handleSubmitStaff}>
                                Add Client HR
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </Modal>

            <NoticeCreateForm visible={visibleCreateNotice} setVisible={setVisibleCreateNotice} />
        </>
    );
}
