import { createContext } from "react";
import { LEAVE, LEAVE_FILTER } from "../global/leave";
import { RESPONSE } from "../helpers/response";

const initValue = {
  leaves: RESPONSE,
  leavesPending: RESPONSE,
  filterLeave: LEAVE_FILTER,
  getLeaveById: (id) => { return LEAVE },
  setFilterLeave: (filterLeave = LEAVE_FILTER) => { },
  fetchLeaves: (filter = LEAVE_FILTER) => { }
}

export const LeaveContext = createContext(initValue);
