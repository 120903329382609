import '../../assets/pay-roll.css';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody } from 'shards-react';
import { Modal, Form, Input, Avatar, Image, Radio, Button, Table } from 'antd';
import PageTitle from './../common/PageTitle';
import { payRollAPI } from '../../apis/payRollAPI';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import html2pdf from 'html2pdf.js';
import { CSVLink } from 'react-csv';
import { useUser } from '../../hooks';
import { EMPLOYEES_TYPE_VALUE, ROLE } from '../../helpers/const';
import { userAPI } from '../../apis/userAPI';
import { covertMonth } from '../../helpers/covertMonth';
import { covertDate } from '../../helpers/covertDate';

const CollectionCreateForm = ({ visible, onCreate, onCancel }) => {
    const { TextArea } = Input;
    const [form] = Form.useForm();

    return (
        <Modal
            open={visible}
            title="Leave request"
            okText="Submit"
            cancelText="Cancel"
            onCancel={onCancel}
            width="700px"
            height="500px"
            onOk={() => {
                // footer={null}
                form.validateFields()
                    .then((values) => {
                        form.resetFields();
                        onCreate(values);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{ modifier: 'public' }}
                zIndex={999}
            >
                <Row lg="12" md="8" sm="6">
                    <Col lg="2">
                        <Avatar
                            shape="square"
                            size={64}
                            src={
                                <Image
                                    width={60}
                                    src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                                />
                            }
                        />
                    </Col>
                    <Col lg="9" className="ml-2">
                        <div className="title_name">
                            <h5>#1015 - Leo Gouse</h5>
                            <p>Sr. Ux designer</p>
                        </div>
                    </Col>
                </Row>
                <Row lg="12" md="8" sm="6">
                    <Col lg="12">
                        <Form.Item
                            name="type"
                            label="Leave type"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the name of collection!',
                                },
                            ]}
                        >
                            <Input type="text" placeholder="example" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row lg="12" md="8" sm="6">
                    <Col lg="6">
                        <Form.Item
                            name="from"
                            label="From"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the name of collection!',
                                },
                            ]}
                        >
                            <Input type="text" placeholder="example" />
                        </Form.Item>
                    </Col>
                    <Col lg="6">
                        <Form.Item
                            name="to"
                            label="To"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the name of collection!',
                                },
                            ]}
                        >
                            <Input type="text" placeholder="example" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row lg="12" md="8" sm="6">
                    <Col lg="12">
                        <Form.Item
                            name="reason"
                            label="Reason here"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the name of collection!',
                                },
                            ]}
                        >
                            <Input type="text" placeholder="example" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row form>
                    <Col md="4" className="form-group">
                        <Form.Item
                            label="Status"
                            name="status"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your username!',
                                },
                            ]}
                            layout="vertical"
                        >
                            <Radio.Group>
                                <Radio value={1}>Approve</Radio>
                                <Radio value={2}>Reject</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row lg="12" md="8" sm="6">
                    <Col lg="12">
                        <Form.Item
                            name="reason"
                            label="Reason here"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the name of collection!',
                                },
                            ]}
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

function NewPaySlip() {
    const { id } = useParams();
    const [visible, setVisible] = useState(false);
    const [paySlip, setPaySlip] = useState([]);
    const [company, setCompany] = useState([]);
    const [infoCompany, setInfoCompany] = useState();
    const [employee, setEmployee] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const { user, getMe } = useUser();
    const [earningList, setEarningList] = useState([]);
    const [deductList, setDeductList] = useState([]);
    const [cdf, setCdf] = useState(0);

    const SGDDollar = new Intl.NumberFormat('en-SG', { style: 'currency', currency: 'SGD' });

    useEffect(() => {
        (async () => {
            if (!user || !user._id) {
                await getMe();
            }
        })();
    }, [user]);

    useEffect(() => {
        if (deductList && deductList.length > 0) {
            deductList.map((item) => {
                if (item && item.title === 'CPF') {
                    setCdf(item.amount);
                }
            });
        }
    }, [deductList]);

    useEffect(() => {
        (async () => {
            const { data } = await payRollAPI.getById(id);
            if (data) {
                setPaySlip(data[0]);
                setEmployee(data[0].employee);
                setCompany(data[0].employee.company);
                const { data: info } = await userAPI.findByEmail(data[0].employee.company.email);
                setInfoCompany(info);
                setDeductList(data[0].deductions);
                setEarningList(data[0].earning);
                // let n = 0;
                // if (data[0].earning.length > data[0].deductions.length) {
                //     n = data[0].earning.length - data[0].deductions.length;

                //     let arr = [];
                //     arr = [...data[0].deductions];
                //     for (let i = 0; i < n; i++) {
                //         arr = [...arr, { title: '', amount: '' }];
                //     }
                //     setEarningList(data[0].earning);
                //     setDeductList(arr);
                // } else if (data[0].earning.length < data[0].deductions.length) {
                //     n = data[0].deductions.length - data[0].earning.length;
                //     let arr = [];
                //     arr = [...data[0].earning];
                //     for (let i = 0; i < n; i++) {
                //         arr = [...arr, { title: '', amount: '' }];
                //     }
                //     setDeductList(data[0].deductions);
                //     setEarningList(arr);
                // } else {
                //     setDeductList(data[0].deductions);
                //     setEarningList(data[0].earning);
                // }
            }
        })();
    }, [id]);

    const onCreate = (values) => {
        setVisible(false);
    };

    const calculatorMoney = (data) => {
        let money = 0;
        if (data) {
            data.map((item) => {
                money += item.amount * 1;
            });
        }
        return money;
    };

    // ==== data table ear

    const columns = [
        {
            title: '#',
            dataIndex: 'key',
            render: (_, row, i) => {
                return i + 1;
            },
        },
        { title: 'Earning', dataIndex: 'title' },
        { title: 'Amount', dataIndex: 'amount', align: 'right', render: (amount) => <p>${amount}</p> },
    ];

    const TotalEar = () => {
        return (
            <div className="gr_total d-flex justify-content-between align-items-center">
                <h5>Total Earnings</h5>
                <p>${paySlip && paySlip.earning && calculatorMoney(paySlip.earning)}</p>
            </div>
        );
    };

    // ==== data table detructions

    const columns_ = [
        {
            title: '#',
            dataIndex: 'key',
            render: (_, row, i) => {
                return i + 1;
            },
        },
        { title: 'Deductions', dataIndex: 'title' },
        { title: 'Amount', dataIndex: 'amount', align: 'right', render: (amount) => <p>${amount}</p> },
    ];

    const TotalDe = () => {
        return (
            <div className="gr_total d-flex justify-content-between align-items-center">
                <h5>Total Deductions</h5>
                <p>${paySlip && paySlip.deductions && calculatorMoney(paySlip.deductions)}</p>
            </div>
        );
    };

    const convertNumbertoText = (num = 0) => {
        var a = [
            '',
            'one ',
            'two ',
            'three ',
            'four ',
            'five ',
            'six ',
            'seven ',
            'eight ',
            'nine ',
            'ten ',
            'eleven ',
            'twelve ',
            'thirteen ',
            'fourteen ',
            'fifteen ',
            'sixteen ',
            'seventeen ',
            'eighteen ',
            'nineteen ',
        ];
        var b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

        if ((num = num.toString()).length > 9) return 'overflow';
        var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        if (!n) return;
        var str = '';
        str += n[1] != 0 ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
        str += n[2] != 0 ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
        str += n[3] != 0 ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
        str += n[4] != 0 ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
        str += n[5] != 0 ? (str != '' ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only' : '';
        return str.replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
    };

    const listDiv = (array) => {
        let title = [];
        let amount = [];
        array.map((e, index) => title.push(`<div>${e.title}</div>`));
        array.map((e, index) =>
            amount.push(`<div>${e.amount ? SGDDollar.format(+e.amount) : SGDDollar.format(0)}</div>`),
        );
        const titleString = title.join('');
        const amountString = amount.join('');
        return { titleString, amountString };
    };

    const printPaySlip = () => {
        var divContents = document.getElementById('print');
        const earningListString = listDiv(paySlip.earning);
        const deductionsListString = listDiv(paySlip.deductions);
        if (divContents) {
            var a = window.open('', '', 'height=500, width=500');
            if (a) {
                a.document.write('<html>');
                a.document.write('<body>');
                a.document.write(`<div style="display: flex;flex-direction: column;align-items: center;">
                <div>${paySlip.employee.company.companyName ? paySlip.employee.company.companyName : '--'}</div>
                <div>Address: ${
                    paySlip.employee.company.address
                        ? paySlip.employee.company.address
                        : paySlip.employee.company.city
                        ? paySlip.employee.company.city
                        : '--'
                }</div>
                <div>Tel No: ${
                    paySlip.employee.company.phoneNumber
                        ? paySlip.employee.company.phoneNumber
                        : paySlip.employee.company.mobileNumber
                        ? paySlip.employee.company.mobileNumber
                        : '--'
                }</div>
            </div>
            <hr />
            <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-around;">
                <div style="display: flex;flex-direction: column;align-items: flex-start;">
                    <div>Name: ${paySlip.employee.firstName ? paySlip.employee.firstName : ''} ${
                    paySlip.employee.middleName ? paySlip.employee.middleName : ''
                } ${paySlip.employee.lastName ? paySlip.employee.lastName : ''}</div>
                    <div>Staff code: ${paySlip.employee.employeesId ? paySlip.employee.employeesId : '--'}</div>
                    <div>Job Title: ${paySlip.employee.jobPosition ? paySlip.employee.jobPosition : '--'}</div>
                    <div>${
                        paySlip.employee.type && paySlip.employee.type === EMPLOYEES_TYPE_VALUE.LOCALSTAFF
                            ? 'NRIC No.'
                            : 'FIN No.'
                    }${paySlip.employee.nationId ? paySlip.employee.nationId : '--'}</div>
                    ${
                        paySlip.employee.type === EMPLOYEES_TYPE_VALUE.WORKPERMIT ? (
                            <div>
                                <div>WP No. {paySlip.employee.wp ? paySlip.employee.wp : '--'}</div>
                            </div>
                        ) : (
                            ''
                        )
                    }
                    ${
                        paySlip.employee.type === EMPLOYEES_TYPE_VALUE.SPASS ? (
                            <div>
                                <div>SP No. {paySlip.employee.sp ? paySlip.employee.sp : '--'}</div>
                            </div>
                        ) : (
                            ''
                        )
                    }
                </div>
                <div style="display: flex;flex-direction: column;align-items: flex-start;">
                    <div> Payslip for: ${
                        paySlip.month
                            ? covertMonth(new Date(paySlip.month).getMonth())
                            : paySlip.effectiveDate
                            ? covertMonth(new Date(paySlip.effectiveDate).getMonth())
                            : '--'
                    }
                    ${
                        paySlip.month
                            ? new Date(paySlip.month).getFullYear()
                            : paySlip.effectiveDate
                            ? new Date(paySlip.effectiveDate).getFullYear()
                            : '--'
                    }
                    </div>
                    <div>Date of Payment</div>
                    <div> ${paySlip.monthlyRate ? 'Monthly Rate: ' : 'Daily Rate: '}
                    ${
                        paySlip.monthlyRate
                            ? SGDDollar.format(+paySlip.monthlyRate)
                            : paySlip.dailyRate
                            ? SGDDollar.format(+paySlip.dailyRate)
                            : SGDDollar.format(0)
                    }
                    </div>

                    <div>Hourly Rate: ${
                        paySlip.hourlyRate ? SGDDollar.format(+paySlip.hourlyRate) : SGDDollar.format(0)
                    }</div>
                    <div>Overtime Rate (1.5): ${
                        paySlip.overtimeRate ? SGDDollar.format(+paySlip.overtimeRate) : SGDDollar.format(0)
                    }</div>
                </div>
            </div>
            <hr />
            <div style="display: flex;flex-direction: row;align-items: flex-start;justify-content: space-around;">

                <table style="width:100%;border: 1px solid black;border-collapse: collapse;">
                    <tr style="border: 1px solid black;border-collapse: collapse;">
                        <th style="width:50%">EARNING</th>
                        <th style="width:50%">DEDUCTIONS</th>
                    </tr>
                    <tr style="border: 1px solid black;border-collapse: collapse;">
                        <td style="border: 1px solid black;border-collapse: collapse;">
                            <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-around;">
                                <div style="width: 150px;padding: 5%;display: flex;flex-direction: column;align-items: flex-start;">
                                    ${earningListString.titleString}
                                </div>
                                <div style="width: 130px;display: flex;flex-direction: column;align-items: flex-start;">
                                    ${earningListString.amountString}
                                </div>
                            </div>
                        </td>
                        <td style="border: 1px solid black;border-collapse: collapse;">
                            <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-around;">
                                <div style="width: 150px;padding: 5%;display: flex;flex-direction: column;align-items: flex-start;">
                                    ${deductionsListString.titleString}
                                </div>
                                <div style="width: 130px;display: flex;flex-direction: column;align-items: flex-start;">
                                    ${deductionsListString.amountString}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr style="border: 1px solid black;border-collapse: collapse;">
                        <td style="border: 1px solid black;border-collapse: collapse;">
                            <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-around;">
                                <div style="width: 150px;padding: 5%;display: flex;flex-direction: column;align-items: flex-start;">
                                    <div>Total earning:</div>
                                </div>
                                <div style="width: 130px;display: flex;flex-direction: column;align-items: flex-start;">
                                    <div>${
                                        paySlip.totalEarning
                                            ? SGDDollar.format(+paySlip.totalEarning)
                                            : SGDDollar.format(0)
                                    }</div>
                                </div>
                            </div>
                        </td>
                        <td style="border: 1px solid black;border-collapse: collapse;">
                            <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-around;">
                                <div style="width: 150px;padding: 5%;display: flex;flex-direction: column;align-items: flex-start;">
                                    <div>Total deductions:</div>
                                </div>
                                <div style="width: 130px;display: flex;flex-direction: column;align-items: flex-start;">
                                    <div>${
                                        paySlip.totalDeduction
                                            ? SGDDollar.format(+paySlip.totalDeduction)
                                            : SGDDollar.format(0)
                                    }</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr style="border: 1px solid black;border-collapse: collapse;">
                        <td style="border: 1px solid black;border-collapse: collapse;">
                            <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-around;">
                                <div style="width: 150px;padding: 5%;display: flex;flex-direction: column;align-items: flex-start;">
                                    <div>Gross Wages:</div>
                                    <div><p></p></div>
                                    <div>Usage:</div>
                                    <div>AL/YTD/Bal:</div>
                                    <div>ML/YTD/Bal:</div>
                                </div>
                                <div style="width: 130px;display: flex;flex-direction: column;align-items: flex-start;">
                                    <div>${
                                        paySlip.summary.grossSalary
                                            ? SGDDollar.format(+paySlip.summary.grossSalary)
                                            : SGDDollar.format(0)
                                    }</div>
                                    <div><p></p></div>
                                    <div><p></p></div>
                                    <div>${`${paySlip.al && paySlip.al.al ? paySlip.al.al : 0} / ${
                                        paySlip.al && paySlip.al.ytd ? paySlip.al.ytd : 0
                                    } / ${paySlip.al && paySlip.al.bl ? paySlip.al.bl : 0}`}</div>
                                    <div>${`${paySlip.ml && paySlip.ml.ml ? paySlip.ml.ml : 0} / ${
                                        paySlip.ml && paySlip.ml.ytd ? paySlip.ml.ytd : 0
                                    } / ${paySlip.ml && paySlip.ml.bl ? paySlip.ml.bl : 0}`}</div>
                                </div>
                            </div>
                        </td>
                        <td style="border: 1px solid black;border-collapse: collapse;">
                            <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-around;">
                                <div style="width: 150px;padding: 5%;display: flex;flex-direction: column;align-items: flex-start;">
                                    <div><a><b>Nett Wages:</b></a></div>
                                    <div><p></p></div>
                                    <div><a><b>CPF Contribution</b></a></div>
                                    <div>Employer CPF:</div>
                                    <div>Employee CPF:</div>
                                </div>
                                <div style="width: 130px;display: flex;flex-direction: column;align-items: flex-start;">
                                    <div>${
                                        paySlip.summary.netPay
                                            ? SGDDollar.format(+paySlip.summary.netPay)
                                            : SGDDollar.format(0)
                                    }</div>
                                    <div><p></p></div>
                                    <div><p></p></div>
                                    <div>${paySlip && paySlip.cdfEmployer && '$' + paySlip.cdfEmployer.toFixed(0)}</div>
                                    <div>${cdf && '$' + +cdf.toFixed(0)}</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>`);
                a.document.write('</body></html>');
                a.document.close();
                a.print();
            } else {
                console.error('Popup window could not be opened.');
            }
        } else {
            console.error("Element with id 'print' not found.");
        }
    };

    let headers = ['From', '', '', 'To'];
    let data = [
        [`${company.companyName}`, '', '', `${employee.firstName}`],
        [`${company.state}`, '', '', `${employee.jobPosition}`],
        [`${company.address}`, '', '', `Employee ID: ${employee.employeesId}`],
        [`Email: ${company.email}`, '', '', `Joining Date:${moment(employee.dateJoin).format('DD MMM, YYYY')}`],
        [`Phone: ${company.phoneNumber}`, '', '', ''],
        [],
    ];

    const renderTable = () => {
        let items = [];

        data.push(['#', 'Earnning', 'Amount']);
        paySlip.earning.map((item, idx) => {
            items.push(idx + 1);
            items.push(item.title);
            items.push(`$${item.amount}`);
            data.push(items);
            items = [];
        });
        data.push(['Total Earnings', '', `$${calculatorMoney(paySlip.earning)}`]);

        data.push(['', '', '']);
        data.push(['#', 'Deductions', 'Amount']);
        paySlip.deductions.map((item, idx) => {
            items.push(idx + 1);
            items.push(item.title);
            items.push(`$${item.amount}`);
            data.push(items);
            items = [];
        });
        data.push(['Total Deductions', '', `$${calculatorMoney(paySlip.deductions)}`]);
        data.push(['', '', '']);
        data.push([
            `Net Salary: $${calculatorMoney(paySlip.earning) -
                calculatorMoney(paySlip.deductions)} (${convertNumbertoText(
                calculatorMoney(paySlip.earning) - calculatorMoney(paySlip.deductions),
            )})`,
        ]);
        setCsvData(data);
    };

    const downloadPdf = () => {
        const content = document.getElementById('print');
        // html2canvas(content, { width: 550 }).then((canvas) => {
        //     const imgData = canvas.toDataURL('img/png');
        //     const pdf = new jsPDF('p', 'mm', 'a4');
        //     pdf.canvas.height = 297;
        //     pdf.canvas.width = 210;
        //     pdf.addImage(imgData, 'JPEG', 0, 0);
        //     pdf.save();
        // });
        html2pdf()
            .from(content)
            .save();
    };

    // console.log(paySlip);

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <Col lg="6">
                    <PageTitle title="PaySlip" subtitle="Payroll / PaySlip" className="text-sm-left mb-3 " />
                </Col>
                <Col lg="6" className="d-flex justify-content-end">
                    {((user && user.role && (user.role === ROLE.COMPANY || user.role === ROLE.COMPANYSTAFF)) ||
                        (user && user.position && user.position.payroll && user.position.payroll.includes('6')) ||
                        (user &&
                            user.employee &&
                            user.employee.controls &&
                            user.employee.controls.payroll &&
                            user.employee.controls.payroll.includes('6'))) && (
                        <Col lg="6">
                            <Button>
                                <span className="material-icons" onClick={() => downloadPdf()}>
                                    picture_as_pdf
                                </span>
                                PDF
                            </Button>
                            <Button onClick={() => renderTable(company)}>
                                <CSVLink data={csvData} headers={headers}>
                                    <span className="material-icons">download</span>CSV
                                </CSVLink>
                            </Button>
                            <Button onClick={() => printPaySlip()}>
                                <span className="material-icons">print</span>Print
                            </Button>
                        </Col>
                    )}

                    <CollectionCreateForm
                        visible={visible}
                        onCreate={onCreate}
                        onCancel={() => {
                            setVisible(false);
                        }}
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    <div id="print">
                        <Card>
                            <CardBody>
                                <Row className="px-5">
                                    <Col lg="12" md="12" sm="12" className="d-flex justify-content-center">
                                        {infoCompany && infoCompany.avatar && (
                                            <div>
                                                <Avatar src={infoCompany.avatar} />
                                            </div>
                                        )}
                                    </Col>
                                    <Col lg="12" md="12" sm="12" className="d-flex justify-content-center">
                                        {company && company.companyName && <div>{company.companyName}</div>}
                                    </Col>
                                    <Col lg="12" md="12" sm="12" className="d-flex justify-content-center">
                                        {company && company.address ? (
                                            <div>Address: {company.address}</div>
                                        ) : (
                                            <div>Address: {company.city}</div>
                                        )}
                                    </Col>
                                    <Col lg="12" md="12" sm="12" className="d-flex justify-content-center">
                                        {company && company.phoneNumber ? (
                                            <div>Tel No: {company.phoneNumber}</div>
                                        ) : (
                                            <div>Tel No: {company.mobileNumber}</div>
                                        )}
                                    </Col>
                                </Row>

                                <Row className="px-5 mt-5 border-top">
                                    <Col lg="9" md="9" sm="9" className="mt-3 mb-3">
                                        <div>
                                            Name:{' '}
                                            {employee && employee.middleName
                                                ? `${employee.middleName} ${employee.firstName} ${employee.lastName}`
                                                : employee && !employee.middleName
                                                ? `${employee.firstName}  ${employee.lastName}`
                                                : ''}
                                        </div>
                                        <div>Staff Code: {employee && employee.employeesId}</div>
                                        <div>Job Title: {employee && employee.jobPosition}</div>
                                        <div>
                                            {employee &&
                                            employee.type &&
                                            employee.type === EMPLOYEES_TYPE_VALUE.LOCALSTAFF
                                                ? 'NRIC No.'
                                                : 'FIN No.'}{' '}
                                            {employee && employee.nationId}
                                        </div>

                                        {employee &&
                                            employee.type &&
                                            employee.type === EMPLOYEES_TYPE_VALUE.WORKPERMIT && (
                                                <div>WP No. {employee && employee.wp}</div>
                                            )}
                                        {employee && employee.type && employee.type === EMPLOYEES_TYPE_VALUE.SPASS && (
                                            <div>SP No. {employee && employee.wp}</div>
                                        )}
                                    </Col>
                                    <Col lg="3" md="3" sm="3" className="mt-3 mb-3">
                                        <div>
                                            Payslip for{' '}
                                            {paySlip && paySlip.month
                                                ? covertMonth(new Date(paySlip.month).getMonth())
                                                : paySlip && paySlip.effectiveDate
                                                ? covertMonth(new Date(paySlip.effectiveDate).getMonth())
                                                : ''}
                                            ,{' '}
                                            {paySlip && paySlip.month
                                                ? new Date(paySlip.month).getFullYear()
                                                : paySlip && paySlip.effectiveDate
                                                ? new Date(paySlip.effectiveDate).getFullYear()
                                                : ''}
                                        </div>
                                        <br></br>
                                        <div>Date of Payment</div>
                                        {paySlip.monthlyRate ? (
                                            <div>
                                                Monthly Rate:{' '}
                                                {(paySlip &&
                                                    paySlip.monthlyRate &&
                                                    // +paySlip.monthlyRate.toFixed(2) &&
                                                    SGDDollar.format(+paySlip.monthlyRate)) ||
                                                    SGDDollar.format(0)}
                                            </div>
                                        ) : (
                                            <div>
                                                Daily Rate:{' '}
                                                {(paySlip &&
                                                    paySlip.dailyRate &&
                                                    SGDDollar.format(+paySlip.dailyRate)) ||
                                                    SGDDollar.format(0)}
                                            </div>
                                        )}

                                        <div>
                                            Hourly Rate:{' '}
                                            {(paySlip && paySlip.hourlyRate && SGDDollar.format(+paySlip.hourlyRate)) ||
                                                SGDDollar.format(0)}
                                        </div>
                                        <div>
                                            Overtime Rate (1.5):{' '}
                                            {(paySlip &&
                                                paySlip.overtimeRate &&
                                                SGDDollar.format(+paySlip.overtimeRate)) ||
                                                SGDDollar.format(0)}
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="px-5 mt-5 border-top">
                                    <Col lg="7" md="7" sm="7" className="border-right">
                                        <Row className="mb-2">
                                            <Col lg="12" md="12" sm="12">
                                                <div className="font-weight-bold">EARNING</div>
                                            </Col>
                                        </Row>

                                        {earningList.map((e, index) => (
                                            <Row>
                                                <Col
                                                    lg="8"
                                                    md="8"
                                                    sm="8"
                                                    className={index === earningList.length - 1 ? 'pb-3' : ''}
                                                >
                                                    <div key={index}>{e.title}</div>
                                                </Col>
                                                <Col
                                                    lg="4"
                                                    md="4"
                                                    sm="4"
                                                    className={index === earningList.length - 1 ? 'pb-3' : ''}
                                                >
                                                    <div key={index}>{e.desc || SGDDollar.format(+e.amount)}</div>
                                                </Col>
                                            </Row>
                                        ))}
                                    </Col>

                                    <Col lg="5" md="5" sm="5">
                                        <Row>
                                            <Col lg="7" md="7" sm="7" className="">
                                                <div className="font-weight-bold">DEDUCTION</div>
                                            </Col>
                                            <Col lg="5" md="5" sm="5">
                                                <div></div>
                                            </Col>
                                        </Row>

                                        {deductList.map((e, index) => (
                                            <Row>
                                                <Col
                                                    lg="7"
                                                    md="7"
                                                    sm="7"
                                                    className={
                                                        index === 0
                                                            ? 'pt-2'
                                                            : index === deductList.length - 1
                                                            ? 'pb-3'
                                                            : ''
                                                    }
                                                >
                                                    <div key={index}>{e.title}</div>
                                                </Col>
                                                <Col
                                                    lg="5"
                                                    md="5"
                                                    sm="5"
                                                    className={
                                                        index === 0
                                                            ? 'pt-2'
                                                            : index === deductList.length - 1
                                                            ? 'pb-3'
                                                            : ''
                                                    }
                                                >
                                                    <div key={index}>{SGDDollar.format(+e.amount)}</div>
                                                </Col>
                                            </Row>
                                        ))}
                                    </Col>
                                </Row>

                                <Row className="px-5  border-top">
                                    <Col lg="7" md="7" sm="7">
                                        <Row>
                                            <Col lg="8" md="8" sm="8">
                                                <div>Total Earning :</div>
                                            </Col>
                                            <Col lg="4" md="4" sm="4" className="border-right">
                                                <div>
                                                    {' '}
                                                    {(paySlip &&
                                                        paySlip.totalEarning &&
                                                        SGDDollar.format(+paySlip.totalEarning)) ||
                                                        SGDDollar.format(0)}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col lg="5" md="5" sm="5">
                                        <Row>
                                            <Col lg="7" md="7" sm="7">
                                                <div>Total Deductions :</div>
                                            </Col>
                                            <Col lg="5" md="5" sm="5">
                                                <div>
                                                    {paySlip && paySlip.totalDeduction
                                                        ? SGDDollar.format(+paySlip.totalDeduction)
                                                        : SGDDollar.format(0)}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row className="px-5  border-top pb-4">
                                    <Col lg="7" md="7" sm="7">
                                        <Row className="border-right">
                                            <Col lg="8" md="8" sm="8">
                                                <div>Gross Wages:</div>
                                            </Col>
                                            <Col lg="4" md="4" sm="4">
                                                <div>
                                                    {paySlip &&
                                                        paySlip.summary &&
                                                        paySlip.summary.grossSalary &&
                                                        SGDDollar.format(paySlip.summary.grossSalary)}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col lg="5" md="5" sm="5">
                                        <Row>
                                            <Col lg="7" md="7" sm="7">
                                                {' '}
                                                <div className="font-weight-bold">Nett Wages:</div>
                                            </Col>
                                            <Col lg="5" md="5" sm="5">
                                                {' '}
                                                <div>
                                                    {' '}
                                                    {paySlip &&
                                                        paySlip.summary &&
                                                        paySlip.summary.netPay &&
                                                        SGDDollar.format(paySlip.summary.netPay)}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                {/* <Row className="px-5">
                                    <Col lg="7" md="7" sm="7" className="border-right">
                                        <Row className="pt-4 pb-4">
                                            <Col lg="4" md="4" sm="4">
                                                <div className="font-weight-bold">BANK TO</div>
                                            </Col>
                                            <Col lg="4" md="4" sm="4">
                                                <div>POSB</div>
                                            </Col>
                                            <Col lg="4" md="4" sm="4">
                                                <div>Account No</div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col lg="5" md="5" sm="5">
                                        <div></div>
                                    </Col>
                                </Row> */}

                                <Row className="px-5 border-bottom">
                                    <Col lg="7" md="7" sm="7" className="border-right">
                                        <Row className="">
                                            <Col lg="12" md="12" sm="12">
                                                <div>Usage</div>
                                            </Col>
                                        </Row>
                                        <Row className="">
                                            <Col lg="3" md="3" sm="3">
                                                <div>AL/YTD/Bal:</div>
                                            </Col>
                                            <Col lg="5" md="5" sm="5"></Col>

                                            <Col lg="4" md="4" sm="4">
                                                <div>{`${paySlip.al && paySlip.al.al ? paySlip.al.al : 0} / ${
                                                    paySlip.al && paySlip.al.ytd ? paySlip.al.ytd : 0
                                                } / ${paySlip.al && paySlip.al.bl ? paySlip.al.bl : 0}`}</div>
                                            </Col>
                                        </Row>
                                        <Row className="">
                                            <Col lg="3" md="3" sm="3">
                                                <div>ML/YTD/Bal:</div>
                                            </Col>
                                            <Col lg="5" md="5" sm="5"></Col>

                                            <Col lg="4" md="4" sm="4">
                                                <div>{`${paySlip.ml && paySlip.ml.ml ? paySlip.ml.ml : 0} / ${
                                                    paySlip.ml && paySlip.ml.ytd ? paySlip.ml.ytd : 0
                                                } / ${paySlip.ml && paySlip.ml.bl ? paySlip.ml.bl : 0}`}</div>
                                            </Col>
                                        </Row>
                                        {/* <Row className="pb-4">
                                            <Col lg="12" md="12" sm="12">
                                                <div>
                                                    [Bal = if work until{' '}
                                                    {covertDate(new Date(paySlip.effectiveDate).getMonth())}{' '}
                                                    {covertMonth(new Date(paySlip.effectiveDate).getMonth())}{' '}
                                                    {new Date(paySlip.effectiveDate).getFullYear()}]
                                                </div>
                                            </Col>
                                        </Row> */}
                                    </Col>

                                    {employee && employee.type && employee.type === EMPLOYEES_TYPE_VALUE.LOCALSTAFF && (
                                        <Col lg="5" md="5" sm="5">
                                            <Row className="">
                                                <Col lg="12" md="12" sm="12">
                                                    <div className="font-weight-bold">CPF Contribution</div>
                                                </Col>
                                            </Row>
                                            <Row className="">
                                                <Col lg="7" md="7" sm="7">
                                                    <div className="">Employer CPF</div>
                                                </Col>
                                                <Col lg="5" md="5" sm="5">
                                                    <div>
                                                        {paySlip &&
                                                            paySlip.cdfEmployer &&
                                                            '$' + paySlip.cdfEmployer.toFixed(0)}
                                                        {/* {(paySlip &&
                                                            paySlip.summary && paySlip.summary.employerCPF &&
                                                            SGDDollar.format(+paySlip.summary.employerCPF)) ||
                                                            SGDDollar.format(0)} */}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="">
                                                <Col lg="7" md="7" sm="7">
                                                    <div className="">Employee CPF</div>
                                                </Col>
                                                <Col lg="5" md="5" sm="5">
                                                    <div>{cdf && '$' + +cdf.toFixed(0)}</div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    )}
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default NewPaySlip;
