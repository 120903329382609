import { Avatar, DatePicker, Form, Image, Input, Modal, Radio, Select, TimePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Row } from 'shards-react';
import { employeesAPI } from '../../apis/employeesAPI';
import { historiesAPI } from '../../apis/historiesAPI';
import { leaveAPI } from '../../apis/leaveAPI';
import { leavesSettingAPI } from '../../apis/leavesSettingAPI';
import { LEAVE, LEAVE_STATUS } from '../../global/leave';
import { FORMAT, FORM_TYPE, ROLE, TOTAL_LEAVE_HOURS, TYPE_NOTIFICATION } from '../../helpers/const';
import { getError } from '../../helpers/getErrorServer';
import { useLoading } from '../../hooks';
import { useLeave } from '../../hooks/useLeave';
import { useUser } from '../../hooks/useUser';
import IMG_AVT from '../../images/avatars/0.jpg';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { uploadImageAPI } from '../../apis/uploadImageAPI';
import { dayLeavesAPI } from '../../apis/dayLeavesAPI';
import { LEAVE_SESSION, LEAVE_SESSION_VALUE, LEAVE_UNIT } from '../../global/leave/leaveEnum';
import { individualLeaveSettingAPI } from '../../apis/individualLeaveSettingAPI';
import { transfersMinutesToHours } from '../../helpers/transfersMinutesToHours';
import { fileManagementAPI } from '../../apis/fileManagementAPI';
import { FILE_UPLOAD_FOR, FILE_UPLOAD_TYPE } from '../../global/fileManagement';

const LeaveCreateForm = ({
    visible,
    onCancel,
    id,
    type = FORM_TYPE.VIEW,
    setRender,
    value,
    setValue,
    pageHistory,
    pagePending,
    setAddPending,
}) => {
    const { user, getMe } = useUser();
    const { setLoading } = useLoading();
    const { fetchLeaves } = useLeave();
    const { TextArea } = Input;
    const dateFormatList = [FORMAT.DATE, FORMAT.DATE];
    const [form] = Form.useForm();
    const { Option } = Select;
    const [employees, setEmployees] = useState([]);
    const [leaveRequest, setLeaveRequest] = useState(LEAVE);
    const [leaveType, setLeaveType] = useState([]);
    const [leaveSetting, setLeaveSetting] = useState();
    const [fileList, setFileList] = useState([]);
    const [remaining, setRemaining] = useState('--');
    const [reRender, setReRender] = useState(0);
    const [isWarning, setIsWarning] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [listEmployeeTmp, setListEmployeeTmp] = useState([]);
    const [listIdEmployees, setListIdEmployees] = useState([]);
    const [idCompany, setIdCompany] = useState();
    const uploadRef = useRef();

    useEffect(() => {
        (async () => {
            if (user.role) {
                loadEmployees();
                if (
                    user &&
                    user.role === ROLE.USER &&
                    user.position &&
                    user.position.leave &&
                    !user.position.leave.includes('3') &&
                    (!user.employee.controls ||
                        !user.employee.controls.leave ||
                        !user.employee.controls.leave.includes('3'))
                ) {
                    setLeaveRequest({
                        ...leaveRequest,
                        employee: user.employee._id,
                    });
                }
            }
            await getType();
        })();
    }, [user, reRender]);

    useEffect(() => {
        if (user && user.company && user.company._id) {
            setIdCompany(user.company._id);
        } else if (user && user.employee && user.employee.company && user.employee.company._id) {
            setIdCompany(user.employee.company._id);
        }
    }, [user]);

    useEffect(() => {
        if (value !== undefined) {
            localStorage.setItem('tab', JSON.stringify(value));
        }
    }, [value]);

    useEffect(() => {
        if (pagePending !== undefined) {
            localStorage.setItem('pagePending', JSON.stringify(pagePending));
        }
    }, [pagePending]);

    useEffect(() => {
        if (pageHistory !== undefined) {
            localStorage.setItem('pageHistory', JSON.stringify(pageHistory));
        }
    }, [pageHistory]);

    useEffect(() => {
        // getType();
        if (id) {
            (async () => {
                const { data } = await leaveAPI.getById(id);
                if (data) {
                    setLeaveRequest(data);
                }
            })();
        }
    }, [id]);

    useEffect(() => {
        (async () => {
            if (leaveRequest && leaveRequest.type) {
                const { data } = await leavesSettingAPI.getById(leaveRequest.type);
                setLeaveSetting(data);
            }
        })();
    }, [leaveRequest.type]);

    useEffect(() => {
        (async () => {
            try {
                if (leaveRequest && leaveRequest.employee && leaveRequest.type) {
                    const leaveTypeTmp = await (await leavesSettingAPI.getById(leaveRequest.type)).data;

                    if (leaveTypeTmp && leaveTypeTmp.leaveUnit && leaveTypeTmp.leaveUnit !== LEAVE_UNIT.HOURS) {
                        let data = {};
                        if (type === FORM_TYPE.CREATE) {
                            data = await getDayCount(
                                leaveRequest.employee,
                                leaveRequest.type,
                                new Date(leaveRequest.to).getFullYear(),
                            );
                        } else {
                            data = await getDayCount(
                                leaveRequest.employee._id,
                                leaveRequest.type,
                                new Date(leaveRequest.to).getFullYear(),
                            );
                        }

                        let amount;
                        let individual = {};
                        if (type === FORM_TYPE.CREATE) {
                            individual = await (
                                await individualLeaveSettingAPI.getByEmployeeAndType(
                                    leaveRequest.employee,
                                    leaveTypeTmp._id,
                                )
                            ).data;
                        } else {
                            individual = await (
                                await individualLeaveSettingAPI.getByEmployeeAndType(
                                    leaveRequest.employee._id,
                                    leaveTypeTmp._id,
                                )
                            ).data;
                        }

                        if (individual && individual[0] && individual[0]._id && individual[0]._id.length > 0) {
                            amount = individual[0].overwrite;
                        } else {
                            amount = leaveTypeTmp.standardNumber;
                        }
                        if (data && data.count) {
                            let tmp = amount - data.count > 0 ? amount - data.count : 0;
                            setRemaining(`${tmp} days`);
                        } else {
                            let tmp = amount > 0 ? amount : 0;
                            setRemaining(`${tmp} days`);
                        }
                    } else if (leaveTypeTmp && leaveTypeTmp.leaveUnit && leaveTypeTmp.leaveUnit === LEAVE_UNIT.HOURS) {
                        const employee = type === FORM_TYPE.CREATE ? leaveRequest.employee : leaveRequest.employee._id;

                        const year = new Date(leaveRequest.from).getFullYear();
                        const amount = leaveTypeTmp.standardNumber;
                        const total = await getDayCount(employee, leaveRequest.type, year);

                        let remaining = 0;
                        if (total && total.count) {
                            remaining = amount - total.count;
                        } else {
                            remaining = amount;
                        }

                        remaining = remaining > 0 ? remaining : 0;

                        setRemaining(transfersMinutesToHours(remaining));
                    }
                }
            } catch (error) {
                toast.error(getError(error));
            }
        })();
    }, [leaveRequest.employee, leaveRequest.type, leaveRequest.from]);

    const getDayCount = async (employee, type, year) => {
        const e = typeof employee === 'object' ? employee._id : employee;
        const { data } = await dayLeavesAPI.find({ employee: e, type: type, year: +year, isDeleted: false });
        return data;
    };

    const getTotalHours = async (employee, year) => {
        const { data } = await dayLeavesAPI.calcTotalHours({ employee, year });
        return data;
    };

    const getType = async () => {
        if (!user) {
            getMe();
        }

        let id = '';
        if (user && user.company && user.company._id && user.role === ROLE.COMPANY) {
            id = user.company._id;
        }
        if (user && user.employee && user.employee.company && user.employee.company._id && user.role !== ROLE.COMPANY) {
            id = user.employee.company._id;
        }

        if (id !== '') {
            const { data } = await leavesSettingAPI.getByCompanyActive(id);
            let tmp = data;
            tmp = data.filter((item) => item.leaveUnit !== LEAVE_UNIT.SPECIAL);
            if (user && user.company && user.company._id) {
                setLeaveType(data);
            } else if (user && user.employee && user.employee.company && user.employee.company._id) {
                setLeaveType(tmp);
            }
        }
    };

    const loadEmployees = async () => {
        try {
            if (user && user.role === ROLE.COMPANY) {
                const { data } = await employeesAPI.getAllCompany(user.company._id);
                setEmployees(data);
            } else if (user && (user.role === ROLE.USER || user.role === ROLE.COMPANYSTAFF)) {
                const { data } = await employeesAPI.getAllCompany(user.employee.company._id);
                setEmployees(data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const submit = () => {
        if (id) {
            editLeaveRequest();
        } else {
            createLeaveRequest();
        }
    };

    const checkValidate = async () => {
        if (leaveRequest.employee.length === 0 && type !== FORM_TYPE.APPROVE) {
            toast.error('Please select employee!');
            return false;
        }

        if (leaveRequest.type === '') {
            toast.error('Please select leave type!');
            return false;
        }

        if (
            leaveRequest &&
            leaveSetting &&
            leaveSetting.leaveUnit &&
            leaveSetting.leaveUnit === LEAVE_UNIT.HOURS &&
            leaveRequest.from >= leaveRequest.to
        ) {
            toast.error('Time from must be before time to!');
            return false;
        }

        if (
            leaveRequest &&
            leaveSetting &&
            leaveSetting.leaveUnit &&
            leaveSetting.leaveUnit === LEAVE_UNIT.HOURS &&
            new Date(leaveRequest.from).getHours() < 12 &&
            new Date(leaveRequest.to).getHours() > 12
        ) {
            toast.error('Cant create request during break time!');
            return false;
        }

        if (
            leaveRequest &&
            leaveSetting &&
            leaveSetting.leaveUnit &&
            leaveSetting.leaveUnit !== LEAVE_UNIT.HOURS &&
            new Date(new Date(leaveRequest.from).setHours(18, 0, 0, 0)).getTime() >
                new Date(new Date(leaveRequest.to).setHours(18, 0, 0, 0)).getTime()
        ) {
            toast.error('Leave start day must be before leave end day!');
            return false;
        }

        if (leaveSetting && leaveSetting.reasonRequired === true && leaveRequest.reason === '') {
            toast.error('Please enter reason!');
            return false;
        }

        if (
            leaveSetting &&
            leaveSetting.leaveUnit &&
            leaveSetting.leaveUnit === LEAVE_UNIT.HALF_DAY &&
            leaveRequest.fromSession === undefined
        ) {
            toast.error('Please enter session of from!');
            return false;
        }

        if (
            leaveSetting &&
            leaveSetting.leaveUnit &&
            leaveSetting.leaveUnit === LEAVE_UNIT.HALF_DAY &&
            leaveRequest.toSession === undefined
        ) {
            toast.error('Please enter session of to!');
            return false;
        }
        if (
            leaveSetting &&
            leaveSetting.leaveUnit &&
            leaveSetting.leaveUnit === LEAVE_UNIT.HALF_DAY &&
            new Date(new Date(leaveRequest.from).setHours(9, 0, 0, 0)).getTime() ===
                new Date(new Date(leaveRequest.to).setHours(9, 0, 0, 0)).getTime() &&
            leaveRequest.fromSession === LEAVE_SESSION_VALUE.AFTERNOON &&
            leaveRequest.toSession === LEAVE_SESSION_VALUE.MORNING
        ) {
            toast.error('Leave start day must be before leave end day!');
            return false;
        }

        if (leaveSetting && leaveSetting.attachmentRequired === true && fileList.length < 1) {
            toast.error('Please insert attachment!');
            return false;
        }

        if (new Date(leaveRequest.from).getMonth() !== new Date(leaveRequest.to).getMonth()) {
            toast.error('Can only create request in the same month!');
            return false;
        }

        const { data: oldRequest } = await leaveAPI.get({
            employee: leaveRequest.employee._id ? leaveRequest.employee._id : leaveRequest.employee,
            from: new Date(leaveRequest.from),
            to: new Date(leaveRequest.to),
            fromStart: new Date(leaveRequest.from).setHours(0),
            fromEnd: new Date(leaveRequest.from).setHours(23),
            toStart: new Date(leaveRequest.to).setHours(0),
            toEnd: new Date(leaveRequest.to).setHours(23),
            type: leaveRequest.type,
            status: `${LEAVE_STATUS.PENDING},${LEAVE_STATUS.APPROVE}`,
        });

        if (
            oldRequest.items.length > 0 &&
            type === FORM_TYPE.CREATE &&
            leaveSetting &&
            leaveSetting.leaveUnit &&
            leaveSetting.leaveUnit !== LEAVE_UNIT.HOURS &&
            leaveSetting.leaveUnit !== LEAVE_UNIT.HALF_DAY
        ) {
            toast.error(`There is already a leave request same type on the selected date!`);
            return false;
        }

        const { data: oldRequestTmpHalf } = await leaveAPI.checkSpamHours({
            employee: leaveRequest.employee._id ? leaveRequest.employee._id : leaveRequest.employee,
            from: new Date(leaveRequest.from),
            to: new Date(leaveRequest.to),

            type: leaveRequest.type,
            status: `${LEAVE_STATUS.APPROVE},${LEAVE_STATUS.PENDING}`,
        });

        if (
            oldRequestTmpHalf.length > 0 &&
            type === FORM_TYPE.CREATE &&
            leaveSetting &&
            leaveSetting.leaveUnit &&
            leaveSetting.leaveUnit === LEAVE_UNIT.HALF_DAY
        ) {
            toast.error(`There is already a leave request same type on the selected date!`);
            return false;
        }

        const { data: oldRequestTmp } = await leaveAPI.checkSpamHours({
            employee: leaveRequest.employee._id ? leaveRequest.employee._id : leaveRequest.employee,
            from: new Date(leaveRequest.from),
            to: new Date(leaveRequest.to),
            status: `${LEAVE_STATUS.APPROVE}`,
        });

        if (
            oldRequestTmp.length > 0 &&
            type === FORM_TYPE.CREATE &&
            leaveSetting &&
            leaveSetting.leaveUnit &&
            leaveSetting.leaveUnit === LEAVE_UNIT.HOURS
        ) {
            toast.error(`There is already a leave request on the selected time!`);
            return false;
        }

        if (type === FORM_TYPE.APPROVE && !leaveRequest.reasonApprove) {
            toast.error('Please input the reason!');
            return false;
        }
        if (type === FORM_TYPE.APPROVE && leaveRequest.status === LEAVE_STATUS.PENDING) {
            toast.error('Please approve or reject!');
            return false;
        }

        return true;
    };

    const handleSubmitAgain = async () => {
        let result = true;

        const { data } = await leaveAPI.checkSpamHours({
            employee: leaveRequest.employee._id ? leaveRequest.employee._id : leaveRequest.employee,
            from: new Date(leaveRequest.from),
            to: new Date(leaveRequest.to),
            status: `${LEAVE_STATUS.PENDING},${LEAVE_STATUS.APPROVE}`,
        });

        if (data && data.length > 0) {
            result = false;
            setIsWarning(true);
        }
        return result;
    };

    const handleOkWarning = () => {
        setIsWarning(false);
        setIsConfirm(true);
    };

    const handleCancelWarning = () => {
        setIsWarning(false);
        form.resetFields();
        setLeaveRequest(LEAVE);
        setIsConfirm(false);
    };

    const getHistories = async (id) => {
        const { data } = await employeesAPI.getById(leaveRequest.employee);
        await historiesAPI.add({
            title: 'NEW LEAVE REQUEST',
            desc: `${data[0].firstName} just create new leave request`,
            employee: data[0]._id,
            type: TYPE_NOTIFICATION.LEAVE,
            idRequest: id,
            toCompany: true,
            toCompanyStaff: true,
        });
    };

    const handleChangeFrom = (date, dateString) => {
        if (leaveSetting && leaveSetting.leaveUnit && leaveSetting.leaveUnit !== LEAVE_UNIT.HOURS) {
            let tmp = new Date(new Date(date).setHours(6, 0, 0, 0));
            setLeaveRequest({ ...leaveRequest, from: tmp });
        }
    };

    const handleChangeTo = (date, dateString) => {
        if (leaveSetting && leaveSetting.leaveUnit && leaveSetting.leaveUnit !== LEAVE_UNIT.HOURS) {
            let tmp = new Date(new Date(date).setHours(22, 0, 0, 0));
            setLeaveRequest({ ...leaveRequest, to: tmp });
        }
    };

    const handleChangeDate = (date, dateString) => {
        const tmp = new Date(new Date(date).setHours(9, 0, 0, 0));
        setLeaveRequest({ ...leaveRequest, from: tmp, to: tmp });
    };

    const handleChangeFromHours = (date, time) => {
        const tmp = new Date(moment(date).format('YYYY-MM-DD') + ' ' + moment(time).format('HH:mm'));
        setLeaveRequest({ ...leaveRequest, from: tmp });
    };

    const handleChangeToHours = (date, time) => {
        const tmp = new Date(moment(date).format('YYYY-MM-DD') + ' ' + moment(time).format('HH:mm'));
        setLeaveRequest({ ...leaveRequest, to: tmp });
    };

    const createLeaveRequest = async () => {
        try {
            if (Object.keys(user).length === 0) {
                getMe();
            }

            const check = await checkValidate();
            if (!check) {
                return;
            }
            if (!isConfirm && leaveSetting && leaveSetting.leaveUnit && leaveSetting.leaveUnit !== LEAVE_UNIT.HOURS) {
                const isAgain = await handleSubmitAgain();
                if (!isAgain) {
                    return;
                }
            }
            setLoading(true);
            let arr = [];
            let tmp = [];
            for await (const file of fileList) {
                const { data } = await uploadImageAPI.uploadMultipleFiles(file, idCompany);

                if (data && data.original_filename && data.url) {
                    arr = [...arr, { [data.original_filename]: data.url }];
                }
                const type = file.type.includes('image') ? FILE_UPLOAD_TYPE.IMAGE : FILE_UPLOAD_TYPE.TEXT;
                tmp = [
                    ...tmp,
                    {
                        thumbnail: data.url,
                        name: data.original_filename,
                        uploadDate: new Date(data.created_at).getTime(),
                        size: data.bytes,
                        type,
                        publicID: data.public_id,
                    },
                ];
            }

            const newRequest = await leaveAPI.add({ ...leaveRequest, attachments: arr });

            for await (const item of tmp) {
                await fileManagementAPI.add({
                    company: idCompany,
                    uploadBy: user._id,
                    thumbnail: item.thumbnail,
                    name: item.name,
                    uploadDate: item.uploadDate,
                    size: item.size,
                    uploadFor: FILE_UPLOAD_FOR.LEAVE,
                    request: newRequest.data._id,
                    type: item.type,
                    publicID: item.publicID,
                });
            }

            getHistories(newRequest.data._id);

            form.resetFields();
            setFileList([]);
            setLeaveRequest({ ...LEAVE, attachments: [] });
            toast.success('Add success!');
            setLoading(false);
            setIsConfirm(false);

            setReRender((prev) => prev + 1);

            const valueTmp = value !== undefined ? value : localStorage.getItem('tab');
            const pagePendingTmp = pagePending !== undefined ? pagePending : localStorage.getItem('pagePending');
            const pageHistoryTmp = pageHistory !== undefined ? pageHistory : localStorage.getItem('pageHistory');

            if (valueTmp === '1') {
                await fetchLeaves({
                    status: LEAVE_STATUS.PENDING,
                    page: pagePendingTmp,
                });

                await fetchLeaves({
                    status: LEAVE_STATUS.APPROVE + ',' + LEAVE_STATUS.REJECT,
                    page: pageHistoryTmp,
                });
            } else {
                fetchLeaves({
                    status: LEAVE_STATUS.PENDING,
                    page: pagePendingTmp,
                });
            }

            onCancel();
        } catch (error) {
            setLoading(false);
            form.resetFields();
            setReRender((prev) => prev + 1);
            setLeaveRequest({ ...LEAVE, attachments: [] });
            setFileList([]);
            toast.error(getError(error));
        }
    };

    const editLeaveRequest = async () => {
        try {
            setLoading(true);
            const { employee, ...payload } = await (await leaveAPI.getById(id)).data;

            let tmp = {
                ...payload,
                ...leaveRequest,
                employee: employee._id,
                isAwarded: leaveRequest.isAwarded ? true : false,
            };

            const check = await checkValidate();

            if (!check) {
                setLoading(false);
                return;
            }

            const request = await leaveAPI.update(id, tmp);
            if (request && request.data && request.data._id && request.data.employee) {
                getHistoriesToEmployee(request.data._id, request.data.employee);
            }
            if (
                request &&
                request.data &&
                request.data._id &&
                request.data.employee &&
                user &&
                user.role &&
                user.role !== ROLE.COMPANY
            ) {
                getHistoriesToCompany(request.data._id, request.data.employee);
            }

            toast.success('Update successfully!');
            setLoading(false);
            setRender((prev) => prev + 1);
            setReRender((prev) => prev + 1);

            onCancel();
            if (value === 1) {
                fetchLeaves({
                    status: LEAVE_STATUS.APPROVE + ',' + LEAVE_STATUS.REJECT,
                    page: pageHistory ? pageHistory : 1,
                });
            } else {
                fetchLeaves({
                    status: LEAVE_STATUS.PENDING,
                    page: pagePending ? pagePending : 1,
                });
            }

            setLeaveRequest(LEAVE);
        } catch (error) {
            setLoading(false);
            form.resetFields();
            setReRender((prev) => prev + 1);
            // setLeaveRequest(LEAVE);
            console.log(error);
            toast.error(getError(error));
        }
    };

    const getHistoriesToEmployee = async (id, employee) => {
        await historiesAPI.add({
            title: 'LEAVE REQUEST',
            desc: 'Your leave request just reviewed',
            employee,
            type: TYPE_NOTIFICATION.LEAVE,
            idRequest: id,
            toEmployee: true,
        });
    };

    const getHistoriesToCompany = async (id, employee) => {
        await historiesAPI.add({
            title: 'LEAVE REQUEST',
            desc: `${user.employee.firstName} just review leave request`,
            employee,
            type: TYPE_NOTIFICATION.LEAVE,
            idRequest: id,
            toCompany: true,
        });
    };

    const onRemove = (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
    };

    const beforeUpload = (file) => {
        setFileList([...fileList, file]);
        return false;
    };

    const handleOpen = (link) => {
        window.open(link);
    };

    const handleCancel = () => {
        form.resetFields();
        setReRender((prev) => prev + 1);
        setLeaveRequest(LEAVE);
        onCancel();
    };

    const handleChangeEmployeesList = async (value) => {
        let isAll = false;
        value.map((item) => {
            if (item === 'all') {
                isAll = true;
            }
        });

        if (isAll) {
            setListEmployeeTmp([]);
            let tmp = [];
            employees.map((item) => (tmp = [...tmp, item._id]));
            setListIdEmployees(tmp);
        } else {
            setListEmployeeTmp(employees);
            setListIdEmployees(value);
        }
    };

    return (
        <>
            <Modal
                open={visible}
                title="Leave request"
                okText="Submit"
                cancelText="Cancel"
                zIndex={998}
                onCancel={handleCancel}
                width="700px"
                height="500px"
                okButtonProps={type === 'view' && { style: { display: 'none' } }}
                onOk={() => {
                    form.validateFields()
                        .then((values) => {
                            form.resetFields();
                            submit();
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form form={form} layout="vertical" name="form_in_modal" initialValues={{ modifier: 'public' }}>
                    <Row lg="12" md="8" sm="6">
                        {(id ||
                            (user &&
                                user.role === ROLE.USER &&
                                user.position &&
                                user.position.leave &&
                                !user.position.leave.includes('3') &&
                                (!user.employee.controls ||
                                    !user.employee.controls.leave ||
                                    !user.employee.controls.leave.includes('3')))) && (
                            <>
                                <Col lg="2">
                                    <Avatar
                                        shape="square"
                                        name="avatar"
                                        size={64}
                                        src={
                                            <Image
                                                width={60}
                                                src={
                                                    id &&
                                                    leaveRequest &&
                                                    leaveRequest.employee &&
                                                    leaveRequest.employee.user &&
                                                    leaveRequest.employee.user.avatar
                                                        ? leaveRequest.employee.user.avatar
                                                        : !id &&
                                                          user &&
                                                          user.avatar &&
                                                          user.role === ROLE.USER &&
                                                          user.position &&
                                                          user.position.leave &&
                                                          !user.position.leave.includes('3') &&
                                                          (!user.employee.controls ||
                                                              !user.employee.controls.leave ||
                                                              !user.employee.controls.leave.includes('3'))
                                                        ? user.avatar
                                                        : IMG_AVT
                                                }
                                            />
                                        }
                                    />
                                </Col>
                                <Col lg="9" className="ml-2">
                                    <div className="title_name">
                                        <h5>
                                            #
                                            {id && leaveRequest && leaveRequest.employee
                                                ? leaveRequest.employee.employeesId
                                                : user && user.employee
                                                ? user.employee.employeesId
                                                : "can't get employee id"}{' '}
                                            -{' '}
                                            {id &&
                                            leaveRequest &&
                                            leaveRequest.employee &&
                                            leaveRequest.employee.firstName
                                                ? leaveRequest.employee.firstName
                                                : user && user.employee
                                                ? user.employee.firstName
                                                : "can't get name"}
                                        </h5>
                                        <p>
                                            {id &&
                                            leaveRequest &&
                                            leaveRequest.employee &&
                                            leaveRequest.employee.jobPosition
                                                ? leaveRequest.employee.jobPosition
                                                : user && user.employee
                                                ? user.employee.jobPosition
                                                : "can't get job position"}
                                        </p>
                                    </div>
                                </Col>
                            </>
                        )}
                    </Row>
                    <Row lg="12" md="8" sm="6">
                        {((user && user.role !== ROLE.USER) ||
                            (user && user.position && user.position.leave && user.position.leave.includes('3')) ||
                            (user &&
                                user.employee &&
                                user.employee.controls &&
                                user.employee.controls.leave &&
                                user.employee.controls.leave.includes('3'))) &&
                            !id && (
                                <Col lg="12">
                                    <div className="mt-4 mb-2">
                                        <label htmlFor="firstName" className="title">
                                            {' '}
                                            <span>*</span> First Name
                                        </label>
                                        <Select
                                            showSearch
                                            disabled={type === FORM_TYPE.VIEW ? true : false}
                                            style={{ width: '100%', zIndex: 999 }}
                                            value={leaveRequest && leaveRequest.employee ? leaveRequest.employee : ''}
                                            onChange={(e) => {
                                                setLeaveRequest({ ...leaveRequest, employee: e });
                                            }}
                                            id="firstName"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().includes(input.toLowerCase())
                                            }
                                        >
                                            {employees &&
                                                employees.map((employee, index) => (
                                                    <Option key={index} value={employee._id}>
                                                        {employee.firstName}
                                                    </Option>
                                                ))}
                                        </Select>
                                    </div>
                                </Col>
                            )}

                        {/* {((user && user.role !== ROLE.USER) ||
                            (user && user.position && user.position.leave && user.position.leave.includes('3')) ||
                            (user &&
                                user.employee &&
                                user.employee.controls &&
                                user.employee.controls.leave &&
                                user.employee.controls.leave.includes('3'))) &&
                            !id && (
                                <Col lg="12">
                                    <div className="mt-4 mb-2">
                                        <Form.Item
                                            name="fistName"
                                            label="Fist Name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please choose employee!',
                                                },
                                            ]}
                                        >
                                            <Select
                                                mode="multiple"
                                                onChange={handleChangeEmployeesList}
                                                optionLabelProp="label"
                                                style={{ width: '100%', zIndex: 999 }}
                                            >
                                                {employees && employees.length > 0 && (
                                                    <Option value="all" label="All">
                                                        <Space>--All--</Space>
                                                    </Option>
                                                )}
                                                {listEmployeeTmp.map((item) => (
                                                    <Option value={item._id} label={item.firstName}>
                                                        <Space>{item.firstName}</Space>
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Col>
                            )} */}

                        <Col lg="12">
                            <div className="mt-4 mb-2">
                                <label htmlFor="label" className="title">
                                    {' '}
                                    <span>*</span> Leave Type
                                </label>
                                <Select
                                    showSearch
                                    id="label"
                                    style={{ width: '100%', zIndex: 999 }}
                                    value={leaveRequest.type}
                                    disabled={id || type === FORM_TYPE.VIEW ? true : false}
                                    onChange={(value) => {
                                        setLeaveRequest({ ...leaveRequest, type: value });
                                    }}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {leaveType &&
                                        leaveType.map((item, index) => (
                                            <Option key={index} value={item._id}>
                                                {`${item.description}`}{' '}
                                                {/* {item.leaveUnit === LEAVE_UNIT.HOURS
                                                    ? `- ${item.standardNumber / 60} hours`
                                                    : item.leaveUnit === LEAVE_UNIT.SPECIAL
                                                    ? ''
                                                    : `- ${item.standardNumber} days`} */}
                                            </Option>
                                        ))}
                                </Select>
                            </div>
                            {leaveRequest && leaveRequest.type && leaveSetting && leaveSetting.leaveUnit && (
                                <div className="mt-3 mb-2">Leave unit: {leaveSetting.leaveUnit}</div>
                            )}
                            {leaveRequest &&
                                leaveRequest.type &&
                                leaveSetting &&
                                leaveSetting &&
                                leaveSetting.leaveUnit &&
                                leaveSetting.leaveUnit !== LEAVE_UNIT.HOURS &&
                                leaveSetting.leaveUnit !== LEAVE_UNIT.SPECIAL && (
                                    <div>Number of leave remaining: {remaining}</div>
                                )}

                            {leaveRequest &&
                                leaveRequest.type &&
                                leaveSetting &&
                                leaveSetting.leaveUnit &&
                                leaveSetting.leaveUnit === LEAVE_UNIT.HOURS && (
                                    <div>Number of hours remaining: {remaining}</div>
                                )}
                        </Col>
                    </Row>

                    {(!leaveSetting || leaveSetting.leaveUnit !== LEAVE_UNIT.HOURS) && (
                        <Row lg="12" md="8" sm="6">
                            <Col lg="6">
                                <div className="mt-4 mb-4">
                                    <label htmlFor="from" className="title">
                                        <span>*</span> From
                                    </label>
                                    <Form.Item>
                                        <DatePicker
                                            value={
                                                type !== FORM_TYPE.CREATE
                                                    ? moment(new Date(leaveRequest.from), 'YYYY-MM-DD')
                                                    : moment(leaveRequest.from, 'YYYY-MM-DD')
                                            }
                                            name="date"
                                            format={dateFormatList}
                                            style={{ width: '100%', zIndex: 9999 }}
                                            id="from"
                                            selected={leaveRequest.from}
                                            onChange={handleChangeFrom}
                                            disabled={type === FORM_TYPE.VIEW || type === FORM_TYPE.EDIT ? true : false}
                                        />
                                    </Form.Item>
                                </div>
                                {leaveSetting &&
                                    leaveSetting.leaveUnit &&
                                    leaveSetting.leaveUnit === LEAVE_UNIT.HALF_DAY && (
                                        <div className="mt-4 mb-4">
                                            <label className="title">
                                                <span>*</span>Session
                                            </label>
                                            <Select
                                                style={{ width: '100%' }}
                                                disabled={
                                                    type === FORM_TYPE.VIEW || type === FORM_TYPE.EDIT ? true : false
                                                }
                                                value={leaveRequest.fromSession}
                                                onChange={(value) => {
                                                    let payloadTMP = { ...leaveRequest };
                                                    if (value === LEAVE_SESSION_VALUE.MORNING) {
                                                        let tmp = new Date(
                                                            new Date(leaveRequest.from).setHours(6, 0, 0, 0),
                                                        );
                                                        payloadTMP = { ...leaveRequest, from: tmp };
                                                    } else if (value === LEAVE_SESSION_VALUE.AFTERNOON) {
                                                        let tmp = new Date(
                                                            new Date(leaveRequest.from).setHours(13, 0, 0, 0),
                                                        );
                                                        payloadTMP = { ...leaveRequest, from: tmp };
                                                    }

                                                    setLeaveRequest({ ...payloadTMP, fromSession: value });
                                                }}
                                            >
                                                {LEAVE_SESSION.map((item, index) => (
                                                    <Option key={index} value={item.value}>
                                                        {item.label}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </div>
                                    )}

                                {leaveSetting &&
                                    leaveSetting.leaveUnit &&
                                    leaveSetting.leaveUnit === LEAVE_UNIT.SPECIAL && (
                                        <div className="mt-4 mb-4">
                                            <label className="title">
                                                <span>*</span>Time
                                            </label>
                                            <Form.Item>
                                                <TimePicker
                                                    value={
                                                        leaveRequest && leaveRequest.from
                                                            ? moment(leaveRequest.from)
                                                            : ''
                                                    }
                                                    name="date"
                                                    style={{ width: '100%', zIndex: 9999 }}
                                                    selected={leaveRequest.from}
                                                    onChange={(e) => {
                                                        handleChangeFromHours(leaveRequest.from, e);
                                                    }}
                                                    disabled={
                                                        type === FORM_TYPE.VIEW || type === FORM_TYPE.EDIT
                                                            ? true
                                                            : false
                                                    }
                                                    format={'HH:mm'}
                                                />
                                            </Form.Item>
                                        </div>
                                    )}
                            </Col>
                            <Col lg="6">
                                <div className="mt-4 mb-4">
                                    <label htmlFor="from" className="title">
                                        <span>*</span> To
                                    </label>
                                    <Form.Item>
                                        <DatePicker
                                            value={moment(leaveRequest.to, 'YYYY-MM-DD')}
                                            name="date"
                                            format={dateFormatList}
                                            style={{ width: '100%', zIndex: 9999 }}
                                            id="to"
                                            selected={leaveRequest.to}
                                            onChange={handleChangeTo}
                                            disabled={type === FORM_TYPE.VIEW || type === FORM_TYPE.EDIT ? true : false}
                                        />
                                    </Form.Item>
                                </div>
                                {leaveSetting &&
                                    leaveSetting.leaveUnit &&
                                    leaveSetting.leaveUnit === LEAVE_UNIT.HALF_DAY && (
                                        <div className="mt-4 mb-4">
                                            <label className="title">
                                                <span>*</span>Session
                                            </label>
                                            <Select
                                                style={{ width: '100%' }}
                                                disabled={
                                                    type === FORM_TYPE.VIEW || type === FORM_TYPE.EDIT ? true : false
                                                }
                                                value={leaveRequest.toSession}
                                                onChange={(value) => {
                                                    let payloadTMP = { ...leaveRequest };
                                                    if (value === LEAVE_SESSION_VALUE.MORNING) {
                                                        let tmp = new Date(
                                                            new Date(leaveRequest.to).setHours(12, 0, 0, 0),
                                                        );

                                                        payloadTMP = { ...leaveRequest, to: tmp };
                                                    } else if (value === LEAVE_SESSION_VALUE.AFTERNOON) {
                                                        let tmp = new Date(
                                                            new Date(leaveRequest.to).setHours(22, 0, 0, 0),
                                                        );
                                                        payloadTMP = { ...leaveRequest, to: tmp };
                                                    }
                                                    setLeaveRequest({ ...payloadTMP, toSession: value });
                                                }}
                                            >
                                                {LEAVE_SESSION.map((item, index) => (
                                                    <Option key={index} value={item.value}>
                                                        {item.label}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </div>
                                    )}

                                {leaveSetting &&
                                    leaveSetting.leaveUnit &&
                                    leaveSetting.leaveUnit === LEAVE_UNIT.SPECIAL && (
                                        <div className="mt-4 mb-4">
                                            <label className="title">
                                                <span>*</span>Time
                                            </label>
                                            <Form.Item>
                                                <TimePicker
                                                    value={
                                                        leaveRequest && leaveRequest.to ? moment(leaveRequest.to) : ''
                                                    }
                                                    name="date"
                                                    style={{ width: '100%', zIndex: 9999 }}
                                                    selected={leaveRequest.to}
                                                    onChange={(e) => {
                                                        handleChangeToHours(leaveRequest.to, e);
                                                    }}
                                                    disabled={
                                                        type === FORM_TYPE.VIEW || type === FORM_TYPE.EDIT
                                                            ? true
                                                            : false
                                                    }
                                                    format={'HH:mm'}
                                                />
                                            </Form.Item>
                                        </div>
                                    )}
                            </Col>
                        </Row>
                    )}

                    {leaveSetting && leaveSetting.leaveUnit && leaveSetting.leaveUnit === LEAVE_UNIT.HOURS && (
                        <Row lg="12" md="8" sm="6">
                            <Col lg="4">
                                <div className="mt-4 mb-4">
                                    <label htmlFor="from" className="title">
                                        <span>*</span> Date
                                    </label>
                                    <Form.Item>
                                        <DatePicker
                                            value={moment(leaveRequest.from, 'YYYY-MM-DD')}
                                            name="date"
                                            format={dateFormatList}
                                            style={{ width: '100%', zIndex: 9999 }}
                                            id="from"
                                            selected={leaveRequest.from}
                                            onChange={handleChangeDate}
                                            disabled={type === FORM_TYPE.VIEW || type === FORM_TYPE.EDIT ? true : false}
                                        />
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col lg="4">
                                <div className="mt-4 mb-4">
                                    <label htmlFor="from" className="title">
                                        <span>*</span> From
                                    </label>
                                    <Form.Item>
                                        <TimePicker
                                            value={leaveRequest && leaveRequest.from ? moment(leaveRequest.from) : ''}
                                            name="date"
                                            style={{ width: '100%', zIndex: 9999 }}
                                            selected={leaveRequest.from}
                                            onChange={(e) => {
                                                handleChangeFromHours(leaveRequest.from, e);
                                            }}
                                            disabled={type === FORM_TYPE.VIEW || type === FORM_TYPE.EDIT ? true : false}
                                            format={'HH:mm'}
                                        />
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col lg="4">
                                <div className="mt-4 mb-4">
                                    <label htmlFor="from" className="title">
                                        <span>*</span> To
                                    </label>
                                    <Form.Item>
                                        <TimePicker
                                            value={leaveRequest && leaveRequest.to ? moment(leaveRequest.to) : ''}
                                            name="date"
                                            format={'HH:mm'}
                                            style={{ width: '100%', zIndex: 9999 }}
                                            selected={leaveRequest.to}
                                            onChange={(e) => {
                                                handleChangeToHours(leaveRequest.to, e);
                                            }}
                                            disabled={type === FORM_TYPE.VIEW || type === FORM_TYPE.EDIT ? true : false}
                                        />
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                    )}

                    <Row lg="12" md="8" sm="6">
                        <Col lg="12">
                            <div className="mt-2 mb-4">
                                <label htmlFor="reason" className="title">
                                    {leaveSetting && leaveSetting.reasonRequired === true && <span>* </span>}Reason
                                </label>
                                <Input.TextArea
                                    placeholder="Input your reason"
                                    autoSize={{ minRows: 3, maxRows: 10 }}
                                    value={leaveRequest.reason}
                                    disabled={id || type === FORM_TYPE.VIEW ? true : false}
                                    id="reason"
                                    onChange={(e) => {
                                        setLeaveRequest({ ...leaveRequest, reason: e.target.value });
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>

                    {type === FORM_TYPE.CREATE && (
                        <Row lg="12" md="8" sm="6">
                            <Col lg="12">
                                <div className="mt-2 mb-4">
                                    <label htmlFor="attachment" className="title">
                                        {leaveSetting && leaveSetting.attachmentRequired === true && <span>* </span>}
                                        Attachment
                                    </label>

                                    <Upload
                                        onRemove={onRemove}
                                        beforeUpload={beforeUpload}
                                        ref={uploadRef}
                                        fileList={fileList}
                                    >
                                        <Button icon={<UploadOutlined />}>Select File</Button>
                                    </Upload>
                                </div>
                            </Col>
                        </Row>
                    )}

                    {type !== FORM_TYPE.CREATE && leaveRequest.attachments && leaveRequest.attachments.length > 0 && (
                        <Row lg="12" md="8" sm="6">
                            <Col lg="12">
                                <div className="mt-2 mb-4">
                                    <label htmlFor="attachment" className="title">
                                        <span>*</span> Attachment
                                    </label>

                                    {leaveRequest.attachments.map((item) => (
                                        // <p onClick={() => handleOpen(Object.values(item)[0])} className="link">
                                        //     - {Object.keys(item)[0]}
                                        // </p>
                                        <div>
                                            <a href={Object.values(item)[0]} download className="text-dark">
                                                - {Object.keys(item)[0]}
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    )}

                    {type !== FORM_TYPE.CREATE && (
                        <>
                            <Row form>
                                <Col md="4" className="form-group">
                                    <div className="mt-3 mb-3">
                                        <Radio.Group
                                            onChange={(e) =>
                                                setLeaveRequest({ ...leaveRequest, status: e.target.value })
                                            }
                                            disabled={type === FORM_TYPE.VIEW ? true : false}
                                            value={leaveRequest.status}
                                        >
                                            <Radio value={LEAVE_STATUS.APPROVE}>Approve</Radio>
                                            <Radio value={LEAVE_STATUS.REJECT}>Reject</Radio>
                                        </Radio.Group>
                                    </div>
                                </Col>
                            </Row>

                            <Row lg="12" md="8" sm="6">
                                <Col lg="12">
                                    <div className="mt-2 mb-4">
                                        <TextArea
                                            placeholder="Input your reason approve"
                                            rows={4}
                                            value={leaveRequest.reasonApprove}
                                            disabled={type === FORM_TYPE.VIEW ? true : false}
                                            onChange={(e) => {
                                                setLeaveRequest({
                                                    ...leaveRequest,
                                                    reasonApprove: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}
                </Form>
            </Modal>
            <Modal title="Warning" zIndex={999} open={isWarning} onOk={handleOkWarning} onCancel={handleCancelWarning}>
                <Row className="mt-2 mb-2">
                    <Col>
                        <p>There is already a leave request on the selected date, do you want to continue?</p>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};
export default LeaveCreateForm;
