import { Form, Input, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Row } from 'shards-react';
import { companiesAPI } from '../../apis/companiesAPI';
import { departmentAPI } from '../../apis/departmentAPI';
import { employeesAPI } from '../../apis/employeesAPI';
import { historiesAPI } from '../../apis/historiesAPI';
import { DEPARTMENT } from '../../global/department/departmentInterface';
import { ROLE, TYPE_NOTIFICATION } from '../../helpers/const';
import { getError } from '../../helpers/getErrorServer';
import { useDepartment, useLoading, useUser } from '../../hooks';

const DepartmentCreateForm = ({ visible, onCreate, onCancel, isChange, setIsChange, setAdd }) => {
    const [form] = Form.useForm();
    const { user } = useUser();
    const [companies, setCompanies] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [departmentRequest, setDepartmentRequest] = useState(DEPARTMENT);
    const { setLoading } = useLoading();
    const { fetchDepartment } = useDepartment();

    // const regName = /^[a-zA-Z0-9._-\s]{0,50}$/;
    // const regPhone = /^[0-9]{0,30}$/;

    useEffect(() => {
        (async () => {
            if (user && user.role) {
                await loadCompanies();

                if (user.role === ROLE.COMPANY) {
                    setDepartmentRequest({
                        ...departmentRequest,
                        companyID: user.company._id,
                    });
                    await loadEmployees(user.company._id);
                }
                if (user.role === ROLE.COMPANYSTAFF) {
                    setDepartmentRequest({
                        ...departmentRequest,
                        companyID: user.employee.company._id,
                    });
                    await loadEmployees(user.employee.company._id);
                }
            }
        })();
    }, [user, isChange]);

    const loadCompanies = async () => {
        try {
            if (user.role === ROLE.ADMIN || user.role === ROLE.SYSTEMSTAFF) {
                const { data } = await companiesAPI.get();
                setCompanies(data.items);
            }
            if (user.role === ROLE.COMPANY) {
                const { data } = await companiesAPI.getById(user.company._id);
                setCompanies(data);
            }
            if (user.role === ROLE.COMPANYSTAFF) {
                const { data } = await companiesAPI.getById(user.employee.company._id);
                setCompanies(data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const loadEmployees = async (id) => {
        try {
            if (id) {
                const { data } = await employeesAPI.getDepartment(id);
                setEmployees(data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const submit = async () => {
        try {
            // if (!checkValidate()) return;
            setLoading(true);
            const department = await departmentAPI.add(departmentRequest);
            await employeesAPI.setDepartment(departmentRequest.departmentHead, department.data._id);
            if (department && user && user.role && user.role === ROLE.COMPANYSTAFF) {
                await historiesAPI.add({
                    title: 'DEPARTMENT',
                    desc: `Client HR ${user.employee.firstName} just add new department`,
                    type: TYPE_NOTIFICATION.DEPARTMENT,
                    employee: user.employee._id,
                    idRequest: department.data._id,
                    toCompany: true,
                });
            }

            toast.success('Add successfully!');

            setDepartmentRequest({
                ...departmentRequest,
                departmentName: '',
                departmentHead: '',
            });
            await loadEmployees(departmentRequest.companyID);
            setAdd((prev) => prev + 1);

            setLoading(false);
            onCancel();
        } catch (error) {
            toast.error(getError(error));
            setLoading(false);
        }
    };

    return (
        <Modal
            open={visible}
            title=" Add Departments"
            okText="Add"
            cancelText="Cancel"
            onCancel={onCancel}
            // footer={null}
            width="700px"
            height="500px"
            zIndex={999}
            onOk={() => {
                form.validateFields()
                    .then((values) => {
                        form.resetFields();
                        submit();
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form form={form} layout="vertical" name="form_in_modal" initialValues={{ modifier: 'public' }}>
                <Row lg="12" md="8" sm="6">
                    {(user.role === ROLE.ADMIN || user.role === ROLE.SYSTEMSTAFF) && (
                        <Col lg="12">
                            <div className="mt-4 mb-4">
                                <label htmlFor="company">
                                    {' '}
                                    <span>*</span> Company
                                </label>
                                <Select
                                    showSearch
                                    id="company"
                                    style={{ width: '100%', zIndex: 999 }}
                                    value={departmentRequest.companyID ? departmentRequest.companyID : ''}
                                    onChange={(e) => setDepartmentRequest({ ...departmentRequest, companyID: e })}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {companies.map((company, index) => (
                                        <Select.Option key={index} value={company._id}>
                                            {company.companyName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                    )}
                </Row>

                <Form.Item
                    name="title"
                    label="Department name"
                    rules={[{ required: true, message: 'Please input the name of department!' }]}
                >
                    <Input
                        type="text"
                        placeholder="ex Marketing"
                        id="title"
                        value={form.setFieldValue(departmentRequest.departmentName)}
                        onChange={(e) => {
                            setDepartmentRequest({ ...departmentRequest, departmentName: e.target.value });
                        }}
                    />
                </Form.Item>
                <Row lg="12" md="8" sm="6">
                    <Col lg="12">
                        <Form.Item
                            name="departmentHead"
                            label="Department head"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please choose department head!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                id="departmentHead"
                                style={{ width: '100%', zIndex: 999 }}
                                value={departmentRequest.departmentHead ? departmentRequest.departmentHead : ''}
                                onChange={(e) => setDepartmentRequest({ ...departmentRequest, departmentHead: e })}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {employees.map((employee, index) => (
                                    <Select.Option key={index} value={employee._id}>
                                        {employee.firstName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default DepartmentCreateForm;
