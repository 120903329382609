import React, { useEffect } from 'react';
import { Col, Row } from 'shards-react';
import { useUser } from '../../../hooks';

function SDL() {
    const { user, getMe } = useUser();

    useEffect(() => {
        getData();
    }, [user]);

    const getData = async () => {
        if (!user) {
            getMe();
        }
    };

    return (
        <div className="padding_100">
            <Row>
                <Col lg="12">
                    <div className="d-flex justify-content-between align-items-center mb-1 mt-4">
                        <h5 className="title-font">{'  Skills Development Levy (SDL)'}</h5>
                    </div>
                </Col>
                <Col lg="12" md="6" sm="12">
                    <span htmlFor="da" className="text bold">
                        SDL payable by employer deducted at 0.25% of monthly total salary. Min deduction is $2 and
                        maximum is $11.25
                    </span>
                </Col>
            </Row>
        </div>
    );
}

export default SDL;
