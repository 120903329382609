import { Card, Modal } from 'antd';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { CardHeader, Col, Container, FormInput, InputGroup, InputGroupAddon, InputGroupText, Row } from 'shards-react';
import { MdDeleteForever } from 'react-icons/md';
import PageTitle from '../components/common/PageTitle';
import { getError } from '../helpers/getErrorServer';
import { useLoading, useUser } from '../hooks';
import { AiFillPlusCircle } from 'react-icons/ai';
import { FORM_TYPE } from '../helpers/const';
import ListTraining from '../components/training/ListTraining';
import TrainingForm from '../components/training/TrainingForm';
import { trainingAPI } from '../apis/trainingAPI';

function Training() {
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [reload, setReload] = useState(0);
    const { user, getMe } = useUser();
    const [deleteList, setDeleteList] = useState([]);
    const [filter, setFilter] = useState({ page: 1 });
    const [type, setType] = useState(FORM_TYPE.CREATE);
    const [chooseList, setChooseList] = useState('joined');
    const { setLoading } = useLoading();

    const showModalDelete = () => {
        setOpenDelete(true);
    };

    const handleChooseList = (value) => {
        setChooseList(value);
    };

    const handleDeleteOk = async () => {
        try {
            if (deleteList.length < 1) {
                setOpenDelete(false);
                toast.warning('Have not chosen a training course yet');
            } else {
                setLoading(true);
                for await (const item of deleteList) {
                    await trainingAPI.removeTraining(item);
                }
                setLoading(false);
                setDeleteList([]);
                setOpenDelete(false);
                setReload((e) => e + 1);
                toast.success('Success');
            }
        } catch (error) {
            setLoading(false);
            toast.error(getError(error));
        }
    };

    const handleDeleteCancel = () => {
        setOpenDelete(false);
    };

    const showModal = () => {
        setType(FORM_TYPE.CREATE);
        setOpen(true);
    };

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle
                    title="Training"
                    subtitle={<span className="route-main">Training</span>}
                    className="text-sm-left mb-3 "
                />
            </Row>
            <Card small className="h-100 mt-4 w-100">
                <CardHeader className="border-bottom">
                    <div>
                        <span className="ct-title">
                            {user.role === 'company' || user.role === 'company-staff'
                                ? 'List of Training'
                                : chooseList === 'joined'
                                ? 'List training joined'
                                : 'List training of company'}
                        </span>
                    </div>
                </CardHeader>
                <div className="ct-box">
                    <div className="col-xl-12 col-lg-12">
                        <Row className="p-0" lg="12" sm="12" md="8" xl="12">
                            <Col xl="4" lg="12" md="12" sm="12" className="p-1">
                                <InputGroup>
                                    <FormInput
                                        placeholder="Input Search Text"
                                        onChange={(e) => setFilter({ ...filter, search: e.target.value })}
                                    />
                                    <InputGroupAddon type="append">
                                        <InputGroupText className="pointer">
                                            <i className="material-icons" style={{ fontSize: '25px' }}>
                                                search
                                            </i>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                            <Col xl="4"></Col>
                            {user.role === 'company' || user.role === 'company-staff' ? (
                                <Col xl="4" lg="12" md="12" sm="12">
                                    <Row>
                                        <Col lg="6" md="6" sm="12" className="p-1">
                                            <button className="button_delete" onClick={() => showModalDelete()}>
                                                <MdDeleteForever size={20} />
                                                <span className="text__red">Delete</span>
                                            </button>
                                        </Col>
                                        <Col lg="6" md="6" sm="12" className="p-1">
                                            <div
                                                className="text-center rounded p-2 d-flex justify-content-center align-items-center button__warning"
                                                onClick={() => showModal()}
                                            >
                                                <AiFillPlusCircle size={25} />
                                                <span className="text ml-2">Add Training</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            ) : (
                                <Col xl="4" lg="12" md="12" sm="12">
                                    <Row>
                                        <Col lg="6" md="6" sm="12" className="p-1">
                                            <div
                                                className={
                                                    chooseList === 'joined'
                                                        ? 'text-center rounded p-2 d-flex justify-content-center align-items-center  button__chooseList'
                                                        : 'text-center rounded p-2 d-flex justify-content-center align-items-center button__training '
                                                }
                                                onClick={() => handleChooseList('joined')}
                                            >
                                                <span className="text ml-2">Joined</span>
                                            </div>
                                        </Col>
                                        <Col lg="6" md="6" sm="12" className="p-1">
                                            <div
                                                className={
                                                    chooseList === 'joined'
                                                        ? 'text-center rounded p-2 d-flex justify-content-center align-items-center  button__training'
                                                        : 'text-center rounded p-2 d-flex justify-content-center align-items-center  button__chooseList'
                                                }
                                                onClick={() => handleChooseList('not-participate')}
                                            >
                                                <span className="text ml-2">Not participate</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                        </Row>
                        <Row>
                            <Col className="p-0">
                                <ListTraining
                                    user={user}
                                    refetch={reload}
                                    setRefetch={setReload}
                                    deleteList={deleteList}
                                    setDeleteList={setDeleteList}
                                    filter={filter}
                                    setFilter={setFilter}
                                    type={type}
                                    setType={setType}
                                    chooseList={chooseList}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </Card>

            <TrainingForm visible={open} setVisible={setOpen} type={type} setReload={setReload} />
            <Modal
                title={
                    <>
                        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.4167 0.833344H2.58333C1.56583 0.833344 0.75 1.65834 0.75 2.66668V15.5C0.75 16.5083 1.56583 17.3333 2.58333 17.3333H6.25L9 20.0833L11.75 17.3333H15.4167C16.425 17.3333 17.25 16.5083 17.25 15.5V2.66668C17.25 1.65834 16.425 0.833344 15.4167 0.833344ZM9.91667 15.5H8.08333V13.6667H9.91667V15.5ZM11.8142 8.39584L10.9892 9.23918C10.3292 9.90834 9.91667 10.4583 9.91667 11.8333H8.08333V11.375C8.08333 10.3667 8.49583 9.45001 9.15583 8.78084L10.2925 7.62584C10.6317 7.29584 10.8333 6.83751 10.8333 6.33334C10.8333 5.32501 10.0083 4.50001 9 4.50001C7.99167 4.50001 7.16667 5.32501 7.16667 6.33334H5.33333C5.33333 4.30751 6.97417 2.66668 9 2.66668C11.0258 2.66668 12.6667 4.30751 12.6667 6.33334C12.6667 7.14001 12.3367 7.87334 11.8142 8.39584Z"
                                fill="#FCBB0A"
                            />
                        </svg>

                        <p className="title-modal-custom">{`Are you sure delete ${deleteList.length || 0} items?`}</p>
                    </>
                }
                open={openDelete}
                onOk={handleDeleteOk}
                onCancel={handleDeleteCancel}
            >
                <p>By delete messages</p>
            </Modal>
        </Container>
    );
}

export default Training;
