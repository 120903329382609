import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Card, CardHeader } from 'shards-react';
import TableAttendaceSheet from './TableAttendaceSheet';
import TableEmployeeAttendance from './TableEmployeeAttendance';
import TableMyAttendance from './TableMyAttendance';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ padding: '0 20px 20px 20px' }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function TabAttendance() {
   
    const [value, setValue] = useState(0);
    

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Card>
            <CardHeader>
                <span className="ct-title">Attendance</span>
            </CardHeader>

            <Tabs className="" value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab className="tab-title" label="My attendance" {...a11yProps(0)} />
                <Tab className="tab-title" label="Employee attendance" {...a11yProps(1)} />
                <Tab className="tab-title" label="Attendance sheet" {...a11yProps(2)} />
            </Tabs>

            <TabPanel value={value} index={0}>
                <TableMyAttendance />
            </TabPanel>

            <TabPanel value={value} index={1}>
                <TableEmployeeAttendance />
            </TabPanel>

            <TabPanel value={value} index={2}>
                <TableAttendaceSheet />
            </TabPanel>
        </Card>
    );
}
