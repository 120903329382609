import React, { useEffect, useState } from 'react';

import { employeesAPI } from '../../apis/employeesAPI';
import { ROLE } from '../../helpers/const';
import { useLoading, useUser } from '../../hooks';
import Papa from 'papaparse';
import { Modal } from 'antd';
import { toast } from 'react-toastify';
import { getError } from '../../helpers/getErrorServer';
import { permanentResidentsAPI } from '../../apis/permanentResidentsAPI';
export default function ImportCSV({ setRefresh }) {
    const { user, getMe } = useUser();
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const { setLoading } = useLoading();
    const [inputKey, setInputKey] = useState(Date.now());

    const genderList = ['male', 'female'];
    const typeList = ['local-staff', 'work-permit', 's-pass', 'ep'];

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        Papa.parse(file, {
            header: true,
            complete: (results) => {
                let rs = [];

                results.data.map((item) => {
                    let newObj = {};
                    for (let key in item) {
                        let newKey = key
                            .trim()
                            .replace(/\s+/g, '')
                            .toLowerCase();
                        newObj[newKey] = item[key];
                    }

                    if (
                        newObj.email ||
                        newObj.birthday ||
                        newObj.employeesid ||
                        newObj.firstname ||
                        newObj.gender ||
                        newObj.lastname ||
                        newObj.type
                    ) {
                        rs = [...rs, newObj];
                    }
                });
                console.log(rs);
                setData(rs);
            },
        });
    };

    const handleOk = async () => {
        setLoading(true);
        const rs = await verify();
        if (rs.pass === false) {
            toast.error(rs.mess);
            setData([]);
            setInputKey(Date.now());
            setLoading(false);
            return;
        }
        let companyID;

        if (user && user.employee && user.employee.company && user.employee.company._id) {
            companyID = user.employee.company._id;
        } else if (user && user.company && user.company._id) {
            companyID = user.company._id;
        }
        try {
            for await (const db of data) {
                const payload = {
                    email: db.email,
                    employeesId: db.employeesid,
                    firstName: db.firstname,
                    lastName: db.lastname,
                    gender: db.gender,
                    birthday: new Date(db.birthday),
                    companyID,
                    type: db.type,
                };
                const result = await employeesAPI.add(payload);
                await permanentResidentsAPI.create({
                    company: companyID.toString(),
                    employee: result.data._id.toString(),
                    status: 'Citizens',
                });
            }
            setRefresh((prev) => prev + 1);
            setOpen(false);
            setInputKey(Date.now());
            setLoading(false);
            toast.success('Success');
        } catch (error) {
            setOpen(false);
            setLoading(false);
            setData([]);
            setInputKey(Date.now());
            toast.error(getError(error));
        }
    };
    const handleCancel = () => {
        setOpen(false);
        setData([]);
        setInputKey(Date.now());
    };

    const isValidDate = (dateString) => {
        const date = new Date(dateString);
        return !isNaN(date.getTime());
    };

    const verify = async () => {
        let rs = {
            pass: true,
            mess: '',
        };
        if (data.length < 1) {
            return {
                pass: false,
                mess: `Data is empty!`,
            };
        }
        let emailSet = new Set();
        let n = 1;
        for await (const employee of data) {
            //verify email
            if (!employee.email) {
                return {
                    pass: false,
                    mess: `Data ${n}: missing email!`,
                };
            }
            if (emailSet.has(employee.email)) {
                // Check if email is already in the set
                return {
                    pass: false,
                    mess: `Data ${n}: email is duplicated!`,
                };
            }
            emailSet.add(employee.email);

            const { data } = await employeesAPI.verifyEmail(employee.email);
            if (data === false) {
                return {
                    pass: false,
                    mess: `Data ${n}: email already exists!`,
                };
            }

            //verify birthday
            if (employee.birthday && !isValidDate(employee.birthday)) {
                return {
                    pass: false,
                    mess: `Data ${n}: birthday invalid!`,
                };
            }
            //verify id
            if (!employee.employeesid) {
                return {
                    pass: false,
                    mess: `Data ${n}: missing employees id!`,
                };
            }

            //first name
            if (!employee.firstname) {
                return {
                    pass: false,
                    mess: `Data ${n}: missing first name!`,
                };
            }

            //last name
            if (!employee.lastname) {
                return {
                    pass: false,
                    mess: `Data ${n}: missing last name!`,
                };
            }

            //gender
            if (!employee.gender) {
                return {
                    pass: false,
                    mess: `Data ${n}: missing gender!`,
                };
            }

            if (!genderList.includes(employee.gender)) {
                return {
                    pass: false,
                    mess: `Data ${n}: gender invalid. 'male' or 'female'!`,
                };
            }

            //type
            if (!employee.type) {
                return {
                    pass: false,
                    mess: `Data ${n}: missing type!`,
                };
            }

            if (!typeList.includes(employee.type)) {
                return {
                    pass: false,
                    mess: `Data ${n}: type invalid. 'local-staff', 'work-permit', 's-pass' or 'ep'!`,
                };
            }

            n++;
        }
        n = 1;
        return rs;
    };

    return (
        <>
            <div className="text-center rounded p-2 d-flex justify-content-center align-items-center button__warning">
                <span className="material-icons">download</span>
                <span className="text ml-2" onClick={() => setOpen(true)}>
                    Import Employee
                </span>
            </div>
            <Modal
                title={
                    <>
                        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.4167 0.833344H2.58333C1.56583 0.833344 0.75 1.65834 0.75 2.66668V15.5C0.75 16.5083 1.56583 17.3333 2.58333 17.3333H6.25L9 20.0833L11.75 17.3333H15.4167C16.425 17.3333 17.25 16.5083 17.25 15.5V2.66668C17.25 1.65834 16.425 0.833344 15.4167 0.833344ZM9.91667 15.5H8.08333V13.6667H9.91667V15.5ZM11.8142 8.39584L10.9892 9.23918C10.3292 9.90834 9.91667 10.4583 9.91667 11.8333H8.08333V11.375C8.08333 10.3667 8.49583 9.45001 9.15583 8.78084L10.2925 7.62584C10.6317 7.29584 10.8333 6.83751 10.8333 6.33334C10.8333 5.32501 10.0083 4.50001 9 4.50001C7.99167 4.50001 7.16667 5.32501 7.16667 6.33334H5.33333C5.33333 4.30751 6.97417 2.66668 9 2.66668C11.0258 2.66668 12.6667 4.30751 12.6667 6.33334C12.6667 7.14001 12.3367 7.87334 11.8142 8.39584Z"
                                fill="#FCBB0A"
                            />
                        </svg>

                        <p className="title-modal-custom">Import file csv</p>
                    </>
                }
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <input key={inputKey} type="file" accept=".csv" onChange={(e) => handleFileUpload(e)} />
            </Modal>
        </>
    );
}
