import axios from 'axios';

const path = 'individual-leave-setting/';

const add = async (payload) => {
    return await axios.post(path, payload);
};

const getByEmployeeAndType = async (employee, type) => {
    return await axios.get(path + employee + '/' + type);
};

const getByEmployee = async (id) => {
    return await axios.get(path + id);
};

const update = async (id, payload) => {
    return await axios.patch(path + id, payload);
};

const softDelete = async (id) => {
    return await axios.delete(path + id);
};

export const individualLeaveSettingAPI = {
    add,
    getByEmployee,
    update,
    softDelete,
    getByEmployeeAndType,
};
