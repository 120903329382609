import axios from 'axios';

const path = 'countries/';

const create = async () => {
    return await axios.post(path);
};

const getById = async (id) => {
    return (await axios.get(path + id)).data;
};

const getAll = async () => {
    return await axios.get(path);
};

export const countryAPI = {
    create,
    getById,
    getAll,
};
