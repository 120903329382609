import { Avatar, DatePicker, Form, Image, Input, Modal, Radio, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Card, Col, Row } from 'shards-react';
import { employeesAPI } from '../../apis/employeesAPI';
import { expenseClaimAPI } from '../../apis/expenseClaimAPI';
import { fileManagementAPI } from '../../apis/fileManagementAPI';
import { historiesAPI } from '../../apis/historiesAPI';
import { uploadImageAPI } from '../../apis/uploadImageAPI';
import { EXPENSE_CLAIM, EXPENSE_CLAIM_PAID_BY, EXPENSE_CLAIM_STATUS } from '../../global/expenseClaim';
import { FILE_UPLOAD_FOR, FILE_UPLOAD_TYPE } from '../../global/fileManagement';
import { FORMAT, FORM_TYPE, ROLE, TYPE_NOTIFICATION } from '../../helpers/const';
import { getError } from '../../helpers/getErrorServer';
import { useExpenseClaim, useLoading, useUser } from '../../hooks';
import IMG_AVT from '../../images/avatars/0.jpg';

const ExClaimCreateForm = ({ visible, onCancel, id, type = FORM_TYPE.VIEW, pagePending, pageHistory, value }) => {
    // const { TextArea } = Input;
    const [form] = Form.useForm();
    const { user } = useUser();
    const { setLoading } = useLoading();
    const { fetchExpenseClaims, getExpenseClaimByID } = useExpenseClaim();
    const dateFormatList = [FORMAT.DATE, FORMAT.DATE];
    const { Option } = Select;
    const [employees, setEmployees] = useState([]);
    const [expenseClaimRequest, setExpenseClaimRequest] = useState(EXPENSE_CLAIM);
    const [response, setResponse] = useState();
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [fileImage, setFileImage] = useState();
    const [render, setRender] = useState(0);
    const [idCompany, setIdCompany] = useState();

    const cancelModal = () => {
        setImagePreviewUrl(null);
        form.resetFields();
        onCancel();
    };

    useEffect(() => {
        if (user && user.company && user.company._id) {
            setIdCompany(user.company._id);
        } else if (user && user.employee && user.employee.company && user.employee.company._id) {
            setIdCompany(user.employee.company._id);
        }
    }, [user]);
    useEffect(() => {
        if (user.role) {
            loadEmployees();
            if (
                user &&
                user.role === ROLE.USER &&
                user.position &&
                user.position.expenseClaim &&
                !user.position.expenseClaim.includes('3') &&
                (!user.employee.controls ||
                    !user.employee.controls.expenseClaim ||
                    !user.employee.controls.expenseClaim.includes('3'))
            ) {
                setExpenseClaimRequest({
                    ...expenseClaimRequest,
                    employee: user.employee._id,
                });
            }
        }
    }, [user, render]);

    useEffect(() => {
        if (id) {
            (async () => {
                const { data } = await expenseClaimAPI.getById(id);
                if (data) {
                    setExpenseClaimRequest(data);
                }
            })();
        }
    }, [id]);

    useEffect(() => {
        if (value !== undefined) {
            localStorage.setItem('tabEX', JSON.stringify(value));
        }
    }, [value]);

    useEffect(() => {
        if (pagePending !== undefined) {
            localStorage.setItem('pagePendingEX', JSON.stringify(pagePending));
        }
    }, [pagePending]);

    useEffect(() => {
        if (pageHistory !== undefined) {
            localStorage.setItem('pageHistoryEX', JSON.stringify(pageHistory));
        }
    }, [pageHistory]);

    const loadEmployees = async () => {
        try {
            if (user.role === ROLE.COMPANY) {
                const { data } = await employeesAPI.getAllCompany(user.company._id);
                setEmployees(data);
            } else if (user && (user.role === ROLE.USER || user.role === ROLE.COMPANYSTAFF)) {
                const { data } = await employeesAPI.getAllCompany(user.employee.company._id);
                setEmployees(data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const submit = () => {
        if (id) {
            editExpenseClaimRequest();
        } else {
            createExpenseClaimRequest();
        }
    };

    const checkValidate = () => {
        if (!expenseClaimRequest.employee || expenseClaimRequest.employee.length < 1) {
            toast.error('Please select employee!');
            return false;
        }
        if (!expenseClaimRequest.itemName || expenseClaimRequest.itemName.length < 1) {
            toast.error('Please enter item name!');
            return false;
        }
        if (!expenseClaimRequest.from || expenseClaimRequest.from < 1) {
            toast.error('Please enter remark!');
            return false;
        }
        if (!expenseClaimRequest || expenseClaimRequest.amount.length < 1) {
            toast.error('Please enter amount!');
            return false;
        }
        if (!expenseClaimRequest.paidBy || expenseClaimRequest.paidBy.length < 1) {
            toast.error('Please select paid by!');
            return false;
        }
        return true;
    };

    const checkValidateEdit = () => {
        if (expenseClaimRequest.status === EXPENSE_CLAIM_STATUS.PENDING) {
            toast.error('Please approve or reject!');
            return false;
        }
        if (!expenseClaimRequest.reasonApprove) {
            toast.error('Please input the reason!');
            return false;
        }
        return true;
    };

    const createExpenseClaimRequest = async () => {
        try {
            if (!checkValidate()) return;
            setLoading(true);
            let newRequest;
            const tmp = {
                ...expenseClaimRequest,
                itemName: expenseClaimRequest.itemName.trim(),
                from: expenseClaimRequest.from.trim(),
            };

            if (fileImage) {
                const { data } = await uploadImageAPI.uploadMultipleFiles(fileImage, idCompany);

                if (data && data.url) {
                    newRequest = await expenseClaimAPI.add({ ...tmp, attachments: data.url });
                    const type = fileImage.type.includes('image') ? FILE_UPLOAD_TYPE.IMAGE : FILE_UPLOAD_TYPE.TEXT;
                    await fileManagementAPI.add({
                        company: idCompany,
                        uploadBy: user._id,
                        thumbnail: data.url,
                        name: data.original_filename,
                        uploadDate: new Date(data.created_at).getTime(),
                        size: data.bytes,
                        uploadFor: FILE_UPLOAD_FOR.EXPENSE_CLAIM,
                        request: newRequest.data._id,
                        type,
                        publicID: data.public_id,
                    });
                } else {
                    toast.error("Can't upload file");
                }
            } else {
                newRequest = await expenseClaimAPI.add(tmp);
            }

            if (newRequest && newRequest.data && newRequest.data._id) {
                getHistories(newRequest.data._id);
            }
            toast.success('Add success!');
            setImagePreviewUrl(null);
            setFileImage();
            setLoading(false);
            form.resetFields();
            setExpenseClaimRequest(EXPENSE_CLAIM);
            setRender((prev) => prev + 1);

            const valueTmp = value !== undefined ? value : localStorage.getItem('tabEX');
            const pagePendingTmp = pagePending !== undefined ? pagePending : localStorage.getItem('pagePendingEX');
            const pageHistoryTmp = pageHistory !== undefined ? pageHistory : localStorage.getItem('pageHistoryEX');

            if (valueTmp === '1') {
                await fetchExpenseClaims({
                    status: EXPENSE_CLAIM_STATUS.PENDING,
                    page: pagePendingTmp,
                });

                await fetchExpenseClaims({
                    status: EXPENSE_CLAIM_STATUS.APPROVE + ',' + EXPENSE_CLAIM_STATUS.REJECT,
                    page: pageHistoryTmp,
                });
            } else {
                fetchExpenseClaims({
                    status: EXPENSE_CLAIM_STATUS.PENDING,
                    page: pagePendingTmp,
                });
            }

            onCancel();
        } catch (error) {
            setImagePreviewUrl(null);
            setFileImage();
            setLoading(false);
            setRender((prev) => prev + 1);
            setExpenseClaimRequest(EXPENSE_CLAIM);
            console.log(error);
            toast.error(getError(error));
        }
    };

    const editExpenseClaimRequest = async () => {
        try {
            if (!checkValidateEdit()) return;

            setLoading(true);
            let request;

            const tmp = {
                ...expenseClaimRequest,
                itemName: expenseClaimRequest.itemName.trim(),
                from: expenseClaimRequest.from.trim(),
            };

            //const { data } = await uploadImageAPI.uploadAttachment(fileImage);
            const urlImg = await uploadImage(fileImage);

            if (urlImg) {
                request = await expenseClaimAPI.update(id, { ...tmp, attachments: urlImg });
            } else {
                request = await expenseClaimAPI.update(id, tmp);
            }
            if (request && request.data && request.data._id && request.data.employee) {
                getHistoriesToEmployee(request.data._id, request.data.employee);
            }

            if (
                request &&
                request.data &&
                request.data._id &&
                request.data.employee &&
                user &&
                user.role &&
                user.role !== ROLE.COMPANY
            ) {
                getHistoriesToCompany(request.data._id, request.data.employee);
            }
            setLoading(false);
            toast.success('Update successfully!');
            onCancel();
            // fetchExpenseClaims({
            //     status: EXPENSE_CLAIM_STATUS.PENDING,
            // });
            // fetchExpenseClaims({
            //     status: EXPENSE_CLAIM_STATUS.APPROVE + ',' + EXPENSE_CLAIM_STATUS.REJECT,
            //     page: pageHistory ? pageHistory : 1,
            // });
            if (value === 1) {
                fetchExpenseClaims({
                    status: EXPENSE_CLAIM_STATUS.APPROVE + ',' + EXPENSE_CLAIM_STATUS.REJECT,
                    page: pageHistory ? pageHistory : 1,
                });
            } else {
                fetchExpenseClaims({
                    status: EXPENSE_CLAIM_STATUS.PENDING,
                    page: pagePending ? pagePending : 1,
                });
            }

            setExpenseClaimRequest(EXPENSE_CLAIM);
        } catch (error) {
            console.log(error);
            setLoading(false);
            const message = error.response.data.message;
            if (message && typeof message === 'object') {
                toast.error(message[0]);
            } else if (message && typeof message === 'object') {
                toast.error(message[0]);
            }
        }
    };

    const getHistoriesToCompany = async (id, employee) => {
        await historiesAPI.add({
            title: 'NEW EXPENSE ClAIM REQUEST',
            desc: `${user.employee.firstName} just review leave request`,
            employee,
            type: TYPE_NOTIFICATION.EXPENSECLAIM,
            idRequest: id,
            toCompany: true,
        });
    };

    async function upLoadImage(e) {
        const file = e.target.files[0];
        setFileImage(file);
        const reader = new FileReader();
        reader.onload = (rs) => {
            setImagePreviewUrl(rs.target.result);
        };
        reader.readAsDataURL(file);
    }

    const uploadImage = async (file) => {
        if (file) {
            const { data } = await uploadImageAPI.upload(file);
            return data.url;
        }
    };

    useEffect(() => {
        if (response) {
            setExpenseClaimRequest({ ...expenseClaimRequest, attachments: response.data.filename });
        }
    }, [response]);

    const getHistories = async (id) => {
        const { data } = await employeesAPI.getById(expenseClaimRequest.employee);
        await historiesAPI.add({
            title: 'NEW EXPENSE ClAIM REQUEST',
            desc: `${data[0].firstName} just create new expense claim request`,
            employee: data[0]._id,
            type: TYPE_NOTIFICATION.EXPENSECLAIM,
            idRequest: id,
            toCompany: true,
            toCompanyStaff: true,
        });
    };

    const getHistoriesToEmployee = async (id, employee) => {
        await historiesAPI.add({
            title: 'EXPENSE CLAIMS REQUEST',
            desc: 'Your expense claims request just reviewed',
            employee,
            type: TYPE_NOTIFICATION.EXPENSECLAIM,
            idRequest: id,
            toEmployee: true,
        });
    };

    const handleBoxImage = () => {
        const input = document.getElementById('input_fiel_attachment');
        input.click();
    };

    const handleCancel = () => {
        form.resetFields();
        setExpenseClaimRequest(EXPENSE_CLAIM);
        setRender((prev) => prev + 1);
        onCancel();
    };

    return (
        <Modal
            open={visible}
            title="Expense Claim"
            okText="Submit"
            cancelText="Cancel"
            onCancel={handleCancel}
            width="700px"
            height="500px"
            zIndex={999}
            okButtonProps={type === 'view' && { style: { display: 'none' } }}
            onOk={() => {
                submit();
            }}
        >
            <Form form={form} layout="vertical" name="form_in_modal" initialValues={{ modifier: 'public' }}>
                <Row lg="12" md="8" sm="6">
                    {(id ||
                        (user &&
                            user.role === ROLE.USER &&
                            user.position &&
                            user.position.expenseClaim &&
                            !user.position.expenseClaim.includes('3') &&
                            (!user.employee.controls ||
                                !user.employee.controls.expenseClaim ||
                                !user.employee.controls.expenseClaim.includes('3')))) && (
                        <>
                            <Col lg="2">
                                <Avatar
                                    shape="square"
                                    size={80}
                                    src={
                                        <Image
                                            width={80}
                                            height={80}
                                            src={
                                                id &&
                                                expenseClaimRequest &&
                                                expenseClaimRequest.employee &&
                                                expenseClaimRequest.employee.user &&
                                                expenseClaimRequest.employee.user.avatar
                                                    ? expenseClaimRequest.employee.user.avatar
                                                    : !id &&
                                                      user &&
                                                      user.avatar &&
                                                      user.role === ROLE.USER &&
                                                      !user.position.expenseClaim.includes('3') &&
                                                      (!user.employee.controls ||
                                                          !user.employee.controls.expenseClaim ||
                                                          !user.employee.controls.expenseClaim.includes('3'))
                                                    ? user.avatar
                                                    : IMG_AVT
                                            }
                                        />
                                    }
                                />
                            </Col>

                            <Col lg="9">
                                <div className="title_name expence-claim">
                                    <div>
                                        <p>
                                            #
                                            {id && expenseClaimRequest && expenseClaimRequest.employee
                                                ? expenseClaimRequest.employee.employeesId
                                                : user && user.employee
                                                ? user.employee.employeesId
                                                : "can't get employee id"}{' '}
                                            -{' '}
                                            {id &&
                                            expenseClaimRequest &&
                                            expenseClaimRequest.employee &&
                                            expenseClaimRequest.employee.firstName
                                                ? expenseClaimRequest.employee.firstName
                                                : user && user.employee
                                                ? user.employee.firstName
                                                : "can't get name"}
                                        </p>
                                        <p>
                                            {id &&
                                            expenseClaimRequest &&
                                            expenseClaimRequest.employee &&
                                            expenseClaimRequest.employee.jobPosition
                                                ? expenseClaimRequest.employee.jobPosition
                                                : user && user.employee
                                                ? user.employee.jobPosition
                                                : "can't get job position"}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </>
                    )}
                </Row>

                <Row lg="12" md="8" sm="6">
                    {((user && user.role && user.role !== ROLE.USER && !id) ||
                        (user &&
                            user.position &&
                            user.position.expenseClaim &&
                            user.position.expenseClaim.includes('3')) ||
                        (user &&
                            user.employee &&
                            user.employee.controls &&
                            user.employee.controls.expenseClaim &&
                            user.employee.controls.expenseClaim.includes('3'))) && (
                        <Col lg="12">
                            <div className="mt-4 mb-4">
                                <label htmlFor="firstName">
                                    <span style={{ color: 'red' }}>*</span> First Name
                                </label>
                                <Select
                                    showSearch
                                    disabled={type === FORM_TYPE.VIEW ? true : false}
                                    style={{ width: '100%', zIndex: 999 }}
                                    value={
                                        expenseClaimRequest && expenseClaimRequest.employee
                                            ? expenseClaimRequest.employee
                                            : ''
                                    }
                                    onChange={(e) => {
                                        setExpenseClaimRequest({ ...expenseClaimRequest, employee: e });
                                    }}
                                    id="firstName"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {employees &&
                                        employees.map((employee, index) => (
                                            <Option key={index} value={employee._id}>
                                                {employee.firstName}
                                            </Option>
                                        ))}
                                </Select>
                            </div>
                        </Col>
                    )}
                    <Col lg="12">
                        {type === FORM_TYPE.CREATE && (
                            <div className="mt-4 mb-4">
                                <label htmlFor="itemName">
                                    <span style={{ color: 'red' }}>*</span> Item Name
                                </label>
                                <Input
                                    placeholder="ex. Stationary"
                                    value={expenseClaimRequest.itemName}
                                    onChange={(e) => {
                                        setExpenseClaimRequest({
                                            ...expenseClaimRequest,
                                            itemName: e.target.value,
                                        });
                                    }}
                                    id="itemName"
                                />
                            </div>
                        )}
                        {type !== FORM_TYPE.CREATE && (
                            <div className="mt-4 mb-4">
                                <label htmlFor="itemName">
                                    {' '}
                                    <span style={{ color: 'red' }}>*</span>Item Name
                                </label>
                                <Input
                                    placeholder="ex. Stationary"
                                    value={expenseClaimRequest.itemName}
                                    //disabled={type === FORM_TYPE.VIEW ? true : false}
                                    disabled={true}
                                    onChange={(e) => {
                                        setExpenseClaimRequest({ ...expenseClaimRequest, itemName: e.target.value });
                                    }}
                                    id="itemName"
                                />
                            </div>
                        )}
                    </Col>
                </Row>

                <Row lg="12" md="8" sm="6">
                    <Col lg="6">
                        {type === FORM_TYPE.CREATE && (
                            <div className="mb-4 mt-4">
                                <div>
                                    <label htmlFor="from">
                                        {' '}
                                        <span style={{ color: 'red' }}>*</span>Remarks
                                    </label>
                                    <Input
                                        value={expenseClaimRequest.from}
                                        placeholder="ex .For sales department"
                                        onChange={(e) => {
                                            setExpenseClaimRequest({ ...expenseClaimRequest, from: e.target.value });
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        {type !== FORM_TYPE.CREATE && (
                            <div className="mb-4">
                                <label htmlFor="from">
                                    <span style={{ color: 'red' }}>*</span>Remarks
                                </label>
                                <Input
                                    placeholder="ex. For sales department"
                                    value={expenseClaimRequest.from}
                                    //disabled={type === FORM_TYPE.VIEW ? true : false}
                                    disabled={true}
                                    onChange={(e) => {
                                        setExpenseClaimRequest({ ...expenseClaimRequest, from: e.target.value });
                                    }}
                                    id="itemName"
                                />
                            </div>
                        )}
                    </Col>
                    <Col lg="6">
                        {type === FORM_TYPE.CREATE && (
                            <div className="mb-4 mt-4">
                                <Form.Item name="date" label="Date" disabled={type === FORM_TYPE.VIEW ? true : false}>
                                    <DatePicker
                                        value={moment(expenseClaimRequest.date, 'YYYY-MM-DD')}
                                        name="date"
                                        format={dateFormatList}
                                        style={{ width: '100%', zIndex: 9999, position: 'relative' }}
                                        id="date"
                                        selected={expenseClaimRequest.date}
                                        onSelect={(date) =>
                                            setExpenseClaimRequest({ ...expenseClaimRequest, date: date })
                                        }
                                        disabled={type === FORM_TYPE.VIEW ? true : false}
                                    />
                                </Form.Item>
                            </div>
                        )}
                        {type !== FORM_TYPE.CREATE && (
                            <div className="mb-4">
                                <label htmlFor="date">Date</label>
                                <DatePicker
                                    value={moment(expenseClaimRequest.date, 'YYYY-MM-DD')}
                                    name="date"
                                    format={dateFormatList}
                                    style={{ width: '100%', zIndex: 9999, position: 'relative' }}
                                    id="date"
                                    selected={expenseClaimRequest.date}
                                    onSelect={(date) => setExpenseClaimRequest({ ...expenseClaimRequest, date: date })}
                                    //disabled={type === FORM_TYPE.VIEW ? true : false}
                                    disabled={true}
                                />
                            </div>
                        )}
                    </Col>
                </Row>

                <Row lg="12" md="8" sm="6">
                    <Col lg="6">
                        {type === FORM_TYPE.CREATE && (
                            <div className="mb-4">
                                <label htmlFor="amount">
                                    <span style={{ color: 'red' }}>*</span>Amount
                                </label>
                                <Input
                                    type="number"
                                    placeholder="ex. 107.50"
                                    value={expenseClaimRequest.amount}
                                    onChange={(e) => {
                                        setExpenseClaimRequest({ ...expenseClaimRequest, amount: e.target.value });
                                    }}
                                    id="amount"
                                />
                            </div>
                        )}
                        {type !== FORM_TYPE.CREATE && (
                            <div className="mb-4">
                                <label htmlFor="amount">
                                    <span style={{ color: 'red' }}>*</span>Amount
                                </label>
                                <Input
                                    type="number"
                                    placeholder="ex. 107.50"
                                    value={expenseClaimRequest.amount}
                                    //disabled={type === FORM_TYPE.VIEW ? true : false}
                                    disabled={true}
                                    onChange={(e) => {
                                        setExpenseClaimRequest({ ...expenseClaimRequest, amount: e.target.value });
                                    }}
                                    id="itemName"
                                />
                            </div>
                        )}
                    </Col>

                    <Col lg="6">
                        {type === FORM_TYPE.CREATE && (
                            <div className="mb-4">
                                <Form.Item
                                    name="paidBy"
                                    label="Paid by"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input paid by!',
                                        },
                                    ]}
                                    initialValue={''}
                                    disabled={type === FORM_TYPE.VIEW ? true : false}
                                >
                                    <Select
                                        value={expenseClaimRequest.paidBy}
                                        onChange={(e) => setExpenseClaimRequest({ ...expenseClaimRequest, paidBy: e })}
                                        style={{ width: '100%' }}
                                    >
                                        {EXPENSE_CLAIM_PAID_BY.map((item, index) => (
                                            <Option key={index} value={item.value}>
                                                {item.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                        )}
                        {type !== FORM_TYPE.CREATE && (
                            <div className="mb-4">
                                <label htmlFor="paidBy">Paid By</label>
                                <Select
                                    defaultValue=""
                                    value={expenseClaimRequest.paidBy}
                                    onChange={(e) => setExpenseClaimRequest({ ...expenseClaimRequest, paidBy: e })}
                                    style={{ width: '100%' }}
                                    //disabled={type === FORM_TYPE.VIEW ? true : false}
                                    disabled={true}
                                >
                                    {EXPENSE_CLAIM_PAID_BY.map((item, index) => (
                                        <Option key={index} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        )}
                    </Col>
                </Row>

                <Row>
                    <Col lg="6">
                        {type === FORM_TYPE.CREATE && (
                            <div className="mb-4">
                                <Form.Item
                                    name="attachments"
                                    label="Attachments"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                    disabled={type === FORM_TYPE.VIEW ? true : false}
                                >
                                    <Card small className="mb-4">
                                        <div
                                            className="mx-auto position-relative box_image"
                                            onClick={() => handleBoxImage()}
                                        >
                                            {imagePreviewUrl ? (
                                                <img src={imagePreviewUrl} alt="" id="img_attachments" width="150" />
                                            ) : (
                                                <div>
                                                    <div>+</div>
                                                    <div>Upload</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="input_avt_attachments">
                                            <input
                                                type="file"
                                                accept="image/*"
                                                name="image-upload"
                                                id="input_fiel_attachment"
                                                onChange={(e) => upLoadImage(e)}
                                            />
                                        </div>
                                    </Card>
                                </Form.Item>
                            </div>
                        )}
                        {type !== FORM_TYPE.CREATE && (
                            <div>
                                <label htmlFor="attachments">Attachments</label>
                                <div>
                                    <Avatar
                                        shape="square"
                                        name="avatar"
                                        size={300}
                                        src={
                                            <Image
                                                width={200}
                                                src={
                                                    expenseClaimRequest &&
                                                    expenseClaimRequest.attachments &&
                                                    expenseClaimRequest.attachments
                                                }
                                            />
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>

                {type !== FORM_TYPE.CREATE && (
                    <>
                        <Row form>
                            <Col md="4" className="form-group">
                                <div className="mt-3 mb-3">
                                    <Radio.Group
                                        onChange={(e) =>
                                            setExpenseClaimRequest({ ...expenseClaimRequest, status: e.target.value })
                                        }
                                        disabled={type === FORM_TYPE.VIEW ? true : false}
                                        value={expenseClaimRequest.status}
                                    >
                                        <Radio value={EXPENSE_CLAIM_STATUS.APPROVE}>Approve</Radio>
                                        <Radio value={EXPENSE_CLAIM_STATUS.REJECT}>Reject</Radio>
                                    </Radio.Group>
                                </div>
                            </Col>
                        </Row>
                        <Row lg="12" md="8" sm="6">
                            <Col lg="12">
                                <div className="mt-2 mb-4">
                                    <TextArea
                                        placeholder="Input your reason approve"
                                        rows={4}
                                        value={expenseClaimRequest.reasonApprove}
                                        disabled={type === FORM_TYPE.VIEW ? true : false}
                                        onChange={(e) => {
                                            setExpenseClaimRequest({
                                                ...expenseClaimRequest,
                                                reasonApprove: e.target.value,
                                            });
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </>
                )}
            </Form>
        </Modal>
    );
};

export default ExClaimCreateForm;
