import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { defaultAPI } from "../apis/defaultAPI";
import { UserContext } from "../contexts/userContext";

export default function UserProvider({ children }) {
  const [user, setUser] = useState({});

  const getMe = async () => {
    try {
      const { data } = await defaultAPI.getUser();
      setUser(data);

      return data;
    } catch (error) {
      toast.error(error);
    }
  }
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      getMe();
    }
  }, [localStorage.getItem("token")]);

  return (
    <UserContext.Provider value={{ user, getMe }}>{children}</UserContext.Provider>
  );
}
