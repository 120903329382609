import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'shards-react';
import { ROLE } from '../../helpers/const';
import { useUser } from '../../hooks';
import PageTitle from './../common/PageTitle';
import { Select, Alert, Input, Divider, Checkbox } from 'antd';
import { companiesAPI } from './../../apis/companiesAPI.js';
import { toast } from 'react-toastify';
import { getError } from '../../helpers/getErrorServer';

const PermanentResidentsSetting = () => {
    const { user, getMe } = useUser();
    const history = useHistory();
    const { Option } = Select;
    const [defaultPR, setDefaultPR] = useState('Citizens');
    const [data, setData] = useState();

    useEffect(() => {
        if (!user) {
            getMe();
        }
        user &&
            user.role &&
            user.role !== ROLE.COMPANY &&
            user.role !== ROLE.COMPANYSTAFF &&
            user.role !== ROLE.USER &&
            history.push('/login');
    }, [user]);
    useEffect(() => {
        getCompany();
    }, [user]);

    const getCompany = async () => {
        if (!user) {
            getMe();
        }
        if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
            const { data } = await companiesAPI.getById(user.company._id);
            setData(data);
            setDefaultPR(data.defaultPR);
        }
        if (
            user &&
            user.role &&
            (user.role === ROLE.COMPANYSTAFF || user.role === ROLE.USER) &&
            user.employee &&
            user.employee.company &&
            user.employee.company._id
        ) {
            const { data } = await companiesAPI.getById(user.employee.company._id);
            setData(data);
            setDefaultPR(data.defaultPR);
        }
    };

    const handleClick = async () => {
        try {
            if (!defaultPR) {
                toast.warning('Please choose default value!');
            }
            const newRequest = await companiesAPI.update(data._id, { defaultPR: defaultPR });
            setData(newRequest.data);
            toast.success('Saved successfully');
        } catch (error) {
            toast.error(getError(error));
        }
    };
    return (
        <Container fluid className="main-content-container px-4">
            <PageTitle
                title="Permanent Residents Settings"
                subtitle={
                    <>
                        <span className="route-extra">Company settings/ Permanent Residents Settings </span>
                    </>
                }
                className="text-sm-left mb-3"
            />
            <Card small className="h-100">
                <CardHeader className="border-bottom">
                    <span className="ct-title">Permanent Residents Settings</span>
                </CardHeader>

                <CardBody className="col-xl-12 col-lg-12 ">
                    <div className="container">
                        <h5>Default value permanent resident</h5>
                        <Row className="py-3">
                            <Col lg="6" md="12" sm="12">
                                <label id="lib1">Permanent residents type:</label>
                                <p>- Citizens: Singapore citizen. </p>
                                <p>- F/G: Full ER (Full Employer & Graduated Employee Rates).</p>
                                <p>- G/G: Graduated (Graduated Employer & Employee Rates).</p>
                            </Col>
                            <Col lg="6" md="12" sm="12">
                                <label id="lib1">
                                    Default value for permanent residents when just creating a new employee
                                </label>
                                <Select
                                    htmlFor="lb1"
                                    value={defaultPR}
                                    style={{ width: '100%', marginTop: 10 }}
                                    onChange={(e) => setDefaultPR(e)}
                                    disabled={
                                        user.role !== ROLE.COMPANY && user.role !== ROLE.COMPANYSTAFF ? true : false
                                    }
                                >
                                    <Option value={'Citizens'} key={'Citizens'}>
                                        {'Citizens'}
                                    </Option>
                                    <Option value={'F/G'} key={'F/G'}>
                                        {'F/G'}
                                    </Option>
                                    <Option value={'G/G'} key={'G/G'}>
                                        {'G/G'}
                                    </Option>
                                </Select>
                            </Col>
                        </Row>
                        {/* <Alert
                            message="Change of Duration Calculation Preference will only affect newly created Attendance records, i.e. if an Attendance record has already been created (such as by employee clock-in), subsequent clock-in or out will still use the same Duration Calculation method."
                            type="info"
                            showIcon
                            style={{
                                marginTop: 20,
                                padding: 25,
                            }}
                        /> */}

                        {user && user.role && user.role === ROLE.COMPANY && (
                            <div className="d-flex justify-content-center align-items-center mt-3">
                                <div
                                    onClick={handleClick}
                                    className="text-center btn-save-attendanceSetting rounded py-2 px-4 d-flex justify-content-center align-items-center button__warning"
                                >
                                    <span className="text">Save</span>
                                </div>
                            </div>
                        )}
                    </div>
                </CardBody>
            </Card>
        </Container>
    );
};

export default PermanentResidentsSetting;
