import React, { useEffect, useState } from 'react';
// import ReactDOM from "react-dom";
import { DualAxes } from '@ant-design/plots';
import { useUser } from '../hooks';
import { ROLE } from '../helpers/const';
import { payRollAPI } from '../apis/payRollAPI';
import { usePayroll } from '../hooks/usePayRoll';

const Chart2 = ({ time, yearly }) => {
    const { user, getMe } = useUser();
    const [data, setData] = useState();
    const { payroll } = usePayroll();
    const [result, setResult] = useState();
    const [value, setValue] = useState();

    useEffect(() => {
        statistic();
    }, [user, payroll, time, yearly]);

    const statistic = async () => {
        if (!user) {
            getMe();
        }

        if (user && user.role && (user.role === ROLE.ADMIN || user.role === ROLE.SYSTEMSTAFF)) {
            const { data } = await payRollAPI.statistic({ role: user.role, time, yearly });
            setData(data);
        }

        if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
            const { data } = await payRollAPI.statistic({ role: user.role, idCompany: user.company._id, time, yearly });

            setData(data);
        }

        if (
            user &&
            user.role &&
            user.role === ROLE.COMPANYSTAFF &&
            user.employee &&
            user.employee.company &&
            user.employee.company._id
        ) {
            const { data } = await payRollAPI.statistic({
                role: user.role,
                idCompany: user.employee.company._id,
                time,
                yearly,
            });
            setData(data);
        }
    };

    useEffect(() => {
        getData();
    }, [data, time]);

    const getData = async () => {
        if (data && data.length > 0) {
            let tmp = [];
            data.map((item) => {
                tmp[item._id.department] = filterData(data).filter((item) => item !== undefined);
            });

            setResult(tmp);

            setValue(data[0]._id.department);
        }
        if (data && data.length === 0) {
            setResult();
        }
    };

    // const formatCurrency = new Intl.NumberFormat('en-US', {
    //     style: 'currency',
    //     currency: 'USD',
    //     minimumFractionDigits: 0,
    // });

    const filterData = (arr) => {
        return arr.map((item) => {
            if (item._id.month) {
                return {
                    time: `${item._id.year} - ${item._id.month}`,
                    //value: formatCurrency.format(item.total),
                    value: item.total,
                    type: item._id.department[0],
                };
            } else {
                return {
                    time: `${item._id.year}`,
                    //value: formatCurrency.format(item.total),
                    value: item.total,
                    type: item._id.department[0],
                };
            }
        });
    };

    var config;
    if (result && value) {
        config = {
            data: [result[value], []],
            xField: 'time',
            yField: ['value', 'count'],
            geometryOptions: [
                {
                    geometry: 'line',
                    seriesField: 'type',
                    // label: {
                    //     formatter: (datum) => {
                    //         return formatCurrency.format(datum.value);
                    //     },
                    // },
                    lineStyle: {
                        lineWidth: 3,
                        lineDash: [5, 5],
                    },

                    smooth: true,
                },
                {
                    geometry: 'line',
                    seriesField: 'name',
                    point: {},
                },
            ],
        };
        return <DualAxes {...config} />;
    }
    return <h2>Nothing</h2>;
};

export default Chart2;
