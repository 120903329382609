import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROLE } from '../helpers/const';
import { useUser } from '../hooks';

function Helps() {
    const { user, getMe } = useUser();
    const history = useHistory();

    useEffect(() => {
        if (!user) {
            getMe();
        }
        user &&
            user.role &&
            user.role !== ROLE.COMPANY &&
            user.role !== ROLE.COMPANYSTAFF &&
            user.role !== ROLE.USER &&
            history.push('/login');
    }, [user]);
    return <div>helps</div>;
}

export default Helps;
