import { createContext } from "react";
const init = {
    user: {
        _id: "",
        role: "",
        company: {},
        employee: {},
    },
    getMe: async () => {
        return init.user;
    },
};
export const UserContext = createContext(init);
