import { Alert, Divider, Input, List, Modal, Select, Switch, Tag } from 'antd';
import VirtualList from 'rc-virtual-list';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'shards-react';
import { leavesSettingAPI } from '../../apis/leavesSettingAPI';
import { CATEGORY_LEAVE, LEAVE_DAY_COUNT, LEAVE_UNIT } from '../../global/leave/leaveEnum';
import { ROLE } from '../../helpers/const';
import { useLoading, useUser } from '../../hooks';

const TabLeaveType = ({ data }) => {
    const ContainerHeight = 300;
    const location = useLocation();
    const { user, getMe } = useUser();
    const [id, setId] = useState();
    const [detail, setDetail] = useState();
    const history = useHistory();
    const { setLoading } = useLoading();
    const [unit, setUnit] = useState('');

    useEffect(() => {
        if (!user) {
            getMe();
        }
        user &&
            user.role &&
            user.role !== ROLE.COMPANY &&
            user.role !== ROLE.COMPANYSTAFF &&
            user.role !== ROLE.USER &&
            history.push('/login');
    }, [user]);

    const onScroll = (e) => {
        if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight) {
        }
    };

    const [visible, setVisible] = useState(false);
    const { Option } = Select;

    const handleClick = (id) => {
        setVisible(true);
        setId(id);
    };

    useEffect(() => {
        (async () => {
            if (id) {
                const { data } = await leavesSettingAPI.getById(id);
                let tmp = { ...data };
                if (tmp && tmp.leaveUnit && tmp.leaveUnit === LEAVE_UNIT.HOURS) {
                    tmp = { ...data, standardNumber: data.standardNumber / 60 };
                }
                setDetail(tmp);
            }
        })();
    }, [id]);

    useEffect(() => {
        if (detail && detail.leaveUnit && detail.leaveUnit === LEAVE_UNIT.HOURS) {
            setUnit('(hours)');
        } else if (detail && detail.leaveUnit && detail.leaveUnit !== LEAVE_UNIT.HOURS) {
            setUnit('(days)');
        }
    }, [detail]);

    const validate = () => {
        let result = true;
        // if (
        //     (!detail.standardNumber || +detail.standardNumber < 1) &&
        //     detail.leaveUnit &&
        //     detail.leaveUnit !== LEAVE_UNIT.SPECIAL
        // ) {
        //     toast.error('Standard number of leave must be greater than 0');
        //     result = false;
        // }

        if (!detail.code || detail.code.length < 1) {
            toast.error('Code should not be empty');
            result = false;
        }

        if (!detail.description || detail.description.length < 1) {
            toast.error('Description should not be empty');
            result = false;
        }
        return result;
    };

    const handleUpdate = async () => {
        if (!user) {
            getMe();
        }

        try {
            if (!validate()) {
                return;
            }
            setLoading(true);
            await leavesSettingAPI.update(id, detail);
            setLoading(false);
            toast.success('Update success!');
            history.push('/employers/leave-settings');
            setVisible(false);
        } catch (error) {
            setLoading(false);
            const message = error.response.data.message;
            if (message && typeof message === 'string') {
                toast.error(<p className="text-capitalize mt-3">{message}</p>);
            } else if (message && typeof message === 'object') {
                toast.error(<p className="text-capitalize mt-3">{message[0]}</p>);
            }
        }
    };

    return (
        <>
            <Row className="d-flex justify-content-center align-itemns-center">
                <Col lg="8" md="12" sm="12">
                    <List>
                        <VirtualList data={data} itemHeight={47} itemKey="email" onScroll={onScroll}>
                            {(item, index) => (
                                <List.Item key={index} onClick={() => handleClick(item._id)}>
                                    <div>
                                        {console.log(item.leaveUnit)}
                                        <h6>{item.code}</h6>
                                        <Tag color="processing">
                                            {`${item.description}`}{' '}
                                            {item.leaveUnit === LEAVE_UNIT.HOURS
                                                ? `- ${item.standardNumber / 60} hours`
                                                : item.leaveUnit === LEAVE_UNIT.SPECIAL
                                                ? ''
                                                : `- ${item.standardNumber} days`}
                                        </Tag>
                                    </div>

                                    <div>
                                        <span className="material-icons fs_18">arrow_forward_ios</span>
                                    </div>
                                </List.Item>
                            )}
                        </VirtualList>
                    </List>
                </Col>
            </Row>
            <Modal
                title={detail && detail.description && detail.description}
                centered
                open={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                footer={false}
                width={1000}
                zIndex={999}
                id={id}
            >
                <div
                    style={{
                        padding: ' 0px 70px',
                    }}
                >
                    <Row lg="12">
                        <Col lg="6" md="6" sm="12">
                            <label id="code">Code</label>
                            <Input
                                for="code"
                                placeholder="ANNUAL"
                                value={detail && detail.code && detail.code}
                                disabled={user.role !== ROLE.COMPANY}
                                onChange={(e) => {
                                    setDetail({ ...detail, code: e.target.value });
                                }}
                            />
                        </Col>
                        <Col lg="6" md="6" sm="12">
                            <label id="des">Description</label>
                            <Input
                                for="des"
                                placeholder="Annual Leave"
                                value={detail && detail.description && detail.description}
                                disabled={user.role !== ROLE.COMPANY}
                                onChange={(e) => {
                                    setDetail({ ...detail, description: e.target.value });
                                }}
                            />
                        </Col>
                    </Row>
                    <ul className="p-0 mt-4">
                        <li className="d-flex justify-content-lg-between align-items-center">
                            <p>Active</p>
                            <Switch
                                checked={detail && detail.active && detail.active === true ? true : false}
                                onChange={(e) => {
                                    setDetail({ ...detail, active: e });
                                }}
                                value={detail && detail.active && detail.active}
                                disabled={user.role !== ROLE.COMPANY}
                            />
                        </li>
                    </ul>
                    <Row lg="12">
                        <Col lg="6" md="6" sm="12">
                            <label id="uni">Leave Unit</label>
                            <Select
                                defaultValue=""
                                style={{
                                    width: '100%',
                                }}
                                value={detail && detail.leaveUnit && detail.leaveUnit}
                                disabled={user.role !== ROLE.COMPANY}
                                onChange={(e) => {
                                    let tmp = { ...detail, leaveUnit: e };
                                    if (e === LEAVE_UNIT.SPECIAL) {
                                        tmp = {
                                            ...tmp,
                                            paidLeave: true,
                                            standardNumber: 0,
                                            allowNegativeBalance: true,
                                            applyWorkday: 0,
                                            allowEmergencyLeave: true,
                                        };
                                    }
                                    if (e === LEAVE_UNIT.HOURS) {
                                        tmp = {
                                            ...tmp,
                                            dayCount: LEAVE_DAY_COUNT.WORKDAY,
                                        };
                                    }
                                    setDetail(tmp);
                                }}
                            >
                                <Option value={LEAVE_UNIT.DAY}>Day</Option>
                                <Option value={LEAVE_UNIT.HALF_DAY}>Half Day</Option>
                                <Option value={LEAVE_UNIT.HOURS}>Hours</Option>
                                <Option value={LEAVE_UNIT.SPECIAL}>Special award</Option>
                            </Select>
                        </Col>

                        <Col lg="6" md="6" sm="12">
                            <label id="coun">Day count</label>
                            <Select
                                defaultValue=""
                                style={{
                                    width: '100%',
                                }}
                                value={detail && detail.dayCount && detail.dayCount}
                                disabled={
                                    user.role !== ROLE.COMPANY ||
                                    (detail &&
                                        detail.leaveUnit &&
                                        (detail.leaveUnit === LEAVE_UNIT.SPECIAL ||
                                            detail.leaveUnit === LEAVE_UNIT.HOURS))
                                        ? true
                                        : false
                                }
                                onChange={(e) => {
                                    setDetail({ ...detail, dayCount: e });
                                }}
                            >
                                <Option value="work-day">Workday</Option>
                                <Option value="calendar-day">Calendar Day</Option>
                            </Select>
                        </Col>
                    </Row>
                    <ul className="p-0 mt-4">
                        <li className="d-flex justify-content-lg-between align-items-center">
                            <p>Paid Leave</p>
                            <Switch
                                checked={detail && detail.paidLeave && detail.paidLeave === true ? true : false}
                                value={detail && detail.paidLeave && detail.paidLeave}
                                disabled={
                                    user.role !== ROLE.COMPANY ||
                                    (detail && detail.leaveUnit && detail.leaveUnit === LEAVE_UNIT.SPECIAL)
                                }
                                onChange={(e) => {
                                    setDetail({ ...detail, paidLeave: e });
                                }}
                            />
                        </li>
                    </ul>

                    <Row lg="12">
                        <Col lg="6" md="6" sm="12">
                            <label id="number">Standard number of leave {unit}</label>
                            <Input
                                for="number"
                                type="number"
                                placeholder="14"
                                value={detail && detail.standardNumber && detail.standardNumber}
                                disabled={
                                    user.role !== ROLE.COMPANY ||
                                    (detail && detail.leaveUnit && detail.leaveUnit === LEAVE_UNIT.SPECIAL)
                                }
                                onChange={(e) => setDetail({ ...detail, standardNumber: +e.target.value })}
                                rules={[{ required: true, message: 'Please input standard number of leave!' }]}
                            />
                        </Col>
                        <Col lg="6" md="6" sm="12">
                            <label id="category">Category</label>
                            <Select
                                style={{
                                    width: '100%',
                                }}
                                value={detail && detail.category && detail.category}
                                onChange={(e) => setDetail({ ...detail, category: e })}
                                disabled={user.role !== ROLE.COMPANY}
                            >
                                {CATEGORY_LEAVE.map((item, index) => (
                                    <Option value={item.value} key={index}>
                                        {item.title}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>

                    <Divider></Divider>

                    <h6 className="mb-3">APPLICATION POLICY</h6>
                    <ul className="p-0">
                        <li className="d-flex justify-content-lg-between align-items-center">
                            <p>Allow Negative Balance</p>
                            <Switch
                                checked={
                                    detail && detail.allowNegativeBalance && detail.allowNegativeBalance === true
                                        ? true
                                        : false
                                }
                                value={detail && detail.allowNegativeBalance && detail.allowNegativeBalance}
                                onChange={(e) => {
                                    setDetail({ ...detail, allowNegativeBalance: e });
                                }}
                                disabled={
                                    user.role !== ROLE.COMPANY ||
                                    (detail && detail.leaveUnit && detail.leaveUnit === LEAVE_UNIT.SPECIAL)
                                }
                            />
                        </li>
                        <li className="d-flex justify-content-lg-between align-items-center my-3">
                            <p>Reason Required</p>
                            <Switch
                                checked={
                                    detail && detail.reasonRequired && detail.reasonRequired === true ? true : false
                                }
                                value={detail && detail.reasonRequired && detail.reasonRequired}
                                onChange={(e) => {
                                    setDetail({ ...detail, reasonRequired: e });
                                }}
                                disabled={user.role !== ROLE.COMPANY}
                            />
                        </li>
                        <li className="d-flex justify-content-lg-between align-items-center">
                            <p>Attachment Required</p>
                            <Switch
                                checked={
                                    detail && detail.attachmentRequired && detail.attachmentRequired === true
                                        ? true
                                        : false
                                }
                                value={detail && detail.attachmentRequired && detail.attachmentRequired}
                                onChange={(e) => {
                                    setDetail({ ...detail, attachmentRequired: e });
                                }}
                                disabled={user.role !== ROLE.COMPANY}
                            />
                        </li>
                    </ul>

                    <Divider></Divider>

                    <h6>RULES</h6>
                    <div className="d-flex justify-content-left align-items-center">
                        <span
                            style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                backgroundColor: '#0c415b',
                                display: 'grid',
                                placeItems: 'center',
                                color: '#fff',
                                marginRight: '10px',
                            }}
                        >
                            0
                        </span>
                        <div className="pl-3">For ALL leaves, apply ANYTIME.</div>
                    </div>
                    <div className="d-flex justify-content-left  mt-2">
                        <span
                            style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                backgroundColor: '#0c415b',
                                display: 'grid',
                                placeItems: 'center',
                                color: '#fff',
                                marginRight: '10px',
                            }}
                        >
                            2
                        </span>

                        <Col lg="6" md="6" sm="12">
                            <label id="coun">Apply (X) workday in advance</label>
                            <Select
                                defaultValue=""
                                style={{
                                    width: '100%',
                                }}
                                value={detail && detail.applyWorkday && detail.applyWorkday}
                                onChange={(e) => setDetail({ ...detail, applyWorkday: e })}
                                disabled={
                                    user.role !== ROLE.COMPANY ||
                                    (detail && detail.leaveUnit && detail.leaveUnit === LEAVE_UNIT.SPECIAL)
                                        ? true
                                        : false
                                }
                            >
                                <Option value="0">0</Option>
                                <Option value="1">1</Option>
                                <Option value="2">2</Option>
                                <Option value="3">3</Option>
                                <Option value="4">4</Option>
                                <Option value="5">5</Option>
                            </Select>
                        </Col>
                    </div>

                    <ul className="p-0 my-4">
                        <li className="d-flex justify-content-lg-between align-items-center">
                            <div className="d-flex justify-content-left align-items-center">
                                <span className="material-icons    mr-2">alarm_add</span>
                                <span>Allow Emergency Leave</span>
                            </div>

                            <Switch
                                checked={
                                    detail && detail.allowEmergencyLeave && detail.allowEmergencyLeave === true
                                        ? true
                                        : false
                                }
                                value={detail && detail.allowEmergencyLeave && detail.allowEmergencyLeave}
                                onChange={(e) => {
                                    setDetail({ ...detail, allowEmergencyLeave: e });
                                }}
                                disabled={
                                    user.role !== ROLE.COMPANY ||
                                    (detail &&
                                        detail.leaveUnit &&
                                        (detail.leaveUnit === LEAVE_UNIT.HOURS ||
                                            detail.leaveUnit === LEAVE_UNIT.SPECIAL))
                                }
                            />
                        </li>
                    </ul>
                    <Alert
                        message="Application Policy will only be enforced on employee submission, this will not affect submission by Administrator or Custom HR Roles."
                        type="info"
                        showIcon
                    />

                    {user && user.role && user.role === ROLE.COMPANY && (
                        <div className="d-flex justify-content-center align-items-center mt-3">
                            <div
                                onClick={handleUpdate}
                                className="text-center btn-save-attendanceSetting rounded py-2 px-4 d-flex justify-content-center align-items-center button__warning"
                            >
                                <span className="text">Save</span>
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default TabLeaveType;
