import axios from 'axios';
import { cleanObject } from '../helpers/cleanObject';

const path = 'attendances/';

const getTotalOvertime = async (payload = {}) => {
    return await axios.get(path + 'totalOvertime/', { params: payload });
};

const getAll = async (filter = {}) => {
    let searchParams = new URLSearchParams(cleanObject(filter));
    return await axios.get(path + 'all?' + searchParams.toString());
};

const getAllById = async (id, filter = {}) => {
    let searchParams = new URLSearchParams(cleanObject(filter));
    return await axios.get(path + 'all/' + id + '?' + searchParams.toString());
};

const add = async (payload) => {
    return await axios.post(path, payload);
};

const update = async (id, payload) => {
    return await axios.patch(path + id, payload);
};

const updateDaily = async (id, payload) => {
    return await axios.patch(path + 'daily/' + id, payload);
};

const remove = async (id) => {
    return await axios.delete(path + id);
};

export const attendanceAPI = {
    getAll,
    getAllById,
    update,
    add,
    getTotalOvertime,
    remove,
    updateDaily,
};
