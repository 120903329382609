import axios from 'axios';

const path = 'messages/';

const create = async (payload) => {
    return await axios.post(path, payload);
};

const get = async (id, filter = {}) => {
    return await axios.get(path + 'user/' + id, { params: filter });
};

const getByID = async (id) => {
    return await axios.get(path + id);
};

const update = async (id, payload) => {
    return await axios.patch(path + id, payload);
};

const softDelete = async (idMessages, idUser) => {
    return await axios.delete(path + 'remove/' + idMessages + '/' + idUser);
};

export const noticeAPI = {
    create,
    get,
    getByID,
    update,
    softDelete,
};
