import { MinusCircleOutlined } from '@ant-design/icons';
import { DatePicker, Form, Input, Modal, Select, Space, Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'shards-react';
import { employeesAPI } from '../../apis/employeesAPI';
import { jobPositionAPI } from '../../apis/jobPositionAPI';
import { payRollAPI } from '../../apis/payRollAPI';
import { PAYROLL_CURENTCY } from '../../global/payroll';
import { FORMAT, ROLE, EMPLOYEES_TYPE_SUMMARY_PAYROLL } from '../../helpers/const';
import { getError } from '../../helpers/getErrorServer';
import { useUser } from '../../hooks';
import { useLoading } from '../../hooks';

const TheMonthPaySlipList = ({ onCreate, tab }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [load, setReload] = useState(0);
    const [payRolls, setPayRolls] = useState();
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [size, setSize] = useState(0);
    const [search, setSearch] = useState('');
    const [role, setRole] = useState(EMPLOYEES_TYPE_SUMMARY_PAYROLL[0].value);
    const [positions, setPositions] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [dataFrom, setDataForm] = useState([]);
    const [dataDelete, setDataDelete] = useState([]);
    const [date, setDate] = useState();
    const { user, getMe } = useUser();
    const [form] = Form.useForm();
    const { type } = useParams();
    const { Option } = Select;
    const { setLoading } = useLoading();
    const { RangePicker } = DatePicker;
    let history = useHistory();
    const SGDDollar = new Intl.NumberFormat('en-SG', { style: 'currency', currency: 'SGD' });
    useEffect(() => {
        (async () => {
            if (!user._id) {
                await getMe();
            }
            setLoading(true);
            if (user.role === ROLE.COMPANY) {
                const { data } = await payRollAPI.findSummaryForPayroll(user.company._id, {
                    type,
                    role,
                    page,
                    limit: 10,
                });
                let rs = data;
                if (rs.items.length < 1 && page > 1) {
                    setPage((prev) => prev - 1);
                    const { data: tmp } = await payRollAPI.findSummaryForPayroll(user.company._id, {
                        role,
                        type,
                        page: page - 1,
                        limit: 10,
                    });
                    rs = tmp;
                }
                setPayRolls(rs.items);
                setPagination(rs);
            } else if (
                tab === 1 &&
                (user.role === ROLE.COMPANYSTAFF ||
                    (((user && user.position && user.position.payroll && user.position.payroll.includes('1')) ||
                        (user &&
                            user.employee &&
                            user.employee.controls &&
                            user.employee.controls.payroll &&
                            user.employee.controls.payroll.includes('1'))) &&
                        user &&
                        user.employee &&
                        user.employee.company &&
                        user.employee.company._id))
            ) {
                const { data } = await payRollAPI.findSummaryForPayroll(user.employee.company._id, {
                    role,
                    type,
                    page,
                    limit: 10,
                });
                let rs = data;
                if (rs.items.length < 1 && page > 1) {
                    setPage((prev) => prev - 1);
                    const { data: tmp } = await await payRollAPI.findSummaryForPayroll(user.employee.company._id, {
                        role,
                        type,
                        page: page - 1,
                        limit: 10,
                    });
                    rs = tmp;
                }
                setPayRolls(rs.items);
                setPagination(rs);
            } else {
                if (user && user.employee && user.employee._id) {
                    const { data } = await payRollAPI.getByEmployeeId(user.employee._id, { type, page, limit: 10 });
                    let rs = data;
                    if (rs.items.length < 1 && page > 1) {
                        setPage((prev) => prev - 1);
                        const { data: tmp } = await payRollAPI.getByEmployeeId(user.employee._id, {
                            type,
                            page: page - 1,
                            limit: 10,
                        });
                        rs = tmp;
                    }
                    setPayRolls(rs.items);
                    setPagination(rs);
                }
            }
            setLoading(false);
        })();
    }, [type, page, user, load, onCreate]);

    useEffect(() => {
        (async () => {
            if (Object.keys(user).length === 0) await getMe();
            if (user.company && user.company._id) {
                const { data } = await jobPositionAPI.getAllById(user.company._id);
                setPositions(data);
            } else if (user.employee && user.employee.company._id) {
                const { data } = await jobPositionAPI.getAllById(user.employee.company._id);
                setPositions(data);
            }
        })();
    }, [user, onCreate]);

    const setPagination = (data) => {
        setTotal(data.paginate.count);
        setPage(data.paginate.page);
        setSize(data.paginate.size);
    };

    const initColumns_SPass = [
        {
            title: 'ID',
            dataIndex: 'key',
            key: 'id',
            render: (_, row, i) => {
                return row.employee.employeesId;
            },
        },
        ,
        {
            title: ' Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Name</p>
                    <p>{record && record.employee.firstName ? record.employee.firstName : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Month',
            dataIndex: 'Month',
            key: 'Month',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Month</p>
                    <p> {record && record.month ? moment(record.month).format('MMM YY') : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Basic Salary',
            dataIndex: 'BasicSal',
            key: 'BasicSal',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Basic Salary</p>
                    <p>
                        {record.summary && record.summary.basicSalary
                            ? SGDDollar.format(+record.summary.basicSalary)
                            : '--'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Transport Allowance',
            dataIndex: 'TransportAllowance',
            key: 'TransportAllowance',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Transport Allowance</p>
                    <p>
                        {record.summary && record.summary.transportAllowance
                            ? SGDDollar.format(+record.summary.transportAllowance)
                            : '--'}
                    </p>
                </div>
            ),
        },
        {
            title: 'OT Allowance',
            dataIndex: 'OTAllowance',
            key: 'OTAllowance',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Over Time Allowance</p>
                    <p>
                        {record.summary && record.summary.otAllowance
                            ? SGDDollar.format(+record.summary.otAllowance)
                            : '--'}
                    </p>
                </div>
            ),
        },

        {
            title: 'Unpaid Leave',
            dataIndex: 'UnpaidLeave',
            key: 'UnpaidLeave',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Unpaid Leave</p>
                    <p>
                        {record.summary && record.summary.unpaidLeave
                            ? SGDDollar.format(+record.summary.unpaidLeave)
                            : '--'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Others',
            dataIndex: 'Others',
            key: 'Others',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Others</p>
                    <p>
                        {record.summary && record.summary.other
                            ? SGDDollar.format(
                                  record.summary.claims
                                      ? +record.summary.other - +record.summary.claims
                                      : +record.summary.other,
                              )
                            : '--'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Gross Salary',
            dataIndex: 'GrossSalary',
            key: 'GrossSalary',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Gross Salary</p>
                    <p>
                        {record.summary && record.summary.grossSalary
                            ? SGDDollar.format(
                                  record.summary.claims
                                      ? +record.summary.grossSalary - +record.summary.claims
                                      : +record.summary.grossSalary,
                              )
                            : '--'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Advance',
            dataIndex: 'Advance',
            key: 'Advance',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Advance</p>
                    <p>
                        {record.summary && record.summary.deductionAdvance
                            ? SGDDollar.format(+record.summary.deductionAdvance)
                            : '--'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Claims',
            dataIndex: 'Claims',
            key: 'Claims',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Claims</p>
                    <p>{record.summary && record.summary.claims ? SGDDollar.format(+record.summary.claims) : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Net Pay',
            dataIndex: 'NetPay',
            key: 'NetPay',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Net Pay</p>
                    <p>{record.summary && record.summary.netPay ? SGDDollar.format(+record.summary.netPay) : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Employer SDL',
            dataIndex: 'EmployerSDL',
            key: 'EmployerSDL',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Employer SDL</p>
                    <p>
                        {record.summary && record.summary.employerSDL
                            ? SGDDollar.format(+record.summary.employerSDL)
                            : '--'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Bank Account Number',
            dataIndex: 'bankAccountNumber',
            key: 'bankAccountNumber',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Bank Account Number</p>
                    <p>{record.summary && record.summary.bankAccount ? record.summary.bankAccount : '--'}</p>
                </div>
            ),
        },
    ];
    const initColumns_Wp = [
        {
            title: 'ID',
            dataIndex: 'key',
            key: 'id',
            render: (_, row, i) => {
                return row.employee.employeesId;
            },
        },
        {
            title: ' Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Name</p>
                    <p>{record && record.employee.firstName ? record.employee.firstName : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Month',
            dataIndex: 'Month',
            key: 'Month',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Month</p>
                    <p> {record && record.month ? moment(record.month).format('MMM YY') : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Daily/Month',
            dataIndex: 'dailyMonth',
            key: 'dailyMonth',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Daily/Month</p>
                    <p>{record && record.dailyMonthly ? record.dailyMonthly : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Basic Salary (Per Day/ Per Month)',
            dataIndex: 'BasicSal_PerDayPerMonth',
            key: 'BasicSal_PerDayPerMonth',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Basic Salary (Per Day/ Per Month)</p>
                    <p>
                        {record.summary && record.summary.basicSalary
                            ? SGDDollar.format(+record.summary.basicSalary)
                            : '--'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Hourly Rate',
            dataIndex: 'HourlyRate',
            key: 'HourlyRate',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Hourly Rate</p>
                    <p>{record && record.hourlyRate ? SGDDollar.format(+record.hourlyRate) : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Hourly OT Rate',
            dataIndex: 'HourlyOTRate',
            key: 'HourlyOTRate',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Hourly OT Rate</p>
                    <p>{record && record.overtimeRate ? SGDDollar.format(+record.overtimeRate) : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Worked Days',
            dataIndex: 'WorkedDays',
            key: 'WorkedDays',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Worked Days</p>
                    <p>{record.summary && record.summary.workedDays ? record.summary.workedDays : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Net OT Hours (Hrs)',
            dataIndex: 'NETTOTHours',
            key: 'NETTOTHours',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Net OT Hours (Hrs)</p>
                    <p>{record.summary && record.summary.netOtHours ? record.summary.netOtHours + ' hrs' : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Total Basic Salary',
            dataIndex: 'TotalBasicSalary',
            key: 'TotalBasicSalary',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Total Basic Salary</p>
                    <p>
                        {record.summary && record.summary.totalBasicSalary
                            ? SGDDollar.format(+record.summary.totalBasicSalary)
                            : '--'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Total OT Pay',
            dataIndex: 'TotalOTPay',
            key: 'TotalOTPay',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Total OT Pay</p>
                    <p>
                        {record.summary && record.summary.totalOTPay
                            ? SGDDollar.format(+record.summary.totalOTPay)
                            : '--'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Allowance',
            dataIndex: 'Allowance',
            key: 'Allowance',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Allowance</p>
                    <p>
                        {record.summary && record.summary.allowance
                            ? SGDDollar.format(+record.summary.allowance)
                            : '--'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Others',
            dataIndex: 'Others',
            key: 'Others',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Others</p>
                    <p>
                        {record.summary && record.summary.other
                            ? SGDDollar.format(
                                  record.summary.claims
                                      ? +record.summary.other - +record.summary.claims
                                      : +record.summary.other,
                              )
                            : '--'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Gross Salary',
            dataIndex: 'GrossSalary',
            key: 'GrossSalary',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Gross Salary</p>
                    <p>
                        {record.summary && record.summary.grossSalary
                            ? SGDDollar.format(
                                  record.summary.claims
                                      ? +record.summary.grossSalary - +record.summary.claims
                                      : +record.summary.grossSalary,
                              )
                            : '--'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Advance',
            dataIndex: 'Advance',
            key: 'Advance',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Advance</p>
                    <p>{record.summary && record.summary.advance ? SGDDollar.format(+record.summary.advance) : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Claims',
            dataIndex: 'Claims',
            key: 'Claims',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Claims</p>
                    <p>{record.summary && record.summary.claims ? SGDDollar.format(+record.summary.claims) : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Net Salary (S$)',
            dataIndex: 'NetSal',
            key: 'NetSal',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Net Salary (S$)</p>
                    <p>{record.summary && record.summary.netPay ? SGDDollar.format(+record.summary.netPay) : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Employer SDL',
            dataIndex: 'EmployerSDL',
            key: 'EmployerSDL',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Employer SDL</p>
                    <p>
                        {record.summary && record.summary.employerSDL
                            ? SGDDollar.format(+record.summary.employerSDL)
                            : '--'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Bank Account',
            dataIndex: 'bankAccountNumber',
            key: 'bankAccountNumber',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Bank Account</p>
                    <p>{record.summary && record.summary.bankAccount ? record.summary.bankAccount : '--'}</p>
                </div>
            ),
        },
    ];

    const initColumns_Local = [
        {
            title: 'ID',
            dataIndex: 'key',
            key: 'id',
            render: (_, row, i) => {
                return row.employee.employeesId;
            },
        },
        ,
        {
            title: ' Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Name</p>
                    <p>{record && record.employee.firstName ? record.employee.firstName : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Month',
            dataIndex: 'Month',
            key: 'Month',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Month</p>
                    <p> {record && record.month ? moment(record.month).format('MMM YY') : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Basic Salary',
            dataIndex: 'BasicSal',
            key: 'BasicSal',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Basic Salary</p>
                    <p>
                        {record.summary && record.summary.basicSalary
                            ? SGDDollar.format(+record.summary.basicSalary)
                            : '--'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Transport Allowance',
            dataIndex: 'TransportAllowance',
            key: 'TransportAllowance',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Transport Allowance</p>
                    <p>
                        {record.summary && record.summary.transportAllowance
                            ? SGDDollar.format(+record.summary.transportAllowance)
                            : '--'}
                    </p>
                </div>
            ),
        },
        {
            title: 'OT Allowance',
            dataIndex: 'OTAllowance',
            key: 'OTAllowance',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">OT Allowance</p>
                    <p>
                        {record.summary && record.summary.otAllowance
                            ? SGDDollar.format(+record.summary.otAllowance)
                            : '--'}
                    </p>
                </div>
            ),
        },

        {
            title: 'Unpaid Leave',
            dataIndex: 'UnpaidLeave',
            key: 'UnpaidLeave',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Unpaid Leave</p>
                    <p>
                        {record.summary && record.summary.unpaidLeave
                            ? SGDDollar.format(+record.summary.unpaidLeave)
                            : '--'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Others',
            dataIndex: 'Others',
            key: 'Others',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Others</p>
                    <p>
                        {record.summary && record.summary.other
                            ? SGDDollar.format(
                                  record.summary.claims
                                      ? +record.summary.other - +record.summary.claims
                                      : +record.summary.other,
                              )
                            : '--'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Gross Salary',
            dataIndex: 'GrossSalary',
            key: 'GrossSalary',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Gross Salary</p>
                    <p>
                        {record.summary && record.summary.grossSalary
                            ? SGDDollar.format(
                                  record.summary.claims
                                      ? +record.summary.grossSalary -
                                            +record.summary.claims +
                                            +record.summary.unpaidLeave
                                      : +record.summary.grossSalary,
                              )
                            : '--'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Total CPF To Be Paid',
            dataIndex: 'TotalCPFToBePaid',
            key: 'TotalCPFToBePaid',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Total CPF To Be Paid</p>
                    <p>
                        {record.summary && record.summary.totalCPFToBePaid
                            ? SGDDollar.format(+record.summary.totalCPFToBePaid)
                            : '--'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Employer SDL',
            dataIndex: 'EmployerSDL',
            key: 'EmployerSDL',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Employer SDL</p>
                    <p>
                        {record.summary && record.summary.employerSDL
                            ? SGDDollar.format(+record.summary.employerSDL)
                            : '--'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Employer CPF',
            dataIndex: 'EmployerCPF',
            key: 'EmployerCPF',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Employer CPF</p>
                    <p>
                        {record.summary && record.summary.employerCPF
                            ? SGDDollar.format(+record.summary.employerCPF)
                            : '--'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Employee CPF',
            dataIndex: 'EmployeeCPF',
            key: 'EmployeeCPF',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Employee CPF</p>
                    <p>
                        {record.summary && record.summary.employeeCPF
                            ? SGDDollar.format(+record.summary.employeeCPF)
                            : '--'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Employee MBMF',
            dataIndex: 'EmployeeMBMF',
            key: 'EmployeeMBMF',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Employee MBMF</p>
                    <p>
                        {record.summary && record.summary.employeeMBMF
                            ? SGDDollar.format(+record.summary.employeeMBMF)
                            : '--'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Advance',
            dataIndex: 'Advance',
            key: 'Advance',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Advance</p>
                    <p>
                        {record.summary && record.summary.deductionAdvance
                            ? SGDDollar.format(+record.summary.deductionAdvance)
                            : '--'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Claims',
            dataIndex: 'Claims',
            key: 'Claims',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Claims</p>
                    <p>{record.summary && record.summary.claims ? SGDDollar.format(+record.summary.claims) : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Net Pay',
            dataIndex: 'NetPay',
            key: 'NetPay',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Net Pay</p>
                    <p>{record.summary && record.summary.netPay ? SGDDollar.format(+record.summary.netPay) : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Bank Account Number',
            dataIndex: 'bankAccountNumber',
            key: 'bankAccountNumber',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Bank Account Number</p>
                    <p>{record.summary && record.summary.bankAccount ? record.summary.bankAccount : '--'}</p>
                </div>
            ),
        },
    ];
    const [columns, setColumns] = useState(initColumns_Local);

    const submitSearch = async () => {
        try {
            const a = new Date(date);
            let month = a.getTime();

            // let from = date ? moment(date[0]).format('YYYY-MM-DD') : undefined;
            // let to = date ? moment(date[1]).format('YYYY-MM-DD') : undefined;
            setLoading(true);
            if (user.role === ROLE.COMPANY) {
                const { data } = await payRollAPI.findSummaryForPayroll(user.company._id, {
                    search,
                    role,
                    month,
                    type,
                    page,
                    limit: 10,
                });
                setPayRolls(data.items);
                setTotal(data.paginate.count);
                setSize(data.paginate.size);
            }
            if (
                user.role === ROLE.COMPANYSTAFF ||
                (user && user.position && user.position.payroll && user.position.payroll.includes('1'))
            ) {
                const { data } = await payRollAPI.findSummaryForPayroll(user.employee.company._id, {
                    search,
                    role,
                    month,
                    type,
                    page,
                    limit: 10,
                });
                setPayRolls(data.items);
                setTotal(data.paginate.count);
                setSize(data.paginate.size);
            }
            setLoading(false);
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const onChangePage = (page) => {
        setPage(page);
    };

    useEffect(() => {
        (async () => {
            submitSearch();
        })();
    }, [role]);

    const onChangeRole = async (value) => {
        setRole(value);

        if (value === 's-pass') {
            setColumns(initColumns_SPass);
        } else if (value === 'work-permit') {
            setColumns(initColumns_Wp);
        } else if (value === 'local-staff') {
            setColumns(initColumns_Local);
        }
    };

    return (
        <>
            <div>
                <Row form className="mt-1">
                    {user && user.role && user.role !== ROLE.USER && (
                        <>
                            <Col lg="3" md="3" className="form-group">
                                <Input placeholder="Search by name" onChange={(e) => setSearch(e.target.value)} />
                            </Col>
                            <Col md="3" className="form-group">
                                <Select
                                    style={{ width: '100%' }}
                                    onChange={(value) => onChangeRole(value)}
                                    value={role}
                                >
                                    {EMPLOYEES_TYPE_SUMMARY_PAYROLL &&
                                        EMPLOYEES_TYPE_SUMMARY_PAYROLL.map((item) => (
                                            <Option key={item.value} value={item.value}>
                                                {item.label}
                                            </Option>
                                        ))}
                                </Select>
                            </Col>
                        </>
                    )}
                    <Col md="3" className="form-group">
                        <DatePicker
                            style={{ width: '100%' }}
                            picker="month"
                            onChange={(_, dateString) => {
                                setDate(new Date(dateString).getTime());
                            }}
                        />
                    </Col>
                    <Col md="3" className="d-flex justify-content-end align-items-start">
                        <button type="submit" className="button__search" onClick={() => submitSearch()}>
                            Search
                        </button>
                    </Col>
                </Row>
            </div>
            <Table
                className="ct-table-data"
                columns={columns}
                dataSource={payRolls}
                pagination={{
                    current: page,
                    onChange: onChangePage,
                    pageSize: size,
                    total,
                }}
            />
        </>
    );
};

export default TheMonthPaySlipList;
