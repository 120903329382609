import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { employeesAPI } from '../../apis/employeesAPI';
import { ROLE } from '../../helpers/const';
import { useUser } from '../../hooks';

export default function ExportCSV() {
    const { user, getMe } = useUser();
    const [dataFormat, setDataFormat] = useState([]);

    const columns = [
        { label: 'Employees ID', key: 'employeesId' },
        { label: 'Last Name', key: 'lastName' },
        { label: 'First Name ', key: 'firstName' },
        { label: 'Middle Name ', key: 'middleName' },
        { label: 'Birthday ', key: 'birthday' },
        { label: 'Gender ', key: 'gender' },
        { label: 'Email ', key: 'email' },
        { label: 'House Phone ', key: 'phone.housePhone' },
        { label: 'Mobile Phone ', key: 'phone.mobilePhone' },
        { label: 'Office Phone ', key: 'phone.officePhone' },
        { label: 'Job Position ', key: 'jobPosition' },
        //{ label: 'Nationality Key', key: 'nationality' },
        //{ label: 'NationId ', key: 'nationId' },
        { label: 'Type ', key: 'type' },
        //{ label: 'CreatedAt ', key: 'createdAt' },
        // { label: 'Ethnicity', key: 'ethnicity' },
        // { label: 'Religion', key: 'religion' },
        { label: 'Type Work', key: 'typeWork' },
    ];

    const fetchData = async () => {
        try {
            if (!user) await getMe();
            if (user.role === ROLE.ADMIN) {
                const { data } = await employeesAPI.get();
                setDataFormat(data.items);
            } else if (user.role === ROLE.COMPANY) {
                const { data } = await employeesAPI.getAllCompany(user.company._id);
                setDataFormat(data);
            } else {
                const { data } = await employeesAPI.getAllCompany(user.employee.company._id);
                setDataFormat(data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [user]);

    return (
        <>
            <CSVLink
                data={dataFormat}
                headers={columns}
                className="text-center rounded p-2 border-0 d-flex justify-content-center align-items-center button__warning"
            >
                <span className="material-icons">download</span>
                <span className="text ml-2">Export CSV</span>
            </CSVLink>
        </>
    );
}
