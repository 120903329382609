import { Space, Table, Checkbox, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { employeesAPI } from '../../apis/employeesAPI';
import { toast } from 'react-toastify';
import { FORMAT, ROLE, TYPE_NOTIFICATION } from '../../helpers/const';
import moment from 'moment';
import { useUser } from '../../hooks';
import { departmentAPI } from '../../apis/departmentAPI';
import IMG_AVT from '../../images/avatars/0.jpg';
import { historiesAPI } from '../../apis/historiesAPI';

const TableEmployees = ({ tableData, setReload, deleteList, setDeleteList, setPage, page }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [data, setData] = useState();
    const [total, setTotal] = useState(0);
    const [size, setSize] = useState(0);
    const [selected, setSelected] = useState();

    const showModal = (row) => {
        setIsModalVisible(true);
        setSelected(row);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const { user, getMe } = useUser();

    useEffect(() => {
        if (tableData && tableData.paginate && tableData.paginate.count && tableData.paginate.size) {
            setTotal(tableData.paginate.count);
            setSize(tableData.paginate.size);
        }
    }, [tableData]);

    const handleDelete = async () => {
        try {
            if (selected) {
                if (selected) {
                    const { data } = await departmentAPI.getByHead(selected._id);

                    if (data.length > 0) {
                        toast.error(`Can't delete department head: ${selected.firstName}`);
                        return;
                    }

                    const result = await employeesAPI._delete(selected._id);

                    if (user && user.employee && user.employee.firstName && result && result.data) {
                        await historiesAPI.add({
                            title: 'DELETE EMPLOYEE',
                            desc: `${user.employee.firstName} just deleted employee ${result.data.firstName}`,
                            type: TYPE_NOTIFICATION.EMPLOYEE,
                            employee: result.data._id,
                            idRequest: result.data._id,
                            toCompany: true,
                            requestDelete: true,
                        });
                    }
                    onChangePage(1);
                    toast.success('Deleted successfully!');
                    setIsModalVisible(false);
                    setReload((e) => e + 1);
                }
            }
        } catch (error) {
            console.log(error);
            toast.error('Delete failed!');
        }
    };

    useEffect(() => {
        if (tableData && tableData.items) {
            setData(tableData.items);
        }
        if (tableData && !tableData.items) {
            setData(tableData);
        }
    }, [tableData]);

    const onChange = async (e, id) => {
        try {
            const checked = e.target.checked;
            if (checked === true) {
                setDeleteList([...deleteList, id]);
            } else if (checked === false) {
                const index = deleteList.filter((item) => item !== id);
                setDeleteList(index);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onChangePage = (page) => {
        setPage(page);
    };

    const columns = [
        {
            title: '',
            dataIndex: '_id',
            key: 'id',
            render: (id, row) => {
                return <Checkbox onChange={(e) => onChange(e, id)} />;
            },
        },
        {
            title: 'ID',
            dataIndex: 'employeesId',
            key: 'id',
            render: (id) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">ID</p>
                        <p>{id}</p>
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.employeesId > record2.employeesId;
            },
        },
        {
            title: 'Name',
            dataIndex: 'firstName',
            key: 'name',
            render: (_, row) => {
                return (
                    <div className="content_td_mobile_db">
                        <p className="title_mobile">Name</p>
                        <div className="box-name">
                            <div className="avt_leave_requ">
                                <img src={row && row.user && row.user.avatar ? row.user.avatar : IMG_AVT} alt="" />
                            </div>
                            <p>{row && row.firstName ? row.firstName : "Can't get first name employee"}</p>
                        </div>
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.firstName > record2.firstName;
            },
        },
        {
            title: 'Role',
            dataIndex: 'jobPosition',
            key: 'role',
            render: (_, row) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Role</p>
                        <p>{row.jobPosition}</p>
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.jobPosition > record2.jobPosition;
            },
        },
        {
            title: 'E-Mail',
            dataIndex: 'email',
            key: 'email',
            render: (email) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">E-Mail</p>
                        <p>{email}</p>
                    </div>
                );
            },
        },
        {
            title: 'Join Date',
            dataIndex: 'dateJoin',
            key: 'dateJoin',
            render: (dateJoin) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Join Date</p>
                        <p>{dateJoin ? moment(dateJoin).format(FORMAT.DATE) : '--'}</p>
                    </div>
                );
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            dataIndex: '_id',
            align: 'center',
            fixed: 'right',

            render: (id, row) => (
                <Space size="middle">
                    {((user && user.role && (user.role === ROLE.COMPANY || user.role === ROLE.COMPANYSTAFF)) ||
                        (user && user.position && user.position.employee && user.position.employee.includes('1')) ||
                        (user.employee &&
                            user.employee.controls &&
                            user.employee.controls.employee &&
                            user.employee.controls.employee.includes('1'))) && (
                        <Link to={`/view-employee/${id}`}>
                            <span className="material-icons icon_eye">visibility</span>
                        </Link>
                    )}

                    {user &&
                        user.role &&
                        user.role === ROLE.USER &&
                        user &&
                        user.position &&
                        user.position.employee &&
                        !user.position.employee.includes('1') &&
                        user.employee &&
                        (!user.employee.controls ||
                            (user.employee.controls.employee && !user.employee.controls.employee.includes('1'))) && (
                            <Link to={`/view-employee/${id}`}>
                                <span className="material-icons icon_eye">visibility</span>
                            </Link>
                        )}

                    {((user &&
                        user.role &&
                        (user.role === ROLE.ADMIN ||
                            user.role === ROLE.SYSTEMSTAFF ||
                            user.role === ROLE.COMPANY ||
                            user.role === ROLE.COMPANYSTAFF)) ||
                        (user && user.position && user.position.employee && user.position.employee.includes('2')) ||
                        (user.employee &&
                            user.employee.controls &&
                            user.employee.controls.employee &&
                            user.employee.controls.employee.includes('2'))) && (
                        <Link to={`/edit-employee/${id}`}>
                            <span className="material-icons icon_edit">edit</span>
                        </Link>
                    )}

                    {((user && user.role && (user.role === ROLE.COMPANY || user.role === ROLE.COMPANYSTAFF)) ||
                        (user && user.position && user.position.employee && user.position.employee.includes('4')) ||
                        (user.employee &&
                            user.employee.controls &&
                            user.employee.controls.employee &&
                            user.employee.controls.employee.includes('4'))) && (
                        <span className="material-icons icon_delete " onClick={() => showModal(row)}>
                            delete_forever
                        </span>
                    )}
                </Space>
            ),
        },
    ];

    return (
        <div className="overflow-hidden">
            <Table
                className="ct-table-data"
                pagination={{
                    current: page,
                    onChange: onChangePage,
                    pageSize: size || 1,
                    total: total || 1,
                }}
                columns={columns}
                dataSource={
                    data &&
                    data.map((item) => {
                        return { ...item, key: item._id };
                    })
                }
            />
            <Modal
                title={
                    <>
                        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.4167 0.833344H2.58333C1.56583 0.833344 0.75 1.65834 0.75 2.66668V15.5C0.75 16.5083 1.56583 17.3333 2.58333 17.3333H6.25L9 20.0833L11.75 17.3333H15.4167C16.425 17.3333 17.25 16.5083 17.25 15.5V2.66668C17.25 1.65834 16.425 0.833344 15.4167 0.833344ZM9.91667 15.5H8.08333V13.6667H9.91667V15.5ZM11.8142 8.39584L10.9892 9.23918C10.3292 9.90834 9.91667 10.4583 9.91667 11.8333H8.08333V11.375C8.08333 10.3667 8.49583 9.45001 9.15583 8.78084L10.2925 7.62584C10.6317 7.29584 10.8333 6.83751 10.8333 6.33334C10.8333 5.32501 10.0083 4.50001 9 4.50001C7.99167 4.50001 7.16667 5.32501 7.16667 6.33334H5.33333C5.33333 4.30751 6.97417 2.66668 9 2.66668C11.0258 2.66668 12.6667 4.30751 12.6667 6.33334C12.6667 7.14001 12.3367 7.87334 11.8142 8.39584Z"
                                fill="#FCBB0A"
                            />
                        </svg>

                        <p className="title-modal-custom">Are you sure delete this employee?</p>
                    </>
                }
                open={isModalVisible}
                onOk={handleDelete}
                onCancel={handleCancel}
                zIndex={999}
            >
                <p>Delete {selected && selected.firstName} employee</p>
            </Modal>
        </div>
    );
};
export default TableEmployees;
