// import { InfoCircleOutlined } from "@ant-design/icons";
import { Form, Select, Switch } from 'antd';
import { Col, Row } from 'shards-react';

import React, { useEffect, useState } from 'react';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { companiesAPI } from '../../apis/companiesAPI';
import { employeesAPI } from '../../apis/employeesAPI';
import { leavesSettingAPI } from '../../apis/leavesSettingAPI';
import { ROLE, WORKING_DAYS } from '../../helpers/const';
import { useLoading, useUser } from '../../hooks';
import { attendanceSettingAPI } from '../../apis/attendanceSettingAPI';
import { getError } from '../../helpers/getErrorServer';
const TabLeaveSettings = () => {
    const [approver, setApprover] = useState([]); // data approver
    const { Option } = Select;
    const [form] = Form.useForm();
    const { user, getMe } = useUser();
    const [employees, setEmployees] = useState();
    const [leaveSetting, setLeaveSetting] = useState();
    const [arrApprover, setArrApprover] = useState([]);
    const [render, setRender] = useState(0);
    const [company, setCompany] = useState();
    const [attendanceSetting, setAttendanceSetting] = useState();
    const history = useHistory();
    const { setLoading } = useLoading();

    useEffect(() => {
        if (!user) {
            getMe();
        }
        user &&
            user.role &&
            user.role !== ROLE.COMPANY &&
            user.role !== ROLE.COMPANYSTAFF &&
            user.role !== ROLE.USER &&
            history.push('/login');
    }, [user]);

    useEffect(() => {
        (async () => {
            if (!user) {
                getMe();
            }
            if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
                getAllEmployees(user.company._id);
                getAllApprover(user.company._id);
                getLeaveSetting(user.company._id);
                getCompany(user.company._id);
            }
        })();
    }, [user, render]);

    useEffect(() => {
        getAttendanceSetting();
    }, [user, render]);

    const getAllEmployees = async (id) => {
        const { data } = await employeesAPI.getAllCompany(id);
        setEmployees(data);
    };

    const getLeaveSetting = async (id) => {
        const { data } = await leavesSettingAPI.getByCompany(id);
        setLeaveSetting(data[0]);
    };

    const getAllApprover = async (id) => {
        const { data } = await employeesAPI.getApprover(id, { isApproverLeave: true });
        setArrApprover(data);

        let tmpArr = [];
        if (data) {
            data.map((item) => {
                tmpArr.push({ first: item._id });
            });

            form.setFieldsValue({
                approver: tmpArr,
            });
        }
    };

    const getCompany = async (id) => {
        const { data } = await companiesAPI.getById(id);
        setCompany(data);
    };

    const getAttendanceSetting = async () => {
        if (!user) {
            getMe();
        }
        if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
            const { data } = await attendanceSettingAPI.getByCompany(user.company._id);
            setAttendanceSetting(data[0]);
        }
    };

    const onFinish = async (data) => {
        await companiesAPI.update(company._id, company);
        await leavesSettingAPI.update(leaveSetting._id, leaveSetting);
        if (data && data.approver && arrApprover) {
            let tmpArr = [];
            data.approver.map((item) => {
                if (item.first) {
                    tmpArr.push(item.first);
                }
            });
            let newArr = [];
            tmpArr.forEach((item) => {
                if (!newArr.includes(item)) {
                    newArr.push(item);
                }
            });
            let oldArr = [];
            arrApprover.map((item) => oldArr.push(item._id));
            let sameArr = [];
            newArr.forEach((newItem) => {
                oldArr.forEach((oldItem) => {
                    if (newItem === oldItem) {
                        sameArr.push(newItem);
                    }
                });
            });
            let removeArr = [];
            oldArr.map((item) => {
                if (!sameArr.includes(item)) {
                    removeArr.push(item);
                }
            });
            let addArr = [];
            newArr.map((item) => {
                if (!sameArr.includes(item)) {
                    addArr.push(item);
                }
            });
            removeArr.map(async (item) => {
                await employeesAPI.removeApproverLeave(item);
            });
            addArr.map(async (item) => {
                await employeesAPI.setApproverLeave(item);
            });
            let arr = [];
            newArr.map(async (item) => {
                const { data } = await employeesAPI.getById(item);
                arr.push(data[0]);
            });
            setArrApprover((prev) => arr);
            toast.success('Update successful!');
        }
        //setRender((e) => e + 1);
        if (attendanceSetting) {
            try {
                setLoading(true);
                if (attendanceSetting._id && attendanceSetting._id.length > 0) {
                    await attendanceSettingAPI.update(attendanceSetting._id, attendanceSetting);
                } else {
                    await attendanceSettingAPI.add({ ...attendanceSetting, companyID: user.company._id });
                }
                setLoading(false);
                getAttendanceSetting();
            } catch (error) {
                toast.error(getError(error));
                setLoading(false);
            }
        }
    };

    return (
        <Row className="d-flex justify-content-center align-items-center">
            <Col lg="8" md="12" sm="12">
                {/* <h5 className="mb-3">LEAVE RESET</h5>
                <div className="mb-4">
                    <label>Which cycle should be followed for leave reset?</label>
                    <Select style={{ width: '100%' }} value={'Calendar year'}>
                        <Option value="self">Calendar year</Option>
                    </Select>
                </div>
                <div className="mb-4" style={{ display: 'flex' }}>
                    <label>Prorate year leave entitlement?</label>
                    <label style={{ marginLeft: '1%', display: 'flex' }}>
                        <label>
                            <Switch style={{ marginLeft: '1%' }} defaultChecked={true} disabled={true} />
                        </label>
                    </label>
                    <label style={{ marginLeft: '1%' }}>Yes</label>
                </div> */}

                <h5 className="mb-3">LEAVE PLANNER / SCHEDULE</h5>

                <div className="mb-4">
                    <label>Visible employee leave</label>
                    <Select
                        style={{ width: '100%' }}
                        //onChange={(value) => setLeaveSetting({ ...leaveSetting, visibleEmployeeLeave: value })}
                        value={(company && company.visibleEmployeeLeave) || 'self'}
                        disabled={user.role !== ROLE.COMPANY ? true : false}
                        onChange={(value) => setCompany({ ...company, visibleEmployeeLeave: value })}
                    >
                        <Option value="self">Self</Option>
                        <Option value="same-department">Same Department</Option>
                        <Option value="same-branch">Same Branch</Option>
                        <Option value="same-department-branch">Same Department / Branch</Option>
                        <Option value="all">All</Option>
                    </Select>
                </div>
                <div className="mb-4">
                    <label>Number of working days in a week</label>
                    <Select
                        style={{ width: '100%' }}
                        disabled={user.role !== ROLE.COMPANY ? true : false}
                        value={attendanceSetting && attendanceSetting.workingDays && attendanceSetting.workingDays}
                        onChange={(e) => setAttendanceSetting({ ...attendanceSetting, workingDays: e })}
                    >
                        {WORKING_DAYS.map((item, index) => (
                            <Option value={item} key={index}>
                                {item}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div>Approver</div>
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Form.List name="approver">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <div key={field.key} className="d-flex mt-2">
                                        <Col lg="11" className="list-item">
                                            <Form.Item name={[index, 'first']} style={{ width: '100%' }}>
                                                <Select
                                                    disabled={user.role !== ROLE.COMPANY ? true : false}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().includes(input.toLowerCase())
                                                    }
                                                >
                                                    {employees &&
                                                        employees.map((item) => (
                                                            <Select.Option value={`${item._id}`}>
                                                                {item.firstName}
                                                            </Select.Option>
                                                        ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        {user && user.role && user.role === ROLE.COMPANY && (
                                            <Col lg="1" className="list-btn">
                                                <div className="icon-delete-list" onClick={() => remove(field.name)}>
                                                    <span className="material-icons">delete_forever</span>
                                                </div>
                                            </Col>
                                        )}
                                    </div>
                                ))}

                                {user && user.role && user.role === ROLE.COMPANY && (
                                    <div
                                        className="d-flex justify-content-left align-items-center"
                                        onClick={() => {
                                            add();
                                        }}
                                    >
                                        <BsFillPlusCircleFill className="mr-2" size={20} color="#0C415B" />
                                        <span>Add approver</span>
                                    </div>
                                )}
                            </>
                        )}
                    </Form.List>
                </Form>

                {user && user.role && user.role === ROLE.COMPANY && (
                    <div className="d-flex justify-content-center align-items-center mt-3">
                        <div
                            onClick={() => {
                                form.submit();
                            }}
                            className="text-center btn-save-attendanceSetting rounded py-2 px-4 d-flex justify-content-center align-items-center button__warning"
                        >
                            <span className="text">Save</span>
                        </div>
                    </div>
                )}
            </Col>
        </Row>
    );
};

export default TabLeaveSettings;
