import axios from 'axios';

const path = 'training/';

const create = async (payload) => {
    return await axios.post(path, payload);
};

const getCompany = async (filter = {}) => {
    return await axios.get(path + 'company', { params: filter });
};

const getEmployeeJoined = async (filter = {}) => {
    return await axios.get(path + 'get-training-employee-joined', { params: filter });
};
const getEmployeeNotJoined = async (filter = {}) => {
    return await axios.get(path + 'get-training-employee-not-joined', { params: filter });
};

const requestToJoin = async (id) => {
    return await axios.get(path + 'request-join-training/' + id);
};

const getEmployeesNotJoin = async (id) => {
    return await axios.get(path + 'get-employees-not-join/' + id);
};

const getByID = async (id) => {
    return await axios.get(path + id);
};

const addEmployees = async (id, payload) => {
    return await axios.patch(path + 'addEmployee/' + id, payload);
};

const update = async (id, payload) => {
    return await axios.patch(path + id, payload);
};

const removeEmployee = async (id, payload) => {
    return await axios.delete(path + 'removeEmployee/' + id + '/' + payload);
};

const removeTraining = async (id) => {
    return await axios.delete(path + id);
};

export const trainingAPI = {
    create,
    // updateIdEmployee,
    getCompany,
    // getEmployee,
    getByID,
    update,
    getEmployeesNotJoin,
    addEmployees,
    removeEmployee,
    removeTraining,
    getEmployeeJoined,
    getEmployeeNotJoined,
    requestToJoin,
};
