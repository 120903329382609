import { Input, Modal, Space, Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Row } from 'shards-react';
import { expenseClaimAPI } from '../../apis/expenseClaimAPI';
import { historiesAPI } from '../../apis/historiesAPI';
import { FORMAT, FORM_TYPE, ROLE, TYPE_NOTIFICATION } from '../../helpers/const';
import { useExpenseClaim, useLoading, useUser } from '../../hooks';
import ExClaimCreateForm from './ExClaimCreateForm';
import { EXPENSE_CLAIM_STATUS } from '../../global/expenseClaim';

const initRequest = {
    reasonApprove: '',
    status: '',
};

const TablePendingExClaim = ({ value }) => {
    const { expenseClaims, fetchExpenseClaims, filterExpenseClaim, setFilterExpenseClaim } = useExpenseClaim();
    const [dataTable, setDataTable] = useState([]);
    const { user } = useUser();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [title, setTitle] = useState('');
    const [idRequest, setIdRequest] = useState();
    const [responseRequest, setResponseRequest] = useState(initRequest);
    const [visible, setVisible] = useState();
    const [exClaimSelected, setExClaimSelected] = useState();
    const [page, setPage] = useState(1);
    const { TextArea } = Input;
    const history = useHistory();
    const { setLoading } = useLoading();
    const regReason = /^[a-zA-Z0-9._-\s]{0,200}$/;
    const initColumns = [
        {
            title: ' Item',
            dataIndex: 'itemName',
            key: 'itemName',
            render: (itemName, row) => (
                <div className="content_td_mobile" onClick={() => onOpen(row._id)} style={{ cursor: 'pointer' }}>
                    <p className="title_mobile">Item</p>
                    {itemName ? <p>{itemName}</p> : "Can't get item's name"}
                </div>
            ),
            sorter: (record1, record2) => {
                return record1.itemName > record2.itemName;
            },
        },
        {
            title: 'Oder By',
            dataIndex: 'type',
            key: 'type',
            render: (_, row) => (
                <div className="content_td_mobile" onClick={() => onOpen(row._id)} style={{ cursor: 'pointer' }}>
                    <p className="title_mobile">Oder By</p>
                    {row.employee && row.employee.firstName ? row.employee.firstName : "Can't get first name employee"}
                </div>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (value, row) => {
                return (
                    <div className="content_td_mobile" onClick={() => onOpen(row._id)} style={{ cursor: 'pointer' }}>
                        <p className="title_mobile"> Date</p>
                        {moment(value).format(FORMAT.DATE)}
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.date > record2.date;
            },
        },
        {
            title: 'From',
            dataIndex: 'from',
            key: 'from',
            render: (from, row) => (
                <div className="content_td_mobile" onClick={() => onOpen(row._id)} style={{ cursor: 'pointer' }}>
                    <p className="title_mobile"> From</p>
                    {from ? <p>{from}</p> : ''}
                </div>
            ),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount, row) => (
                <div className="content_td_mobile" onClick={() => onOpen(row._id)} style={{ cursor: 'pointer' }}>
                    <p className="title_mobile"> Amount</p>
                    {amount ? <p>{amount}</p> : "Can't get amount"}
                </div>
            ),
        },
        {
            title: 'Paid By',
            dataIndex: 'paidBy',
            key: 'paidBy',
            render: (paidBy, row) => (
                <div className="content_td_mobile" onClick={() => onOpen(row._id)} style={{ cursor: 'pointer' }}>
                    <p className="title_mobile"> Paid By</p>
                    {paidBy ? <p>{paidBy}</p> : "Can't get paid by"}
                </div>
            ),
        },
    ];

    const columnsManager = [
        {
            title: 'ID',
            key: 'employee.employeesId',
            render: (_, row, i) => {
                return (
                    <>
                        <div
                            className="content_td_mobile"
                            onClick={() => onOpen(row._id)}
                            style={{ cursor: 'pointer' }}
                        >
                            <p className="title_mobile">ID</p>
                            <p>{row.employee.employeesId}</p>
                        </div>
                    </>
                );
            },

            sorter: (record1, record2) => {
                return record1.employee.employeesId > record2.employee.employeesId;
            },
        },
        ...initColumns,
        {
            title: 'Actions',
            key: '_id',
            fixed: 'right',
            render: (id) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Action</p>
                    <Space size="middle">
                        <span className="reject_btn" onClick={(type) => showModal((type = 'reject'), id)}>
                            Reject
                        </span>
                        <span className="approve_btn" onClick={(type) => showModal((type = 'approve'), id)}>
                            Approve
                        </span>
                    </Space>
                </div>
            ),
        },
    ];

    const [columns, setColumns] = useState(initColumns);

    useEffect(() => {
        if (
            (user.role && user.role !== ROLE.USER) ||
            (user && user.employee && user.employee.isApproverExpense) ||
            (user && user.position && user.position.expenseClaim && user.position.expenseClaim.includes('2')) ||
            (user &&
                user.employee &&
                user.employee.controls &&
                user.employee.controls.expenseClaim &&
                user.employee.controls.expenseClaim.includes('2'))
        ) {
            setColumns(columnsManager);
        }
    }, [user]);

    useEffect(() => {
        if (expenseClaims) {
            let tmp = [];
            expenseClaims.items.forEach((item, i) => {
                tmp.push({
                    ...item,
                    key: ((expenseClaims.paginate.page || 1) - 1) * 5 + i + 1,
                });
            });
            setDataTable(tmp);
        }
    }, [expenseClaims]);

    const showModal = (type, id) => {
        setResponseRequest({ ...initRequest, status: type });
        setIsModalVisible(true);
        setTitle(type);
        setIdRequest(id._id);
    };

    const handleSubmit = async () => {
        try {
            if (responseRequest.reasonApprove === '') {
                toast.error(`Please input the reason why you ${title} this request!`);
            } else {
                setLoading(true);
                const tmp = {
                    ...responseRequest,
                    reasonApprove: responseRequest.reasonApprove.trim(),
                };
                const request = await expenseClaimAPI.update(idRequest, tmp);

                if (request && request.data && request.data._id && request.data.employee) {
                    getHistories(request.data._id, request.data.employee);
                }
                if (
                    request &&
                    request.data &&
                    request.data._id &&
                    request.data.employee &&
                    user &&
                    user.role &&
                    user.role !== ROLE.COMPANY
                ) {
                    getHistoriesToCompany(request.data._id, request.data.employee);
                }
                setLoading(false);
                toast.success('Update successfully!');
                history.push('/expense-claim');
                setIsModalVisible(false);
                fetchExpenseClaims({ ...filterExpenseClaim, status: EXPENSE_CLAIM_STATUS.PENDING, page: 1 });
                setResponseRequest(initRequest);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setResponseRequest(initRequest);
    };

    const onChangePage = (page) => {
        setPage(page);
        const filter = { ...filterExpenseClaim, page };
        setFilterExpenseClaim(filter);
        fetchExpenseClaims(filter);
    };

    const getHistories = async (id, employee) => {
        await historiesAPI.add({
            title: 'EXPENSE CLAIM REQUEST',
            desc: 'Your expense claim request just reviewed',
            employee,
            type: TYPE_NOTIFICATION.EXPENSECLAIM,
            idRequest: id,
            toEmployee: true,
        });
    };

    const getHistoriesToCompany = async (id, employee) => {
        await historiesAPI.add({
            title: 'EXPENSE CLAIM REQUEST',
            desc: `${user.employee.firstName} just review leave request`,
            employee,
            type: TYPE_NOTIFICATION.EXPENSECLAIM,
            idRequest: id,
            toCompany: true,
        });
    };

    const onOpen = (id) => {
        setExClaimSelected(id);
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
        setExClaimSelected();
    };

    return (
        <div className="overflow-hidden">
            <Table
                className="ct-table-data"
                columns={columns}
                dataSource={dataTable}
                pagination={{
                    onChange: onChangePage,
                    pageSize:
                        expenseClaims && expenseClaims.paginate && expenseClaims.paginate.size
                            ? expenseClaims.paginate.size
                            : 10,
                    total: expenseClaims && expenseClaims.paginate ? expenseClaims.paginate.count : 0,
                }}
            />
            <ExClaimCreateForm
                visible={visible}
                id={exClaimSelected}
                onCancel={() => {
                    onClose();
                }}
                type={FORM_TYPE.EDIT}
                pagePending={page}
                value={value}
            />
            <Modal
                title={`Are you sure to ${title} this request?`}
                open={isModalVisible}
                onOk={handleSubmit}
                onCancel={handleCancel}
            >
                <Row form>
                    <Col lg="12" className="form-group">
                        <div className="mt-2 mb-5">
                            <TextArea
                                maxLength={100}
                                rows={4}
                                value={responseRequest.reasonApprove}
                                placeholder={`Input the reason why you ${title} this request.`}
                                onChange={(e) => {
                                    setResponseRequest({
                                        ...responseRequest,
                                        reasonApprove: e.target.value,
                                    });
                                }}
                                width="100%"
                            />
                        </div>
                    </Col>
                </Row>
            </Modal>
        </div>
    );
};

export default TablePendingExClaim;
