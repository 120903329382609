import axios from 'axios';

const path = 'departments/';

const add = async (payload) => {
    return await axios.post(path, payload);
};

const update = async (id, payload) => {
    return await axios.patch(path + id, payload);
};

const get = async (filter = {}) => {
    return await axios.get(path, {
        params: filter,
    });
};

const getAllByCompany = async (id) => {
    return await axios.get(path + 'company/' + id);
};

const getByHead = async (id) => {
    return await axios.get(path + 'head/' + id);
};

const getById = async (id) => {
    return await axios.get(path + id);
};

export const departmentAPI = {
    add,
    update,
    get,
    getAllByCompany,
    getByHead,
    getById,
};
