import axios from "axios";
import { jwtManager } from "../helpers/jwtManager";

export default function configAxios() {
  axios.defaults.baseURL = process.env.REACT_APP_API;
  axios.interceptors.request.use(
    config => {
      const getToken = jwtManager.get();
      if (getToken) {
        // console.log(getToken);
        config.headers["Authorization"] = "Bearer " + getToken;
      }
      return config;
    },
    error => {
      Promise.reject(error);
    }
  );
}
