/* eslint-disable react/jsx-pascal-case */
import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'shards-react';
import { employeesAPI } from '../apis/employeesAPI';
import PageTitle from '../components/common/PageTitle';
import ControlDetail from '../components/control/ControlDetail';
import { ROLE } from '../helpers/const';
import { useUser } from '../hooks';

const Controls = () => {
    const history = useHistory();

    const { user, getMe } = useUser();
    const location = useLocation();
    const [employees, setEmployees] = useState([]);
    const [employee, setEmployee] = useState();
    const [info, setInfo] = useState();

    useEffect(() => {
        if (!user) {
            getMe();
        }
        user &&
            user.role &&
            user.role !== ROLE.COMPANY &&
            user.role !== ROLE.COMPANYSTAFF &&
            user.role !== ROLE.USER &&
            history.push('/login');
    }, [user]);

    useEffect(() => {
        if (!user) {
            getMe();
        }
        let id;
        if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
            id = user.company._id;
        } else if (
            user &&
            user.role &&
            user.role === ROLE.COMPANYSTAFF &&
            user.employee &&
            user.employee.company &&
            user.employee.company._id
        ) {
            id = user.employee.company._id;
        }
        loadEmployees(id);
    }, [user, location]);

    useEffect(() => {
        loadEmployee(employee);
    }, [employee]);

    const loadEmployees = async (id) => {
        try {
            if (id) {
                const { data } = await employeesAPI.getAllCompany(id);
                setEmployees(data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const loadEmployee = async (id) => {
        try {
            if (id) {
                const { data } = await employeesAPI.getById(id);
                setInfo(data[0]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <Col>
                    <PageTitle title="Controls" subtitle="Controls" className="text-sm-left mb-3" />
                </Col>

                {user && user.role && (user.role === ROLE.COMPANY || user.role === ROLE.COMPANYSTAFF) && (
                    <Col xl="2" lg="3" md="6" sm="12">
                        <div className="p-2 d-flex justify-content-center align-items-center">
                            <Select
                                showSearch
                                id="departmentHead"
                                style={{ width: '100%' }}
                                value={employee ? employee : ''}
                                onChange={(e) => setEmployee(e)}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {employees.map((employee, index) => (
                                    <Select.Option key={index} value={employee._id}>
                                        {employee.firstName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                    </Col>
                )}
            </Row>
            <Row className="mt-2 w-100" lg="12" xl="12" md="12" sm="12">
                <Card small className="h-100 w-100">
                    <CardHeader className="border-bottom">
                        <span className="ct-title">
                            {info && info.firstName
                                ? `${info.firstName} - ${info.employeesId}`
                                : 'Please choose employee'}
                        </span>
                    </CardHeader>

                    <CardBody className="col-xl-12 col-lg-12 ">
                        <Row>
                            <Col>
                                <ControlDetail item={info} />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Row>
        </Container>
    );
};

export default Controls;
