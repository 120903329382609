import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { AiFillPlusCircle } from 'react-icons/ai';
import { MdDeleteForever } from 'react-icons/md';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    Card,
    CardHeader,
    Col,
    Container,
    FormInput,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
} from 'shards-react';
import { departmentAPI } from '../apis/departmentAPI';
import { employeesAPI } from '../apis/employeesAPI';
import { historiesAPI } from '../apis/historiesAPI';
import ImportCSV from '../components/employeer/ImportEmployeesCSV';
import StatisticsEmployee from '../components/employeer/StatisticsEmployee';
import { ROLE, TYPE_NOTIFICATION } from '../helpers/const';
import { getError } from '../helpers/getErrorServer';
import { useUser } from '../hooks';
import EmployeeProvider from '../providers/employeeProvider';
import PageTitle from './../components/common/PageTitle';
import ExportCSV from './../components/employeer/EmployeesCSV';
import TableEmployees from './../components/employeer/TableEmployees';

function Employees() {
    const { user, getMe } = useUser();
    const [employees, setEmployees] = useState([]);
    const [search, setSearch] = useState('');
    const [deleteList, setDeleteList] = useState([]);
    const { type } = useParams();
    const [page, setPage] = useState(1);
    const history = useHistory();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const location = useLocation();

    useEffect(() => {
        if (!user) {
            getMe();
        }
        user &&
            user.role &&
            user.role !== ROLE.COMPANY &&
            user.role !== ROLE.COMPANYSTAFF &&
            user.role !== ROLE.USER &&
            history.push('/login');
    }, [user]);

    useEffect(() => {
        if (type) {
            fetchData(type);
        }
    }, [type, user, page, refresh, location]);

    const showModal = () => {
        if (deleteList.length > 0) {
            setIsModalOpen(true);
        } else {
            toast.error('Please select employee');
        }
    };

    const handleOk = () => {
        handleDelete();
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleDelete = () => {
        try {
            let rs = true;
            deleteList.map(async (item) => {
                const { data } = await departmentAPI.getByHead(item);

                if (data.length > 0) {
                    rs = false;
                    const { data } = await employeesAPI.getById(item);
                    toast.error(`Can't delete department head: ${data[0].firstName}`);
                    return;
                }

                const result = await employeesAPI._delete(item);

                if (user && user.employee && user.employee.firstName && result && result.data) {
                    await historiesAPI.add({
                        title: 'DELETE EMPLOYEE',
                        desc: `${user.employee.firstName} just deleted employee ${result.data.firstName}`,
                        type: TYPE_NOTIFICATION.EMPLOYEE,
                        employee: result.data._id,
                        idRequest: result.data._id,
                        toCompany: true,
                        requestDelete: true,
                    });
                }
            });
            if (rs === true) {
                toast.success('Successfully');
            }
            setRefresh((prev) => prev + 1);
            fetchData(type);
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const searchEmployee = () => {
        fetchData(type);
    };

    const fetchData = async (type) => {
        try {
            let userTmp = user;
            if (Object.keys(userTmp).length === 0) userTmp = await getMe();
            const role = userTmp.role;
            if (role === ROLE.COMPANY) {
                const { data } = await employeesAPI.getByCompany(userTmp.company._id, {
                    search,
                    type,
                    page,
                    limit: 10,
                });
                let rs = data;
                if (rs.items.length < 1 && page > 1) {
                    setPage((prev) => prev - 1);
                    const { data: tmp } = await employeesAPI.getByCompany(userTmp.company._id, {
                        search,
                        type,
                        page: page - 1,
                        limit: 10,
                    });
                    rs = tmp;
                }
                setEmployees(rs);
            } else if (
                role === ROLE.COMPANYSTAFF ||
                (user &&
                    ((user.position && user.position.employee && user.position.employee.includes('1')) ||
                        (user.employee &&
                            user.employee.controls &&
                            user.employee.controls.employee &&
                            user.employee.controls.employee.includes('1'))))
            ) {
                const { data } = await employeesAPI.getByCompany(userTmp.employee.company._id, {
                    search,
                    type,
                    page,
                    limit: 10,
                });
                let rs = data;
                if (rs.items.length < 1 && page > 1) {
                    setPage((prev) => prev - 1);
                    const { data: tmp } = await employeesAPI.getByCompany(userTmp.employee.company._id, {
                        search,
                        type,
                        page: page - 1,
                        limit: 10,
                    });
                    rs = tmp;
                }
                setEmployees(rs);
            } else if (
                role === ROLE.USER &&
                user &&
                user.position &&
                user.position.employee &&
                user.position.employee.length === 0 &&
                (!user.employee.controls ||
                    (user.employee.controls.employee && user.employee.controls.employee.length === 0))
            ) {
                const { data } = await employeesAPI.getById(userTmp.employee._id);
                setEmployees(data);
            }
        } catch (error) {
            toast.error(getError(error));
        }
    };

    return (
        <EmployeeProvider>
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4">
                    <PageTitle
                        title="Employees"
                        subtitle={<span className="route-main">Employees</span>}
                        className="text-sm-left mb-3"
                    />
                </Row>
                {/* <Row className="mt-2"> */}
                <StatisticsEmployee refresh={refresh} />
                <Card small className="h-100 mt-4 w-100">
                    <CardHeader className="border-bottom">
                        <div>
                            <span className="ct-title">Employees List</span>
                        </div>
                    </CardHeader>

                    <div className="ct-box">
                        <div className="col-xl-12 col-lg-12">
                            <Row className="p-0" lg="12" sm="12" md="8" xl="12">
                                <Col xl="4" lg="12" md="12" sm="12" className="p-1">
                                    <InputGroup>
                                        <FormInput
                                            placeholder="Input Search Text"
                                            onChange={(e) => setSearch(e.target.value)}
                                            onKeyPress={(event) => {
                                                if (event.key === 'Enter') {
                                                    searchEmployee();
                                                }
                                            }}
                                        />
                                        <InputGroupAddon type="append" onClick={() => searchEmployee()}>
                                            <InputGroupText className="pointer">
                                                <i className="material-icons" style={{ fontSize: '25px' }}>
                                                    search
                                                </i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </Col>
                                {/* <Col xl="2"></Col> */}
                                <Col xl="8" lg="12" md="12" sm="12">
                                    <Row>
                                        {/* <Col lg="3"></Col> */}
                                        {((user &&
                                            user.role &&
                                            (user.role === ROLE.COMPANY || user.role === ROLE.COMPANYSTAFF)) ||
                                            (user &&
                                                user.position &&
                                                user.position.employee &&
                                                user.position.employee.includes('4')) ||
                                            (user &&
                                                user.employee &&
                                                user.employee.controls &&
                                                user.employee.controls.employee &&
                                                user.employee.controls.employee.includes('4'))) && (
                                            <Col lg="3" md="12" sm="12" className="p-1">
                                                <button className="button_delete" onClick={() => showModal()}>
                                                    <MdDeleteForever size={20} />
                                                    <span className="text__red">Delete</span>
                                                </button>
                                            </Col>
                                        )}

                                        {((user &&
                                            user.role &&
                                            (user.role === ROLE.COMPANYSTAFF || user.role === ROLE.COMPANY)) ||
                                            (user &&
                                                user.position &&
                                                user.position.employee &&
                                                user.position.employee.includes('3')) ||
                                            (user &&
                                                user.employee &&
                                                user.employee.controls &&
                                                user.employee.controls.employee &&
                                                user.employee.controls.employee.includes('3'))) && (
                                            <Col lg="3" md="12" sm="12" className="p-1">
                                                <div>
                                                    <ImportCSV setRefresh={setRefresh} />
                                                </div>
                                            </Col>
                                        )}

                                        {((user &&
                                            user.role &&
                                            (user.role === ROLE.COMPANYSTAFF || user.role === ROLE.COMPANY)) ||
                                            (user &&
                                                user.position &&
                                                user.position.employee &&
                                                user.position.employee.includes('6')) ||
                                            (user &&
                                                user.employee &&
                                                user.employee.controls &&
                                                user.employee.controls.employee &&
                                                user.employee.controls.employee.includes('6'))) && (
                                            <Col lg="3" md="12" sm="12" className="p-1">
                                                <div>
                                                    <ExportCSV />
                                                </div>
                                            </Col>
                                        )}

                                        {((user &&
                                            user.role &&
                                            (user.role === ROLE.COMPANYSTAFF || user.role === ROLE.COMPANY)) ||
                                            (user &&
                                                user.position &&
                                                user.position.employee &&
                                                user.position.employee.includes('3')) ||
                                            (user &&
                                                user.employee &&
                                                user.employee.controls &&
                                                user.employee.controls.employee &&
                                                user.employee.controls.employee.includes('3'))) && (
                                            <Col lg="3" md="12" sm="12" className="p-1">
                                                <div>
                                                    <Link
                                                        to="/create-employee"
                                                        className="text-center rounded p-2 d-flex justify-content-center align-items-center button__warning"
                                                    >
                                                        <AiFillPlusCircle size={25} />
                                                        <span className="text ml-2"> New Employee</span>
                                                    </Link>
                                                </div>
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="p-0">
                                    <TableEmployees
                                        tableData={employees}
                                        callback={fetchData}
                                        deleteList={deleteList}
                                        setDeleteList={setDeleteList}
                                        setPage={setPage}
                                        page={page}
                                        setReload={setRefresh}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Card>
                {/* </Row> */}
                <Modal
                    title={
                        <>
                            <svg
                                width="18"
                                height="21"
                                viewBox="0 0 18 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M15.4167 0.833344H2.58333C1.56583 0.833344 0.75 1.65834 0.75 2.66668V15.5C0.75 16.5083 1.56583 17.3333 2.58333 17.3333H6.25L9 20.0833L11.75 17.3333H15.4167C16.425 17.3333 17.25 16.5083 17.25 15.5V2.66668C17.25 1.65834 16.425 0.833344 15.4167 0.833344ZM9.91667 15.5H8.08333V13.6667H9.91667V15.5ZM11.8142 8.39584L10.9892 9.23918C10.3292 9.90834 9.91667 10.4583 9.91667 11.8333H8.08333V11.375C8.08333 10.3667 8.49583 9.45001 9.15583 8.78084L10.2925 7.62584C10.6317 7.29584 10.8333 6.83751 10.8333 6.33334C10.8333 5.32501 10.0083 4.50001 9 4.50001C7.99167 4.50001 7.16667 5.32501 7.16667 6.33334H5.33333C5.33333 4.30751 6.97417 2.66668 9 2.66668C11.0258 2.66668 12.6667 4.30751 12.6667 6.33334C12.6667 7.14001 12.3367 7.87334 11.8142 8.39584Z"
                                    fill="#FCBB0A"
                                />
                            </svg>

                            <p className="title-modal-custom">Are you sure delete this employee?</p>
                        </>
                    }
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                >
                    <p>By delete employees</p>
                </Modal>
            </Container>
        </EmployeeProvider>
    );
}

export default Employees;
