import axios from 'axios';

const path = 'https://openexchangerates.org/api/currencies.json';

const axiosConfig = axios.create({
    baseURL: path,
});

const getCurrency = async () => {
    return await axiosConfig.get();
};

export const currencyAPI = {
    getCurrency,
};
