export const LEAVE_TYPE = [
    {
        lable: 'Annual Leave',
        value: 'annualLeave',
    },
    {
        lable: 'Sick Leave',
        value: 'sickLeave',
    },
];

export const LEAVE_STATUS = {
    APPROVE: 'approve',
    REJECT: 'reject',
    PENDING: 'pending',
};

export const LEAVE_SESSION = [
    {
        label: 'Morning',
        value: '1',
    },
    {
        label: 'Afternoon',
        value: '2',
    },
];

export const LEAVE_UNIT = {
    DAY: 'day',
    HALF_DAY: 'half-day',
    HOURS: 'hours',
    SPECIAL: 'special',
};

export const LEAVE_DAY_COUNT = {
    WORKDAY: 'work-day',
    CALENDAR_DAY: 'calendar-day',
};

export const CATEGORY_LEAVE = [
    {
        title: 'ML',
        value: 'MC',
    },
    {
        title: 'Training',
        value: 'Training',
    },
    {
        title: 'Annual Leave',
        value: 'Annual Leave',
    },
    {
        title: 'Entitled Leave',
        value: 'Entitled Leave',
    },
    {
        title: 'Hosp Leave',
        value: 'Hosp Leave',
    },
    {
        title: 'Other Leave',
        value: 'Other Leave',
    },
];

export const CATEGORY_LEAVE_VALUE = {
    MC: 'MC',
    Training: 'Training',
    Annual: 'Annual Leave',
};

export const LEAVE_SESSION_VALUE = {
    MORNING: '1',
    AFTERNOON: '2',
};
