import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody } from 'shards-react';
import PageTitle from './../components/common/PageTitle';
import PayrollList from './../components/payroll/PayrollList';
import PayrollForm from '../components/payroll/PayrollForm';
import { useUser } from '../hooks';
import { ROLE } from '../helpers/const';
import { Button, Modal, Form } from 'antd';
import { AiFillPlusCircle } from 'react-icons/ai';
import { useHistory, useLocation } from 'react-router-dom';
import { payRollAPI } from '../apis/payRollAPI';
import { usePayroll } from '../hooks/usePayRoll';
import AddPayrollForm from '../components/payroll/AddPayrollForm';
import TabPayroll from '../components/payroll/TabPayroll';
import { CSVDownload, CSVLink } from 'react-csv';

function Payroll() {
    const [visible, setVisible] = useState(false);
    const [load, reload] = useState(0);
    const [refetchPage, setRefetchPage] = useState(0);
    const { user, getMe } = useUser();
    const history = useHistory();
    const location = useLocation();
    const [tab, setTab] = useState(1);
    const [overview, setOverview] = useState();
    const [company, setCompany] = useState();
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [year, setYear] = useState(new Date().getFullYear());
    const [pathname, setPathName] = useState('all');
    const [cpf, setCpf] = useState(0);
    const [sdl, setSdl] = useState(0);
    const [totalEmployee, setTotalEmployee] = useState(0);
    const [totalWages, setTotalWages] = useState(0);
    const [add, setAdd] = useState(0);
    const [openAddSalaryForm, setOpenAddSalaryForm] = useState(false);
    const [reloadPageDelete, setReloadPageDelete] = useState(0);
    const SGDDollar = new Intl.NumberFormat('en-SG', { style: 'currency', currency: 'SGD' });
    const [openPrintModel, setOpenPrintModel] = useState(false);
    const [dataPrint, setDataPrint] = useState([]);
    const [dataSummary, setDataSummary] = useState({});
    const [csvData, setCsvData] = useState([]);

    useEffect(() => {
        setTab(1);
        const arr = location.pathname.split('/');
        setPathName(arr[arr.length - 1 >= 0 ? arr.length - 1 : 0]);
    }, [location]);

    useEffect(() => {
        if (!user) {
            getMe();
        }
        user &&
            user.role &&
            user.role !== ROLE.COMPANY &&
            user.role !== ROLE.COMPANYSTAFF &&
            user.role !== ROLE.USER &&
            history.push('/login');
    }, [user]);

    const onCreate = (values) => {
        setVisible(false);
    };

    useEffect(() => {
        if (pathname === 'all') {
            overview && overview.totalSDL ? setSdl(overview.totalSDL) : setSdl(0);
            overview && overview.totalCDF ? setCpf(overview.totalCDF) : setCpf(0);
            overview && overview.totalEmployee ? setTotalEmployee(overview.totalEmployee) : setTotalEmployee(0);
            overview && overview.totalWage ? setTotalWages(overview.totalWage) : setTotalWages(0);
        } else if (pathname === 'local-staff') {
            overview && overview.totalSDLLocal ? setSdl(overview.totalSDLLocal) : setSdl(0);
            overview && overview.totalCDFLocal ? setCpf(overview.totalCDFLocal) : setCpf(0);
            overview && overview.totalEmployeeLocal
                ? setTotalEmployee(overview.totalEmployeeLocal)
                : setTotalEmployee(0);
            overview && overview.totalWageLocal ? setTotalWages(overview.totalWageLocal) : setTotalWages(0);
        } else if (pathname === 'work-permit') {
            overview && overview.totalSDLWP ? setSdl(overview.totalSDLWP) : setSdl(0);
            overview && overview.totalCDFWP ? setCpf(overview.totalCDFWP) : setCpf(0);
            overview && overview.totalEmployeeWP ? setTotalEmployee(overview.totalEmployeeWP) : setTotalEmployee(0);
            overview && overview.totalWageWp ? setTotalWages(overview.totalWageWp) : setTotalWages(0);
        } else if (pathname === 's-pass') {
            overview && overview.totalSDLSP ? setSdl(overview.totalSDLSP) : setSdl(0);
            overview && overview.totalCDFSP ? setCpf(overview.totalCDFSP) : setCpf(0);
            overview && overview.totalEmployeeSP ? setTotalEmployee(overview.totalEmployeeSP) : setTotalEmployee(0);
            overview && overview.totalWageSP ? setTotalWages(overview.totalWageSP) : setTotalWages(0);
        } else if (pathname === 'ep') {
            overview && overview.totalSDLEP ? setSdl(overview.totalSDLEP) : setSdl(0);
            overview && overview.totalCDFEP ? setCpf(overview.totalCDFEP) : setCpf(0);
            overview && overview.totalEmployeeEP ? setTotalEmployee(overview.totalEmployeeEP) : setTotalEmployee(0);
            overview && overview.totalWageEP ? setTotalWages(overview.totalWageEP) : setTotalWages(0);
        }
    }, [pathname, overview, load, refetchPage, reloadPageDelete]);

    useEffect(() => {
        (async () => {
            if (Object.keys(user).length === 0) await getMe();

            if (user && user.company && user.company._id) {
                setCompany(user.company._id);
            } else if (user && user.employee && user.employee.company && user.employee.company._id) {
                setCompany(user.employee.company._id);
            }
        })();
    }, [user]);

    useEffect(() => {
        (async () => {
            const { data } = await payRollAPI.getTotalCDLandSDF({
                month,
                year,
                company,
            });
            setOverview(data);
        })();
    }, [company, month, year, add, refetchPage, reloadPageDelete]);

    useEffect(() => {
        (async () => {
            const { data } = await payRollAPI.exportSummaryForPayroll({
                month,
                year,
                company,
            });
            setDataPrint(data.items);
            setDataSummary(data.total);
        })();
    }, [company, month, year, add, refetchPage, reloadPageDelete]);

    const openPrint = () => {
        setOpenPrintModel(true);
    };
    const closePrint = () => {
        setOpenPrintModel(false);
    };

    const printPaySlip = () => {
        var divContents = document.getElementById('print').innerHTML;
        var a = window.open('', '', 'height=500, width=500');
        a.document.write('<html>');
        a.document.write(`<body > <h1>Summary Payroll ${month}/${year}<br>`);
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
        a.print();
    };

    let headers = ['Summary payroll', `${month}/${year}`];
    let data = [];
    let headersLocalStaff = [
        'Employee name',
        'Id',
        'Email',
        'Type Employee',
        'Bank account',
        'Basic salary',
        'Transport allowance',
        'OT allowance',
        'Unpaid leave',
        'Others',
        'Gross salary',
        'Total CPF To Be Paid',
        'Employer SDL',
        'Employer CPF',
        'Employee CPF',
        'Employee MBMF',
        'Advance',
        'Claims',
        'Net Pay',
    ];
    let headersWorkPermit = [
        'Employee name',
        'Id',
        'Email',
        'Type Employee',
        'Bank account',
        'Daily/Month',
        'Basic Salary (Per Day/ Per Month)',
        'Hourly Rate',
        'Hourly OT Rate',
        'Worked Days',
        'Net OT Hours (Hrs)',
        'Total Basic Salary',
        'Total OT Pay',
        'Allowance',
        'Others',
        'Gross Salary',
        'Advance',
        'Claims',
        'Employer SDL',
        'Net Salary (S$)',
    ];
    let headersWorkSpass = [
        'Employee name',
        'Id',
        'Email',
        'Type Employee',
        'Bank account',
        'Basic Salary',
        'Transport Allowance',
        'OT Allowance',
        'Unpaid Leave',
        'Others',
        'Gross Salary',
        'Advance',
        'Claims',
        'Employer SDL',
        'Net Pay',
    ];
    const renderTable = () => {
        let itemsLocalStaff = [];
        let itemsWorkPermit = [];
        let itemsSpass = [];
        // let itemsEP = [];

        dataPrint.map((item, idex) => {
            if (item.employee.type === 'local-staff') {
                itemsLocalStaff.push([
                    `${item.employee.firstName} ${item.employee.middleName} ${item.employee.lastName}`,
                    `${item.employee.employeesId} `,
                    `${item.employee.email} `,
                    `${item.employee.type} `,
                    `${item.employee.payment.bankAccount} `,
                    `${SGDDollar.format(item.summary.basicSalary)} `,
                    `${SGDDollar.format(item.summary.transportAllowance)} `,
                    `${SGDDollar.format(item.summary.otAllowance)} `,
                    `${SGDDollar.format(item.summary.unpaidLeave)} `,
                    `${SGDDollar.format(item.summary.other)} `,
                    `${SGDDollar.format(item.summary.grossSalary)} `,
                    `${SGDDollar.format(item.summary.totalCPFToBePaid)} `,
                    `${SGDDollar.format(item.summary.employerSDL)} `,
                    `${SGDDollar.format(item.summary.employerCPF)} `,
                    `${SGDDollar.format(item.summary.employeeCPF)} `,
                    `${SGDDollar.format(item.summary.employeeMBMF)} `,
                    `${SGDDollar.format(item.summary.deductionAdvance)} `,
                    `${SGDDollar.format(item.summary.claims)} `,
                    `${SGDDollar.format(item.summary.netPay)} `,
                ]);
            }
            if (item.employee.type === 'work-permit') {
                itemsWorkPermit.push([
                    `${item.employee.firstName} ${item.employee.middleName} ${item.employee.lastName}`,
                    `${item.employee.employeesId} `,
                    `${item.employee.email} `,
                    `${item.employee.type} `,
                    `${item.employee.payment.bankAccount} `,
                    `${item.dailyMonthly} `,
                    `${SGDDollar.format(item.summary.basicSalary)} `,
                    `${SGDDollar.format(item.hourlyRate)} `,
                    `${SGDDollar.format(item.overtimeRate)} `,
                    `${item.summary.workedDays} days`,
                    `${item.summary.netOtHours} hrs`,
                    `${SGDDollar.format(item.summary.totalBasicSalary)} `,
                    `${SGDDollar.format(item.summary.totalOTPay)} `,
                    `${SGDDollar.format(item.summary.allowance)} `,
                    `${SGDDollar.format(item.summary.other)} `,
                    `${SGDDollar.format(item.summary.grossSalary)} `,
                    `${SGDDollar.format(item.summary.deductionAdvance)} `,
                    `${SGDDollar.format(item.summary.claims)} `,
                    `${SGDDollar.format(item.summary.employerSDL)} `,
                    `${SGDDollar.format(item.summary.netPay)} `,
                ]);
            }
            if (item.employee.type === 's-pass' || item.employee.type === 'ep') {
                itemsSpass.push([
                    `${item.employee.firstName} ${item.employee.middleName} ${item.employee.lastName}`,
                    `${item.employee.employeesId} `,
                    `${item.employee.email} `,
                    `${item.employee.type} `,
                    `${item.employee.payment.bankAccount} `,
                    `${SGDDollar.format(item.summary.basicSalary)} `,
                    `${SGDDollar.format(item.summary.transportAllowance)} `,
                    `${SGDDollar.format(item.summary.otAllowance)} `,
                    `${SGDDollar.format(item.summary.unpaidLeave)} `,
                    `${SGDDollar.format(item.summary.other)} `,
                    `${SGDDollar.format(item.summary.grossSalary)} `,
                    `${SGDDollar.format(item.summary.deductionAdvance)} `,
                    `${SGDDollar.format(item.summary.claims)} `,
                    `${SGDDollar.format(item.summary.employerSDL)} `,
                    `${SGDDollar.format(item.summary.netPay)} `,
                ]);
            }
        });

        data.push([]);
        if (itemsLocalStaff.length > 0) {
            data.push(['local-staff', 'Total employee:', itemsLocalStaff.length]);
            data.push(headersLocalStaff);
            for (const item of itemsLocalStaff) {
                data.push(item);
            }
            data.push([]);
        }
        if (itemsWorkPermit.length > 0) {
            data.push(['work-permit', 'Total employee:', itemsWorkPermit.length]);
            data.push(headersWorkPermit);
            for (const item of itemsWorkPermit) {
                data.push(item);
            }
            data.push([]);
        }
        if (itemsSpass.length > 0) {
            data.push(['s-pass and ep', 'Total employee:', itemsSpass.length]);
            data.push(headersWorkSpass);
            for (const item of itemsSpass) {
                data.push(item);
            }
            data.push([]);
        }

        data.push(['Total Net pay:', `${dataSummary ? SGDDollar.format(dataSummary) : 0} `]);
        setCsvData(data);
    };

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <Col lg="6">
                    <PageTitle
                        title="Payroll"
                        subtitle={<span className="route-main">Payroll</span>}
                        className="text-sm-left mb-3 "
                    />
                </Col>
                {user &&
                    (user.role === ROLE.COMPANYSTAFF ||
                        user.role === ROLE.COMPANY ||
                        (user && user.position && user.position.payroll && user.position.payroll.length > 0) ||
                        (user &&
                            user.employee &&
                            user.employee.controls &&
                            user.employee.controls.payroll &&
                            user.employee.controls.payroll.length > 0)) && (
                        <Col lg="6" className="d-flex justify-content-end">
                            <Col lg="4">
                                <div
                                    className="text-center rounded p-2  d-flex justify-content-center align-items-center button__warning"
                                    onClick={() => {
                                        // setVisible(true);
                                        setOpenAddSalaryForm(true);
                                    }}
                                >
                                    <AiFillPlusCircle size={20} />
                                    <span className="ml-2"> Add Salary</span>
                                </div>
                            </Col>
                            <AddPayrollForm
                                visible={openAddSalaryForm}
                                setVisible={setOpenAddSalaryForm}
                                setReload={reload}
                                setRefetchPage={setRefetchPage}
                                refetchPage={refetchPage}
                            />
                            <PayrollForm
                                visible={visible}
                                onCreate={onCreate}
                                refetch={reload}
                                onCancel={() => {
                                    setVisible(false);
                                }}
                                setAdd={setAdd}
                            />
                        </Col>
                    )}
            </Row>

            <Card small className="card_ovv_employees">
                <CardHeader className="border-bottom">
                    <Row form className="d-flex mt-2 ant-form-item-control-input-content">
                        <Col md="1.7" className="d-flex justify-content-end align-items-start">
                            <span className="ct-title">
                                Overview {month}-{year}
                            </span>
                        </Col>
                        <Col md="3" className="button__print_download_payroll">
                            <Button onClick={() => renderTable()}>
                                <CSVLink
                                    data={csvData}
                                    headers={headers}
                                    filename={`summary-payroll-${month}-${year}.csv`}
                                >
                                    <span className="material-icons">download</span>CSV
                                </CSVLink>
                            </Button>
                            <Button onClick={() => openPrint()}>
                                <span className="material-icons">print</span>Print
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>

                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 bg-white rounded-bottom">
                    <Row lg="12" sm="6" md="8" xl="12">
                        <Col lg="3" className="pt-3 pb-3 border-right">
                            <div className="col p-0">
                                <div className=" widget-flat h-100 statistical__box">
                                    <div className="statistical">
                                        <div className="float-end">
                                            <i className="mdi mdi-account-multiple widget-icon" />
                                        </div>
                                        <span className="" title="Number of Customers">
                                            Total Employees:
                                        </span>
                                        <span className="">{totalEmployee}</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg="3" className="pt-3 pb-3 border-right">
                            <div className="col p-0">
                                <div className=" widget-flat h-100 statistical__box">
                                    <div className="statistical">
                                        <div className="float-end">
                                            <i className="mdi mdi-account-multiple widget-icon" />
                                        </div>
                                        <span className="" title="Number of Customers">
                                            Total wages:
                                        </span>
                                        <span className="">{SGDDollar.format(totalWages)}</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg="3" className="pt-3 pb-3 border-right">
                            <div className="col p-0">
                                <div className=" widget-flat h-100 statistical__box">
                                    <div className="statistical">
                                        <div className="float-end">
                                            <i className="mdi mdi-account-multiple widget-icon" />
                                        </div>
                                        <span className="" title="Number of Customers">
                                            CPF:
                                        </span>
                                        <span className="">{SGDDollar.format(cpf)}</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg="3" className="pt-3 pb-3 border-right">
                            <div className="col p-0">
                                <div className=" widget-flat h-100  ">
                                    <div className="statistical">
                                        <div className="float-end">
                                            <i className="mdi mdi-account-multiple widget-icon" />
                                        </div>
                                        <span className="" title="Number of Customers">
                                            SDL:
                                        </span>
                                        <span className="">{SGDDollar.format(sdl)}</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Card>
            <br></br>
            <Modal
                title={`Print Summary Payroll ${month}/${year}`}
                open={openPrintModel}
                onOk={printPaySlip}
                onCancel={closePrint}
                okText={'Print'}
                cancleText={'Close'}
                zIndex={999}
                width={1000}
            >
                <Button onClick={() => printPaySlip()}>
                    <span className="material-icons">print</span>Print
                </Button>
                <div id="print">
                    <Form layout="vertical" name="form_in_modal" initialValues={{ modifier: 'public' }}>
                        <hr />
                        {dataPrint
                            ? dataPrint.map((item, idex) => {
                                  return (
                                      <div key={idex}>
                                          <p>
                                              <b>Employee name: </b>
                                              {`${item.employee.firstName} ${item.employee.middleName} ${item.employee.lastName}`}
                                          </p>
                                          <p>Id: {`${item.employee.employeesId} `}</p>
                                          <p>Email: {`${item.employee.email} `}</p>
                                          <p>Type Employee: {`${item.employee.type} `}</p>
                                          <p>Bank account: {`${item.employee.payment.bankAccount} `}</p>
                                          <br></br>
                                          {item.employee.type === 'local-staff' ? (
                                              <>
                                                  <p>
                                                      Basic salary: {`${SGDDollar.format(item.summary.basicSalary)} `}
                                                  </p>
                                                  <p>
                                                      Transport allowance:{' '}
                                                      {`${SGDDollar.format(item.summary.transportAllowance)} `}
                                                  </p>
                                                  <p>
                                                      OT allowance: {`${SGDDollar.format(item.summary.otAllowance)} `}
                                                  </p>
                                                  <p>
                                                      Unpaid leave: {`${SGDDollar.format(item.summary.unpaidLeave)} `}
                                                  </p>
                                                  <p>Others: {`${SGDDollar.format(item.summary.other)} `}</p>
                                                  <p>
                                                      Gross salary: {`${SGDDollar.format(item.summary.grossSalary)} `}
                                                  </p>
                                                  <p>
                                                      Total CPF to be paid:{' '}
                                                      {`${SGDDollar.format(item.summary.totalCPFToBePaid)} `}
                                                  </p>
                                                  <p>
                                                      Employer SDL: {`${SGDDollar.format(item.summary.employerSDL)} `}
                                                  </p>
                                                  <p>
                                                      Employer CPF: {`${SGDDollar.format(item.summary.employerCPF)} `}
                                                  </p>
                                                  <p>
                                                      Employee CPF: {`${SGDDollar.format(item.summary.employeeCPF)} `}
                                                  </p>
                                                  <p>
                                                      Employee MBMF: {`${SGDDollar.format(item.summary.employeeMBMF)} `}
                                                  </p>
                                                  <p>
                                                      Advance: {`${SGDDollar.format(item.summary.deductionAdvance)} `}
                                                  </p>
                                                  <p>Claims: {`${SGDDollar.format(item.summary.claims)} `}</p>
                                                  <p>
                                                      <b>Net pay:</b> {`${SGDDollar.format(item.summary.netPay)} `}
                                                  </p>
                                              </>
                                          ) : item.employee.type === 'work-permit' ? (
                                              <>
                                                  <p>Daily/Month: {`${item.dailyMonthly} `}</p>
                                                  <p>
                                                      Basic Salary (Per Day/ Per Month):{' '}
                                                      {`${SGDDollar.format(item.summary.basicSalary)} `}
                                                  </p>
                                                  <p>Hourly Rate : {`${SGDDollar.format(item.hourlyRate)} `}</p>
                                                  <p>Hourly OT Rate : {`${SGDDollar.format(item.overtimeRate)} `}</p>
                                                  <p>Worked Days: {`${item.summary.workedDays} days`}</p>
                                                  <p>Net OT Hours (Hrs): {`${item.summary.netOtHours} hrs`}</p>
                                                  <p>
                                                      Total Basic Salary:{' '}
                                                      {`${SGDDollar.format(item.summary.totalBasicSalary)} `}
                                                  </p>
                                                  <p>Total OT Pay: {`${SGDDollar.format(item.summary.totalOTPay)} `}</p>
                                                  <p>Allowance: {`${SGDDollar.format(item.summary.allowance)} `}</p>
                                                  <p>Others: {`${SGDDollar.format(item.summary.other)} `}</p>
                                                  <p>
                                                      Gross salary: {`${SGDDollar.format(item.summary.grossSalary)} `}
                                                  </p>
                                                  <p>
                                                      Advance: {`${SGDDollar.format(item.summary.deductionAdvance)} `}
                                                  </p>
                                                  <p>Claims: {`${SGDDollar.format(item.summary.claims)} `}</p>
                                                  <p>
                                                      Employer SDL: {`${SGDDollar.format(item.summary.employerSDL)} `}
                                                  </p>
                                                  <p>
                                                      <b>Net pay:</b> {`${SGDDollar.format(item.summary.netPay)} `}
                                                  </p>
                                              </>
                                          ) : item.employee.type === 's-pass' || item.employee.type === 'ep' ? (
                                              <>
                                                  <p>
                                                      Basic salary: {`${SGDDollar.format(item.summary.basicSalary)} `}
                                                  </p>
                                                  <p>
                                                      Transport allowance:{' '}
                                                      {`${SGDDollar.format(item.summary.transportAllowance)} `}
                                                  </p>
                                                  <p>
                                                      OT allowance: {`${SGDDollar.format(item.summary.otAllowance)} `}
                                                  </p>
                                                  <p>
                                                      Unpaid leave: {`${SGDDollar.format(item.summary.unpaidLeave)} `}
                                                  </p>
                                                  <p>Others: {`${SGDDollar.format(item.summary.other)} `}</p>
                                                  <p>
                                                      Gross salary: {`${SGDDollar.format(item.summary.grossSalary)} `}
                                                  </p>
                                                  <p>
                                                      Advance: {`${SGDDollar.format(item.summary.deductionAdvance)} `}
                                                  </p>
                                                  <p>Claims: {`${SGDDollar.format(item.summary.claims)} `}</p>
                                                  <p>
                                                      Employer SDL: {`${SGDDollar.format(item.summary.employerSDL)} `}
                                                  </p>
                                                  <p>
                                                      <b>Net pay:</b> {`${SGDDollar.format(item.summary.netPay)} `}
                                                  </p>
                                              </>
                                          ) : (
                                              <></>
                                          )}

                                          <hr />
                                          <br></br>
                                      </div>
                                  );
                              })
                            : []}
                        <p>
                            <b>Total net pay: </b>
                            {`${dataSummary ? SGDDollar.format(dataSummary) : 0} `}
                        </p>
                    </Form>
                </div>
            </Modal>

            <Row>
                {/* <Col>
                    <Card>
                        <CardHeader className="border-bottom">
                            <span className="ct-title">Payroll List</span>
                        </CardHeader>
                        <CardBody>
                            <PayrollList onCreate={load} tab={tab} />
                        </CardBody>
                    </Card>
                </Col> */}
                <TabPayroll
                    reloadPageDelete={reloadPageDelete}
                    setReloadPageDelete={setReloadPageDelete}
                    load={load}
                    tab={tab}
                />
            </Row>
        </Container>
    );
}

export default Payroll;
