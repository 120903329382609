import { DatePicker, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { AiFillPlusCircle } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { Col, Container, Row } from 'shards-react';
import { historiesAPI } from '../apis/historiesAPI';
import { publicHolidayAPI } from '../apis/publicHolidayAPI';
import PageTitle from '../components/common/PageTitle';
import ListPublicHoliday from '../components/public-holiday/ListPublicHoliday';
import { ROLE, TYPE_NOTIFICATION } from '../helpers/const';
import { getError } from '../helpers/getErrorServer';
import { useUser } from '../hooks';

const initAddHoliday = {
    title: '',
    startDay: null,
    endDay: null,
};

function PublicHoliday() {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [reload, setReload] = useState(0);
    const [titleHoliday, setTitleHoliday] = useState('');
    const [day, setDay] = useState(initAddHoliday);
    const { user, getMe } = useUser();

    const validate = () => {
        if (titleHoliday === '') {
            toast.error('Please enter title');
            return false;
        }
        if (day.startDay === null) {
            toast.error('Please choose start day');
            return false;
        }
        if (day.endDay === null) {
            toast.error('Please choose end day');
            return false;
        }
        if (new Date(day.startDay).getTime() > new Date(day.endDay).getTime()) {
            toast.error('The start date cannot be greater than the end date');
            return false;
        }
        return true;
    };

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = async () => {
        if (validate()) {
            setConfirmLoading(true);
            await addHoliday();

            setOpen(false);
            setConfirmLoading(false);
            setReload((e) => e + 1);
        }
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const onChangeStartDay = (date, dateString) => {
        setDay({ ...day, startDay: new Date(new Date(dateString).setHours(0, 0, 0, 0)).getTime() });
    };

    const onChangeEndDay = (date, dateString) => {
        setDay({ ...day, endDay: new Date(new Date(dateString).setHours(23, 59, 59, 999)).getTime() });
    };

    const addHoliday = async () => {
        try {
            if (Object.keys(user).length === 0) await getMe();
            let id = null;
            if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
                id = user.company._id;
            }
            if (
                user &&
                user.role &&
                user.role === ROLE.COMPANYSTAFF &&
                user.employee &&
                user.employee.company &&
                user.employee.company._id
            ) {
                id = user.employee.company._id;
            }

            if (id !== null) {
                const result = await publicHolidayAPI.create({
                    title: titleHoliday,
                    startDay: day.startDay,
                    endDay: day.endDay,
                    companyId: id,
                });

                if (result && user && user.role && user.role === ROLE.COMPANYSTAFF) {
                    await historiesAPI.add({
                        title: 'PUBLIC HOLIDAY',
                        desc: `Client HR ${user.employee.firstName} just add new public holiday`,
                        type: TYPE_NOTIFICATION.HOLIDAY,
                        employee: user.employee._id,
                        idRequest: result.data._id,
                        toCompany: true,
                    });
                }
                toast.success('Successfully added public holiday');
            }
        } catch (error) {
            toast.error(getError(error));
        }
    };

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <Col lg="6">
                    <PageTitle
                        title="Public Holiday"
                        subtitle={<span className="route-main">Public Holiday</span>}
                        className="text-sm-left mb-3 "
                    />
                </Col>

                <Col lg="6" className="d-flex justify-content-end">
                    <div
                        className="text-center rounded p-2 d-flex justify-content-center align-items-center button__warning"
                        onClick={showModal}
                    >
                        <AiFillPlusCircle size={25} />
                        <span className="ml-2">Add Public Holiday</span>
                    </div>
                    <Modal
                        title="Add public holiday"
                        open={open}
                        onOk={handleOk}
                        zIndex={999}
                        confirmLoading={confirmLoading}
                        onCancel={handleCancel}
                    >
                        <div className="form-add-holiday">
                            <div>
                                <label>Title</label>
                                <Input onChange={(e) => setTitleHoliday(e.target.value)} />
                            </div>
                            <div>
                                <label>Start day</label>
                                <DatePicker onChange={onChangeStartDay} />
                            </div>
                            <div>
                                <label>End day</label>
                                <DatePicker onChange={onChangeEndDay} />
                            </div>
                        </div>
                    </Modal>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col lg="12" md="12" sm="12" className="mb-4">
                    <ListPublicHoliday user={user} refetch={reload} />
                </Col>
            </Row>
        </Container>
    );
}

export default PublicHoliday;
