import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { attendanceAPI } from '../apis/attendanceAPI';
import { AttendanceContext } from '../contexts/attendanceContext';
import { ATTENDANCE_FILTER } from '../global/attendance/filterAttendance';
import { ROLE } from '../helpers/const';
import { getError } from '../helpers/getErrorServer';
import { RESPONSE } from '../helpers/response';
import { useUser } from '../hooks';

export default function AttendanceProvider({ children }) {
    const { user, getMe } = useUser();
    const [attendances, setAttendances] = useState(RESPONSE);
    const [filterAttendance, setFilterAttendance] = useState(ATTENDANCE_FILTER);

    const fetchAttendance = async (filter = ATTENDANCE_FILTER) => {
        try {
            if (!user) await getMe();
            filter = {
                ...ATTENDANCE_FILTER,
                ...filter,
            };
            let tmpUser = user;

            if (Object.keys(tmpUser).length === 0) tmpUser = await getMe();
            if (
                tmpUser &&
                tmpUser.role &&
                (tmpUser.role === ROLE.USER || tmpUser.role === ROLE.COMPANYSTAFF) &&
                tmpUser.employee &&
                tmpUser.employee._id
            ) {
                const { data } = await attendanceAPI.getAllById(tmpUser.employee._id, filter);

                setFilterAttendance(filter);
                setAttendances(data);
            }
        } catch (error) {
            toast(getError(error));
        }
    };

    return (
        <AttendanceContext.Provider value={{ attendances, fetchAttendance, filterAttendance, setFilterAttendance }}>
            {children}
        </AttendanceContext.Provider>
    );
}
