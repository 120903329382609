import React, { useEffect, useState } from 'react';

import { Select, Alert, Input, Divider, Checkbox } from 'antd';
import PageTitle from './../common/PageTitle';
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Container,
    Button,
    // CardFooter
} from 'shards-react';
import { useLoading, useUser } from '../../hooks';
import { BREAK, DURATION, ROLE } from '../../helpers/const';
import { attendanceSettingAPI } from '../../apis/attendanceSettingAPI';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { companiesAPI } from '../../apis/companiesAPI';

export default function AttendanceSetting() {
    const { Option } = Select;

    const { user, getMe } = useUser();
    const [data, setData] = useState();
    const [payload, setPayload] = useState();
    const history = useHistory();

    const location = useLocation();
    const { setLoading } = useLoading();
    const [dataCompany, setDataCompany] = useState();

    useEffect(() => {
        if (!user) {
            getMe();
        }
        user &&
            user.role &&
            user.role !== ROLE.COMPANY &&
            user.role !== ROLE.COMPANYSTAFF &&
            user.role !== ROLE.USER &&
            history.push('/login');
    }, [user]);

    const getData = async () => {
        if (!user) {
            getMe();
        }
        setDataCompany(user.company);
        if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
            const { data } = await attendanceSettingAPI.getByCompany(user.company._id);
            setData(data[0]);
            setPayload(data[0]);
        }

        if (
            user &&
            user.role &&
            (user.role === ROLE.COMPANYSTAFF || user.role === ROLE.USER) &&
            user.employee &&
            user.employee.company &&
            user.employee.company._id
        ) {
            const { data } = await attendanceSettingAPI.getByCompany(user.employee.company._id);
            setData(data[0]);
            setPayload(data[0]);
        }
    };

    useEffect(() => {
        getData();
    }, [user, location]);

    const handleClick = async () => {
        if (data === undefined || data.length === 0) {
            setLoading(true);
            await attendanceSettingAPI.add({ ...payload, companyID: user.company._id });
            toast.success('Add success');
            setLoading(false);
        } else {
            setLoading(true);
            await attendanceSettingAPI.update(data._id, { ...payload, companyID: user.company._id });
            toast.success('Update success');
            setLoading(false);
        }

        getData();
    };
    const handleAutoAttendance = async (value) => {
        const newRequest = await companiesAPI.update(dataCompany._id, { isAutoAttended: value.target.checked });
        setDataCompany(newRequest.data);
    };
    return (
        <Container fluid className="main-content-container px-4">
            <PageTitle
                title="Attendance settings"
                subtitle={
                    <>
                        <span className="route-extra">Company settings/ Attendance settings </span>
                    </>
                }
                className="text-sm-left mb-3"
            />
            <Card small className="h-100">
                <CardHeader className="border-bottom">
                    <span className="ct-title">Attendance settings</span>
                </CardHeader>

                <CardBody className="col-xl-12 col-lg-12 ">
                    <div className="container">
                        <h5>DURATION CALCULATION</h5>
                        <Checkbox
                            checked={
                                payload && (payload.isFullDuration === false || payload.isFullDuration === true)
                                    ? payload.isFullDuration
                                    : true
                            }
                            onChange={() => {
                                if (payload && (payload.isFullDuration === false || payload.isFullDuration === true)) {
                                    setPayload({
                                        ...payload,
                                        isFullDuration: !payload.isFullDuration,
                                        actualDuration: payload.isFullDuration,
                                    });
                                } else {
                                    setPayload({ ...payload, isFullDuration: false, actualDuration: true });
                                }
                            }}
                            disabled={user.role !== ROLE.COMPANY ? true : false}
                        >
                            Full Duration between First Check-In and Last Check-Out
                        </Checkbox>
                        <Row className="py-3">
                            <Col lg="6" md="12" sm="12">
                                <label id="lib1">When duration is (X) minutes or more</label>
                                <Select
                                    htmlFor="lb1"
                                    defaultValue="300"
                                    style={{ width: '100%', marginTop: 10 }}
                                    value={payload && payload.duration && payload.duration}
                                    onChange={(e) => setPayload({ ...payload, duration: e })}
                                    disabled={user.role !== ROLE.COMPANY ? true : false}
                                >
                                    {DURATION.map((item, index) => (
                                        <Option value={item} key={index}>
                                            {item}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col lg="6" md="12" sm="12">
                                <label id="lib2">Add (Y) minutes break</label>
                                <Select
                                    htmlFor="lb2"
                                    defaultValue="60"
                                    style={{ width: '100%', marginTop: 10 }}
                                    value={payload && payload.break && payload.break}
                                    onChange={(e) => setPayload({ ...payload, break: e })}
                                    disabled={user.role !== ROLE.COMPANY ? true : false}
                                >
                                    {BREAK.map((item, index) => (
                                        <Option value={item} key={index}>
                                            {item}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>
                        <Checkbox
                            checked={
                                payload && (payload.actualDuration === false || payload.actualDuration === true)
                                    ? payload.actualDuration
                                    : false
                            }
                            onChange={() => {
                                if (payload && (payload.actualDuration === false || payload.actualDuration === true)) {
                                    setPayload({
                                        ...payload,
                                        actualDuration: !payload.actualDuration,
                                        isFullDuration: payload.actualDuration,
                                    });
                                } else {
                                    setPayload({ ...payload, actualDuration: true, isFullDuration: false });
                                }
                            }}
                            disabled={user.role !== ROLE.COMPANY ? true : false}
                        >
                            Actual Duration for Valid Check-In and Check-Out Pairs
                        </Checkbox>
                        <Alert
                            message="Change of Duration Calculation Preference will only affect newly created Attendance records, i.e. if an Attendance record has already been created (such as by employee clock-in), subsequent clock-in or out will still use the same Duration Calculation method."
                            type="info"
                            showIcon
                            style={{
                                marginTop: 20,
                                padding: 25,
                            }}
                        />

                        <Divider
                            style={{
                                borderTop: '1px solid rgba(0, 0, 0, 0.15)',
                                margin: '30px 0',
                            }}
                        />

                        <h5>AUTO ATTENDANCE</h5>
                        <Checkbox
                            checked={dataCompany && dataCompany.isAutoAttended}
                            onChange={handleAutoAttendance}
                            disabled={user.role !== ROLE.COMPANY ? true : false}
                        >
                            Automatic attendance for employees (except holidays and weekends)
                        </Checkbox>
                        <h5 />
                        <h5>TIME CLOCK</h5>
                        <Row className="mt-3">
                            <Col lg="12" md="12" sm="12">
                                <label id="lib4">Geolocation</label>
                                <Select
                                    htmlFor="lib4"
                                    defaultValue="not-required"
                                    style={{ width: '100%', marginTop: 8 }}
                                    value={payload && payload.geolocation && payload.geolocation}
                                    onChange={(e) => setPayload({ ...payload, geolocation: e })}
                                    disabled={user.role !== ROLE.COMPANY ? true : false}
                                >
                                    <Option value="not-required">Not Required</Option>
                                    <Option value="required">Required</Option>
                                </Select>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col lg="12" md="12" sm="12">
                                <label id="lib5">Selfie</label>
                                <Select
                                    htmlFor="lib5"
                                    defaultValue="not-required"
                                    style={{ width: '100%', marginTop: 8 }}
                                    value={payload && payload.selfie && payload.selfie}
                                    onChange={(e) => setPayload({ ...payload, selfie: e })}
                                    disabled={user.role !== ROLE.COMPANY ? true : false}
                                >
                                    <Option value="not-required">Not Required</Option>
                                    <Option value="required">Required</Option>
                                </Select>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col lg="12" md="12" sm="12">
                                <label id="lib6">Invalid IP action</label>
                                <Select
                                    htmlFor="lib6"
                                    defaultValue="block-clocking"
                                    style={{ width: '100%', marginTop: 8 }}
                                    value={payload && payload.invalidIp && payload.invalidIp}
                                    onChange={(e) => setPayload({ ...payload, invalidIp: e })}
                                    disabled={user.role !== ROLE.COMPANY ? true : false}
                                >
                                    <Option value="block-clocking">Block Clocking</Option>
                                    <Option value="open-clocking">Open Clocking</Option>
                                </Select>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col lg="12" md="12" sm="12">
                                <label id="lib7">Allowed IPs</label>
                                <Input
                                    htmlFor="lib7"
                                    placeholder="Example"
                                    style={{
                                        width: '100%',
                                        margin: '8px 0 25px 0',
                                    }}
                                    value={payload && payload.allowedIPs && payload.allowedIPs}
                                    disabled={user.role !== ROLE.COMPANY ? true : false}
                                    onChange={(e) => {
                                        if (user && user.role && user.role === ROLE.COMPANY) {
                                            setPayload({ ...payload, allowedIPs: e.target.value });
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <Alert
                            // message=""
                            type="info"
                            showIcon
                            message={
                                <div className="pl-4">
                                    <p>Allowed IPs:</p>
                                    <ul>
                                        <li>
                                            <p>Empty means no IP restrictions</p>
                                        </li>
                                        <li>
                                            <p>1 IPv4 per line, 100 lines max</p>
                                        </li>
                                        <li>
                                            <p>Does not apply to Field Check-In</p>
                                        </li>
                                        <li>
                                            <p>Correct format: 1.2.3.4, not 001.002.003.004</p>
                                        </li>
                                    </ul>
                                </div>
                            }
                        />

                        {user && user.role && user.role === ROLE.COMPANY && (
                            <div className="d-flex justify-content-center align-items-center mt-3">
                                <div
                                    onClick={handleClick}
                                    className="text-center btn-save-attendanceSetting rounded py-2 px-4 d-flex justify-content-center align-items-center button__warning"
                                >
                                    <span className="text">Save</span>
                                </div>
                            </div>
                        )}
                    </div>
                </CardBody>
            </Card>
        </Container>
    );
}
