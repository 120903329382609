import { MinusCircleOutlined } from '@ant-design/icons';
import { DatePicker, Form, Input, Modal, Select, Space } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'shards-react';
import { employeesAPI } from '../../apis/employeesAPI';
import { historiesAPI } from '../../apis/historiesAPI';
import { payRollAPI } from '../../apis/payRollAPI';
import { PAYROLL_CURENTCY } from '../../global/payroll';
import { FORMAT, ROLE, TYPE_NOTIFICATION } from '../../helpers/const';
import { getError } from '../../helpers/getErrorServer';
import { useLoading, useUser } from '../../hooks';

export default function PayrollForm({ visible, onCancel, refetch, setAdd }) {
    const { Option } = Select;
    const { user, getMe } = useUser();
    const [form] = Form.useForm();
    const [employees, setEmployees] = useState([]);
    const { setLoading } = useLoading();

    const fetchEmployees = async (user) => {
        try {
            let tmpUser = user;
            if (!tmpUser || !tmpUser._id) tmpUser = await getMe();
            if (tmpUser.role === ROLE.COMPANY) {
                const { data } = await employeesAPI.getAllCompany(tmpUser.company._id);
                setEmployees(data);
            } else if (
                tmpUser.role === ROLE.COMPANYSTAFF ||
                (tmpUser && tmpUser.position && tmpUser.position.payroll && tmpUser.position.payroll.includes('3')) ||
                (tmpUser &&
                    tmpUser.employee &&
                    tmpUser.employee.controls &&
                    tmpUser.employee.controls.payroll &&
                    tmpUser.employee.controls.payroll.includes('3'))
            ) {
                const { data } = await employeesAPI.getAllCompany(tmpUser.employee.company._id);
                setEmployees(data);
            }
        } catch (error) {
            toast.error(getError(error));
        }
    };

    useEffect(() => {
        if (user) {
            fetchEmployees(user);
        }
    }, [user]);

    useEffect(() => {
        if (visible) {
            onSubmit();
        }
    }, [visible]);

    const onSubmit = async (value) => {
        try {
            setLoading(true);
            //const request = await payRollAPI.create(value);
            const request = await payRollAPI.autoCreate();
            setAdd((prev) => prev + 1);
            if (request && request.data && request.data._id && request.data.employeesId) {
                getHistories(request.data._id, request.data.employeesId);
            }
            setLoading(false);
            toast.success('Add pay-roll successfully');
            form.resetFields();
            refetch((e) => e + 1);

            onCancel();
        } catch (error) {
            setLoading(false);
            toast.error(getError(error));
        }
    };

    const handleCancel = () => {
        form.resetFields();
        refetch((e) => e + 1);
        onCancel();
    };

    const getHistories = async (id, employee) => {
        await historiesAPI.add({
            title: 'PAY ROLL',
            desc: 'Your salary just reviewed',
            employee,
            type: TYPE_NOTIFICATION.PAYROLL,
            idRequest: id,
            toEmployee: true,
        });
    };

    return (
        <Modal
            // open={visible}
            open={false}
            title="Add salary"
            onCancel={handleCancel}
            zIndex={998}
            width="700px"
            height="500px"
            footer={false}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                onFinish={onSubmit}
                initialValues={{
                    earning: [
                        {
                            title: '',
                            amount: 0,
                        },
                    ],
                    deductions: [
                        {
                            title: '',
                            amount: 0,
                        },
                    ],
                }}
            >
                <Row lg="12" md="8" sm="6">
                    <Col lg="6">
                        <Form.Item
                            name="effectiveDate"
                            label="Effective date"
                            initialValue={moment(new Date(), FORMAT.DATE)}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the name of collection!',
                                },
                            ]}
                        >
                            <DatePicker format={FORMAT.DATE} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col lg="6">
                        <Form.Item
                            name="employeesId"
                            label="Select staff"
                            initialValue={''}
                            rules={[
                                {
                                    required: true,
                                    min: 0,
                                    message: 'Please select employee!',
                                },
                            ]}
                        >
                            <Select
                                style={{ width: '100%' }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {employees.map((employee) => (
                                    <Option value={employee._id} key={employee._id}>
                                        {employee.firstName}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row lg="12" md="8" sm="6">
                    <Col lg="6">
                        <Form.Item
                            name="currency"
                            label="Currency"
                            initialValue={PAYROLL_CURENTCY[0].value}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select currency!',
                                },
                            ]}
                        >
                            <Select style={{ width: '100%' }}>
                                {PAYROLL_CURENTCY.map((item) => (
                                    <Option value={item.value} key={item.value}>
                                        {item.label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <hr />
                <Row lg="12" md="8" sm="6">
                    <Col lg="6" md="12">
                        <h6>Earnings</h6>

                        <Form.List name="earning">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field) => (
                                        <Space key={field.key} align="baseline">
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={(prevValues, curValues) =>
                                                    prevValues.area !== curValues.area ||
                                                    prevValues.sights !== curValues.sights
                                                }
                                            >
                                                {() => (
                                                    <Form.Item
                                                        {...field}
                                                        label="Title"
                                                        name={[field.name, 'title']}
                                                        rules={[{ required: true, message: 'Please enter title' }]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                label="Amount"
                                                name={[field.name, 'amount']}
                                                rules={[{ required: true, message: 'Please enter amount' }]}
                                            >
                                                <Input type={'number'} min={0} />
                                            </Form.Item>

                                            <MinusCircleOutlined onClick={() => remove(field.name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <div
                                            className="d-flex justify-content-start align-items-center"
                                            onClick={() => add()}
                                        >
                                            <span className="material-icons">add_circle_outline</span>
                                            <p className="p-0 m-0">Other</p>
                                        </div>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                    <Col lg="6">
                        <h6>Deductions</h6>
                        <Form.List name="deductions">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field) => (
                                        <Space key={field.key} align="baseline">
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={(prevValues, curValues) =>
                                                    prevValues.area !== curValues.area ||
                                                    prevValues.sights !== curValues.sights
                                                }
                                            >
                                                {() => (
                                                    <Form.Item
                                                        {...field}
                                                        label="Title"
                                                        name={[field.name, 'title']}
                                                        rules={[{ required: true, message: 'Please enter title!' }]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                label="Amount"
                                                name={[field.name, 'amount']}
                                                rules={[{ required: true, message: 'Please enter amount' }]}
                                            >
                                                <Input type={'number'} min={0} />
                                            </Form.Item>

                                            <MinusCircleOutlined onClick={() => remove(field.name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <div
                                            className="d-flex justify-content-start align-items-center"
                                            onClick={() => add()}
                                        >
                                            <span className="material-icons">add_circle_outline</span>
                                            <p className="p-0 m-0">Other</p>
                                        </div>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>

                <Row>
                    <Col md="12">
                        <div className="d-flex justify-content-end align-items-center mt-2 mb-4">
                            <Button
                                outline
                                theme="secondary"
                                id="cancel"
                                className="mx-2 px-4"
                                onClick={() => handleCancel()}
                            >
                                <span style={{ color: '#0C415B', fontSize: '14px', fontWeight: '500' }}>Cancel</span>
                            </Button>

                            <Button onClick={form.submit} theme="warning" className="mx-2 px-4" id="submit">
                                <span style={{ color: '#0C415B', fontSize: '14px', fontWeight: '500' }}>Submit</span>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
