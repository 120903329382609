import { DatePicker, Select } from 'antd';
import React, { useState } from 'react';
import { AiFillCaretDown } from 'react-icons/ai';
import { FaRegCalendar } from 'react-icons/fa';
import { Card, CardBody, CardHeader } from 'shards-react';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Chart2 from './../../utils/Chart2';

const Discussions = ({ title, discussions }) => {
    const [time, setTime] = useState();
    const [yearly, setYearly] = useState('monthly');
    const { Option } = Select;

    const onChange = (date, dateString) => {
        setTime(dateString);
    };

    return (
        <Card small className="blog-comments">
            <CardHeader className="border-bottom d-flex custom_top justify-content-between align-items-center">
                <span className="title__h5">Salary by unit</span>
                <div className="d-flex justify-content-between align-items-center">
                    {/* Time Span */}

                    <div className="d-flex">
                        <DatePicker
                            suffixIcon={<FaRegCalendar size={15} color="#000" />}
                            style={{ width: '120px', borderRadius: '5px', marginRight: '10px' }}
                            onChange={onChange}
                            picker="year"
                        />

                        <div className="select-container">
                            <Select
                                suffixIcon={<AiFillCaretDown size={15} color="#000" />}
                                style={{ width: '120px' }}
                                bordered={false}
                                defaultValue="monthly"
                                onChange={(value) => {
                                    setYearly(value);
                                }}
                            >
                                <Option value="monthly">Monthly</Option>
                                <Option value="yearly">Yearly</Option>
                            </Select>
                        </div>
                    </div>
                </div>
            </CardHeader>

            <CardBody className="p-3 container_chart">
                <div className="chart_wwrapper">
                    <Chart2 time={time} yearly={yearly} />
                </div>
            </CardBody>
        </Card>
    );
};

export default Discussions;
