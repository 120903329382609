import React, { useEffect, useState } from 'react';

import {
    // Row,
    // Col,
    // FormSelect,
    Card,
    CardHeader,
    CardBody,
    // CardFooter
} from 'shards-react';

// import { Progress } from "antd";
import { RingProgress } from '@ant-design/plots';
import { useEmployee, useUser } from '../../hooks';
import { employeesAPI } from '../../apis/employeesAPI';
import { ROLE } from '../../helpers/const';
import { useLocation } from 'react-router-dom';

// import Chart from "../../utils/chart";

function UsersByDevice() {
    const { user, getMe } = useUser();
    const { employees } = useEmployee();
    const [percent, setPercent] = useState(false);
    const [total, setTotal] = useState('');

    const location = useLocation();

    useEffect(() => {
        renderPercent();
    }, [employees, user, location]);

    const renderPercent = async () => {
        if (!user) {
            getMe();
        }

        if (user && user.role && (user.role === ROLE.ADMIN || user.role === ROLE.SYSTEMSTAFF)) {
            const { data } = await employeesAPI.percent({ role: user.role });
            setPercent(data.percent);
            setTotal(data.total);
        }

        if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
            const { data } = await employeesAPI.percent({ role: user.role, idCompany: user.company._id });
            setPercent(data.percent);
            setTotal(data.total);
        }

        if (
            user &&
            user.role &&
            user.role === ROLE.COMPANYSTAFF &&
            user.employee &&
            user.employee.company &&
            user.employee.company._id
        ) {
            const { data } = await employeesAPI.percent({ role: user.role, idCompany: user.employee.company._id });
            setPercent(data.percent);
            setTotal(data.total);
        }
    };
    const DemoRingProgress = () => {
        if (percent) {
            const config = {
                height: 200,
                width: 200,
                autoFit: false,
                color: ['#0C415B', '#FFD96C'],
                percent: percent,
            };
            return <RingProgress {...config} />;
        } else {
            return <h2>Not have employees</h2>;
        }
    };

    return (
        <Card small className="h-100 percent-dashboard">
            <CardHeader className="border-bottom d-flex justify-content-between align-items-center">
                <span className="title__h5">Total Employees</span>
                <span className="ct-number-small">{total && total}</span>
            </CardHeader>
            <CardBody className="d-flex py-2 justify-content-center align-items-center flex-column">
                <DemoRingProgress className="" />

                <div className="w-100 text-center my-5 position-relative d-flex justify-content-center align-items-center">
                    <div className="op_one position-relative m-4">
                        <span />
                        Man
                    </div>
                    <div className="op_two position-relative m-4">
                        <span />
                        Women
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

UsersByDevice.defaultProps = {
    title: 'Total Employees',
    chartData: {
        datasets: [
            {
                hoverBorderColor: '#ffffff',
                data: [68.3, 24.2, 7.5],
                backgroundColor: ['rgba(0,123,255,0.9)', 'rgba(0,123,255,0.5)', 'rgba(0,123,255,0.3)'],
            },
        ],
        labels: ['Desktop', 'Tablet', 'Mobile'],
    },
};

export default UsersByDevice;
