import { Form, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Row } from 'shards-react';
import { useLoading, useUser } from '../../hooks';
import { getError } from '../../helpers/getErrorServer';
import { FORM_TYPE, ROLE } from '../../helpers/const';
import { Option } from 'antd/lib/mentions';
import { Select, Space } from 'antd';
import { WORKING_DAYS } from '../../helpers/const';
import { levelAPI } from '../../apis/levelAPI';
import { workWeekAPI } from '../../apis/workWeekAPI';

const WorkDaysForm = ({ visible, setVisible, type, setReload, id }) => {
    const [form] = Form.useForm();
    const { user, getMe } = useUser();
    const { setLoading } = useLoading();
    const [payload, setPayload] = useState();
    const [companyID, setCompanyID] = useState();
    const [listEmployee, setListEmployee] = useState([]);
    const [level, setLevel] = useState('');
    const [date, setDate] = useState();

    useEffect(() => {
        (async () => {
            try {
                if (user && user.company && user.company._id) {
                    setCompanyID(user.company._id);
                    getListEmployeeLevel(user.company._id);
                } else if (user && user.employee && user.employee.company && user.employee.company._id) {
                    getListEmployeeLevel(user.employee.company._id);
                    setCompanyID(user.employee.company._id);
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, [user]);

    const handleOk = () => {
        if (type === FORM_TYPE.CREATE) {
            create();
        } else if (type === FORM_TYPE.EDIT) {
            update(id);
        } else if (type === FORM_TYPE.VIEW) {
            setVisible(false);
        }
    };

    const getListEmployeeLevel = async (id) => {
        try {
            const { data } = await levelAPI.getBySettingWorkDays(id);
            setListEmployee(data);
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const handleChangeDayNumber = async (value) => {
        setDate(value);
        setPayload({ ...payload, date: value });
    };

    const handleChangeEmployeeLevel = async (value) => {
        setLevel(value);
        setPayload({ ...payload, level: value });
    };

    const checkField = () => {
        if (!payload.date) {
            toast.error('Please choose date!');
            return false;
        }
        if (!payload.level) {
            toast.error('Please choose level!');
            return false;
        }
        return true;
    };

    const getById = async (id) => {
        try {
            const { data } = await workWeekAPI.getById(id);
            setPayload(data);
            form.setFieldsValue(data);
            setLevel(data.level.name);
            setDate(data.date);
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const create = async () => {
        try {
            if (Object.keys(user).length === 0) {
                getMe();
            }
            const check = checkField();
            if (!check) {
                return;
            }
            setLoading(true);
            await workWeekAPI.create(payload);
            setLoading(false);
            setReload((prev) => prev + 1);
            setVisible(false);
            form.resetFields();
            setLevel();
            setDate();
            setPayload({});
            if (user && user.company && user.company._id) {
                setCompanyID(user.company._id);
                getListEmployeeLevel(user.company._id);
            } else if (user && user.employee && user.employee.company && user.employee.company._id) {
                getListEmployeeLevel(user.employee.company._id);
                setCompanyID(user.employee.company._id);
            }
            toast.success('Success');
        } catch (error) {
            setLoading(false);
            toast.error(getError(error));
        }
    };

    const update = async (id) => {
        try {
            if (Object.keys(user).length === 0) {
                getMe();
            }
            const check = checkField();
            if (!check) {
                return;
            }
            setLoading(true);
            const data = {
                level: payload.level,
                date: payload.date,
            };
            await workWeekAPI.update(id, data);
            setLoading(false);
            setReload((prev) => prev + 1);
            setVisible(false);
            toast.success('Success');
        } catch (error) {
            console.log(error);
            setLoading(false);
            toast.error(getError(error));
        }
    };

    const handleCancel = () => {
        setVisible(false);
        if (type === FORM_TYPE.CREATE) {
            form.resetFields();
            setPayload();
        }
    };

    useEffect(() => {
        if (user && user.company && user.company._id) {
            setCompanyID(user.company._id);
        } else if (user && user.employee && user.employee.company && user.employee.company._id) {
            setCompanyID(user.employee.company._id);
        }
    }, [user]);

    useEffect(() => {
        if (id && type !== FORM_TYPE.CREATE) {
            getById(id);
            getListEmployeeLevel(companyID);
        }
    }, [id, type]);

    return (
        <Modal
            title="Setting Days Of Week"
            open={visible}
            onOk={handleOk}
            zIndex={999}
            onCancel={handleCancel}
            width="700px"
            okText={type === FORM_TYPE.CREATE ? 'Submit' : type === FORM_TYPE.EDIT ? 'Save' : 'OK'}
        >
            <Form form={form} layout="vertical" name="form_in_modal">
                <Row>
                    <Col lg="12">
                        <div className=" mb-4">
                            <Form.Item name="employeeLevel" label="Employee Level">
                                <Space style={{ width: '100%' }} direction="vertical">
                                    <Select
                                        allowClear
                                        onChange={handleChangeEmployeeLevel}
                                        optionLabelProp="label"
                                        style={{ width: '100%', zIndex: 999 }}
                                        placeholder="Please select Employee Level"
                                        maxTagCount="responsive"
                                        value={level}
                                        disabled={type === FORM_TYPE.VIEW ? true : false}
                                    >
                                        {listEmployee.map((item) => (
                                            <Option value={item._id} label={item.name}>
                                                <Space>{item.name}</Space>
                                            </Option>
                                        ))}
                                    </Select>
                                </Space>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <div className=" mb-4">
                            <Form.Item name="dayNumber" label="Day Number">
                                <Space style={{ width: '100%' }} direction="vertical">
                                    <Select
                                        allowClear
                                        onChange={handleChangeDayNumber}
                                        optionLabelProp="label"
                                        style={{ width: '100%', zIndex: 999 }}
                                        placeholder="Please select Day number"
                                        maxTagCount="responsive"
                                        value={date}
                                        disabled={type === FORM_TYPE.VIEW ? true : false}
                                    >
                                        {WORKING_DAYS.map((item) => (
                                            <Option value={item} label={item}>
                                                <Space>{item}</Space>
                                            </Option>
                                        ))}
                                    </Select>
                                </Space>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default WorkDaysForm;
