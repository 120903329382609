import axios from 'axios';

const path = 'day-leaves/';

const getTotalHours = async (filter = {}) => {
    return await axios.get(path + 'totalHours/', { params: filter });
};

const calcTotalHours = async (filter = {}) => {
    return await axios.get(path + 'calcTotalHours/', { params: filter });
};

const find = async (filter = {}) => {
    return await axios.get(path, { params: filter });
};

export const dayLeavesAPI = {
    find,
    getTotalHours,
    calcTotalHours,
};
