import { Button, Checkbox, Form, Input, Modal } from 'antd';
import { signOut } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Container, Row } from 'shards-react';
import configAxios from '../../apis';
import { defaultAPI } from '../../apis/defaultAPI';
import { userAPI } from '../../apis/userAPI';
import { getError } from '../../helpers/getErrorServer';
import { jwtManager } from '../../helpers/jwtManager';
import { UserAuth } from '../../providers/authProvider';
import { auth } from './firebase';

export default function Login() {
    const initAccount = {
        email: '',
        password: '',
    };

    const history = useHistory();
    const [account, setAccount] = useState(initAccount);
    const [showPass, setShowPass] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [email, setEmail] = useState('');
    const { userLogin, googleSignIn } = UserAuth();

    useEffect(() => {
        (async () => {
            if (userLogin) {
                try {
                    const result = await userAPI.findByEmail(userLogin.email);
                    const account = result.data;
                    if (
                        !account ||
                        (account && account.company && account.company.status && account.company.status === false) ||
                        (account &&
                            account.employee &&
                            account.employee.company &&
                            account.employee.company.status === false)
                    ) {
                        toast.error('Login fail, please check your account!');
                        return;
                    }

                    const { data } = await defaultAPI.signInGoogle(userLogin.email);
                    if (data && data.access_token) {
                        jwtManager.set(data.access_token);
                        configAxios();
                        localStorage.setItem('role', data.role);
                        const user = await defaultAPI.getUser();

                        localStorage.setItem('userId', user.data.id);
                        toast.success('Login successfully!');
                        history.push('/dashboard');
                    } else {
                        await signOut(auth);
                        toast.error('Login fail, please check your account!');
                    }
                } catch (error) {
                    await signOut(auth);
                    toast.error(error.response.data.message);
                }
            } else {
                await signOut(auth);
            }
        })();
    }, [userLogin]);

    const handleGoogleSignIn = async () => {
        try {
            await googleSignIn();
        } catch (error) {
            console.log(error);
        }
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        if (email) {
            let regx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!regx.test(email)) {
                toast.error('Please enter email');
                return;
            } else {
                await defaultAPI.resetPassword(email);
                setEmail('');
                toast.success('Link change password has been sent to your email, please check your email');
                setIsModalOpen(false);
            }
        } else {
            toast.error('Please enter email');
        }
    };

    const handleCancel = () => {
        setEmail('');
        setIsModalOpen(false);
    };
    const [remember, setRemember] = useState(localStorage.remember ? true : false);

    useEffect(() => {
        (async () => {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');

            if (token && role && localStorage.remember) {
                history.push('/');
            }
        })();
    }, []);

    const showPassWord = () => {
        setShowPass(!showPass);
    };

    const validateForm = (data) => {
        if (data.email === '') {
            toast.error('Please input your email!');
            return false;
        }
        if (data.password === '') {
            toast.error('Please input your password!');
            return false;
        }
        return true;
    };

    const onFinish = async (values) => {
        try {
            if (validateForm(values) && values) {
                if (remember === true) {
                    localStorage.setItem('remember', true);
                } else {
                    localStorage.remember && localStorage.removeItem('remember');
                }

                const { data } = await defaultAPI.login(values);
                if (data.access_token) {
                    jwtManager.set(data.access_token);
                    configAxios();
                    localStorage.setItem('role', data.role);
                    const user = await defaultAPI.getUser();
                    localStorage.setItem('userId', user.data.id);
                    toast.success('Login successfully!');
                    history.push('/');
                } else {
                    toast.error('Login fail, please check your account!');
                }
            }
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const componentClicked = (data) => {};

    const responseFacebook = async (response) => {
        if (response) {
            if (!response.accessToken) {
                toast.error('Login facebook fail!');
                return;
            }
            const { data } = await userAPI.getByFb(response.userID);

            const user = data;

            if (user) {
                if (user && user.employee && user.employee.company && user.employee.company.status === false) {
                    toast.error('Login fail, please check your account!');
                    return;
                }

                if (user && user.company && user.company.status === false) {
                    toast.error('Login fail, please check your account!');
                    return;
                }

                const { data } = await defaultAPI.loginFB(user.email);

                if (data && data.access_token) {
                    jwtManager.set(data.access_token);
                    configAxios();
                    localStorage.setItem('role', data.role);
                    const user = await defaultAPI.getUser();
                    localStorage.setItem('userId', user.data.id);
                    toast.success('Login successfully!');
                    history.push('/');
                } else {
                    toast.error('Login fail, please check your account!');
                    return;
                }
            } else {
                toast.error(
                    'You cannot login with facebook …. Please contact your HR department for more information.',
                );
                return;
            }
        }
    };

    return (
        <div
            className="position-relative"
            style={{
                backgroundColor: '#fff',
            }}
        >
            <Container fluid>
                <Row>
                    <Col className=" content-login" xl="4" lg="4" md="12" sm="12">
                        <div className="wrapper">
                            <div className="avt_prof">
                                <div className="avt"></div>
                                <p>Log in</p>
                            </div>
                            <div className="login_link">
                                <div onClick={() => handleGoogleSignIn()}>
                                    <img src="./img/gg.png" alt="icon" />
                                    <span>Google</span>
                                </div>

                                <div>
                                    <FacebookLogin
                                        appId={process.env.REACT_APP_FACEBOOK_KEY}
                                        fields="name,email,picture"
                                        callback={responseFacebook}
                                        onClick={componentClicked}
                                        render={(renderProps) => (
                                            <div className="py-0" onClick={renderProps.onClick}>
                                                <img src="./img/fb.png" alt="icon" />
                                                <span>Facebook</span>
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="or">
                                <span>Or</span>
                            </div>
                            <Form
                                layout="vertical"
                                name="basic"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label={<span style={{ fontWeight: '500' }}>Email Address</span>}
                                    name="email"
                                >
                                    <Input
                                        value={account.email}
                                        onChange={(e) =>
                                            setAccount({
                                                ...account,
                                                email: e.target.value,
                                            })
                                        }
                                    />
                                </Form.Item>
                                <div className="password">
                                    <Form.Item
                                        label={<span style={{ fontWeight: '500' }}>Password</span>}
                                        name="password"
                                    >
                                        <Input
                                            value={account.password}
                                            type={showPass === false ? 'password' : 'text'}
                                            onChange={(e) =>
                                                setAccount({
                                                    ...account,
                                                    password: e.target.value,
                                                })
                                            }
                                            style={{ paddingRight: '30px' }}
                                        />
                                    </Form.Item>
                                    {showPass === false ? (
                                        <AiFillEyeInvisible
                                            size={20}
                                            className="eye-password"
                                            onClick={() => showPassWord()}
                                        />
                                    ) : (
                                        <AiFillEye size={20} className="eye-password" onClick={() => showPassWord()} />
                                    )}
                                </div>
                                <div className="footer_form">
                                    <Form.Item name="remember">
                                        <Checkbox onChange={(e) => setRemember(e.target.checked)} checked={remember}>
                                            Remember me
                                        </Checkbox>
                                    </Form.Item>
                                    <div>
                                        <span onClick={() => showModal()} style={{ cursor: 'pointer' }}>
                                            Reset password?
                                        </span>
                                    </div>
                                </div>
                                <Button
                                    type="warning"
                                    htmlType="submit"
                                    style={{
                                        width: '100%',
                                        height: '32px',
                                        backgroundColor: '#FFD96C',
                                        fontWeight: '500',
                                        borderRadius: '4px',
                                        color: '#0C415B',
                                    }}
                                >
                                    Log In
                                </Button>
                                {/* <div name="create_account">
                                    <span>
                                        Not a member? <a href="/register">Create Account</a>
                                    </span>
                                </div> */}
                            </Form>
                        </div>
                    </Col>
                    <Col className="p-0" xl="8" lg="8" md="12" sm="12">
                        <img
                            style={{
                                width: '100%',
                                objectFit: 'cover',
                            }}
                            src="./img/bg.jpg"
                            alt="bg_login"
                            className="bg_login"
                        />
                    </Col>
                </Row>
                <Modal title="Reset Password" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <lebel className="title">Email</lebel>
                    <Input
                        value={email && email}
                        placeholder="Enter my email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Modal>
            </Container>
        </div>
    );
}
