import React from 'react';
import TableDepartments from './TableDepartments';
import { Row, Card, CardHeader, CardBody } from 'shards-react';

const ListDepartments = ({ isChange, setIsChange, add }) => {
    return (
        <Card small className="h-100">
            <CardHeader className="border-bottom">
                <span className="ct-title">Departments List</span>
            </CardHeader>

            <CardBody className="col-xl-12 col-lg-12">
                <Row lg="12" md="12" sm="12">
                    <TableDepartments isChange={isChange} setIsChange={setIsChange} add={add} />
                </Row>
            </CardBody>
        </Card>
    );
};

export default ListDepartments;
