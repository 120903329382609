import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'shards-react';
import FilterAttendance from '../components/attendance/FilterAttendance';
import TabAttendance from '../components/attendance/TabAttendance';
import { ROLE } from '../helpers/const';
import { useUser } from '../hooks';
import AttendanceProvider from '../providers/attendanceProvider';
import PageTitle from './../components/common/PageTitle';

function Attendance() {
    const history = useHistory();
    const { user, getMe } = useUser();
    useEffect(() => {
        if (!user) {
            getMe();
        }
        user &&
            user.role &&
            user.role !== ROLE.COMPANY &&
            user.role !== ROLE.COMPANYSTAFF &&
            user.role !== ROLE.USER &&
            history.push('/login');
    }, [user]);

    // window.onbeforeunload = function() {
    //     if (!localStorage.remember) {
    //         jwtManager.clear();
    //     } else {
    //         return;
    //     }
    // };

    return (
        <AttendanceProvider>
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4">
                    <PageTitle
                        title="Attendance"
                        subtitle={<span className="route-main">Attendance</span>}
                        className="text-sm-left mb-3"
                    />
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12" className="mb-4">
                        <FilterAttendance />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col lg="12" md="12" sm="12" className="mb-4">
                        <TabAttendance />
                    </Col>
                </Row>
            </Container>
        </AttendanceProvider>
    );
}

export default Attendance;
