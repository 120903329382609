import axios from 'axios';

const path = 'certificate/';

const create = async (payload) => {
    return await axios.post(path, payload);
};

const getInfoEmployee = async (id, filter = {}) => {
    return await axios.get(path + 'employee/' + id, { params: filter });
};

const getCompany = async (filter = {}) => {
    return await axios.get(path + 'company', { params: filter });
};

const getEmployee = async (filter = {}) => {
    return await axios.get(path + 'employee', { params: filter });
};

const getByID = async (id) => {
    return await axios.get(path + id);
};

const updateIdEmployee = async (id, idEmployee) => {
    return await axios.patch(path + 'employee/' + id + '/' + idEmployee);
};

const update = async (id, payload) => {
    return await axios.patch(path + id, payload);
};

const softDelete = async (id) => {
    return await axios.delete(path + id);
};

export const certificateAPI = {
    create,
    updateIdEmployee,
    getCompany,
    getInfoEmployee,
    getEmployee,
    getByID,
    update,
    softDelete,
};
