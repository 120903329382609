import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { expenseClaimAPI } from '../apis/expenseClaimAPI';
import { ExpenseClaimContext } from '../contexts/expenseClaimContext';
import { EXPENSE_CLAIM_FILTER, EXPENSE_CLAIM_STATUS } from '../global/expenseClaim';
import { ROLE } from '../helpers/const';
import { getError } from '../helpers/getErrorServer';
import { RESPONSE } from '../helpers/response';
import { useLoading, useUser } from '../hooks';

export default function ExpenseClaimProvider({ children }) {
    const { user, getMe } = useUser();
    const { setLoading } = useLoading();
    const [expenseClaims, setExpenseClaims] = useState(RESPONSE);
    const [expenseClaimsPending, setExpenseClaimsPending] = useState(RESPONSE);
    const [filterExpenseClaim, setFilterExpenseClaim] = useState(EXPENSE_CLAIM_FILTER);

    const getFilter = async (filter, user) => {
        if (!user._id) {
            user = await getMe();
        }

        if (user.role === ROLE.COMPANY) {
            return {
                ...filter,
                company: user && user.company && user.company._id ? user.company._id : '',
            };
        } else if (
            user.role === ROLE.COMPANYSTAFF ||
            (user && user.position && user.position.expenseClaim && user.position.expenseClaim.includes('1')) ||
            (user && user.employee && user.employee.isApproverExpense) ||
            (user &&
                user.employee &&
                user.employee.controls &&
                user.employee.controls.expenseClaim &&
                user.employee.controls.expenseClaim.includes('1'))
        ) {
            return {
                ...filter,
                company:
                    user && user.employee && user.employee.company && user.employee.company._id
                        ? user.employee.company._id
                        : '',
            };
        } else {
            return {
                ...filter,
                employee: user && user.employee && user.employee._id ? user.employee._id : '',
            };
        }

        // if (
        //     user.role === ROLE.USER &&
        //     user &&
        //     user.position &&
        //     user.position.expenseClaim &&
        //     !user.position.expenseClaim.includes('1')
        // ) {

        // }

        // return filter;
    };

    const fetchExpenseClaims = async (filter = EXPENSE_CLAIM_FILTER) => {
        try {
            filter = {
                ...EXPENSE_CLAIM_FILTER,
                ...filter,
            };

            filter = await getFilter(filter, user);
            setLoading(true);
            const { data } = await expenseClaimAPI.get(filter);

            if (filter.status === EXPENSE_CLAIM_STATUS.PENDING) {
                setExpenseClaimsPending(data);
            }

            setExpenseClaims(data);
            setFilterExpenseClaim(filter);
            setLoading(false);
        } catch (error) {
            toast(getError(error));
            setLoading(false);
        }
    };

    const getExpenseClaimByID = async (id) => {
        return new Promise((resolve, reject) => {
            expenseClaimAPI
                .getById(id)
                .then((rs) => {
                    resolve(rs.data);
                })
                .catch((err) => reject(err));
        });
    };

    return (
        <ExpenseClaimContext.Provider
            value={{
                expenseClaims,
                expenseClaimsPending,
                filterExpenseClaim,
                getExpenseClaimByID,
                setFilterExpenseClaim,
                fetchExpenseClaims,
            }}
        >
            {children}
        </ExpenseClaimContext.Provider>
    );
}
