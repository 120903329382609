import { Space, Table, Checkbox, Modal, Avatar, Image } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import { FORMAT, FORM_TYPE, ROLE, TYPE_NOTIFICATION } from '../../helpers/const';
import moment from 'moment';
import { useLoading, useUser } from '../../hooks';

import IMG_AVT from '../../images/avatars/0.jpg';
import ExClaimCreateForm from '../expense-claim/ExClaimCreateForm';
import { FILE_UPLOAD_FOR, FILE_UPLOAD_TYPE } from '../../global/fileManagement';
import LeaveCreateForm from '../leave/leaveCreateForm';
import { fileManagementAPI } from '../../apis/fileManagementAPI';
import { uploadImageAPI } from '../../apis/uploadImageAPI';
import CertificateFormView from '../certificate/CertificateFormView';

const TableFileManagement = ({ tableData, setReload, deleteList, setDeleteList, setPage, page }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [data, setData] = useState();
    const [total, setTotal] = useState(0);
    const [size, setSize] = useState(0);
    const [selected, setSelected] = useState();
    const [visibleEX, setVisibleEX] = useState();
    const [idEX, setIdEX] = useState();
    const [typeEX, setTypeEx] = useState();
    const [visibleLeave, setVisibleLeave] = useState();
    const [idLeave, setIdLeave] = useState();
    const [typeLeave, setTypeLeave] = useState();

    const [idCertificate, setIdCertificate] = useState();
    const [visibleCertificate, setVisibleCertificate] = useState();
    const { setLoading } = useLoading();

    const showModal = (row) => {
        setIsModalVisible(true);
        setSelected(row);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        if (tableData && tableData.paginate && tableData.paginate.count && tableData.paginate.size) {
            setTotal(tableData.paginate.count);
            setSize(tableData.paginate.size);
        }
    }, [tableData]);

    const handleDelete = async () => {
        try {
            if (selected) {
                setLoading(true);
                await fileManagementAPI.softDelete(selected._id);
                setLoading(false);
                toast.success('Deleted successfully!');
                setIsModalVisible(false);
                setReload((e) => e + 1);
                setSelected();
                setDeleteList([]);
            }
        } catch (error) {
            setLoading(false);
            toast.error('Delete failed!');
        }
    };

    const show = (row) => {
        if (row && row.uploadFor && row.uploadFor === FILE_UPLOAD_FOR.EXPENSE_CLAIM) {
            setVisibleEX(true);
            setIdEX(row.request);
            setTypeEx(FORM_TYPE.VIEW);
        } else if (row && row.uploadFor && row.uploadFor === FILE_UPLOAD_FOR.LEAVE) {
            setVisibleLeave(true);
            setIdLeave(row.request);
            setTypeLeave(FORM_TYPE.VIEW);
        } else if (row && row.uploadFor && row.uploadFor === FILE_UPLOAD_FOR.CERTIFICATE) {
            setVisibleCertificate(true);
            setIdCertificate(row.request);
        }
    };

    const onCloseEX = () => {
        setVisibleEX(false);
        setIdEX();
    };

    const onCloseLeave = () => {
        setVisibleLeave(false);
        setIdLeave();
    };

    useEffect(() => {
        if (tableData && tableData.items) {
            let tmp = [];
            tableData.items.forEach((item, i) => {
                tmp.push({
                    ...item,
                    key: i + 1,
                });
            });
            setData(tmp);
        }
        if (tableData && !tableData.items) {
            setData(tableData);
        }
    }, [tableData]);

    const onChange = async (e, id) => {
        try {
            const checked = e.target.checked;
            if (checked === true) {
                setDeleteList([...deleteList, id]);
            } else if (checked === false) {
                const index = deleteList.filter((item) => item !== id);
                setDeleteList(index);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onChangePage = (page) => {
        setPage(page);
    };

    const handleOpenText = (link) => {
        window.open(link);
    };

    const columns = [
        {
            title: '',
            dataIndex: '_id',
            key: 'id',
            width: '5%',
            render: (id, row) => {
                return <Checkbox checked={deleteList.includes(id)} onChange={(e) => onChange(e, id)} />;
            },
        },
        {
            title: 'ID',
            dataIndex: 'key',
            key: 'key',
            width: '10%',
            render: (key) => {
                return (
                    // <div className="content_td_mobile">
                    //     <p className="title_mobile">ID</p>
                    //     <p>{key}</p>
                    // </div>
                    <div className="content_td_mobile">{key}</div>
                );
            },
        },
        {
            title: 'Thumbnail',
            dataIndex: 'thumbnail',
            key: 'thumbnail',
            width: '15%',
            render: (_, row) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Thumbnail</p>

                        {row.type === FILE_UPLOAD_TYPE.IMAGE ? (
                            <div className="mt-3">
                                <Avatar shape="square" name="avatar" size={64} src={<Image src={row.thumbnail} />} />
                            </div>
                        ) : (
                            // <p className="link" onClick={() => handleOpenText(row.thumbnail)}>
                            //     {row.name}
                            // </p>

                            <a href={row.thumbnail} download className="text-dark">
                                {row.name}
                            </a>
                        )}

                        {/* <div className="mt-3">
                            {row.type === FILE_UPLOAD_TYPE.IMAGE ? (
                                <Avatar shape="square" name="avatar" size={64} src={<Image src={row.thumbnail} />} />
                            ) : (
                                // <p className="link" onClick={() => handleOpenText(row.thumbnail)}>
                                //     {row.name}
                                // </p>

                                <a href={row.thumbnail} download className="text-dark">
                                    {row.name}
                                </a>
                            )}
                        </div> */}
                    </div>
                );
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '15%',
            render: (name) => {
                return (
                    <div className="content_td_mobile_db">
                        <p className="title_mobile">Name</p>
                        <div className="box-name">
                            <p>{name}</p>
                        </div>
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.name > record2.name;
            },
        },

        {
            title: 'Upload By',
            dataIndex: 'uploadBy',
            key: 'uploadBy',
            width: '15%',
            render: (uploadBy) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Upload By</p>
                        <p>
                            {uploadBy.employee && uploadBy.employee.firstName
                                ? uploadBy.employee.firstName
                                : 'Client super admin'}
                        </p>
                    </div>
                );
            },
        },

        {
            title: 'Upload At',
            dataIndex: 'uploadDate',
            key: 'uploadDate',
            width: '15%',
            render: (uploadDate) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Upload At</p>
                        <p>{moment(uploadDate).format(FORMAT.DATE)}</p>
                    </div>
                );
            },

            sorter: (record1, record2) => {
                return record1.uploadDate > record2.uploadDate;
            },
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
            width: '10%',
            render: (size) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Size</p>
                        <p>{(size / 1024).toFixed(2)} KB</p>
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.size > record2.size;
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            dataIndex: '_id',
            align: 'center',
            fixed: 'right',
            width: '15%',
            render: (id, row) => (
                <Space size="middle">
                    <span onClick={() => show(row)}>
                        <span className="material-icons icon_eye">visibility</span>
                    </span>
                    <span className="material-icons icon_delete " onClick={() => showModal(row)}>
                        delete_forever
                    </span>
                </Space>
            ),
        },
    ];

    return (
        <div className="overflow-hidden">
            <Table
                className="ct-table-data"
                pagination={{
                    current: page,
                    onChange: onChangePage,
                    pageSize: size || 1,
                    total: total || 1,
                }}
                columns={columns}
                dataSource={data}
            />
            <ExClaimCreateForm
                visible={visibleEX}
                id={idEX}
                onCancel={() => {
                    onCloseEX();
                }}
                type={typeEX}
            />
            <LeaveCreateForm
                visible={visibleLeave}
                id={idLeave}
                onCancel={() => {
                    onCloseLeave();
                }}
                type={typeLeave}
            />
            <CertificateFormView visible={visibleCertificate} setVisible={setVisibleCertificate} id={idCertificate} />
            <Modal
                title={
                    <>
                        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.4167 0.833344H2.58333C1.56583 0.833344 0.75 1.65834 0.75 2.66668V15.5C0.75 16.5083 1.56583 17.3333 2.58333 17.3333H6.25L9 20.0833L11.75 17.3333H15.4167C16.425 17.3333 17.25 16.5083 17.25 15.5V2.66668C17.25 1.65834 16.425 0.833344 15.4167 0.833344ZM9.91667 15.5H8.08333V13.6667H9.91667V15.5ZM11.8142 8.39584L10.9892 9.23918C10.3292 9.90834 9.91667 10.4583 9.91667 11.8333H8.08333V11.375C8.08333 10.3667 8.49583 9.45001 9.15583 8.78084L10.2925 7.62584C10.6317 7.29584 10.8333 6.83751 10.8333 6.33334C10.8333 5.32501 10.0083 4.50001 9 4.50001C7.99167 4.50001 7.16667 5.32501 7.16667 6.33334H5.33333C5.33333 4.30751 6.97417 2.66668 9 2.66668C11.0258 2.66668 12.6667 4.30751 12.6667 6.33334C12.6667 7.14001 12.3367 7.87334 11.8142 8.39584Z"
                                fill="#FCBB0A"
                            />
                        </svg>

                        <p className="title-modal-custom">Are you sure delete this file?</p>
                    </>
                }
                open={isModalVisible}
                onOk={handleDelete}
                onCancel={handleCancel}
                zIndex={999}
            >
                <p>Delete {selected && selected.name}</p>
            </Modal>
        </div>
    );
};
export default TableFileManagement;
