import { Box } from '@mui/material';
import { Button, DatePicker, Form, Table, Tag, TimePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'shards-react';
import { getHour } from '../../helpers/getHours';
import { useAttendance, useUser } from '../../hooks';

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item style={{ margin: 0, padding: 0 }} name={dataIndex}>
                    <TimePicker style={{ width: '100%' }} format={'HH:mm'} />
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const TableMyAttendance = () => {
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const [tableData, setTableData] = useState();
    const [total, setTotal] = useState(0);
    const [size, setSize] = useState(0);
    const [search, setSearch] = useState();
    const [page, setPage] = useState(1);
    const { attendances, fetchAttendance, setFilterAttendance, filterAttendance } = useAttendance();

    const { RangePicker } = DatePicker;

    const { user } = useUser();
    useEffect(() => {
        fetchAttendance();
    }, [user]);

    useEffect(() => {
        if (attendances) {
            setTableData(attendances);
            if (attendances && attendances.paginate && attendances.paginate.count && attendances.paginate.size) {
                setTotal(attendances.paginate.count);
                setSize(attendances.paginate.size);
                setPage(attendances.paginate.page);
            }
        }
    }, [attendances]);

    // const isEditing = (record) => record._id === editingKey;
    // const edit = (record) => {
    //     if (record) {
    //         const checkIn = record.checkInTime && new Date(moment(record.checkInTime).format('YYYY-MM-DD HH:mm'));
    //         const checkOut = record.checkOutTime && new Date(moment(record.checkOutTime).format('YYYY-MM-DD HH:mm'));
    //         const overtime = record.overtime && new Date(moment(record.overtime).format('YYYY-MM-DD HH:mm'));

    //         form.setFieldsValue({
    //             checkInTime: checkIn && moment(checkIn, 'HH:mm'),
    //             checkOutTime: checkOut && moment(checkOut, 'HH:mm'),
    //             overtime: overtime ? moment(overtime, 'HH:mm') : null,
    //         });
    //         setEditingKey(record._id);
    //     }
    // };

    // const cancel = () => {
    //     setEditingKey('');
    // };

    // const save = async (record) => {
    //     try {
    //         const row = await form.validateFields();
    //         if (!row.checkInTime && !row.checkOutTime && !row.overtime) {
    //             setEditingKey('');
    //             return;
    //         }

    //         const values = {
    //             checkInTime: row.checkInTime
    //                 ? new Date(moment(record.date).format('YYYY-MM-DD') + ' ' + moment(row.checkInTime).format('HH:mm'))
    //                 : moment(record.checkInTime).format('YYYY-MM-DD HH:mm'),
    //             checkOutTime: new Date(row.checkOutTime)
    //                 ? new Date(
    //                       moment(record.date).format('YYYY-MM-DD') + ' ' + moment(row.checkOutTime).format('HH:mm'),
    //                   )
    //                 : moment(record.checkOutTime).format('YYYY-MM-DD HH:mm'),
    //             overtime: new Date(row.overtime)
    //                 ? new Date(moment(record.date).format('YYYY-MM-DD') + ' ' + moment(row.overtime).format('HH:mm'))
    //                 : new Date(record.overtime),
    //         };

    //         if (values.checkInTime.getTime() > values.checkOutTime.getTime()) {
    //             toast.error('Time out must be greater than time in');
    //             return;
    //         }

    //         const newData = [...tableData.items];
    //         const index = newData.findIndex((item) => record._id === item._id);
    //         if (index > -1) {
    //             const item = newData[index];
    //             newData.splice(index, 1, { ...item, ...values });
    //             await attendanceAPI.update(record._id, newData[index]);
    //             fetchAttendance({ page: 1 });
    //             setEditingKey('');
    //             toast.success('Edit successfully!');
    //         } else {
    //             setEditingKey('');
    //         }
    //     } catch (err) {
    //         console.log('Validate Failed:', err);
    //     }
    // };

    const columns = [
        {
            id: 1,
            title: 'Date',
            dataIndex: 'date',
            editable: false,
            filter: false,
            sorter: true,
            render: (_, record) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Date</p>
                        <span>{moment(record.date).format('DD MMM, YYYY')}</span>
                    </div>
                );
            },
        },
        {
            id: 2,
            title: 'Check In',
            dataIndex: 'checkInTime',
            editable: true,
            render: (_, record) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Check In</p>
                        <span>
                            {record && record.checkInTime
                                ? moment(record.checkInTime).format('HH:mm')
                                : record && record.checkInTime2
                                ? moment(record.checkInTime2).format('HH:mm')
                                : '--'}
                        </span>
                    </div>
                );
            },
        },
        {
            id: 3,
            title: 'Check Out',
            dataIndex: 'checkOutTime',
            editable: true,
            render: (_, record) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Check Out</p>
                        <span>
                            {record && record.checkOutTime2
                                ? moment(record.checkOutTime2).format('HH:mm')
                                : record && record.checkOutTime
                                ? moment(record.checkOutTime).format('HH:mm')
                                : '--'}
                        </span>
                    </div>
                );
            },
        },
        {
            id: 4,
            title: 'Overtime',
            dataIndex: 'overtime',
            editable: true,
            render: (_, record) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Over time</p>
                        <span>{record && record.overtime ? moment(record.overtime).format('HH:mm') : '--'}</span>
                    </div>
                );
            },
        },
        {
            id: 5,
            title: 'Working Hours',
            dataIndex: 'workinghours',
            editable: false,
            render: (_, record) => {
                if (record && record.workingHours) {
                    let { hour, minute } = getHour(record.workingHours);

                    return (
                        <div className="content_td_mobile">
                            <p className="title_mobile">Working Hours</p>
                            <span>
                                {hour === '0' ? '00' : +hour < 10 ? '0' + hour : hour}:
                                {minute === '0' ? '00' : +minute < 10 ? '0' + minute : minute}
                            </span>
                        </div>
                    );
                } else {
                    return (
                        <div className="content_td_mobile">
                            <p className="title_mobile">Working Hours</p>
                            <span>--</span>
                        </div>
                    );
                }
            },
        },
        {
            id: 6,
            title: 'Overtime Hours',
            dataIndex: 'overtimehours',
            editable: false,
            render: (_, record) => {
                if (record && record.overtimeHours) {
                    let { hour, minute } = getHour(record.overtimeHours);
                    return (
                        <div className="content_td_mobile">
                            <p className="title_mobile">Overtime Hours</p>
                            <span>
                                {hour === '0' ? '00' : +hour < 10 ? '0' + hour : hour}:
                                {minute === '0' ? '00' : +minute < 10 ? '0' + minute : minute}
                            </span>
                        </div>
                    );
                } else {
                    return (
                        <div className="content_td_mobile">
                            <p className="title_mobile">Overtime Hours</p>
                            <span>--</span>
                        </div>
                    );
                }
            },
        },
        {
            id: 7,
            title: 'Status',
            dataIndex: 'status',
            editable: false,
            render: (status) => {
                let color = '';
                if (status === 'Present') {
                    color = 'green';
                } else if (status === 'Absent') {
                    color = 'red';
                } else {
                    color = 'blue';
                }
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Status</p>
                        <Tag color={color}>{status}</Tag>
                    </div>
                );
            },
        },
        // {
        //     id: 7,
        //     title: 'Actions',
        //     dataIndex: 'action',
        //     fixed: 'right',
        //     render: (_, record) => {
        //         const editable = isEditing(record);
        //         return editable ? (
        //             <div className="d-flex justify-content-start align-items-center">
        //                 <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
        //                     <p className="p-0 mr-3 mb-0 text-warning cursor-pointer">Cancel</p>
        //                 </Popconfirm>
        //                 <Typography.Link onClick={() => save(record)} style={{ marginRight: 8 }}>
        //                     Save
        //                 </Typography.Link>
        //             </div>
        //         ) : (
        //             <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
        //                 Edit
        //             </Typography.Link>
        //         );
        //     },
        // },
    ];

    const submitSearch = () => {
        let from = search ? moment(search[0]).format('YYYY-MM-DD') : undefined;
        let to = search ? moment(search[1]).format('YYYY-MM-DD') : undefined;
        const filter = { ...filterAttendance, from, to };
        setFilterAttendance(filter);
        fetchAttendance(filter);
    };

    const onChangePage = (page) => {
        const filter = { ...filterAttendance, page };
        setFilterAttendance(filter);
        fetchAttendance(filter);
    };

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'date' ? 'text' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                //editing: isEditing(record),
            }),
        };
    });

    return (
        <div>
            <Box sx={{ marginTop: 2, marginBottom: 2, padding: '0 20px' }}>
                <Row>
                    <Col lg="12">
                        <Row>
                            <Col md="4">
                                <RangePicker format="YYYY-MM-DD" onChange={(e) => setSearch(e)} />
                            </Col>
                            <Col md="8">
                                <Button className="btn-yeallow-block" onClick={submitSearch}>
                                    Search
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Box>
            <Form form={form} component={false}>
                <Table
                    className="ct-table-data"
                    components={{ body: { cell: EditableCell } }}
                    dataSource={tableData && tableData.items}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    pagination={{
                        current: page,
                        onChange: onChangePage,
                        pageSize: size,
                        total,
                    }}
                />
            </Form>
        </div>
    );
};

export default TableMyAttendance;
