import { Form, Input, Modal, Space, Table, Checkbox } from 'antd';

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Row } from 'shards-react';
import { noticeAPI } from '../../apis/noticeAPI';
import { noticeTemplateAPI } from '../../apis/noticeTemplateAPI';

import { FORM_TYPE, ROLE } from '../../helpers/const';
import { getError } from '../../helpers/getErrorServer';
import { useLoading } from '../../hooks';

const innitFilter = {
    page: 1,
};

function ListNoticeTemplate({ user, refetch, added, deleteList, setDeleteList, search }) {
    const [listNotice, setListNotice] = useState();
    const [dataTable, setDataTable] = useState([]);
    const [page, setPage] = useState();
    const [filter, setFilter] = useState(innitFilter);
    const [idTmp, setIdTmp] = useState();
    const [open, setOpen] = useState(false);
    const [openDetail, setOpenDetail] = useState(false);
    const [reload, setReload] = useState(0);
    const [payload, setPayload] = useState({});
    const { setLoading } = useLoading();
    const location = useLocation();

    const warning = (id) => {
        setIdTmp(id);
        showModal();
    };

    const hideModal = () => {
        if (idTmp) {
            deleteT(idTmp);
        }
        setOpen(false);
    };

    const showModal = () => {
        setOpen(true);
    };

    const deleteT = async (id) => {
        try {
            setLoading(true);
            await noticeAPI.softDelete(id, user._id);

            const { data } = await noticeAPI.get(user._id, filter);
            if (data && data.items && data.items < 1) {
                setPage(page - 1);
            }
            setReload((prev) => prev + 1);

            toast.success('Deleted successfully!');
            setIdTmp();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error(getError(error));
        }
    };

    const handleOpen = async (id) => {
        if (id) {
            const { data } = await noticeAPI.getByID(id);

            setPayload(data);
        }

        setOpenDetail(true);
    };

    const handleCancel = async () => {
        setOpenDetail(false);
        setPayload({});
    };

    const handleOkDetail = async () => {
        setOpenDetail(false);
        setPayload({});
    };

    useEffect(() => {
        if (page) {
            setFilter({ ...filter, page });
        }

        if (search) {
            setFilter({ ...filter, search });
        }
    }, [page, added, search]);

    useEffect(() => {
        getNoti();
    }, [filter, reload, user, location, refetch, added]);

    const getNoti = async () => {
        if (user && user._id) {
            const { data } = await noticeAPI.get(user._id, filter);
            setListNotice(data);
        }
    };

    useEffect(() => {
        if (listNotice) {
            let tmp = [];
            listNotice.items.forEach((item, i) => {
                tmp.push({
                    ...item,
                    key: i,
                    page: listNotice.paginate.page,
                });
            });
            setDataTable(tmp);
        }
    }, [listNotice]);

    const onChange = async (e, id) => {
        try {
            const checked = e.target.checked;
            if (checked === true) {
                setDeleteList((prev) => [...prev, id]);
            } else if (checked === false) {
                const index = deleteList.filter((item) => item !== id);
                setDeleteList(index);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '5%',
            render: (id, row) => {
                return <Checkbox onChange={(e) => onChange(e, id)} />;
            },
        },
        {
            title: 'ID',
            dataIndex: 'id',
            width: '10%',

            render: (_, row) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">ID</p>
                        <span>{row.key + 1}</span>
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.key > record2.key;
            },
        },
        {
            title: 'Creator',
            dataIndex: '',
            width: '15%',
            render: (_, row) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Creator</p>
                        <span>{row && row.creator && row.creator.role ? row.creator.role : '--'}</span>
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.creator.role > record2.creator.role;
            },
        },
        {
            title: 'Title',
            dataIndex: '',
            width: '15%',
            ellipsis: true,
            render: (_, row) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Title</p>
                        <span>{row && row.title}</span>
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.title > record2.title;
            },
        },
        {
            title: 'Content',
            dataIndex: '',
            width: '40%',
            ellipsis: true,
            render: (_, row) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Content</p>
                        <span>{row && row.messages}</span>
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.messages > record2.messages;
            },
        },

        {
            title: 'Actions',
            key: '_id',
            dataIndex: '_id',
            width: '15%',
            align: 'center',
            fixed: 'right',
            render: (id, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Actions</p>
                    <Space size="middle">
                        <Link to={`#`} onClick={() => handleOpen(id, FORM_TYPE.VIEW)}>
                            <span className="material-icons icon_eye">visibility</span>
                        </Link>
                        <Link to={`#`} onClick={() => warning(id)}>
                            <span className="material-icons icon_delete">delete_forever</span>
                        </Link>
                    </Space>
                </div>
            ),
        },
    ];

    const onChangePage = (page) => {
        setPage(page);
    };

    return (
        <div className="overflow-hidden">
            <Table
                className="ct-table-data"
                columns={columns}
                dataSource={dataTable}
                pagination={{
                    current: page,
                    onChange: onChangePage,
                    pageSize:
                        listNotice && listNotice.paginate && listNotice.paginate.size ? listNotice.paginate.size : 10,
                    total: listNotice && listNotice.paginate ? listNotice.paginate.count : 0,
                }}
            />
            <Modal
                title={
                    <>
                        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.4167 0.833344H2.58333C1.56583 0.833344 0.75 1.65834 0.75 2.66668V15.5C0.75 16.5083 1.56583 17.3333 2.58333 17.3333H6.25L9 20.0833L11.75 17.3333H15.4167C16.425 17.3333 17.25 16.5083 17.25 15.5V2.66668C17.25 1.65834 16.425 0.833344 15.4167 0.833344ZM9.91667 15.5H8.08333V13.6667H9.91667V15.5ZM11.8142 8.39584L10.9892 9.23918C10.3292 9.90834 9.91667 10.4583 9.91667 11.8333H8.08333V11.375C8.08333 10.3667 8.49583 9.45001 9.15583 8.78084L10.2925 7.62584C10.6317 7.29584 10.8333 6.83751 10.8333 6.33334C10.8333 5.32501 10.0083 4.50001 9 4.50001C7.99167 4.50001 7.16667 5.32501 7.16667 6.33334H5.33333C5.33333 4.30751 6.97417 2.66668 9 2.66668C11.0258 2.66668 12.6667 4.30751 12.6667 6.33334C12.6667 7.14001 12.3367 7.87334 11.8142 8.39584Z"
                                fill="#FCBB0A"
                            />
                        </svg>

                        <p className="title-modal-custom">Warning</p>
                    </>
                }
                open={open}
                onOk={hideModal}
                onCancel={() => setOpen(false)}
                okText="Delete"
                cancelText="Cancel"
            >
                <p>Are you sure you want to delete?</p>
            </Modal>

            <Modal title="Messenger" open={openDetail} onOk={handleOkDetail} onCancel={handleCancel}>
                <div className="form-add-holiday">
                    <div className="mb-4">
                        <label>Title</label>
                        <Input.TextArea autoSize={{ minRows: 2 }} value={payload.title} />
                    </div>
                    <div className="mb-4">
                        <label>Content</label>
                        <Input.TextArea autoSize={{ minRows: 5 }} value={payload.messages} width="100%" />
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default ListNoticeTemplate;
