import axios from 'axios';

const path = 'workweek/';

const create = async (payload) => {
    return await axios.post(path, payload);
};

const getCompany = async (id, filter = {}) => {
    return await axios.get(path + id, { params: filter });
};

const getById = async (id, filter = {}) => {
    return await axios.get(path + 'id/' + id, { params: filter });
};

const update = async (id, payload) => {
    return await axios.patch(path + id, payload);
};

const remove = async (id) => {
    return await axios.delete(path + id);
};

export const workWeekAPI = {
    create,
    getCompany,
    getById,
    update,
    remove,
};
