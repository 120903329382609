import React, { useEffect, useState } from 'react';
import { Col, Row } from 'shards-react';
import { employeesAPI } from '../../apis/employeesAPI';
import { ROLE } from '../../helpers/const';
import { useEmployee, useUser } from '../../hooks';

function StatisticsEmployee({ refresh }) {
    const { employees } = useEmployee();
    const { user, getMe } = useUser();
    const [total, setTotal] = useState(0);
    const [male, setMale] = useState();
    const [female, setFemale] = useState();

    useEffect(() => {
        renderStatistic();
    }, [employees, user, refresh]);

    const renderStatistic = async () => {
        if (!user) {
            getMe();
        }

        if (user && user.role && (user.role === ROLE.ADMIN || user.role === ROLE.SYSTEMSTAFF)) {
            const { data } = await employeesAPI.percent({ role: user.role });
            setTotal(data.total);
            setMale(data.male);
            setFemale(data.female);
        }
        if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
            const { data } = await employeesAPI.percent({ role: user.role, idCompany: user.company._id });
            setTotal(data.total);
            setMale(data.male);
            setFemale(data.female);
        }
        if (
            (user &&
                user.role &&
                user.role === ROLE.COMPANYSTAFF &&
                user.employee &&
                user.employee.company &&
                user.employee.company._id) ||
            (user && user.position && user.position.employee && user.position.employee.length > 0) ||
            (user.employee &&
                user.employee.controls &&
                user.employee.controls.employee &&
                user.employee.controls.employee.length > 0)
        ) {
            const { data } = await employeesAPI.percent({ role: user.role, idCompany: user.employee.company._id });
            setTotal(data.total);
            setMale(data.male);
            setFemale(data.female);
        }
    };

    return (
        <div className="card_ovv_employees w-100">
            <div className="border-bottom bg-white p-3 rounded-top">
                <span className="ct-title">Overview</span>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 bg-white rounded-bottom">
                <Row lg="12" sm="6" md="8" xl="12">
                    <Col lg="4" className="pt-3 pb-3 border-right">
                        <div className="col p-0">
                            <div className="widget-flat h-100 statistical__box">
                                <div className="statistical">
                                    <div className="float-end">
                                        <i className="mdi mdi-account-multiple widget-icon" />
                                    </div>
                                    <span className="" title="Number of Customers">
                                        Total employees
                                    </span>
                                    <span className="">{total ? total : 0}</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg="4" className="pt-3 pb-3 border-right">
                        <div className="col p-0">
                            <div className=" widget-flat h-100  ">
                                <div className="statistical">
                                    <div className="float-end">
                                        <i className="mdi mdi-account-multiple widget-icon" />
                                    </div>
                                    <span className="" title="Number of Customers">
                                        Male
                                    </span>
                                    <span className="">{male ? male : 0}</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg="4" className="pt-3 pb-3">
                        <div className="col p-0">
                            <div className=" widget-flat h-100  ">
                                <div className="statistical">
                                    <div className="float-end">
                                        <i className="mdi mdi-account-multiple widget-icon" />
                                    </div>
                                    <span className="" title="Number of Customers">
                                        Female
                                    </span>
                                    <span className="">{female ? female : 0}</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default StatisticsEmployee;
