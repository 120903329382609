import axios from 'axios';

const path = 'leaves-setting/';

const getAll = async () => {
    return await axios.get(path);
};

const getById = async (id) => {
    return await axios.get(path + id);
};

const findAndCreate = async (id) => {
    return await axios.post(path + id);
};

const add = async (payload) => {
    return await axios.post(path, payload);
};

const update = async (id, payload) => {
    return await axios.patch(path + id, payload);
};

const getByCompany = async (id) => {
    return await axios.get(path + 'company/' + id);
};

const getByCompanyActive = async (id) => {
    return await axios.get(path + 'company-active/' + id);
};

const getToOverwrite = async (id) => {
    return await axios.get(path + 'company-overwrite/' + id);
};

export const leavesSettingAPI = {
    getToOverwrite,
    getById,
    add,
    update,
    getByCompany,
    getAll,
    getByCompanyActive,
    findAndCreate,
};
