import { Tabs } from 'antd';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'shards-react';
import { ROLE } from '../../helpers/const';
import { useUser } from '../../hooks';
import PageTitle from './../common/PageTitle';
import CPF from './TabPayrollSettings/CPF';
import CDAC from './TabPayrollSettings/CDAC';
import ECF from './TabPayrollSettings/ECF';
import SINDA from './TabPayrollSettings/SINDA';
import MBMF from './TabPayrollSettings/MBMF';
import SDL from './TabPayrollSettings/SDL';

const PayrollSetting = () => {
    const { user, getMe } = useUser();
    const history = useHistory();
    useEffect(() => {
        if (!user) {
            getMe();
        }
        user &&
            user.role &&
            user.role !== ROLE.COMPANY &&
            user.role !== ROLE.COMPANYSTAFF &&
            user.role !== ROLE.USER &&
            history.push('/login');
    }, [user]);
    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <Col>
                    <PageTitle
                        title="Payroll settings"
                        subtitle={
                            <>
                                <span className="route-extra">Company settings/ Payroll settings </span>
                            </>
                        }
                        className="text-sm-left mb-3"
                    />
                </Col>
            </Row>
            <Row className="mt-2 w-100" lg="12" xl="12" md="12" sm="12">
                <Card small className="h-100 w-100">
                    <CardHeader className="border-bottom">
                        <div>
                            <span className="ct-title">Payroll settings</span>
                        </div>
                    </CardHeader>

                    <CardBody className="col-xl-12 col-lg-12">
                        <Row>
                            <Col>
                                <Tabs tabPosition="left">
                                    <Tabs.TabPane tab={<span className="title color-bluedark">CPF</span>} key="1">
                                        <CPF />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab={<span className="title color-bluedark">CDAC</span>} key="2">
                                        <CDAC />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab={<span className="title color-bluedark">ECF</span>} key="3">
                                        <ECF />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab={<span className="title color-bluedark">SINDA</span>} key="4">
                                        <SINDA />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab={<span className="title color-bluedark">MBMF</span>} key="5">
                                        <MBMF />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab={<span className="title color-bluedark">SDL</span>} key="6">
                                        <SDL />
                                    </Tabs.TabPane>
                                </Tabs>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Row>
        </Container>
    );
};

export default PayrollSetting;
