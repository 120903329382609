import axios from 'axios';

const URL = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUNDYNARY_NAME}`;

const axiosInstance = axios.create({
    baseURL: URL,
});

const upload = async (data) => {
    let formData = new FormData();
    formData.append('upload_preset', `${process.env.REACT_APP_CLOUNDYNARY_UPLOAD_KEY}`);
    formData.append('file', data);
    formData.append('folder', 'avatar-hrm');
    return await axiosInstance.post('/image/upload', formData, {
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    });
};

const uploadAttachment = async (data) => {
    let formData = new FormData();
    formData.append('upload_preset', `${process.env.REACT_APP_CLOUNDYNARY_UPLOAD_KEY}`);
    formData.append('file', data);
    formData.append('folder', 'attachments-hrm');
    return await axiosInstance.post('/image/upload', formData, {
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    });
};

const uploadLeave = async (data) => {
    let formData = new FormData();
    formData.append('upload_preset', `${process.env.REACT_APP_CLOUNDYNARY_UPLOAD_KEY}`);
    formData.append('file', data);
    formData.append('folder', 'leave-hrm');
    return await axiosInstance.post('/upload', formData, {
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    });
};

const uploadMultipleFiles = async (data, folder) => {
    let formData = new FormData();
    formData.append('upload_preset', `${process.env.REACT_APP_CLOUNDYNARY_UPLOAD_KEY}`);
    formData.append('file', data);
    formData.append('folder', `${folder}`);

    return await axiosInstance.post('/upload', formData, {
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    });
};

export const uploadImageAPI = { upload, uploadAttachment, uploadLeave, uploadMultipleFiles };
