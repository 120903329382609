import React, { useEffect, useState } from 'react';
import { EXPENSE_CLAIM_STATUS } from '../../global/expenseClaim';
import { useExpenseClaim } from '../../hooks';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Col, Card, CardHeader } from 'shards-react';
import TabPendingExpenseClaim from './TabPendingExClaim';
import TabExClaimHistory from './TabExClaimHistory';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function TabExpenseClaim() {
    const { expenseClaimsPending, fetchExpenseClaims, expenseClaims } = useExpenseClaim();
    const [totalPending, setTotalPending] = useState(0);
    const [value, setValue] = useState(0);

    useEffect(() => {
        if (expenseClaimsPending) {
            setTotalPending(
                expenseClaimsPending && expenseClaimsPending.paginate && expenseClaimsPending.paginate.count
                    ? expenseClaimsPending.paginate.count
                    : 0,
            );
        }
    }, [expenseClaims]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (value === 0) {
            fetchExpenseClaims({
                status: EXPENSE_CLAIM_STATUS.PENDING,
            });
        } else if (value === 1) {
            fetchExpenseClaims({
                status: EXPENSE_CLAIM_STATUS.APPROVE + ',' + EXPENSE_CLAIM_STATUS.REJECT,
            });
        }
    }, [value]);

    return (
        <Col lg="12" md="12" sm="12" className="mt-4">
            <Card>
                <CardHeader>
                    <span className="ct-title">Expense Claim</span>
                </CardHeader>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            variant="scrollable"
                            // scrollButtons
                            // allowScrollButtonsMobile
                        >
                            <Tab className="tab-title" label={`Pending request (${totalPending})`} {...a11yProps(0)} />
                            <Tab className="tab-title" label="   Expense Claims history" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <TabPendingExpenseClaim tabSelected={value} value={value} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <TabExClaimHistory tabSelected={value} value={value} />
                    </TabPanel>
                </Box>
            </Card>
        </Col>
    );
}
