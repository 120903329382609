import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
// import { columns } from "./TableCompanies"
import { toast } from 'react-toastify';
import { companiesAPI } from '../../apis/companiesAPI';
import { ROLE } from '../../helpers/const';
import { useUser } from '../../hooks';

const headers = ['companyName', 'contactPerson', 'email', 'phoneNumber', 'mobileNumber', 'postalCode', 'fax'];

export default function ExportCSV() {
    const [data, setData] = useState([]);
    const { user, getMe } = useUser();
    const fetchData = async () => {
        if (!user) await getMe();

        try {
            if (user && user.role && (user.role === ROLE.ADMIN || user.role === ROLE.SYSTEMSTAFF)) {
                const { data } = await companiesAPI.getCSV();
                setData(data);
            }
        } catch (error) {
            const message = error.response.data.message;
            if (message && typeof message === 'string') {
                toast.error(<p className="text-capitalize mt-3">{message}</p>);
            } else if (message && typeof message === 'object') {
                toast.error(<p className="text-capitalize mt-3">{message[0]}</p>);
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, [user]);

    return (
        <CSVLink
            data={data}
            headers={headers}
            className="text-center rounded p-2 border-0 d-flex justify-content-center align-items-center button__warning"
        >
            <span className="material-icons">download</span>
            <span className="text ml-2">Export CSV</span>
        </CSVLink>
    );
}
