import React, { useEffect, useState } from 'react';
import { Col, Row } from 'shards-react';
import { employeesAPI } from '../../apis/employeesAPI';
import { fileManagementAPI } from '../../apis/fileManagementAPI';
import { ROLE } from '../../helpers/const';
import { useUser } from '../../hooks';

function StatisticsFile({ refresh }) {
    const { user, getMe } = useUser();
    const [idCompany, setIdCompany] = useState();
    const [total, setTotal] = useState(0);
    const [size, setSize] = useState();

    useEffect(() => {
        (async () => {
            if (!user || !user._id) {
                await getMe();
            }

            if (user && user.company && user.company._id) {
                setIdCompany(user.company._id);
            } else if (user && user.employee && user.employee.company && user.employee.company._id) {
                setIdCompany(user.employee.company._id);
            }
        })();
    }, [user]);

    useEffect(() => {
        renderStatistic();
    }, [idCompany, refresh]);

    const renderStatistic = async () => {
        const { data } = await fileManagementAPI.statistic(idCompany);
        setTotal(data.total);
        setSize((data.size / 1024 / 1024).toFixed(2));
    };

    return (
        <div className="card_ovv_employees w-100">
            <div className="border-bottom bg-white p-3 rounded-top">
                <span className="ct-title">Overview</span>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 bg-white rounded-bottom">
                <Row lg="12" sm="6" md="8" xl="12">
                    <Col lg="6" className="pt-3 pb-3 border-right">
                        <div className="col p-0">
                            <div className="widget-flat h-100 statistical__box">
                                <div className="statistical">
                                    <div className="float-end">
                                        <i className="mdi mdi-account-multiple widget-icon" />
                                    </div>
                                    <span className="" title="Number of Customers">
                                        Total
                                    </span>
                                    <span className="">{total}</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg="6" className="pt-3 pb-3 border-right">
                        <div className="col p-0">
                            <div className=" widget-flat h-100  ">
                                <div className="statistical">
                                    <div className="float-end">
                                        <i className="mdi mdi-account-multiple widget-icon" />
                                    </div>
                                    <span className="" title="Number of Customers">
                                        Size
                                    </span>
                                    <span className="">{size} MB</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default StatisticsFile;
