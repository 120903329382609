import { Card, Form, Input, Modal, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CardHeader, Col, Container, FormInput, InputGroup, InputGroupAddon, InputGroupText, Row } from 'shards-react';
import { MdDeleteForever } from 'react-icons/md';

import PageTitle from '../components/common/PageTitle';
import ListNoticeTemplate from '../components/notice-template/ListNoticeTemplate';

import { getError } from '../helpers/getErrorServer';
import { useUser } from '../hooks';
import { AiFillPlusCircle } from 'react-icons/ai';
import { Option } from 'antd/lib/mentions';
import { departmentAPI } from '../apis/departmentAPI';
import { ROLE } from '../helpers/const';
import { branchAPI } from '../apis/brachAPI';
import { employeesAPI } from '../apis/employeesAPI';
import { companiesAPI } from '../apis/companiesAPI';
import { noticeAPI } from '../apis/noticeAPI';

const initPayload = {
    title: '',
    desc: '',
};

function Notice() {
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [reload, setReload] = useState(0);
    const [payload, setPayload] = useState(initPayload);
    const { user, getMe } = useUser();
    const { TextArea } = Input;
    const [added, setAdded] = useState();
    const [deleteList, setDeleteList] = useState([]);
    const [form] = Form.useForm();

    const [listDepartment, setListDepartment] = useState([]);
    const [listDepartmentTmp, setListDepartmentTmp] = useState([]);
    const [listIdDepartment, setListIdDepartment] = useState([]);

    const [listBranch, setListBranch] = useState([]);
    const [listBranchTmp, setListBranchTmp] = useState([]);
    const [listIdBranch, setListIdBranch] = useState([]);

    const [listEmployee, setListEmployee] = useState([]);
    const [listEmployeeTmp, setListEmployeeTmp] = useState([]);
    const [listIdEmployees, setListIdEmployees] = useState([]);

    const [listCompany, setListCompany] = useState([]);
    const [listCompanyTmp, setListCompanyTmp] = useState([]);
    const [listIdCompany, setListIdCompany] = useState([]);

    const [search, setSearch] = useState();

    const validate = () => {
        if (payload.title === '') {
            toast.error('Please enter title!');
            return false;
        }
        if (payload.desc === '') {
            toast.error('Please enter content!');
            return false;
        }

        return true;
    };

    const showModal = () => {
        setOpen(true);
    };

    const showModalDelete = () => {
        setOpenDelete(true);
    };

    const handleOk = async () => {
        if (validate()) {
            setConfirmLoading(true);
            await add();
            setConfirmLoading(false);
        }
    };

    const handleDeleteOk = async () => {
        setConfirmLoading(true);
        if (deleteList.length < 1) {
            toast.error('No item has been chose!');
            setOpenDelete(false);
            return;
        }

        for await (const item of deleteList) {
            await noticeAPI.softDelete(item._id, user._id);
        }

        setDeleteList([]);
        setOpenDelete(false);
        setConfirmLoading(false);
        setReload((e) => e + 1);
        toast.success('Success');
        setAdded((prev) => prev + 1);
    };

    const add = async () => {
        try {
            await noticeAPI.create({
                idCreator: user._id,
                title: payload.title,
                messages: payload.desc,
                companies: listIdCompany,
                employee: listIdEmployees,
                branch: listIdBranch,
                department: listIdDepartment,
            });
            setOpen(false);
            setPayload(initPayload);
            setListIdCompany([]);
            setListIdDepartment([]);
            setListIdBranch([]);
            setListIdEmployees([]);
            setReload((e) => e + 1);
            setPayload(initPayload);
            setAdded((prev) => prev + 1);
            toast.success('Success');
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const handleCancel = () => {
        setOpen(false);
        setPayload(initPayload);
        setListIdCompany([]);
        setListIdDepartment([]);
        setListIdBranch([]);
        setListIdEmployees([]);
    };

    const handleDeleteCancel = () => {
        setOpenDelete(false);
    };

    const loadListDepartment = async (id) => {
        const { data } = await departmentAPI.getAllByCompany(id);
        setListDepartment(data);
        setListDepartmentTmp(data);
    };

    const handleChangeDepartmentList = async (value) => {
        let isAll = false;
        value.map((item) => {
            if (item === 'all') {
                isAll = true;
            }
        });

        if (isAll) {
            setListDepartmentTmp([]);
            let tmp = [];
            listDepartment.map((item) => (tmp = [...tmp, item._id]));
            setListIdDepartment(['all']);
        } else {
            setListDepartmentTmp(listDepartment);
            setListIdDepartment(value);
        }
    };

    const loadListBranch = async (id) => {
        const { data } = await branchAPI.getByCompany(id);
        setListBranch(data);
        setListBranchTmp(data);
    };

    const handleChangeBranchList = async (value) => {
        let isAll = false;
        value.map((item) => {
            if (item === 'all') {
                isAll = true;
            }
        });

        if (isAll) {
            setListBranchTmp([]);

            setListIdBranch(['all']);
        } else {
            setListBranchTmp(listBranch);
            setListIdBranch(value);
        }
    };

    const loadListEmployees = async (id) => {
        const { data } = await employeesAPI.getAllCompany(id);
        setListEmployee(data);
        setListEmployeeTmp(data);
    };

    const handleChangeEmployeesList = async (value) => {
        let isAll = false;
        value.map((item) => {
            if (item === 'all') {
                isAll = true;
            }
        });

        if (isAll) {
            setListEmployeeTmp([]);
            setListIdEmployees(['all']);
        } else {
            setListEmployeeTmp(listEmployee);
            setListIdEmployees(value);
        }
    };

    const loadListCompany = async () => {
        const { data } = await companiesAPI.getAll();
        setListCompany(data);
        setListCompanyTmp(data);
    };

    const handleChangeCompanyList = async (value) => {
        let isAll = false;
        value.map((item) => {
            if (item === 'all') {
                isAll = true;
            }
        });

        if (isAll) {
            setListCompanyTmp([]);

            setListIdCompany(['all']);
        } else {
            setListCompanyTmp(listCompany);
            setListIdCompany(value);
        }
    };

    useEffect(() => {
        (async () => {
            if (open) {
                if (!user || !user._id) {
                    await getMe();
                }
                if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
                    loadListDepartment(user.company._id);
                    loadListBranch(user.company._id);
                    loadListEmployees(user.company._id);
                } else if (
                    user &&
                    user.role &&
                    user.role === ROLE.COMPANYSTAFF &&
                    user.employee &&
                    user.employee.company &&
                    user.employee.company._id
                ) {
                    loadListDepartment(user.employee.company._id);
                    loadListBranch(user.employee.company._id);
                    loadListEmployees(user.employee.company._id);
                } else if (user && user.role && user.role === ROLE.ADMIN) {
                    loadListCompany();
                }
            }
        })();
    }, [user, open]);

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle
                    title="Messenger"
                    subtitle={<span className="route-main">Messenger</span>}
                    className="text-sm-left mb-3 "
                />
            </Row>
            <Card small className="h-100 mt-4 w-100">
                <CardHeader className="border-bottom">
                    <div>
                        <span className="ct-title">Messenger</span>
                    </div>
                </CardHeader>
                <div className="ct-box">
                    <div className="col-xl-12 col-lg-12">
                        <Row className="p-0" lg="12" sm="12" md="8" xl="12">
                            <Col xl="4" lg="12" md="12" sm="12" className="p-1">
                                <InputGroup>
                                    <FormInput
                                        placeholder="Input Search Text"
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    <InputGroupAddon type="append">
                                        <InputGroupText className="pointer">
                                            <i className="material-icons" style={{ fontSize: '25px' }}>
                                                search
                                            </i>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                            <Col xl="4"></Col>
                            <Col xl="4" lg="12" md="12" sm="12">
                                <Row>
                                    <Col lg="6" md="6" sm="12" className="p-1">
                                        <button className="button_delete" onClick={() => showModalDelete()}>
                                            <MdDeleteForever size={20} />
                                            <span className="text__red">Delete</span>
                                        </button>
                                    </Col>
                                    {user &&
                                        (user.role === ROLE.ADMIN ||
                                            user.role === ROLE.COMPANY ||
                                            user.role === ROLE.COMPANYSTAFF) && (
                                            <Col lg="6" md="6" sm="12" className="p-1">
                                                <div
                                                    className="text-center rounded p-2 d-flex justify-content-center align-items-center button__warning"
                                                    onClick={() => showModal()}
                                                >
                                                    <AiFillPlusCircle size={25} />
                                                    <span className="text ml-2">Send Message</span>
                                                </div>
                                            </Col>
                                        )}
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="p-0">
                                <ListNoticeTemplate
                                    user={user}
                                    refetch={reload}
                                    added={added}
                                    deleteList={deleteList}
                                    setDeleteList={setDeleteList}
                                    search={search}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </Card>

            {/* <div
                        className="text-center rounded p-2 d-flex justify-content-center align-items-center button__warning"
                        onClick={showModal}
                    >
                        <AiFillPlusCircle size={25} />
                        <span className="ml-2">Add Notice Template</span>
                    </div> */}
            <Modal
                title="Send message"
                open={open}
                onOk={handleOk}
                zIndex={999}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                width="700px"
            >
                <Form form={form} layout="vertical" name="form_in_modal" initialValues={{ modifier: 'public' }}>
                    {user && user.role === ROLE.ADMIN && (
                        <Row>
                            <Col lg="12">
                                <div className=" mb-4">
                                    <Form.Item name="To Company" label="To Company">
                                        <Space style={{ width: '100%' }} direction="vertical">
                                            <Select
                                                allowClear
                                                mode="multiple"
                                                onChange={handleChangeCompanyList}
                                                optionLabelProp="label"
                                                style={{ width: '100%', zIndex: 999 }}
                                                placeholder="Please select"
                                                maxTagCount="responsive"
                                                value={listIdCompany}
                                            >
                                                {listCompany && listCompany.length > 0 && (
                                                    <Option value="all" label="All">
                                                        <Space>--All--</Space>
                                                    </Option>
                                                )}
                                                {listCompanyTmp.map((item) => (
                                                    <Option value={item._id} label={item.companyName}>
                                                        <Space>{item.companyName}</Space>
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Space>
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                    )}
                    {user && (user.role === ROLE.COMPANY || user.role === ROLE.COMPANYSTAFF) && (
                        <>
                            <Row>
                                <Col lg="12">
                                    <div className=" mb-4">
                                        <Form.Item name="To Department" label="To Department">
                                            <Space style={{ width: '100%' }} direction="vertical">
                                                <Select
                                                    allowClear
                                                    mode="multiple"
                                                    onChange={handleChangeDepartmentList}
                                                    optionLabelProp="label"
                                                    style={{ width: '100%', zIndex: 999 }}
                                                    placeholder="Please select"
                                                    maxTagCount="responsive"
                                                    value={listIdDepartment}
                                                >
                                                    {listDepartment && listDepartment.length > 0 && (
                                                        <Option value="all" label="All">
                                                            <Space>--All--</Space>
                                                        </Option>
                                                    )}
                                                    {listDepartmentTmp.map((item) => (
                                                        <Option value={item._id} label={item.departmentName}>
                                                            <Space>{item.departmentName}</Space>
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Space>
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <div className=" mb-4">
                                        <Form.Item name="To Branch" label="To Branch">
                                            <Space style={{ width: '100%' }} direction="vertical">
                                                <Select
                                                    allowClear
                                                    mode="multiple"
                                                    onChange={handleChangeBranchList}
                                                    placeholder="Please select"
                                                    optionLabelProp="label"
                                                    style={{ width: '100%', zIndex: 999 }}
                                                    maxTagCount="responsive"
                                                    value={listIdBranch}
                                                >
                                                    {listBranch && listBranch.length > 0 && (
                                                        <Option value="all" label="All">
                                                            <Space>--All--</Space>
                                                        </Option>
                                                    )}
                                                    {listBranchTmp.map((item) => (
                                                        <Option value={item._id} label={item.name}>
                                                            <Space>{item.name}</Space>
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Space>
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <div className=" mb-4">
                                        <Form.Item name="To Employee" label="To Employee">
                                            <Space style={{ width: '100%' }} direction="vertical">
                                                <Select
                                                    allowClear
                                                    mode="multiple"
                                                    onChange={handleChangeEmployeesList}
                                                    optionLabelProp="label"
                                                    style={{ width: '100%', zIndex: 999 }}
                                                    placeholder="Please select"
                                                    maxTagCount="responsive"
                                                    value={listIdEmployees}
                                                >
                                                    {listEmployee && listEmployee.length > 0 && (
                                                        <Option value="all" label="All">
                                                            <Space>--All--</Space>
                                                        </Option>
                                                    )}

                                                    {listEmployeeTmp.map((item) => (
                                                        <Option value={item._id} label={item.firstName}>
                                                            <Space>{item.firstName}</Space>
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Space>
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}

                    <div>
                        <label>Title</label>
                        <Input.TextArea
                            autoSize={{ minRows: 2 }}
                            onChange={(e) => setPayload({ ...payload, title: e.target.value })}
                            value={payload.title}
                        />
                    </div>
                    <div>
                        <label>Content</label>
                        <TextArea
                            autoSize={{ minRows: 5 }}
                            value={payload.desc}
                            onChange={(e) => {
                                setPayload({ ...payload, desc: e.target.value });
                            }}
                            width="100%"
                        />
                    </div>
                </Form>
            </Modal>
            <Modal
                title={
                    <>
                        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.4167 0.833344H2.58333C1.56583 0.833344 0.75 1.65834 0.75 2.66668V15.5C0.75 16.5083 1.56583 17.3333 2.58333 17.3333H6.25L9 20.0833L11.75 17.3333H15.4167C16.425 17.3333 17.25 16.5083 17.25 15.5V2.66668C17.25 1.65834 16.425 0.833344 15.4167 0.833344ZM9.91667 15.5H8.08333V13.6667H9.91667V15.5ZM11.8142 8.39584L10.9892 9.23918C10.3292 9.90834 9.91667 10.4583 9.91667 11.8333H8.08333V11.375C8.08333 10.3667 8.49583 9.45001 9.15583 8.78084L10.2925 7.62584C10.6317 7.29584 10.8333 6.83751 10.8333 6.33334C10.8333 5.32501 10.0083 4.50001 9 4.50001C7.99167 4.50001 7.16667 5.32501 7.16667 6.33334H5.33333C5.33333 4.30751 6.97417 2.66668 9 2.66668C11.0258 2.66668 12.6667 4.30751 12.6667 6.33334C12.6667 7.14001 12.3367 7.87334 11.8142 8.39584Z"
                                fill="#FCBB0A"
                            />
                        </svg>

                        <p className="title-modal-custom">{`Are you sure delete ${deleteList.length || 0} massage?`}</p>
                    </>
                }
                open={openDelete}
                onOk={handleDeleteOk}
                onCancel={handleDeleteCancel}
            >
                <p>By delete messages</p>
            </Modal>
        </Container>
    );
}

export default Notice;
