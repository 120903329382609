import { Input, Modal, Space, Table, Tag, Checkbox } from 'antd';

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Row } from 'shards-react';
import { historiesAPI } from '../../apis/historiesAPI';
import { historyOTAPI } from '../../apis/historyOTAPI';
import { leaveAPI } from '../../apis/leaveAPI';
import { leavesSettingAPI } from '../../apis/leavesSettingAPI';
import { LEAVE_STATUS } from '../../global/leave';
import { LEAVE_UNIT } from '../../global/leave/leaveEnum';
import { FORMAT, FORM_TYPE, ROLE, TYPE_NOTIFICATION } from '../../helpers/const';
import { getError } from '../../helpers/getErrorServer';
import { useLeave, useUser } from '../../hooks';
import IMG_AVT from '../../images/avatars/0.jpg';
import LeaveCreateForm from './leaveCreateForm';

const initRequest = {
    reasonApprove: '',
    status: '',
};

const TablePendingRequest = ({ value, setRender, setValue }) => {
    const { leaves, fetchLeaves, filterLeave, setFilterLeave } = useLeave();
    const [dataTable, setDataTable] = useState([]);
    const { user } = useUser();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [title, setTitle] = useState('');
    const [idRequest, setIdRequest] = useState();
    const [responseRequest, setResponseRequest] = useState(initRequest);
    const [visible, setVisible] = useState();
    const [leaveSetting, setLeaveSetting] = useState();
    const [leaveSelected, setLeaveSelected] = useState();
    const [type, setType] = useState(FORM_TYPE.VIEW);
    const [isShowIsAwarded, setIsShowIsAwarded] = useState(false);
    const [page, setPage] = useState(1);
    const { TextArea } = Input;
    const history = useHistory();

    const regReason = /^[a-zA-Z0-9._-\s]{0,200}$/;

    const initColumns = [
        {
            title: ' Name',
            key: 'employee.firstName',
            render: (_, row) => {
                return (
                    <div
                        className="content_td_mobile_db d-flex flex-start align-items-center"
                        onClick={() => onOpen(row._id)}
                        style={{ cursor: 'pointer' }}
                    >
                        <p className="title_mobile">Name</p>
                        <div className="box-name">
                            <div className="avt_leave_requ">
                                <img
                                    src={
                                        row.employee && row.employee.user && row.employee.user.avatar
                                            ? row.employee.user.avatar
                                            : IMG_AVT
                                    }
                                    alt=""
                                />
                            </div>
                            <p>
                                {row.employee && row.employee.firstName
                                    ? row.employee.firstName
                                    : "Can't get first name employee"}
                            </p>
                        </div>
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.employee.firstName > record2.employee.firstName;
            },
        },
        {
            title: 'Leave Type',
            dataIndex: 'type',
            key: 'type',
            render: (_, row) => (
                <div className="content_td_mobile" onClick={() => onOpen(row._id)} style={{ cursor: 'pointer' }}>
                    <p className="title_mobile">Leave Type</p>

                    <Tag color={'blue'}>{row.type.description}</Tag>
                </div>
            ),
        },
        {
            title: 'From',
            dataIndex: 'from',
            key: 'from',

            render: (value, row) => {
                return (
                    <div className="content_td_mobile" onClick={() => onOpen(row._id)} style={{ cursor: 'pointer' }}>
                        <p className="title_mobile">From</p>
                        {moment(new Date(new Date(value).setHours(12, 0, 0, 0))).format(FORMAT.DATE)}
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.from > record2.from;
            },
        },
        {
            title: 'To',
            dataIndex: 'to',
            key: 'to',
            render: (value, row) => {
                return (
                    <div className="content_td_mobile" onClick={() => onOpen(row._id)} style={{ cursor: 'pointer' }}>
                        <p className="title_mobile">To</p>
                        {moment(new Date(new Date(value).setHours(12, 0, 0, 0))).format(FORMAT.DATE)}
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.to > record2.to;
            },
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
            render: (value, row) => (
                <div className="content_td_mobile" onClick={() => onOpen(row._id)} style={{ cursor: 'pointer' }}>
                    <p className="title_mobile">Reason</p>
                    {/* {getEllipsisTxt(value)} */}
                    {value}
                </div>
            ),
        },
    ];
    const columnsManager = [
        {
            title: 'ID',
            key: 'tmp',
            dataIndex: 'tmp',
            render: (_, row, i) => {
                return (
                    <>
                        <div
                            className="content_td_mobile"
                            onClick={() => onOpen(row._id)}
                            style={{ cursor: 'pointer' }}
                        >
                            <p className="title_mobile">ID</p>
                            <p>{row.employee.employeesId}</p>
                        </div>
                    </>
                );
            },

            sorter: (record1, record2) => {
                return record1.employee.employeesId > record2.employee.employeesId;
            },
        },
        ...initColumns,
        {
            title: 'Actions',
            key: '_id',
            fixed: 'right',
            render: (id) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Actions</p>
                    <Space size="middle">
                        <span className="reject_btn" onClick={(type) => showModal((type = 'reject'), id)}>
                            Reject
                        </span>
                        <span className="approve_btn" onClick={(type) => showModal((type = 'approve'), id)}>
                            Approve
                        </span>
                    </Space>
                </div>
            ),
        },
    ];
    const [columns, setColumns] = useState(initColumns);

    useEffect(() => {
        if (
            (user.role && user.role !== ROLE.USER) ||
            (user && user.employee && user.employee.isApproverLeave) ||
            (user && user.position && user.position.leave && user.position.leave.includes('2')) ||
            (user &&
                user.employee &&
                user.employee.controls &&
                user.employee.controls.leave &&
                user.employee.controls.leave.includes('2'))
        ) {
            setColumns(columnsManager);
            setType(FORM_TYPE.APPROVE);
        }
    }, [user]);

    useEffect(() => {
        if (leaves) {
            let tmp = [];
            leaves.items.forEach((item, i) => {
                tmp.push({
                    ...item,
                    key: i,
                    page: leaves.paginate.page,
                });
            });

            setDataTable(tmp);
        }
    }, [leaves]);

    useEffect(() => {
        (async () => {
            if (idRequest) {
                const payload = await (await leaveAPI.getById(idRequest)).data;

                const { data } = await leavesSettingAPI.getById(payload.type);

                setLeaveSetting(data);
                if (data && data.leaveUnit && data.leaveUnit === LEAVE_UNIT.HOURS) {
                    const employee = payload.employee._id;
                    const month = new Date(payload.from).getMonth() + 1;
                    const year = new Date(payload.from).getFullYear();
                    const { data } = await historyOTAPI.find({ employee, month, year });
                    if (data && data.count && data.count > 0) {
                        setIsShowIsAwarded(true);
                    } else {
                        setIsShowIsAwarded(false);
                    }
                }
            }
        })();
    }, [idRequest]);

    const showModal = (type, id) => {
        setIsModalVisible(true);
        setTitle(type);
        setIdRequest(id._id);
        setResponseRequest({ ...initRequest, status: type });
    };

    const handleSubmit = async () => {
        try {
            if (responseRequest.reasonApprove === '') {
                toast.error(`Please input the reason why you ${title} this request!`);
            } else {
                const payload = await (await leaveAPI.getById(idRequest)).data;

                const tmp = {
                    ...payload,
                    ...responseRequest,
                    reasonApprove: responseRequest.reasonApprove.trim(),
                    employee: payload.employee._id,
                    isAwarded: responseRequest.isAwarded ? true : false,
                };

                const request = await leaveAPI.update(idRequest, tmp);
                if (request && request.data && request.data._id && request.data.employee) {
                    getHistories(request.data._id, request.data.employee);
                }
                if (
                    request &&
                    request.data &&
                    request.data._id &&
                    request.data.employee &&
                    user &&
                    user.role &&
                    user.role !== ROLE.COMPANY
                ) {
                    getHistoriesToCompany(request.data._id, request.data.employee);
                }
                toast.success('Update successfully!');
                setRender((prev) => prev + 1);
                history.push('/leave');
                setIsModalVisible(false);
                fetchLeaves({ ...filterLeave, page });
                setResponseRequest(initRequest);
            }
        } catch (error) {
            toast.error(getError(error));
            console.log(error);
        }
    };

    const getHistoriesToCompany = async (id, employee) => {
        await historiesAPI.add({
            title: 'LEAVE REQUEST',
            desc: `${user.employee.firstName} just review leave request`,
            employee,
            type: TYPE_NOTIFICATION.LEAVE,
            idRequest: id,
            toCompany: true,
        });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setResponseRequest(initRequest);
    };

    const onChangePage = (page) => {
        setPage(page);
        const filter = { ...filterLeave, page };
        setFilterLeave(filter);
        fetchLeaves(filter);
    };

    const getHistories = async (id, employee) => {
        await historiesAPI.add({
            title: 'LEAVE REQUEST',
            desc: 'Your leave request just reviewed',
            employee,
            type: TYPE_NOTIFICATION.LEAVE,
            idRequest: id,
            toEmployee: true,
        });
    };

    const onOpen = (id) => {
        setLeaveSelected(id);
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
        setLeaveSelected();
    };

    return (
        <>
            <Table
                className="ct-table-data"
                columns={columns}
                dataSource={dataTable}
                pagination={{
                    onChange: onChangePage,
                    current: page,
                    pageSize: leaves && leaves.paginate && leaves.paginate.size ? leaves.paginate.size : 10,
                    total: leaves && leaves.paginate ? leaves.paginate.count : 0,
                }}
            />
            <LeaveCreateForm
                visible={visible}
                id={leaveSelected}
                onCancel={() => {
                    onClose();
                }}
                type={type}
                setRender={setRender}
                value={value}
                setValue={setValue}
                pagePending={page}
            />
            <Modal
                title={`Are you sure to ${title} this request?`}
                open={isModalVisible}
                onOk={handleSubmit}
                onCancel={handleCancel}
                zIndex={999}
            >
                <Row form>
                    <Col lg="12" className="form-group">
                        <div className="mt-2 mb-5">
                            <TextArea
                                maxLength={100}
                                rows={4}
                                value={responseRequest.reasonApprove}
                                placeholder={`Input the reason why you ${title} this request.`}
                                onChange={(e) => {
                                    setResponseRequest({
                                        ...responseRequest,
                                        reasonApprove: e.target.value,
                                    });
                                }}
                                width="100%"
                            />
                        </div>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default TablePendingRequest;
