import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FaRegCalendar } from 'react-icons/fa';
import { Card, CardBody, CardHeader } from 'shards-react';
import IMG_AVT from '../../images/avatars/0.jpg';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { DatePicker } from 'antd';
import { useLocation } from 'react-router-dom';
import { employeesAPI } from '../../apis/employeesAPI';
import { ROLE } from '../../helpers/const';
import { useEmployee, useUser } from '../../hooks';

// const DateTimePicker = ({ value, setValue, open = false }) => {
//     return (

//         // <LocalizationProvider dateAdapter={AdapterDateFns}>
//         //     <DatePicker
//         //         openTo="year"
//         //         views={['year', 'month', 'day']}
//         //         label="Year, month and date"
//         //         value={value}
//         //         onChange={(newValue) => {
//         //             setValue(newValue);
//         //         }}
//         //         renderInput={(params) => <TextField {...params} helperText={null} />}
//         //     />
//         // </LocalizationProvider>
//     );
// };

const ListItems = ({ value, datalist, setDatalist }) => {
    const { user, getMe } = useUser();
    const { employees } = useEmployee();
    const location = useLocation();

    useEffect(() => {
        getData();
    }, [user, employees, value, location]);

    const getData = async () => {
        if (!user) {
            getMe();
        }

        if (user && user.role && (user.role === ROLE.ADMIN || user.role === ROLE.SYSTEMSTAFF)) {
            const { data } = await employeesAPI.birthday({ queryBirthday: value, role: user.role });
            setDatalist(data.items);
        }

        if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
            const { data } = await employeesAPI.birthday({
                queryBirthday: value,
                role: user.role,
                idCompany: user.company._id,
            });
            setDatalist(data.items);
        }

        if (
            user &&
            user.role &&
            user.role === ROLE.COMPANYSTAFF &&
            user.employee &&
            user.employee.company &&
            user.employee.company._id
        ) {
            const { data } = await employeesAPI.birthday({
                queryBirthday: value,
                role: user.role,
                idCompany: user.employee.company._id,
            });
            setDatalist(data.items);
        }
    };

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 560,
                bgcolor: 'background.paper',
                height: 300,
            }}
        >
            {datalist
                ? datalist.map((item, idex) => {
                      return (
                          <div key={idex} className=" border-bottom">
                              <ListItem alignItems="flex-start" key={item._id}>
                                  <ListItemAvatar
                                      style={{
                                          width: '60px',
                                          height: '60px',
                                          backgroundColor: '#0c415b',
                                          borderRadius: 8,
                                          display: 'grid',
                                          placeItems: 'center',
                                          marginRight: 10,
                                      }}
                                  >
                                      <Avatar
                                          alt="Remy Sharp"
                                          src={item && item.user && item.user.avatar ? item.user.avatar : IMG_AVT}
                                      />
                                  </ListItemAvatar>
                                  <ListItemText
                                      primary={item.firstName}
                                      secondary={
                                          <React.Fragment>
                                              <Typography
                                                  sx={{ display: 'inline' }}
                                                  component="span"
                                                  variant="body2"
                                                  color="text.primary"
                                              >
                                                  {item.jobPosition}
                                                  <br></br>
                                                  has birthday today
                                              </Typography>
                                          </React.Fragment>
                                      }
                                  />
                              </ListItem>
                          </div>
                      );
                  })
                : []}
            {}
        </List>
    );
};

const NewDraft = () => {
    const [value, setValue] = useState(new Date());
    const [datalist, setDatalist] = useState();
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState();

    const onchange = (date, dateString) => {
        setDate(dateString);
        setValue(date);
    };
    return (
        <>
            <Card small className="h-100">
                <CardHeader className="border-bottom top_picker">
                    <DatePicker
                        id="data-picker"
                        open={open}
                        onChange={onchange}
                        style={{ position: 'absolute', opacity: 0 }}
                    />
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="title-date">
                            <span>
                                {date
                                    ? moment(date).format('dddd, DD, MMM, YYYY')
                                    : moment().format('dddd, DD, MMM, YYYY')}
                            </span>
                            <span>
                                {moment(date).format('dddd') === moment().format('dddd')
                                    ? 'Today'
                                    : moment(date).format('dddd')}
                            </span>
                        </div>
                        <FaRegCalendar
                            style={{ cursor: 'pointer' }}
                            size={40}
                            color="#fff"
                            onClick={() => {
                                setOpen(!open);
                            }}
                        />
                    </div>
                </CardHeader>

                <CardBody className="d-flex flex-column overflow-auto">
                    <ListItems value={value} datalist={datalist} setDatalist={setDatalist} />
                </CardBody>
            </Card>
        </>
    );
};

NewDraft.propTypes = {
    title: PropTypes.string,
};

NewDraft.defaultProps = {
    title: 'New Draft',
};

export default NewDraft;
