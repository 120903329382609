import { Form, Popconfirm, Table, Tag, TimePicker, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { attendanceAPI } from '../../apis/attendanceAPI';
import { ROLE } from '../../helpers/const';
import { getHour } from '../../helpers/getHours';
import { useUser } from '../../hooks';
import { getError } from '../../helpers/getErrorServer';

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item style={{ margin: 0, padding: 0 }} name={dataIndex}>
                    <TimePicker style={{ width: '100%' }} format={'HH:mm'} />
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const TableViewAttendance = ({ tableData, setTableData, setReload }) => {
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const { user, getMe } = useUser();

    const isEditing = (record) => record._id === editingKey;

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (record) => {
        try {
            const row = await form.validateFields();

            if (!row.checkInTime && !row.checkOutTime && !row.overtime) {
                setEditingKey('');
                return;
            }

            const values = {
                checkInTime: row.checkInTime
                    ? new Date(moment(record.date).format('YYYY-MM-DD') + ' ' + moment(row.checkInTime).format('HH:mm'))
                    : moment(record.checkInTime).format('YYYY-MM-DD HH:mm'),
                checkOutTime: new Date(row.checkOutTime)
                    ? new Date(
                          moment(record.date).format('YYYY-MM-DD') + ' ' + moment(row.checkOutTime).format('HH:mm'),
                      )
                    : moment(record.checkOutTime).format('YYYY-MM-DD HH:mm'),
                overtime: new Date(row.overtime)
                    ? new Date(moment(record.date).format('YYYY-MM-DD') + ' ' + moment(row.overtime).format('HH:mm'))
                    : new Date(record.overtime),
            };

            if (values.checkInTime.getTime() > values.checkOutTime.getTime()) {
                toast.error('Time out must be greater than time in');
                return;
            }

            const newData = { ...record, ...values };

            const result = await attendanceAPI.update(record._id, newData);

            if (result.data) {
                setEditingKey('');
                toast.success('Edit successfully!');
                setTableData((prev) => {
                    return prev.map((item) => {
                        if (item._id === result.data._id) {
                            return result.data;
                        } else {
                            return item;
                        }
                    });
                });
            } else {
                toast.error('Edit fail!');
            }
        } catch (err) {
            console.log('Validate Failed:', err);
        }
    };
    const edit = (record) => {
        if (record) {
            const checkIn = record.checkInTime
                ? new Date(moment(record.checkInTime).format('YYYY-MM-DD HH:mm'))
                : record.checkInTime2
                ? new Date(moment(record.checkInTime2).format('YYYY-MM-DD HH:mm'))
                : '';
            const checkOut = record.checkOutTime2
                ? new Date(moment(record.checkOutTime2).format('YYYY-MM-DD HH:mm'))
                : record.checkOutTime
                ? new Date(moment(record.checkOutTime).format('YYYY-MM-DD HH:mm'))
                : '';
            const overtime = record.overtime && new Date(moment(record.overtime).format('YYYY-MM-DD HH:mm'));

            form.setFieldsValue({
                checkInTime: checkIn && moment(checkIn, 'HH:mm'),
                checkOutTime: checkOut && moment(checkOut, 'HH:mm'),
                overtime: overtime ? moment(overtime, 'HH:mm') : null,
            });

            setEditingKey(record._id);
        }
    };

    const remove = async (record) => {
        try {
            await attendanceAPI.remove(record._id);
            toast.success('Success');
            setReload((prev) => prev + 1);
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const columns = [
        {
            id: 1,
            title: 'Date',
            dataIndex: 'date',
            width: '12.5%',
            editable: false,
            filter: false,
            sorter: true,
            render: (_, record) => {
                return <span>{record ? moment(record.date).format('DD MMM, YYYY') : '--'}</span>;
            },
        },
        {
            id: 2,
            title: 'Check In',
            dataIndex: 'checkInTime',
            width: '12.5%',
            editable: true,
            render: (_, record) => {
                return (
                    <span>
                        {record && record.checkInTime
                            ? moment(record.checkInTime).format('HH:mm')
                            : record && record.checkInTime2
                            ? moment(record.checkInTime2).format('HH:mm')
                            : '--'}
                    </span>
                );
            },
        },
        {
            id: 3,
            title: 'Check Out',
            dataIndex: 'checkOutTime',
            width: '12.5%',
            editable: true,
            render: (_, record) => {
                return (
                    <span>
                        {record && record.checkOutTime2
                            ? moment(record.checkOutTime2).format('HH:mm')
                            : record && record.checkOutTime
                            ? moment(record.checkOutTime).format('HH:mm')
                            : '--'}
                    </span>
                );
            },
        },
        {
            id: 4,
            title: 'Overtime',
            dataIndex: 'overtime',
            width: '12.5%',
            editable: true,
            render: (_, record) => <span>{record.overtime ? moment(record.overtime).format('HH: mm') : '--'}</span>,
        },
        {
            id: 5,
            title: 'Working Hours',
            dataIndex: 'workinghours',
            width: '12.5%',
            editable: false,
            render: (_, record) => {
                if (record && record.workingHours) {
                    let { hour, minute } = getHour(record.workingHours);

                    return (
                        <div className="content_td_mobile">
                            <p className="title_mobile">Working Hours</p>
                            <span>
                                {hour === '0' ? '00' : +hour < 10 ? '0' + hour : hour}:
                                {minute === '0' ? '00' : +minute < 10 ? '0' + minute : minute}
                            </span>
                        </div>
                    );
                } else {
                    return (
                        <div className="content_td_mobile">
                            <p className="title_mobile">Working Hours</p>
                            <span>--</span>
                        </div>
                    );
                }
            },
        },

        {
            id: 6,
            title: 'Overtime Hours',
            dataIndex: 'overtimehours',
            width: '12.5%',
            editable: false,
            render: (_, record) => {
                if (record && record.overtimeHours) {
                    let { hour, minute } = getHour(record.overtimeHours);
                    return (
                        <div className="content_td_mobile">
                            <p className="title_mobile">Overtime Hours</p>
                            <span>
                                {hour === '0' ? '00' : +hour < 10 ? '0' + hour : hour}:
                                {minute === '0' ? '00' : +minute < 10 ? '0' + minute : minute}
                            </span>
                        </div>
                    );
                } else {
                    return (
                        <div className="content_td_mobile">
                            <p className="title_mobile">Overtime Hours</p>
                            <span>--</span>
                        </div>
                    );
                }
            },
        },
        {
            id: 7,
            title: 'Status',
            dataIndex: 'status',
            width: '12.5%',
            editable: false,
            render: (status) => {
                let color = '';

                if (status === 'Present') {
                    color = 'green';
                } else if (status === 'Absent') {
                    color = 'red';
                } else {
                    color = 'blue';
                }

                return <Tag color={color}>{status}</Tag>;
            },
        },
        {
            id: 8,
            title: 'Actions',
            dataIndex: 'action',

            fixed: 'right',

            render: (_, record) => {
                if (true) {
                    const editable = isEditing(record);
                    return editable ? (
                        <div className="d-flex justify-content-start align-items-center">
                            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                                <p className="p-0 mr-3 mb-0 text-warning cursor-pointer">Cancel</p>
                            </Popconfirm>
                            <Typography.Link onClick={() => save(record)} style={{ marginRight: 8 }}>
                                Save
                            </Typography.Link>
                        </div>
                    ) : (
                        <>
                            {true && (
                                <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                                    Edit
                                </Typography.Link>
                            )}

                            {true && (
                                // ((user && user.role && user.role !== ROLE.USER) ||
                                //     (user &&
                                //         user.position &&
                                //         user.position.attendance &&
                                //         user.position.attendance.includes('4')) ||
                                //     (user &&
                                //         user.employee &&
                                //         user.employee.controls &&
                                //         user.employee.controls.attendance &&
                                //         user.employee.controls.attendance.includes('4')))
                                <>
                                    <span className="ml-2 mr-1"> |</span>
                                    <Typography.Link
                                        disabled={editingKey !== ''}
                                        onClick={() => remove(record)}
                                        className="ml-2 text-danger"
                                    >
                                        Delete
                                    </Typography.Link>
                                </>
                            )}
                        </>
                    );
                }
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'date' ? 'text' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <Form form={form} component={false}>
            <Table
                className="ct-table-data"
                components={{ body: { cell: EditableCell } }}
                dataSource={tableData}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{ onChange: cancel }}
            />
        </Form>
    );
};

export default TableViewAttendance;
