import { Checkbox, Divider, Space, Switch, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Row } from 'shards-react';
import { employeesAPI } from '../../apis/employeesAPI';
import { ROLE } from '../../helpers/const';
import { useUser } from '../../hooks';
import { getError } from '../../helpers/getErrorServer';

function ControlDetail({ item }) {
    const [payload, setPayload] = useState();
    const { user, getMe } = useUser();

    useEffect(() => {
        setPayload();
    }, []);

    useEffect(() => {
        if (!user) {
            getMe();
        }
    }, [user]);

    useEffect(() => {
        let tmp = {
            employee: [],
            expenseClaim: [],
            leave: [],
            attendance: [],
            payroll: [],
        };
        if (item && item.controls) {
            tmp = item.controls;
        }

        if (item && item.positionDetail) {
            if (item.positionDetail.employee && item.positionDetail.employee.length > 0) {
                item.positionDetail.employee.map((i) => {
                    if (!tmp.employee.includes(i)) {
                        tmp.employee = [...tmp.employee, i];
                    }
                });
            }
            if (item.positionDetail.expenseClaim && item.positionDetail.expenseClaim.length > 0) {
                item.positionDetail.expenseClaim.map((i) => {
                    if (!tmp.expenseClaim.includes(i)) {
                        tmp.expenseClaim = [...tmp.expenseClaim, i];
                    }
                });
            }
            if (item.positionDetail.leave && item.positionDetail.leave.length > 0) {
                item.positionDetail.leave.map((i) => {
                    if (!tmp.leave.includes(i)) {
                        tmp.leave = [...tmp.leave, i];
                    }
                });
            }
            if (item.positionDetail.attendance && item.positionDetail.attendance.length > 0) {
                item.positionDetail.attendance.map((i) => {
                    if (!tmp.attendance.includes(i)) {
                        tmp.attendance = [...tmp.attendance, i];
                    }
                });
            }
            if (item.positionDetail.payroll && item.positionDetail.payroll.length > 0) {
                item.positionDetail.attendance.map((i) => {
                    if (!tmp.payroll.includes(i)) {
                        tmp.payroll = [...tmp.payroll, i];
                    }
                });
            }

            if (item && item.isApproverExpense === true) {
                if (!tmp.expenseClaim.includes('2') && !tmp.expenseClaim.includes('1')) {
                    tmp.expenseClaim = [...tmp.expenseClaim, '1', '2'];
                } else if (!tmp.expenseClaim.includes('2')) {
                    tmp.expenseClaim = [...tmp.expenseClaim, '2'];
                }
            }
            if (item && item.isApproverLeave === true) {
                if (!tmp.leave.includes('2') && !tmp.leave.includes('1')) {
                    tmp.leave = [...tmp.leave, '1', '2'];
                } else if (!tmp.leave.includes('2')) {
                    tmp.leave = [...tmp.leave, '2'];
                }
            }
        }
        setPayload(tmp);
    }, [item]);

    const columns = [
        { title: '', dataIndex: 'name', key: 'name' },
        {
            title: 'Read',
            dataIndex: 'read',
            key: 'read',
            render: (_, record) => (
                <Space size="middle">
                    <Checkbox
                        className="ct-checkbox"
                        onChange={() => {
                            handleCheck(record, '1');
                        }}
                        checked={
                            (payload && payload.employee && payload.employee.includes('1') && record.key === '1') ||
                            (payload &&
                                payload.expenseClaim &&
                                payload.expenseClaim.includes('1') &&
                                record.key === '2') ||
                            (payload && payload.leave && payload.leave.includes('1') && record.key === '3') ||
                            (payload && payload.attendance && payload.attendance.includes('1') && record.key === '4') ||
                            (payload && payload.payroll && payload.payroll.includes('1') && record.key === '5')
                        }
                        //disabled={user && user.role && user.role !== ROLE.COMPANY ? true : false}
                    />
                </Space>
            ),
        },
        {
            title: 'Write',
            dataIndex: 'write',
            key: 'write',
            render: (_, record) => (
                <Space size="middle">
                    <Checkbox
                        className="ct-checkbox"
                        onChange={() => {
                            handleCheck(record, '2');
                        }}
                        checked={
                            (payload && payload.employee && payload.employee.includes('2') && record.key === '1') ||
                            (payload &&
                                payload.expenseClaim &&
                                payload.expenseClaim.includes('2') &&
                                record.key === '2') ||
                            (payload && payload.leave && payload.leave.includes('2') && record.key === '3') ||
                            (payload && payload.attendance && payload.attendance.includes('2') && record.key === '4') ||
                            (payload && payload.payroll && payload.payroll.includes('2') && record.key === '5')
                        }
                    />
                </Space>
            ),
        },
        {
            title: 'Create',
            key: 'create',
            dataIndex: 'create',
            render: (_, record) => (
                <Space size="middle">
                    <Checkbox
                        className="ct-checkbox"
                        onChange={() => {
                            handleCheck(record, '3');
                        }}
                        checked={
                            (payload && payload.employee && payload.employee.includes('3') && record.key === '1') ||
                            (payload &&
                                payload.expenseClaim &&
                                payload.expenseClaim.includes('3') &&
                                record.key === '2') ||
                            (payload && payload.leave && payload.leave.includes('3') && record.key === '3') ||
                            (payload && payload.attendance && payload.attendance.includes('3') && record.key === '4') ||
                            (payload && payload.payroll && payload.payroll.includes('3') && record.key === '5')
                        }
                    />
                </Space>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (_, record) => (
                <Space size="middle">
                    <Checkbox
                        className="ct-checkbox"
                        onChange={() => {
                            handleCheck(record, '4');
                        }}
                        checked={
                            (payload && payload.employee && payload.employee.includes('4') && record.key === '1') ||
                            (payload &&
                                payload.expenseClaim &&
                                payload.expenseClaim.includes('4') &&
                                record.key === '2') ||
                            (payload && payload.leave && payload.leave.includes('4') && record.key === '3') ||
                            (payload && payload.attendance && payload.attendance.includes('4') && record.key === '4') ||
                            (payload && payload.payroll && payload.payroll.includes('4') && record.key === '5')
                        }
                    />
                </Space>
            ),
        },
        {
            title: 'Import',
            dataIndex: 'import',
            key: 'import',
            render: (_, record) => (
                <Space size="middle">
                    <Checkbox
                        className="ct-checkbox"
                        onChange={() => {
                            handleCheck(record, '5');
                        }}
                        checked={
                            (payload && payload.employee && payload.employee.includes('5') && record.key === '1') ||
                            (payload &&
                                payload.expenseClaim &&
                                payload.expenseClaim.includes('5') &&
                                record.key === '2') ||
                            (payload && payload.leave && payload.leave.includes('5') && record.key === '3') ||
                            (payload && payload.attendance && payload.attendance.includes('5') && record.key === '4') ||
                            (payload && payload.payroll && payload.payroll.includes('5') && record.key === '5')
                        }
                    />
                </Space>
            ),
        },
        {
            title: 'Export',
            key: 'export',
            dataIndex: 'export',
            render: (_, record) => (
                <Space size="middle">
                    <Checkbox
                        className="ct-checkbox"
                        onChange={() => {
                            handleCheck(record, '6');
                        }}
                        checked={
                            (payload && payload.employee && payload.employee.includes('6') && record.key === '1') ||
                            (payload &&
                                payload.expenseClaim &&
                                payload.expenseClaim.includes('6') &&
                                record.key === '2') ||
                            (payload && payload.leave && payload.leave.includes('6') && record.key === '3') ||
                            (payload && payload.attendance && payload.attendance.includes('6') && record.key === '4') ||
                            (payload && payload.payroll && payload.payroll.includes('6') && record.key === '5')
                        }
                    />
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            name: 'Employee',
        },
        {
            key: '2',
            name: 'Expense Claim',
        },
        {
            key: '3',
            name: 'Leave',
        },
        {
            key: '4',
            name: 'Attendance',
        },
        {
            key: '5',
            name: 'Payroll',
        },
    ];

    const handleCheck = async (record, str) => {
        if (!item) {
            return;
        }
        const array = [1, 2, 3, 4, 5, 6];

        if (record.key === '1' && payload && payload.employee) {
            if (
                item &&
                item.positionDetail &&
                item.positionDetail.employee &&
                item.positionDetail.employee.includes(str)
            ) {
                return;
            }
            if (payload.employee.includes(str)) {
                let arr = [];

                if (str !== '5' && str !== '6') {
                    const imp = payload.employee.includes('5');
                    const exp = payload.employee.includes('6');
                    arr = payload.employee.filter((item) => item < str);
                    imp && arr.push('5');
                    exp && arr.push('6');
                } else {
                    arr = payload.employee.filter((item) => item !== str);
                }

                payload.employee = arr;
            } else {
                let arr = [];
                let arr2 = [];
                if (str !== '5' && str !== '6') {
                    const imp = payload.employee.includes('5');
                    const exp = payload.employee.includes('6');
                    arr = array.filter((item) => item <= str);

                    arr2 = arr.map((item) => item.toString());
                    imp && arr2.push('5');
                    exp && arr2.push('6');
                    payload.employee = arr2;
                } else {
                    payload.employee.push(str);
                }
            }
        }

        if (record.key === '2' && payload && payload.expenseClaim) {
            if (
                (item &&
                    item.positionDetail &&
                    item.positionDetail.expenseClaim &&
                    item.positionDetail.expenseClaim.includes(str)) ||
                (item && item.isApproverExpense === true && (str === '1' || str === '2'))
            ) {
                return;
            }
            if (payload.expenseClaim.includes(str)) {
                let arr = [];

                if (str !== '5' && str !== '6') {
                    const imp = payload.expenseClaim.includes('5');
                    const exp = payload.expenseClaim.includes('6');
                    arr = payload.expenseClaim.filter((item) => item < str);
                    imp && arr.push('5');
                    exp && arr.push('6');
                } else {
                    arr = payload.expenseClaim.filter((item) => item !== str);
                }

                payload.expenseClaim = arr;
            } else {
                let arr = [];
                let arr2 = [];
                if (str !== '5' && str !== '6') {
                    const imp = payload.expenseClaim.includes('5');
                    const exp = payload.expenseClaim.includes('6');
                    arr = array.filter((item) => item <= str);

                    arr2 = arr.map((item) => item.toString());
                    imp && arr2.push('5');
                    exp && arr2.push('6');
                    payload.expenseClaim = arr2;
                } else {
                    payload.expenseClaim.push(str);
                }
            }
        }

        if (record.key === '3' && payload && payload.leave) {
            if (
                (item && item.positionDetail && item.positionDetail.leave && item.positionDetail.leave.includes(str)) ||
                (item && item.isApproverLeave === true && (str === '1' || str === '2'))
            ) {
                return;
            }
            if (payload.leave.includes(str)) {
                let arr = [];

                if (str !== '5' && str !== '6') {
                    const imp = payload.leave.includes('5');
                    const exp = payload.leave.includes('6');
                    arr = payload.leave.filter((item) => item < str);
                    imp && arr.push('5');
                    exp && arr.push('6');
                } else {
                    arr = payload.leave.filter((item) => item !== str);
                }

                payload.leave = arr;
            } else {
                let arr = [];
                let arr2 = [];
                if (str !== '5' && str !== '6') {
                    const imp = payload.leave.includes('5');
                    const exp = payload.leave.includes('6');
                    arr = array.filter((item) => item <= str);

                    arr2 = arr.map((item) => item.toString());
                    imp && arr2.push('5');
                    exp && arr2.push('6');
                    payload.leave = arr2;
                } else {
                    payload.leave.push(str);
                }
            }
        }

        if (record.key === '4' && payload && payload.attendance) {
            if (
                item &&
                item.positionDetail &&
                item.positionDetail.attendance &&
                item.positionDetail.attendance.includes(str)
            ) {
                return;
            }
            if (payload.attendance.includes(str)) {
                let arr = [];

                if (str !== '5' && str !== '6') {
                    const imp = payload.attendance.includes('5');
                    const exp = payload.attendance.includes('6');
                    arr = payload.attendance.filter((item) => item < str);
                    imp && arr.push('5');
                    exp && arr.push('6');
                } else {
                    arr = payload.attendance.filter((item) => item !== str);
                }

                payload.attendance = arr;
            } else {
                let arr = [];
                let arr2 = [];
                if (str !== '5' && str !== '6') {
                    const imp = payload.attendance.includes('5');
                    const exp = payload.attendance.includes('6');
                    arr = array.filter((item) => item <= str);

                    arr2 = arr.map((item) => item.toString());
                    imp && arr2.push('5');
                    exp && arr2.push('6');
                    payload.attendance = arr2;
                } else {
                    payload.attendance.push(str);
                }
            }
        }

        if (record.key === '5' && payload && payload.payroll) {
            if (
                item &&
                item.positionDetail &&
                item.positionDetail.payroll &&
                item.positionDetail.payroll.includes(str)
            ) {
                return;
            }
            if (payload.payroll.includes(str)) {
                let arr = [];

                if (str !== '5' && str !== '6') {
                    const imp = payload.payroll.includes('5');
                    const exp = payload.payroll.includes('6');
                    arr = payload.payroll.filter((item) => item < str);
                    imp && arr.push('5');
                    exp && arr.push('6');
                } else {
                    arr = payload.payroll.filter((item) => item !== str);
                }

                payload.payroll = arr;
            } else {
                let arr = [];
                let arr2 = [];
                if (str !== '5' && str !== '6') {
                    const imp = payload.payroll.includes('5');
                    const exp = payload.payroll.includes('6');
                    arr = array.filter((item) => item <= str);

                    arr2 = arr.map((item) => item.toString());
                    imp && arr2.push('5');
                    exp && arr2.push('6');
                    payload.payroll = arr2;
                } else {
                    payload.payroll.push(str);
                }
            }
        }

        setPayload({ ...payload });
    };

    const handleSwitch = async (str) => {
        if (
            !item ||
            (item && item.positionDetail && item.positionDetail[str] && item.positionDetail[str].length === 6) ||
            (item && item.isApproverExpense && str === 'expenseClaim' && payload[str].length === 6)
        ) {
            return;
        }
        payload && payload[str] && payload[str].length === 6
            ? setPayload({ ...payload, [str]: [] })
            : setPayload({ ...payload, [str]: ['1', '2', '3', '4', '5', '6'] });
    };

    const handleSubmit = async () => {
        try {
            if (item && item._id) {
                await employeesAPI.update(item._id, { controls: payload });
                toast.success('Update successful');
            } else {
                toast.error('Please choose employee!');
            }
        } catch (error) {
            console.log(error);
            getError(error);
        }
    };

    return (
        <>
            <Row>
                <Col>
                    <h5 className="title-font">MODULE ACCESS</h5>
                    <ul className="list_admin">
                        <li>
                            <span>Employee</span>
                            <Switch
                                checked={payload && payload.employee && payload.employee.length === 6}
                                onChange={(e) => {
                                    handleSwitch('employee');
                                }}
                            />
                        </li>
                        <li>
                            <span>Expense Claim</span>
                            <Switch
                                checked={payload && payload.expenseClaim && payload.expenseClaim.length === 6}
                                onChange={(e) => {
                                    handleSwitch('expenseClaim');
                                }}
                            />
                        </li>
                        <li>
                            <span>Leave</span>
                            <Switch
                                checked={payload && payload.leave && payload.leave.length === 6}
                                onChange={(e) => {
                                    handleSwitch('leave');
                                }}
                            />
                        </li>
                        <li>
                            <span>Attendance</span>
                            <Switch
                                checked={payload && payload.attendance && payload.attendance.length === 6}
                                onChange={(e) => {
                                    handleSwitch('attendance');
                                }}
                            />
                        </li>
                        <li>
                            <span>Payroll</span>
                            <Switch
                                checked={payload && payload.payroll && payload.payroll.length === 6}
                                onChange={(e) => {
                                    handleSwitch('payroll');
                                }}
                            />
                        </li>
                    </ul>
                </Col>
            </Row>
            <Divider style={{ borderTop: '1px solid rgb(0 0 0 / 24%)' }} />
            <Row>
                <Col>
                    <h5 className="title-font">MODULE PERMISSION</h5>

                    <Table columns={columns} dataSource={data} pagination={false} />
                    {user && user.role && user.role === ROLE.COMPANY && (
                        <div className="mt-4">
                            <button
                                onClick={handleSubmit}
                                className="ct_btn px-4 next none-border btn-middle"
                                id="save"
                            >
                                <span>Save</span>
                            </button>
                        </div>
                    )}
                </Col>
            </Row>
        </>
    );
}

export default ControlDetail;
