import axios from 'axios';

const path = 'job-positions/';

const create = async (payload) => {
    return await axios.post(path, payload);
};

const getAll = async () => {
    return axios.get(path);
};

const getOne = async (id) => {
    return axios.get(path + id);
};

const getAllById = async (id) => {
    return axios.get(path + 'company/' + id);
};

const update = async (id, payload) => {
    return await axios.patch(path + id, payload);
};

const getActive = async (id) => {
    return axios.get(path + 'active/' + id);
};

export const jobPositionAPI = { create, getAll, getAllById, getOne, update, getActive };
