export const getHour = (minutes) => {
    let hour = Math.floor(minutes / 60);
    let minute = 0;
    if (hour === 0) {
        minute = minutes;
    } else {
        minute = minutes - hour * 60;
    }

    return { hour: hour, minute: minute };
};
