import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Container, Row } from 'shards-react';
import { defaultAPI } from '../../apis/defaultAPI';

function Register() {
    const initAccount = {
        email: '',
        password: '',
        confirmPassword: '',
    };

    const history = useHistory();

    const [account, setAccount] = useState(initAccount);

    const validate = () => {
        const confirmPassword = document.getElementById('confirmPassword').value;
        if (confirmPassword !== account.password) {
            alert('Your confirm password not match password field!');
        } else {
            onFinish(account);
        }
    };

    const onFinish = async (values) => {
        try {
            const { data } = await defaultAPI.register(values);
            if (data._id) {
                toast.success('Register successfully!');
                history.push('/login');
            } else {
                toast.error('Register fail, please check your account!');
            }
        } catch (error) {
            console.log(error);
            toast.error(error);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <Container fluid>
            <Row>
                <Col className=" content-login" xl="4" lg="4" md="12" sm="12">
                    <div className="wrapper">
                        <div className="avt_prof">
                            <div className="avt"></div>
                            <h6>Sign up</h6>
                        </div>
                        {/* <div className="login_link">
              <a href="#google">
                <img src="./img/google-icon.png" alt="icon" />
                Google
              </a>
              <a href="#facebook">
                <img src="./img/facebook-icon.png" alt="icon" />
                Facebook
              </a>
            </div> */}

                        <Form
                            layout="vertical"
                            name="basic"
                            initialValues={{
                                remember: true,
                            }}
                            // onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Email Address"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email!',
                                    },
                                ]}
                            >
                                <Input
                                    value={account.email}
                                    onChange={(e) => setAccount({ ...account, email: e.target.value })}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                ]}
                            >
                                <Input.Password
                                    value={account.password}
                                    onChange={(e) => setAccount({ ...account, password: e.target.value })}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Confirm Password"
                                name="confirmPassword"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your confirm password!',
                                    },
                                ]}
                            >
                                <Input.Password
                                    id="confirmPassword"
                                    value={account.confirmPassword}
                                    onChange={(e) => setAccount({ ...account, confirmPassword: e.target.value })}
                                />
                            </Form.Item>

                            {/* <Form.Item
                name="remember"
                valuePropName="checked"
                className="ssss"
              >
                <Checkbox>Remember me</Checkbox>
                <span>Reset password ?</span>
              </Form.Item> */}
                            <Form.Item name="remember" valuePropName="checked" className="ssss">
                                <span>
                                    Already a member? <a href="/login">Login here</a>
                                </span>
                            </Form.Item>

                            <Button
                                type="warning"
                                htmlType="submit"
                                style={{
                                    width: '100%',
                                    backgroundColor: '#FFD96C',
                                }}
                                onClick={validate}
                            >
                                Register
                            </Button>
                        </Form>
                    </div>
                </Col>
                <Col className="p-0" xl="8" lg="8" md="12" sm="12">
                    <img
                        style={{
                            width: '100%',
                            objectFit: 'cover',
                        }}
                        src="./img/bg.jpg"
                        alt="bg_login"
                    />
                </Col>
            </Row>
        </Container>
    );
}

export default Register;
