import React from 'react';
import FilterLeave from './FilterLeave';
import TableLeaveHistory from './TableLeaveHistory';

function TabLeaveHistory({ tabSelected, value, setRender, setValue }) {
    return (
        <div>
            <FilterLeave tabSelected={tabSelected} />

            <TableLeaveHistory value={value} setRender={setRender} setValue={setValue} />
        </div>
    );
}

export default TabLeaveHistory;
