import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'shards-react';

import PageTitle from '../common/PageTitle';

import { DatePicker } from 'antd';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { employeesAPI } from '../../apis/employeesAPI';
import IMG from '../../images/avatars/user.png';
import TableViewAttendance from './TableViewAttendance';

function ViewAttendance() {
    const [employee, setEmployee] = useState([]);
    const [attendances, setAttendances] = useState([]);
    const [date, setDate] = useState();
    const [valueSelect, setValueSelect] = useState();
    const [dates, setDates] = useState(null);
    const { RangePicker } = DatePicker;
    const { Option } = Select;
    const [reload, setReload] = useState(0);

    let { id } = useParams();

    const disabledDateWeek = (current, type) => {
        if (!dates) {
            return false;
        }
        if (type === 'day') return false;
        if (type === 'week') {
            const tooLate = dates[0] && current.diff(dates[0], 'days') > 7;
            const tooEarly = dates[1] && dates[1].diff(current, 'days') > 7;
            return !!tooEarly || !!tooLate;
        }
        if (type === 'month') {
            const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
            const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
            return !!tooEarly || !!tooLate;
        }
    };

    useEffect(() => {
        (async () => {
            if (id) {
                const { data } = await employeesAPI.getById(id);
                if (data) {
                    setEmployee(data[0]);
                    setAttendances(data[0].attendance);
                }
            }
        })();
    }, [id, reload]);

    const submitSearch = async () => {
        if (date && date[0] && date[1]) {
            let dayStart = moment(date[0]).format('YYYY-MM-DD');
            let dayEnd = moment(date[1]).format('YYYY-MM-DD');
            const { data } = await employeesAPI.getById(id, { dayStart, dayEnd });
            setAttendances(data[0].attendance);
        }
    };

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <Col lg="6">
                    <PageTitle
                        title="Employee attendance"
                        subtitle={
                            <>
                                <span className="route-extra">Attendance / Employee attendance / </span>
                                <span className="route-main">
                                    {employee.lastName} {employee.firstName}
                                </span>
                            </>
                        }
                        className="text-sm-left mb-3 "
                    />
                </Col>
            </Row>
            <Card small>
                <CardHeader className="border-bottom">
                    <div>
                        <span className="ct-title">Employee information</span>
                    </div>
                </CardHeader>
                <div className="px-3">
                    <Row lg="12" sm="6" md="8" xl="12">
                        <Col
                            xl="3"
                            lg="6"
                            md="6"
                            style={{
                                backgroundColor: '#0c415b',
                            }}
                            className="border-right"
                        >
                            <div className="col p-0">
                                <div className=" widget-flat h-100  ">
                                    <div className="card-body ">
                                        <div className="content_">
                                            <div className="img_content">
                                                <img
                                                    src={
                                                        employee && employee.user && employee.user.avatar
                                                            ? employee.user.avatar
                                                            : IMG
                                                    }
                                                    alt="img"
                                                />
                                            </div>
                                            <div className="title_content">
                                                <span>
                                                    {employee.lastName} {employee.firstName}
                                                </span>
                                                <span>{employee.jobPosition}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xl="3" lg="6" md="6" className="border-right d-flex align-items-center">
                            <div className="col p-0">
                                <div className=" widget-flat h-100">
                                    <div className="card-body statistic-title">
                                        <div className="float-end">
                                            <i className="mdi mdi-account-multiple widget-icon" />
                                        </div>
                                        <span title="Number of Customers">Employee ID</span>
                                        <span>{employee && employee.employeesId}</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xl="3" lg="6" md="6" className="border-right d-flex align-items-center">
                            <div className="col p-0">
                                <div className=" widget-flat h-100">
                                    <div className="card-body statistic-title">
                                        <div className="float-end">
                                            <i className="mdi mdi-account-multiple widget-icon" />
                                        </div>
                                        <span title="Number of Customers">Department</span>
                                        <span>{employee && employee.jobPosition}</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xl="3" lg="6" md="6" className="border-right d-flex align-items-center">
                            <div className="col p-0">
                                <div className=" widget-flat h-100">
                                    <div className="card-body statistic-title">
                                        <div className="float-end">
                                            <i className="mdi mdi-account-multiple widget-icon" />
                                        </div>
                                        <span title="Number of Customers">Joining Date</span>
                                        <span>{employee && moment(employee.createdAt).format('DD MMMM YYYY')}</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Card>

            <Card style={{ marginTop: 30 }}>
                <CardHeader className="border-bottom">
                    <div>
                        <span className="ct-title">Attendance</span>
                    </div>
                </CardHeader>
                <CardBody>
                    <Row form className="mt-1 ">
                        <Col md="2" className="form-group">
                            <Select
                                defaultValue={'day'}
                                style={{ width: 120, marginRight: 10 }}
                                onChange={(value) => setValueSelect(value)}
                            >
                                <Option value="day">Day</Option>
                                <Option value="week">Weekly</Option>
                                <Option value="month">Monthly</Option>
                            </Select>
                        </Col>
                        <Col md="3" className="form-group">
                            <RangePicker
                                disabledDate={(current) => disabledDateWeek(current, valueSelect)}
                                onCalendarChange={(val) => setDates(val)}
                                onChange={(e) => setDate(e)}
                            />
                        </Col>
                        <Col md="7" className="d-flex justify-content-end align-items-start">
                            <Button type="submit" className=" btn-warning px-5" onClick={submitSearch}>
                                Search
                            </Button>
                        </Col>
                    </Row>
                    <TableViewAttendance tableData={attendances} setTableData={setAttendances} setReload={setReload} />
                </CardBody>
            </Card>
        </Container>
    );
}

export default ViewAttendance;
