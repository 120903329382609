import React from 'react';

function NavLanguage() {
    return (
        <div>
            {/* className="d-flex justify-content-center align-items-center border-right content_item">
            <span style={{
                fontWeight: 600
            }}>EN</span>
            <ArrowDropDownIcon /> */}
        </div>
    );
}

export default NavLanguage;
