import { Form, Input, Modal, Space, Table, Tag, Select, DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'shards-react';
import { LEAVE_STATUS, LEAVE_TYPE } from '../../global/leave';
import { FORMAT, FORM_TYPE, ROLE, EMPLOYEES_TYPE_SUMMARY_PAYROLL } from '../../helpers/const';
import { useUser } from '../../hooks';
import { useLeave } from '../../hooks/useLeave';
import IMG_AVT from '../../images/avatars/0.jpg';
import LeaveCreateForm from './leaveCreateForm';
import { leavesSettingAPI } from '../../apis/leavesSettingAPI';
import { leaveAPI } from '../../apis/leaveAPI';
import { toast } from 'react-toastify';
import { getError } from '../../helpers/getErrorServer';
import { useLoading } from '../../hooks';

const TableSummaryLeave = ({ setRender, value, setValue }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [dataTable, setDataTable] = useState([]);
    const [leaveSelected, setLeaveSelected] = useState();
    const [visible, setVisible] = useState();
    const [type, setType] = useState([]);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState();
    const [leaves, setLeaves] = useState();
    const { TextArea } = Input;
    const { Option } = Select;
    const { RangePicker } = DatePicker;
    const { user, getMe } = useUser();
    const { setLoading } = useLoading();
    const initColumns = [
        {
            title: 'ID',
            render: (_, row) => {
                return (
                    <div className="content_td_mobile_db d-flex flex-start align-items-center">
                        <p className="title_mobile">
                            ID
                            {/* <RiArrowUpDownFill /> */}
                        </p>
                        <p>{row && row.employeesId ? row.employeesId : "--"}</p>
                    </div>
                );
            },
            // sorter: (record1, record2) => {
            //     return record1.employee.firstName > record2.employee.firstName;
            // },
        },
        {
            title: 'Employee Name',
            render: (_, row) => {
                return (
                    <div className="content_td_mobile_db d-flex flex-start align-items-center">
                        <p className="title_mobile">
                            Employee Name
                            {/* <RiArrowUpDownFill /> */}
                        </p>
                        <div className="avt_leave_requ mr-3">
                            <img src={row && row.user && row.user.avatar ? row.user.avatar : IMG_AVT} alt="" />
                        </div>
                        <p>{row && row.firstName ? row.firstName : "Can't get first name employee"}</p>
                    </div>
                );
            },
            // sorter: (record1, record2) => {
            //     return record1.employee.firstName > record2.employee.firstName;
            // },
        },
        // {
        //     title: 'Bank account',
        //     dataIndex: 'bankAccount',
        //     key: 'bankAccount',
        //     render: (_, record) => (
        //         <p>{record.employee.payment.bankAccount ? record.employee.payment.bankAccount : '--'}</p>
        //     ),
        // },
        // {
        //     title: 'NRIC No',
        //     dataIndex: 'NRIC',
        //     key: 'NRIC',
        //     render: (_, record) => (
        //         <div className="content_td_mobile">
        //             <p className="title_mobile">NRIC No</p>
        //             <p>{record ? record.nationId : '--'}</p>
        //         </div>
        //     ),
        // },
        {
            title: 'Joined Date',
            dataIndex: 'JoinedDate',
            key: 'JoinedDate',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Joined Date</p>
                    <p>
                        {record
                            ? record.dateJoin
                                ? moment(new Date(new Date(record.dateJoin).setHours(12, 0, 0, 0))).format(FORMAT.DATE)
                                : '--'
                            : '--'}
                    </p>
                </div>
            ),
            sorter: (record1, record2) => {
                return record1.dateJoin > record2.dateJoin;
            },
        },
        {
            title: 'Resigned Date',
            dataIndex: 'ResignedDate',
            key: 'ResignedDate',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Resigned Date</p>
                    <p>
                        {record
                            ? record.resignedDate
                                ? moment(new Date(new Date(record.resignedDate).setHours(12, 0, 0, 0))).format(
                                      FORMAT.DATE,
                                  )
                                : '--'
                            : '--'}
                    </p>
                </div>
            ),
            sorter: (record1, record2) => {
                return record1.resignedDate > record2.resignedDate;
            },
        },
        {
            title: 'AL B/F From Last Year',
            dataIndex: 'ALBFFromLastYear',
            key: 'ALBFFromLastYear',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">AL B/F From Last Year</p>
                    <p>{record.leaveInfo.ALBFFromLastYear ? record.leaveInfo.ALBFFromLastYear.toFixed() : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Entitled Leave For This Year',
            dataIndex: 'EntitledLeaveForThisYear',
            key: 'EntitledLeaveForThisYear',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Entitled leave for This Year</p>
                    <p>{record.leaveInfo.entitledLeaveForThisYear ? record.leaveInfo.entitledLeaveForThisYear.toFixed() : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Any Additions',
            dataIndex: 'AnyAdditions',
            key: 'AnyAdditions',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Any Additions</p>
                    <p>{record.leaveInfo.anyAdditionsLeaves ? record.leaveInfo.anyAdditionsLeaves.toFixed() : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Leave Use In This Year',
            dataIndex: 'LeaveUseInThisYear',
            key: 'LeaveUseInThisYear',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Leave Use in This Year</p>
                    <p>{record.leaveInfo.leaveUseInThisYear ? record.leaveInfo.leaveUseInThisYear.toFixed() : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Leave Expired',
            dataIndex: 'LeaveExpired',
            key: 'LeaveExpired',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Leave expired</p>
                    <p>{record.leaveInfo.leaveExpired ? record.leaveInfo.leaveExpired.toFixed() : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Total Leave For This Year',
            dataIndex: 'TotalLeaveForThisYear',
            key: 'TotalLeaveForThisYear',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Total Leave For This Year</p>
                    <p>{record.leaveInfo.totalLeaveForThisYear ? record.leaveInfo.totalLeaveForThisYear.toFixed() : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Sick Leave',
            dataIndex: 'SickLeave',
            key: 'SickLeave',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Sick Leave</p>
                    <p>{record.leaveInfo.mcLeave ? record.leaveInfo.mcLeave : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Sick Leave Use',
            dataIndex: 'SickLeaveUse',
            key: 'SickLeaveUse',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Sick Leave Use</p>
                    <p>{record.leaveInfo.leaveMCUseInThisYear ? record.leaveInfo.leaveMCUseInThisYear : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Sick Leave Left',
            dataIndex: 'SickLeaveLeft',
            key: 'SickLeaveLeft',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Sick Leave Left</p>
                    <p>{record.leaveInfo.leftMC ? record.leaveInfo.leftMC : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Hosp Leave',
            dataIndex: 'HospLeave',
            key: 'HospLeave',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Hosp Leave</p>
                    <p>{record.leaveInfo.hospLeave ? record.leaveInfo.hospLeave : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Hosp Leave Use',
            dataIndex: 'HospLeaveUse',
            key: 'HospLeaveUse',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Hosp Leave Use</p>
                    <p>{record.leaveInfo.leaveHospUseInThisYear ? record.leaveInfo.leaveHospUseInThisYear : '--'}</p>
                </div>
            ),
        },
        {
            title: 'Hosp Leave Left',
            dataIndex: 'HospLeaveLeft',
            key: 'HospLeaveLeft',
            render: (_, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Hosp Leave Left</p>
                    <p>{record.leaveInfo.leftHosp ? record.leaveInfo.leftHosp : '--'}</p>
                </div>
            ),
        },
    ];
    const [columns, setColumns] = useState(initColumns);

    useEffect(() => {
        try {
            getType();
        } catch (error) {}
    }, [user]);

    const getType = async () => {
        getAllForSummaryLeaves();
        if (!user) {
            getMe();
        }

        let id = '';
        if (user && user.company && user.company._id) {
            id = user.company._id;
        }
        if (user && user.employee && user.employee.company && user.employee.company._id) {
            id = user.employee.company._id;
        }

        if (id !== '') {
            const { data } = await leavesSettingAPI.getByCompany(id);
            setType(data);
        }
    };

    useEffect(() => {
        if (leaves) {
            let tmp = [];
            leaves.items.forEach((item, i) => {
                tmp.push({
                    ...item,
                    key: i,
                    page: leaves.paginate.page,
                });
            });
            setDataTable(tmp);
            console.log(leaves);
        }
    }, [leaves]);

    const getAllForSummaryLeaves = async () => {
        try {
            setLoading(true);
            if (user && user.role === ROLE.COMPANY && user.company && user.company._id) {
                const { data } = await leaveAPI.getAllForSummaryLeaves({ ...filter, company: user.company._id });
                setLeaves(data);
            } else if (user && user.role === ROLE.COMPANYSTAFF && user.employee && user.employee._id) {
                const { data } = await leaveAPI.getAllForSummaryLeaves({ ...filter, company: user.employee.companyID });
                setLeaves(data);
            } else if (user && user.employee && user.employee._id) {
                const { data } = await leaveAPI.getAllForSummaryLeaves({ ...filter, employee: user.employee._id });
                setLeaves(data);
            } else {
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error(getError(error));
        }
    };

    const onSearch = async () => {
        getAllForSummaryLeaves();
    };

    const onChangePage = (page) => {
        setPage(page);
        const filter = { ...filter, page };
        // setFilterLeave(filter);
        // fetchLeaves(filter);
    };
    useEffect(() => {
        (async () => {
            onSearch();
        })();
    }, [filter]);
    const onChangeType = (value) => {
        setFilter({ ...filter, type: value });
    };
    return (
        <>
            {user.role === ROLE.COMPANY || user.role === ROLE.COMPANYSTAFF ? (
                <>
                    <Row className="mt-1">
                        <Col className="form-group" lg="3" md="3">
                            <div>
                                <Input
                                    className="ct-input"
                                    placeholder="Search by item name"
                                    // value={filter.search}
                                    onChange={(e) => setFilter({ ...filter, search: e.target.value })}
                                    onKeyPress={(event) => {
                                        if (event.key === 'Enter') {
                                            onSearch();
                                        }
                                    }}
                                />
                            </div>
                        </Col>

                        <Col className="form-group" md="3">
                            <div>
                                <Select
                                    defaultValue=""
                                    // value={filter.type}
                                    onChange={(e) => onChangeType(e)}
                                    style={{ width: '100%' }}
                                >
                                    <Option value="">All</Option>
                                    {EMPLOYEES_TYPE_SUMMARY_PAYROLL &&
                                        EMPLOYEES_TYPE_SUMMARY_PAYROLL.map((item) => (
                                            <Option key={item.value} value={item.value}>
                                                {item.label}
                                            </Option>
                                        ))}
                                </Select>
                            </div>
                        </Col>
                        <Col md="1" className="d-flex justify-content-end align-items-start">
                            <div>
                                <button type="submit" className="button__search" onClick={onSearch}>
                                    Search
                                </button>
                            </div>
                        </Col>
                        {/* <Col className="form-group" md="3">
                    <div>
                        <RangePicker
                            onChange={(date) => {
                                setFilter({
                                    ...filter,
                                    from: date && date[0] ? date[0]._d : null,
                                    to: date && date[1] ? date[1]._d : null,
                                });
                            }}
                            format={FORMAT.DATE}
                        />
                    </div>
                </Col> */}
                    </Row>
                </>
            ) : (
                <></>
            )}
            <Table
                className="ct-table-data"
                columns={columns}
                dataSource={dataTable}
                pagination={{
                    onChange: onChangePage,
                    pageSize: leaves && leaves.paginate && leaves.paginate.size ? leaves.paginate.size : 10,
                    total: leaves && leaves.paginate ? leaves.paginate.count : 0,
                }}
            />
        </>
    );
};

export default TableSummaryLeave;
