import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Space, TimePicker } from 'antd';
import moment from 'moment';
import { FaRegCalendar } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, Row } from 'shards-react';
import { attendanceAPI } from '../../apis/attendanceAPI';

import { useAttendance, useUser } from '../../hooks';

import { getError } from '../../helpers/getErrorServer';

import { BREAK_TIME, ROLE } from '../../helpers/const';
import { attendanceSettingAPI } from '../../apis/attendanceSettingAPI';
import { publicHolidayAPI } from '../../apis/publicHolidayAPI';

const init = {
    date: null,
    checkInTime: null,
    checkOutTime: null,
    overtime: null,
};

function FilterAttendance() {
    const [attendence, setAttendance] = useState(init);
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState();
    const [form] = Form.useForm();
    const { user, getMe } = useUser();
    const { fetchAttendance } = useAttendance();
    const [disableOvertime, setDisableOvertime] = useState(false);

    const onChangeDate = (date, dateString) => {
        setDate(dateString);
        setAttendance({ ...attendence, date: date });
        setOpen(false);
    };
    const onChangeTimeIn = (time, timeString) => {
        setAttendance({ ...attendence, checkInTime: time });
    };
    const onChangeTimeOut = (time, timeString) => {
        setAttendance({ ...attendence, checkOutTime: time });
    };
    const onChangeOverTime = (time, timeString) => {
        setAttendance({ ...attendence, overtime: time });
    };

    const validate = (data) => {
        if (!data.date) {
            toast.error('Please enter date');
            return false;
        }
        if (!data.checkInTime && !data.checkOutTime) {
            toast.error('Please enter check-in time or check-out time');
            return false;
        }

        if (data.checkInTime && data.checkOutTime) {
            toast.error('Can only check-in time or check-out time');
            return false;
        }

        if (data.overtime && new Date(data.checkOutTime).getTime() > new Date(data.overtime).getTime()) {
            toast.error('Over time must be greater than time out');
            return false;
        }

        return true;
    };

    const handleSubmit = async () => {
        if (validate(attendence)) {
            try {
                let isWeekendOrHoliday = false;
                let isSaturday = false;
                const { data: holidayList } = await publicHolidayAPI.getAll({ companyId: user.employee.company._id });

                if (holidayList && holidayList.items && holidayList.items.length > 0) {
                    holidayList.items.map((e) => {
                        if (
                            new Date(attendence.date).getTime() >= e.startDay &&
                            new Date(attendence.date).getTime() <= e.endDay
                        ) {
                            isWeekendOrHoliday = true;
                        }
                    });
                }

                const { data: attendanceSettingList } = await attendanceSettingAPI.getByCompany(
                    user.employee.company._id,
                );

                const workingDays = attendanceSettingList[0].workingDays;
                if (!workingDays) {
                    toast.error("Haven't setting number of working days in a week!");
                    return;
                }
                if (
                    new Date(attendence.date).getDay() === 0 ||
                    (new Date(attendence.date).getDay() === 6 && workingDays < 5.5)
                ) {
                    isWeekendOrHoliday = true;
                }

                if (new Date(attendence.date).getDay() === 6 && workingDays === 5.5) {
                    isSaturday = true;
                }

                await attendanceAPI.add({
                    ...attendence,
                    employeesId: user.employee._id,
                    isWeekendOrHoliday,
                    isSaturday,
                });

                toast.success('Add attendence success!');
                setAttendance({
                    date: null,
                    checkInTime: null,
                    checkOutTime: null,
                    overtime: null,
                });
                setDate(null);
                fetchAttendance();
                form.resetFields();
            } catch (error) {
                console.log(error);
                toast.error(getError(error));
            }
        }
    };

    useEffect(() => {
        (async () => {
            if (!user) {
                getMe();
            }
            if (user.role === ROLE.COMPANY) {
                return;
            }

            if (user && user.employee && user.employee._id && user.employee.company && user.employee.company._id) {
                const { data: setting } = await attendanceSettingAPI.getByCompany(user.employee.company._id);

                if (
                    +new Date(attendence.checkOutTime).getHours() <= BREAK_TIME &&
                    !(
                        new Date(attendence.date).getDay() === 6 &&
                        setting &&
                        setting[0] &&
                        setting[0].workingDays &&
                        setting[0].workingDays === 5.5
                    )
                ) {
                    setDisableOvertime(true);
                } else {
                    setDisableOvertime(false);
                }
            }
        })();
    }, [user, attendence.date, attendence.checkOutTime]);

    return (
        <Card>
            <CardHeader className="border-bottom">
                <span className="ct-title">Attendance Mark</span>
            </CardHeader>
            <CardBody>
                <Form form={form}>
                    <div className="add-attendence-box">
                        <Form.Item name="date" style={{ position: 'absolute', opacity: 0 }}>
                            <DatePicker
                                disabled={user.role === ROLE.COMPANY}
                                id="data-picker"
                                open={open}
                                onChange={onChangeDate}
                                style={{ position: 'absolute', top: '55px' }}
                            />
                        </Form.Item>
                        <Space direction="vertical" style={{ width: '100%', position: 'relative' }}>
                            <div className="d-flex justify-content-between align-items-center calender-box">
                                <div className="date-input">
                                    <span>{date && moment(date).format('DD, MMM, YYYY')}</span>
                                    <span>{date && moment(date).format('dddd')}</span>
                                </div>
                                <FaRegCalendar
                                    style={{ cursor: 'pointer' }}
                                    size={35}
                                    color="#0C415B"
                                    onClick={() => {
                                        setOpen(!open);
                                    }}
                                />
                            </div>
                        </Space>
                        <Row className="mt-4" lg="12" md="6">
                            <Col lg="6">
                                <Form.Item name="checkInTime">
                                    <TimePicker
                                        //defaultValue={null}
                                        disabled={user.role === ROLE.COMPANY}
                                        format={'HH:mm'}
                                        onChange={onChangeTimeIn}
                                        style={{ width: '100%', borderRadius: '5px', height: '32px' }}
                                        placeholder="Time in"
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg="6">
                                <Form.Item name="checkOutTime">
                                    <TimePicker
                                        disabled={user.role === ROLE.COMPANY}
                                        format={'HH:mm'}
                                        onChange={onChangeTimeOut}
                                        placeholder="Time out"
                                        style={{ width: '100%', borderRadius: '5px', height: '32px' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg="6">
                                <Form.Item name="overtime">
                                    <TimePicker
                                        disabled={user.role === ROLE.COMPANY || disableOvertime}
                                        format={'HH:mm'}
                                        onChange={onChangeOverTime}
                                        style={{ width: '100%', borderRadius: '5px', height: '32px' }}
                                        placeholder="Overtime"
                                    />
                                </Form.Item>
                            </Col>
                            <Col lg="6">
                                <Button block type="text" className="btn-yeallow" onClick={handleSubmit}>
                                    Mark Attendance
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </CardBody>
        </Card>
    );
}

export default FilterAttendance;
