import axios from 'axios';
import { cleanObject } from '../helpers/cleanObject';
const path = 'employees/';

const get = async (filter = {}) => {
    let searchParams = new URLSearchParams(cleanObject(filter));
    return await axios.get(path + 'all?' + searchParams.toString());
};

const getAll = async () => {
    return await axios.get(path);
};

const getAllCompany = async (id) => {
    return await axios.get(path + 'all-company/' + id);
};

const getAllEmployeeOfCompany = async (id) => {
    return await axios.get(path + 'get-all-employees-company/' + id);
};

const findEmployeeForSheet = async (filter = {}) => {
    let searchParams = new URLSearchParams(cleanObject(filter));
    return await axios.get(path + 'sheet?' + searchParams.toString());
};

const getByCompany = async (id, filter = {}) => {
    let searchParams = new URLSearchParams(cleanObject(filter));
    return await axios.get(path + 'companies/' + id + '?' + searchParams.toString());
};

const getById = async (id, filter = {}) => {
    let searchParams = new URLSearchParams(cleanObject(filter));
    return await axios.get(path + id + '?' + searchParams);
};

const findById = async (id) => {
    return await axios.get(path + 'findByID/' + id);
};

const changePasswordByAdmin = async (id, payload) => {
    return await axios.patch(path + 'change-password-by-admin/' + id, payload);
};

const resetPasswordByAdmin = async (email) => {
    return await axios.patch(path + 'reset-password-by-admin/' + email);
};

const filterEmployees = async () => {
    return await axios.get(path + 'filter-employee');
};

const add = async (payload) => {
    return await axios.post(path, payload);
};

const update = async (id, payload) => {
    return await axios.patch(path + id, payload);
};

const updateProfile = async (id, payload) => {
    return await axios.patch(path + 'profile/' + id, payload);
};

const _delete = async (id) => {
    return await axios.delete(path + id);
};

const percent = async (filter = {}) => {
    return await axios.get(path + 'percent/', { params: filter });
};

const birthday = async (filter = {}) => {
    return await axios.get(path + 'birthday/', { params: filter });
};

const attendance = async (filter = {}) => {
    return await axios.get(path + 'attendance-today/', { params: filter });
};

const statisticDashboard = async (filter = {}) => {
    return await axios.get(path + 'statisticDashboard/', { params: filter });
};

const setApproverExpense = async (id) => {
    return await axios.get(path + 'approver-expense/' + id);
};

const removeApproverExpense = async (id) => {
    return await axios.get(path + 'remove-approver-expense/' + id);
};

const setApproverLeave = async (id) => {
    return await axios.get(path + 'approver-leave/' + id);
};

const removeApproverLeave = async (id) => {
    return await axios.get(path + 'remove-approver-leave/' + id);
};

const getApprover = async (id, filter = {}) => {
    return await axios.get(path + 'approver/' + id, { params: filter });
};

const getSameDepartment = async (id, der) => {
    return await axios.get(path + 'same-department/' + id + '/' + der);
};

const getDepartment = async (id) => {
    return await axios.get(path + 'department/' + id);
};

const setDepartment = async (id, department) => {
    return await axios.get(`${path}set-department/${id}/${department}`);
};

const chooseStaff = async (id) => {
    return await axios.get(path + 'choose-staff/' + id);
};

const verifyEmail = async (email) => {
    return await axios.get(path + 'verify/' + email);
};

const findSearchEmployeesOfCompany = async (id, search) => {
    return await axios.get(path + 'findSearchEmployeesOfCompany/' + id + '?search=' + search);
};

export const employeesAPI = {
    get,
    getByCompany,
    filterEmployees,
    findEmployeeForSheet,
    getById,
    add,
    update,
    _delete,
    percent,
    birthday,
    statisticDashboard,
    getAll,
    getAllCompany,
    setApproverExpense,
    getApprover,
    removeApproverExpense,
    setApproverLeave,
    removeApproverLeave,
    getDepartment,
    setDepartment,
    getSameDepartment,
    updateProfile,
    chooseStaff,
    attendance,
    changePasswordByAdmin,
    resetPasswordByAdmin,
    getAllEmployeeOfCompany,
    findSearchEmployeesOfCompany,
    findById,
    verifyEmail,
};
