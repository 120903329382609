import { Input, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Form, FormGroup, FormInput, ListGroup, ListGroupItem, Row } from 'shards-react';
import { companiesAPI } from '../../apis/companiesAPI';
import { countriesAPI } from '../../apis/countriesAPI';
import { countryAPI } from '../../apis/countryAPI';
import { historiesAPI } from '../../apis/historiesAPI';
import { stateAPI } from '../../apis/state.api';
import { ROLE, TYPE_NOTIFICATION } from '../../helpers/const';
import { getError } from '../../helpers/getErrorServer';
import { useLoading, useUser } from '../../hooks';

const initCompany = {
    companyName: '',
    contactPerson: '',
    address: '',
    country: '',
    city: '',
    state: '',
    postalCode: '',
    email: '',
    phoneNumber: '',
    mobileNumber: '',
    fax: '',
    websiteUrl: '',
};

export default function FormCompaines() {
    const [company, setCompany] = useState(initCompany);
    const [isModalOpenCountry, setIsModalOpenCountry] = useState(false);
    // const [countryNames, setCountryNames] = useState([]);
    // const [countryName, setCountryName] = useState('');
    const [load, setReload] = useState(0);
    const { id } = useParams();
    const history = useHistory();
    const [countries, setCountries] = useState([]);
    const { Option } = Select;
    const [codeCountry, setCodeCountry] = useState();
    const [state, setState] = useState([]);

    const { user } = useUser();
    const { setLoading } = useLoading();

    // const regexPhone = /^\d+$/;
    // const regFirstName = /^[a-zA-Z0-9._-\s]{0,30}$/;
    // const regCompanyName = /^[a-zA-Z0-9._-\s]{0,50}$/;
    //const regUrl = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

    const handleCancelCountry = () => {
        setIsModalOpenCountry(false);
    };

    const fetchCompany = async (id) => {
        try {
            const { data } = await companiesAPI.getById(id);
            setCompany(data);
            setCodeCountry(data.country);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        (async () => {
            // const { data } = await countriesAPI.getCountries();
            // setCountries(data);
            const { data } = await countryAPI.getAll();
            setCountries(data.items);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            // const { data } = await countriesAPI.getStates(codeCountry);
            // setState(data);
            const { data } = await stateAPI.getByCountry(codeCountry);
            setState(data.items);
        })();
    }, [codeCountry]);

    useEffect(() => {
        if (id) {
            fetchCompany(id);
        }
        // getCountry();
    }, [load]);

    const onSubmit = () => {
        if (id) {
            editCompany(id);
        } else {
            addCompany();
        }
    };

    const validate = (data) => {
        const regexEmail = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm;
        //const regexPhone = /^\d+$/;
        if (data.companyName === '') {
            toast.error('Company name should not be empty!');
            return false;
        }

        if (!regexEmail.test(company.email)) {
            toast.error('Please enter the correct email format!');
            return false;
        }

        if (data.country === '') {
            toast.error('Country should not be empty!!');
            return false;
        }

        return true;
    };

    async function addCompany() {
        try {
            setLoading(true);
            if (validate(company)) {
                const payload = {
                    ...company,
                    companyName: company.companyName.trim(),
                    contactPerson: company.contactPerson.trim(),
                    address: company.address.trim(),
                    websiteUrl: company.websiteUrl.trim(),
                };
                const result = await companiesAPI.add(payload);
                if (result && result.data && result.data._id) {
                    await historiesAPI.add({
                        title: 'COMPANY',
                        desc: `${user.firstName} just add a new company`,
                        type: TYPE_NOTIFICATION.COMPANY,
                        idRequest: result.data._id,
                        toAdmin: true,
                    });
                }
                setLoading(false);
                toast.success('Add success!');
                history.push('/companies');
            } else {
                setLoading(false);
                history.push('/create-company');
            }
        } catch (error) {
            setLoading(false);
            const message = error.response.data.message;
            if (message && typeof message === 'string') {
                toast.error(<p className="text-capitalize mt-3">{message}</p>);
            } else if (message && typeof message === 'object') {
                toast.error(<p className="text-capitalize mt-3">{message[0]}</p>);
            }
        }
    }

    async function editCompany(id) {
        if (validate(company)) {
            try {
                const payload = {
                    ...company,
                    companyName: company.companyName.trim(),
                    contactPerson: company.contactPerson.trim(),
                };

                await companiesAPI.update(id, payload);
                toast.success('Updated successfully!');

                if (user && user.role && user.role === ROLE.SYSTEMSTAFF && user.firstName) {
                    await historiesAPI.add({
                        title: 'UPDATE INFO',
                        desc: `${user.firstName} just change info of company ${payload.companyName}`,
                        type: TYPE_NOTIFICATION.COMPANY,
                        idRequest: id,
                        toAdmin: true,
                    });
                }

                history.push('/companies');
            } catch (error) {
                const message = error.response.data.message;
                if (message && typeof message === 'string') {
                    toast.error(<p className="text-capitalize mt-3">{message}</p>);
                } else if (message && typeof message === 'object') {
                    toast.error(<p className="text-capitalize mt-3">{message[0]}</p>);
                }
            }
        }
    }

    // const handleOkCountry = async () => {
    //     try {
    //         if (countryName) {
    //             await countryAPI.create({ countryName });
    //         }
    //         setReload((e) => e + 1);
    //         setIsModalOpenCountry(false);
    //     } catch (error) {
    //         toast.error(getError(error));
    //     }
    // };

    const cancel = () => {
        history.push('/');
    };

    return (
        <ListGroup flush>
            <ListGroupItem className="p-3">
                <Row>
                    <Col>
                        <Form>
                            <Row form>
                                <Col md="6" className="form-group">
                                    <label htmlFor="feCompanieName" className="title">
                                        <span>*</span> Company Name
                                    </label>
                                    <FormInput
                                        id="feCompanieName"
                                        type="text"
                                        placeholder="Dreamguy's Technologies"
                                        value={company.companyName}
                                        onChange={(e) => {
                                            // if (!regCompanyName.test(e.target.value)) {
                                            //     toast.error('Cannot enter special characters');
                                            //     return;
                                            // }
                                            setCompany({ ...company, companyName: e.target.value });
                                        }}
                                    />
                                </Col>
                                <Col md="6">
                                    <label htmlFor="feContactPerson" className="title">
                                        Contact Person
                                    </label>
                                    <FormInput
                                        id="feContactPerson"
                                        type="text"
                                        placeholder="Daniel Porter"
                                        value={company.contactPerson}
                                        onChange={(e) => {
                                            // if (!regFirstName.test(e.target.value)) {
                                            //     toast.error('Cannot enter special characters');
                                            //     return;
                                            // }
                                            setCompany({ ...company, contactPerson: e.target.value });
                                        }}
                                    />
                                </Col>
                            </Row>

                            <FormGroup className="mt-4">
                                <label htmlFor="feInputAddress" className="title">
                                    Address
                                </label>
                                <FormInput
                                    id="feInputAddress"
                                    type="text"
                                    placeholder="3864 Quiet Valley Lane, Sherman Oaks, CA, 91403"
                                    value={company.address}
                                    onChange={(e) => setCompany({ ...company, address: e.target.value })}
                                />
                            </FormGroup>

                            <Row form className="mt-4">
                                <Col md="3" className="form-group">
                                    <label htmlFor="feCountry" className="title">
                                        <span>*</span> Country
                                    </label>

                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        value={company.country}
                                        onChange={(value) => {
                                            setCompany({ ...company, country: value, state: '' });
                                            setCodeCountry(value);
                                        }}
                                        id="feCountry"
                                        placeholder="Search to Select"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {countries &&
                                            countries.map((item, idx) => (
                                                <Option key={item._id} value={item.iso2}>
                                                    {item.name}
                                                </Option>
                                            ))}
                                    </Select>
                                </Col>
                                <Col md="3" className="form-group">
                                    <label htmlFor="feCity" className="title">
                                        City
                                    </label>
                                    <FormInput
                                        id="feCity"
                                        type="text"
                                        placeholder="Sherman Oaks"
                                        value={company.city}
                                        onChange={(e) => setCompany({ ...company, city: e.target.value })}
                                    />
                                </Col>
                                <Col md="3" className="form-group">
                                    <label htmlFor="feState" className="title">
                                        State/Province
                                    </label>
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        value={company.state}
                                        defaultValue={company && company.state && company.state}
                                        onChange={(value) => setCompany({ ...company, state: value })}
                                        id="feState"
                                        placeholder="Search to Select"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {state &&
                                            state.map((item, idx) => (
                                                <Option key={item._id} value={item.iso2}>
                                                    {item.name}
                                                </Option>
                                            ))}
                                    </Select>
                                </Col>
                                <Col md="3" className="form-group">
                                    <label htmlFor="fePostal" className="title">
                                        Postal code
                                    </label>
                                    <FormInput
                                        id="fePostal"
                                        type="text"
                                        placeholder="91403"
                                        value={company.postalCode}
                                        onChange={(e) => {
                                            // if (!regexPhone.test(e.target.value)) {
                                            //     toast.error('Cannot enter special characters');
                                            //     return;
                                            // }
                                            setCompany({ ...company, postalCode: e.target.value });
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row form className="mt-4">
                                <Col md="6" className="form-group">
                                    <label htmlFor="feEmailAddress" className="title">
                                        <span>*</span> Email
                                    </label>
                                    <FormInput
                                        id="feEmailAddress"
                                        type="email"
                                        placeholder="Danielporter@example.com"
                                        value={company.email}
                                        onChange={(e) => {
                                            if (id) {
                                                toast.error('Email editing is not allowed!');
                                                return;
                                            } else {
                                                setCompany({ ...company, email: e.target.value });
                                            }
                                        }}
                                    />
                                </Col>
                                <Col md="6" className="form-group">
                                    <label htmlFor="fePhone">Phone Number</label>
                                    <FormInput
                                        id="fePhone"
                                        type="text"
                                        placeholder="818-978-7102"
                                        value={company.phoneNumber}
                                        onChange={(e) => {
                                            // if (!regexPhone.test(e.target.value)) {
                                            //     toast.error('Cannot enter  characters');
                                            //     return;
                                            // }
                                            setCompany({ ...company, phoneNumber: e.target.value });
                                        }}
                                    />
                                </Col>
                                <Col md="6" className="form-group mt-4">
                                    <label htmlFor="feMobile">Mobile Number</label>
                                    <FormInput
                                        id="feMobile"
                                        type="text"
                                        placeholder="818-635-5579"
                                        value={company.mobileNumber}
                                        onChange={(e) => {
                                            // if (!regexPhone.test(e.target.value)) {
                                            //     toast.error('Cannot enter  characters');
                                            //     return;
                                            // }
                                            setCompany({ ...company, mobileNumber: e.target.value });
                                        }}
                                    />
                                </Col>
                                <Col md="6" className="form-group mt-4">
                                    <label htmlFor="feFax">Fax</label>
                                    <FormInput
                                        id="feFax"
                                        type="text"
                                        placeholder="818-635-5579"
                                        value={company.fax}
                                        onChange={(e) => {
                                            // if (!regexPhone.test(e.target.value)) {
                                            //     toast.error('Cannot enter special characters');
                                            //     return;
                                            // }
                                            setCompany({ ...company, fax: e.target.value });
                                        }}
                                    />
                                </Col>
                            </Row>

                            <FormGroup className="mt-4">
                                <label htmlFor="feInputUrl">Website Url</label>
                                <FormInput
                                    id="feInputUrl"
                                    placeholder="https://www.example.com"
                                    value={company.websiteUrl}
                                    onChange={(e) => setCompany({ ...company, websiteUrl: e.target.value })}
                                />
                            </FormGroup>
                            <Row className="d-flex justify-content-center align-items-center mt-5">
                                <Col lg="2" md="3" sm="12" className="d-flex justify-content-center mb-mbile">
                                    <Button
                                        type="button"
                                        onClick={onSubmit}
                                        className="bg-warning  px-4 w-100"
                                        theme="warning"
                                    >
                                        <p>{id ? 'Save' : 'Add'}</p>
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </ListGroupItem>
        </ListGroup>
    );
}
