import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Col } from 'shards-react';
import PayrollList from './PayrollList';
import TheMonthPaySlipList from './TheMonthPaySlipList';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function TabPayroll({ load, tab, setReloadPageDelete, reloadPageDelete }) {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Col lg="12" md="12" sm="12" className="mt-4">
            <Card className="payrollCart">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="scrollable"
                    // scrollButtons
                    // allowScrollButtonsMobile
                >
                    <Tab className="tab-title" label={<span>Payroll List</span>} {...a11yProps(0)} />
                    <Tab className="tab-title" label="Summary for payroll" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <PayrollList
                        reloadPageDelete={reloadPageDelete}
                        setReloadPageDelete={setReloadPageDelete}
                        onCreate={load}
                        tab={tab}
                    />
                </TabPanel>
                <TabPanel value={value} index={1} className="payrollCart">
                    <TheMonthPaySlipList onCreate={load} tab={tab} />
                </TabPanel>
            </Card>
        </Col>
    );
}
