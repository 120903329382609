import { DatePicker, Table, Form, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Row } from 'shards-react';
import { useLoading, useUser } from '../../hooks';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getError } from '../../helpers/getErrorServer';
import { Option } from 'antd/lib/mentions';
import { Select, Space } from 'antd';
import { trainingAPI } from '../../apis/trainingAPI';

const TrainingInfoForm = ({ visible, setVisible, setReload, id, chooseList }) => {
    const [form] = Form.useForm();
    const { user, getMe } = useUser();
    const { setLoading } = useLoading();
    const [payload, setPayload] = useState();
    const [listEmployee, setListEmployee] = useState([]);
    const [listEmployeeTmp, setListEmployeeTmp] = useState([]);
    const [listIdEmployees, setListIdEmployees] = useState([]);
    const [finalColumns, setFinalColumns] = useState(initColumns);
    const [dataTable, setDataTable] = useState([]);
    const [page, setPage] = useState();
    const [listTraining, setlistTraining] = useState();
    const [open, setOpen] = useState(false);
    const [idTmp, setIdTmp] = useState();
    const [quality, setQuality] = useState('');

    const loadListEmployees = async (id) => {
        try {
            const { data } = await trainingAPI.getEmployeesNotJoin(id);
            console.log(data);
            setListEmployee(data);
            setListEmployeeTmp(data);
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const initColumns = [
        {
            title: 'Name',
            dataIndex: '',
            width: '10%',
            render: (_, row) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Name</p>
                        <span>{row && row.name}</span>
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.name > record2.name;
            },
        },
        {
            title: 'Email',
            dataIndex: '',
            width: '10%',
            render: (_, row) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Email</p>
                        <span>{row && row.email}</span>
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.email > record2.email;
            },
        },
        {
            title: 'Day Join',
            dataIndex: '',
            width: '10%',
            render: (_, row) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Day Join</p>
                        <span>{row && row.dayJoin}</span>
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.dayJoin > record2.dayJoin;
            },
        },
        {
            title: 'Actions',
            key: '_id',
            dataIndex: '_id',
            width: '3%',
            align: 'center',
            fixed: 'right',
            render: (_, row) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Actions</p>
                    <Space size="middle">
                        <Link to={`#`} onClick={() => warning(row.id)} style={{ cursor: 'pointer' }}>
                            <span className="material-icons icon_delete">delete_forever</span>
                        </Link>
                    </Space>
                </div>
            ),
        },
    ];

    const initColumnsUser = [
        {
            title: 'Name',
            dataIndex: '',
            width: '10%',
            render: (_, row) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Name</p>
                        <span>{row && row.name}</span>
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.name > record2.name;
            },
        },
        {
            title: 'Email',
            dataIndex: '',
            width: '10%',
            render: (_, row) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Email</p>
                        <span>{row && row.email}</span>
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.email > record2.email;
            },
        },
        {
            title: 'Day Join',
            dataIndex: '',
            width: '10%',
            render: (_, row) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Day Join</p>
                        <span>{row && row.dayJoin}</span>
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.dayJoin > record2.dayJoin;
            },
        },
    ];

    useEffect(() => {
        if (listTraining) {
            let tmp = [];
            listTraining.items.forEach((item, i) => {
                tmp.push({
                    ...item,
                    key: i,
                    page: listTraining.paginate.page,
                });
            });
            setDataTable(tmp);
        }
    }, [listTraining]);

    const hideModal = () => {
        if (idTmp) {
            deleteT(idTmp);
        }
        setOpen(false);
    };

    const deleteT = async (id) => {
        try {
            console.log(payload.id);
            setLoading(true);
            await trainingAPI.removeEmployee(payload.id, id);
            setLoading(false);
            setReload((prev) => prev + 1);
            getById(payload.id);
            toast.success('Success!');
            setIdTmp();
        } catch (error) {
            console.log(error);
            setLoading(false);
            toast.error(getError(error));
        }
    };

    const showModal = () => {
        setOpen(true);
    };

    const warning = (id) => {
        setIdTmp(id);
        showModal();
    };

    const handleChangeEmployeesList = async (value) => {
        let isAll = false;
        value.map((item) => {
            if (item === 'all') {
                isAll = true;
            }
        });

        if (isAll) {
            setListEmployeeTmp([]);
            setListIdEmployees(['all']);
        } else {
            setListEmployeeTmp(listEmployee);
            setListIdEmployees(value);
        }
    };

    const handleOk = () => {
        if (user.role === 'company' || user.role === 'company-staff') {
            update();
        } else {
            if (chooseList === 'joined') {
                setVisible(false);
            } else {
                requestToJoin(id);
            }
        }
    };

    const getById = async (id) => {
        try {
            if (id) {
                const { data } = await trainingAPI.getByID(id);
                loadListEmployees(id);
                setPayload(data);
                setQuality(data.quality);
                form.setFieldsValue(data);
                data.timeStart && form.setFieldValue('startDay', moment(new Date(data.timeStart), 'YYYY-MM-DD'));
                data.timeEnd && form.setFieldValue('endDay', moment(new Date(data.timeEnd), 'YYYY-MM-DD'));

                let rsDataTable = [];
                for await (const item of data.students) {
                    const dataTables = item;
                    const dayJoin = moment(dataTables.dayJoin).format('MMM Do YY');
                    dataTables.dayJoin = dayJoin;
                    rsDataTable.push(dataTables);
                }
                setDataTable(rsDataTable);
            }
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const requestToJoin = async (id) => {
        try {
            if (Object.keys(user).length === 0) {
                getMe();
            }

            setLoading(true);
            await trainingAPI.requestToJoin(id);
            setLoading(false);
            setVisible(false);
            setReload((prev) => prev + 1);
            toast.success('Request Success');
        } catch (error) {
            console.log(error);
            setLoading(false);
            toast.error(getError(error));
        }
    };

    const update = async () => {
        try {
            console.log(listIdEmployees);
            if (Object.keys(user).length === 0) {
                getMe();
            }
            if (listIdEmployees.length < 1) {
                toast.warning('No user selected');
            } else {
                setLoading(true);
                const { data } = await trainingAPI.addEmployees(payload.id, listIdEmployees);
                setLoading(false);
                setReload((prev) => prev + 1);
                getById(payload.id);
                toast.success('Success');
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            toast.error(getError(error));
        }
    };

    const handleCancel = () => {
        setVisible(false);
        setReload((prev) => prev + 1);
        form.resetFields();
    };

    useEffect(() => {
        if (id) {
            getById(id);
        }
    }, [id]);

    useEffect(() => {
        if (user.role === 'company' || user.role === 'company-staff') {
            setFinalColumns(initColumns);
        } else {
            setFinalColumns(initColumnsUser);
        }
    }, [user.role]);

    const onChangePage = (page) => {
        setPage(page);
    };

    return (
        <Modal
            title={user.role === 'company' || user.role === 'company-staff' ? 'Add Employees' : 'Info Training'}
            open={visible}
            onOk={handleOk}
            zIndex={999}
            onCancel={handleCancel}
            width="700px"
            okText={
                user.role === 'company' || user.role === 'company-staff'
                    ? 'Add'
                    : chooseList !== 'joined'
                    ? 'Join'
                    : 'Ok'
            }
            cancelText="Cancel"
        >
            <Form form={form} layout="vertical" name="form_in_modal" initialValues={{ modifier: 'public' }}>
                <Row lg="12" md="8" sm="6">
                    <Col lg="6">
                        <div className="mb-4">
                            <Form.Item name="name" label="Name">
                                <Input type="text" placeholder="ex Name" id="Name" disabled />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="mb-4">
                            <Form.Item name="studyPlace" label="Study Place">
                                <Input type="text" placeholder="ex Name" id="studyPlace" disabled />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row lg="12" md="8" sm="6">
                    <Col lg="6">
                        <div className="mb-4">
                            <Form.Item name="startDay" label="Start Day">
                                <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }} id="startDay" disabled />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="mb-4">
                            <Form.Item name="endDay" label="End Day">
                                <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }} id="endDay" disabled />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row lg="12" md="8" sm="6">
                    <Col lg="6">
                        <div className="mb-4">
                            <Form.Item name="status" label="Status">
                                <Input type="text" placeholder="ex Name" id="status" disabled />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="mb-4">
                            <Form.Item name="quantity" label="Quantity">
                                <Input disabled type="text" placeholder="ex Name" id="quantity" value={quality} />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                {user.role === 'company' || user.role === 'company-staff' ? (
                    <Row>
                        <Col lg="12">
                            <div className=" mb-4">
                                <Form.Item name="Students" label="Add Employees">
                                    <Space style={{ width: '100%' }} direction="vertical">
                                        <Select
                                            showSearch
                                            allowClear
                                            mode="multiple"
                                            style={{ width: '100%', zIndex: 999 }}
                                            onChange={handleChangeEmployeesList}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().includes(input.toLowerCase())
                                            }
                                        >
                                            {listEmployee && listEmployee.length > 0 && (
                                                <Select.Option value={'all'} label={'All'}>
                                                    --All--
                                                </Select.Option>
                                            )}
                                            {listEmployeeTmp.map((item) => (
                                                <Select.Option value={item._id} label={item.firstName}>
                                                    {item.firstName}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                        {/* <Select
                                            allowClear
                                            mode="multiple"
                                            onChange={handleChangeEmployeesList}
                                            optionLabelProp="label"
                                            style={{ width: '100%', zIndex: 999 }}
                                            placeholder="Please select employees of your company"
                                            maxTagCount="responsive"
                                        >
                                            {listEmployee && listEmployee.length > 0 && (
                                                <Option value="all" label="All">
                                                    <Space>--All--</Space>
                                                </Option>
                                            )}

                                            {listEmployeeTmp.map((item) => (
                                                <Option value={item._id} label={item.firstName}>
                                                    <Space>
                                                        {item.firstName} - {item.email}
                                                    </Space>
                                                </Option>
                                            ))}
                                        </Select> */}
                                    </Space>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <></>
                )}
                <Row lg="12" md="8" sm="6">
                    <Col lg="12">
                        <div className="overflow-hidden">
                            <Table
                                className="ct-table-data"
                                columns={finalColumns}
                                dataSource={dataTable}
                                pagination={{
                                    current: page,
                                    onChange: onChangePage,
                                    pageSize:
                                        listTraining && listTraining.paginate && listTraining.paginate.size
                                            ? listTraining.paginate.size
                                            : 10,
                                    total: listTraining && listTraining.paginate ? listTraining.paginate.count : 0,
                                }}
                            />
                            <Modal
                                title={
                                    <>
                                        <svg
                                            width="18"
                                            height="21"
                                            viewBox="0 0 18 21"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M15.4167 0.833344H2.58333C1.56583 0.833344 0.75 1.65834 0.75 2.66668V15.5C0.75 16.5083 1.56583 17.3333 2.58333 17.3333H6.25L9 20.0833L11.75 17.3333H15.4167C16.425 17.3333 17.25 16.5083 17.25 15.5V2.66668C17.25 1.65834 16.425 0.833344 15.4167 0.833344ZM9.91667 15.5H8.08333V13.6667H9.91667V15.5ZM11.8142 8.39584L10.9892 9.23918C10.3292 9.90834 9.91667 10.4583 9.91667 11.8333H8.08333V11.375C8.08333 10.3667 8.49583 9.45001 9.15583 8.78084L10.2925 7.62584C10.6317 7.29584 10.8333 6.83751 10.8333 6.33334C10.8333 5.32501 10.0083 4.50001 9 4.50001C7.99167 4.50001 7.16667 5.32501 7.16667 6.33334H5.33333C5.33333 4.30751 6.97417 2.66668 9 2.66668C11.0258 2.66668 12.6667 4.30751 12.6667 6.33334C12.6667 7.14001 12.3367 7.87334 11.8142 8.39584Z"
                                                fill="#FCBB0A"
                                            />
                                        </svg>

                                        <p className="title-modal-custom">Warning</p>
                                    </>
                                }
                                open={open}
                                onOk={hideModal}
                                onCancel={() => setOpen(false)}
                                okText="Delete"
                                cancelText="Cancel"
                            >
                                <p>Are you sure you want to delete?</p>
                            </Modal>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default TrainingInfoForm;
