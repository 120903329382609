import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouteNavLink, useHistory, useLocation } from 'react-router-dom';
import { NavItem, NavLink } from 'shards-react';
import { Modal } from 'antd';
import { toast } from 'react-toastify';
import useLocalStorage from '../../../hooks/useLocalStorage';

const SidebarNavItem = ({ item }) => {
    const [subnav, setSubnav] = useState(false);
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [saved, setSaved] = useLocalStorage('saved', true);
    const hideModal = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (location.pathname === item.to) {
            if (item.subNav) {
                setSubnav(true);
            }
        }
    }, [location]);

    const active = () => {
        let result = false;
        if (location.pathname === item.to) {
            result = true;
        }
        if (item && item.subNav) {
            item.subNav.forEach((s) => {
                if (s.to === location.pathname) {
                    result = true;
                }
            });
        }
        return result;
    };

    useEffect(() => {
        setSaved(true);
    }, []);

    return (
        <>
            <NavItem>
                <NavLink
                    active={active()}
                    tag={(props) => <RouteNavLink {...props} />}
                    to={item.to}
                    onClick={(e) => {
                        const storedValue = localStorage.getItem('saved');
                        if (storedValue && JSON.parse(storedValue) === false) {
                            e.preventDefault();
                            toast.warning('You have unsaved changes. Click Save or Cancel?');
                        }
                    }}
                >
                    {item.htmlBefore && (
                        <div
                            className="d-inline-block item-icon-wrapper white_icon"
                            dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
                        />
                    )}
                    {item.title && <span className="text_nav ml-3">{item.title}</span>}

                    {item.htmlAfter && (
                        <div
                            className="d-inline-block item-icon-wrapper"
                            dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
                        />
                    )}
                    {item.subNav && subnav ? (
                        <span className="material-icons sub_icon">expand_less</span>
                    ) : item.subNav ? (
                        <span className="material-icons sub_icon">expand_more</span>
                    ) : null}
                </NavLink>
            </NavItem>

            {subnav && (
                <div className="sub-menu pl-3">
                    {item.subNav.map((submenu) => (
                        <NavItem className="sub-menu-item p-0 " key={submenu.id}>
                            <NavLink tag={(props) => <RouteNavLink {...props} />} to={submenu.to}>
                                {submenu.title && <span className="text_nav">{submenu.title}</span>}
                            </NavLink>
                        </NavItem>
                    ))}
                </div>
            )}
        </>
    );
};

SidebarNavItem.propTypes = {
    /**
     * The item object.
     */
    item: PropTypes.object,
};

export default SidebarNavItem;
