export const FORMAT = {
    DATE: 'DD MMM, YYYY',
};

export const ROLE = {
    ADMIN: 'admin',
    COMPANY: 'company',
    USER: 'user',
    SYSTEMSTAFF: 'system-staff',
    COMPANYSTAFF: 'company-staff',
};

export const EMPLOYEES_TYPE = [
    { label: 'Local Staff', value: 'local-staff' },
    { label: 'Work Permit', value: 'work-permit' },
    { label: 'S Pass', value: 's-pass' },
    { label: 'Ep', value: 'ep' },
];

export const EMPLOYEES_TYPE_SUMMARY_PAYROLL = [
    { label: 'Local Staff', value: 'local-staff' },
    { label: 'Work Permit', value: 'work-permit' },
    { label: 'S Pass', value: 's-pass' },
];

export const EMPLOYEES_TYPE_VALUE = {
    LOCALSTAFF: 'local-staff',
    WORKPERMIT: 'work-permit',
    SPASS: 's-pass',
    EP: 'ep',
};

export const EMPLOYEE_PRIVACY_LEVEL = [
    { id: 0, label: 'Employee', value: 'employee' },
    { id: 1, label: 'Manager', value: 'manager' },
    { id: 2, label: 'Not Accessible', value: 'notAccessible' },
];

export const EMPLOYEE_HEALTH = [
    { id: 0, label: 'Normal', value: 'normal' },
    { id: 1, label: 'Short-Sighted', value: 'short-sighted' },
    { id: 2, label: 'Far-Sighted', value: 'far-sighted' },
    { id: 3, label: 'Astigmatic', value: 'astigmatic' },
    { id: 4, label: 'Short-Sighted & Astigmatic', value: 'short-sighted-astigmatic' },
    { id: 5, label: 'Far-Sighted & Astigmatic', value: 'far-sighted-astigmatic' },
    { id: 6, label: 'Partially', value: 'impaired' },
    { id: 7, label: 'Blind', value: 'blind' },
];

export const EMPLOYEE_HEARING = [
    { id: 0, label: 'Normal', value: 'normal' },
    { id: 1, label: 'Partially Impaired', value: 'partially-impaired' },
    { id: 2, label: 'Deaf', value: 'deaf' },
];

export const EMPLOYEE_HAND_LEG = [
    { id: 0, label: 'Normal', value: 'normal' },
    { id: 1, label: 'Partially Impaired', value: 'partially-impaired' },
    { id: 2, label: 'Handicapped', value: 'handicapped' },
];

export const EMPLOYEE_BLOOD_TYPE = [
    { id: 0, label: 'A+', value: 'a+' },
    { id: 1, label: 'B+', value: 'b+' },
    { id: 2, label: 'AB+', value: 'ab+' },
    { id: 3, label: 'O+', value: 'o+' },
    { id: 4, label: 'A-', value: 'a-' },
    { id: 5, label: 'B-', value: 'b-' },
    { id: 6, label: 'AB-', value: 'ab-' },
    { id: 7, label: 'O-', value: 'o-' },
];

export const EMPLOYEE_PAYMENT_PAY = [
    { id: 0, label: 'Monthly', value: 'monthly' },
    { id: 1, label: 'Weekly', value: 'weekly' },
    { id: 2, label: 'Semi-Monthly', value: 'semi-monthly' },
    { id: 3, label: 'Be-Weekly', value: 'be-weekly' },
];

export const EMPLOYEE_PAYMENT_METHOD = [
    { id: 0, label: 'Cash', value: 'cash' },
    { id: 1, label: 'Bank', value: 'bank' },
    { id: 2, label: 'Cheque', value: 'cheque' },
];

export const FORM_TYPE = {
    VIEW: 'view',
    EDIT: 'edit',
    CREATE: 'create',
    APPROVE: 'approve',
};

export const TRAINING_TYPE = {
    REQUEST: 'request',
    UPDATE: 'update',
    FINISH: 'finish',
    ADD: 'add',
};

export const TYPE_NOTIFICATION = {
    EMPLOYEE: 'employee',
    LEAVE: 'leave',
    EXPENSECLAIM: 'expense-claim',
    PAYROLL: 'pay-roll',
    COMPANY: 'company',
    DEPARTMENT: 'department',
    HOLIDAY: 'public-holiday',
    CERTIFICATE: 'certificate',
    TRAINING: 'training',
    MESSAGES: 'messages',
};

export const GEOLOCATION = {
    NOTREQUIRED: 'not-required',
    REQUIRED: 'required',
};

export const INVALIDIPACTION = {
    BLOCKCLOCKING: 'block-clocking',
    OPENCLOCKING: 'open-clocking',
};

export const SELFIE = {
    NOTREQUIRED: 'not-required',
    REQUIRED: 'required',
};

export const VISIBLEEMPLOYEELEAVE = {
    SELF: 'self',
    DEPARTMENT: 'same-department',
    BRANCH: 'same-branch',
    DEPARTMENTBRACH: 'same-department-branch',
    ALL: 'all',
};

// export const PAYROLL_SETTING_TYPE = {
//     LOCALSTAFF: 'local-staff',
//     WORKPERMIT: 'work-permit',
//     SPASS: 's-pass',
//     EP: 'ep',
// };

export const ALL_MONTH = [
    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
];

export const WORKING_DAYS = [5, 5.5, 6];

export const DURATION = [300, 330, 360, 390, 420, 450, 480, 510, 540];

export const BREAK = [60, 70, 80, 90];

export const BREAK_TIME = 12;

export const RANK = {
    ADMIN: 'Software Admin',
    SYSTEMSTAFF: 'Software Staff',
    COMPANY: 'Client Super Admin',
    COMPANYSTAFF: 'Client Staff',
    DEPARTMENT: 'Department',
    BRANCH: 'Branch',
    EMPLOYEE: 'Employee',
};

export const TOTAL_LEAVE_HOURS = 20 * 60;
