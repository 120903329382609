import { DatePicker, Form, Input, Popconfirm, Table, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Row } from 'shards-react';
import { historiesAPI } from '../../apis/historiesAPI';
import { publicHolidayAPI } from '../../apis/publicHolidayAPI';
import { ROLE, TYPE_NOTIFICATION } from '../../helpers/const';
import { getError } from '../../helpers/getErrorServer';

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
    const inputNode = inputType === 'date' ? <DatePicker format={'YYYY-MM-DD'} /> : <Input />;

    return (
        <td {...restProps}>
            {editing ? (
                <div className="content_td_mobile">
                    <p className="title_mobile">{title}</p>
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message: `Please Input ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                </div>
            ) : (
                children
            )}
        </td>
    );
};

function ListPublicHoliday({ user, refetch }) {
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const [holiday, setHoliday] = useState([]);
    const [reload, setReload] = useState(0);
    const [total, setTotal] = useState(0);
    const [size, setSize] = useState(0);
    const [page, setPage] = useState();
    const formatDate = 'YYYY-MM-DD';

    useEffect(() => {
        (async () => {
            if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
                const { data } = await publicHolidayAPI.getAll({ companyId: user.company._id });
                setHoliday(data.items);
                if (data && data.paginate && data.paginate.count && data.paginate.size) {
                    setTotal(data.paginate.count);
                    setSize(data.paginate.size);
                }
            }
            if (
                user &&
                user.role &&
                (user.role === ROLE.COMPANYSTAFF || user.role === ROLE.USER) &&
                user.employee &&
                user.employee.company &&
                user.employee.company._id
            ) {
                const { data } = await publicHolidayAPI.getAll({ companyId: user.employee.company._id });
                setHoliday(data.items);
                if (data && data.paginate && data.paginate.count && data.paginate.size) {
                    setTotal(data.paginate.count);
                    setSize(data.paginate.size);
                }
            }
        })();
    }, [reload, user, refetch]);

    const isEditing = (record) => record._id === editingKey;

    const edit = (record) => {
        if (record) {
            const title = record.title && record.title;
            const startDay = record.startDay && new Date(moment(record.startDay).format(formatDate));
            const endDay = record.endDay && new Date(moment(record.endDay).format(formatDate));
            form.setFieldsValue({
                title: title && title,
                startDay: startDay && moment(startDay, formatDate),
                endDay: endDay && moment(endDay, formatDate),
            });
            setEditingKey(record._id);
        }
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (key, id) => {
        try {
            const row = await form.validateFields();

            if (new Date(row.startDay).getTime() > new Date(row.endDay).getTime()) {
                toast.error('The start date cannot be greater than the end date');
                return;
            }

            const payload = {
                title: row.title,
                startDay: new Date(row.startDay).getTime(),
                endDay: new Date(row.endDay).getTime(),
            };
            if (row) {
                const result = await publicHolidayAPI.update(id, payload);
                if (result && user && user.role && user.role === ROLE.COMPANYSTAFF) {
                    await historiesAPI.add({
                        title: 'PUBLIC HOLIDAY',
                        desc: `Client HR ${user.employee.firstName} just update public holiday`,
                        type: TYPE_NOTIFICATION.HOLIDAY,
                        employee: user.employee._id,
                        idRequest: result.data._id,
                        toCompany: true,
                    });
                }
                toast.success('Update successfully!');
                setReload((e) => e + 1);
                setEditingKey('');
            }
        } catch (errInfo) {
            toast.error(getError(errInfo));
        }
    };

    const columns = [
        {
            title: 'Title Holiday',
            dataIndex: 'title',
            editable: true,
            render: (title) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Title Holiday</p>
                        <span>{title}</span>
                    </div>
                );
            },
        },
        {
            title: 'Start Day',
            dataIndex: 'startDay',
            editable: true,
            render: (startDay) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Start Day</p>
                        <span>{moment(startDay).format('YYYY-MM-DD')}</span>
                    </div>
                );
            },
        },
        {
            title: 'End Day',
            dataIndex: 'endDay',
            editable: true,
            render: (endDay) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">End Day</p>
                        <span>{moment(endDay).format('YYYY-MM-DD')}</span>
                    </div>
                );
            },
        },
        {
            title: 'Action',
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Action</p>
                        {editable ? (
                            <span>
                                <Popconfirm className="edit-cancel" title="Sure to cancel?" onConfirm={cancel}>
                                    <a>Cancel</a>
                                </Popconfirm>
                                <Typography.Link
                                    className="edit-save"
                                    onClick={() => save(record.key, record._id)}
                                    style={{
                                        marginRight: 8,
                                    }}
                                >
                                    Save
                                </Typography.Link>
                            </span>
                        ) : (
                            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                                Edit
                            </Typography.Link>
                        )}
                    </div>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'startDay' || col.dataIndex === 'endDay' ? 'date' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const onChangePage = (page) => {
        setPage(page);
    };

    return (
        <Card small className="h-100">
            <CardHeader className="border-bottom">
                <span className="ct-title">Public holiday List</span>
            </CardHeader>

            <CardBody className="col-xl-12 col-lg-12">
                <Row lg="12" md="12" sm="12">
                    <Form form={form} component={false}>
                        <Table
                            className="ct-table-data"
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            bordered
                            dataSource={holiday}
                            columns={mergedColumns}
                            rowClassName="editable-row"
                            pagination={{
                                current: page,
                                onChange: onChangePage,
                                pageSize: size || 1,
                                total: total || 1,
                            }}
                        />
                    </Form>
                </Row>
            </CardBody>
        </Card>
    );
}

export default ListPublicHoliday;
