import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { departmentAPI } from '../apis/departmentAPI';
import { DepartmentContext } from '../contexts/departmentContext';
import { DEPARTMENT_FILTER } from '../global/department/filterDepartment';
import { ROLE } from '../helpers/const';
import { getError } from '../helpers/getErrorServer';
import { RESPONSE } from '../helpers/response';
import { useLoading, useUser } from '../hooks';

export default function DepartmentProvider({ children }) {
    const { user, getMe } = useUser();
    const { setLoading } = useLoading();
    const [department, setDepartment] = useState(RESPONSE);
    const [filterDepartment, setFilterDepartment] = useState(RESPONSE);

    const getFilter = async (filter, user) => {
        if (!user._id) {
            user = await getMe();
        }

        if (user.role === ROLE.COMPANY) {
            return {
                ...filter,
                companyID: user && user.company && user.company._id ? user.company._id : '',
            };
        }

        if (user.role === ROLE.COMPANYSTAFF) {
            return {
                ...filter,
                companyID:
                    user && user.employee && user.employee.company && user.employee.company._id
                        ? user.employee.company._id
                        : '',
            };
        }

        if (user.role === ROLE.USER) {
            return {
                ...filter,
                companyID:
                    user && user.employee && user.employee.company && user.employee.company._id
                        ? user.employee.company._id
                        : '',
            };
        }
        return filter;
    };

    const fetchDepartment = async (filter = DEPARTMENT_FILTER) => {
        try {
            filter = {
                ...DEPARTMENT_FILTER,
                ...filter,
            };

            filter = await getFilter(filter, user);
            setLoading(true);
            const { data } = await departmentAPI.get(filter);
            setDepartment(data);
            setFilterDepartment(filter);
            setLoading(false);
        } catch (error) {
            toast(getError(error));
            setLoading(false);
        }
    };

    return (
        <DepartmentContext.Provider value={{ department, filterDepartment, setFilterDepartment, fetchDepartment }}>
            {children}
        </DepartmentContext.Provider>
    );
}
