import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'shards-react';
import { DatePicker, Select, Input } from 'antd';
import { useExpenseClaim } from '../../hooks';
import { EXPENSE_CLAIM_PAID_BY, EXPENSE_CLAIM_STATUS } from '../../global/expenseClaim';
import { FORMAT } from '../../helpers/const';

const initFilter = {
    search: '',
    paidBy: '',
    status: '',
    from: null,
    to: null,
};

function FilterExClaim({ tabSelected }) {
    const { RangePicker } = DatePicker;
    const { Option } = Select;
    const { fetchExpenseClaims, setFilterExpenseClaim } = useExpenseClaim();
    const [filter, setFilter] = useState(initFilter);

    const onSearch = () => {
        fetchExpenseClaims({
            ...filter,
            status:
                tabSelected === 0
                    ? EXPENSE_CLAIM_STATUS.PENDING
                    : filter.status
                    ? filter.status
                    : EXPENSE_CLAIM_STATUS.APPROVE + ',' + EXPENSE_CLAIM_STATUS.REJECT,
        });
    };

    useEffect(() => {
        setFilterExpenseClaim(filter);
    }, [filter]);

    return (
        <Row form className="mt-1">
            <Col lg="3" md="3" className="form-group">
                <Input
                    placeholder="Search by item name"
                    value={filter.search}
                    onChange={(e) => setFilter({ ...filter, search: e.target.value })}
                    onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                            onSearch();
                        }
                    }}
                />
            </Col>

            <Col md={tabSelected === 0 ? 3 : 1.5} className="form-group">
                <Select
                    defaultValue=""
                    style={{ width: '100%' }}
                    value={filter.paidBy}
                    onChange={(e) => setFilter({ ...filter, paidBy: e })}
                >
                    <Option value="">Paid by</Option>
                    {EXPENSE_CLAIM_PAID_BY.map((item, index) => (
                        <Option key={index} value={item.value}>
                            {item.label}
                        </Option>
                    ))}
                </Select>
            </Col>

            {tabSelected === 1 && (
                <Col md="1.5" className="form-group">
                    <Select
                        defaultValue=""
                        style={{ width: '100%' }}
                        value={filter.status}
                        onChange={(e) => setFilter({ ...filter, status: e })}
                    >
                        <Option value="">Status</Option>
                        <Option value="approve">Approve</Option>
                        <Option value="reject">Reject</Option>
                    </Select>
                </Col>
            )}

            <Col md="3" className="form-group">
                <RangePicker
                    style={{
                        width: '100%',
                    }}
                    defaultValue={[filter.from, filter.to]}
                    onChange={(date) => {
                        setFilter({
                            ...filter,
                            from: date && date[0] ? date[0]._d : null,
                            to: date && date[1] ? date[1]._d : null,
                        });
                    }}
                    format={FORMAT.DATE}
                />
            </Col>
            <Col md="3" className="d-flex justify-content-end align-items-start">
                <button type="submit" className="button__search" onClick={onSearch}>
                    Search
                </button>
            </Col>
        </Row>
    );
}

export default FilterExClaim;
