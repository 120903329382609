export const FILE_UPLOAD_FOR = {
    AVATAR: 'avatar',
    LEAVE: 'leave',
    EXPENSE_CLAIM: 'expense_claim',
    CERTIFICATE: 'certificate',
};

export const FILE_UPLOAD_TYPE = {
    IMAGE: 'image',
    TEXT: 'text',
};
