import axios from 'axios';

const urlCountries = process.env.REACT_APP_URL_COUNTRIES;

const AxiosCountries = axios.create({
    baseURL: urlCountries,
    headers: {
        'X-CSCAPI-KEY': process.env.REACT_APP_API_KEY_COUNTRIES,
    },
});

const getCountries = async () => {
    return await AxiosCountries.get('/countries');
};

const getInfoCountry = async (iso2) => {
    return await AxiosCountries.get('/countries/' + iso2);
};

const getCities = async (country, state) => {
    return await AxiosCountries.get('/countries/' + country + '/states/' + state + '/cities');
};

const getStates = async (country) => {
    return await AxiosCountries.get('/countries/' + country + '/states');
};

export const countriesAPI = {
    getCountries,
    getCities,
    getStates,
    getInfoCountry,
};
