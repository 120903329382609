import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Col } from 'shards-react';
import { LEAVE_STATUS } from '../../global/leave';
import { useLeave } from '../../hooks/useLeave';
import TabLeaveHistory from './TabLeaveHistory';
import TabPendingRequest from './TabPendingRequest';
import TabSummaryLeave from './TabSummaryLeave';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function TabLeave({ setRender, render, setPending }) {
    const { leavesPending, fetchLeaves, leaves } = useLeave();
    const [totalPending, setTotalPending] = useState(0);
    const [value, setValue] = useState(0);

    useEffect(() => {
        if (leavesPending) {
            setTotalPending(
                leavesPending && leavesPending.paginate && leavesPending.paginate.count
                    ? leavesPending.paginate.count
                    : 0,
            );
        }
    }, [leaves]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (value === 0) {
            fetchLeaves({
                status: LEAVE_STATUS.PENDING,
            });
        } else if (value === 1) {
            fetchLeaves({
                status: LEAVE_STATUS.APPROVE + ',' + LEAVE_STATUS.REJECT,
            });
        }
    }, [value]);

    useEffect(() => {
        setPending(totalPending);
    }, [totalPending]);

    return (
        <Col lg="12" md="12" sm="12" className="mt-4">
            <Card>
                <CardHeader>
                    <span className="ct-title">Leave record</span>
                </CardHeader>

                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="scrollable"
                    // scrollButtons
                    // allowScrollButtonsMobile
                >
                    <Tab
                        className="tab-title"
                        label={
                            <span>
                                Pending request <span className="text__red">({totalPending})</span>
                            </span>
                        }
                        {...a11yProps(0)}
                    />
                    <Tab className="tab-title" label="Leave history" {...a11yProps(1)} />
                    <Tab className="tab-title" label="Summary Leave" {...a11yProps(2)} />
                </Tabs>

                <TabPanel value={value} index={0}>
                    <TabPendingRequest
                        tabSelected={value}
                        setRender={setRender}
                        value={value}
                        setPending={setPending}
                        setValue={setValue}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <TabLeaveHistory tabSelected={value} setRender={setRender} value={value} setValue={setValue} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <TabSummaryLeave tabSelected={value} setRender={setRender} value={value} setValue={setValue} />
                </TabPanel>
            </Card>
        </Col>
    );
}
