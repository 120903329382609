import React from 'react';
import FilterLeave from './FilterLeave';
import TableSummaryLeave from './TableSummaryLeave';

function TabSummaryLeave({ tabSelected, value, setRender, setValue }) {
    return (
        <div>
            {/* <FilterLeave tabSelected={tabSelected} /> */}

            <TableSummaryLeave value={value} setRender={setRender} setValue={setValue} />
        </div>
    );
}

export default TabSummaryLeave;
