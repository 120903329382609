import { Button, Form, Input } from 'antd';
import { signOut } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Container, Row } from 'shards-react';
import { resetTokenAPI } from '../../apis/resetToken';
import { userAPI } from '../../apis/userAPI';
import { getError } from '../../helpers/getErrorServer';
import { jwtManager } from '../../helpers/jwtManager';

export default function ResetPassword() {
    const initAccount = {
        email: '',
        password: '',
    };

    const [account, setAccount] = useState(initAccount);
    const [showPass, setShowPass] = useState(false);
    const [showPassConfirm, setShowPassConfirm] = useState(false);
    const [id, setId] = useState();
    const [token, setToken] = useState();
    const search = useLocation().search;
    const history = useHistory();

    useEffect(() => {
        (() => {
            const token = new URLSearchParams(search).get('token');
            setToken(token);
        })();
    }, [search]);

    const showPassWord = () => {
        setShowPass(!showPass);
    };

    const showPassWordConfirms = () => {
        setShowPassConfirm(!showPassConfirm);
    };

    const validateForm = (data) => {
        if (data.password === '') {
            toast.error('Please input your new password!');
            return false;
        }
        if (data.confirmPassword === '') {
            toast.error('Please input your new password!');
            return false;
        }
        if (data.password !== data.confirmPassword) {
            toast.error('Password incorrect!');
            return false;
        }
        return true;
    };

    const onFinish = async (values) => {
        try {
            if (validateForm(values)) {
                if (token) {
                    const { data } = await resetTokenAPI.getToken({ token });
                    if (data) {
                        await userAPI.resetPassword({
                            password: values.password,
                            confirmPassword: values.confirmPassword,
                            token: data.token,
                            userId: data.userId,
                        });
                        await resetTokenAPI.deleteToken(data.userId);

                        jwtManager.clear();
                        toast.success('Change password successfully!');
                        history.push('/');
                    } else {
                        toast.error('The link has expired');
                    }
                }
            }
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div
            className="position-relative"
            style={{
                backgroundColor: '#fff',
            }}
        >
            <Container fluid>
                <Row>
                    <Col className=" content-login" xl="4" lg="4" md="12" sm="12">
                        <div className="wrapper">
                            <div className="avt_prof">
                                <div className="avt"></div>
                                <p>Reset Password</p>
                            </div>

                            <Form
                                layout="vertical"
                                name="basic"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <div className="password">
                                    <Form.Item
                                        label={<span style={{ fontWeight: '500' }}>New Password</span>}
                                        name="password"
                                    >
                                        <Input
                                            value={account.password}
                                            type={showPass === false ? 'password' : 'text'}
                                            onChange={(e) =>
                                                setAccount({
                                                    ...account,
                                                    password: e.target.value,
                                                })
                                            }
                                            style={{ paddingRight: '30px' }}
                                        />
                                    </Form.Item>
                                    {showPass === false ? (
                                        <AiFillEyeInvisible
                                            size={20}
                                            className="eye-password"
                                            onClick={() => showPassWord()}
                                        />
                                    ) : (
                                        <AiFillEye size={20} className="eye-password" onClick={() => showPassWord()} />
                                    )}
                                </div>
                                <div className="password">
                                    <Form.Item
                                        label={<span style={{ fontWeight: '500' }}>Confirm Password</span>}
                                        name="confirmPassword"
                                    >
                                        <Input
                                            value={account.password}
                                            type={showPassConfirm === false ? 'password' : 'text'}
                                            onChange={(e) =>
                                                setAccount({
                                                    ...account,
                                                    password: e.target.value,
                                                })
                                            }
                                            style={{ paddingRight: '30px' }}
                                        />
                                    </Form.Item>
                                    {showPassConfirm === false ? (
                                        <AiFillEyeInvisible
                                            size={20}
                                            className="eye-password"
                                            onClick={() => showPassWordConfirms()}
                                        />
                                    ) : (
                                        <AiFillEye
                                            size={20}
                                            className="eye-password"
                                            onClick={() => showPassWordConfirms()}
                                        />
                                    )}
                                </div>

                                <Button
                                    type="warning"
                                    htmlType="submit"
                                    style={{
                                        width: '100%',
                                        height: '32px',
                                        backgroundColor: '#FFD96C',
                                        fontWeight: '500',
                                        borderRadius: '4px',
                                        color: '#0C415B',
                                    }}
                                >
                                    Reset Password
                                </Button>
                            </Form>
                        </div>
                    </Col>
                    <Col className="p-0" xl="8" lg="8" md="12" sm="12">
                        <img
                            style={{
                                width: '100%',
                                objectFit: 'cover',
                            }}
                            src="./img/bg.jpg"
                            alt="bg_login"
                            className="bg_login"
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
