import React from 'react';
import LeaveRequest from './LeaveRequest';
import { Card, CardHeader, CardBody, ListGroup } from 'shards-react';

const TopReferrals = () => {
    return (
        <Card small>
            <CardHeader className="border-bottom">
                <span className="title__h5">Leave request</span>
                <div className="block-handle" />
            </CardHeader>

            <CardBody className="p-0">
                <ListGroup small flush className="list-group-small">
                    <LeaveRequest />
                </ListGroup>
            </CardBody>
        </Card>
    );
};

export default TopReferrals;
