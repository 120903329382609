import React, { useEffect } from 'react';

import { Col, Container, Row } from 'shards-react';

import PageTitle from './../components/common/PageTitle';
// import SmallStats from "./../components/common/SmallStats";
// import UsersOverview from "./../components/blog/UsersOverview";
import { useHistory } from 'react-router-dom';
import { ROLE } from '../helpers/const';
import { useUser } from '../hooks';
import Discussions from './../components/blog/Discussions';
import EmployeeAvailable from './../components/blog/EmployeeAvailable';
import NewDraft from './../components/blog/NewDraft';
import UsersByDevice from './../components/blog/UsersByDevice';
import TopReferrals from './../components/common/TopReferrals';
import ResetPasswordModal from '../components/layout/Modal/ResetPassword';

// const widthDevice = window.screen.width;
// console.log("object", widthDevice);

function Dashboard({ smallStats }) {
    const { user } = useUser();
    const history = useHistory();

    useEffect(() => {
        if (user.role === ROLE.USER && user.position) {
            if (user.position.employee.length > 0) {
                history.push('/employees/local-staff');
            } else {
                history.push('/my-information');
            }
        }
    }, []);

    return (
        <Container fluid className="main-content-container px-4">
            {/* Page Header */}
            <ResetPasswordModal />
            <Row noGutters className="page-header py-4">
                <PageTitle
                    title="Dashboard"
                    subtitle={
                        <>
                            <span className="route-main">Dashboard</span>
                        </>
                    }
                    className="text-sm-left mb-3"
                />
            </Row>

            <Row>
                {/* Users Overview */}
                <Col xl="4" lg="6" md="12" sm="12" className="mb-4">
                    {/* <UsersOverview /> */}
                    <EmployeeAvailable />
                </Col>

                {/* Users by Device */}
                <Col xl="4" lg="6" md="12" sm="12" className="mb-4">
                    <UsersByDevice />
                </Col>

                {/* New Draft */}
                <Col xl="4" lg="6" md="12" sm="12" className="mb-4">
                    <NewDraft />
                </Col>

                {/* Discussions */}
                <Col xl="6" lg="6" md="12" sm="12" className="mb-4">
                    <Discussions />
                </Col>

                {/* Top Referrals */}
                <Col xl="6" lg="12" md="12" sm="12" className="mb-4">
                    <TopReferrals />
                </Col>
            </Row>
        </Container>
    );
}

export default Dashboard;
