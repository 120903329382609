import axios from 'axios';

const path = 'pay-rolls/';

const get = async (filter = {}) => {
    return await axios.get(path, {
        params: filter,
    });
};

const autoCreate = async (filter = {}) => {
    return await axios.get(path + 'auto');
};

const getById = async (id) => {
    return await axios.get(path + id);
};

const getByCompanyId = async (id, filter = {}) => {
    return await axios.get(path + 'company/' + id, {
        params: filter,
    });
};

const findSummaryForPayroll = async (id, filter = {}) => {
    return await axios.get(path + 'findSummaryForPayroll/company/' + id, {
        params: filter,
    });
};

const getByEmployeeId = async (id, filter = {}) => {
    return await axios.get(path + 'employee/' + id, {
        params: filter,
    });
};

const create = async (payload) => {
    return await axios.post(path, payload);
};

const createByCompany = async (payload) => {
    return await axios.post(path + 'create-by-company', payload);
};

const statistic = async (filter = {}) => {
    return await axios.get(path + 'statistic/', {
        params: filter,
    });
};

const getTotalCDLandSDF = async (filter = {}) => {
    return await axios.get(path + 'getTotalSDLandCDF/', {
        params: filter,
    });
};

const update = async (id, payload) => {
    return await axios.patch(path + id, payload);
};

const _delete = async (id) => {
    return await axios.delete(path + id);
};

const exportSummaryForPayroll = async (filter = {}) => {
    return await axios.get(path + 'export/', {
        params: filter,
    });
};

export const payRollAPI = {
    get,
    getById,
    update,
    _delete,
    create,
    getByCompanyId,
    statistic,
    getByEmployeeId,
    autoCreate,
    getTotalCDLandSDF,
    createByCompany,
    findSummaryForPayroll,
    exportSummaryForPayroll,
};
