import { Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardHeader, Col, Container, Row } from 'shards-react';
import { employeesAPI } from '../../apis/employeesAPI';
import { ROLE } from '../../helpers/const';
import { useUser } from '../../hooks';
import PageTitle from './../common/PageTitle';
import { AiFillPlusCircle } from 'react-icons/ai';

export default function ExpenApproval() {
    const [form] = Form.useForm();

    const [employees, setEmployees] = useState();
    const { user, getMe } = useUser();
    const [arrApprover, setArrApprover] = useState([]);
    const [render, setRender] = useState(0);

    const history = useHistory();

    useEffect(() => {
        if (!user) {
            getMe();
        }
        user &&
            user.role &&
            user.role !== ROLE.COMPANY &&
            user.role !== ROLE.COMPANYSTAFF &&
            user.role !== ROLE.USER &&
            history.push('/login');
    }, [user]);

    useEffect(() => {
        (async () => {
            if (Object.keys(user).length === 0) {
                await getMe();
            }
            if (user && user.company && user.role && user.role === ROLE.COMPANY && user.company._id) {
                getAllEmployees(user.company._id);
                getAllApprover(user.company._id);
            }

            if (
                user &&
                user.role &&
                (user.role === ROLE.COMPANYSTAFF || user.role === ROLE.USER) &&
                user.employee &&
                user.employee.company &&
                user.employee.company._id
            ) {
                getAllEmployees(user.employee.company._id);
                getAllApprover(user.employee.company._id);
            }
        })();
    }, [user, render]);

    const onFinish = async (data) => {
        if (data && data.approver && arrApprover) {
            let tmpArr = [];
            data.approver.map((item) => {
                if (item.first) {
                    tmpArr.push(item.first);
                }
            });

            let newArr = [];
            tmpArr.forEach((item) => {
                if (!newArr.includes(item)) {
                    newArr.push(item);
                }
            });

            let oldArr = [];
            arrApprover.map((item) => oldArr.push(item._id));

            let sameArr = [];
            newArr.forEach((newItem) => {
                oldArr.forEach((oldItem) => {
                    if (newItem === oldItem) {
                        sameArr.push(newItem);
                    }
                });
            });

            let removeArr = [];
            oldArr.map((item) => {
                if (!sameArr.includes(item)) {
                    removeArr.push(item);
                }
            });

            let addArr = [];
            newArr.map((item) => {
                if (!sameArr.includes(item)) {
                    addArr.push(item);
                }
            });

            removeArr.map(async (item) => {
                await employeesAPI.removeApproverExpense(item);
            });

            addArr.map(async (item) => {
                await employeesAPI.setApproverExpense(item);
            });

            let arr = [];
            newArr.map(async (item) => {
                const { data } = await employeesAPI.getById(item);
                arr.push(data[0]);
            });

            setArrApprover((prev) => arr);

            toast.success('Update successful!');
        }
        //setRender((e) => e + 1);
    };

    const getAllApprover = async (id) => {
        const { data } = await employeesAPI.getApprover(id, { isApproverExpense: true });
        setArrApprover(data);
        let tmpArr = [];
        if (data) {
            data.map((item) => {
                tmpArr.push({ first: item._id });
            });
            form.setFieldsValue({
                approver: tmpArr,
            });
        }
    };

    const getAllEmployees = async (id) => {
        const { data } = await employeesAPI.getAllCompany(id);
        setEmployees(data);
    };

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <Col>
                    <PageTitle
                        title="Expense approval"
                        subtitle="Company settings/ Expense approval"
                        className="text-sm-left mb-3"
                    />
                </Col>

                {user && user.role && user.role === ROLE.COMPANY && (
                    <Col xl="2" lg="3" md="6" sm="12">
                        <div
                            className="text-center rounded p-2 d-flex justify-content-center align-items-center button__warning"
                            onClick={() => history.push('/employers/add-job-position')}
                        >
                            <AiFillPlusCircle size={25} />
                            <span className="ml-2">Add Role</span>
                        </div>
                    </Col>
                )}
            </Row>
            <Card small className="h-100 p-0">
                <CardHeader className="border-bottom">
                    <span className="ct-title">Expense approval</span>
                </CardHeader>
                <Row className="d-flex justify-content-center align-items-center pt-4 pb-4">
                    <Col lg="8" md="12" sm="12">
                        <Form form={form} name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
                            <Form.List name="approver">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <div key={field.key} className="d-flex mt-2">
                                                <Col lg="11" className="list-item">
                                                    <Form.Item name={[index, 'first']}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '92%' }}
                                                            disabled={user.role !== ROLE.COMPANY ? true : false}
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children
                                                                    .toLowerCase()
                                                                    .includes(input.toLowerCase())
                                                            }
                                                        >
                                                            {employees &&
                                                                employees.map((item, index) => (
                                                                    <Select.Option key={index} value={`${item._id}`}>
                                                                        {item.firstName}
                                                                    </Select.Option>
                                                                ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>

                                                {user && user.role && user.role === ROLE.COMPANY && (
                                                    <Col lg="1" className="list-btn">
                                                        <div
                                                            onClick={() => remove(field.name)}
                                                            className="icon-delete-list"
                                                        >
                                                            <span className="material-icons">delete_forever</span>
                                                        </div>
                                                    </Col>
                                                )}
                                            </div>
                                        ))}

                                        {user && user.role && user.role === ROLE.COMPANY && (
                                            <div
                                                className="d-flex justify-content-left align-items-center"
                                                onClick={() => {
                                                    add();
                                                }}
                                            >
                                                <BsFillPlusCircleFill className="mr-2" size={20} color="#0C415B" />
                                                <span>Add approver</span>
                                            </div>
                                        )}
                                    </>
                                )}
                            </Form.List>
                        </Form>
                    </Col>
                </Row>
                <div>
                    {user && user.role && user.role === ROLE.COMPANY && (
                        <div className="d-flex justify-content-center align-items-center my-3">
                            <div
                                onClick={() => {
                                    form.submit();
                                }}
                                className="text-center btn-save-attendanceSetting rounded py-2 px-4 d-flex justify-content-center align-items-center button__warning"
                            >
                                <span className="text">Save</span>
                            </div>
                        </div>
                    )}
                </div>
            </Card>
        </Container>
    );
}
