import { GoogleAuthProvider, onAuthStateChanged, signInWithRedirect, signOut } from 'firebase/auth';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../views/Login/firebase';
const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [userLogin, setUserLogin] = useState();

    const googleSignIn = () => {
        const provider = new GoogleAuthProvider();
        signInWithRedirect(auth, provider);
    };

    const logOutGoogle = () => {
        signOut(auth);
    };

    useEffect(() => {
        (() => {
            onAuthStateChanged(auth, (currentUser) => {
                setUserLogin(currentUser);
            });
        })();
    }, []);

    return (
        <AuthContext.Provider value={{ userLogin, googleSignIn, logOutGoogle, logOutGoogle }}>
            {children}
        </AuthContext.Provider>
    );
};

export const UserAuth = () => {
    return useContext(AuthContext);
};
