import moment from 'moment';

import { Form, Input, Radio, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    Button,
    Card,
    CardHeader,
    Col,
    Container,
    FormGroup,
    FormInput,
    ListGroup,
    ListGroupItem,
    Row,
} from 'shards-react';
import { useLoading, useUser } from '../../src/hooks';
import { companiesAPI } from '../apis/companiesAPI';
import { countriesAPI } from '../apis/countriesAPI';
import { employeesAPI } from '../apis/employeesAPI';
import { historiesAPI } from '../apis/historiesAPI';
import { uploadImageAPI } from '../apis/uploadImageAPI';
import { userAPI } from '../apis/userAPI';
import PageTitle from '../components/common/PageTitle';
import { ROLE, TYPE_NOTIFICATION } from '../helpers/const';

let imageProfile = '/img/user.png';

function UserProfileLite() {
    const [company, setCompany] = useState({});
    const [admin, setAdmin] = useState({});
    const [employee, setEmployee] = useState({});
    const [codeCountry, setCodeCountry] = useState([]);
    const [state, setState] = useState([]);
    const [countries, setCountries] = useState([]);
    const [imagePreviewUrl, setImagePreviewUrl] = useState();
    const [fileImage, setFileImage] = useState();
    const { user } = useUser();
    const history = useHistory();
    const role = user.role;
    const regFirstName = /^[a-zA-Z0-9._-\s]{0,30}$/;
    const regPhone = /^[0-9]{0,30}$/;

    const componentClicked = (data) => {};
    const { setLoading } = useLoading();

    const responseFacebook = async (response) => {
        if (response) {
            if (!response.accessToken) {
                toast.error('Link to your facebook fail!');
                return;
            }

            if (user && user._id) {
                const result = await userAPI.linkToFb(user._id, response.userID);
                console.log(result);
                if (result) {
                    toast.success('Link to your facebook successfully!');
                } else {
                    toast.error('Link to your facebook fail!');
                }
            }
        }
    };

    const handleRole = () => {
        if (user && user.company && role === ROLE.COMPANY) {
            setCompany(user.company);
            setCodeCountry(user.company.country);
        } else if (role === ROLE.ADMIN || role === ROLE.SYSTEMSTAFF) {
            setAdmin(user);
        } else if (user && user.employee && (role === ROLE.USER || role === ROLE.COMPANYSTAFF)) {
            setEmployee(user.employee);
        }
    };

    const changeGender = (e) => {
        if (e.target.value === 1) {
            setEmployee({ ...employee, gender: 'male' });
        } else {
            setEmployee({ ...employee, gender: 'female' });
        }
    };

    useEffect(() => {
        handleRole();
        setImagePreviewUrl(user.avatar);
    }, [user, role]);

    useEffect(() => {
        (async () => {
            const { data } = await countriesAPI.getCountries();
            setCountries(data);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const { data } = await countriesAPI.getStates(codeCountry);
            setState(data);
        })();
    }, [codeCountry]);

    const uploadImage = async (file) => {
        if (file) {
            const { data } = await uploadImageAPI.upload(file);
            return data.url;
        }
    };

    async function upLoadImage(e) {
        const file = e.target.files[0];
        setFileImage(file);
        const reader = new FileReader();
        reader.onload = (rs) => {
            setImagePreviewUrl(rs.target.result);
        };
        reader.readAsDataURL(file);
    }

    const onSubmit = () => {
        if (role === ROLE.COMPANY) {
            updateCompany();
        } else if (role === ROLE.ADMIN || role === ROLE.SYSTEMSTAFF) {
            updateAdmin();
        } else if (role === ROLE.USER || role === ROLE.COMPANYSTAFF) {
            updateUser();
        } else {
            toast.error('Submit Fail!');
        }
    };

    async function updateCompany() {
        try {
            const urlImg = await uploadImage(fileImage);
            const { data } = await userAPI.me();
            const id = data.company._id;
            const payload = {
                ...company,
                companyName: company.companyName.trim(),
                contactPerson: company.contactPerson.trim(),
            };

            const newRequest = await companiesAPI.update(id, payload);
            if (urlImg) {
                await userAPI.update(user._id, {
                    avatar: urlImg,
                });
            }
            if (
                newRequest &&
                newRequest.data &&
                newRequest.data._id &&
                user &&
                user.role &&
                user.role === ROLE.COMPANY
            ) {
                await getHistoriesCompany(newRequest.data._id);
            }
            toast.success('Updated successfully!');
            history.push('/');
        } catch (error) {
            const message = error.response.data.message;
            if (message && typeof message === 'string') {
                toast.error(<p className="text-capitalize mt-3">{message}</p>);
            } else if (message && typeof message === 'object') {
                toast.error(<p className="text-capitalize mt-3">{message[0]}</p>);
            }
        }
    }

    async function updateAdmin() {
        try {
            const urlImg = await uploadImage(fileImage);
            if (urlImg) {
                await userAPI.update(user._id, {
                    ...admin,
                    avatar: urlImg,
                });
            } else {
                const payload = { ...admin, firstName: admin.firstName.trim() };
                await userAPI.update(user._id, payload);
            }
            toast.success('Updated successfully!');
            history.push('/');
        } catch (error) {
            const message = error.response.data.message;
            if (message && typeof message === 'string') {
                toast.error(<p className="text-capitalize mt-3">{message}</p>);
            } else if (message && typeof message === 'object') {
                toast.error(<p className="text-capitalize mt-3">{message[0]}</p>);
            }
        }
    }

    async function updateUser() {
        try {
            setLoading(true);
            const urlImg = await uploadImage(fileImage);
            const { data } = await userAPI.me();
            const id = data.employee._id;
            const payload = {
                firstName: employee.firstName.trim(),
                lastName: employee.lastName.trim(),
                email: employee.email,
                gender: employee.gender,
                nationality: employee.nationality,
                nationId: employee.nationId.trim(),
                passport: employee.passport.trim(),
                birthday: employee.birthday,
            };

            if (payload.firstName.length < 2) {
                setLoading(false);
                toast.error('First Name Must Be Longer Than Or Equal To 2 Characters!');
                return;
            }
            if (payload.firstName.length > 30) {
                setLoading(false);
                toast.error('First Name Must Be Shorter Than Or Equal To 30 Characters!');
                return;
            }

            if (payload.lastName.length < 2) {
                setLoading(false);
                toast.error('Last Name Must Be Longer Than Or Equal To 2 Characters!');
                return;
            }
            if (payload.lastName.length > 30) {
                setLoading(false);
                toast.error('Last Name Must Be Shorter Than Or Equal To 30 Characters!');
                return;
            }

            const newRequest = await employeesAPI.updateProfile(id, payload);

            if (newRequest && newRequest.data && newRequest.data._id && user && user.role && user.role === ROLE.USER) {
                await getHistories(newRequest.data._id);
            }

            if (
                newRequest &&
                newRequest.data &&
                newRequest.data._id &&
                user &&
                user.role &&
                user.role === ROLE.COMPANYSTAFF
            ) {
                await getHistoriesCompanyStaff(newRequest.data._id);
            }

            if (urlImg) {
                const newRequest = await userAPI.update(user._id, {
                    avatar: urlImg,
                });
                if (
                    newRequest &&
                    newRequest.data &&
                    newRequest.data._id &&
                    user &&
                    user.role &&
                    user.role === ROLE.USER
                ) {
                    await getHistories(newRequest.data._id);
                }

                if (
                    newRequest &&
                    newRequest.data &&
                    newRequest.data._id &&
                    user &&
                    user.role &&
                    user.role === ROLE.COMPANYSTAFF
                ) {
                    await getHistoriesCompanyStaff(newRequest.data._id);
                }
            }
            setLoading(false);
            toast.success('Updated successfully!');
            history.push('/');
        } catch (error) {
            setLoading(false);
            const message = error.response.data.message;
            if (message && typeof message === 'string') {
                toast.error(<p className="text-capitalize mt-3">{message}</p>);
            } else if (message && typeof message === 'object') {
                toast.error(<p className="text-capitalize mt-3">{message[0]}</p>);
            }
        }
    }

    const getHistories = async (id) => {
        await historiesAPI.add({
            title: 'UPDATE INFO',
            desc: `${user.employee.firstName} just change info`,
            employee: user.employee,
            type: TYPE_NOTIFICATION.EMPLOYEE,
            idRequest: id,
            toCompany: true,
            toCompanyStaff: true,
        });
    };

    const getHistoriesCompanyStaff = async (id) => {
        await historiesAPI.add({
            title: 'UPDATE INFO',
            desc: `Client HR ${user.employee.firstName} just change info`,
            employee: user.employee,
            type: TYPE_NOTIFICATION.EMPLOYEE,
            idRequest: id,
            toCompany: true,
        });
    };

    const getHistoriesCompany = async (id) => {
        await historiesAPI.add({
            title: 'UPDATE INFO',
            desc: `Company ${user.company.companyName} just change info`,
            type: TYPE_NOTIFICATION.COMPANY,
            idRequest: id,
            toAdmin: true,
            toSystemStaff: true,
        });
    };

    const handleUpload = () => {
        const input = document.getElementById('input');
        input.click();
    };

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle title="User Profile" subtitle="Overview" md="12" className="ml-sm-auto mr-sm-auto" />
            </Row>
            <Row>
                <Col lg="4">
                    <Card small className="mb-4 pt-3">
                        <CardHeader className="border-bottom text-center">
                            <div className="mb-3 mx-auto position-relative">
                                <div className="image_box">
                                    <img src={imagePreviewUrl ? imagePreviewUrl : imageProfile} alt="" id="img" />
                                </div>

                                <div className="input_avt_profile">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        name="image-upload"
                                        id="input"
                                        onChange={(e) => upLoadImage(e)}
                                    />
                                    <img src="/img/camera.png" alt="camera" onClick={() => handleUpload()} />
                                </div>
                            </div>

                            <h4 className="mb-3">
                                {(role === ROLE.ADMIN || role === ROLE.SYSTEMSTAFF) && <>{admin.firstName}</>}
                                {role === ROLE.COMPANY && <>{company.companyName}</>}
                                {(role === ROLE.USER || role === ROLE.COMPANYSTAFF) && <>{employee.firstName}</>}
                            </h4>
                            <div className="mb-1">
                                <FacebookLogin
                                    appId="799994011245484"
                                    //appId={process.env.FACEBOOK_KEY}
                                    fields="name,email,picture"
                                    callback={responseFacebook}
                                    onClick={componentClicked}
                                    render={(renderProps) => (
                                        <a className="py-0" onClick={renderProps.onClick}>
                                            <i className="material-icons">facebook</i>
                                            <span className="text-icon-fb">Link to Facebook</span>
                                        </a>
                                    )}
                                />
                            </div>
                        </CardHeader>
                    </Card>
                </Col>

                <Col lg="8">
                    <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Account Details</h6>
                        </CardHeader>
                        <ListGroup flush>
                            <ListGroupItem className="p-3">
                                <Row>
                                    <Col>
                                        <Form>
                                            {(role === ROLE.ADMIN || role === ROLE.SYSTEMSTAFF) && (
                                                <Form>
                                                    <Row form>
                                                        {/* First Name */}
                                                        <Col md="6" className="form-group">
                                                            <label htmlFor="feFirstName">Name</label>
                                                            <FormInput
                                                                id="feFirstName"
                                                                placeholder="First Name"
                                                                value={admin.firstName}
                                                                onChange={(e) => {
                                                                    if (!regFirstName.test(e.target.value)) {
                                                                        toast.error('Cannot enter special characters');
                                                                        return;
                                                                    }
                                                                    setAdmin({
                                                                        ...admin,
                                                                        firstName: e.target.value,
                                                                    });
                                                                }}
                                                            />
                                                        </Col>
                                                        {/* Email */}
                                                        <Col md="6" className="form-group">
                                                            <label htmlFor="feLastName">Email</label>
                                                            <FormInput
                                                                id="feLastName"
                                                                placeholder="Email"
                                                                value={admin.email}
                                                                // onChange={() => {
                                                                //     toast.error('Email editing is not allowed!');
                                                                //     return;
                                                                // }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            )}

                                            {(role === ROLE.USER || role === ROLE.COMPANYSTAFF) && (
                                                <Row form>
                                                    {/* First Name */}
                                                    <Col md="6" className="form-group">
                                                        <label htmlFor="feFirstName">First Name</label>
                                                        <FormInput
                                                            id="feFirstName"
                                                            placeholder="First Name"
                                                            value={employee.firstName}
                                                        />
                                                    </Col>
                                                    {/* Last Name */}
                                                    <Col md="6" className="form-group">
                                                        <label htmlFor="feLastName">Last Name</label>
                                                        <FormInput
                                                            id="feLastName"
                                                            placeholder="Last Name"
                                                            value={employee.lastName}
                                                        />
                                                    </Col>
                                                    <Col md="6" className="form-group">
                                                        <label htmlFor="feEmail">Email</label>
                                                        <Input
                                                            type="email"
                                                            id="feEmail"
                                                            placeholder="Email Address"
                                                            value={employee.email}
                                                            autoComplete="email"
                                                            disable={true}
                                                        />
                                                    </Col>

                                                    <Col md="6" className="form-group">
                                                        <label htmlFor="feNCp">Company</label>
                                                        <Input
                                                            type="text"
                                                            id="feNCp"
                                                            placeholder="Name Company"
                                                            value={
                                                                employee.company
                                                                    ? employee.company.companyName
                                                                    : 'Name Company'
                                                            }
                                                        />
                                                    </Col>
                                                    <Col md="6" className="form-group">
                                                        <label htmlFor="fePass">Gender</label>

                                                        <div>
                                                            <Radio.Group
                                                                value={employee.gender === 'male' ? 1 : 2}
                                                                id="gender"
                                                            >
                                                                <Radio value={1} id="male">
                                                                    <p style={{ color: '#000' }}>Male</p>
                                                                </Radio>
                                                                <Radio value={2} id="female">
                                                                    <p style={{ color: '#000' }}>Female</p>
                                                                </Radio>
                                                            </Radio.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md="6" className="form-group">
                                                        <label htmlFor="feDoB">Date of Birth</label>
                                                        <FormInput
                                                            type="text"
                                                            id="feDoB"
                                                            placeholder="Date of Birth"
                                                            value={moment(employee.birthday).format('MM/DD/YYYY')}
                                                        />
                                                    </Col>
                                                    <Col md="6" className="form-group">
                                                        <label htmlFor="feJob">Job Position</label>
                                                        <Input
                                                            type="text"
                                                            id="feJob"
                                                            placeholder="Job Position"
                                                            value={employee.jobPosition}
                                                        />
                                                    </Col>
                                                    <Col md="6" className="form-group">
                                                        <label htmlFor="feNid">Nation ID</label>
                                                        <FormInput
                                                            type="text"
                                                            id="feNid"
                                                            placeholder="Nation ID"
                                                            value={employee.nationId}
                                                        />
                                                    </Col>
                                                    <Col md="6" className="form-group">
                                                        <label htmlFor="feN">Nationality</label>
                                                        {/* <FormInput
                                                            type="text"
                                                            id="feN"
                                                            placeholder="Nationality"
                                                            value={employee.nationality}
                                                            onChange={(e) =>
                                                                setEmployee({
                                                                    ...employee,
                                                                    nationality: e.target.value,
                                                                })
                                                            }
                                                        /> */}
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            value={employee.nationality}
                                                            id="nationality"
                                                        >
                                                            {countries &&
                                                                countries.map((item, idx) => (
                                                                    <Select.Option key={item._id} value={item.iso2}>
                                                                        {item.name}
                                                                    </Select.Option>
                                                                ))}
                                                        </Select>
                                                    </Col>
                                                    <Col md="6" className="form-group">
                                                        <label htmlFor="feP">Passport</label>
                                                        <FormInput
                                                            type="text"
                                                            id="feP"
                                                            placeholder="Passport"
                                                            value={employee.passport}
                                                        />
                                                    </Col>
                                                </Row>
                                            )}

                                            {role === ROLE.COMPANY && (
                                                <Form>
                                                    <Row form>
                                                        {/* First Name */}
                                                        <Col md="6" className="form-group">
                                                            <label htmlFor="feFirstName">Company Name</label>
                                                            <FormInput
                                                                id="feFirstName"
                                                                placeholder="First Name"
                                                                value={company.companyName}
                                                                onChange={(e) => {
                                                                    // if (!regFirstName.test(e.target.value)) {
                                                                    //     toast.error(
                                                                    //         'Cannot enter special characters',
                                                                    //     );
                                                                    //     return;
                                                                    // }
                                                                    setCompany({
                                                                        ...company,
                                                                        companyName: e.target.value,
                                                                    });
                                                                }}
                                                            />
                                                        </Col>

                                                        <Col md="6" className="form-group">
                                                            <label htmlFor="feLastName">Contact Person</label>
                                                            <FormInput
                                                                id="feLastName"
                                                                placeholder=""
                                                                value={company.contactPerson}
                                                                onChange={(e) => {
                                                                    // if (!regFirstName.test(e.target.value)) {
                                                                    //     toast.error(
                                                                    //         'Cannot enter special characters',
                                                                    //     );
                                                                    //     return;
                                                                    // }
                                                                    setCompany({
                                                                        ...company,
                                                                        contactPerson: e.target.value,
                                                                    });
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row form>
                                                        {/* Email */}
                                                        <Col md="6" className="form-group">
                                                            <label htmlFor="feEmail">Email</label>
                                                            <FormInput
                                                                type="email"
                                                                id="feEmail"
                                                                placeholder="Email Address"
                                                                value={company.email}
                                                                autoComplete="email"
                                                                disable={true}
                                                            />
                                                        </Col>

                                                        <Col md="6" className="form-group">
                                                            <label htmlFor="nphone">Mobile Phone</label>
                                                            <FormInput
                                                                id="nphone"
                                                                placeholder="Number Phone"
                                                                type="number"
                                                                value={company.mobileNumber}
                                                                onChange={(e) => {
                                                                    // if (!regPhone.test(e.target.value)) {
                                                                    //     toast.error('Cannot enter characters');
                                                                    //     return;
                                                                    // }
                                                                    setCompany({
                                                                        ...company,
                                                                        mobileNumber: e.target.value,
                                                                    });
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row form>
                                                        <Col md="10">
                                                            <FormGroup>
                                                                <label htmlFor="feAddress">Address</label>
                                                                <FormInput
                                                                    id="feAddress"
                                                                    placeholder="Address"
                                                                    value={company.address}
                                                                    onChange={(e) =>
                                                                        setCompany({
                                                                            ...company,
                                                                            address: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="2" className="form-group">
                                                            <label htmlFor="feCountry">Country</label>
                                                            <Select
                                                                style={{ width: '100%' }}
                                                                value={company.country}
                                                                onChange={(value) => {
                                                                    setCompany({ ...company, country: value });
                                                                    setCodeCountry(value);
                                                                }}
                                                                id="nationality"
                                                            >
                                                                {countries &&
                                                                    countries.map((item, idx) => (
                                                                        <Select.Option key={item._id} value={item.iso2}>
                                                                            {item.name}
                                                                        </Select.Option>
                                                                    ))}
                                                            </Select>
                                                        </Col>
                                                    </Row>
                                                    <Row form>
                                                        {/* City */}
                                                        <Col md="6" className="form-group">
                                                            <label htmlFor="feCity">City</label>
                                                            <FormInput
                                                                id="feCity"
                                                                placeholder="City"
                                                                value={company.city}
                                                                onChange={(e) =>
                                                                    setCompany({
                                                                        ...company,
                                                                        city: e.target.value,
                                                                    })
                                                                }
                                                            />
                                                        </Col>
                                                        {/* State */}
                                                        <Col md="4" className="form-group">
                                                            <label htmlFor="feState">State</label>
                                                            <Select
                                                                style={{ width: '100%' }}
                                                                value={company.state}
                                                                onChange={(value) =>
                                                                    setCompany({ ...company, state: value })
                                                                }
                                                                id="nationality"
                                                            >
                                                                {state &&
                                                                    state.map((item, idx) => (
                                                                        <Select.Option key={item._id} value={item.iso2}>
                                                                            {item.name}
                                                                        </Select.Option>
                                                                    ))}
                                                            </Select>
                                                        </Col>

                                                        {/* Zip Code */}
                                                        <Col md="2" className="form-group">
                                                            <label htmlFor="feZipCode">Zip</label>
                                                            <FormInput
                                                                id="feZipCode"
                                                                placeholder="Zip"
                                                                value={company.postalCode}
                                                                onChange={(e) =>
                                                                    setCompany({
                                                                        ...company,
                                                                        postalCode: e.target.value,
                                                                    })
                                                                }
                                                            />
                                                        </Col>

                                                        <Col md="12" className="form-group">
                                                            <label htmlFor="feURL">Website URL</label>
                                                            <FormInput
                                                                id="feURL"
                                                                placeholder="Web URL"
                                                                value={company.websiteUrl}
                                                                onChange={(e) =>
                                                                    setCompany({
                                                                        ...company,
                                                                        websiteUrl: e.target.value,
                                                                    })
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            )}
                                            <Button theme="accent" onClick={onSubmit}>
                                                Update Account
                                            </Button>
                                        </Form>
                                    </Col>
                                </Row>
                            </ListGroupItem>
                        </ListGroup>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default UserProfileLite;
