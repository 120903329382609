export const transfersMinutesToHours = (minutes) => {
    let h = 0;
    let m = 0;

    h = Math.floor(minutes / 60);

    m = minutes - h * 60;

    return `${h} hours ${m} minutes`;
};
