import { Checkbox, Modal, Select, Space, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { companiesAPI } from '../../apis/companiesAPI';
import { countriesAPI } from '../../apis/countriesAPI';
import { countryAPI } from '../../apis/countryAPI';
import { employeesAPI } from '../../apis/employeesAPI';
import { historiesAPI } from '../../apis/historiesAPI';
import { userAPI } from '../../apis/userAPI';
import '../../assets/components/company.css';
import { ROLE, TYPE_NOTIFICATION } from '../../helpers/const';
import { useUser } from '../../hooks';

const { Option } = Select;

function TableCompanies({ tableData = [], fetchData, deleteList, setDeleteList, setPage, page }) {
    const [data, setData] = useState([]);
    const [size, setSize] = useState(0);
    const [total, setTotal] = useState(0);
    const [open, setOpen] = useState(false);
    const [companiesId, setCompaniesId] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [columns, setColumns] = useState([]);

    const { user } = useUser();

    const showModal = () => {
        setOpen(true);
    };

    const hideModal = () => {
        deleteCompany(companiesId);
        setOpen(false);
    };

    const changeStatus = async (item, row) => {
        try {
            const id = row._id;
            let st = '';
            if (item.value === 'active') {
                st = true;
            } else if (item.value === 'inactive') {
                st = false;
            }
            await companiesAPI.update(id, { status: st });
            toast.success('Updated successfully!');
            fetchData();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (tableData && tableData.items) {
            pushData(tableData.items);
        }
    }, [tableData]);

    const pushData = async (data) => {
        let tmp = [];
        let i = 0;
        for await (const item of data) {
            // let { country, ...payload } = item;
            // country = await (await countryAPI.getById(country)).name;

            tmp.push({
                ...item,
                indexing: ((tableData.paginate.page || 1) - 1) * 10 + i + 1,
            });
            i++;
        }
        setData(tmp);
    };

    useEffect(() => {
        if (tableData && tableData.paginate && tableData.paginate.count && tableData.paginate.size) {
            setTotal(tableData.paginate.count);
            setSize(tableData.paginate.size);
        }
    }, [tableData]);

    const initColumns = [
        {
            title: '#',
            dataIndex: 'indexing',
            key: 'indexing',
            render: (_, row) => {
                return (
                    <div className="content_td_mobile">
                        <span className="title_mobile">#</span>
                        <span>{row.indexing}</span>
                    </div>
                );
            },
        },
        {
            title: 'Current Name',
            dataIndex: 'companyName',
            key: 'name',
            render: (_, record) => {
                return (
                    <div className="content_td_mobile">
                        <span className="title_mobile">Current Name</span>
                        <div className="company-name">
                            <div className="avatar">
                                {record.user.avatar ? <img src={record.user.avatar} /> : <span></span>}
                            </div>
                            <span>{record.companyName}</span>
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
            render: (_, row) => {
                return (
                    <div className="content_td_mobile">
                        <span className="title_mobile">Country</span>
                        <span>{row && row.country && row.country.name ? row.country.name : ''}</span>
                    </div>
                );
            },
        },
        {
            title: 'Contact number',
            dataIndex: 'phoneNumber',
            key: 'contactNumber',
            render: (numberPhone) => {
                return (
                    <div className="content_td_mobile">
                        <span className="title_mobile">Contact number</span>
                        <span>{numberPhone}</span>
                    </div>
                );
            },
        },
        {
            title: 'E-Mail',
            dataIndex: 'email',
            key: 'email',
            render: (email) => {
                return (
                    <div className="content_td_mobile">
                        <span className="title_mobile">Email</span>
                        <span>{email}</span>
                    </div>
                );
            },
        },
        {
            title: 'Status',
            key: 'tags',
            dataIndex: '_id',
            render: (id, row) => (
                <div className="content_td_mobile">
                    <div className="title_mobile">Status</div>
                    <Select
                        bordered={false}
                        labelInValue
                        suffixIcon={<AiOutlineCaretDown />}
                        defaultValue={row.status === true ? 'active' : 'inactive'}
                        style={{
                            color: row.status === true ? '#52C41A' : '#999999',
                            width: 120,
                        }}
                        onChange={(item) => changeStatus(item, row)}
                    >
                        <Option
                            value="active"
                            style={{
                                backgroundColor: 'transparent',
                            }}
                        >
                            <Tag color="success">Active </Tag>
                        </Option>
                        <Option
                            value="inactive"
                            style={{
                                backgroundColor: '#FAFBFC',
                                color: '#999999',
                            }}
                        >
                            <Tag>Inactive </Tag>
                        </Option>
                    </Select>
                </div>
            ),
        },
        {
            title: 'Actions',
            key: 'action',
            dataIndex: '_id',
            fixed: 'right',
            render: (_, record) => {
                return (
                    <div className="content_td_mobile">
                        <div className="title_mobile">Actions </div>
                        <Space size="middle">
                            {/* {localStorage.getItem('role') && localStorage.getItem('role') === ROLE.ADMIN && (
            <> */}
                            <Link to={`/edit-companies/${_}`}>
                                <span className="material-icons icon_edit">edit</span>
                            </Link>
                            {user && user.role && user.role === ROLE.ADMIN && (
                                <span className="material-icons icon_delete">
                                    <button className="border border-0" onClick={(id) => warning(record)}>
                                        delete_forever
                                    </button>
                                </span>
                            )}

                            {/* </>
          )} */}
                        </Space>
                    </div>
                );
            },
        },
    ];

    const managerColumns = [
        {
            title: '',
            dataIndex: '_id',
            key: 'id',
            render: (id, row) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile"></p>
                        <Checkbox onChange={(e) => onChange(e, id)} />
                    </div>
                );
            },
        },
        ...initColumns,
    ];

    useEffect(() => {
        let flag = true;
        if (flag === true) {
            if (user && user.role && user.role === ROLE.ADMIN) {
                setColumns(managerColumns);
            }
            if (user && user.role && user.role === ROLE.SYSTEMSTAFF) {
                setColumns(initColumns);
            }
        }
    }, [user]);

    const warning = (data) => {
        setCompaniesId(data._id);
        setCompanyName(data.companyName);
        showModal();
    };

    const deleteCompany = async (id) => {
        try {
            const { data } = await employeesAPI.getByCompany(id);

            let userList = [];
            let employeeList = [];

            data.items.map((i) => {
                userList = [...userList, i.user._id];
                employeeList = [...employeeList, i._id];
            });
            await Promise.all(
                userList.map(async (u) => {
                    await userAPI._delete(u);
                }),
            );

            await Promise.all(
                employeeList.map(async (e) => {
                    await employeesAPI._delete(e);
                }),
            );
            await companiesAPI.deleteCompany(id);
            onChangePage(1);
            toast.success('Deleted successfully!');
            fetchData();
        } catch (error) {
            console.log(error);
        }
    };

    const onChange = async (e, id) => {
        try {
            setDeleteList((prev) => {
                const checked = e.target.checked;
                if (checked === true) {
                    return [...prev, id];
                } else if (checked === false) {
                    return prev.filter((item) => item !== id);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const onChangePage = (page) => {
        setPage(page);
    };

    return (
        <div className="overflow-hidden">
            <Table
                className="ct-table-data"
                columns={columns}
                dataSource={
                    data &&
                    data.map((item) => {
                        return { ...item, key: item._id };
                    })
                }
                pagination={{ current: page, onChange: onChangePage, pageSize: size, total }}
            />

            <Modal
                title={
                    <>
                        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.4167 0.833344H2.58333C1.56583 0.833344 0.75 1.65834 0.75 2.66668V15.5C0.75 16.5083 1.56583 17.3333 2.58333 17.3333H6.25L9 20.0833L11.75 17.3333H15.4167C16.425 17.3333 17.25 16.5083 17.25 15.5V2.66668C17.25 1.65834 16.425 0.833344 15.4167 0.833344ZM9.91667 15.5H8.08333V13.6667H9.91667V15.5ZM11.8142 8.39584L10.9892 9.23918C10.3292 9.90834 9.91667 10.4583 9.91667 11.8333H8.08333V11.375C8.08333 10.3667 8.49583 9.45001 9.15583 8.78084L10.2925 7.62584C10.6317 7.29584 10.8333 6.83751 10.8333 6.33334C10.8333 5.32501 10.0083 4.50001 9 4.50001C7.99167 4.50001 7.16667 5.32501 7.16667 6.33334H5.33333C5.33333 4.30751 6.97417 2.66668 9 2.66668C11.0258 2.66668 12.6667 4.30751 12.6667 6.33334C12.6667 7.14001 12.3367 7.87334 11.8142 8.39584Z"
                                fill="#FCBB0A"
                            />
                        </svg>

                        <p className="title-modal-custom">Are you sure delete this company?</p>
                    </>
                }
                open={open}
                onOk={hideModal}
                onCancel={() => setOpen(false)}
                okText="Delete"
                cancelText="Cancel"
            >
                <p>
                    By delete <strong>{companyName}</strong> company
                </p>
            </Modal>
        </div>
    );
}

export default TableCompanies;
