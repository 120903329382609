import { createContext } from 'react';
import { DEPARTMENT_FILTER } from '../global/department/filterDepartment';
import { RESPONSE } from '../helpers/response';

const initValue = {
    department: RESPONSE,
    filterDepartment: DEPARTMENT_FILTER,
    setFilterDepartment: (filter = DEPARTMENT_FILTER) => {},
    fetchDepartment: (filter = DEPARTMENT_FILTER) => {},
};
export const DepartmentContext = createContext(initValue);
