import { Form, Modal, Select, Space } from 'antd';

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { RANK, ROLE } from '../../helpers/const';
import { Col, Row } from 'shards-react';

import { useLoading, useUser } from '../../hooks';
import { employeesAPI } from '../../apis/employeesAPI';
import { departmentAPI } from '../../apis/departmentAPI';
import { branchAPI } from '../../apis/brachAPI';
import { companiesAPI } from '../../apis/companiesAPI';
import { userAPI } from '../../apis/userAPI';

const NoticeCreateForm = ({ visible, setVisible }) => {
    // const { TextArea } = Input;
    const [form] = Form.useForm();
    const { user, getMe } = useUser();
    const { setLoading } = useLoading();
    const [to, setTo] = useState();
    const [listTo, setListTo] = useState([]);
    const [listEmployee, setListEmployee] = useState([]);
    const [listEmployeeTmp, setListEmployeeTmp] = useState([]);
    const [listIdEmployees, setListIdEmployees] = useState([]);
    const [listDepartment, setListDepartment] = useState([]);
    const [listDepartmentTmp, setListDepartmentTmp] = useState([]);
    const [listIdDepartment, setListIdDepartment] = useState([]);
    const [listBranch, setListBranch] = useState([]);
    const [listBranchTmp, setListBranchTmp] = useState([]);
    const [listIdBranch, setListIdBranch] = useState([]);
    const [listCompany, setListCompany] = useState([]);
    const [listCompanyTmp, setListCompanyTmp] = useState([]);
    const [listIdCompany, setListIdCompany] = useState([]);
    const [listSystemStaff, setListSystemStaff] = useState([]);
    const [listSystemStaffTmp, setListSystemStaffTmp] = useState([]);
    const [listIdSystemStaff, setListIdSystemStaff] = useState([]);
    const [idCompany, setIdCompany] = useState();

    const { Option } = Select;

    const handleCancel = () => {
        setVisible(false);
        form.resetFields();
        setListIdEmployees([]);
        setListIdDepartment([]);
        setListIdBranch([]);
        setListIdCompany([]);
        setListIdSystemStaff([]);
    };

    const submit = () => {
        setVisible(false);
    };

    const loadListEmployees = async (id) => {
        const { data } = await employeesAPI.getAllCompany(id);
        setListEmployee(data);
        setListEmployeeTmp(data);
    };

    const loadListDepartment = async (id) => {
        const { data } = await departmentAPI.getAllByCompany(id);
        setListDepartment(data);
        setListDepartmentTmp(data);
    };

    const loadListBranch = async (id) => {
        const { data } = await branchAPI.getByCompany(id);
        setListBranch(data);
        setListBranchTmp(data);
    };

    const loadListCompany = async () => {
        const { data } = await companiesAPI.getAll();
        setListCompany(data.items);
        setListCompanyTmp(data.items);
    };

    const loadListSystemStaff = async () => {
        const { data } = await userAPI.getSystemStaff();
        setListSystemStaff(data);
        setListSystemStaffTmp(data);
    };

    const handleChangeTo = async (e) => {
        setTo(e);
        setListIdEmployees([]);
        setListIdDepartment([]);
        setListIdBranch([]);
        setListCompany([]);
        setListSystemStaff([]);
    };

    const handleChangeEmployeesList = async (value) => {
        let isAll = false;
        value.map((item) => {
            if (item === 'all') {
                isAll = true;
            }
        });

        if (isAll) {
            setListEmployeeTmp([]);
            let tmp = [];
            listEmployee.map((item) => (tmp = [...tmp, item._id]));
            setListIdEmployees(tmp);
        } else {
            setListEmployeeTmp(listEmployee);
            setListIdEmployees(value);
        }
    };

    const handleChangeDepartmentList = async (value) => {
        let isAll = false;
        value.map((item) => {
            if (item === 'all') {
                isAll = true;
            }
        });

        if (isAll) {
            setListDepartmentTmp([]);
            let tmp = [];
            listDepartment.map((item) => (tmp = [...tmp, item._id]));
            setListIdDepartment(tmp);
        } else {
            setListDepartmentTmp(listDepartment);
            setListIdDepartment(value);
        }
    };

    const handleChangeBranchList = async (value) => {
        let isAll = false;
        value.map((item) => {
            if (item === 'all') {
                isAll = true;
            }
        });

        if (isAll) {
            setListBranchTmp([]);
            let tmp = [];
            listBranch.map((item) => (tmp = [...tmp, item._id]));
            setListIdBranch(tmp);
        } else {
            setListBranchTmp(listBranch);
            setListIdBranch(value);
        }
    };

    const handleChangeCompanyList = async (value) => {
        let isAll = false;
        value.map((item) => {
            if (item === 'all') {
                isAll = true;
            }
        });

        if (isAll) {
            setListCompanyTmp([]);
            let tmp = [];
            listCompany.map((item) => (tmp = [...tmp, item._id]));
            setListIdCompany(tmp);
        } else {
            setListCompanyTmp(listCompany);
            setListIdCompany(value);
        }
    };

    const handleChangeSystemStaffList = async (value) => {
        let isAll = false;
        value.map((item) => {
            if (item === 'all') {
                isAll = true;
            }
        });

        if (isAll) {
            setListSystemStaffTmp([]);
            let tmp = [];
            listSystemStaff.map((item) => (tmp = [...tmp, item._id]));
            setListIdSystemStaff(tmp);
        } else {
            setListSystemStaffTmp(listSystemStaff);
            setListIdSystemStaff(value);
        }
    };

    useEffect(() => {
        (async () => {
            if (!user || !user._id) {
                await getMe();
            }

            if (user && user.role && user.role === ROLE.ADMIN) {
                setListTo([RANK.SYSTEMSTAFF, RANK.COMPANY]);
            }

            if (user && user.role && user.role === ROLE.SYSTEMSTAFF) {
                setListTo([RANK.ADMIN, RANK.COMPANY]);
            }

            if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
                setListTo([
                    RANK.ADMIN,
                    RANK.SYSTEMSTAFF,
                    RANK.COMPANYSTAFF,
                    RANK.DEPARTMENT,
                    RANK.BRANCH,
                    RANK.EMPLOYEE,
                ]);
                setIdCompany(user.company._id);
            }

            if (
                user &&
                user.role &&
                user.role === ROLE.COMPANYSTAFF &&
                user.employee &&
                user.employee.company &&
                user.employee.company._id
            ) {
                setListTo([RANK.ADMIN, RANK.SYSTEMSTAFF, RANK.COMPANY, RANK.DEPARTMENT, RANK.BRANCH, RANK.EMPLOYEE]);
                setIdCompany(user.employee.company._id);
            }

            if (
                user &&
                user.role &&
                user.role === ROLE.USER &&
                user.employee &&
                user.employee.company &&
                user.employee.company._id
            ) {
                setListTo([RANK.COMPANY, RANK.COMPANYSTAFF, RANK.DEPARTMENT, RANK.BRANCH, RANK.EMPLOYEE]);
                setIdCompany(user.employee.company._id);
            }
        })();
    }, [user]);

    useEffect(() => {
        if (to && to === RANK.EMPLOYEE && idCompany) {
            loadListEmployees(idCompany);
            setListIdDepartment([]);
            setListIdBranch([]);
            setListIdCompany([]);
            setListIdSystemStaff([]);
        }
        if (to && to === RANK.DEPARTMENT && idCompany) {
            loadListDepartment(idCompany);
            setListIdEmployees([]);
            setListIdBranch([]);
            setListIdCompany([]);
            setListIdSystemStaff([]);
        }
        if (to && to === RANK.BRANCH && idCompany) {
            loadListBranch(idCompany);
            setListIdEmployees([]);
            setListIdDepartment([]);
            setListIdCompany([]);
            setListIdSystemStaff([]);
        }
        if (to && to === RANK.COMPANY) {
            loadListCompany();
            setListIdEmployees([]);
            setListIdDepartment([]);
            setListIdBranch([]);
            setListIdSystemStaff([]);
        }
        if (to && to === RANK.SYSTEMSTAFF) {
            loadListCompany();
            setListIdEmployees([]);
            setListIdDepartment([]);
            setListIdBranch([]);
            setListIdCompany([]);
        }
    }, [to, visible]);

    return (
        <Modal
            open={visible}
            title="Send new notice"
            okText="Submit"
            cancelText="Cancel"
            onCancel={handleCancel}
            width="700px"
            height="500px"
            zIndex={999}
            onOk={() => {
                submit();
            }}
        >
            <Form form={form} layout="vertical" name="form_in_modal" initialValues={{ modifier: 'public' }}>
                <Row lg="12" md="8" sm="6">
                    <Col lg="12">
                        <div className=" mb-4">
                            <Form.Item
                                name="To"
                                label="To"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please choose to!',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: '100%', zIndex: 999 }}
                                    id="to"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().includes(input.toLowerCase())
                                    }
                                    onChange={(e) => handleChangeTo(e)}
                                    value={to}
                                >
                                    {listTo.map((item, index) => (
                                        <Select.Option key={index} value={item}>
                                            {item}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>

                {to && to === RANK.COMPANY && (
                    <Row lg="12" md="8" sm="6">
                        <Col lg="12">
                            <div className=" mb-4">
                                <Form.Item
                                    name="Company"
                                    label="Company"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please choose company!',
                                        },
                                    ]}
                                >
                                    <Select
                                        mode="multiple"
                                        onChange={handleChangeCompanyList}
                                        optionLabelProp="label"
                                        style={{ width: '100%', zIndex: 999 }}
                                    >
                                        {listCompany && listCompany.length > 0 && (
                                            <Option value="all" label="All">
                                                <Space>--All--</Space>
                                            </Option>
                                        )}
                                        {listCompanyTmp.map((item) => (
                                            <Option value={item._id} label={item.companyName}>
                                                <Space>{item.companyName}</Space>
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                )}

                {to && to === RANK.DEPARTMENT && (
                    <Row lg="12" md="8" sm="6">
                        <Col lg="12">
                            <div className=" mb-4">
                                <Form.Item
                                    name="Department"
                                    label="Department"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please choose department!',
                                        },
                                    ]}
                                >
                                    <Select
                                        mode="multiple"
                                        onChange={handleChangeDepartmentList}
                                        optionLabelProp="label"
                                        style={{ width: '100%', zIndex: 999 }}
                                    >
                                        {listDepartment && listDepartment.length > 0 && (
                                            <Option value="all" label="All">
                                                <Space>--All--</Space>
                                            </Option>
                                        )}
                                        {listDepartmentTmp.map((item) => (
                                            <Option value={item._id} label={item.departmentName}>
                                                <Space>{item.departmentName}</Space>
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                )}

                {to && to === RANK.BRANCH && (
                    <Row lg="12" md="8" sm="6">
                        <Col lg="12">
                            <div className=" mb-4">
                                <Form.Item
                                    name="Branch"
                                    label="Branch"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please choose branch!',
                                        },
                                    ]}
                                >
                                    <Select
                                        mode="multiple"
                                        onChange={handleChangeBranchList}
                                        optionLabelProp="label"
                                        style={{ width: '100%', zIndex: 999 }}
                                    >
                                        {listBranch && listBranch.length > 0 && (
                                            <Option value="all" label="All">
                                                <Space>--All--</Space>
                                            </Option>
                                        )}
                                        {listBranchTmp.map((item) => (
                                            <Option value={item._id} label={item.name}>
                                                <Space>{item.name}</Space>
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                )}

                {to && to === RANK.EMPLOYEE && (
                    <Row lg="12" md="8" sm="6">
                        <Col lg="12">
                            <div className=" mb-4">
                                <Form.Item
                                    name="Employee"
                                    label="Employee"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please choose employee!',
                                        },
                                    ]}
                                >
                                    <Select
                                        mode="multiple"
                                        onChange={handleChangeEmployeesList}
                                        optionLabelProp="label"
                                        style={{ width: '100%', zIndex: 999 }}
                                    >
                                        {listEmployee && listEmployee.length > 0 && (
                                            <Option value="all" label="All">
                                                <Space>--All--</Space>
                                            </Option>
                                        )}

                                        {listEmployeeTmp.map((item) => (
                                            <Option value={item._id} label={item.firstName}>
                                                <Space>{item.firstName}</Space>
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                )}

                {to && to === RANK.SYSTEMSTAFF && (
                    <Row lg="12" md="8" sm="6">
                        <Col lg="12">
                            <div className=" mb-4">
                                <Form.Item
                                    name="System staff"
                                    label="System staff"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please choose system staff!',
                                        },
                                    ]}
                                >
                                    <Select
                                        mode="multiple"
                                        onChange={handleChangeSystemStaffList}
                                        optionLabelProp="label"
                                        style={{ width: '100%', zIndex: 999 }}
                                    >
                                        {listSystemStaff && listSystemStaff.length > 0 && (
                                            <Option value="all" label="All">
                                                <Space>--All--</Space>
                                            </Option>
                                        )}

                                        {listSystemStaffTmp.map((item) => (
                                            <Option value={item._id} label={item.firstName}>
                                                <Space>{item.firstName}</Space>
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                )}
            </Form>
        </Modal>
    );
};

export default NoticeCreateForm;
