import { Card, CardHeader, Row, Col, Container } from 'shards-react';
import { Modal, Divider, Switch, Input, Button, Select, Alert } from 'antd';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabLeaveType from './TabLeaveType';
import { AiFillPlusCircle } from 'react-icons/ai';
import TabLeaveSettings from './TabLeaveSettings';
import PageTitle from './../common/PageTitle';
import { useLoading, useUser } from '../../hooks';
import { leavesSettingAPI } from '../../apis/leavesSettingAPI';
import { toast } from 'react-toastify';
import { ROLE } from '../../helpers/const';
import { useHistory, useLocation } from 'react-router-dom';
import { getError } from '../../helpers/getErrorServer';
import { CATEGORY_LEAVE, LEAVE_DAY_COUNT, LEAVE_UNIT } from '../../global/leave/leaveEnum';

const { Option } = Select;
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function LeaveSetting() {
    const [value, setValue] = React.useState(0);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const history = useHistory();
    const [data, setData] = useState();
    const location = useLocation();
    const { user, getMe } = useUser();
    const { setLoading } = useLoading();
    const [unit, setUnit] = useState('');

    useEffect(() => {
        if (!user) {
            getMe();
        }
        user &&
            user.role &&
            user.role !== ROLE.COMPANY &&
            user.role !== ROLE.COMPANYSTAFF &&
            user.role !== ROLE.USER &&
            history.push('/login');
    }, [user]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [leaveAdd, setLeaveAdd] = useState();

    useEffect(() => {
        if (leaveAdd && leaveAdd.leaveUnit && leaveAdd.leaveUnit === LEAVE_UNIT.HOURS) {
            setUnit('(hours)');
        } else if (leaveAdd && leaveAdd.leaveUnit && leaveAdd.leaveUnit !== LEAVE_UNIT.HOURS) {
            setUnit('(days)');
        }
    }, [leaveAdd]);

    const validate = () => {
        let result = true;
        // if (
        //     (!leaveAdd.standardNumber || +leaveAdd.standardNumber < 1) &&
        //     leaveAdd.leaveUnit &&
        //     leaveAdd.leaveUnit !== LEAVE_UNIT.SPECIAL
        // ) {
        //     toast.error('Standard number of leave must be greater than 0');
        //     result = false;
        // }

        if (!leaveAdd.code || leaveAdd.code.length < 1) {
            toast.error('Code should not be empty');
            result = false;
        }

        if (!leaveAdd.description || leaveAdd.description.length < 1) {
            toast.error('Description should not be empty');
            result = false;
        }
        return result;
    };

    const handleAdd = async () => {
        if (!user) {
            getMe();
        }
        if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
            try {
                const check = validate();
                if (check) {
                    setLoading(true);
                    await leavesSettingAPI.add({ companyID: user.company._id, ...leaveAdd });
                    setLoading(false);
                    toast.success('Add success');
                    setIsModalOpen(false);
                    // history.push('/employers/leave-settings');
                    fetchData();
                    setLeaveAdd({});
                } else {
                    return;
                }
            } catch (error) {
                setLoading(false);
                toast.error(getError(error));
            }
        }
    };

    const fetchData = async () => {
        if (!user) {
            getMe();
        }

        if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
            //const { data: tmp } = await leavesSettingAPI.findAndCreate(user.company._id);

            const { data } = await leavesSettingAPI.getByCompany(user.company._id);

            //const arr = data.filter((item) => item._id !== tmp._id);

            setData(data);
        }

        if (
            user &&
            user.role &&
            (user.role === ROLE.COMPANYSTAFF || user.role === ROLE.USER) &&
            user.employee &&
            user.employee.company &&
            user.employee.company._id
        ) {
            //const { data: tmp } = await leavesSettingAPI.findAndCreate(user.employee.company._id);

            const { data } = await leavesSettingAPI.getByCompany(user.employee.company._id);

            //const arr = data.filter((item) => item._id !== tmp._id);

            setData(data);
        }
    };

    useEffect(() => {
        let flag = true;
        if (user && (user.role !== ROLE.ADMIN || user.role !== ROLE.SYSTEMSTAFF) && flag === true) {
            fetchData();
        }
        return () => {
            flag = false;
        };
    }, [user, location]);

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <Col>
                    <PageTitle
                        title="Leave settings"
                        subtitle="Company settings/ Leave settings"
                        className="text-sm-left mb-3"
                    />
                </Col>

                {user && user.role && user.role === ROLE.COMPANY && (
                    <Col xl="2" lg="3" md="6" sm="12">
                        <div
                            className="text-center rounded p-2 d-flex justify-content-center align-items-center button__warning"
                            onClick={showModal}
                        >
                            <AiFillPlusCircle size={25} />
                            <span className="ml-2"> Add Leave Type</span>
                        </div>
                    </Col>
                )}
            </Row>
            <Card>
                <CardHeader className="border-bottom">
                    <span className="ct-title">Leave settings</span>
                </CardHeader>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label=" Leave type" {...a11yProps(0)} />
                            <Tab label="  Setting" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <TabLeaveType data={data} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <TabLeaveSettings />
                    </TabPanel>
                </Box>
            </Card>
            <Modal
                title="Add leave type"
                centered
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={false}
                width={1000}
                zIndex={999}
            >
                <div
                    style={{
                        padding: ' 0px 70px',
                    }}
                >
                    <Row lg="12">
                        <Col lg="6" md="6" sm="12">
                            <label id="code">Code</label>
                            <Input
                                for="code"
                                placeholder="ANNUAL"
                                value={leaveAdd && leaveAdd.code && leaveAdd.code}
                                onChange={(e) => setLeaveAdd({ ...leaveAdd, code: e.target.value })}
                                rules={[{ required: true, message: 'Please input code!' }]}
                            />
                        </Col>
                        <Col lg="6" md="6" sm="12">
                            <label id="des">Description</label>
                            <Input
                                for="des"
                                placeholder="Annual Leave"
                                value={leaveAdd && leaveAdd.description && leaveAdd.description}
                                onChange={(e) => setLeaveAdd({ ...leaveAdd, description: e.target.value })}
                                rules={[{ required: true, message: 'Please input description!' }]}
                            />
                        </Col>
                    </Row>
                    <ul className="p-0 mt-4">
                        <li className="d-flex justify-content-lg-between align-items-center">
                            <p>Active</p>
                            <Switch
                                defaultChecked
                                onChange={(e) => setLeaveAdd({ ...leaveAdd, active: e })}
                                value={leaveAdd && leaveAdd.active && leaveAdd.active}
                            />
                        </li>
                    </ul>
                    <Row lg="12">
                        <Col lg="6" md="6" sm="12">
                            <label id="uni">Leave Unit</label>
                            <Select
                                defaultValue="day"
                                style={{
                                    width: '100%',
                                }}
                                value={leaveAdd && leaveAdd.leaveUnit && leaveAdd.leaveUnit}
                                onChange={(e) => {
                                    let tmp = { ...leaveAdd, leaveUnit: e };
                                    if (e === LEAVE_UNIT.SPECIAL) {
                                        tmp = {
                                            ...tmp,
                                            paidLeave: true,
                                            standardNumber: 0,
                                            allowNegativeBalance: true,
                                            applyWorkday: 0,
                                            allowEmergencyLeave: true,
                                            dayCount: LEAVE_DAY_COUNT.WORKDAY,
                                        };
                                    }
                                    if (e === LEAVE_UNIT.HOURS) {
                                        tmp = {
                                            ...tmp,
                                            dayCount: LEAVE_DAY_COUNT.WORKDAY,
                                        };
                                    }
                                    setLeaveAdd(tmp);
                                }}
                            >
                                <Option value={LEAVE_UNIT.DAY}>Day</Option>
                                <Option value={LEAVE_UNIT.HALF_DAY}>Half day</Option>
                                <Option value={LEAVE_UNIT.HOURS}>Hours</Option>
                                <Option value={LEAVE_UNIT.SPECIAL}>Special award</Option>
                            </Select>
                        </Col>
                        <Col lg="6" md="6" sm="12">
                            <label id="coun">Day count</label>
                            <Select
                                defaultValue="work-day"
                                style={{
                                    width: '100%',
                                }}
                                value={leaveAdd && leaveAdd.dayCount && leaveAdd.dayCount}
                                onChange={(e) => setLeaveAdd({ ...leaveAdd, dayCount: e })}
                                disabled={
                                    leaveAdd &&
                                    leaveAdd.leaveUnit &&
                                    (leaveAdd.leaveUnit === LEAVE_UNIT.HOURS ||
                                        leaveAdd.leaveUnit === LEAVE_UNIT.SPECIAL)
                                }
                            >
                                <Option value={LEAVE_DAY_COUNT.WORKDAY}>Workday</Option>
                                <Option value={LEAVE_DAY_COUNT.CALENDAR_DAY}>Calendar Day</Option>
                            </Select>
                        </Col>
                    </Row>
                    <ul className="p-0 mt-4">
                        <li className="d-flex justify-content-lg-between align-items-center">
                            <p>Paid Leave</p>
                            <Switch
                                disabled={leaveAdd && leaveAdd.leaveUnit && leaveAdd.leaveUnit === LEAVE_UNIT.SPECIAL}
                                onChange={(e) => {
                                    setLeaveAdd({ ...leaveAdd, paidLeave: e });
                                }}
                                value={leaveAdd && leaveAdd.paidLeave && leaveAdd.paidLeave}
                                checked={leaveAdd && leaveAdd.paidLeave && leaveAdd.paidLeave === true ? true : false}
                            />
                        </li>
                    </ul>

                    <Row lg="12">
                        <Col lg="6" md="6" sm="12">
                            <label id="number">Standard number of leave {unit}</label>
                            <Input
                                for="number"
                                placeholder="0"
                                type="number"
                                value={leaveAdd && leaveAdd.standardNumber && leaveAdd.standardNumber}
                                onChange={(e) => setLeaveAdd({ ...leaveAdd, standardNumber: +e.target.value })}
                                rules={[{ required: true, message: 'Please input standard number of leave!' }]}
                                disabled={leaveAdd && leaveAdd.leaveUnit && leaveAdd.leaveUnit === LEAVE_UNIT.SPECIAL}
                            />
                        </Col>
                        <Col lg="6" md="6" sm="12">
                            <label id="category">Category</label>
                            <Select
                                style={{
                                    width: '100%',
                                }}
                                value={leaveAdd && leaveAdd.category && leaveAdd.category}
                                onChange={(e) => setLeaveAdd({ ...leaveAdd, category: e })}
                            >
                                {CATEGORY_LEAVE.map((item, index) => (
                                    <Option value={item.value} key={index}>
                                        {item.title}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>

                    <Divider></Divider>

                    <h6 className="mb-3">APPLICATION POLICY</h6>
                    <ul className="p-0">
                        <li className="d-flex justify-content-lg-between align-items-center">
                            <p>Allow Negative Balance</p>
                            <Switch
                                checked={
                                    leaveAdd && leaveAdd.allowNegativeBalance && leaveAdd.allowNegativeBalance === true
                                        ? true
                                        : false
                                }
                                onChange={(e) => setLeaveAdd({ ...leaveAdd, allowNegativeBalance: e })}
                                value={leaveAdd && leaveAdd.allowNegativeBalance && leaveAdd.allowNegativeBalance}
                                disabled={leaveAdd && leaveAdd.leaveUnit && leaveAdd.leaveUnit === LEAVE_UNIT.SPECIAL}
                            />
                        </li>

                        <li className="d-flex justify-content-lg-between align-items-center my-3">
                            <p>Reason Required</p>
                            <Switch
                                onChange={(e) => setLeaveAdd({ ...leaveAdd, reasonRequired: e })}
                                value={leaveAdd && leaveAdd.reasonRequired && leaveAdd.reasonRequired}
                            />
                        </li>
                        <li className="d-flex justify-content-lg-between align-items-center">
                            <p>Attachment Required</p>
                            <Switch
                                onChange={(e) => setLeaveAdd({ ...leaveAdd, attachmentRequired: e })}
                                value={leaveAdd && leaveAdd.attachmentRequired && leaveAdd.attachmentRequired}
                            />
                        </li>
                    </ul>
                    <Divider></Divider>

                    <h6>RULES</h6>
                    <div className="d-flex justify-content-left align-items-center">
                        <span
                            style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                backgroundColor: '#0c415b',
                                display: 'grid',
                                placeItems: 'center',
                                color: '#fff',
                                marginRight: '25px',
                            }}
                        >
                            0
                        </span>
                        <div>For ALL leaves, apply ANYTIME.</div>
                    </div>
                    <div className="d-flex justify-content-left  mt-2">
                        <span
                            style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                backgroundColor: '#0c415b',
                                display: 'grid',
                                placeItems: 'center',
                                color: '#fff',
                                marginRight: '10px',
                            }}
                        >
                            2
                        </span>
                        {/* <Col>
                            <label id="uni">For (X) days or more</label>
                            <Select
                                defaultValue="1"
                                style={{
                                    width: '100%',
                                }}
                                value={leaveAdd && leaveAdd.forDay && leaveAdd.forDay}
                                onChange={(e) => setLeaveAdd({ ...leaveAdd, forDay: e })}
                            >
                                <Option value="0">0</Option>
                                <Option value="1">1</Option>
                                <Option value="2">2</Option>
                                <Option value="3">3</Option>
                                <Option value="4">4</Option>
                                <Option value="5">5</Option>
                            </Select>
                        </Col> */}
                        <Col lg="6" md="6" sm="12">
                            <label id="coun">Apply (X) workday in advance</label>
                            <Select
                                defaultValue="1"
                                style={{
                                    width: '100%',
                                }}
                                value={leaveAdd && leaveAdd.applyWorkday && leaveAdd.applyWorkday}
                                onChange={(e) => setLeaveAdd({ ...leaveAdd, applyWorkday: e })}
                                disabled={leaveAdd && leaveAdd.leaveUnit && leaveAdd.leaveUnit === LEAVE_UNIT.SPECIAL}
                            >
                                <Option value="0">0</Option>
                                <Option value="1">1</Option>
                                <Option value="2">2</Option>
                                <Option value="3">3</Option>
                                <Option value="4">4</Option>
                                <Option value="5">5</Option>
                            </Select>
                        </Col>
                    </div>

                    <ul className="p-0 my-4">
                        <li className="d-flex justify-content-lg-between align-items-center">
                            <div className="d-flex justify-content-left align-items-center">
                                <span className="material-icons    mr-2">alarm_add</span>
                                <span>Allow Emergency Leave</span>
                            </div>

                            <Switch
                                onChange={(e) => setLeaveAdd({ ...leaveAdd, allowEmergencyLeave: e })}
                                value={leaveAdd && leaveAdd.allowEmergencyLeave && leaveAdd.allowEmergencyLeave}
                                checked={leaveAdd && leaveAdd.allowEmergencyLeave}
                                disabled={leaveAdd && leaveAdd.leaveUnit && leaveAdd.leaveUnit === LEAVE_UNIT.SPECIAL}
                            />
                        </li>
                    </ul>
                    <div className="text-leave-type">
                        <Alert
                            message="Application Policy will only be enforced on employee submission, this will not affect submission by Administrator or Custom HR Roles."
                            type="info"
                            showIcon
                        />
                    </div>

                    <div className="d-flex justify-content-center align-items-center mt-3">
                        <div
                            onClick={handleAdd}
                            className="text-center btn-save-attendanceSetting rounded py-2 px-4 d-flex justify-content-center align-items-center button__warning"
                        >
                            <span className="text">Save</span>
                        </div>
                    </div>
                </div>
            </Modal>
        </Container>
    );
}
