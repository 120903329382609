import axios from 'axios';
const path = 'companies/';

const get = async (filter = {}) => {
    return await axios.get(path + 'all', {
        params: filter,
    });
};

const getById = async (id) => {
    return await axios.get(path + id);
};

const add = async (payload) => {
    return await axios.post(path, payload);
};

const update = async (id, payload) => {
    return await axios.patch(path + id, payload);
};

const deleteCompany = async (id) => {
    return await axios.delete(path + id);
};

const getCSV = async () => {
    return await axios.get(path + 'csv');
};

const getAll = async () => {
    return await axios.get(path);
};

export const companiesAPI = {
    get,
    getById,
    add,
    update,
    // delete: _delete
    deleteCompany,
    getCSV,
    getAll,
};
