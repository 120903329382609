import React from 'react';
import FilterLeave from './FilterLeave';
import TablePedingRequest from './TablePedingRequest';

function TabPendingRequest({ tabSelected, value, setRender, setValue }) {
    return (
        <div>
            <FilterLeave tabSelected={tabSelected} />
            <TablePedingRequest value={value} setRender={setRender} setValue={setValue} />
        </div>
    );
}

export default TabPendingRequest;
