import { Button, DatePicker, Form, Input, Modal, Upload } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'shards-react';
import { useLoading, useUser } from '../../hooks';
import moment from 'moment';
import { certificateAPI } from '../../apis/certificateAPI';

const CertificateFormView = ({ visible, setVisible, id }) => {
    const [form] = Form.useForm();
    const { user, getMe } = useUser();
    const { setLoading } = useLoading();
    const [payload, setPayload] = useState();
    const [fileList, setFileList] = useState([]);
    const [idCompany, setIdCompany] = useState();

    const getById = async (id) => {
        const { data } = await certificateAPI.getByID(id);
        setPayload(data);
        setFileList(data.files);
        form.setFieldsValue(data);
        data.receivedDate && form.setFieldValue('ReceivedDate', moment(new Date(data.receivedDate), 'YYYY-MM-DD'));
        data.expirationDate &&
            form.setFieldValue('ExpirationDate', moment(new Date(data.expirationDate), 'YYYY-MM-DD'));
    };

    const handleCancel = () => {
        setVisible(false);
        // setFileList([]);
        // form.resetFields();
        // setPayload({});
    };
    const handleOk = () => {
        setVisible(false);
        // setFileList([]);
        // form.resetFields();
        // setPayload({});
    };
    useEffect(() => {
        if (user && user.company && user.company._id) {
            setIdCompany(user.company._id);
        } else if (user && user.employee && user.employee.company && user.employee.company._id) {
            setIdCompany(user.employee.company._id);
        }
    }, [user]);

    useEffect(() => {
        getById(id);
    }, [id,visible]);

    return (
        <Modal title="Info Certificate" open={visible} zIndex={999} onOk={handleOk} onCancel={handleCancel} width="700px">
            <Form form={form} layout="vertical" name="form_in_modal">
                <Row>
                    <Col lg="12">
                        <div className="mb-4">
                            <Form.Item name="name" label="Name">
                                <Input type="text" placeholder="ex Name" id="Name" />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <div className="mb-4">
                            <Form.Item name="ReceivedDate" label="Received date">
                                <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }} id="ReceivedDate" />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <div className="mb-4">
                            <Form.Item name="ExpirationDate" label="Expiration date">
                                <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }} id="ExpirationDate" />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <div className="mb-4">
                            <label htmlFor="attachment" className="title">
                                <span>*</span> File
                            </label>
                            {fileList.map((item) => (
                                <div>
                                    <a href={Object.values(item)[0]} download className="text-dark">
                                        - {Object.keys(item)[0]}
                                    </a>
                                </div>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default CertificateFormView;
