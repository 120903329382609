import { Button, DatePicker, Form, Input, Modal, Upload } from 'antd';

import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { Col, Row } from 'shards-react';

import { useLoading, useUser } from '../../hooks';

import moment from 'moment';
import { UploadOutlined } from '@ant-design/icons';
import { uploadImageAPI } from '../../apis/uploadImageAPI';
import { FILE_UPLOAD_FOR, FILE_UPLOAD_TYPE } from '../../global/fileManagement';
import { fileManagementAPI } from '../../apis/fileManagementAPI';
import { certificateAPI } from '../../apis/certificateAPI';
import { getError } from '../../helpers/getErrorServer';
import { FORM_TYPE, ROLE } from '../../helpers/const';

const CertificateForm = ({
    visible,
    setVisible,
    type,
    setReload,
    id,
    setType,
    idEmployee,
    isAddEmployee,
    setListTmpCertificate,
}) => {
    // const { TextArea } = Input;
    const [form] = Form.useForm();
    const { user, getMe } = useUser();
    const { setLoading } = useLoading();
    const [payload, setPayload] = useState();
    const [fileList, setFileList] = useState([]);
    const uploadRef = useRef();
    const [idCompany, setIdCompany] = useState();

    const handleOk = () => {
        if (type === FORM_TYPE.CREATE) {
            create();
        } else if (type === FORM_TYPE.EDIT && id) {
            update();
        }
    };

    const checkValidate = () => {
        if (fileList.length < 1) {
            toast.error('Please select file!');
            return false;
        }
        if (payload.receivedDate && payload.expirationDate && payload.receivedDate > payload.expirationDate) {
            toast.error('Expiration Date invalid!');
            return false;
        }
        return true;
    };

    const checkValidateUpdate = () => {
        if (payload.receivedDate && payload.expirationDate && payload.receivedDate > payload.expirationDate) {
            toast.error('Expiration Date invalid!');
            return false;
        }
        return true;
    };

    const getById = async (id) => {
        const { data } = await certificateAPI.getByID(id);
        setPayload(data);
        form.setFieldsValue(data);
        data.receivedDate && form.setFieldValue('ReceivedDate', moment(new Date(data.receivedDate), 'YYYY-MM-DD'));
        data.expirationDate &&
            form.setFieldValue('ExpirationDate', moment(new Date(data.expirationDate), 'YYYY-MM-DD'));
    };

    const create = async () => {
        try {
            if (Object.keys(user).length === 0) {
                getMe();
            }

            const check = checkValidate();
            if (!check) {
                return;
            }
            setLoading(true);
            let arr = [];
            let tmp = [];
            for await (const file of fileList) {
                const { data } = await uploadImageAPI.uploadMultipleFiles(file, idCompany);

                if (data && data.original_filename && data.url) {
                    arr = [...arr, { [data.original_filename]: data.url }];
                }
                const type = file.type.includes('image') ? FILE_UPLOAD_TYPE.IMAGE : FILE_UPLOAD_TYPE.TEXT;
                tmp = [
                    ...tmp,
                    {
                        thumbnail: data.url,
                        name: data.original_filename,
                        uploadDate: new Date(data.created_at).getTime(),
                        size: data.bytes,
                        type,
                        publicID: data.public_id,
                    },
                ];
            }

            let newRequest;
            if (isAddEmployee) {
                newRequest = await certificateAPI.create({ ...payload, files: arr });
                setListTmpCertificate((prev) => [...prev, newRequest.data]);
            } else if (idEmployee) {
                newRequest = await certificateAPI.create({ ...payload, idEmployee, files: arr });
            } else if (user && user.role === ROLE.COMPANY) {
                newRequest = await certificateAPI.create({ ...payload, idCompany, files: arr });
            } else if (user && user.employee && user.employee._id) {
                newRequest = await certificateAPI.create({ ...payload, idEmployee: user.employee._id, files: arr });
            }

            for await (const item of tmp) {
                await fileManagementAPI.add({
                    company: idCompany,
                    uploadBy: user._id,
                    thumbnail: item.thumbnail,
                    name: item.name,
                    uploadDate: item.uploadDate,
                    size: item.size,
                    uploadFor: FILE_UPLOAD_FOR.CERTIFICATE,
                    request: newRequest.data._id,
                    type: item.type,
                    publicID: item.publicID,
                });
            }
            setLoading(false);

            setReload((prev) => prev + 1);
            setVisible(false);
            form.resetFields();
            setFileList([]);
            toast.success('Success');
        } catch (error) {
            setLoading(false);
            toast.error(getError(error));
        }
    };

    const update = async () => {
        try {
            if (Object.keys(user).length === 0) {
                getMe();
            }

            const check = checkValidateUpdate();
            if (!check) {
                return;
            }
            setLoading(true);

            if (fileList && fileList.length > 0) {
                let arr = [];
                let tmp = [];
                for await (const file of fileList) {
                    const { data } = await uploadImageAPI.uploadMultipleFiles(file, idCompany);

                    if (data && data.original_filename && data.url) {
                        arr = [...arr, { [data.original_filename]: data.url }];
                    }
                    const type = file.type.includes('image') ? FILE_UPLOAD_TYPE.IMAGE : FILE_UPLOAD_TYPE.TEXT;
                    tmp = [
                        ...tmp,
                        {
                            thumbnail: data.url,
                            name: data.original_filename,
                            uploadDate: new Date(data.created_at).getTime(),
                            size: data.bytes,
                            type,
                            publicID: data.public_id,
                        },
                    ];
                }

                await certificateAPI.update(id, { ...payload, files: arr });

                for await (const item of tmp) {
                    await fileManagementAPI.add({
                        company: idCompany,
                        uploadBy: user._id,
                        thumbnail: item.thumbnail,
                        name: item.name,
                        uploadDate: item.uploadDate,
                        size: item.size,
                        uploadFor: FILE_UPLOAD_FOR.CERTIFICATE,
                        request: id,
                        type: item.type,
                        publicID: item.publicID,
                    });
                }
            } else {
                await certificateAPI.update(id, payload);
            }
            setLoading(false);
            setReload((prev) => prev + 1);
            setVisible(false);
            setFileList([]);
            toast.success('Success');
        } catch (error) {
            setLoading(false);
            toast.error(getError(error));
        }
    };

    const handleCancel = () => {
        setVisible(false);
        setFileList([]);
        // if (type === FORM_TYPE.CREATE) {
        //     form.resetFields();
        //     setPayload();
        // }
        form.resetFields();
        setPayload({});
    };

    const onRemove = (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
    };

    const beforeUpload = (file) => {
        // setFileList([...fileList, file]);
        setFileList([file]);
        return false;
    };

    // const onChangeFile = (file) => {
    //     setFileList(file.fileList);
    //     return false;
    // };

    useEffect(() => {
        if (user && user.company && user.company._id) {
            setIdCompany(user.company._id);
        } else if (user && user.employee && user.employee.company && user.employee.company._id) {
            setIdCompany(user.employee.company._id);
        }
    }, [user]);

    useEffect(() => {
        if (id && type === FORM_TYPE.EDIT) {
            getById(id);
        }
    }, [id, type]);

    return (
        <Modal
            title="Create Certificate"
            open={visible}
            onOk={handleOk}
            zIndex={999}
            onCancel={handleCancel}
            width="700px"
        >
            <Form form={form} layout="vertical" name="form_in_modal">
                <Row>
                    <Col lg="12">
                        <div className="mb-4">
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[
                                    {
                                        required: true,
                                        message: `Please Input Name`,
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    placeholder="ex Name"
                                    id="Name"
                                    onChange={(e) => {
                                        setPayload({ ...payload, name: e.target.value });
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <div className="mb-4">
                            <Form.Item
                                name="ReceivedDate"
                                label="Received date"
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: `Please Input Received date`,
                                //     },
                                // ]}
                            >
                                <DatePicker
                                    // value={start && moment(new Date(start), 'YYYY-MM-DD')}
                                    format={'YYYY-MM-DD'}
                                    style={{ width: '100%' }}
                                    id="Received date"
                                    onChange={(_, dateString) => {
                                        setPayload({ ...payload, receivedDate: new Date(dateString).getTime() });
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <div className="mb-4">
                            <Form.Item
                                name="ExpirationDate"
                                label="Expiration date"
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: `Please Input Expiration date`,
                                //     },
                                // ]}
                            >
                                <DatePicker
                                    format={'YYYY-MM-DD'}
                                    style={{ width: '100%' }}
                                    id="Expiration date"
                                    // value={
                                    //     payload.expirationDate
                                    //         ? moment(new Date(payload.expirationDate), 'YYYY-MM-DD')
                                    //         : moment(new Date(), 'YYYY-MM-DD')
                                    // }
                                    onChange={(_, dateString) => {
                                        setPayload({ ...payload, expirationDate: new Date(dateString).getTime() });
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <div className="mb-4">
                            <Form.Item
                                name="File"
                                label="File"
                                rules={[
                                    {
                                        required: true,
                                        message: `Please Input File`,
                                    },
                                ]}
                            >
                                <Upload
                                    onRemove={onRemove}
                                    beforeUpload={beforeUpload}
                                    ref={uploadRef}
                                    fileList={fileList}
                                    // multiple={true}
                                    // onChange={onChangeFile}
                                >
                                    <Button icon={<UploadOutlined />}>Select File</Button>
                                </Upload>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                {type === FORM_TYPE.EDIT && payload && payload.files && (
                    <Row>
                        <Col lg="12">
                            <div className="mb-4">
                                <label htmlFor="attachment" className="title">
                                    <span>*</span> File
                                </label>
                                {payload.files.map((item) => (
                                    <div>
                                        <a href={Object.values(item)[0]} download className="text-dark">
                                            - {Object.keys(item)[0]}
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                )}
            </Form>
        </Modal>
    );
};

export default CertificateForm;
