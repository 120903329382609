import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ROLE } from '../../../helpers/const';
import { useUser } from '../../../hooks';
import { FormGroup, FormInput, Col, Row } from 'shards-react';
import { sindaAPI } from '../../../apis/payroll-settings/sindaAPI';

const initValue = [
    {
        from: 0,
        to: 1000,
        percent: 1,
    },
    {
        from: 1001,
        to: 1500,
        percent: 3,
    },
    {
        from: 1501,
        to: 2500,
        percent: 5,
    },
    {
        from: 2501,
        to: 4500,
        percent: 7,
    },
    {
        from: 4501,
        to: 7500,
        percent: 9,
    },
    {
        from: 7501,
        to: 10000,
        percent: 12,
    },
    {
        from: 10001,
        to: 15000,
        percent: 18,
    },
    {
        from: 15001,
        to: Math.pow(10, 20),
        percent: 30,
    },
];

function SINDA() {
    const { user, getMe } = useUser();
    const [dataSINDA, setDataSINDA] = useState(initValue);
    const [data, setData] = useState({});

    useEffect(() => {
        getData();
    }, [user]);

    const getData = async () => {
        if (!user) {
            getMe();
        }
        if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
            const { data } = await sindaAPI.getByCompany(user.company._id);
            setDataSINDA(data.sinda ? data.sinda : initValue);
            setData(data);
        }
        if (user && user.role && user.role === ROLE.COMPANYSTAFF && user.employee && user.employee.company._id) {
            const { data } = await sindaAPI.getByCompany(user.employee.company._id);
            setDataSINDA(data.sinda ? data.sinda : initValue);
            setData(data);
        }
    };

    const handleChange = async (from, to, title, value) => {
        setDataSINDA((prev) => {
            return prev.map((item) => {
                if (item.from === from && item.to === to) {
                    return { ...item, [title]: +value };
                }
                return item;
            });
        });
    };

    const handleSubmit = async () => {
        try {
            await sindaAPI.updateByCompany(data._id.toString(), { sinda: dataSINDA });
            toast.success('Update successful');
        } catch (error) {
            const message = error.response.data.message;
            if (message && typeof message === 'string') {
                toast.error(<p className="text-capitalize mt-3">{message}</p>);
            } else if (message && typeof message === 'object') {
                toast.error(<p className="text-capitalize mt-3">{message[0]}</p>);
            }
        }
    };
    return (
        <div className="padding_100">
            <Row>
                <Col lg="12">
                    <div className="d-flex justify-content-between align-items-center mb-1 mt-4">
                        <h5 className="title-font">{'SINDA (paid by staff)(also Indians with EP)'}</h5>
                    </div>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            Gross Wages/Month
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            Monthly contribution ($)
                        </span>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            $1000 and below
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="0"
                            type="number"
                            min="0"
                            max="100"
                            value={dataSINDA[0].percent}
                            onChange={(e) => {
                                handleChange(0, 1000, 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            $1000 to $1500
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="1"
                            type="number"
                            min="0"
                            max="100"
                            value={dataSINDA[1].percent}
                            onChange={(e) => {
                                handleChange(1001, 1500, 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            $1500 to $2500
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="2"
                            type="number"
                            min="0"
                            max="100"
                            value={dataSINDA[2].percent}
                            onChange={(e) => {
                                handleChange(1501, 2500, 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            $2500 to $4500
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="3"
                            type="number"
                            min="0"
                            max="100"
                            value={dataSINDA[3].percent}
                            onChange={(e) => {
                                handleChange(2501, 4500, 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            $4500 to $7500
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="4"
                            type="number"
                            min="0"
                            max="100"
                            value={dataSINDA[4].percent}
                            onChange={(e) => {
                                handleChange(4501, 7500, 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            $7500 to $10000
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="5"
                            type="number"
                            min="0"
                            max="100"
                            value={dataSINDA[5].percent}
                            onChange={(e) => {
                                handleChange(7501, 10000, 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            $10000 to $15000
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="6"
                            type="number"
                            min="0"
                            max="100"
                            value={dataSINDA[6].percent}
                            onChange={(e) => {
                                handleChange(10001, 15000, 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            Above $15000
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="7"
                            type="number"
                            min="0"
                            max="100"
                            value={dataSINDA[7].percent}
                            onChange={(e) => {
                                handleChange(15001, Math.pow(10, 20), 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            {user && user.role && user.role === ROLE.COMPANY && (
                <div className="mt-2">
                    <button onClick={handleSubmit} className="ct_btn px-4 next none-border btn-middle" id="save">
                        <span>Save</span>
                    </button>
                </div>
            )}
        </div>
    );
}

export default SINDA;
