import { Modal, Space, Table, Checkbox } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { trainingAPI } from '../../apis/trainingAPI';
import { FORM_TYPE, ROLE } from '../../helpers/const';
import { getError } from '../../helpers/getErrorServer';
import { useLoading } from '../../hooks';
import TrainingForm from './TrainingForm';
import TrainingInfoForm from './TrainingInfoForm';

function ListTraining({
    user,
    refetch,
    setRefetch,
    deleteList,
    setDeleteList,
    filter,
    setFilter,
    type,
    setType,
    listTmpTraining,
    isAddEmployee,
    chooseList,
}) {
    const [listTraining, setlistTraining] = useState();
    const [dataTable, setDataTable] = useState([]);
    const [page, setPage] = useState();
    const [idTmp, setIdTmp] = useState();
    const [open, setOpen] = useState(false);
    const [visible, setVisible] = useState(false);
    const [infoVisible, setInfoVisible] = useState(false);
    const [reload, setReload] = useState(0);
    const [id, setId] = useState();
    const [finalColumns, setFinalColumns] = useState(initColumns);
    const { setLoading } = useLoading();
    const [idTraining, setIdTraining] = useState();

    const warning = (id) => {
        setIdTmp(id);
        showModal();
    };

    const onOpen = (id) => {
        setIdTraining(id);
        setInfoVisible(true);
    };

    const hideModal = () => {
        if (idTmp) {
            deleteT(idTmp);
        }
        setOpen(false);
    };

    const showModal = () => {
        setOpen(true);
    };

    const deleteT = async (id) => {
        try {
            console.log(id);
            setLoading(true);
            await trainingAPI.removeTraining(id);
            setLoading(false);
            setReload((prev) => prev + 1);
            toast.success('Success!');
            setIdTmp();
        } catch (error) {
            console.log(error);
            setLoading(false);
            toast.error(getError(error));
        }
    };

    const handleOpen = async (id) => {
        if (id) {
            setId(id);
            setType(FORM_TYPE.EDIT);
            setVisible(true);
        }
    };

    useEffect(() => {
        if (page) {
            setFilter({ ...filter, page });
        } else {
            setFilter({ ...filter });
        }
    }, [page, refetch]);

    useEffect(() => {
        (async () => {
            try {
                if (listTmpTraining && listTmpTraining.length > 0) {
                    setlistTraining({
                        items: listTmpTraining,
                        paginate: {
                            page: filter.page || 1,
                            size: 10,
                            count: listTmpTraining.length,
                        },
                    });
                } else if (user && user.role === ROLE.COMPANY) {
                    const { data } = await trainingAPI.getCompany(filter);
                    setlistTraining(data);
                } else if (user && user.role === ROLE.COMPANYSTAFF) {
                    const { data } = await trainingAPI.getCompany(filter);
                    setlistTraining(data);
                } else if (user && user.role === ROLE.USER) {
                    if (chooseList === 'joined') {
                        const { data } = await trainingAPI.getEmployeeJoined(filter);
                        setlistTraining(data);
                    } else {
                        const { data } = await trainingAPI.getEmployeeNotJoined(filter);
                        setlistTraining(data);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, [filter, reload, user, listTmpTraining, chooseList]);

    useEffect(() => {
        if (listTraining) {
            let tmp = [];
            listTraining.items.forEach((item, i) => {
                tmp.push({
                    ...item,
                    key: i,
                    page: listTraining.paginate.page,
                });
            });
            setDataTable(tmp);
        }
    }, [listTraining]);

    const onChange = async (e, id) => {
        try {
            const checked = e.target.checked;
            if (checked === true) {
                deleteList.push(id._id);
                setDeleteList(deleteList);
            } else if (checked === false) {
                deleteList = deleteList.filter((item) => item !== id._id);
                setDeleteList(deleteList);
            } else {
            }
        } catch (error) {
            console.log(error);
        }
    };

    const initColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: '10%',

            render: (_, row) => {
                return (
                    <div className="content_td_mobile" onClick={() => onOpen(row._id)} style={{ cursor: 'pointer' }}>
                        <p className="title_mobile">ID</p>
                        <span>{row.key + 1}</span>
                    </div>
                );
            },
        },
        {
            title: 'Name',
            dataIndex: '',
            width: '30%',
            render: (_, row) => {
                return (
                    <div className="content_td_mobile" onClick={() => onOpen(row._id)} style={{ cursor: 'pointer' }}>
                        <p className="title_mobile">Name</p>
                        <span>{row && row.name}</span>
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.name > record2.name;
            },
        },
        {
            title: 'Start day',
            dataIndex: '',
            width: '15%',
            render: (_, row) => {
                return (
                    <div className="content_td_mobile" onClick={() => onOpen(row._id)} style={{ cursor: 'pointer' }}>
                        <p className="title_mobile">Start day</p>
                        <span>{row && row.timeStart && moment(row.timeStart).format('MMMM Do YYYY')}</span>
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.timeStart > record2.timeStart;
            },
        },
        {
            title: 'End date',
            dataIndex: '',
            width: '15%',

            render: (_, row) => {
                return (
                    <div className="content_td_mobile" onClick={() => onOpen(row._id)} style={{ cursor: 'pointer' }}>
                        <p className="title_mobile">End date</p>
                        <span>{row && row.timeEnd && moment(row.timeEnd).format('MMMM Do YYYY')}</span>
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.timeEnd > record2.timeEnd;
            },
        },

        {
            title: 'Students',
            dataIndex: '',
            width: '10%',

            render: (_, row) => {
                return (
                    <div className="content_td_mobile" onClick={() => onOpen(row._id)} style={{ cursor: 'pointer' }}>
                        <p className="title_mobile">Students</p>
                        <span>{row && row.students && row.students.length}</span>
                    </div>
                );
            },
        },
    ];

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '5%',
            render: (id, row) => {
                return <Checkbox onChange={(e) => onChange(e, id)} />;
            },
        },
        ...initColumns,
        {
            title: 'Actions',
            key: '_id',
            dataIndex: '_id',
            width: '15%',
            align: 'center',
            fixed: 'right',
            render: (id, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Actions</p>
                    <Space size="middle">
                        <Link to={`#`} onClick={() => handleOpen(id)}>
                            <span className="material-icons icon_edit">edit</span>
                        </Link>
                        <Link to={`#`} onClick={() => warning(id)}>
                            <span className="material-icons icon_delete">delete_forever</span>
                        </Link>
                    </Space>
                </div>
            ),
        },
    ];

    useEffect(() => {
        if (!isAddEmployee) {
            setFinalColumns(columns);
        } else {
            setFinalColumns(initColumns);
        }
    }, [isAddEmployee]);

    const onChangePage = (page) => {
        setPage(page);
    };

    return (
        <div className="overflow-hidden">
            <Table
                className="ct-table-data"
                columns={user.role === 'company' || user.role === 'company-staff' ? finalColumns : initColumns}
                dataSource={dataTable}
                pagination={{
                    current: page,
                    onChange: onChangePage,
                    pageSize:
                        listTraining && listTraining.paginate && listTraining.paginate.size
                            ? listTraining.paginate.size
                            : 10,
                    total: listTraining && listTraining.paginate ? listTraining.paginate.count : 0,
                }}
            />
            <Modal
                title={
                    <>
                        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.4167 0.833344H2.58333C1.56583 0.833344 0.75 1.65834 0.75 2.66668V15.5C0.75 16.5083 1.56583 17.3333 2.58333 17.3333H6.25L9 20.0833L11.75 17.3333H15.4167C16.425 17.3333 17.25 16.5083 17.25 15.5V2.66668C17.25 1.65834 16.425 0.833344 15.4167 0.833344ZM9.91667 15.5H8.08333V13.6667H9.91667V15.5ZM11.8142 8.39584L10.9892 9.23918C10.3292 9.90834 9.91667 10.4583 9.91667 11.8333H8.08333V11.375C8.08333 10.3667 8.49583 9.45001 9.15583 8.78084L10.2925 7.62584C10.6317 7.29584 10.8333 6.83751 10.8333 6.33334C10.8333 5.32501 10.0083 4.50001 9 4.50001C7.99167 4.50001 7.16667 5.32501 7.16667 6.33334H5.33333C5.33333 4.30751 6.97417 2.66668 9 2.66668C11.0258 2.66668 12.6667 4.30751 12.6667 6.33334C12.6667 7.14001 12.3367 7.87334 11.8142 8.39584Z"
                                fill="#FCBB0A"
                            />
                        </svg>

                        <p className="title-modal-custom">Warning</p>
                    </>
                }
                open={open}
                onOk={hideModal}
                onCancel={() => setOpen(false)}
                okText="Delete"
                cancelText="Cancel"
            >
                <p>Are you sure you want to delete?</p>
            </Modal>
            <TrainingForm visible={visible} setVisible={setVisible} type={type} setReload={setRefetch} id={id} />
            <TrainingInfoForm
                visible={infoVisible}
                setVisible={setInfoVisible}
                setReload={setRefetch}
                id={idTraining}
                chooseList={chooseList}
            />
        </div>
    );
}

export default ListTraining;
