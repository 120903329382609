import React, { useState } from 'react';
import { Alert, Button, Form, Input, Modal } from 'antd';
import { toast } from 'react-toastify';
import { userAPI } from '../../../apis/userAPI';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../../hooks';
import { resetTokenAPI } from '../../../apis/resetToken';
import { useEffect } from 'react';
import { defaultAPI } from '../../../apis/defaultAPI';
export default function ResetPasswordModal() {
    const initAccount = {
        newPassword: '',
        confirmNewPassword: '',
    };
    const { user } = useUser();
    const history = useHistory();

    const [isModalVisible, setIsModalVisible] = useState(false);
    useEffect(() => {
        changeVisible();
    }, []);
    useEffect(() => {
        changeVisible();
    }, [isModalVisible]);

    const changeVisible = async () => {
        const { data } = await defaultAPI.getUser();
        if (data.isFirst == false) {
            setIsModalVisible(false);
        } else {
            setIsModalVisible(true);
        }
    };

    const [account, setAccount] = useState(initAccount);
    const handleOk = () => {
        setIsModalVisible(!isModalVisible);
    };
    const handleCancel = () => {
        // setIsModalVisible(!isModalVisible);
        toast.warn('Please reset password!');
    };
    const onFinishFailed = (errorInfo) => {};

    const handleSubmit = () => {
        const confirm = document.getElementById('confirmPassword').value;
        if (confirm !== account.newPassword) {
            toast.error('Your confirm password not match password field!');
        } else if (account.newPassword.length < 4) {
            toast.error('Password must be more than 4 characters!');
        } else {
            onFinish(account);
        }
    };

    const onFinish = async (values) => {
        try {
            const TOKEN = localStorage.getItem('token');
            await userAPI.resetPassword({
                token: TOKEN,
                userId: user._id,
                password: values.newPassword,
                confirmPassword: values.confirmNewPassword,
            });
            await resetTokenAPI.deleteToken(user._id);
            toast.success('Change password successfully');
            history.push('/login');
        } catch (error) {
            console.log(error);
            toast.error('ERR!');
        }
    };
    return (
        <>
            <Modal title="Change Password" open={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={false}>
                <Alert message="Enter your new password below!" type="info" showIcon />
                <br />
                <Form
                    layout=" vertical"
                    name="basic"
                    wrapperCol={{
                        span: 24,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    // onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="New Password"
                        name="newpass"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password
                            value={account.newPassword}
                            onChange={(e) =>
                                setAccount({
                                    ...account,
                                    newPassword: e.target.value,
                                })
                            }
                        />
                    </Form.Item>

                    <Form.Item
                        label="Confirm New Password"
                        name="cfnewpass"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password
                            id="confirmPassword"
                            value={account.confirmNewPassword}
                            onChange={(e) =>
                                setAccount({
                                    ...account,
                                    confirmNewPassword: e.target.value,
                                })
                            }
                        />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit" onClick={handleSubmit}>
                            Reset Password
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}
