import { Box } from '@mui/material';
import { Button, DatePicker, Input, Select, Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Row } from 'shards-react';
import { employeesAPI } from '../../apis/employeesAPI';
import { publicHolidayAPI } from '../../apis/publicHolidayAPI';
import { ALL_MONTH, ROLE } from '../../helpers/const';
import { useAttendance, useUser } from '../../hooks';
import { getError } from '../../helpers/getErrorServer';
import { useLocation } from 'react-router-dom';
import { attendanceSettingAPI } from '../../apis/attendanceSettingAPI';
import { CATEGORY_LEAVE, CATEGORY_LEAVE_VALUE } from '../../global/leave/leaveEnum';

const TableAttendaceSheet = () => {
    const [days, setDays] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [size, setSize] = useState(0);
    const [search, setSearch] = useState();
    const [publicHoliday, setPublicHoliday] = useState();
    const [valueSelectYear, setValueSelectYear] = useState(new Date().getFullYear());
    const [valueSelectMonth, setValueSelectMonth] = useState(new Date().getMonth() + 1);
    const [name, setName] = useState('');
    const [workDays, setWorkDays] = useState(5);
    const [yearArr, setYearArr] = useState();
    const { Option } = Select;
    const { user, getMe } = useUser();
    const location = useLocation();
    const { attendances } = useAttendance();

    useEffect(() => {
        let isMounted = true;
        (async () => {
            try {
                if (isMounted) {
                    if (!user._id) {
                        await getMe();
                    }
                    if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
                        const { data } = await employeesAPI.findEmployeeForSheet({
                            company: user.company._id,
                            page,
                            month: new Date().getMonth() + 1,
                            year: new Date().getFullYear(),
                            limit: 10,
                        });

                        setEmployees(data.items);
                        setPaginate(data);
                    } else if (
                        (user && user.role && user.role === ROLE.COMPANYSTAFF) ||
                        (user &&
                            user.role &&
                            user.role === ROLE.USER &&
                            user.position.attendance &&
                            user.position.attendance.includes('1')) ||
                        (user &&
                            user.employee &&
                            user.employee.controls &&
                            user.employee.controls.attendance &&
                            user.employee.controls.attendance.includes('1'))
                    ) {
                        const { data } = await employeesAPI.findEmployeeForSheet({
                            company: user.employee.company._id,
                            month: new Date().getMonth() + 1,
                            year: new Date().getFullYear(),
                            page,
                            limit: 10,
                        });
                        setEmployees(data.items);
                        setPaginate(data);
                    } else {
                        const { data } = await employeesAPI.findEmployeeForSheet({
                            employee: user.employee._id,
                            month: new Date().getMonth() + 1,
                            year: new Date().getFullYear(),
                            page,
                            limit: 10,
                        });
                        setEmployees(data.items);
                        setPaginate(data);
                    }

                    // if (
                    //     user &&
                    //     user.role &&
                    //     user.role === ROLE.USER &&
                    //     user.position &&
                    //     user.position.attendance &&
                    //     !user.position.attendance.includes('1')
                    // ) {
                    //     const { data } = await employeesAPI.findEmployeeForSheet({
                    //         employee: user.employee._id,
                    //         month: new Date().getMonth() + 1,
                    //         year: new Date().getFullYear(),
                    //         page,
                    //         limit: 10,
                    //     });
                    //     setEmployees(data.items);
                    //     setPaginate(data);
                    // }

                    getDays();
                    optionsYear();
                }
            } catch (error) {
                toast.error(getError(error));
            }
        })();

        return () => {
            isMounted = false;
        };
    }, [page, location, attendances]);

    console.log(employees);

    useEffect(() => {
        let isMounted = true;
        (async () => {
            try {
                if (isMounted) {
                    if (!user._id) {
                        await getMe();
                    }
                    if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
                        const { data } = await publicHolidayAPI.getAll({ companyId: user.company._id });
                        setPublicHoliday(data.items);
                    }

                    if (
                        (user && user.role && user.role === ROLE.COMPANYSTAFF) ||
                        (user && user.role && user.role === ROLE.USER)
                    ) {
                        const { data } = await publicHolidayAPI.getAll({ companyId: user.employee.company._id });
                        setPublicHoliday(data.items);
                    }
                }
            } catch (error) {
                toast.error(getError(error));
            }
        })();
        return () => {
            isMounted = false;
        };
    }, [location]);

    useEffect(() => {
        (async () => {
            if (!user._id) {
                await getMe();
            }
            let companyID;
            if (user.role === ROLE.COMPANY && user.company && user.company._id) {
                companyID = user.company._id;
            }
            if (user && user.employee && user.employee.company && user.employee.company._id) {
                companyID = user.employee.company._id;
            }
            const { data } = await attendanceSettingAPI.getByCompany(companyID);

            if (data && data.length > 0 && data[0] && data[0].workingDays) {
                setWorkDays(data[0].workingDays);
            }
        })();
    }, [user, location]);

    const setPaginate = (data) => {
        if (data && data.paginate && data.paginate.count && data.paginate.size) {
            setTotal(data.paginate.count);
            setSize(data.paginate.size);
        }
    };

    const RadioButtonHalfDay = () => <span className="material-icons half_day">tonality</span>;
    const RadioButtonFullDay = () => <span className="material-icons full_day">radio_button_checked</span>;
    const RadioButtonAbsenceDay = () => <span className="material-icons absence_day">panorama_fish_eye</span>;
    const RadioButtonPublicDay = () => <span className="material-icons public_day">star</span>;
    const RadioButtonMC = () => <span className="material-icons medical_services">medical_services</span>;
    const RadioButtonTraining = () => <span className="material-icons school">school</span>;
    const RadioButtonLeave = () => <span className="material-icons time_to_leave">time_to_leave</span>;

    const getHoliday = (data) => {
        const day = new Date(data).getTime();
        if (publicHoliday && publicHoliday.length > 0) {
            const holiday = publicHoliday.filter((item) => item.startDay <= day && item.endDay >= day);
            return holiday;
        }
    };

    const getDays = (month = new Date().getMonth(), year = new Date().getFullYear()) => {
        let date = new Date(`${year}-${parseInt(month) + 1}-01`);
        let days = [];
        while (date.getMonth() === parseInt(month)) {
            days.push(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());
            date.setDate(date.getDate() + 1);
        }
        setDays(days);
    };

    const optionsYear = () => {
        const arr = [];
        const year = new Date('2022').getFullYear();
        const yearCurrent = new Date().getFullYear() + 1;

        for (let i = year; i <= yearCurrent; i++) {
            arr.push(i);
        }
        setYearArr(arr);
    };

    const renderSheet = (record, date) => {
        let attendance = record.attendance;
        let leave = record.leave;
        let key = new Date(date).getDate();
        let day = '';
        let h = '';
        let dddd = new Date(date).getDay();
        let holiday = getHoliday(date);

        if (holiday && holiday.length > 0) return <RadioButtonPublicDay />;
        if (dddd === 0 || (dddd === 6 && workDays < 5.5)) {
            return;
        } else {
            if (attendance && attendance.length > 0) {
                const data = attendance.filter((item) => new Date(item.date).getDate() === key);
                if (data && data.length > 0) {
                    day = new Date(data[0].date).getDate(); // get day
                    h = data[0].workingHours / 60;
                    if (day === key) {
                        if (h > 4) {
                            return <RadioButtonFullDay />;
                        }
                        if (h <= 4) {
                            return <RadioButtonHalfDay />;
                        }
                    }
                } else if (leave && leave.length > 0) {
                    const data = leave.filter(
                        (item) =>
                            new Date(item.from).getTime() <= new Date(date).setHours(12) &&
                            new Date(item.to).getTime() >= new Date(date).setHours(12),
                    );
                    if (data && data.length > 0) {
                        if (
                            data[0].leaveSetting &&
                            data[0].leaveSetting.category &&
                            data[0].leaveSetting.category === CATEGORY_LEAVE_VALUE.MC
                        ) {
                            return <RadioButtonMC />;
                        }
                        if (
                            data[0].leaveSetting &&
                            data[0].leaveSetting.category &&
                            data[0].leaveSetting.category === CATEGORY_LEAVE_VALUE.Training
                        ) {
                            return <RadioButtonTraining />;
                        }
                        if (
                            data[0].leaveSetting &&
                            data[0].leaveSetting.category &&
                            data[0].leaveSetting.category === CATEGORY_LEAVE_VALUE.Annual
                        ) {
                            return <RadioButtonLeave />;
                        }
                        return <RadioButtonAbsenceDay />;
                    }
                }
                return <RadioButtonAbsenceDay />;
            } else if (leave && leave.length > 0) {
                const data = leave.filter(
                    (item) =>
                        new Date(item.from).getTime() <= new Date(date).setHours(12) &&
                        new Date(item.to).getTime() >= new Date(date).setHours(12),
                );
                if (data && data.length > 0) {
                    if (
                        data[0].leaveSetting &&
                        data[0].leaveSetting.category &&
                        data[0].leaveSetting.category === CATEGORY_LEAVE_VALUE.MC
                    ) {
                        return <RadioButtonMC />;
                    }
                    if (
                        data[0].leaveSetting &&
                        data[0].leaveSetting.category &&
                        data[0].leaveSetting.category === CATEGORY_LEAVE_VALUE.Training
                    ) {
                        return <RadioButtonTraining />;
                    }
                    if (
                        data[0].leaveSetting &&
                        data[0].leaveSetting.category &&
                        data[0].leaveSetting.category === CATEGORY_LEAVE_VALUE.Annual
                    ) {
                        return <RadioButtonLeave />;
                    }
                    return <RadioButtonAbsenceDay />;
                }
                return <RadioButtonAbsenceDay />;
            } else return <RadioButtonAbsenceDay />;
        }
    };

    const columns =
        days &&
        days.map((item, idx) => {
            return {
                key: idx,
                title: new Date(item).getDate(),
                dataIndex: new Date(item).getDate(),
                render: (_, record) => {
                    return <p>{renderSheet(record, moment(item).format('YYYY-MM-DD'))}</p>;
                },
            };
        });

    const submitSearch = async () => {
        getDays(valueSelectMonth - 1, valueSelectYear);
        if (!user._id) {
            await getMe();
        }

        if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
            const { data } = await employeesAPI.findEmployeeForSheet({
                company: user.company._id,
                name,
                month: valueSelectMonth,
                year: valueSelectYear,
                page,
                limit: 10,
            });
            setEmployees(data.items);
            setPaginate(data);
        } else if (
            (user && user.role && user.role === ROLE.COMPANYSTAFF) ||
            (user &&
                user.role &&
                user.role === ROLE.USER &&
                user.position.attendance &&
                user.position.attendance.includes('1')) ||
            (user &&
                user.employee &&
                user.employee.controls &&
                user.employee.controls.attendance &&
                user.employee.controls.attendance.includes('1'))
        ) {
            const { data } = await employeesAPI.findEmployeeForSheet({
                company: user.employee.company._id,
                name,
                month: valueSelectMonth,
                year: valueSelectYear,
                page,
                limit: 10,
            });
            setEmployees(data.items);
            setPaginate(data);
        } else {
            const { data } = await employeesAPI.findEmployeeForSheet({
                employee: user.employee._id,
                name,
                month: valueSelectMonth,
                year: valueSelectYear,
                page,
                limit: 10,
            });
            setEmployees(data.items);
            setPaginate(data);
        }

        // if (
        //     user &&
        //     user.role &&
        //     user.role === ROLE.USER &&
        //     user.position &&
        //     user.position.attendance &&
        //     user.position.attendance.length < 1
        // ) {
        //     const { data } = await employeesAPI.findEmployeeForSheet({
        //         employee: user.employee._id,
        //         name,
        //         month: valueSelectMonth,
        //         year: valueSelectYear,
        //         page,
        //         limit: 10,
        //     });
        //     setEmployees(data.items);
        //     setPaginate(data);
        // }
    };

    const onChangePage = (page) => {
        setPage(page);
    };

    return (
        <>
            <Box sx={{ marginTop: 2, marginBottom: 2, padding: '0 20px' }}>
                <Row>
                    <Col lg="3" md="6" sm="12">
                        <Input placeholder="Search by name" onChange={(e) => setName(e.target.value)} />
                    </Col>
                    <Col lg="2" md="6" sm="12">
                        <Select
                            defaultValue={new Date().getFullYear()}
                            style={{ width: '100%' }}
                            onChange={(value) => setValueSelectYear(value)}
                        >
                            {yearArr &&
                                yearArr.map((item) => (
                                    <Option key={item} value={item}>
                                        {item}
                                    </Option>
                                ))}
                        </Select>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                        <Select
                            defaultValue={new Date().getMonth() + 1}
                            style={{ width: '100%' }}
                            onChange={(value) => setValueSelectMonth(value)}
                        >
                            {ALL_MONTH &&
                                ALL_MONTH.map((item) => (
                                    <Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                        </Select>
                    </Col>

                    <Col lg="4" md="6" sm="12">
                        <Button className="btn-yeallow-block" onClick={submitSearch}>
                            Search
                        </Button>
                    </Col>
                </Row>
            </Box>
            <Row>
                <Col lg="12" md="12 " sm="12">
                    <div className="gr_note">
                        <ul>
                            <li className="full_day">
                                <RadioButtonFullDay />
                                <p>Full Day Present</p>
                            </li>
                            <li className="half_day">
                                <RadioButtonHalfDay />
                                <p>Half Day Present</p>
                            </li>
                            <li className="absence_day">
                                <RadioButtonAbsenceDay />
                                <p>Full Day Absence</p>
                            </li>
                            <li className="public_day">
                                <RadioButtonPublicDay />
                                <p>Public holiday</p>
                            </li>
                            <li className="public_day">
                                <RadioButtonMC />
                                <p>Medical Certificate</p>
                            </li>
                            <li className="public_day">
                                <RadioButtonTraining />
                                <p>Training</p>
                            </li>
                            <li className="public_day">
                                <RadioButtonLeave />
                                <p>Annual Leave</p>
                            </li>
                        </ul>
                    </div>
                </Col>
            </Row>
            <div>
                <Table
                    columns={[{ title: ' Name', dataIndex: 'firstName' }, ...columns]}
                    dataSource={employees}
                    scroll={{ x: 2500 }}
                    pagination={{
                        onChange: onChangePage,
                        pageSize: size,
                        total,
                    }}
                />
            </div>
        </>
    );
};

export default TableAttendaceSheet;
