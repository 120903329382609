import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ROLE } from '../../../helpers/const';
import { useUser } from '../../../hooks';
import { FormGroup, FormInput, Col, Row } from 'shards-react';
import { mbmfAPI } from '../../../apis/payroll-settings/mbmfAPI';

const initValue = [
    {
        from: 0,
        to: 1000,
        percent: 3,
    },
    {
        from: 1001,
        to: 2000,
        percent: 4.5,
    },
    {
        from: 2001,
        to: 3000,
        percent: 6.5,
    },
    {
        from: 3001,
        to: 4000,
        percent: 15,
    },
    {
        from: 4001,
        to: 6000,
        percent: 19.5,
    },
    {
        from: 6001,
        to: 8000,
        percent: 22,
    },
    {
        from: 8001,
        to: 10000,
        percent: 24,
    },
    {
        from: 10001,
        to: Math.pow(10, 20),
        percent: 26,
    },
];
function MBMF() {
    const { user, getMe } = useUser();
    const [dataMBMF, setDataMBMF] = useState(initValue);
    const [data, setData] = useState({});

    useEffect(() => {
        getData();
    }, [user]);

    const getData = async () => {
        if (!user) {
            getMe();
        }
        if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
            const { data } = await mbmfAPI.getByCompany(user.company._id);
            setDataMBMF(data.mbmf ? data.mbmf : initValue);
            setData(data);
        }
        if (user && user.role && user.role === ROLE.COMPANYSTAFF && user.employee && user.employee.company._id) {
            const { data } = await mbmfAPI.getByCompany(user.employee.company._id);
            setDataMBMF(data.mbmf ? data.mbmf : initValue);
            setData(data);
        }
    };

    const handleChange = async (from, to, title, value) => {
        setDataMBMF((prev) => {
            return prev.map((item) => {
                if (item.from === from && item.to === to) {
                    return { ...item, [title]: +value };
                }
                return item;
            });
        });
    };

    const handleSubmit = async () => {
        try {
            await mbmfAPI.updateByCompany(data._id.toString(), { mbmf: dataMBMF });
            toast.success('Update successful');
        } catch (error) {
            const message = error.response.data.message;
            if (message && typeof message === 'string') {
                toast.error(<p className="text-capitalize mt-3">{message}</p>);
            } else if (message && typeof message === 'object') {
                toast.error(<p className="text-capitalize mt-3">{message[0]}</p>);
            }
        }
    };
    return (
        <div className="padding_100">
            <Row>
                <Col lg="12">
                    <div className="d-flex justify-content-between align-items-center mb-1 mt-4">
                        <h5 className="title-font">{' Mosque Building and Mendaki Fund (MBMF)(paid by staff)'}</h5>
                    </div>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            Gross Wages/Month
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            Monthly contribution ($)
                        </span>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            $1000 and below
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="0"
                            type="number"
                            min="0"
                            max="100"
                            value={dataMBMF[0].percent}
                            onChange={(e) => {
                                handleChange(0, 1000, 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            $1000 to $2000
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="1"
                            type="number"
                            min="0"
                            max="100"
                            value={dataMBMF[1].percent}
                            onChange={(e) => {
                                handleChange(1001, 2000, 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            $2000 to $3000
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="2"
                            type="number"
                            min="0"
                            max="100"
                            value={dataMBMF[2].percent}
                            onChange={(e) => {
                                handleChange(2001, 3000, 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            $3000 to $4000
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="3"
                            type="number"
                            min="0"
                            max="100"
                            value={dataMBMF[3].percent}
                            onChange={(e) => {
                                handleChange(3001, 4000, 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            $4000 to $6000
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="4"
                            type="number"
                            min="0"
                            max="100"
                            value={dataMBMF[4].percent}
                            onChange={(e) => {
                                handleChange(4001, 6000, 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            $6000 to $8000
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="5"
                            type="number"
                            min="0"
                            max="100"
                            value={dataMBMF[5].percent}
                            onChange={(e) => {
                                handleChange(6001, 8000, 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            $8000 to $10000
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="6"
                            type="number"
                            min="0"
                            max="100"
                            value={dataMBMF[6].percent}
                            onChange={(e) => {
                                handleChange(8001, 10000, 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            Above $10000
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="7"
                            type="number"
                            min="0"
                            max="100"
                            value={dataMBMF[7].percent}
                            onChange={(e) => {
                                handleChange(10001, Math.pow(10, 20), 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            {user && user.role && user.role === ROLE.COMPANY && (
                <div className="mt-2">
                    <button onClick={handleSubmit} className="ct_btn px-4 next none-border btn-middle" id="save">
                        <span>Save</span>
                    </button>
                </div>
            )}
        </div>
    );
}

export default MBMF;
