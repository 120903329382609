import { Form, Input, Popconfirm, Select, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { departmentAPI } from '../../apis/departmentAPI';
import { employeesAPI } from '../../apis/employeesAPI';
import { historiesAPI } from '../../apis/historiesAPI';
import { ROLE, TYPE_NOTIFICATION } from '../../helpers/const';
import { useDepartment, useUser } from '../../hooks';

const originData = [];
let key = '';

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, idCompany, ...restProps }) => {
    const [employees, setEmployees] = useState([]);

    const inputNode =
        inputType === 'select' ? (
            <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            >
                {employees.map((employee, i) => (
                    <Select.Option key={i} value={employee._id}>
                        {employee && employee.firstName ? employee.firstName : ''}
                    </Select.Option>
                ))}
            </Select>
        ) : (
            <Input />
        );

    useEffect(() => {
        (async () => {
            if (idCompany && record && record._id) {
                let employ = await loadEmployees();
                let sameDepartment = await loadSameDepartment(idCompany, record._id);
                if (!sameDepartment) sameDepartment = [];

                setEmployees(employ.concat(sameDepartment));
            }
        })();
    }, [idCompany, key]);

    const loadEmployees = async () => {
        try {
            const { data } = await employeesAPI.getDepartment(idCompany);
            return data;
        } catch (error) {
            console.log(error);
        }
    };

    const loadSameDepartment = async (company, id) => {
        try {
            const { data } = await employeesAPI.getSameDepartment(company, id);
            return data;
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const TableDepartments = ({ isChange, setIsChange, add }) => {
    const [form] = Form.useForm();
    const [data, setData] = useState(originData);
    const [editingKey, setEditingKey] = useState('');
    const [render, setRender] = useState(false);
    const { user } = useUser();
    const [idCompany, setIdCompany] = useState();
    const { department, fetchDepartment, filterDepartment, setFilterDepartment } = useDepartment();
    const [page, setPage] = useState();
    useEffect(() => {
        key = editingKey;
    }, [editingKey]);

    useEffect(() => {
        if (department) {
            let tmp = [];
            department.items.forEach((item, i) => {
                tmp.push({
                    ...item,
                    key: i + 1,
                });
            });
            setData(tmp);
        }
    }, [department]);

    useEffect(() => {
        fetchDepartment(filterDepartment);
    }, [user, add]);

    const isEditing = (record) => {
        return record._id === editingKey;
    };
    const edit = (record) => {
        form.setFieldsValue({
            id: '',
            departmentName: '',
            departmentHead: '',
            ...record,
        });
        setEditingKey(record._id);
        render === false ? setRender(true) : setRender(false);
        setIdCompany(record.company._id);
    };

    const cancel = () => {
        setEditingKey('');
        render === false ? setRender(true) : setRender(false);
    };

    const save = async (record) => {
        try {
            const row = await form.validateFields();

            const newData = [...data];
            const index = newData.findIndex((item) => record._id === item._id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                const department = await departmentAPI.update(record._id, newData[index]);

                await employeesAPI.setDepartment(department.data.departmentHead, record._id);
                if (department && user && user.role && user.role === ROLE.COMPANYSTAFF) {
                    await historiesAPI.add({
                        title: 'DEPARTMENT',
                        desc: `Client HR ${user.employee.firstName} just update info department`,
                        type: TYPE_NOTIFICATION.DEPARTMENT,
                        employee: user.employee._id,
                        idRequest: department.data._id,
                        toCompany: true,
                    });
                }
                let idCompany = null;
                if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
                    idCompany = user.company._id;
                }
                if (
                    user &&
                    user.role &&
                    user.role === ROLE.COMPANYSTAFF &&
                    user.employee &&
                    user.employee.company &&
                    user.employee.company._id
                ) {
                    idCompany = user.employee.company._id;
                }
                if (idCompany !== null) {
                    const arrEmploy = await employeesAPI.getSameDepartment(idCompany, department.data._id);
                    if (arrEmploy && arrEmploy.data && arrEmploy.data.length > 0) {
                        arrEmploy.data.map(async (item) => {
                            await historiesAPI.add({
                                title: 'DEPARTMENT',
                                desc: `Your department just be updated`,
                                type: TYPE_NOTIFICATION.DEPARTMENT,
                                employee: item._id,
                                idRequest: department.data._id,
                                toEmployee: true,
                            });
                        });
                    }
                }

                setIsChange(isChange === true ? false : true);
                fetchDepartment({ page: page });

                setEditingKey('');
                toast.success('Edit successfully!');
            } else {
                setEditingKey('');
            }
            render === false ? setRender(true) : setRender(false);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const columns = [
        {
            title: <label data-label="#">#</label>,
            dataIndex: 'employee.employeesId',
            key: 'employee.employeesId',
            editable: false,
            filter: true,
            render: (_, row) => (
                <div className="content_td_mobile">
                    <p>{row.key}</p>
                </div>
            ),

            sorter: (record1, record2) => {
                return record1.key > record2.key;
            },
        },
        {
            title: 'Department Name',
            dataIndex: 'departmentName',
            key: 'departmentName',

            editable: true,
            render: (departmentName) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Department Name</p>
                    {departmentName ? <p>{departmentName}</p> : "Can't get department name"}
                </div>
            ),
            sorter: (record1, record2) => {
                return record1.departmentName > record2.departmentName;
            },
        },
        {
            title: 'Department Head',
            dataIndex: 'departmentHead',

            editable: true,
            key: 'employee.firstName',
            render: (_, row) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Department Head</p>
                    {row.employee && row.employee.firstName ? (
                        <p>{row.employee.firstName}</p>
                    ) : (
                        "Can't get department head"
                    )}
                </div>
            ),
            sorter: (record1, record2) => {
                return record1.employee.firstName > record2.employee.firstName;
            },
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',

            editable: false,
            render: (total) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Total</p>
                    {total ? <p>{total}</p> : 0}
                </div>
            ),

            sorter: (record1, record2) => {
                return record1.total > record2.total;
            },
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            render: (_, record) => {
                const editable = isEditing(record);
                return (
                    <>
                        {user.role && user.role !== ROLE.USER && (
                            <div className="content_td_mobile">
                                <p className="title_mobile">Actions</p>
                                {editable ? (
                                    <span>
                                        <Popconfirm className="edit-cancel" title="Sure to cancel?" onConfirm={cancel}>
                                            <a>Cancel</a>
                                        </Popconfirm>
                                        <Typography.Link
                                            className="edit-save"
                                            onClick={async () => {
                                                const result = await employeesAPI.setDepartment(
                                                    record.departmentHead,
                                                    record._id,
                                                );

                                                if (result) {
                                                    save(record);
                                                }
                                            }}
                                        >
                                            Save
                                        </Typography.Link>
                                    </span>
                                ) : (
                                    <Typography.Link
                                        disabled={editingKey !== ''}
                                        onClick={() => {
                                            edit(record);
                                        }}
                                    >
                                        Edit
                                    </Typography.Link>
                                )}
                            </div>
                        )}
                    </>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'departmentHead' ? 'select' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                idCompany,
            }),
        };
    });

    const onChangePage = (page) => {
        const filter = { ...filterDepartment, page };
        setFilterDepartment(filter);
        fetchDepartment(filter);
        setPage(page);
    };

    return (
        <Form form={form} component={false}>
            <Table
                className="ct-table-data"
                components={{ body: { cell: EditableCell } }}
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                    onChange: onChangePage,
                    current: page,
                    pageSize:
                        department && department.paginate && department.paginate.size ? department.paginate.size : 10,
                    total:
                        department && department.paginate && department.paginate.count ? department.paginate.count : 0,
                }}
                style={{
                    width: '100%',
                }}
            />
        </Form>
    );
};

export default TableDepartments;
