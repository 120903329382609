import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MoreIcon from '@mui/icons-material/More';
import PersonIcon from '@mui/icons-material/Person';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import BatchPredictionIcon from '@mui/icons-material/BatchPrediction';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
// import { Typography as TypographyMUI } from '@mui/material/Typography';
import {
    Alert,
    DatePicker,
    Input,
    Modal,
    Radio,
    Select,
    Switch,
    Form,
    Table,
    Popconfirm,
    Typography,
    Button,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { AiFillPlusCircle } from 'react-icons/ai';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, Col, Container, FormInput, InputGroup, InputGroupAddon, InputGroupText, Row } from 'shards-react';
import { employeesAPI } from '../../apis/employeesAPI';
import PageTitle from './../common/PageTitle';
import { WORKING_DAYS } from '../../helpers/const';
import { BsQuestionSquareFill } from 'react-icons/bs';

import { countriesAPI } from '../../apis/countriesAPI';
import { countryAPI } from '../../apis/countryAPI';
import { departmentAPI } from '../../apis/departmentAPI';
import { historiesAPI } from '../../apis/historiesAPI';
import { jobPositionAPI } from '../../apis/jobPositionAPI';
import { uploadImageAPI } from '../../apis/uploadImageAPI';
import {
    EMPLOYEES_TYPE,
    EMPLOYEES_TYPE_VALUE,
    EMPLOYEE_BLOOD_TYPE,
    EMPLOYEE_HAND_LEG,
    EMPLOYEE_HEALTH,
    EMPLOYEE_HEARING,
    EMPLOYEE_PAYMENT_METHOD,
    EMPLOYEE_PAYMENT_PAY,
    EMPLOYEE_PRIVACY_LEVEL,
    FORM_TYPE,
    ROLE,
    TYPE_NOTIFICATION,
} from '../../helpers/const';
import { getError } from '../../helpers/getErrorServer';
import { useLoading, useUser } from '../../hooks';
import { currencyAPI } from '../../apis/currencyAPI';
import { companiesAPI } from '../../apis/companiesAPI';
import { branchAPI } from '../../apis/brachAPI';
import { levelAPI } from '../../apis/levelAPI';
import { bankAPI } from '../../apis/bankAPI';
import { leaveWordFlowAPI } from '../../apis/leaveWordFlowAPI';
import { workDayAPI } from '../../apis/workDayAPI';
import { jobHolidayAPI } from '../../apis/jobHolidayAPI';
import { ethnicityAPI } from '../../apis/ethnicityAPI';
import { religionAPI } from '../../apis/religionAPI';
import IMG_AVT from '../../images/avatars/0.jpg';
import { leavesSettingAPI } from '../../apis/leavesSettingAPI';
import { individualLeaveSettingAPI } from '../../apis/individualLeaveSettingAPI';
import ListCertificate from '../certificate/ListCertificate';
import { MdDeleteForever } from 'react-icons/md';
import { certificateAPI } from '../../apis/certificateAPI';
import CertificateForm from '../certificate/CertificateForm';
import useLocalStorage from '../../hooks/useLocalStorage';
import { publicHolidayAPI } from '../../apis/publicHolidayAPI';
import { permanentResidentsAPI } from '../../apis/permanentResidentsAPI';
let imageProfile = '/img/user.png';

const initSalary = {
    effectiveDate: null,
    basicSalary: '',
    currency: '',
    reviewDate: '',
    earning: '',
    deduction: '',
    bonus: '',
    statutoryContribution: '',
};

const initPayment = {
    bank: '',
    bankAccount: '',
    payPeriod: '',
    method: '',
};

const initEmploymentTerm = {
    effectiveDate: null,
    jobType: 'permanent',
    description: 'confirm',
    leaveWordflow: 'default',
    workDay: 'default',
    holiday: 'default',
    termStart: null,
    termEnd: null,
};

const initPlacement = {
    effectiveDate: '',
    jobPosition: '',
    lineManager: '',
    branch: '',
    level: '',
};

const initEmployee = {
    birthday: '',
    email: '',
    employeesId: '',
    firstName: '',
    gender: 'male',
    lastName: '',
    middleName: '',
    nationId: '',
    nationality: '',
    passport: '',
    companyID: '',
    type: '',
    family: {},
    salary: {},
    insurance: [],
    department: '',
    ethnicity: null,
    religion: '',
    remark: '',
    jobPosition: '',
    dateJoin: null,
    endProbation: null,
    payment: {},
    employmentTerm: {},
    placement: {},
    phone: {},
    address: {},
    emergency: {},
    health: {},
    derectory: {},
    user: {},
};

const initPhone = {
    email: '',
    blog: '',
    officePhone: '',
    mobilePhone: '',
    housePhone: '',
};

const initAddress = {
    address1: '',
    address2: '',
    city: '',
    postCode: '',
    state: '',
    country: '',
};

const initEmergency = {
    firstName: '',
    middleName: '',
    lastName: '',
    relationship: '',
    mobilePhone: '',
    housePhone: '',
    officePhone: '',
};

const initFamily = {
    maritalStatus: 'single',
    spouseWorking: false,
    firstName: '',
    middleName: '',
    lastName: '',
    birthday: null,
    nationality: '',
    nationId: '',
    passport: '',
    ethnicity: null,
    religion: '',
    children: '',
};

const initHealth = {
    physicalHeight: '',
    physicalWeight: '',
    physicalBloodType: '',
    visionLeft: '',
    visonRight: '',
    hearingLeft: '',
    hearingRight: '',
    handLeft: '',
    handRight: '',
    legLeft: '',
    legRight: '',
};

const initDerectory = {
    employeeRole: 'employee',
    email: 'employee',
    blog: 'employee',
    officePhone: 'employee',
    housePhone: 'employee',
    mobilePhone: 'employee',
    address: 'employee',
    emergency: 'employee',
    birthday: 'employee',
    familyBirthday: 'employee',
    anniversary: 'employee',
};

const initAddJobPosition = {
    code: '',
    jobPositionName: '',
};

const initAddDepartment = {
    departmentName: '',
    departmentHead: '',
};

const initAddIndividualLeaveSetting = {
    leaveSetting: '',
    overwrite: '',
};

const initAddBranch = {
    code: '',
    name: '',
};

const initAddLevel = {
    code: '',
    name: '',
};

const initAddBank = {
    code: '',
    name: '',
};

const initAddLeaveWordFlow = {
    code: '',
    name: '',
};

const initAddWorkDay = {
    code: '',
    name: '',
};

const initAddHoliday = {
    code: '',
    name: '',
};

const initAddEthnicity = {
    name: '',
};

const initAddReligion = {
    name: '',
};

function CreateEmployees() {
    const [employee, setEmployee] = useState(initEmployee);
    const [placement, setPlacement] = useState(initPlacement);
    const [employmentTerm, setEmploymentTerm] = useState(initEmploymentTerm);
    const [payment, setPayment] = useState(initPayment);
    const [phone, setPhone] = useState(initPhone);
    const [address, setAddress] = useState(initAddress);
    const [emergency, setEmergency] = useState(initEmergency);
    const [familys, setFamilys] = useState(initFamily);
    const [health, setHealth] = useState(initHealth);
    const [derectory, setDerectory] = useState(initDerectory);
    const [salary, setSalary] = useState(initSalary);

    const [isModalNationality, setIsModalNationality] = useState(false);
    const [isModalDepartment, setIsModalDepartment] = useState(false);
    const [isModalPosition, setIsModalPosition] = useState(false);
    const [isModalBranch, setIsModalBranch] = useState(false);
    const [isModalLevel, setIsModalLevel] = useState(false);
    const [isModalBank, setIsModalBank] = useState(false);
    const [isModalLeaveWordFlow, setIsModalLeaveWordFlow] = useState(false);
    const [isModalWorkDay, setIsModalWorkDay] = useState(false);
    const [isModalHoliday, setIsModalHoliday] = useState(false);
    const [isModalEthnicity, setIsModalEthnicity] = useState(false);
    const [isModalReligion, setIsModalReligion] = useState(false);
    const [isModalIndividualLeaveSetting, setModalIndividualLeaveSetting] = useState(false);
    const [countryNames, setCountryNames] = useState([]);
    const [companyId, setCompanyId] = useState('');

    const [jobPositions, setJobPositions] = useState([]);
    const [reload, setReload] = useState(0);
    const [valueTabs, setValueTabs] = useState(0);
    const [departments, setDepartments] = useState([]);
    const [countries, setCountries] = useState([]);
    const [timeClock, setTimeClock] = useState(false);
    const [addJobPosition, setAddJobPosition] = useState(initAddJobPosition);
    const [addDepartment, setAddDepartment] = useState(initAddDepartment);
    const [addIndividualLeaveSetting, setAddIndividualLeaveSetting] = useState(initAddIndividualLeaveSetting);
    const [addBranch, setAddBranch] = useState(initAddBranch);
    const [addLevel, setAddLevel] = useState(initAddLevel);
    const [addBank, setAddBank] = useState(initAddBank);
    const [addLeaveWordFlow, setAddLeaveWordFlow] = useState(initAddLeaveWordFlow);
    const [addWorkDay, setAddWorkDay] = useState(initAddWorkDay);
    const [addHoliday, setAddHoliday] = useState(initAddHoliday);
    const [addEthnicity, setAddEthnicity] = useState(initAddEthnicity);
    const [addReligion, setAddReligion] = useState(initAddReligion);
    const [fileImage, setFileImage] = useState();
    const [imagePreviewUrl, setImagePreviewUrl] = useState();
    const [currency, setCurrency] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [listEmployees, setListEmployees] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [branches, setBranches] = useState([]);
    const [levelList, setLevelList] = useState([]);
    const [bankList, setBankList] = useState([]);
    const [leaveWordflowList, setAddLeaveWordFlowList] = useState([]);
    const [workDayList, setWorkDayList] = useState([]);
    const [holidayList, setHolidayList] = useState([]);
    const [ethnicityList, setEthnicityList] = useState([]);
    const [religionList, setReligionList] = useState([]);
    const [state, setState] = useState([]);
    const [idTmp, setIdTmp] = useState();
    const [added, setAdded] = useState(false);
    const [listLeaveSetting, setListLeaveSetting] = useState([]);
    const [dataIndividualLeaveSetting, setDataIndividualLeaveSetting] = useState([]);
    const [editingKey, setEditingKey] = useState('');

    const [render, setRender] = useState(false);

    const location = useLocation();
    const history = useHistory();
    const { id } = useParams();
    const { user, getMe } = useUser();
    const { Option } = Select;
    const { TextArea } = Input;
    const url = useLocation();
    let urlView = url.pathname.search('view');
    let urlEdit = url.pathname.search('edit');
    const dateFormat = 'YYYY-MM-DD';
    const [form] = Form.useForm();
    const { setLoading } = useLoading();
    const [idCompany, setIdCompany] = useState();
    // HRM 16
    const [showWP, setShowWP] = useState(true);
    const [showNRIC, setShowNRIC] = useState(true);
    const [showExpiryDateForWP_SP, setShowExpiryDateForWP_SP] = useState(false);
    //
    const [titleNation, setTitleNation] = useState('FIN NO');
    const [openDetail, setOpenDetail] = useState(false);

    const [deleteListCertificate, setDeleteListCertificate] = useState([]);
    const [filterCertificate, setFilterCertificate] = useState({ page: 1 });
    const [typeCertificate, setTypeCertificate] = useState(FORM_TYPE.CREATE);
    const [reloadCertificate, setReloadCertificate] = useState(false);
    const [userCertificate, setUserCertificate] = useState();
    const [openDeleteCertificate, setOpenDeleteCertificate] = useState(false);
    const [openCertificate, setOpenCertificate] = useState(false);
    const [isAddEmployee, setIsAddEmployee] = useState(false);
    const [listTmpCertificate, setListTmpCertificate] = useState([]);
    const [isSaved, setIsSaved] = useState(true);

    const [saved, setSaved] = useLocalStorage('saved', true);

    const [defaultPR, setDefaultPR] = useState();
    const [datePR, setDatePR] = useState();
    const [dataPR, setDataPR] = useState();
    const [dataCompany, setDataCompany] = useState();

    useEffect(() => {
        (async () => {
            if (!user || !user._id) {
                await getMe();
            }
            const { data } = await permanentResidentsAPI.getByEmployee(id);
            setDefaultPR(data.status);
            setDatePR(data.date);
            setDataPR(data);

            if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
                const { data } = await companiesAPI.getById(user.company._id);
                setDataCompany(data);
            }
            if (
                user &&
                user.role &&
                (user.role === ROLE.COMPANYSTAFF || user.role === ROLE.USER) &&
                user.employee &&
                user.employee.company &&
                user.employee.company._id
            ) {
                const { data } = await companiesAPI.getById(user.employee.company._id);
                setDataCompany(data);
            }
        })();
    }, [reload, companyId, user, location]);

    useEffect(() => {
        (async () => {
            if (!user || !user._id) {
                await getMe();
            }

            if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
                const { data } = await companiesAPI.getById(user.company._id);
                setDataCompany(data);
            }
            if (
                user &&
                user.role &&
                (user.role === ROLE.COMPANYSTAFF || user.role === ROLE.USER) &&
                user.employee &&
                user.employee.company &&
                user.employee.company._id
            ) {
                const { data } = await companiesAPI.getById(user.employee.company._id);
                setDataCompany(data);
            }
        })();
    }, [reload, companyId, user, location]);

    useEffect(() => {
        if (urlEdit === -1 && urlView === -1) {
            setIsSaved(false);
        } else if (urlEdit === 1 && urlView === -1) {
            setIsSaved(false);
        } else if (urlEdit === -1 && urlView === 1) {
            setIsSaved(true);
        }
    }, [urlEdit, urlView]);

    useEffect(() => {
        if (isSaved === true || isSaved === false) {
            setSaved(isSaved);
        }
    }, [isSaved]);

    const handleOpen = async () => {
        setOpenDetail(true);
    };
    //
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const handleOkDetail = async () => {
        try {
            const payload = { confirmPassword, newPassword };
            const { data } = await employeesAPI.changePasswordByAdmin(id, payload);

            toast.success('Change password successfully!');
            setOpenDetail(false);
        } catch (error) {
            setOpenDetail(false);
            toast.error(getError(error));
        }
    };
    const handleCancel = async () => {
        setOpenDetail(false);
    };
    const [open, setOpen] = useState(false);
    const hideModal = async () => {
        try {
            const { data } = await employeesAPI.resetPasswordByAdmin(employee.email);
            toast.success('Change password successfully!');
            setOpenDetail(false);
        } catch (error) {
            toast.error(getError(error));
        }
    };
    const showModalEdit = () => {
        setOpen(true);
    };
    const warning = (id) => {
        showModalEdit();
    };
    const [showPass, setShowPass] = useState(false);
    const showPassWord = () => {
        setShowPass(!showPass);
    };

    //
    const isEditing = (record) => {
        if (record._id && record._id.length > 0) {
            return record._id === editingKey;
        } else {
            const index = dataIndividualLeaveSetting.findIndex(
                (item) => record.leaveSetting._id === item.leaveSetting._id,
            );
            return index === editingKey;
        }
    };

    const cancelIndividual = () => {
        setEditingKey('');

        // render === false ? setRender(true) : setRender(false);
    };

    const showModalDeleteCertificate = () => {
        setOpenDeleteCertificate(true);
    };

    const showModalCertificate = () => {
        setTypeCertificate(FORM_TYPE.CREATE);
        setOpenCertificate(true);
    };

    const handleDeleteOkCertificate = async () => {
        try {
            setLoading(true);
            for await (const item of deleteListCertificate) {
                await certificateAPI.softDelete(item._id);
            }
            setLoading(false);
            setDeleteListCertificate([]);
            setOpenDeleteCertificate(false);
            setReloadCertificate((e) => e + 1);
            toast.success('Success');
        } catch (error) {
            setLoading(false);
            toast.error(getError(error));
        }
    };

    const handleDeleteCancelCertificate = () => {
        setOpenDeleteCertificate(false);
    };

    useEffect(() => {
        if (id) {
            setUserCertificate({ employeeId: id });
        }
        if (urlEdit === -1) {
            setIsAddEmployee(true);
        } else if (urlEdit === 1) {
            setIsAddEmployee(false);
        }
    }, [id, urlEdit, urlView]);

    useEffect(() => {
        if (employee.type === EMPLOYEES_TYPE_VALUE.LOCALSTAFF) {
            setTitleNation('NRIC');
            setShowWP(false);
            setShowNRIC(false);
            setShowExpiryDateForWP_SP(false);
        } else if (employee.type === EMPLOYEES_TYPE_VALUE.WORKPERMIT) {
            setTitleNation('FIN NO');
            setShowWP(true);
            setShowNRIC(false);
            setShowExpiryDateForWP_SP(true);
        } else {
            setTitleNation('FIN NO');
            setShowWP(true);
            setShowNRIC(true);
            setShowExpiryDateForWP_SP(false);
        }
    }, [employee.type]);

    const deleteIndividual = async (record) => {
        try {
            setLoading(true);
            if (record._id && record._id.length > 0) {
                await individualLeaveSettingAPI.softDelete(record._id);
                toast.success('Deleted successfully');
            } else {
                const tmp = dataIndividualLeaveSetting.filter(
                    (item) => item.leaveSetting._id !== record.leaveSetting._id,
                );
                setDataIndividualLeaveSetting(tmp);
            }

            render === false ? setRender(true) : setRender(false);
            setLoading(false);
        } catch (error) {
            toast.error(getError(error));
            setLoading(false);
        }
    };

    const editIndividual = (record) => {
        form.setFieldsValue({
            id: '',
            leaveSetting: '',
            overwrite: '',
            ...record,
        });

        if (record._id && record._id.length > 0) {
            setEditingKey(record._id);
        } else {
            const index = dataIndividualLeaveSetting.findIndex(
                (item) => record.leaveSetting._id === item.leaveSetting._id,
            );
            setEditingKey(index);
        }

        if (user.role === ROLE.COMPANY && user.company && user.company._id) {
            setIdCompany(user.company._id);
        } else if (user && user.employee && user.employee.company && user.employee.company._id) {
            setIdCompany(user.employee.company._id);
        }
    };

    const saveIndividual = async (record) => {
        setLoading(true);
        try {
            let row;
            if (record._id && record._id.length > 0) {
                row = await form.validateFields();
            } else {
                row = await form.getFieldsValue();
            }

            const newData = [...dataIndividualLeaveSetting];
            let index = -1;
            if (record._id && record._id.length > 0) {
                index = newData.findIndex((item) => record._id === item._id);
            } else {
                index = newData.findIndex((item) => record.leaveSetting._id === item.leaveSetting._id);
            }

            if (index > -1) {
                const item = newData[index];

                newData.splice(index, 1, { ...item, ...row });

                if (record._id && record._id.length > 0) {
                    await individualLeaveSettingAPI.update(record._id, newData[index]);
                    toast.success('Edit successfully!');
                } else {
                    setDataIndividualLeaveSetting(newData);
                }
                setEditingKey('');

                setReload((prev) => prev + 1);
            } else {
                setEditingKey('');
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error(getError(error));
        }
    };

    const columnsIndividualLeave = [
        {
            title: 'Leave type',
            dataIndex: 'leaveSetting',
            key: 'leaveSetting.description',

            editable: false,
            render: (_, row) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Leave type</p>
                    {row.leaveSetting && row.leaveSetting.description ? (
                        <p>{row.leaveSetting.description}</p>
                    ) : (
                        "Can't get leave type"
                    )}
                </div>
            ),
            sorter: (record1, record2) => {
                return record1.leaveSetting.description > record2.leaveSetting.description;
            },
        },
        {
            title: 'Normal amount',
            dataIndex: 'normalAmount',

            editable: false,
            key: 'leaveSetting.standardNumber',
            render: (_, row) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Normal Amount</p>
                    {row.leaveSetting.standardNumber ? <p>{row.leaveSetting.standardNumber}</p> : '--'}
                </div>
            ),
            sorter: (record1, record2) => {
                return record1.leaveSetting.standardNumber > record2.leaveSetting.standardNumber;
            },
        },
        {
            title: 'Overwrite amount',
            dataIndex: 'overwrite',
            key: 'overwrite',

            editable: true,
            render: (overwrite) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Overwrite amount</p>
                    {overwrite ? <p>{overwrite}</p> : '--'}
                </div>
            ),

            sorter: (record1, record2) => {
                return record1.overwrite > record2.overwrite;
            },
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            render: (_, record) => {
                const editable = isEditing(record);

                return (
                    <>
                        {urlView === -1 && (
                            <div className="content_td_mobile">
                                <p className="title_mobile">Actions</p>

                                {editable ? (
                                    <span>
                                        <Popconfirm
                                            className="edit-cancel"
                                            title="Sure to cancel?"
                                            onConfirm={cancelIndividual}
                                        >
                                            <a>Cancel</a>
                                        </Popconfirm>
                                        <Typography.Link className="edit-save" onClick={() => saveIndividual(record)}>
                                            Save
                                        </Typography.Link>
                                    </span>
                                ) : (
                                    <div>
                                        <Typography.Link
                                            disabled={editingKey !== ''}
                                            onClick={() => {
                                                editIndividual(record);
                                            }}
                                        >
                                            Edit
                                        </Typography.Link>
                                        <span className="ml-2 mr-2">|</span>

                                        <Popconfirm
                                            className="edit-cancel"
                                            title="Sure to deleted?"
                                            onConfirm={() => deleteIndividual(record)}
                                        >
                                            <a style={{ color: 'red' }}>Delete</a>
                                        </Popconfirm>
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                );
            },
        },
    ];

    const mergedColumns = columnsIndividualLeave.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'leaveSetting' ? 'select' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                idCompany,
            }),
        };
    });

    const handleChangeTabs = (event, newValue) => {
        if (urlView !== -1) {
            setValueTabs(newValue);
            return;
        }
        if (newValue === 0) setValueTabs(newValue);
        if (newValue === 1) setValueTabs(newValue);
        if (newValue === 2 && validatePerson()) {
            setValueTabs(newValue);
            // setValueTabs((e) => e + 1);
        }
        if (newValue === 3 && validatePerson() && validateJob()) {
            setValueTabs(newValue);
            // setValueTabs((e) => e + 1);
        }
        if (newValue === 4 && validatePerson() && validateJob() && validateSalary()) {
            setValueTabs(newValue);
            // setValueTabs((e) => e + 1);
        }
        if (newValue >= 5 && validatePerson() && validateJob() && validateSalary()) {
            setValueTabs(newValue);
            // setValueTabs((e) => e + 1);
        }
    };

    const fetchEmployee = async (id) => {
        try {
            const { data } = await employeesAPI.getById(id);
            if (data) {
                setImagePreviewUrl(data[0].user.avatar);
                setEmployee(data[0]);
                setPlacement(data[0].placement);
                setPayment(data[0].payment);
                setPhone(data[0].phone);
                setAddress(data[0].address);
                setEmergency(data[0].emergency);
                setEmploymentTerm(data[0].employmentTerm);
                setFamilys(data[0].family);
                setHealth(data[0].health);
                setDerectory(data[0].derectory);
                setSalary(data[0].salary);
            }
        } catch (error) {
            toast.error(getError(error));
        }
    };

    useEffect(() => {
        (async () => {
            const { data } = await countriesAPI.getCountries();
            setCountries(data);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (!user || !user._id) {
                await getMe();
            }

            if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
                const { data } = await branchAPI.getByCompany(user.company._id);
                setBranches(data);
            }

            if (
                user &&
                user.role &&
                (user.role === ROLE.COMPANYSTAFF || user.role === ROLE.USER) &&
                user.employee &&
                user.employee.company &&
                user.employee.company._id
            ) {
                const { data } = await branchAPI.getByCompany(user.employee.company._id);
                setBranches(data);
            }
        })();
    }, [reload, companyId, user, location]);

    useEffect(() => {
        (async () => {
            if (!user || !user._id) {
                await getMe();
            }

            if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
                const { data } = await leavesSettingAPI.getToOverwrite(user.company._id);
                // const { data: tmp } = await leavesSettingAPI.findAndCreate(user.company._id);
                //const arr = data.filter((item) => item._id !== tmp._id);
                setListLeaveSetting(data);
            }

            if (
                user &&
                user.role &&
                (user.role === ROLE.COMPANYSTAFF || user.role === ROLE.USER) &&
                user.employee &&
                user.employee.company &&
                user.employee.company._id
            ) {
                const { data } = await leavesSettingAPI.getToOverwrite(user.employee.company._id);
                //const { data: tmp } = await leavesSettingAPI.findAndCreate(user.employee.company._id);
                //const arr = data.filter((item) => item._id !== tmp._id);
                setListLeaveSetting(data);
            }
        })();
    }, [reload, companyId, user, location]);

    useEffect(() => {
        (async () => {
            if (!user || !user._id) {
                await getMe();
            }

            if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
                const { data } = await levelAPI.getByCompany(user.company._id);
                setLevelList(data);
            }

            if (
                user &&
                user.role &&
                (user.role === ROLE.COMPANYSTAFF || user.role === ROLE.USER) &&
                user.employee &&
                user.employee.company &&
                user.employee.company._id
            ) {
                const { data } = await levelAPI.getByCompany(user.employee.company._id);
                setLevelList(data);
            }
        })();
    }, [reload, companyId, user, location]);

    useEffect(() => {
        (async () => {
            if (!user || !user._id) {
                await getMe();
            }

            if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
                const { data } = await bankAPI.getByCompany(user.company._id);
                setBankList(data);
            }

            if (
                user &&
                user.role &&
                (user.role === ROLE.COMPANYSTAFF || user.role === ROLE.USER) &&
                user.employee &&
                user.employee.company &&
                user.employee.company._id
            ) {
                const { data } = await bankAPI.getByCompany(user.employee.company._id);
                setBankList(data);
            }
        })();
    }, [reload, companyId, user, location]);

    useEffect(() => {
        (async () => {
            if (!user || !user._id) {
                await getMe();
            }
            if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
                const { data } = await leavesSettingAPI.getByCompany(user.company._id);
                setAddLeaveWordFlowList(data);
            }
            if (
                user &&
                user.role &&
                (user.role === ROLE.COMPANYSTAFF || user.role === ROLE.USER) &&
                user.employee &&
                user.employee.company &&
                user.employee.company._id
            ) {
                const { data } = await leavesSettingAPI.getByCompany(user.employee.company._id);
                setAddLeaveWordFlowList(data);
            }
            // if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
            //     const { data } = await leaveWordFlowAPI.getByCompany(user.company._id);
            //     setAddLeaveWordFlowList(data);
            // }

            // if (
            //     user &&
            //     user.role &&
            //     (user.role === ROLE.COMPANYSTAFF || user.role === ROLE.USER) &&
            //     user.employee &&
            //     user.employee.company &&
            //     user.employee.company._id
            // ) {
            //     const { data } = await leaveWordFlowAPI.getByCompany(user.employee.company._id);
            //     setAddLeaveWordFlowList(data);
            // }
        })();
    }, [reload, companyId, user, location]);

    useEffect(() => {
        (async () => {
            if (!user || !user._id) {
                await getMe();
            }

            if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
                const { data } = await workDayAPI.getByCompany(user.company._id);
                setWorkDayList(data);
            }

            if (
                user &&
                user.role &&
                (user.role === ROLE.COMPANYSTAFF || user.role === ROLE.USER) &&
                user.employee &&
                user.employee.company &&
                user.employee.company._id
            ) {
                const { data } = await workDayAPI.getByCompany(user.employee.company._id);
                setWorkDayList(data);
            }
        })();
    }, [reload, companyId, user, location]);

    useEffect(() => {
        (async () => {
            if (!user || !user._id) {
                await getMe();
            }
            if (employee && employee._id && employee._id.length > 0) {
                const { data } = await individualLeaveSettingAPI.getByEmployee(employee._id);
                setDataIndividualLeaveSetting(data);
            } else if (id && id.length > 0) {
                const { data } = await individualLeaveSettingAPI.getByEmployee(id);
                setDataIndividualLeaveSetting(data);
            }
        })();
    }, [reload, companyId, user, location, render]);

    useEffect(() => {
        (async () => {
            if (!user || !user._id) {
                await getMe();
            }

            if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
                // const { data } = await jobHolidayAPI.getByCompany(user.company._id);
                const { data } = await publicHolidayAPI.getByCompany(user.company._id);
                setHolidayList(data);
            }

            if (
                user &&
                user.role &&
                (user.role === ROLE.COMPANYSTAFF || user.role === ROLE.USER) &&
                user.employee &&
                user.employee.company &&
                user.employee.company._id
            ) {
                const { data } = await publicHolidayAPI.getByCompany(user.employee.company._id);
                setHolidayList(data);
            }
        })();
    }, [reload, companyId, user, location]);

    useEffect(() => {
        (async () => {
            if (!user || !user._id) {
                await getMe();
            }

            if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
                await ethnicityAPI.findAndCreate(user.company._id);
                const { data } = await ethnicityAPI.getByCompany(user.company._id);
                setEthnicityList(data);
            }

            if (
                user &&
                user.role &&
                (user.role === ROLE.COMPANYSTAFF || user.role === ROLE.USER) &&
                user.employee &&
                user.employee.company &&
                user.employee.company._id
            ) {
                await ethnicityAPI.findAndCreate(user.employee.company._id);
                const { data } = await ethnicityAPI.getByCompany(user.employee.company._id);

                setEthnicityList(data);
            }
        })();
    }, [reload, companyId, user, location]);

    useEffect(() => {
        (async () => {
            if (!user || !user._id) {
                await getMe();
            }

            if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
                const { data } = await religionAPI.getByCompany(user.company._id);
                setReligionList(data);
            }

            if (
                user &&
                user.role &&
                (user.role === ROLE.COMPANYSTAFF || user.role === ROLE.USER) &&
                user.employee &&
                user.employee.company &&
                user.employee.company._id
            ) {
                const { data } = await religionAPI.getByCompany(user.employee.company._id);
                setReligionList(data);
            }
        })();
    }, [reload, companyId, user, location]);

    useEffect(() => {
        (async () => {
            if (!user || !user._id) {
                await getMe();
            }

            if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
                const { data } = await employeesAPI.getAllCompany(user.company._id);
                setListEmployees(data);
            }

            if (
                user &&
                user.role &&
                (user.role === ROLE.COMPANYSTAFF || user.role === ROLE.USER) &&
                user.employee &&
                user.employee.company &&
                user.employee.company._id
            ) {
                const { data } = await employeesAPI.getAllCompany(user.employee.company._id);
                setListEmployees(data);
            }
        })();
    }, [reload, companyId, user, location]);

    useEffect(() => {
        (async () => {
            const { data } = await currencyAPI.getCurrency();
            setCurrency(Object.keys(data));
        })();
    }, []);

    useEffect(() => {
        if (id) {
            fetchEmployee(id);
        }
    }, [id]);

    useEffect(() => {
        (async () => {
            if (address && address.country) {
                const { data } = await countriesAPI.getStates(address.country);
                setState(data);
            }
        })();
    }, [address]);

    useEffect(() => {
        (async () => {
            if (user && user.role) {
                await loadCompanies();

                if (user.role === ROLE.COMPANY) {
                    setAddDepartment({
                        ...addDepartment,
                        companyID: user.company._id,
                    });
                    await loadEmployees(user.company._id);
                }
                if (user.role === ROLE.COMPANYSTAFF || user.role === ROLE.USER) {
                    setAddDepartment({
                        ...addDepartment,
                        companyID: user.employee.company._id,
                    });
                    await loadEmployees(user.employee.company._id);
                }
            }
        })();
    }, [reload, companyId, user, location]);

    useEffect(() => {
        (async () => {
            if (user && user.role === ROLE.COMPANY) {
                getJobPosition(user.company._id);
                getDepartment(user.company._id);
            } else if (user.employee && user.employee.company) {
                getJobPosition(user.employee.company._id);
                getDepartment(user.employee.company._id);
            }
            getCountry();
        })();
    }, [reload, companyId, user, location]);

    const uploadImage = async (file) => {
        if (file) {
            const { data } = await uploadImageAPI.upload(file);
            return data.url;
        }
        return employee && employee.user && employee.user.avatar;
    };

    const getCountry = async () => {
        const { data } = await countryAPI.getAll();
        setCountryNames(data);
    };

    const getJobPosition = async (id) => {
        const { data } = await jobPositionAPI.getActive(id);
        setJobPositions(data);
    };

    const getDepartment = async (id) => {
        const { data } = await departmentAPI.getAllByCompany(id);
        setDepartments(data);
    };

    const changeNation = (e) => {
        var nationality = '';
        if (e !== '' && e !== 'add') {
            nationality = e;
        }
        setEmployee({ ...employee, nationality: nationality });
    };

    const changeType = (e) => {
        var type = '';
        EMPLOYEES_TYPE.forEach((item) => {
            if (e === item.value) {
                type = item.value;
            }
        });
        if (type === 'local-staff') {
            setShowWP(false);
            setShowNRIC(false);
            setShowExpiryDateForWP_SP(false);
        } else if (type === 'work-permit') {
            setShowWP(true);
            setShowNRIC(false);
            setShowExpiryDateForWP_SP(true);
        } else {
            setShowWP(true);
            setShowNRIC(true);
            setShowExpiryDateForWP_SP(false);
        }
        setEmployee({ ...employee, type: type });
    };

    const changeJob = (e) => {
        setEmployee({ ...employee, jobPosition: e });
    };

    const changeGender = (e) => {
        if (e.target.value === 1) {
            setEmployee({ ...employee, gender: 'male' });
        } else {
            setEmployee({ ...employee, gender: 'female' });
        }
    };

    const changeDepartment = async (e) => {
        if (employee && employee.department !== '') {
            const { data } = await departmentAPI.getAllByCompany(employee.companyID);
            let tmpDepartmentHead = [];
            if (data) {
                data.forEach((item) => {
                    tmpDepartmentHead.push(item.departmentHead);
                });
            }

            if (tmpDepartmentHead && tmpDepartmentHead.length > 0 && tmpDepartmentHead.includes(employee._id)) {
                toast.error('Employee is the head of the department!');
                return;
            }
        }

        setEmployee({ ...employee, department: e });
    };

    const validate = (data) => {
        if (data && data.employeesId === '') {
            toast.error('Please enter ID');
            return false;
        }

        if (data && data.firstName === '') {
            toast.error('Please enter first name');
            return false;
        }

        if (data && data.lastName === '') {
            toast.error('Please enter last name');
            return false;
        }

        if (data && data.gender === '') {
            toast.error('Please select gender');
            return false;
        }

        if (data && data.nationality === '') {
            toast.error('Please select nationality');
            return false;
        }

        if (data && data.type === '') {
            toast.error('Please select type employee');
            return false;
        }

        if (data && data.nationId === '') {
            toast.error(
                employee.type === EMPLOYEES_TYPE_VALUE.LOCALSTAFF
                    ? 'Please enter type NRIC'
                    : employee.type === EMPLOYEES_TYPE_VALUE.WORKPERMIT
                    ? 'Please enter type FINO'
                    : 'Please enter type national id',
            );
            return false;
        }

        if (data && data.jobPosition === '' && valueTabs !== 1) {
            toast.error('Please select job position');
            return false;
        }

        if (data && data.email === '') {
            toast.error('Please enter email');
            return false;
        }

        return true;
    };

    const validatePerson = () => {
        if (employee && employee.employeesId === '') {
            toast.error('Please enter ID');
            return false;
        }

        if (employee && employee.firstName === '') {
            toast.error('Please enter first name');
            return false;
        }

        if (employee && employee.lastName === '') {
            toast.error('Please enter last name');
            return false;
        }

        if (employee && employee.gender === '') {
            toast.error('Please select gender');
            return false;
        }

        if (employee && employee.nationality === '') {
            toast.error('Please select nationality');
            return false;
        }

        if (employee && employee.type === '') {
            toast.error('Please select type employee');
            return false;
        }

        if (employee && employee.email === '') {
            toast.error('Please enter email');
            return false;
        }

        return true;
    };

    const validateJob = () => {
        if (employee && employee.dateJoin === null) {
            toast.error('Please choose date join');
            return false;
        }
        // if (placement && placement.effectiveDate === null) {
        //     toast.error('Please choose effective date');
        //     return false;
        // }
        if (employee && employee.jobPosition === '') {
            toast.error('Please select job position');
            return false;
        }
        // if (employmentTerm && employmentTerm.effectiveDate === null) {
        //     toast.error('Please choose effective date');
        //     return false;
        // }
        if (
            employmentTerm &&
            employmentTerm.termStart !== null &&
            employmentTerm.termEnd !== null &&
            employmentTerm.termEnd < employmentTerm.termStart
        ) {
            toast.error('Term start day must be before term end day!');
            return false;
        }
        return true;
    };

    const validateSalary = () => {
        // if (salary && salary.effectiveDate === null) {
        //     toast.error('Please choose effective date');
        //     return false;
        // }
        if (salary && salary.basicSalary === null) {
            toast.error('Please insert basic salary');
            return false;
        }
        if (salary && salary.basicSalary < 0) {
            toast.error('Please insert basic salary > 0');
            return false;
        }
        if (salary && salary.currency === null) {
            toast.error('Please choose currency');
            return false;
        }
        return true;
    };

    const validatePR = () => {
        if (
            (defaultPR && defaultPR !== 'Citizens' && !datePR) ||
            (defaultPR && defaultPR !== 'Citizens' && datePR === 0) ||
            (defaultPR && defaultPR !== 'Citizens' && datePR === null)
        ) {
            toast.error('Please select a permanent residence registration date');
            return false;
        }

        return true;
    };
    const submit = async () => {
        const a = validateSalary();
        const b = validatePR();
        if (a == true) {
            if (id) {
                if (b == true) editEmployee(id);
            } else if (idTmp) {
                if (b == true) editEmployee(idTmp);
            } else {
                addEmployee();
            }
        }
    };

    const addQuickJobPosition = async () => {
        if (!user) {
            getMe();
        }
        if (user && addJobPosition) {
            if (addJobPosition.code === '') {
                toast.error('Job position code should not be empty!');
            } else if (addJobPosition.jobPositionName === '') {
                toast.error('Job position title not be empty!');
            }
            try {
                if (user && user.company && user.company._id) {
                    await jobPositionAPI.create({ companyID: user.company._id, ...addJobPosition });
                }

                if (user && user.employee && user.employee.company && user.employee.company._id) {
                    await jobPositionAPI.create({
                        companyID: user.employee.company._id,
                        ...addJobPosition,
                    });
                }
                setReload((prev) => prev + 1);
                //setEmployee({ ...employee, jobPosition: jobPosition.data._id });

                toast.success('Add position successfully');
            } catch (error) {
                toast.error(getError(error));
            }
        }
    };

    const addQuickDepartment = async () => {
        if (!user) {
            getMe();
        }
        if (user && addDepartment) {
            try {
                setLoading(true);
                if (user.role === ROLE.COMPANY && user.company && user.company._id) {
                    const tmp = await departmentAPI.add({ companyID: user.company._id, ...addDepartment });
                    await employeesAPI.setDepartment(tmp.data.departmentHead, tmp.data._id);
                }

                if (user.employee && user.employee.company && user.employee.company._id) {
                    const tmp = await departmentAPI.add({ companyID: user.employee.company._id, ...addDepartment });
                    await employeesAPI.setDepartment(tmp.data.departmentHead, tmp.data._id);
                }

                setAddDepartment(initAddDepartment);
                setReload((prev) => prev + 1);
                setLoading(false);
                toast.success('Add department successfully');
                // setEmployee({ ...employee, department: department.data._id });
            } catch (error) {
                toast.error(getError(error));
                setLoading(false);
            }
        } else toast.error('Add department failed');
    };

    const addQuickIndividualLeaveSetting = async () => {
        if (!user) {
            getMe();
        }
        if (
            user &&
            user.role &&
            (user.role === ROLE.COMPANY || user.role === ROLE.COMPANYSTAFF) &&
            addIndividualLeaveSetting
        ) {
            try {
                setLoading(true);
                if (employee && employee._id) {
                    await individualLeaveSettingAPI.add({
                        ...addIndividualLeaveSetting,
                        employee: employee._id,
                        overwrite: +addIndividualLeaveSetting.overwrite,
                    });
                    setReload((prev) => prev + 1);
                    setAddIndividualLeaveSetting(initAddIndividualLeaveSetting);
                    toast.success('Add Individual Leave Setting successfully');
                } else if (id && id.length > 0) {
                    await individualLeaveSettingAPI.add({
                        ...addIndividualLeaveSetting,
                        employee: id,
                        overwrite: +addIndividualLeaveSetting.overwrite,
                    });
                    setReload((prev) => prev + 1);
                    setAddIndividualLeaveSetting(initAddIndividualLeaveSetting);
                    toast.success('Add Individual Leave Setting successfully');
                } else {
                    const { data } = await leavesSettingAPI.getById(addIndividualLeaveSetting.leaveSetting);
                    let check = true;
                    dataIndividualLeaveSetting.map((item) => {
                        if (item.leaveSetting._id === data._id) {
                            toast.error('Setting already exits!');
                            check = false;
                        }
                    });
                    if (check) {
                        setDataIndividualLeaveSetting((prev) => [
                            ...prev,
                            {
                                ...addIndividualLeaveSetting,
                                overwrite: +addIndividualLeaveSetting.overwrite,
                                leaveSetting: { ...data },
                            },
                        ]);
                    }
                    setAddIndividualLeaveSetting(initAddIndividualLeaveSetting);
                    setReload((prev) => prev + 1);
                }
                form.resetFields();
                setLoading(false);
            } catch (error) {
                toast.error(getError(error));
                form.resetFields();
                setLoading(false);
            }
        } else toast.error('Add Individual Leave Setting failed');
    };

    const addQuickBranch = async () => {
        if (!user) {
            getMe();
        }
        if (user && addBranch) {
            if (addBranch.code === '') {
                toast.error('Branch code should not be empty!');
            }
            if (addBranch.name === '') {
                toast.error('Branch name should not be empty!');
            }
            try {
                if (user && user.company && user.company._id) {
                    await branchAPI.add({ ...addBranch, company: user.company._id });
                }
                if (user && user.employee && user.employee.company && user.employee.company._id) {
                    await branchAPI.add({ ...addBranch, company: user.employee.company._id });
                }

                setReload((prev) => prev + 1);
                toast.success('Add branch successfully');
                //setPlacement({ ...placement, branch: branch.data._id });
            } catch (error) {
                toast.error(getError(error));
            }
        } else toast.error('Add branch failed');
    };

    const addQuickLevel = async () => {
        if (!user) {
            getMe();
        }
        if (user && addLevel) {
            if (addLevel.code === '') {
                toast.error('Level code should not be empty!');
            }
            if (addLevel.name === '') {
                toast.error('Level name should not be empty!');
            }
            try {
                if (user.role === ROLE.COMPANY && user.company && user.company._id) {
                    await levelAPI.add({ ...addLevel, company: user.company._id });
                }
                if (user.employee && user.employee.company && user.employee.company._id) {
                    await levelAPI.add({ ...addLevel, company: user.employee.company._id });
                }

                //setPlacement({ ...placement, level: level.data._id });
                setReload((prev) => prev + 1);
                toast.success('Add level successfully');
            } catch (error) {
                toast.error(getError(error));
            }
        } else toast.error('Add level failed');
    };

    const addQuickBank = async () => {
        if (!user) {
            getMe();
        }
        if (user && addBank) {
            if (addBank.code === '') {
                toast.error('Bank code should not be empty!');
            }
            if (addBank.name === '') {
                toast.error('Bank name should not be empty!');
            }
            try {
                if (user.role === ROLE.COMPANY && user.company && user.company._id) {
                    await bankAPI.add({ ...addBank, company: user.company._id });
                }
                if (user && user.employee && user.employee.company && user.employee.company._id) {
                    await bankAPI.add({ ...addBank, company: user.employee.company._id });
                }

                setReload((prev) => prev + 1);
                toast.success('Add bank successfully');
                //setPlacement({ ...placement, level: level.data._id });
            } catch (error) {
                toast.error(getError(error));
            }
        } else toast.error('Add bank failed');
    };

    const addQuickLeaveWordFlow = async () => {
        if (!user) {
            getMe();
        }
        if (user && addLeaveWordFlow) {
            if (addLeaveWordFlow.code === '') {
                toast.error('Code should not be empty!');
            }
            if (addLeaveWordFlow.name === '') {
                toast.error('Name should not be empty!');
            }
            try {
                if (user && user.company && user.company._id) {
                    await leaveWordFlowAPI.add({ ...addLeaveWordFlow, company: user.company._id });
                }
                if (user && user.employee && user.employee.company && user.employee.company._id) {
                    await leaveWordFlowAPI.add({ ...addLeaveWordFlow, company: user.employee.company._id });
                }

                setReload((prev) => prev + 1);
                toast.success('Add leave wordflow successfully');
                //setPlacement({ ...placement, level: level.data._id });
            } catch (error) {
                toast.error(getError(error));
            }
        } else toast.error('Add leave wordflow  failed');
    };

    const addQuickWorkDay = async () => {
        if (!user) {
            getMe();
        }
        if (user && addWorkDay) {
            if (addWorkDay.code === '') {
                toast.error('Code should not be empty!');
            }
            if (addWorkDay.name === '') {
                toast.error('Name should not be empty!');
            }
            try {
                if (user && user.company && user.company._id) {
                    await workDayAPI.add({ ...addWorkDay, company: user.company._id });
                }
                if (user && user.employee && user.employee.company && user.employee.company._id) {
                    await workDayAPI.add({ ...addWorkDay, company: user.company._id });
                }

                setReload((prev) => prev + 1);
                toast.success('Add work day successfully');
                //setPlacement({ ...placement, level: level.data._id });
            } catch (error) {
                toast.error(getError(error));
            }
        } else toast.error('Add work day failed');
    };

    const addQuickHoliday = async () => {
        if (!user) {
            getMe();
        }

        if (user && addHoliday) {
            if (addHoliday.code === '') {
                toast.error('Code should not be empty!');
            }
            if (addHoliday.name === '') {
                toast.error('Name should not be empty!');
            }
            try {
                if (user && user.company && user.company._id) {
                    await jobHolidayAPI.add({ ...addHoliday, company: user.company._id });
                }
                if (user && user.employee && user.employee.company && user.employee.company._id) {
                    await jobHolidayAPI.add({ ...addHoliday, company: user.employee.company._id });
                }

                setReload((prev) => prev + 1);
                toast.success('Add holiday successfully');
                //setPlacement({ ...placement, level: level.data._id });
            } catch (error) {
                toast.error(getError(error));
            }
        } else toast.error('Add holiday failed');
    };

    const addQuickEthnicity = async () => {
        if (!user) {
            getMe();
        }
        if (user && addEthnicity) {
            if (addEthnicity.name === '') {
                toast.error('Name should not be empty!');
            }
            try {
                if (user.role === ROLE.COMPANY && user.company && user.company._id) {
                    await ethnicityAPI.add({ ...addEthnicity, company: user.company._id });
                }
                if (user.employee && user.employee.company && user.employee.company._id) {
                    await ethnicityAPI.add({ ...addEthnicity, company: user.employee.company._id });
                }

                setReload((prev) => prev + 1);
                toast.success('Add ethnicity successfully');
                //setPlacement({ ...placement, level: level.data._id });
            } catch (error) {
                toast.error(getError(error));
            }
        }
    };

    const addQuickReligion = async () => {
        if (!user) {
            getMe();
        }
        if (user && addReligion) {
            if (addReligion.name === '') {
                toast.error('Name should not be empty!');
            }
            try {
                if (user.role === ROLE.COMPANY && user.company && user.company._id) {
                    await religionAPI.add({ ...addReligion, company: user.company._id });
                }
                if (user.employee && user.employee.company && user.employee.company._id) {
                    await religionAPI.add({ ...addReligion, company: user.employee.company._id });
                }

                setReload((prev) => prev + 1);
                toast.success('Add religion successfully');
                //setPlacement({ ...placement, level: level.data._id });
            } catch (error) {
                toast.error(getError(error));
            }
        } else toast.error('Add religion failed');
    };

    const addQuickEntry = async () => {
        try {
            if (validate(employee)) {
                if (user && (user.role === ROLE.ADMIN || user.role === ROLE.SYSTEMSTAFF)) {
                    await employeesAPI.add({
                        ...employee,
                        payment: payment,
                    });
                } else if (user && user.role === ROLE.COMPANY) {
                    await employeesAPI.add({
                        ...employee,
                        companyID: user.company._id,
                    });
                } else {
                    await employeesAPI.add({
                        ...employee,
                        companyID: user.employee.company._id,
                    });
                }
                toast.success('Created successfully!');
                setEmployee(initEmployee);
            }
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const addPerson = async () => {
        try {
            if (validate(employee)) {
                if (user && (user.role === ROLE.ADMIN || user.role === ROLE.SYSTEMSTAFF)) {
                    const { data } = await employeesAPI.add({
                        ...employee,
                        payment: payment,
                    });
                    setIdTmp(data._id);
                    localStorage.setItem('newEmployee', JSON.stringify(data));
                } else if (user && user.role === ROLE.COMPANY) {
                    const { data } = await employeesAPI.add({
                        ...employee,
                        companyID: user.company._id,
                    });
                    setIdTmp(data._id);
                    localStorage.setItem('newEmployee', JSON.stringify(data));
                } else {
                    const { data } = await employeesAPI.add({
                        ...employee,
                        companyID: user.employee.company._id,
                    });
                    setIdTmp(data._id);
                    localStorage.setItem('newEmployee', JSON.stringify(data));
                }
                toast.success('Save successfully!');
                setValueTabs(valueTabs + 1);
                setAdded(true);
            }
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const updateTabs = async () => {
        try {
            const userId = JSON.parse(localStorage.getItem('newEmployee'));
            const urlImg = await uploadImage(fileImage);
            if (user && (user.role === ROLE.ADMIN || user.role === ROLE.SYSTEMSTAFF)) {
                const { data } = await employeesAPI.update(userId._id, {
                    ...employee,
                    payment: payment,
                    employmentTerm: employmentTerm,
                    placement: placement,
                    phone: phone,
                    address: address,
                    emergency: emergency,
                    avatar: urlImg,
                    timeClock: timeClock,
                    family: familys,
                    health: health,
                    derectory: derectory,
                    salary: salary,
                });
                localStorage.setItem('newEmployee', JSON.stringify(data));
            } else if (user && user.role === ROLE.COMPANY) {
                const { data } = await employeesAPI.update(userId._id, {
                    ...employee,
                    companyID: user.company._id,
                    payment: payment,
                    employmentTerm: employmentTerm,
                    placement: placement,
                    phone: phone,
                    address: address,
                    emergency: emergency,
                    avatar: urlImg,
                    timeClock: timeClock,
                    family: familys,
                    health: health,
                    derectory: derectory,
                    salary: salary,
                });
                localStorage.setItem('newEmployee', JSON.stringify(data));
            } else {
                const { data } = await employeesAPI.update(userId._id, {
                    ...employee,
                    companyID: user.employee.company._id,
                    payment: payment,
                    employmentTerm: employmentTerm,
                    placement: placement,
                    phone: phone,
                    address: address,
                    avatar: urlImg,
                    emergency: emergency,
                    timeClock: timeClock,
                    family: familys,
                    health: health,
                    derectory: derectory,
                    salary: salary,
                });
                localStorage.setItem('newEmployee', JSON.stringify(data));
            }
            toast.success('Save successfully!');
            if (valueTabs < 7) {
                setValueTabs(valueTabs + 1);
            }
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const addEmployee = async () => {
        try {
            if (validate(employee)) {
                setLoading(true);
                let employ;
                const urlImg = await uploadImage(fileImage);
                if (user && (user.role === ROLE.ADMIN || user.role === ROLE.SYSTEMSTAFF)) {
                    employ = await (
                        await employeesAPI.add({
                            ...employee,
                            payment: payment,
                            employmentTerm: employmentTerm,
                            placement: placement,
                            phone: phone,
                            address: address,
                            emergency: emergency,
                            avatar: urlImg,
                            timeClock: timeClock,
                            family: familys,
                            health: health,
                            derectory: derectory,
                            salary: salary,
                            password: newPassword,
                        })
                    ).data;
                } else if (user && user.role === ROLE.COMPANY) {
                    employ = await (
                        await employeesAPI.add({
                            ...employee,
                            companyID: user.company._id,
                            payment: payment,
                            employmentTerm: employmentTerm,
                            placement: placement,
                            phone: phone,
                            address: address,
                            emergency: emergency,
                            avatar: urlImg,
                            timeClock: timeClock,
                            family: familys,
                            health: health,
                            derectory: derectory,
                            salary: salary,
                            password: newPassword,
                        })
                    ).data;
                } else {
                    const result = await employeesAPI.add({
                        ...employee,
                        companyID: user.employee.company._id,
                        payment: payment,
                        employmentTerm: employmentTerm,
                        placement: placement,
                        phone: phone,
                        address: address,
                        avatar: urlImg,
                        emergency: emergency,
                        timeClock: timeClock,
                        family: familys,
                        health: health,
                        derectory: derectory,
                        salary: salary,
                        password: newPassword,
                    });
                    employ = result.data;
                    if (result && result.data && result.data._id && user && user.employee && user.employee.firstName) {
                        await historiesAPI.add({
                            title: 'EMPLOYEES',
                            desc: `${user.employee.firstName} just add new employee `,
                            employee: result.data._id,
                            type: TYPE_NOTIFICATION.EMPLOYEE,
                            idRequest: id,
                            toCompany: true,
                        });
                    }
                }
                if (dataIndividualLeaveSetting && dataIndividualLeaveSetting.length > 0 && employ && employ._id) {
                    for await (const item of dataIndividualLeaveSetting) {
                        await individualLeaveSettingAPI.add({
                            employee: employ._id,
                            leaveSetting: item.leaveSetting._id,
                            overwrite: +item.overwrite,
                        });
                    }
                }
                if (listTmpCertificate && listTmpCertificate.length > 0 && employ && employ._id) {
                    for await (const item of listTmpCertificate) {
                        await certificateAPI.updateIdEmployee(item._id, employ._id.toString());
                    }
                }
                if (dataCompany && employ) {
                    const a = await permanentResidentsAPI.create({
                        company: dataCompany._id.toString(),
                        employee: employ._id.toString(),
                        status: dataCompany.defaultPR,
                        date: new Date().getTime(),
                    });
                }
                setIsSaved(true);
                setLoading(false);
                toast.success('Created successfully!');
                history.push('/employees/all');
            }
        } catch (error) {
            setLoading(false);
            toast.error(getError(error));
        }
    };

    const editEmployee = async (id) => {
        try {
            setLoading(true);
            const urlImg = await uploadImage(fileImage);
            if (user && (user.role === ROLE.ADMIN || user.role === ROLE.SYSTEMSTAFF)) {
                if (
                    employmentTerm.termStart &&
                    employmentTerm.termEnd &&
                    employmentTerm.termEnd < employmentTerm.termStart
                ) {
                    toast.error('Term start day must be before term end day!');
                    return false;
                }
                await employeesAPI.update(id, {
                    ...employee,
                    payment: payment,
                    employmentTerm: employmentTerm,
                    placement: placement,
                    phone: phone,
                    address: address,
                    emergency: emergency,
                    avatar: urlImg,
                    timeClock: timeClock,
                    family: familys,
                    health: health,
                    derectory: derectory,
                    salary: salary,
                });
                await updatePermanentResidents({ company: employee.companyID.toString(), employee: id.toString() });
            } else if (user && user.role === ROLE.COMPANY) {
                if (
                    employmentTerm &&
                    employmentTerm.termStart !== null &&
                    employmentTerm.termEnd !== null &&
                    employmentTerm.termEnd < employmentTerm.termStart
                ) {
                    toast.error('Term start day must be before term end day!');
                    return false;
                }
                await employeesAPI.update(id, {
                    ...employee,
                    companyID: user.company._id,
                    payment: payment,
                    employmentTerm: employmentTerm,
                    placement: placement,
                    phone: phone,
                    address: address,
                    emergency: emergency,
                    avatar: urlImg,
                    timeClock: timeClock,
                    family: familys,
                    health: health,
                    derectory: derectory,
                    salary: salary,
                });
                await updatePermanentResidents({ company: user.company._id.toString(), employee: id.toString() });
            } else {
                if (
                    employmentTerm.termStart &&
                    employmentTerm.termEnd &&
                    employmentTerm.termEnd < employmentTerm.termStart
                ) {
                    toast.error('Term start day must be before term end day!');
                    return false;
                }
                const result = await employeesAPI.update(id, {
                    ...employee,
                    companyID: user.employee.company._id,
                    payment: payment,
                    employmentTerm: employmentTerm,
                    placement: placement,
                    phone: phone,
                    address: address,
                    avatar: urlImg,
                    emergency: emergency,
                    timeClock: timeClock,
                    family: familys,
                    health: health,
                    derectory: derectory,
                    salary: salary,
                });

                if (result && result.data && result.data._id && user && user.employee && user.employee.firstName) {
                    await historiesAPI.add({
                        title: 'UPDATE INFO',
                        desc: `${user.employee.firstName} change info of ${result.data.firstName} `,
                        employee: result.data._id,
                        type: TYPE_NOTIFICATION.EMPLOYEE,
                        idRequest: id,
                        toCompany: true,
                    });
                }
                await updatePermanentResidents({ company: user.company._id.toString(), employee: id.toString() });
            }
            setIsSaved(true);
            setLoading(false);
            toast.success('Update successfully!');
            history.push('/employees/all');
        } catch (error) {
            setLoading(false);
            toast.error(getError(error));
        }
    };

    const cancel = () => {
        setIsSaved(true);
        localStorage.setItem('saved', true);
        history.push('/employees/all');
    };

    const updatePermanentResidents = async (payload) => {
        await permanentResidentsAPI.update({
            company: payload.company,
            employee: payload.employee,
            status: defaultPR || 'Citizens',
            date: datePR,
        });
    };
    const showModal = (key) => {
        if (key === 1) {
            setIsModalNationality(true);
        }
        if (key === 2) {
            setIsModalPosition(true);
        }

        if (key === 3) {
            setIsModalDepartment(true);
        }

        if (key === 4) {
            setIsModalBranch(true);
        }

        if (key === 5) {
            setIsModalLevel(true);
        }

        if (key === 6) {
            setIsModalBank(true);
        }

        if (key === 7) {
            // setIsModalLeaveWordFlow(true);
            history.push('/employers/leave-settings');
        }

        if (key === 8) {
            setIsModalWorkDay(true);
        }

        if (key === 9) {
            setIsModalHoliday(true);
        }

        if (key === 10) {
            setIsModalEthnicity(true);
        }

        if (key === 11) {
            setIsModalReligion(true);
        }

        if (key === 12) {
            setModalIndividualLeaveSetting(true);
        }
    };

    const handleCancelNationality = () => {
        setIsModalNationality(false);
    };

    const handleOkPosition = async () => {
        try {
            addQuickJobPosition();
            setAddJobPosition(initAddJobPosition);
            setIsModalPosition(false);
            setReload();
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const handleCancelPosition = () => {
        setAddJobPosition(initAddJobPosition);
        setIsModalPosition(false);
    };

    const handleOkDepartment = async () => {
        try {
            addQuickDepartment();
            setAddDepartment(initAddDepartment);
            setIsModalDepartment(false);
            setReload();
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const handleCancelDepartment = () => {
        setAddDepartment(initAddDepartment);
        setIsModalDepartment(false);
    };

    const handleOkIndividualLeaveSetting = async () => {
        try {
            addQuickIndividualLeaveSetting();
            setAddIndividualLeaveSetting(initAddIndividualLeaveSetting);
            setModalIndividualLeaveSetting(false);
            setReload();
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const handleCancelIndividualLeaveSetting = () => {
        setAddIndividualLeaveSetting(initAddIndividualLeaveSetting);
        setModalIndividualLeaveSetting(false);
    };

    const handleOkBranch = async () => {
        try {
            addQuickBranch();
            setAddBranch(initAddBranch);
            setIsModalBranch(false);
            setReload();
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const handleCancelBranch = () => {
        setAddBranch(initAddBranch);
        setIsModalBranch(false);
    };

    const handleOkLevel = async () => {
        try {
            addQuickLevel();
            setAddLevel(initAddLevel);
            setIsModalLevel(false);
            setReload();
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const handleCancelLevel = () => {
        setAddLevel(initAddLevel);
        setIsModalLevel(false);
    };

    const handleOkBank = async () => {
        try {
            addQuickBank();
            setAddBank(initAddBank);
            setIsModalBank(false);
            setReload();
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const handleCancelBank = () => {
        setAddBank(initAddBank);
        setIsModalBank(false);
    };

    const handleOkLeaveWordFlow = async () => {
        try {
            addQuickLeaveWordFlow();
            setAddLeaveWordFlow(initAddLeaveWordFlow);
            setIsModalLeaveWordFlow(false);
            setReload();
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const handleCancelLeaveWordFlow = () => {
        setAddLeaveWordFlow(initAddLeaveWordFlow);
        setIsModalLeaveWordFlow(false);
    };

    const handleOkWorkDay = async () => {
        try {
            addQuickWorkDay();
            setAddWorkDay(initAddWorkDay);
            setIsModalWorkDay(false);
            setReload();
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const handleCancelWorkDay = () => {
        setAddWorkDay(initAddWorkDay);
        setIsModalWorkDay(false);
    };

    const handleOkHoliday = async () => {
        try {
            addQuickHoliday();
            setAddHoliday(initAddHoliday);
            setIsModalHoliday(false);
            setReload();
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const handleCancelHoliday = () => {
        setAddHoliday(initAddHoliday);
        setIsModalHoliday(false);
    };

    const handleOkEthnicity = async () => {
        try {
            addQuickEthnicity();
            setAddEthnicity(initAddEthnicity);
            setIsModalEthnicity(false);
            setReload();
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const handleCancelEthnicity = () => {
        setAddEthnicity(initAddEthnicity);
        setIsModalEthnicity(false);
    };

    const handleOkReligion = async () => {
        try {
            addQuickReligion();
            setAddReligion(initAddReligion);
            setIsModalReligion(false);
            setReload();
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const handleCancelReligion = () => {
        setAddReligion(initAddReligion);
        setIsModalReligion(false);
    };

    const openInput = () => {
        const input = document.getElementById('img_input');
        input.click();
    };

    const handleChangePhoto = (data) => {
        const file = data[0];
        setFileImage(file);
        const reader = new FileReader();
        reader.onload = (rs) => {
            setImagePreviewUrl(rs.target.result);
        };
        reader.readAsDataURL(file);
    };

    const onChangeSwitch = (checked) => {
        setTimeClock(checked);
    };

    const loadCompanies = async () => {
        try {
            if (user.role === ROLE.COMPANY) {
                const { data } = await companiesAPI.getById(user.company._id);
                setCompanies(data);
            }
            if (user.role === ROLE.COMPANYSTAFF) {
                const { data } = await companiesAPI.getById(user.employee.company._id);
                setCompanies(data);
            }
        } catch (error) {
            toast.error(getError(error));
        }
    };
    const loadEmployees = async (id) => {
        try {
            if (id) {
                const { data } = await employeesAPI.getDepartment(id);
                setEmployees(data);
            }
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const handleNextTab = async () => {
        setValueTabs(valueTabs + 1);
    };
    const handleNextTab2 = async () => {
        setEmployee({ ...employee, placement: placement, employmentTerm: employmentTerm });
        setValueTabs(valueTabs + 1);
    };
    const handleNextTab3 = async () => {
        setEmployee({ ...employee, salary: salary, payment: payment });
        setValueTabs(valueTabs + 1);
    };

    const handleNextTab4 = async () => {
        setEmployee({ ...employee, family: familys });
        setValueTabs(valueTabs + 1);
    };

    const handleNextTab5 = async () => {
        setEmployee({ ...employee, phone: phone, address: address, emergency: emergency });
        setValueTabs(valueTabs + 1);
    };

    const handleNextTab6 = async () => {
        setEmployee({ ...employee, health: health });
        setValueTabs(valueTabs + 1);
    };
    const handleNextTab7 = async () => {
        setEmployee({ ...employee, derectory: derectory });
        setValueTabs(valueTabs + 1);
    };
    const handleNextTab8 = async () => {
        setEmployee({ ...employee, derectory: derectory });
        setValueTabs(valueTabs + 1);
    };
    const handleNextTab9 = async () => {
        try {
            setLoading(true);
            let urlImg = undefined;
            if (fileImage) {
                urlImg = await uploadImage(fileImage);
            }
            let employ;
            if ((user.role === ROLE.COMPANY || user.role === ROLE.COMPANYSTAFF) && user.company && user.company._id) {
                employ = await (await employeesAPI.add({ ...employee, companyID: user.company._id, avatar: urlImg }))
                    .data;
            }
            if (user.role === ROLE.USER && user.employee && user.employee.company && user.employee.company._id) {
                employ = await (
                    await employeesAPI.add({ ...employee, companyID: user.employee.company._id, avatar: urlImg })
                ).data;
            }

            if (dataIndividualLeaveSetting && dataIndividualLeaveSetting.length > 0 && employ && employ._id) {
                for await (const item of dataIndividualLeaveSetting) {
                    await individualLeaveSettingAPI.add({
                        employee: employ._id,
                        leaveSetting: item.leaveSetting._id,
                        overwrite: +item.overwrite,
                    });
                }
            }
            setIsSaved(true);
            setLoading(false);
            toast.success('Created successfully!');
            setEmployee(initEmployee);
            history.push('/employees/all');
        } catch (error) {
            toast.error(getError(error));
            setLoading(false);
        }
    };

    const initAddHoliday = {
        title: '',
        startDay: null,
        endDay: null,
    };

    const addHolidays = async () => {
        try {
            if (Object.keys(user).length === 0) await getMe();
            let id = null;
            if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
                id = user.company._id;
            }
            if (
                user &&
                user.role &&
                user.role === ROLE.COMPANYSTAFF &&
                user.employee &&
                user.employee.company &&
                user.employee.company._id
            ) {
                id = user.employee.company._id;
            }

            if (id !== null) {
                const result = await publicHolidayAPI.create({
                    title: titleHoliday,
                    startDay: day.startDay,
                    endDay: day.endDay,
                    companyId: id,
                });

                if (result && user && user.role && user.role === ROLE.COMPANYSTAFF) {
                    await historiesAPI.add({
                        title: 'PUBLIC HOLIDAY',
                        desc: `Client HR ${user.employee.firstName} just add new public holiday`,
                        type: TYPE_NOTIFICATION.HOLIDAY,
                        employee: user.employee._id,
                        idRequest: result.data._id,
                        toCompany: true,
                    });
                }
                toast.success('Successfully added public holiday');
            }
        } catch (error) {
            toast.error(getError(error));
        }
    };
    const [day, setDay] = useState(initAddHoliday);
    const [titleHoliday, setTitleHoliday] = useState('');
    const [confirmLoading, setConfirmLoading] = useState(false);
    const validateAddHoliday = () => {
        if (titleHoliday === '') {
            toast.error('Please enter title');
            return false;
        }
        if (day.startDay === null) {
            toast.error('Please choose start day');
            return false;
        }
        if (day.endDay === null) {
            toast.error('Please choose end day');
            return false;
        }
        if (new Date(day.startDay).getTime() > new Date(day.endDay).getTime()) {
            toast.error('The start date cannot be greater than the end date');
            return false;
        }
        return true;
    };
    const handleOkAddHoliday = async () => {
        if (validateAddHoliday()) {
            setConfirmLoading(true);
            await addHolidays();
            setIsModalHoliday(false);
            setConfirmLoading(false);
            setReload((e) => e + 1);
        }
    };
    const handleCancelAddHoliday = () => {
        setIsModalHoliday(false);
    };
    const onChangeStartDay = (date, dateString) => {
        setDay({ ...day, startDay: new Date(new Date(dateString).setHours(0, 0, 0, 0)).getTime() });
    };

    const onChangeEndDay = (date, dateString) => {
        setDay({ ...day, endDay: new Date(new Date(dateString).setHours(23, 59, 59, 999)).getTime() });
    };

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle
                    title={`${urlView !== -1 ? 'View' : urlEdit !== -1 ? 'Edit' : 'Add New'} Employee`}
                    subtitle={
                        <>
                            <span className="route-extra">Employees / </span>
                            <span className="route-main">
                                {urlView !== -1 ? 'View' : urlEdit !== -1 ? 'Edit' : 'Add New'} Employee
                            </span>
                        </>
                    }
                    className="text-sm-left mb-3"
                />
            </Row>
            <Card>
                <Row>
                    <Col>
                        <Tabs value={valueTabs} onChange={handleChangeTabs} aria-label="icon tabs example">
                            <Tab
                                className="slide"
                                label={valueTabs === 0 && <span>Quick Entry</span>}
                                icon={<FlashOnIcon />}
                                {...a11yProps(0)}
                                aria-label="Quick Entry"
                            />
                            <Tab
                                className="slide"
                                label={valueTabs === 1 && <span>Employee</span>}
                                icon={<PersonIcon />}
                                {...a11yProps(1)}
                                aria-label="employee"
                            />
                            <Tab
                                className="slide"
                                label={valueTabs === 2 && <span>Job</span>}
                                icon={<BusinessCenterIcon />}
                                {...a11yProps(2)}
                                aria-label="JOB"
                            />
                            <Tab
                                className="slide"
                                label={valueTabs === 3 && <span>Salary</span>}
                                icon={<MonetizationOnIcon />}
                                {...a11yProps(3)}
                                aria-label="SALARY"
                            />
                            <Tab
                                className="slide"
                                label={valueTabs === 4 && <span>Family</span>}
                                icon={<FamilyRestroomIcon />}
                                {...a11yProps(4)}
                                aria-label="Family"
                            />
                            <Tab
                                label={valueTabs === 5 && <span>Contact details</span>}
                                className="slide"
                                icon={<PhoneInTalkIcon />}
                                {...a11yProps(5)}
                                aria-label="Contacts"
                            />
                            <Tab
                                className="slide"
                                label={valueTabs === 6 && <span>Health</span>}
                                icon={<LocalHospitalIcon />}
                                {...a11yProps(6)}
                                aria-label="Phone"
                            />
                            <Tab
                                className="slide"
                                label={valueTabs === 7 && <span>Directory</span>}
                                icon={<PrivacyTipIcon />}
                                {...a11yProps(7)}
                                aria-label="Pyarnerson"
                            />
                            <Tab
                                className="slide"
                                label={valueTabs === 8 && <span>Certificate</span>}
                                icon={<BatchPredictionIcon />}
                                {...a11yProps(8)}
                                aria-label="Certificate"
                            />
                            <Tab
                                className="slide"
                                label={valueTabs === 9 && <span>Other</span>}
                                icon={<MoreIcon />}
                                {...a11yProps(9)}
                                aria-label="Orther"
                            />
                        </Tabs>
                    </Col>
                </Row>

                <TabPanel value={valueTabs} index={0}>
                    <Row className="">
                        <Col>
                            <Alert
                                message="In a rush? Just fill up Quick entry and you are good to go."
                                type="info"
                                showIcon
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="employeeId" className="title">
                                <span>*</span> ID
                            </label>
                            <Input
                                placeholder="example"
                                id="employeeId"
                                className="ct-input"
                                value={employee.employeesId}
                                disabled={urlView !== -1 && true}
                                onChange={(e) =>
                                    setEmployee({
                                        ...employee,
                                        employeesId: e.target.value,
                                    })
                                }
                            />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="firstName" className="title">
                                <span>*</span> First name
                            </label>
                            <Input
                                placeholder="example"
                                id="firstName"
                                className="ct-input"
                                value={employee.firstName}
                                disabled={urlView !== -1 && true}
                                onChange={(e) =>
                                    setEmployee({
                                        ...employee,
                                        firstName: e.target.value,
                                    })
                                }
                            />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="lastName" className="title">
                                <span>*</span> Last name
                            </label>
                            <Input
                                placeholder="example"
                                id="lastName"
                                className="ct-input"
                                value={employee.lastName}
                                disabled={urlView !== -1 && true}
                                onChange={(e) => setEmployee({ ...employee, lastName: e.target.value })}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="gender" className="title">
                                <span>*</span> Gender
                            </label>
                            <div>
                                <Radio.Group
                                    onChange={changeGender}
                                    value={employee.gender === 'male' ? 1 : 2}
                                    disabled={urlView !== -1 && true}
                                    id="gender"
                                >
                                    <Radio value={1} id="male">
                                        <p style={{ color: '#000' }}>Male</p>
                                    </Radio>
                                    <Radio value={2} id="female">
                                        <p style={{ color: '#000' }}>Female</p>
                                    </Radio>
                                </Radio.Group>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="birthday" className="title">
                                <span>*</span> Day of birth
                            </label>
                            {employee.birthday.length > 0 && (
                                <DatePicker
                                    defaultValue={employee.birthday && moment(employee.birthday, 'YYYY-MM-DD')}
                                    format={'YYYY-MM-DD'}
                                    disabled={urlView !== -1 && true}
                                    style={{ width: '100%' }}
                                    id="birthday"
                                    onChange={(date, dateString) => {
                                        if (date === null && employee && employee.birthday) {
                                            return;
                                        }

                                        setEmployee({
                                            ...employee,
                                            birthday: dateString,
                                        });
                                    }}
                                />
                            )}

                            {!id && (
                                <DatePicker
                                    defaultValue={
                                        employee && employee.birthday
                                            ? moment(moment(new Date(employee.birthday)).format(dateFormat), dateFormat)
                                            : null
                                    }
                                    format={dateFormat}
                                    style={{ width: '100%' }}
                                    disabled={urlView !== -1 && true}
                                    id="birthday"
                                    onSelect={(date) => {
                                        setEmployee({ ...employee, birthday: date });
                                    }}
                                />
                            )}
                        </Col>

                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="nationality" className="title">
                                <span>*</span> Nationality
                            </label>

                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                value={employee.nationality}
                                disabled={urlView !== -1 && true}
                                onChange={changeNation}
                                id="nationality"
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {countries &&
                                    countries.map((item, index) => (
                                        <Option key={index} value={item.iso2}>
                                            {item.name}
                                        </Option>
                                    ))}
                            </Select>
                        </Col>
                        {/* password input  */}

                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="nationality" className="title">
                                <span>*</span> Password
                            </label>
                            <div className="password adminEditPassword">
                                <Form.Item
                                    // label={<span style={{ fontWeight: '500' }}>Password</span>}
                                    name="password"
                                    className="form-edit-password"
                                >
                                    <Input
                                        disabled={urlView !== -1 && true}
                                        value={newPassword}
                                        type={showPass === false ? 'password' : 'text'}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        style={{ paddingRight: '30px' }}
                                    />

                                    {showPass === false ? (
                                        <AiFillEyeInvisible
                                            size={20}
                                            className="eye-password eye-password2"
                                            onClick={() => showPassWord()}
                                        />
                                    ) : (
                                        <AiFillEye
                                            size={20}
                                            className="eye-password eye-password2"
                                            onClick={() => showPassWord()}
                                        />
                                    )}
                                </Form.Item>
                                {urlEdit !== -1 && true && (
                                    <div className="edit-password" disabled={urlView !== -1 && true}>
                                        <div>
                                            <button
                                                type="submit"
                                                disabled={urlEdit !== -1 && true ? false : true}
                                                className="button__search"
                                                onClick={() => handleOpen()}
                                            >
                                                Edit
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                type="submit"
                                                disabled={urlEdit !== -1 && true ? false : true}
                                                className="button__search"
                                                onClick={() => warning()}
                                            >
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Col>
                        <Modal
                            title="Change Password"
                            open={openDetail}
                            onOk={handleOkDetail}
                            onCancel={handleCancel}
                            okText="Submit"
                            cancelText="Cancel"
                        >
                            <div className="form-add-holiday">
                                <div className="mb-4">
                                    <label>New Password</label>
                                    <Input.TextArea
                                        autoSize={{ minRows: 1 }}
                                        value={newPassword || ''}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label>Confirm Password</label>
                                    <Input.TextArea
                                        autoSize={{ minRows: 1 }}
                                        value={confirmPassword || ''}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                </div>
                            </div>
                        </Modal>
                        <Modal
                            title={
                                <>
                                    <svg
                                        width="18"
                                        height="21"
                                        viewBox="0 0 18 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M15.4167 0.833344H2.58333C1.56583 0.833344 0.75 1.65834 0.75 2.66668V15.5C0.75 16.5083 1.56583 17.3333 2.58333 17.3333H6.25L9 20.0833L11.75 17.3333H15.4167C16.425 17.3333 17.25 16.5083 17.25 15.5V2.66668C17.25 1.65834 16.425 0.833344 15.4167 0.833344ZM9.91667 15.5H8.08333V13.6667H9.91667V15.5ZM11.8142 8.39584L10.9892 9.23918C10.3292 9.90834 9.91667 10.4583 9.91667 11.8333H8.08333V11.375C8.08333 10.3667 8.49583 9.45001 9.15583 8.78084L10.2925 7.62584C10.6317 7.29584 10.8333 6.83751 10.8333 6.33334C10.8333 5.32501 10.0083 4.50001 9 4.50001C7.99167 4.50001 7.16667 5.32501 7.16667 6.33334H5.33333C5.33333 4.30751 6.97417 2.66668 9 2.66668C11.0258 2.66668 12.6667 4.30751 12.6667 6.33334C12.6667 7.14001 12.3367 7.87334 11.8142 8.39584Z"
                                            fill="#FCBB0A"
                                        />
                                    </svg>

                                    <p className="title-modal-custom">Warning</p>
                                </>
                            }
                            open={open}
                            onOk={hideModal}
                            onCancel={() => setOpen(false)}
                            okText="Reset"
                            cancelText="Cancel"
                        >
                            <p>Are you sure you want to reset password?</p>
                        </Modal>
                    </Row>
                    <Row>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="type" className="title">
                                <span>*</span> Type Employee
                            </label>
                            <Select
                                style={{ width: '100%' }}
                                value={employee.type}
                                onChange={changeType}
                                disabled={urlView !== -1 && true}
                                id="type"
                            >
                                {EMPLOYEES_TYPE &&
                                    EMPLOYEES_TYPE.map((item, index) => (
                                        <Option key={index} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                            </Select>
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="nationId" className="title">
                                <span>*</span> {titleNation}
                            </label>
                            <Input
                                placeholder="example"
                                id="nationId"
                                className="ct-input"
                                value={employee.nationId}
                                disabled={urlView !== -1 && true}
                                onChange={(e) => setEmployee({ ...employee, nationId: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {employee.type !== EMPLOYEES_TYPE_VALUE.LOCALSTAFF ? (
                            <Col md="4" className="form-group mt-2">
                                <label htmlFor="passport" className="title">
                                    Passport
                                </label>
                                <Input
                                    placeholder="example"
                                    id="passport"
                                    value={employee.passport}
                                    disabled={urlView !== -1 && true}
                                    onChange={(e) => setEmployee({ ...employee, passport: e.target.value })}
                                />
                            </Col>
                        ) : (
                            <></>
                        )}

                        {employee.type !== EMPLOYEES_TYPE_VALUE.LOCALSTAFF ? (
                            <Col md="4" className="form-group mt-2">
                                <label htmlFor="expiryOfPassport" className="title">
                                    Expiry Date for Passport
                                </label>
                                {employee && employee.expiryOfPassport && employee.expiryOfPassport.length > 0 && (
                                    <DatePicker
                                        defaultValue={moment(employee.expiryOfPassport, 'YYYY-MM-DD')}
                                        format={'YYYY-MM-DD'}
                                        disabled={urlView !== -1 && true}
                                        style={{ width: '100%' }}
                                        id="expiryOfPassport"
                                        onChange={(date, dateString) => {
                                            if (date === null && employee && employee.expiryOfPassport) {
                                                return;
                                            }

                                            setEmployee({
                                                ...employee,
                                                expiryOfPassport: dateString,
                                            });
                                        }}
                                    />
                                )}

                                {(!id || !employee.expiryOfPassport) && (
                                    <DatePicker
                                        defaultValue={null}
                                        format={dateFormat}
                                        style={{ width: '100%' }}
                                        disabled={urlView !== -1 && true}
                                        id="expiryOfNationId"
                                        onSelect={(date) => {
                                            setEmployee({ ...employee, expiryOfPassport: date });
                                        }}
                                    />
                                )}
                            </Col>
                        ) : (
                            <></>
                        )}
                    </Row>
                    <Row>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="jobPosition" className="title">
                                <span>*</span> Job position
                            </label>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                value={employee.jobPosition}
                                disabled={urlView !== -1 && true}
                                onChange={changeJob}
                                id="jobPosition"
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {jobPositions &&
                                    jobPositions.map((item, index) => (
                                        <Option key={index} value={item.jobPositionName}>
                                            {item.jobPositionName}
                                        </Option>
                                    ))}
                            </Select>

                            {(ROLE.COMPANY || ROLE.COMPANYSTAFF) && (
                                <>
                                    {urlView === -1 && (
                                        <div className="ct-new-job" onClick={() => showModal(2)}>
                                            <AiFillPlusCircle size={25} color="#ffd96c" />
                                        </div>
                                    )}
                                </>
                            )}
                            <Modal
                                title="Add Job Position"
                                open={isModalPosition}
                                onOk={handleOkPosition}
                                onCancel={handleCancelPosition}
                                zIndex={900}
                            >
                                <Row>
                                    <Col>
                                        <label htmlFor="code" className="title">
                                            <span>*</span> Code
                                        </label>
                                        <Input
                                            value={addJobPosition.code}
                                            onChange={(e) => {
                                                setAddJobPosition({ ...addJobPosition, code: e.target.value });
                                            }}
                                            placeholder="Enter code"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <label htmlFor="title" className="title">
                                            <span>*</span> Title
                                        </label>
                                        <Input
                                            value={addJobPosition.jobPositionName}
                                            onChange={(e) => {
                                                setAddJobPosition({
                                                    ...addJobPosition,
                                                    jobPositionName: e.target.value,
                                                });
                                            }}
                                            placeholder="Enter title"
                                        />
                                    </Col>
                                </Row>
                            </Modal>
                        </Col>

                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="email" className="title">
                                <span>*</span> Email
                            </label>
                            <Input
                                placeholder="For employee web account invitation"
                                id="email"
                                className="ct-input"
                                value={employee.email}
                                disabled={(urlView !== -1 || id) && true}
                                onChange={(e) => {
                                    if (id) {
                                        toast.error('Email editing is not allowed!');
                                        return;
                                    } else {
                                        setEmployee({ ...employee, email: e.target.value });
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {showWP && (
                            <Col md="4" className="form-group mt-2">
                                <label htmlFor="wpsp" className="title">
                                    WP No. / SP No.
                                </label>
                                <Input
                                    placeholder="example"
                                    id="wpsp"
                                    value={employee.wp}
                                    disabled={urlView !== -1 && true}
                                    onChange={(e) => setEmployee({ ...employee, wp: e.target.value })}
                                />
                            </Col>
                        )}
                        {showExpiryDateForWP_SP && (
                            <Col md="4" className="form-group mt-2">
                                <label htmlFor="expiryOfWP_SP" className="title">
                                    Expiry Date for WP/SP
                                </label>
                                {employee && employee.expiryOfWP_SP && employee.expiryOfWP_SP.length > 0 && (
                                    <DatePicker
                                        defaultValue={moment(employee.expiryOfWP_SP, 'YYYY-MM-DD')}
                                        format={'YYYY-MM-DD'}
                                        disabled={urlView !== -1 && true}
                                        style={{ width: '100%' }}
                                        id="expiryOfWP_SP"
                                        onChange={(date, dateString) => {
                                            if (date === null && employee && employee.expiryOfWP_SP) {
                                                return;
                                            }

                                            setEmployee({
                                                ...employee,
                                                expiryOfWP_SP: dateString,
                                            });
                                        }}
                                    />
                                )}

                                {(!id || !employee.expiryOfWP_SP) && (
                                    <DatePicker
                                        defaultValue={null}
                                        format={dateFormat}
                                        style={{ width: '100%' }}
                                        disabled={urlView !== -1 && true}
                                        id="expiryOfWP_SP"
                                        onSelect={(date) => {
                                            setEmployee({ ...employee, expiryOfWP_SP: date });
                                        }}
                                    />
                                )}
                            </Col>
                        )}
                        {/* {showNRIC && (
                            <Col md="4" className="form-group mt-2">
                                <label htmlFor="expiryOfNationId" className="title">
                                    Expiry Date for {titleNation}
                                </label>

                                {employee && employee.expiryOfNationId && employee.expiryOfNationId.length > 0 && (
                                    <>
                                        <DatePicker
                                            defaultValue={moment(employee.expiryOfNationId, 'YYYY-MM-DD')}
                                            format={'YYYY-MM-DD'}
                                            disabled={urlView !== -1 && true}
                                            style={{ width: '100%' }}
                                            id="expiryOfNationId"
                                            onChange={(date, dateString) => {
                                                if (date === null && employee && employee.expiryOfNationId) {
                                                    return;
                                                }

                                                setEmployee({
                                                    ...employee,
                                                    expiryOfNationId: dateString,
                                                });
                                            }}
                                        />
                                    </>
                                )}

                                {(!id || !employee.expiryOfNationId) && (
                                    <DatePicker
                                        defaultValue={null}
                                        format={dateFormat}
                                        style={{ width: '100%' }}
                                        disabled={urlView !== -1 && true}
                                        id="expiryOfNationId"
                                        onSelect={(date) => {
                                            setEmployee({ ...employee, expiryOfNationId: date });
                                        }}
                                    />
                                )}
                            </Col>
                        )} */}
                    </Row>
                </TabPanel>

                <TabPanel value={valueTabs} index={1}>
                    <Row>
                        <Col lg="12">
                            <div className="avatar_wrap">
                                <div className="img_box" onClick={() => openInput()}>
                                    <img src={imagePreviewUrl ? imagePreviewUrl : imageProfile} />
                                </div>
                                <p className="title_tabs">CHANGE PHOTO</p>
                                <input
                                    id="img_input"
                                    className="img_avatar"
                                    type="file"
                                    onChange={(e) => handleChangePhoto(e.target.files)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Row>
                                <Col md="12" className="form-group mt-2">
                                    <label htmlFor="employeeId" className="title">
                                        <span>*</span> ID
                                    </label>
                                    <Input
                                        placeholder="example"
                                        id="employeeId"
                                        className="ct-input"
                                        value={employee.employeesId}
                                        disabled={urlView !== -1 && true}
                                        onChange={(e) =>
                                            setEmployee({
                                                ...employee,
                                                employeesId: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col md="4" className="form-group mt-2">
                                    <label htmlFor="firstName" className="title">
                                        <span>*</span> First Name
                                    </label>
                                    <Input
                                        placeholder="example"
                                        id="firstName"
                                        className="ct-input"
                                        value={employee.firstName}
                                        disabled={urlView !== -1 && true}
                                        onChange={(e) =>
                                            setEmployee({
                                                ...employee,
                                                firstName: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col md="4" className="form-group mt-2">
                                    <label htmlFor="middleName" className="title">
                                        Middle Name
                                    </label>
                                    <Input
                                        placeholder="example"
                                        id="middleName"
                                        className="ct-input"
                                        value={employee.middleName}
                                        disabled={urlView !== -1 && true}
                                        onChange={(e) =>
                                            setEmployee({
                                                ...employee,
                                                middleName: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col md="4" className="form-group mt-2">
                                    <label htmlFor="lastName" className="title">
                                        <span>*</span> Last Name
                                    </label>
                                    <Input
                                        placeholder="example"
                                        id="lastName"
                                        className="ct-input"
                                        value={employee.lastName}
                                        disabled={urlView !== -1 && true}
                                        onChange={(e) => setEmployee({ ...employee, lastName: e.target.value })}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md="4" className="form-group mt-2">
                                    <label htmlFor="email" className="title">
                                        <span>*</span> Email
                                    </label>
                                    <Input
                                        placeholder="email"
                                        id="email"
                                        className="ct-input"
                                        value={employee.email}
                                        disabled={(urlView !== -1 || urlEdit !== -1) && true}
                                        onChange={(e) => setEmployee({ ...employee, email: e.target.value })}
                                    />
                                </Col>
                                <Col md="4" className="form-group mt-2">
                                    <label htmlFor="gender" className="title">
                                        <span>*</span> Gender
                                    </label>
                                    <div>
                                        <Radio.Group
                                            disabled={urlView !== -1 && true}
                                            onChange={changeGender}
                                            value={employee.gender === 'male' ? 1 : 2}
                                            id="gender"
                                        >
                                            <Radio value={1} id="male">
                                                <p style={{ color: '#000' }}>Male</p>
                                            </Radio>
                                            <Radio value={2} id="female">
                                                <p style={{ color: '#000' }}>Female</p>
                                            </Radio>
                                        </Radio.Group>
                                    </div>
                                </Col>
                                <Col md="4" className="form-group mt-2">
                                    <label htmlFor="birthday" className="title">
                                        <span>*</span> Day of birth
                                    </label>
                                    {employee.birthday.length > 0 && (
                                        <DatePicker
                                            defaultValue={employee.birthday && moment(employee.birthday, 'YYYY-MM-DD')}
                                            format={'YYYY-MM-DD'}
                                            disabled={urlView !== -1 && true}
                                            style={{ width: '100%' }}
                                            id="birthday"
                                            onChange={(date, dateString) => {
                                                if (date === null && employee && employee.birthday) {
                                                    return;
                                                }
                                                setEmployee({
                                                    ...employee,
                                                    birthday: dateString,
                                                });
                                            }}
                                        />
                                    )}
                                    {!id && (
                                        <DatePicker
                                            defaultValue={employee.birthday && moment(employee.birthday, 'YYYY-MM-DD')}
                                            format={'YYYY-MM-DD'}
                                            style={{ width: '100%' }}
                                            disabled={urlView !== -1 && true}
                                            id="birthday"
                                            onSelect={(date) => {
                                                setEmployee({ ...employee, birthday: date });
                                            }}
                                        />
                                    )}
                                </Col>
                            </Row>

                            <Row>
                                <Col md="4" className="form-group mt-2">
                                    <label htmlFor="nationality" className="title">
                                        <span>*</span> Nationality
                                    </label>
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        value={employee.nationality}
                                        disabled={urlView !== -1 && true}
                                        onChange={changeNation}
                                        id="nationality"
                                        placeholder="Search to Select"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {countries &&
                                            countries.map((item, idx) => (
                                                <Option key={idx} value={item.iso2}>
                                                    {item.name}
                                                </Option>
                                            ))}
                                    </Select>
                                </Col>
                                <Col md="4" className="form-group mt-2">
                                    <label htmlFor="nationId" className="title">
                                        <span>*</span> {titleNation}
                                    </label>
                                    <Input
                                        placeholder="example"
                                        id="nationId"
                                        className="ct-input"
                                        value={employee.nationId}
                                        disabled={urlView !== -1 && true}
                                        onChange={(e) => setEmployee({ ...employee, nationId: e.target.value })}
                                    />
                                </Col>

                                <Col md="4" className="form-group mt-2">
                                    <label htmlFor="type" className="title">
                                        <span>*</span> Type Employee
                                    </label>
                                    <Select
                                        style={{ width: '100%' }}
                                        value={employee.type}
                                        disabled={urlView !== -1 && true}
                                        onChange={changeType}
                                        id="type"
                                    >
                                        {EMPLOYEES_TYPE &&
                                            EMPLOYEES_TYPE.map((item, index) => (
                                                <Option key={index} value={item.value}>
                                                    {item.label}
                                                </Option>
                                            ))}
                                    </Select>
                                </Col>
                            </Row>
                            <Row>
                                {employee.type !== EMPLOYEES_TYPE_VALUE.LOCALSTAFF ? (
                                    <Col md="4" className="form-group mt-2">
                                        <label htmlFor="passport" className="title">
                                            Passport
                                        </label>
                                        <Input
                                            placeholder="example"
                                            id="passport"
                                            value={employee.passport}
                                            disabled={urlView !== -1 && true}
                                            onChange={(e) => setEmployee({ ...employee, passport: e.target.value })}
                                        />
                                    </Col>
                                ) : (
                                    <></>
                                )}
                                {employee.type !== EMPLOYEES_TYPE_VALUE.LOCALSTAFF ? (
                                    <Col md="4" className="form-group mt-2">
                                        <label htmlFor="expiryOfPassport" className="title">
                                            Expiry Date for Passport
                                        </label>
                                        {employee && employee.expiryOfPassport && (
                                            <DatePicker
                                                defaultValue={
                                                    employee && employee.expiryOfPassport
                                                        ? moment(employee.expiryOfPassport, 'YYYY-MM-DD')
                                                        : null
                                                }
                                                format={'YYYY-MM-DD'}
                                                disabled={urlView !== -1 && true}
                                                style={{ width: '100%' }}
                                                id="expiryOfPassport"
                                                onChange={(date, dateString) => {
                                                    if (date === null && employee && employee.expiryOfPassport) {
                                                        return;
                                                    }

                                                    setEmployee({
                                                        ...employee,
                                                        expiryOfPassport: dateString,
                                                    });
                                                }}
                                            />
                                        )}

                                        {(!id || !employee.expiryOfPassport) && (
                                            <DatePicker
                                                defaultValue={
                                                    employee && employee.expiryOfPassport
                                                        ? moment(
                                                              moment(new Date(employee.expiryOfPassport)).format(
                                                                  dateFormat,
                                                              ),
                                                              dateFormat,
                                                          )
                                                        : null
                                                }
                                                format={dateFormat}
                                                style={{ width: '100%' }}
                                                disabled={urlView !== -1 && true}
                                                id="expiryOfNationId"
                                                onSelect={(date) => {
                                                    setEmployee({ ...employee, expiryOfPassport: date });
                                                }}
                                            />
                                        )}
                                    </Col>
                                ) : (
                                    <></>
                                )}

                                {showWP && (
                                    <Col md="4" className="form-group mt-2">
                                        <label htmlFor="wp" className="title">
                                            WP No. / SP No.
                                        </label>
                                        <Input
                                            placeholder="example"
                                            id="wp"
                                            value={employee.wp}
                                            disabled={urlView !== -1 && true}
                                            onChange={(e) => setEmployee({ ...employee, wp: e.target.value })}
                                        />
                                    </Col>
                                )}
                                {showExpiryDateForWP_SP && (
                                    <Col md="4" className="form-group mt-2">
                                        <label htmlFor="expiryOfWP_SP" className="title">
                                            Expiry Date for WP/SP
                                        </label>
                                        {employee && employee.expiryOfWP_SP && employee.expiryOfWP_SP.length > 0 && (
                                            <DatePicker
                                                defaultValue={moment(employee.expiryOfWP_SP, 'YYYY-MM-DD')}
                                                format={'YYYY-MM-DD'}
                                                disabled={urlView !== -1 && true}
                                                style={{ width: '100%' }}
                                                id="expiryOfWP_SP"
                                                onChange={(date, dateString) => {
                                                    if (date === null && employee && employee.expiryOfWP_SP) {
                                                        return;
                                                    }

                                                    setEmployee({
                                                        ...employee,
                                                        expiryOfWP_SP: dateString,
                                                    });
                                                }}
                                            />
                                        )}

                                        {(!id || !employee.expiryOfWP_SP) && (
                                            <DatePicker
                                                defaultValue={null}
                                                format={dateFormat}
                                                style={{ width: '100%' }}
                                                disabled={urlView !== -1 && true}
                                                id="expiryOfWP_SP"
                                                onSelect={(date) => {
                                                    setEmployee({ ...employee, expiryOfWP_SP: date });
                                                }}
                                            />
                                        )}
                                    </Col>
                                )}
                                {/* {showNRIC && (
                                    <Col md="4" className="form-group mt-2">
                                        <label htmlFor="expiryOfNationId" className="title">
                                            Expiry Date for {titleNation}
                                        </label>
                                        {employee && employee.expiryOfNationId && employee.expiryOfNationId.length > 0 && (
                                            <DatePicker
                                                defaultValue={moment(employee.expiryOfNationId, 'YYYY-MM-DD')}
                                                format={'YYYY-MM-DD'}
                                                disabled={urlView !== -1 && true}
                                                style={{ width: '100%' }}
                                                id="expiryOfNationId"
                                                onChange={(date, dateString) => {
                                                    if (date === null && employee && employee.expiryOfNationId) {
                                                        return;
                                                    }

                                                    setEmployee({
                                                        ...employee,
                                                        expiryOfNationId: dateString,
                                                    });
                                                }}
                                            />
                                        )}

                                        {(!id || !employee.expiryOfNationId) && (
                                            <DatePicker
                                                defaultValue={
                                                    employee && employee.expiryOfNationId
                                                        ? moment(
                                                              moment(new Date(employee.expiryOfNationId)).format(
                                                                  dateFormat,
                                                              ),
                                                              dateFormat,
                                                          )
                                                        : null
                                                }
                                                format={dateFormat}
                                                style={{ width: '100%' }}
                                                disabled={urlView !== -1 && true}
                                                id="expiryOfNationId"
                                                onSelect={(date) => {
                                                    setEmployee({ ...employee, expiryOfNationId: date });
                                                }}
                                            />
                                        )}
                                    </Col>
                                )} */}
                                {/* </Row> */}

                                {/* <Row> */}
                                <Col md="4" className="form-group mt-2">
                                    <label htmlFor="ethnicity" className="title">
                                        Ethnicity
                                    </label>

                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        value={employee.ethnicity}
                                        disabled={urlView !== -1 && true}
                                        onChange={(value) => setEmployee({ ...employee, ethnicity: value })}
                                        id="ethnicity"
                                        placeholder="Search to Select"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {ethnicityList &&
                                            ethnicityList.map((item, index) => (
                                                <Option key={index} value={item._id}>
                                                    {item.name}
                                                </Option>
                                            ))}
                                    </Select>
                                    {/* {(ROLE.COMPANY || ROLE.COMPANYSTAFF || ROLE.USER) && (
                                        <>
                                            {urlView === -1 && (
                                                <div className="ct-new-job" onClick={() => showModal(10)}>
                                                    <AiFillPlusCircle size={25} color="#ffd96c" />
                                                </div>
                                            )}
                                        </>
                                    )}
                                    <Modal
                                        title="Add New Ethnicity"
                                        open={isModalEthnicity}
                                        onOk={handleOkEthnicity}
                                        onCancel={handleCancelEthnicity}
                                        zIndex={900}
                                    >
                                        <Row>
                                            <Col>
                                                <label htmlFor="code" className="title">
                                                    <span>*</span> Name
                                                </label>
                                                <Input
                                                    value={addEthnicity.name}
                                                    onChange={(e) => {
                                                        setAddEthnicity({ ...addEthnicity, name: e.target.value });
                                                    }}
                                                    placeholder="Enter name"
                                                />
                                            </Col>
                                        </Row>
                                    </Modal> */}
                                </Col>

                                <Col md="4" className="form-group mt-2">
                                    <label htmlFor="religion" className="title">
                                        Religion
                                    </label>

                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        value={employee.religion}
                                        disabled={urlView !== -1 && true}
                                        onChange={(value) => setEmployee({ ...employee, religion: value })}
                                        id="religion"
                                        placeholder="Search to Select"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {religionList &&
                                            religionList.map((item, index) => (
                                                <Option key={index} value={item._id}>
                                                    {item.name}
                                                </Option>
                                            ))}
                                    </Select>
                                    {(ROLE.COMPANY || ROLE.COMPANYSTAFF || ROLE.USER) && (
                                        <>
                                            {urlView === -1 && (
                                                <div className="ct-new-job" onClick={() => showModal(11)}>
                                                    <AiFillPlusCircle size={25} color="#ffd96c" />
                                                </div>
                                            )}
                                        </>
                                    )}
                                    <Modal
                                        title="Add New Religion"
                                        open={isModalReligion}
                                        onOk={handleOkReligion}
                                        onCancel={handleCancelReligion}
                                        zIndex={900}
                                    >
                                        <Row>
                                            <Col>
                                                <label htmlFor="code" className="title">
                                                    <span>*</span> Name
                                                </label>
                                                <Input
                                                    value={addReligion.name}
                                                    onChange={(e) => {
                                                        setAddReligion({ ...addReligion, name: e.target.value });
                                                    }}
                                                    placeholder="Enter name"
                                                />
                                            </Col>
                                        </Row>
                                    </Modal>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </TabPanel>

                <TabPanel value={valueTabs} index={2}>
                    <Row>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="dateJoin" className="title">
                                <span>*</span> Date Join
                            </label>
                            <DatePicker
                                id="dateJoin"
                                name="dateJoin"
                                defaultValue={
                                    employee && employee.dateJoin
                                        ? moment(moment(new Date(employee.dateJoin)).format(dateFormat), dateFormat)
                                        : null
                                }
                                format={dateFormat}
                                disabled={urlView !== -1 && true}
                                onChange={(date, dateString) =>
                                    setEmployee({ ...employee, dateJoin: new Date(date).getTime() })
                                }
                            />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="endProbation" className="title">
                                End of Probation
                            </label>
                            <DatePicker
                                id="endProbation"
                                name="endProbation"
                                defaultValue={
                                    employee && employee.endProbation
                                        ? moment(moment(new Date(employee.endProbation)).format(dateFormat), dateFormat)
                                        : null
                                }
                                format={dateFormat}
                                disabled={urlView !== -1 && true}
                                onChange={(date, dateString) =>
                                    setEmployee({ ...employee, endProbation: new Date(date).getTime() })
                                }
                            />
                        </Col>

                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="endProbation" className="title">
                                Time Clock Needed
                            </label>
                            <Switch defaultChecked={employee && employee.timeClock} onChange={onChangeSwitch} />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="resignedDate" className="title">
                                Resigned Date
                            </label>
                            <DatePicker
                                id="resignedDate"
                                name="resignedDate"
                                defaultValue={
                                    employee && employee.resignedDate
                                        ? moment(moment(new Date(employee.resignedDate)).format(dateFormat), dateFormat)
                                        : null
                                }
                                format={dateFormat}
                                disabled={urlView !== -1 && true}
                                onChange={(date, dateString) =>
                                    setEmployee({ ...employee, resignedDate: new Date(date).getTime() })
                                }
                            />
                        </Col>
                    </Row>
                    <p className="title_tabs">PLACEMENT</p>
                    <Row>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="effectiveDate" className="title">
                                Effective Date
                            </label>
                            <DatePicker
                                id="effectiveDate"
                                name="effectiveDate"
                                defaultValue={
                                    placement && placement.effectiveDate
                                        ? moment(
                                              moment(new Date(placement.effectiveDate)).format(dateFormat),
                                              dateFormat,
                                          )
                                        : null
                                }
                                format={dateFormat}
                                disabled={urlView !== -1 && true}
                                onChange={(date, dateString) =>
                                    setPlacement({ ...placement, effectiveDate: new Date(date).getTime() })
                                }
                            />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="jobPosition" className="title">
                                <span>*</span> Job Position
                            </label>

                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                disabled={urlView !== -1 && true}
                                onChange={changeJob}
                                id="jobPosition"
                                value={employee.jobPosition}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {jobPositions &&
                                    jobPositions.map((item, index) => (
                                        <Option key={index} value={item.jobPositionName}>
                                            {item.jobPositionName}
                                        </Option>
                                    ))}
                            </Select>

                            {(ROLE.COMPANY || ROLE.COMPANYSTAFF) && (
                                <>
                                    {urlView === -1 && (
                                        <div className="ct-new-job" onClick={() => showModal(2)}>
                                            <AiFillPlusCircle size={25} color="#ffd96c" />
                                        </div>
                                    )}
                                </>
                            )}
                            <Modal
                                title="Add Job Position"
                                open={isModalPosition}
                                onOk={handleOkPosition}
                                onCancel={handleCancelPosition}
                                zIndex={900}
                            >
                                <Row>
                                    <Col>
                                        <label htmlFor="code" className="title">
                                            <span>*</span> Code
                                        </label>
                                        <Input
                                            value={addJobPosition.code}
                                            onChange={(e) => {
                                                setAddJobPosition({ ...addJobPosition, code: e.target.value });
                                            }}
                                            placeholder="Enter code"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <label htmlFor="title" className="title">
                                            <span>*</span> Title
                                        </label>
                                        <Input
                                            value={addJobPosition.jobPositionName}
                                            onChange={(e) => {
                                                setAddJobPosition({
                                                    ...addJobPosition,
                                                    jobPositionName: e.target.value,
                                                });
                                            }}
                                            placeholder="Enter title"
                                        />
                                    </Col>
                                </Row>
                            </Modal>
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="lineManager" className="title">
                                Line Manager
                            </label>

                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                disabled={urlView !== -1 && true}
                                onChange={(value) => setPlacement({ ...placement, lineManager: value })}
                                id="lineManager"
                                value={placement && placement.lineManager}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {listEmployees.map((item, index) => (
                                    <Option key={index} value={item._id}>
                                        {item.firstName}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="department" className="title">
                                Department
                            </label>

                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                disabled={urlView !== -1 && true}
                                onChange={changeDepartment}
                                id="department"
                                value={employee && employee.department}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {departments &&
                                    departments.map((item, index) => (
                                        <Option key={index} value={item._id}>
                                            {item.departmentName}
                                        </Option>
                                    ))}
                            </Select>
                            {(ROLE.COMPANY || ROLE.COMPANYSTAFF) && (
                                <>
                                    {urlView === -1 && (
                                        <div className="ct-new-job" onClick={() => showModal(3)}>
                                            <AiFillPlusCircle size={25} color="#ffd96c" />
                                        </div>
                                    )}
                                </>
                            )}
                            <Modal
                                title="Add Department"
                                open={isModalDepartment}
                                onOk={handleOkDepartment}
                                onCancel={handleCancelDepartment}
                                zIndex={900}
                            >
                                <Form layout="vertical" name="form_in_modal" initialValues={{ modifier: 'public' }}>
                                    <Form.Item
                                        name="title"
                                        label="Department name"
                                        rules={[{ required: true, message: 'Please input the name of collection!' }]}
                                    >
                                        <Input
                                            type="text"
                                            placeholder="example"
                                            id="title"
                                            value={addDepartment.departmentName}
                                            onChange={(e) => {
                                                setAddDepartment({ ...addDepartment, departmentName: e.target.value });
                                            }}
                                        />
                                    </Form.Item>
                                    <Row lg="12" md="8" sm="6">
                                        <Col lg="12">
                                            <Form.Item
                                                name="departmentHead"
                                                label="Department head"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please choose department head!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    id="departmentHead"
                                                    style={{ width: '100%', zIndex: 999 }}
                                                    value={
                                                        addDepartment.departmentHead ? addDepartment.departmentHead : ''
                                                    }
                                                    onChange={(e) =>
                                                        setAddDepartment({ ...addDepartment, departmentHead: e })
                                                    }
                                                >
                                                    {employees.map((employee, index) => (
                                                        <Select.Option key={index} value={employee._id}>
                                                            {employee.firstName}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Modal>
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="branch" className="title">
                                Branch
                            </label>

                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                disabled={urlView !== -1 && true}
                                onChange={(value) => setPlacement({ ...placement, branch: value })}
                                id="department"
                                value={placement && placement.branch}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {branches &&
                                    branches.map((item, index) => (
                                        <Option key={index} value={item._id}>
                                            {item.name}
                                        </Option>
                                    ))}
                            </Select>
                            {(ROLE.COMPANY || ROLE.COMPANYSTAFF) && (
                                <>
                                    {urlView === -1 && (
                                        <div className="ct-new-job" onClick={() => showModal(4)}>
                                            <AiFillPlusCircle size={25} color="#ffd96c" />
                                        </div>
                                    )}
                                </>
                            )}
                            <Modal
                                title="Add New Branch"
                                open={isModalBranch}
                                onOk={handleOkBranch}
                                onCancel={handleCancelBranch}
                                zIndex={900}
                            >
                                <Row>
                                    <Col>
                                        <label htmlFor="code" className="title">
                                            <span>*</span> Code
                                        </label>
                                        <Input
                                            value={addBranch.code}
                                            onChange={(e) => {
                                                setAddBranch({ ...addBranch, code: e.target.value });
                                            }}
                                            placeholder="Enter code"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <label htmlFor="title" className="title">
                                            <span>*</span> Name
                                        </label>
                                        <Input
                                            value={addBranch.name}
                                            onChange={(e) => {
                                                setAddBranch({
                                                    ...addBranch,
                                                    name: e.target.value,
                                                });
                                            }}
                                            placeholder="Enter name"
                                        />
                                    </Col>
                                </Row>
                            </Modal>
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="level" className="title">
                                Level
                            </label>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                value={placement && placement.level}
                                disabled={urlView !== -1 && true}
                                onChange={(value) => setPlacement({ ...placement, level: value })}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {levelList &&
                                    levelList.map((item, index) => (
                                        <Option key={index} value={item._id}>
                                            {item.name}
                                        </Option>
                                    ))}
                            </Select>
                            {(ROLE.COMPANY || ROLE.COMPANYSTAFF) && (
                                <>
                                    {urlView === -1 && (
                                        <div className="ct-new-job" onClick={() => showModal(5)}>
                                            <AiFillPlusCircle size={25} color="#ffd96c" />
                                        </div>
                                    )}
                                </>
                            )}
                            <Modal
                                title="Add New Level"
                                open={isModalLevel}
                                onOk={handleOkLevel}
                                onCancel={handleCancelLevel}
                                zIndex={900}
                            >
                                <Row>
                                    <Col>
                                        <label htmlFor="code" className="title">
                                            <span>*</span> Code
                                        </label>
                                        <Input
                                            value={addLevel.code}
                                            onChange={(e) => {
                                                setAddLevel({ ...addLevel, code: e.target.value });
                                            }}
                                            placeholder="Enter code"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <label htmlFor="title" className="title">
                                            <span>*</span> Name
                                        </label>
                                        <Input
                                            value={addLevel.name}
                                            onChange={(e) => {
                                                setAddLevel({
                                                    ...addLevel,
                                                    name: e.target.value,
                                                });
                                            }}
                                            placeholder="Enter name"
                                        />
                                    </Col>
                                </Row>
                            </Modal>
                        </Col>
                    </Row>
                    <p className="title_tabs">EMPLOYMENT TERM</p>
                    <Row>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="effectiveDate" className="title">
                                Effective Date
                            </label>
                            <DatePicker
                                id="effectiveDate"
                                name="effectiveDate"
                                defaultValue={
                                    employmentTerm && employmentTerm.effectiveDate
                                        ? moment(
                                              moment(new Date(employmentTerm.effectiveDate)).format(dateFormat),
                                              dateFormat,
                                          )
                                        : null
                                }
                                format={dateFormat}
                                disabled={urlView !== -1 && true}
                                onChange={(date, dateString) =>
                                    setEmploymentTerm({
                                        ...employmentTerm,
                                        effectiveDate: new Date(date).getTime(),
                                    })
                                }
                            />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="jobType" className="title">
                                Job Type
                            </label>
                            <Select
                                style={{ width: '100%' }}
                                value={employmentTerm && employmentTerm.jobType}
                                disabled={urlView !== -1 && true}
                                onChange={(value) => setEmploymentTerm({ ...employmentTerm, jobType: value })}
                            >
                                <Option value="permanent">Permanent</Option>
                                <Option value="contract">Contract</Option>
                                <Option value="parttime">Part Time</Option>
                                <Option value="internship">Internship</Option>
                            </Select>
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="description" className="title">
                                Description
                            </label>
                            <Select
                                style={{ width: '100%' }}
                                value={employmentTerm && employmentTerm.description}
                                disabled={urlView !== -1 && true}
                                onChange={(value) => setEmploymentTerm({ ...employmentTerm, description: value })}
                            >
                                <Option value="confirm">Confirmed</Option>
                                <Option value="probation">Probation</Option>
                                <Option value="resigned">Resigned</Option>
                                <Option value="dismissed">Dismissed</Option>
                                <Option value="contract">Contract </Option>
                                <Option value="retired">Retired</Option>
                                <Option value="retrened">Retrened</Option>
                                <Option value="deceased">Deceased</Option>
                            </Select>
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="leaveWordflow" className="title">
                                Leave Workflow
                            </label>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                value={employmentTerm && employmentTerm.leaveWordflow}
                                disabled={urlView !== -1 && true}
                                onChange={(value) => setEmploymentTerm({ ...employmentTerm, leaveWordflow: value })}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {leaveWordflowList &&
                                    leaveWordflowList.map((item, index) => (
                                        <Option key={index} value={item._id}>
                                            {item.description}
                                        </Option>
                                    ))}
                            </Select>
                            {(ROLE.COMPANY || ROLE.COMPANYSTAFF) && (
                                <>
                                    {urlView === -1 && (
                                        <div className="ct-new-job" onClick={() => showModal(7)}>
                                            <AiFillPlusCircle size={25} color="#ffd96c" />
                                        </div>
                                    )}
                                </>
                            )}
                            {/* <Modal
                                title="Add New Leave WordFlow"
                                open={isModalLeaveWordFlow}
                                onOk={handleOkLeaveWordFlow}
                                onCancel={handleCancelLeaveWordFlow}
                                zIndex={900}
                            >
                                <Row>
                                    <Col>
                                        <label htmlFor="code" className="title">
                                            <span>*</span> Code
                                        </label>
                                        <Input
                                            value={addLeaveWordFlow.code}
                                            onChange={(e) => {
                                                setAddLeaveWordFlow({ ...addLeaveWordFlow, code: e.target.value });
                                            }}
                                            placeholder="Enter code"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <label htmlFor="title" className="title">
                                            <span>*</span> Name
                                        </label>
                                        <Input
                                            value={addLeaveWordFlow.name}
                                            onChange={(e) => {
                                                setAddLeaveWordFlow({
                                                    ...addLeaveWordFlow,
                                                    name: e.target.value,
                                                });
                                            }}
                                            placeholder="Enter name"
                                        />
                                    </Col>
                                </Row>
                            </Modal> */}
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="workDay" className="title">
                                Work Day
                            </label>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                value={employmentTerm && employmentTerm.workDay}
                                disabled={urlView !== -1 && true}
                                onChange={(value) => setEmploymentTerm({ ...employmentTerm, workDay: value })}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {/* {workDayList &&
                                    workDayList.map((item, index) => (
                                        <Option key={index} value={item._id}>
                                            {item.name}
                                        </Option>
                                    ))} */}
                                {WORKING_DAYS.map((item) => (
                                    <Option value={item} label={item}>
                                        {item}
                                    </Option>
                                ))}
                            </Select>
                            {/* {(ROLE.COMPANY || ROLE.COMPANYSTAFF) && (
                                <>
                                    {urlView === -1 && (
                                        <div className="ct-new-job" onClick={() => showModal(8)}>
                                            <AiFillPlusCircle size={25} color="#ffd96c" />
                                        </div>
                                    )}
                                </>
                            )} */}
                            <Modal
                                title="Add New WorkDay"
                                open={isModalWorkDay}
                                onOk={handleOkWorkDay}
                                onCancel={handleCancelWorkDay}
                                zIndex={900}
                            >
                                <Row>
                                    <Col>
                                        <label htmlFor="code" className="title">
                                            <span>*</span> Code
                                        </label>
                                        <Input
                                            value={addWorkDay.code}
                                            onChange={(e) => {
                                                setAddWorkDay({ ...addWorkDay, code: e.target.value });
                                            }}
                                            placeholder="Enter code"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <label htmlFor="title" className="title">
                                            <span>*</span> Name
                                        </label>
                                        <Input
                                            value={addWorkDay.name}
                                            onChange={(e) => {
                                                setAddWorkDay({
                                                    ...addWorkDay,
                                                    name: e.target.value,
                                                });
                                            }}
                                            placeholder="Enter name"
                                        />
                                    </Col>
                                </Row>
                            </Modal>
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="holiday" className="title">
                                Holiday
                            </label>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                value={employmentTerm && employmentTerm.holiday}
                                disabled={urlView !== -1 && true}
                                onChange={(value) => setEmploymentTerm({ ...employmentTerm, holiday: value })}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {holidayList &&
                                    holidayList.map((item, index) => (
                                        <Option key={index} value={item._id}>
                                            {item.title}
                                        </Option>
                                    ))}
                            </Select>
                            {(ROLE.COMPANY || ROLE.COMPANYSTAFF) && (
                                <>
                                    {urlView === -1 && (
                                        <div className="ct-new-job" onClick={() => showModal(9)}>
                                            <AiFillPlusCircle size={25} color="#ffd96c" />
                                        </div>
                                    )}
                                </>
                            )}
                            <Modal
                                title="Add public holiday"
                                open={isModalHoliday}
                                onOk={handleOkAddHoliday}
                                zIndex={999}
                                confirmLoading={confirmLoading}
                                onCancel={handleCancelAddHoliday}
                            >
                                <div className="form-add-holiday">
                                    <div>
                                        <label>Title</label>
                                        <Input onChange={(e) => setTitleHoliday(e.target.value)} />
                                    </div>
                                    <div>
                                        <label>Start day</label>
                                        <DatePicker onChange={onChangeStartDay} />
                                    </div>
                                    <div>
                                        <label>End day</label>
                                        <DatePicker onChange={onChangeEndDay} />
                                    </div>
                                </div>
                            </Modal>
                            {/* <Modal
                                title="Add New Holiday"
                                open={isModalHoliday}
                                onOk={handleOkHoliday}
                                onCancel={handleCancelHoliday}
                                zIndex={900}
                            >
                                <Row>
                                    <Col>
                                        <label htmlFor="code" className="title">
                                            <span>*</span> Code
                                        </label>
                                        <Input
                                            value={addHoliday.code}
                                            onChange={(e) => {
                                                setAddHoliday({ ...addHoliday, code: e.target.value });
                                            }}
                                            placeholder="Enter code"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <label htmlFor="title" className="title">
                                            <span>*</span> Name
                                        </label>
                                        <Input
                                            value={addHoliday.name}
                                            onChange={(e) => {
                                                setAddHoliday({
                                                    ...addHoliday,
                                                    name: e.target.value,
                                                });
                                            }}
                                            placeholder="Enter name"
                                        />
                                    </Col>
                                </Row>
                            </Modal> */}
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="holiday" className="title">
                                Term Start
                            </label>
                            <DatePicker
                                defaultValue={
                                    employmentTerm && employmentTerm.termStart
                                        ? moment(
                                              moment(new Date(employmentTerm.termStart)).format(dateFormat),
                                              dateFormat,
                                          )
                                        : null
                                }
                                format={dateFormat}
                                disabled={employmentTerm && employmentTerm.jobType === 'permanent' ? true : false}
                                onChange={(date, dateString) =>
                                    setEmploymentTerm({ ...employmentTerm, termStart: new Date(date).getTime() })
                                }
                                style={{ width: '100%' }}
                            />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="holiday" className="title">
                                Term End
                            </label>
                            <DatePicker
                                defaultValue={
                                    employmentTerm && employmentTerm.termEnd
                                        ? moment(
                                              moment(new Date(employmentTerm.termEnd)).format(dateFormat),
                                              dateFormat,
                                          )
                                        : null
                                }
                                format={dateFormat}
                                disabled={employmentTerm && employmentTerm.jobType === 'permanent' ? true : false}
                                onChange={(date, dateString) =>
                                    setEmploymentTerm({ ...employmentTerm, termEnd: new Date(date).getTime() })
                                }
                                style={{ width: '100%' }}
                            />
                        </Col>
                    </Row>
                </TabPanel>

                <TabPanel value={valueTabs} index={3}>
                    <p className="title_tabs">SALARY</p>

                    <Row>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="effectiveDate" className="title">
                                Effective Date
                            </label>
                            <DatePicker
                                id="effectiveDate"
                                name="effectiveDate"
                                defaultValue={
                                    salary && salary.effectiveDate
                                        ? moment(moment(new Date(salary.effectiveDate)).format(dateFormat), dateFormat)
                                        : null
                                }
                                format={dateFormat}
                                disabled={urlView !== -1 && true}
                                onChange={(date, dateString) =>
                                    setSalary({ ...salary, effectiveDate: new Date(date).getTime() })
                                }
                            />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="basicsalary" className="title">
                                <span>*</span> Basic Salary
                            </label>
                            <Input
                                type="number"
                                id="basicsalary"
                                className="ct-input"
                                value={salary && salary.basicSalary}
                                disabled={urlView !== -1 && true}
                                onChange={(e) =>
                                    setSalary({
                                        ...salary,
                                        basicSalary: e.target.value,
                                    })
                                }
                            />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="currency" className="title">
                                <span>*</span> Currency
                            </label>

                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                value={salary && salary.currency}
                                // value="SGD"
                                disabled={urlView !== -1 && true}
                                onChange={(value) => setSalary({ ...salary, currency: value })}
                                id="currency"
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {currency.length > 0 &&
                                    currency.map((item, idx) => {
                                        return (
                                            <Option key={idx} value={item}>
                                                {item}
                                            </Option>
                                        );
                                    })}
                                {/* <Option key={1} value={'SGD'}>
                                    SGD
                                </Option> */}
                            </Select>
                        </Col>

                        <Col md="12">
                            <div className="salary_box">
                                <BsQuestionSquareFill size={20} color="#cddc39" />{' '}
                                <span>For Hourly rate, you may use Earning.</span>
                            </div>
                        </Col>

                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="reviewDate" className="title">
                                Next Review Date
                            </label>
                            <DatePicker
                                id="reviewDate"
                                name="reviewDate"
                                defaultValue={
                                    salary && salary.reviewDate
                                        ? moment(moment(new Date(salary.reviewDate)).format(dateFormat), dateFormat)
                                        : null
                                }
                                format={dateFormat}
                                disabled={urlView !== -1 && true}
                                onChange={(date, dateString) =>
                                    setSalary({ ...salary, reviewDate: new Date(date).getTime() })
                                }
                            />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="earning" className="title">
                                Earning
                            </label>
                            <Input
                                id="earning"
                                type="number"
                                className="ct-input"
                                value={salary && salary.earning}
                                disabled={urlView !== -1 && true}
                                onChange={(e) =>
                                    +e.target.value >= 0
                                        ? setSalary({
                                              ...salary,
                                              earning: e.target.value,
                                          })
                                        : toast.warning('Earning value > 0')
                                }
                            />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="deduction" className="title">
                                Deduction
                            </label>
                            <Input
                                id="deduction"
                                className="ct-input"
                                value={salary && salary.deduction}
                                disabled={urlView !== -1 && true}
                                onChange={(e) =>
                                    +e.target.value >= 0
                                        ? setSalary({
                                              ...salary,
                                              deduction: e.target.value,
                                          })
                                        : toast.warning('Deduction value > 0')
                                }
                            />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="bonus" className="title">
                                Bonus
                            </label>
                            <Input
                                id="bonus"
                                type="number"
                                className="ct-input"
                                value={salary && salary.bonus}
                                disabled={urlView !== -1 && true}
                                onChange={(e) =>
                                    +e.target.value >= 0
                                        ? setSalary({
                                              ...salary,
                                              bonus: e.target.value,
                                          })
                                        : toast.warning('Bonus value > 0')
                                }
                            />
                        </Col>
                        {employee.type === EMPLOYEES_TYPE_VALUE.WORKPERMIT ||
                        employee.type === EMPLOYEES_TYPE_VALUE.SPASS ? (
                            <Col md="4" className="form-group mt-2">
                                <label htmlFor="MealAllowance" className="title">
                                    Meal Allowance
                                </label>
                                <Input
                                    type="number"
                                    id="MealAllowance"
                                    className="ct-input"
                                    value={salary && salary.mealAllowance}
                                    disabled={urlView !== -1 && true}
                                    onChange={(e) =>
                                        +e.target.value >= 0
                                            ? setSalary({
                                                  ...salary,
                                                  mealAllowance: +e.target.value,
                                              })
                                            : toast.warning('Meal Allowance value > 0')
                                    }
                                />
                            </Col>
                        ) : (
                            <></>
                        )}
                        {employee.type === EMPLOYEES_TYPE_VALUE.SPASS ? (
                            <Col md="4" className="form-group mt-2">
                                <label htmlFor="AccomodationAllowance" className="title">
                                    Accomodation Allowance
                                </label>
                                <Input
                                    type="number"
                                    id="AccomodationAllowance"
                                    className="ct-input"
                                    value={salary && salary.accomodationAllowance}
                                    disabled={urlView !== -1 && true}
                                    onChange={(e) =>
                                        +e.target.value >= 0
                                            ? setSalary({
                                                  ...salary,
                                                  accomodationAllowance: +e.target.value,
                                              })
                                            : toast.warning('Accomodation Allowance value > 0')
                                    }
                                />
                            </Col>
                        ) : (
                            <></>
                        )}
                        {employee.type === EMPLOYEES_TYPE_VALUE.LOCALSTAFF ||
                        employee.type === EMPLOYEES_TYPE_VALUE.SPASS ? (
                            <Col md="4" className="form-group mt-2">
                                <label htmlFor="TransportAllowance" className="title">
                                    Transport Allowance
                                </label>
                                <Input
                                    type="number"
                                    id="TransportAllowance"
                                    className="ct-input"
                                    value={salary && salary.transportAllowance}
                                    disabled={urlView !== -1 && true}
                                    onChange={(e) =>
                                        +e.target.value >= 0
                                            ? setSalary({
                                                  ...salary,
                                                  transportAllowance: +e.target.value,
                                              })
                                            : toast.warning('Transport Allowance value > 0')
                                    }
                                />
                            </Col>
                        ) : (
                            <></>
                        )}

                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="statutoryContribution" className="title">
                                Statutory Contribution
                            </label>
                            <Input
                                id="statutoryContribution"
                                className="ct-input"
                                type="number"
                                value={salary && salary.statutoryContribution}
                                disabled={urlView !== -1 && true}
                                onChange={(e) =>
                                    +e.target.value >= 0
                                        ? setSalary({
                                              ...salary,
                                              statutoryContribution: +e.target.value,
                                          })
                                        : toast.warning('Statutory Contribution value > 0')
                                }
                            />
                        </Col>
                    </Row>

                    <p className="title_tabs">PAYMENT</p>
                    <Row>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="bank" className="title">
                                Bank
                            </label>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                value={payment && payment.bank}
                                disabled={urlView !== -1 && true}
                                onChange={(value) => setPayment({ ...payment, bank: value })}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {bankList &&
                                    bankList.map((item, index) => (
                                        <Option key={index} value={item._id}>
                                            {item.name}
                                        </Option>
                                    ))}
                            </Select>
                            {(ROLE.COMPANY || ROLE.COMPANYSTAFF || ROLE.USER) && (
                                <>
                                    {urlView === -1 && (
                                        <div className="ct-new-job" onClick={() => showModal(6)}>
                                            <AiFillPlusCircle size={25} color="#ffd96c" />
                                        </div>
                                    )}
                                </>
                            )}
                            <Modal
                                title="Add new bank"
                                open={isModalBank}
                                onOk={handleOkBank}
                                onCancel={handleCancelBank}
                                zIndex={900}
                            >
                                <Row>
                                    <Col>
                                        <label htmlFor="code" className="title">
                                            <span>*</span> Code
                                        </label>
                                        <Input
                                            value={addBank.code}
                                            onChange={(e) => {
                                                setAddBank({ ...addBank, code: e.target.value });
                                            }}
                                            placeholder="Enter code"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col>
                                        <label htmlFor="title" className="title">
                                            <span>*</span> Title
                                        </label>
                                        <Input
                                            value={addBank.name}
                                            onChange={(e) => {
                                                setAddBank({
                                                    ...addBank,
                                                    name: e.target.value,
                                                });
                                            }}
                                            placeholder="Enter title"
                                        />
                                    </Col>
                                </Row>
                            </Modal>
                        </Col>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="bankAccount" className="title">
                                IBAN / Bank Account
                            </label>
                            <Input
                                placeholder="IBAN / Bank Account"
                                id="bankAccount"
                                disabled={urlView !== -1 && true}
                                value={payment && payment.bankAccount}
                                onChange={(e) =>
                                    setPayment({
                                        ...payment,
                                        bankAccount: e.target.value,
                                    })
                                }
                            />
                        </Col>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="payPeriod" className="title">
                                Pay Period
                            </label>
                            <Select
                                style={{ width: '100%' }}
                                disabled={urlView !== -1 && true}
                                value={payment && payment.payPeriod}
                                onChange={(value) => setPayment({ ...payment, payPeriod: value })}
                            >
                                {EMPLOYEE_PAYMENT_PAY.map((item) => (
                                    <Option key={item.id} value={item.value}>
                                        {item.label}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="method" className="title">
                                Method
                            </label>
                            <Select
                                style={{ width: '100%' }}
                                disabled={urlView !== -1 && true}
                                value={payment && payment.method}
                                onChange={(value) => setPayment({ ...payment, method: value })}
                            >
                                {EMPLOYEE_PAYMENT_METHOD.map((item) => (
                                    <Option key={item.id} value={item.value}>
                                        {item.label}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                </TabPanel>

                <TabPanel value={valueTabs} index={4}>
                    <p className="title_tabs">SPOUSE</p>
                    <Row>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="maritalStatus" className="title">
                                Married Status
                            </label>
                            <Select
                                style={{ width: '100%' }}
                                disabled={urlView !== -1 && true}
                                value={familys && familys.maritalStatus}
                                onChange={(value) => setFamilys({ ...familys, maritalStatus: value })}
                            >
                                <Option value="single">Single</Option>
                                <Option value="married">Married</Option>
                                <Option value="divorced">Divorced</Option>
                                <Option value="wedowed">Widowed</Option>
                            </Select>
                        </Col>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="spouseworking" className="title">
                                Spouse Working
                            </label>
                            <Switch
                                disabled={familys && familys.maritalStatus === 'married' ? false : true}
                                defaultChecked={familys && familys.spouseWorking}
                                onChange={(value) => setFamilys({ ...familys, spouseWorking: value })}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="firstName" className="title">
                                First Name
                            </label>
                            <Input
                                id="firstName"
                                disabled={familys && familys.maritalStatus === 'married' ? false : true}
                                value={familys && familys.firstName}
                                // disabled={urlView !== -1 && true}
                                onChange={(e) => setFamilys({ ...familys, firstName: e.target.value })}
                            />
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="middleName" className="title">
                                Middle Name
                            </label>
                            <Input
                                id="middleName"
                                disabled={familys && familys.maritalStatus === 'married' ? false : true}
                                value={familys && familys.middleName}
                                onChange={(e) => setFamilys({ ...familys, middleName: e.target.value })}
                            />
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="lastName" className="title">
                                Last Name
                            </label>
                            <Input
                                id="lastName"
                                disabled={familys && familys.maritalStatus === 'married' ? false : true}
                                value={familys && familys.lastName}
                                onChange={(e) => setFamilys({ ...familys, lastName: e.target.value })}
                            />
                        </Col>

                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="birthday" className="title">
                                Birthday
                            </label>
                            <DatePicker
                                id="birthday"
                                name="birthday"
                                disabled={familys && familys.maritalStatus === 'married' ? false : true}
                                defaultValue={
                                    familys && familys.birthday
                                        ? moment(moment(new Date(familys.birthday)).format(dateFormat), dateFormat)
                                        : null
                                }
                                format={dateFormat}
                                onChange={(date, dateString) =>
                                    setFamilys({ ...familys, birthday: new Date(date).getTime() })
                                }
                            />
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="nationality" className="title">
                                Nationality
                            </label>
                            <Select
                                style={{ width: '100%' }}
                                disabled={familys && familys.maritalStatus === 'married' ? false : true}
                                value={familys && familys.nationality}
                                onChange={(value) => setFamilys({ ...familys, nationality: value })}
                            >
                                {countries &&
                                    countries.map((item, idx) => (
                                        <Option key={idx} value={item.iso2}>
                                            {item.name}
                                        </Option>
                                    ))}
                            </Select>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="nationId" className="title">
                                National ID
                            </label>
                            <Input
                                id="nationId"
                                value={familys && familys.nationId}
                                disabled={familys && familys.maritalStatus === 'married' ? false : true}
                                onChange={(e) => setFamilys({ ...familys, nationId: e.target.value })}
                            />
                        </Col>

                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="passport" className="title">
                                Passport
                            </label>
                            <Input
                                id="passport"
                                value={familys && familys.passport}
                                disabled={familys && familys.maritalStatus === 'married' ? false : true}
                                onChange={(e) => setFamilys({ ...familys, passport: e.target.value })}
                            />
                        </Col>

                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="ethnicity" className="title">
                                Ethnicity
                            </label>
                            <Select
                                style={{ width: '100%' }}
                                disabled={familys && familys.maritalStatus === 'married' ? false : true}
                                onChange={(value) => setFamilys({ ...familys, ethnicity: value })}
                            >
                                <Option value="ethnicity1">Ethnicity 1</Option>
                                <Option value="ethnicity2">Ethnicity 2</Option>
                                <Option value="ethnicity3">Ethnicity 3</Option>
                                <Option value="ethnicity4">Ethnicity 4</Option>
                            </Select>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="religion" className="title">
                                Religion
                            </label>
                            <Select
                                style={{ width: '100%' }}
                                disabled={familys && familys.maritalStatus === 'married' ? false : true}
                                onChange={(value) => setFamilys({ ...familys, religion: value })}
                            >
                                <Option value="religion1">Religion 1</Option>
                                <Option value="religion2">Religion 2</Option>
                                <Option value="religion3">Religion 3</Option>
                                <Option value="religion4">Religion 4</Option>
                            </Select>
                        </Col>
                    </Row>
                    <p className="title_tabs">CHILDREN</p>
                    <Row>
                        <Col lg="12" className="form-group mt-2">
                            <label htmlFor="children" className="title">
                                Children
                            </label>
                            <Input
                                id="children"
                                value={familys && familys.children}
                                disabled={urlView !== -1 && true}
                                onChange={(e) => setFamilys({ ...familys, children: e.target.value })}
                            />
                        </Col>
                    </Row>
                </TabPanel>

                <TabPanel value={valueTabs} index={5}>
                    <p className="title_tabs">WEB</p>
                    <Row>
                        <Col md="6" className="form-group mt-2">
                            <label htmlFor="email" className="title">
                                Email
                            </label>
                            <Input
                                id="email"
                                value={phone && phone.email}
                                disabled={urlView !== -1 && true}
                                onChange={(e) => setPhone({ ...phone, email: e.target.value })}
                            />
                        </Col>
                        {/* <Col md="6" className="form-group mt-2">
                            <label htmlFor="blog" className="title">
                                Blog
                            </label>
                            <Input
                                id="blog"
                                value={phone && phone.blog}
                                disabled={urlView !== -1 && true}
                                onChange={(e) => setPhone({ ...phone, blog: e.target.value })}
                            />
                        </Col> */}
                    </Row>
                    <p className="title_tabs">PHONE</p>
                    <Row>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="officePhone" className="title">
                                Office Phone
                            </label>
                            <Input
                                id="officePhone"
                                value={phone && phone.officePhone}
                                disabled={urlView !== -1 && true}
                                onChange={(e) => setPhone({ ...phone, officePhone: e.target.value })}
                            />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="mobilePhone" className="title">
                                Mobile phone
                            </label>

                            <Input
                                id="mobilePhone"
                                value={phone && phone.mobilePhone}
                                disabled={urlView !== -1 && true}
                                onChange={(e) => setPhone({ ...phone, mobilePhone: e.target.value })}
                            />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="housePhone" className="title">
                                House phone
                            </label>

                            <Input
                                id="housePhone"
                                value={phone && phone.housePhone}
                                disabled={urlView !== -1 && true}
                                onChange={(e) => setPhone({ ...phone, housePhone: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <p className="title_tabs">ADDRESS</p>
                    <Row>
                        <Col md="6" className="form-group mt-2">
                            <label htmlFor="address1" className="title">
                                Address 1
                            </label>
                            <Input
                                id="address1"
                                value={address && address.address1}
                                disabled={urlView !== -1 && true}
                                onChange={(e) => setAddress({ ...address, address1: e.target.value })}
                            />
                        </Col>
                        <Col md="6" className="form-group mt-2">
                            <label htmlFor="address2" className="title">
                                Address 2
                            </label>
                            <Input
                                id="address2"
                                value={address && address.address2}
                                disabled={urlView !== -1 && true}
                                onChange={(e) => setAddress({ ...address, address2: e.target.value })}
                            />
                        </Col>
                        <Col md="6" className="form-group mt-2">
                            <label htmlFor="city" className="title">
                                City
                            </label>
                            <Input
                                id="city"
                                value={address && address.city}
                                disabled={urlView !== -1 && true}
                                onChange={(e) => setAddress({ ...address, city: e.target.value })}
                            />
                        </Col>
                        <Col md="6" className="form-group mt-2">
                            <label htmlFor="postCode" className="title">
                                Postcode
                            </label>
                            <Input
                                id="postCode"
                                value={address && address.postCode}
                                disabled={urlView !== -1 && true}
                                onChange={(e) => setAddress({ ...address, postCode: e.target.value })}
                            />
                        </Col>
                        <Col md="6" className="form-group mt-2">
                            <label htmlFor="state" className="title">
                                State
                            </label>
                            {/* <Input
                                id="state"
                                value={address && address.state}
                                disabled={urlView !== -1 && true}
                                onChange={(e) => setAddress({ ...address, state: e.target.value })}
                            /> */}
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                value={address && address.state}
                                defaultValue={address && address.state}
                                onChange={(value) => setAddress({ ...address, state: value })}
                                id="state"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {state &&
                                    state.map((item, idx) => (
                                        <Option key={item._id} value={item.iso2}>
                                            {item.name}
                                        </Option>
                                    ))}
                            </Select>
                        </Col>
                        <Col md="6" className="form-group mt-2">
                            <label htmlFor="country" className="title">
                                Country
                            </label>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                id="country"
                                value={address && address.country}
                                disabled={urlView !== -1 && true}
                                onChange={(value) => setAddress({ ...address, country: value })}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {countries.map((item, index) => {
                                    return (
                                        <Option key={index} value={item.iso2}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Col>
                    </Row>
                    <p className="title_tabs">IN CASE OF EMERGENCY</p>
                    <Row>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="firstName" className="title">
                                First Name
                            </label>
                            <Input
                                id="firstName"
                                value={emergency && emergency.firstName}
                                disabled={urlView !== -1 && true}
                                onChange={(e) => setEmergency({ ...emergency, firstName: e.target.value })}
                            />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="middleName" className="title">
                                Middle Name
                            </label>
                            <Input
                                id="middleName"
                                value={emergency && emergency.middleName}
                                disabled={urlView !== -1 && true}
                                onChange={(e) => setEmergency({ ...emergency, middleName: e.target.value })}
                            />
                        </Col>
                        <Col md="4" className="form-group mt-2">
                            <label htmlFor="lastName" className="title">
                                Last Name
                            </label>
                            <Input
                                id="lastName"
                                value={emergency && emergency.lastName}
                                disabled={urlView !== -1 && true}
                                onChange={(e) => setEmergency({ ...emergency, lastName: e.target.value })}
                            />
                        </Col>
                        <Col md="6" className="form-group mt-2">
                            <label htmlFor="relationship" className="title">
                                Relationship
                            </label>
                            <Input
                                id="relationship"
                                value={emergency && emergency.relationship}
                                disabled={urlView !== -1 && true}
                                onChange={(e) => setEmergency({ ...emergency, relationship: e.target.value })}
                            />
                        </Col>
                        <Col md="6" className="form-group mt-2">
                            <label htmlFor="mobilePhone" className="title">
                                Mobile Phone
                            </label>
                            <Input
                                id="mobilePhone"
                                disabled={urlView !== -1 && true}
                                value={emergency && emergency.mobilePhone}
                                onChange={(e) => setEmergency({ ...emergency, mobilePhone: e.target.value })}
                            />
                        </Col>
                        <Col md="6" className="form-group mt-2">
                            <label htmlFor="housePhone" className="title">
                                House Phone
                            </label>
                            <Input
                                id="housePhone"
                                disabled={urlView !== -1 && true}
                                value={emergency && emergency.housePhone}
                                onChange={(e) => setEmergency({ ...emergency, housePhone: e.target.value })}
                            />
                        </Col>
                        <Col md="6" className="form-group mt-2">
                            <label htmlFor="officePhone" className="title">
                                Office Phone
                            </label>
                            <Input
                                id="officePhone"
                                disabled={urlView !== -1 && true}
                                value={emergency && emergency.officePhone}
                                onChange={(e) => setEmergency({ ...emergency, officePhone: e.target.value })}
                            />
                        </Col>
                    </Row>
                </TabPanel>

                <TabPanel value={valueTabs} index={6}>
                    <p className="title_tabs">PHYSICAL</p>
                    <Row>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="physicalHeight" className="title">
                                Height
                            </label>
                            <div>
                                <Input
                                    placeholder="Height (cm)"
                                    id="physicalHeight"
                                    className="ct-input"
                                    value={health && health.physicalHeight}
                                    disabled={urlView !== -1 && true}
                                    onChange={(e) => setHealth({ ...health, physicalHeight: e.target.value })}
                                />
                            </div>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="physicalWeight" className="title">
                                Weight
                            </label>
                            <div>
                                <Input
                                    placeholder="Weight (kg)"
                                    id="physicalWeight"
                                    className="ct-input"
                                    value={health && health.physicalWeight}
                                    disabled={urlView !== -1 && true}
                                    onChange={(e) => setHealth({ ...health, physicalWeight: e.target.value })}
                                />
                            </div>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="physicalBloodType" className="title">
                                Blood Type
                            </label>
                            <div>
                                <Select
                                    value={health && health.physicalBloodType}
                                    style={{ width: '100%' }}
                                    disabled={urlView !== -1 && true}
                                    onChange={(value) => setHealth({ ...health, physicalBloodType: value })}
                                >
                                    {EMPLOYEE_BLOOD_TYPE.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                    </Row>
                    <p className="title_tabs">VISION</p>
                    <Row>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="visionLeft" className="title">
                                Left
                            </label>
                            <div>
                                <Select
                                    value={health && health.visionLeft}
                                    style={{ width: '100%' }}
                                    disabled={urlView !== -1 && true}
                                    onChange={(value) => setHealth({ ...health, visionLeft: value })}
                                >
                                    {EMPLOYEE_HEALTH.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="visonRight" className="title">
                                Right
                            </label>
                            <div>
                                <Select
                                    value={health && health.visonRight}
                                    style={{ width: '100%' }}
                                    disabled={urlView !== -1 && true}
                                    onChange={(value) => setHealth({ ...health, visonRight: value })}
                                >
                                    {EMPLOYEE_HEALTH.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                    </Row>
                    <p className="title_tabs">HEARING</p>
                    <Row>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="hearingLeft" className="title">
                                Left
                            </label>
                            <div>
                                <Select
                                    value={health && health.hearingLeft}
                                    style={{ width: '100%' }}
                                    disabled={urlView !== -1 && true}
                                    onChange={(value) => setHealth({ ...health, hearingLeft: value })}
                                >
                                    {EMPLOYEE_HEARING.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="hearingRight" className="title">
                                Right
                            </label>
                            <div>
                                <Select
                                    value={health && health.hearingRight}
                                    style={{ width: '100%' }}
                                    disabled={urlView !== -1 && true}
                                    onChange={(value) => setHealth({ ...health, hearingRight: value })}
                                >
                                    {EMPLOYEE_HEARING.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                    </Row>
                    <p className="title_tabs">HAND</p>
                    <Row>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="handLeft" className="title">
                                Left
                            </label>
                            <div>
                                <Select
                                    value={health && health.handLeft}
                                    style={{ width: '100%' }}
                                    disabled={urlView !== -1 && true}
                                    onChange={(value) => setHealth({ ...health, handLeft: value })}
                                >
                                    {EMPLOYEE_HAND_LEG.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="handRight" className="title">
                                Right
                            </label>
                            <div>
                                <Select
                                    value={health && health.handRight}
                                    style={{ width: '100%' }}
                                    disabled={urlView !== -1 && true}
                                    onChange={(value) => setHealth({ ...health, handRight: value })}
                                >
                                    {EMPLOYEE_HAND_LEG.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                    </Row>
                    <p className="title_tabs">LEG</p>
                    <Row>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="legLeft" className="title">
                                Left
                            </label>
                            <div>
                                <Select
                                    value={health && health.legLeft}
                                    style={{ width: '100%' }}
                                    disabled={urlView !== -1 && true}
                                    onChange={(value) => setHealth({ ...health, legLeft: value })}
                                >
                                    {EMPLOYEE_HAND_LEG.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="6" className="form-group mt-2">
                            <label htmlFor="legRight" className="title">
                                Right
                            </label>
                            <div>
                                <Select
                                    value={health && health.legRight}
                                    style={{ width: '100%' }}
                                    disabled={urlView !== -1 && true}
                                    onChange={(value) => setHealth({ ...health, legRight: value })}
                                >
                                    {EMPLOYEE_HAND_LEG.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                    </Row>
                </TabPanel>

                <TabPanel value={valueTabs} index={7}>
                    <p className="title_tabs">ACCESS RIGHT</p>
                    <Row>
                        <Col lg="12" className="form-group mt-2">
                            <label htmlFor="employeeRole" className="title">
                                Employee Role
                            </label>
                            <div>
                                <Select
                                    value={derectory && derectory.employeeRole}
                                    style={{ width: '100%' }}
                                    disabled={urlView !== -1 && true}
                                    onChange={(value) => setDerectory({ ...derectory, employeeRole: value })}
                                >
                                    {EMPLOYEE_PRIVACY_LEVEL.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="12">
                            <div className="access_box">
                                <BsQuestionSquareFill size={20} color="#cddc39" />
                                <p>Guest: No access to the Employee Directory.</p>
                                <p>Employee: Access to Privacy Level marked as Employee.</p>
                                <p>Manager: Access to Privacy Level marked as either Employee or Manager.</p>
                            </div>
                        </Col>
                    </Row>
                    <p className="title_tabs">PRIVACY LEVEL</p>
                    <Row>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="email" className="title">
                                Email
                            </label>
                            <div>
                                <Select
                                    value={derectory && derectory.email}
                                    style={{ width: '100%' }}
                                    disabled={urlView !== -1 && true}
                                    onChange={(value) => setDerectory({ ...derectory, email: value })}
                                >
                                    {EMPLOYEE_PRIVACY_LEVEL.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="blog" className="title">
                                Blog / Homepage
                            </label>
                            <div>
                                <Select
                                    value={derectory && derectory.blog}
                                    style={{ width: '100%' }}
                                    disabled={urlView !== -1 && true}
                                    onChange={(value) => setDerectory({ ...derectory, blog: value })}
                                >
                                    {EMPLOYEE_PRIVACY_LEVEL.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="officePhone" className="title">
                                Office Phone
                            </label>
                            <div>
                                <Select
                                    value={derectory && derectory.officePhone}
                                    style={{ width: '100%' }}
                                    disabled={urlView !== -1 && true}
                                    onChange={(value) => setDerectory({ ...derectory, officePhone: value })}
                                >
                                    {EMPLOYEE_PRIVACY_LEVEL.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="housePhone" className="title">
                                House Phone
                            </label>
                            <div>
                                <Select
                                    value={derectory && derectory.housePhone}
                                    style={{ width: '100%' }}
                                    disabled={urlView !== -1 && true}
                                    onChange={(value) => setDerectory({ ...derectory, housePhone: value })}
                                >
                                    {EMPLOYEE_PRIVACY_LEVEL.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="mobilePhone" className="title">
                                Mobile Phone
                            </label>
                            <div>
                                <Select
                                    value={derectory && derectory.mobilePhone}
                                    style={{ width: '100%' }}
                                    disabled={urlView !== -1 && true}
                                    onChange={(value) => setDerectory({ ...derectory, mobilePhone: value })}
                                >
                                    {EMPLOYEE_PRIVACY_LEVEL.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="address" className="title">
                                Address
                            </label>
                            <div>
                                <Select
                                    value={derectory && derectory.address}
                                    style={{ width: '100%' }}
                                    disabled={urlView !== -1 && true}
                                    onChange={(value) => setDerectory({ ...derectory, address: value })}
                                >
                                    {EMPLOYEE_PRIVACY_LEVEL.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="emergency" className="title">
                                In Case of Emergency
                            </label>
                            <div>
                                <Select
                                    value={derectory && derectory.emergency}
                                    style={{ width: '100%' }}
                                    disabled={urlView !== -1 && true}
                                    onChange={(value) => setDerectory({ ...derectory, emergency: value })}
                                >
                                    {EMPLOYEE_PRIVACY_LEVEL.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="birthday" className="title">
                                Birthday
                            </label>
                            <div>
                                <Select
                                    value={derectory && derectory.birthday}
                                    style={{ width: '100%' }}
                                    disabled={urlView !== -1 && true}
                                    onChange={(value) => setDerectory({ ...derectory, birthday: value })}
                                >
                                    {EMPLOYEE_PRIVACY_LEVEL.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="familyBirthday" className="title">
                                Family Birthday
                            </label>
                            <div>
                                <Select
                                    value={derectory && derectory.familyBirthday}
                                    style={{ width: '100%' }}
                                    disabled={urlView !== -1 && true}
                                    onChange={(value) => setDerectory({ ...derectory, familyBirthday: value })}
                                >
                                    {EMPLOYEE_PRIVACY_LEVEL.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col lg="4" className="form-group mt-2">
                            <label htmlFor="anniversary" className="title">
                                Anniversary
                            </label>
                            <div>
                                <Select
                                    value={derectory && derectory.anniversary}
                                    style={{ width: '100%' }}
                                    disabled={urlView !== -1 && true}
                                    onChange={(value) => setDerectory({ ...derectory, anniversary: value })}
                                >
                                    {EMPLOYEE_PRIVACY_LEVEL.map((item) => (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                    </Row>
                </TabPanel>

                <TabPanel value={valueTabs} index={8}>
                    <p className="title_tabs">Certificate</p>
                    <Row className="p-0" lg="12" sm="12" md="8" xl="12">
                        <Col xl="4" lg="12" md="12" sm="12" className="p-1">
                            {id && (
                                <InputGroup>
                                    <FormInput
                                        placeholder="Input Search Text"
                                        onChange={(e) =>
                                            setFilterCertificate({ ...filterCertificate, search: e.target.value })
                                        }
                                    />
                                    <InputGroupAddon type="append">
                                        <InputGroupText className="pointer">
                                            <i className="material-icons" style={{ fontSize: '25px' }}>
                                                search
                                            </i>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            )}
                        </Col>
                        <Col xl="4"></Col>
                        <Col xl="4" lg="12" md="12" sm="12">
                            <Row>
                                <Col lg="6" md="6" sm="12" className="p-1">
                                    {urlEdit === 1 && (
                                        <button className="button_delete">
                                            <MdDeleteForever size={20} />
                                            <span className="text__red" onClick={() => showModalDeleteCertificate()}>
                                                Delete
                                            </span>
                                        </button>
                                    )}
                                </Col>

                                <Col lg="6" md="6" sm="12" className="p-1">
                                    {urlEdit === 1 && (
                                        <div
                                            className="text-center rounded p-2 d-flex justify-content-center align-items-center button__warning"
                                            onClick={() => showModalCertificate()}
                                        >
                                            <AiFillPlusCircle size={25} />
                                            <span className="text ml-2">Add Certificate</span>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="p-0">
                            <ListCertificate
                                user={userCertificate}
                                refetch={reloadCertificate}
                                setRefetch={setReloadCertificate}
                                deleteList={deleteListCertificate}
                                setDeleteList={setDeleteListCertificate}
                                filter={filterCertificate}
                                setFilter={setFilterCertificate}
                                type={typeCertificate}
                                setType={setTypeCertificate}
                                listTmpCertificate={listTmpCertificate}
                                setListTmpCertificate={setListTmpCertificate}
                                isAddEmployee={isAddEmployee}
                            />
                        </Col>
                    </Row>
                    <CertificateForm
                        visible={openCertificate}
                        setVisible={setOpenCertificate}
                        type={typeCertificate}
                        setReload={setReloadCertificate}
                        idEmployee={id}
                        isAddEmployee={isAddEmployee}
                        setListTmpCertificate={setListTmpCertificate}
                    />
                    <Modal
                        title={
                            <>
                                <svg
                                    width="18"
                                    height="21"
                                    viewBox="0 0 18 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M15.4167 0.833344H2.58333C1.56583 0.833344 0.75 1.65834 0.75 2.66668V15.5C0.75 16.5083 1.56583 17.3333 2.58333 17.3333H6.25L9 20.0833L11.75 17.3333H15.4167C16.425 17.3333 17.25 16.5083 17.25 15.5V2.66668C17.25 1.65834 16.425 0.833344 15.4167 0.833344ZM9.91667 15.5H8.08333V13.6667H9.91667V15.5ZM11.8142 8.39584L10.9892 9.23918C10.3292 9.90834 9.91667 10.4583 9.91667 11.8333H8.08333V11.375C8.08333 10.3667 8.49583 9.45001 9.15583 8.78084L10.2925 7.62584C10.6317 7.29584 10.8333 6.83751 10.8333 6.33334C10.8333 5.32501 10.0083 4.50001 9 4.50001C7.99167 4.50001 7.16667 5.32501 7.16667 6.33334H5.33333C5.33333 4.30751 6.97417 2.66668 9 2.66668C11.0258 2.66668 12.6667 4.30751 12.6667 6.33334C12.6667 7.14001 12.3367 7.87334 11.8142 8.39584Z"
                                        fill="#FCBB0A"
                                    />
                                </svg>

                                <p className="title-modal-custom">{`Are you sure delete ${deleteListCertificate.length ||
                                    0} items?`}</p>
                            </>
                        }
                        open={openDeleteCertificate}
                        onOk={handleDeleteOkCertificate}
                        onCancel={handleDeleteCancelCertificate}
                    >
                        <p>By delete messages</p>
                    </Modal>
                </TabPanel>

                <TabPanel value={valueTabs} index={9} className="tab-panel">
                    <p className="title_tabs">REMARK</p>
                    {/* <Row>
                        <Col lg="12" className="form-group mt-2"> */}
                    <label htmlFor="remark" className="title">
                        Remark
                    </label>
                    <TextArea
                        rows={5}
                        id="remark"
                        placeholder="Remark (2000 characters max)"
                        className="ct-textarea"
                        value={employee && employee.remark}
                        disabled={urlView !== -1 && true}
                        onChange={(e) =>
                            setEmployee({
                                ...employee,
                                remark: e.target.value,
                            })
                        }
                    />
                    {/* </Col>
                    </Row> */}
                    <p className="title_tabs">PERMANENT RESIDENTS SETTING</p>

                    <Row className="py-3">
                        <Col lg="6" md="12" sm="12">
                            <label id="lib1">Permanent residents type</label>
                            <Select
                                htmlFor="lb1"
                                value={defaultPR}
                                style={{ width: '100%', marginTop: 10 }}
                                onChange={(e) => setDefaultPR(e)}
                                // disabled={user.role !== ROLE.COMPANY && user.role !== ROLE.COMPANYSTAFF ? true : false}
                            >
                                <Option value={'Citizens'} key={'Citizens'}>
                                    {'Citizens'}
                                </Option>
                                <Option value={'F/G'} key={'F/G'}>
                                    {'F/G'}
                                </Option>
                                <Option value={'G/G'} key={'G/G'}>
                                    {'G/G'}
                                </Option>
                            </Select>
                        </Col>
                        {defaultPR && defaultPR !== 'Citizens' && (
                            <>
                                <Col lg="6" md="12" sm="12">
                                    <label id="lib1">Date of permanent residence registration</label>
                                    <DatePicker
                                        htmlFor="lb1"
                                        style={{ width: '100%', marginTop: '10px' }}
                                        format="YYYY-MM-DD"
                                        defaultValue={
                                            datePR && datePR
                                                ? moment(moment(new Date(datePR)).format(dateFormat), dateFormat)
                                                : null
                                        }
                                        onChange={(date, dateString) => setDatePR(new Date(date).getTime())}
                                    />
                                </Col>
                            </>
                        )}
                    </Row>

                    <p className="title_tabs">
                        INDIVIDUAL LEAVE SETTING
                        {(ROLE.COMPANY || ROLE.COMPANYSTAFF || ROLE.USER) && (
                            <>
                                {urlView === -1 && (
                                    <span className="ml-2" onClick={() => showModal(12)} style={{ cursor: 'pointer' }}>
                                        <AiFillPlusCircle size={25} color="#ffd96c" />
                                    </span>
                                )}
                            </>
                        )}
                    </p>
                    <Modal
                        title="Add individual leave setting"
                        open={isModalIndividualLeaveSetting}
                        onOk={handleOkIndividualLeaveSetting}
                        onCancel={handleCancelIndividualLeaveSetting}
                        zIndex={900}
                    >
                        <Form form={form} layout="vertical" name="form_in_modal" initialValues={{ modifier: 'public' }}>
                            <Row lg="12" md="8" sm="6">
                                <Col lg="6">
                                    <Form.Item
                                        name="leaveSetting"
                                        label="Select Leave Type"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select Leave Type!',
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            id="leaveSetting"
                                            style={{ width: '100%', zIndex: 999 }}
                                            value={addIndividualLeaveSetting.leaveSetting}
                                            onChange={(e) =>
                                                setAddIndividualLeaveSetting({
                                                    ...addIndividualLeaveSetting,
                                                    leaveSetting: e,
                                                })
                                            }
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().includes(input.toLowerCase())
                                            }
                                        >
                                            {listLeaveSetting.map((item, index) => (
                                                <Select.Option key={index} value={item._id}>
                                                    {item.description}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg="6">
                                    <Form.Item
                                        name="title"
                                        label="Number of leave"
                                        rules={[{ required: true, message: 'Please input number of leave!' }]}
                                    >
                                        <Input
                                            type="number"
                                            id="title"
                                            value={addIndividualLeaveSetting.overwrite}
                                            onChange={(e) => {
                                                setAddIndividualLeaveSetting({
                                                    ...addIndividualLeaveSetting,
                                                    overwrite: e.target.value,
                                                });
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>

                    <Form form={form} component={false}>
                        <Table
                            className="ct-table-data"
                            components={{ body: { cell: EditableCell } }}
                            dataSource={dataIndividualLeaveSetting}
                            columns={mergedColumns}
                            rowClassName="editable-row"
                            pagination={false}
                            style={{
                                width: '100%',
                            }}
                        />
                    </Form>
                </TabPanel>

                <div className="d-flex justify-content-center align-items-center mt-2 mb-4">
                    <button onClick={cancel} id="cancel" className="ct_btn mx-2 px-4 cancel">
                        Cancel
                    </button>
                    {urlView === -1 && (
                        <>
                            {/* {valueTabs === 0 && ( */}
                            <button
                                onClick={() => {
                                    submit();
                                }}
                                className="ct_btn mx-2 px-4 save"
                                id="save"
                            >
                                <span>Save</span>
                            </button>
                            {/* )} */}
                            {/* {valueTabs !== 0 && (
                                <>
                                    {urlEdit === -1 && valueTabs === 0 && (
                                        <button
                                            onClick={() => {
                                                addQuickEntry();
                                            }}
                                            className="ct_btn mx-2 px-4 save"
                                            id="save"
                                        >
                                            <span>S1</span>
                                        </button>
                                    )}
                                    {urlEdit !== -1 && (
                                        <button onClick={() => submit()} className="ct_btn mx-2 px-4 save" id="save">
                                            <span>Save</span>
                                        </button>
                                    )}
                                </>
                            )} */}
                        </>
                    )}

                    {urlView === -1 && urlEdit === -1 && valueTabs < 9 && (
                        <button
                            theme="warning"
                            id="next"
                            className="ct_btn mx-2 px-4 next"
                            // disabled={valueTabs === 8 && true}
                            htmlType="submit"
                            onClick={() => {
                                if (validatePerson() && valueTabs === 0) {
                                    handleNextTab();
                                }
                                if (valueTabs === 1 && validatePerson()) {
                                    handleNextTab();
                                }
                                if (valueTabs === 2 && validatePerson() && validateJob()) {
                                    handleNextTab2();
                                }
                                if (valueTabs === 3 && validatePerson() && validateSalary()) {
                                    handleNextTab3();
                                }
                                if (valueTabs === 4 && validatePerson() && validateSalary()) {
                                    handleNextTab4();
                                }
                                if (valueTabs === 5) {
                                    handleNextTab5();
                                }
                                if (valueTabs === 6) {
                                    handleNextTab6();
                                }
                                if (valueTabs === 7) {
                                    handleNextTab7();
                                }
                                if (valueTabs === 8) {
                                    handleNextTab8();
                                }
                                if (valueTabs === 9) {
                                    handleNextTab9();
                                }
                            }}
                        >
                            Next
                        </button>
                    )}
                </div>
            </Card>
        </Container>
    );
}

export default CreateEmployees;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, idCompany, ...restProps }) => {
    const [listLeaveSetting, setListLeaveSetting] = useState([]);

    const inputNode =
        inputType === 'select' ? (
            <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            >
                {listLeaveSetting.length > 0 &&
                    listLeaveSetting.map((item, i) => (
                        <Select.Option key={i} value={item._id}>
                            {item && item.description ? item.description : ''}
                        </Select.Option>
                    ))}
            </Select>
        ) : (
            <Input />
        );

    useEffect(() => {
        (async () => {
            if (idCompany && record && record._id) {
                //const { data: tmp } = await leavesSettingAPI.findAndCreate(idCompany);
                const { data } = await leavesSettingAPI.getByCompanyActive(idCompany);
                //const arr = data.filter((item) => item._id !== tmp._id);
                setListLeaveSetting(data);
            }
        })();
    }, [idCompany]);

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};
