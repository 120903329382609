import { DatePicker, Form, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Row } from 'shards-react';
import { useLoading, useUser } from '../../hooks';
import moment from 'moment';
import { getError } from '../../helpers/getErrorServer';
import { FORM_TYPE, ROLE } from '../../helpers/const';
import { employeesAPI } from '../../apis/employeesAPI';
import { Option } from 'antd/lib/mentions';
import { Select, Space } from 'antd';
import { trainingAPI } from '../../apis/trainingAPI';

const TrainingForm = ({ visible, setVisible, type, setReload, id }) => {
    const [form] = Form.useForm();
    const { user, getMe } = useUser();
    const { setLoading } = useLoading();
    const [payload, setPayload] = useState();
    const [listEmployee, setListEmployee] = useState([]);
    const [listEmployeeTmp, setListEmployeeTmp] = useState([]);
    const [listIdEmployees, setListIdEmployees] = useState([]);
    const [status, setStatus] = useState('');
    const [studyPlace, setStudyPlace] = useState('');
    const [search, setSearch] = useState([]);

    const loadListEmployees = async (id, search) => {
        try {
            const { data } = await employeesAPI.findSearchEmployeesOfCompany(id, search);
            setListEmployee(data);
            setListEmployeeTmp(data);
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const handleChangeStatus = async (value) => {
        setStatus(value);
    };

    const handleChangeStudyPlace = async (value) => {
        setStudyPlace(value);
    };

    const handleChangeEmployeesList = async (value) => {
        let isAll = false;
        value.map((item) => {
            if (item === 'all') {
                isAll = true;
            }
        });

        if (isAll) {
            setListEmployeeTmp([]);
            setListIdEmployees(['all']);
        } else {
            setListEmployeeTmp(listEmployee);
            setListIdEmployees(value);
        }
    };

    const handleOk = () => {
        if (type === FORM_TYPE.CREATE) {
            create();
        } else if (type === FORM_TYPE.EDIT && id) {
            update();
        }
    };

    const checkValidate = () => {
        if (payload.timeStart && payload.timeEnd && payload.timeStart > payload.timeEnd) {
            toast.error('Date invalid!');
            return false;
        }
        return true;
    };

    const checkField = () => {
        if (!payload.name) {
            toast.error('Please enter name!');
            return false;
        }
        if (!payload.timeStart) {
            toast.error('Please enter start day!');
            return false;
        }
        if (!payload.timeEnd) {
            toast.error('Please enter end day!');
            return false;
        }
        if (!payload.studyPlace) {
            toast.error('Please enter study place!');
            return false;
        }
        if (!payload.quantity) {
            toast.error('Please enter quantity!');
            return false;
        }
        if (!payload.status) {
            toast.error('Please enter status!');
            return false;
        }
        return true;
    };

    const getById = async (id) => {
        try {
            if (id) {
                const { data } = await trainingAPI.getByID(id);
                setPayload(data);
                form.setFieldsValue(data);
                setStudyPlace(data.studyPlace);
                setStatus(data.status);
                form.setFieldValue('Start-day', moment(new Date(data.timeStart), 'YYYY-MM-DD'));
                form.setFieldValue('End-day', moment(new Date(data.timeEnd), 'YYYY-MM-DD'));
            }
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const create = async () => {
        try {
            if (Object.keys(user).length === 0) {
                getMe();
            }
            payload.status = status;
            payload.studyPlace = studyPlace;
            payload.students = listIdEmployees;
            const validateField = checkField();
            if (!validateField) {
                return;
            }

            const check = checkValidate();
            if (!check) {
                return;
            }
            setLoading(true);
            await trainingAPI.create(payload);

            setLoading(false);
            setReload((prev) => prev + 1);
            setVisible(false);
            form.resetFields();
            setPayload({});
            toast.success('Success');
        } catch (error) {
            setLoading(false);
            toast.error(getError(error));
        }
    };

    const update = async () => {
        try {
            if (Object.keys(user).length === 0) {
                getMe();
            }
            const validateField = checkField();
            if (!validateField) {
                return;
            }

            const check = checkValidate();
            if (!check) {
                return;
            }
            setLoading(true);

            payload.status = status;
            payload.studyPlace = studyPlace;
            const data = {
                name: payload.name,
                timeStart: payload.timeStart,
                timeEnd: payload.timeEnd,
                studyPlace: payload.studyPlace,
                status: payload.status,
                quantity: payload.quantity,
            };
            await trainingAPI.update(payload.id, data);
            setLoading(false);
            setReload((prev) => prev + 1);
            setVisible(false);
            toast.success('Success');
        } catch (error) {
            console.log(error);
            setLoading(false);
            toast.error(getError(error));
        }
    };

    const handleCancel = () => {
        setVisible(false);
        if (type === FORM_TYPE.CREATE) {
            form.resetFields();
            setPayload();
        }
    };

    useEffect(() => {
        if (user && user.company && user.company._id) {
            loadListEmployees(user.company._id, search);
        } else if (user && user.employee && user.employee.company && user.employee.company._id) {
            loadListEmployees(user.employee.company._id, search);
        }
    }, [user, search]);

    useEffect(() => {
        if (id && type === FORM_TYPE.EDIT) {
            if (user.role === 'company') {
                getById(id);
                loadListEmployees(user.company._id, search);
            } else if (user.role === 'company-staff') {
                getById(id);
                loadListEmployees(user.employee.company._id, search);
            }
        }
    }, [id, type]);

    return (
        <Modal
            title={type === FORM_TYPE.CREATE ? 'Create Training' : 'Edit Training'}
            open={visible}
            onOk={handleOk}
            zIndex={999}
            onCancel={handleCancel}
            width="700px"
        >
            <Form form={form} layout="vertical" name="form_in_modal">
                <Row>
                    <Col lg="12">
                        <div className="mb-4">
                            <Form.Item name="name" label="Name">
                                <Input
                                    type="text"
                                    placeholder="ex Name"
                                    id="Name"
                                    onChange={(e) => {
                                        setPayload({ ...payload, name: e.target.value });
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <div className="mb-4">
                            <Form.Item name="Start-day" label="Start day">
                                <DatePicker
                                    format={'YYYY-MM-DD'}
                                    style={{ width: '100%' }}
                                    id="Start-day"
                                    onChange={(_, dateString) => {
                                        setPayload({ ...payload, timeStart: new Date(dateString).getTime() });
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <div className="mb-4">
                            <Form.Item name="End-day" label="End day">
                                <DatePicker
                                    format={'YYYY-MM-DD'}
                                    style={{ width: '100%' }}
                                    id="End-day"
                                    onChange={(_, dateString) => {
                                        setPayload({ ...payload, timeEnd: new Date(dateString).getTime() });
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <div className=" mb-4">
                            <Form.Item name="Study-Place" label="Study Place">
                                <Space style={{ width: '100%' }} direction="vertical">
                                    <Select
                                        allowClear
                                        onChange={handleChangeStudyPlace}
                                        optionLabelProp="label"
                                        style={{ width: '100%', zIndex: 999 }}
                                        placeholder="Please select"
                                        maxTagCount="responsive"
                                        value={studyPlace}
                                    >
                                        <Option value="Company">
                                            <Space>Company</Space>
                                        </Option>
                                        <Option value="Academy">
                                            <Space>Academy</Space>
                                        </Option>
                                    </Select>
                                </Space>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <div className="mb-4">
                            <Form.Item name="quantity" label="Quantity">
                                <Input
                                    type="number"
                                    placeholder="ex quantity"
                                    id="quantity"
                                    onChange={(e) => {
                                        setPayload({ ...payload, quantity: e.target.value });
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <div className=" mb-4">
                            <Form.Item name="Status" label="Status">
                                <Space style={{ width: '100%' }} direction="vertical">
                                    <Select
                                        allowClear
                                        onChange={handleChangeStatus}
                                        optionLabelProp="label"
                                        style={{ width: '100%', zIndex: 999 }}
                                        placeholder="Please select"
                                        maxTagCount="responsive"
                                        value={status}
                                    >
                                        <Option value="Admissions">
                                            <Space>Admissions</Space>
                                        </Option>
                                        <Option value="Started">
                                            <Space>Started</Space>
                                        </Option>
                                        <Option value="Finished">
                                            <Space>Finished</Space>
                                        </Option>
                                    </Select>
                                </Space>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                {type === FORM_TYPE.CREATE ? (
                    <Row>
                        <Col lg="12">
                            <div className=" mb-4">
                                <Form.Item name="Students" label="Add Employees">
                                    <Space style={{ width: '100%' }} direction="vertical">
                                        <Select
                                            showSearch
                                            allowClear
                                            mode="multiple"
                                            style={{ width: '100%', zIndex: 999 }}
                                            onChange={handleChangeEmployeesList}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().includes(input.toLowerCase())
                                            }
                                        >
                                            {listEmployee && listEmployee.length > 0 && (
                                                <Select.Option value={'all'} label={'All'}>
                                                    --All--
                                                </Select.Option>
                                            )}
                                            {listEmployeeTmp.map((item) => (
                                                <Select.Option value={item._id} label={item.firstName}>
                                                    {item.firstName}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                        {/* <Select
                                            allowClear
                                            mode="multiple"
                                            onChange={handleChangeEmployeesList}
                                            optionLabelProp="label"
                                            style={{ width: '100%', zIndex: 999 }}
                                            placeholder="Please select employees of your company"
                                            maxTagCount="responsive"
                                        >
                                            {listEmployee && listEmployee.length > 0 && (
                                                <Option value="all" label="All">
                                                    <Space>--All--</Space>
                                                </Option>
                                            )}

                                            {listEmployeeTmp.map((item) => (
                                                <Option value={item._id} label={item.firstName}>
                                                    <Space>
                                                        {item.firstName} - {item.email}
                                                    </Space>
                                                </Option>
                                            ))}
                                        </Select> */}
                                    </Space>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <></>
                )}
            </Form>
        </Modal>
    );
};

export default TrainingForm;
