import axios from 'axios';

const path = 'histories/';

const get = async (filter = {}) => {
    return await axios.get(path, {
        params: filter,
    });
};

const add = async (payload) => {
    return await axios.post(path, payload);
};

const getById = async (id) => {
    return await axios.get(path + id);
};

const update = async (id) => {
    return await axios.patch(path + id);
};

export const historiesAPI = {
    get,
    add,
    getById,
    update,
};
