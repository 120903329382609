import { Form, Input, Modal, Space, Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Row } from 'shards-react';
import { expenseClaimAPI } from '../../apis/expenseClaimAPI';
import { EXPENSE_CLAIM_STATUS } from '../../global/expenseClaim/expenseClaimEnum';
import { FORMAT, FORM_TYPE, ROLE } from '../../helpers/const';
import { useExpenseClaim, useUser } from '../../hooks';
import ExClaimCreateForm from './ExClaimCreateForm';

const TableExHistory = ({ value }) => {
    const { expenseClaims, fetchExpenseClaims, filterExpenseClaim, setFilterExpenseClaim } = useExpenseClaim();
    const [dataTable, setDataTable] = useState([]);
    const { user, getMe } = useUser();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { TextArea } = Input;
    const [form] = Form.useForm();
    const [exClaimSelected, setExClaimSelected] = useState();
    const [visible, setVisible] = useState();
    const [type, setType] = useState(FORM_TYPE.VIEW);
    const [modalDelete, setModalDelete] = useState(false);
    const [dataDelete, setDatatDelete] = useState();
    const [page, setPage] = useState(1);

    const initColumns = [
        {
            title: 'Item',
            dataIndex: 'itemName',
            key: 'itemName',
            render: (itemName) => (
                <>
                    <div className="content_td_mobile">
                        <p className="title_mobile">Item</p>
                        <p>{itemName ? <p>{itemName}</p> : "Can't get item's name"}</p>
                    </div>
                </>
            ),

            sorter: (record1, record2) => {
                return record1.itemName > record2.itemName;
            },
        },
        {
            title: 'Oder By',
            dataIndex: 'type',
            key: 'type',
            render: (_, row) => (
                <>
                    <div className="content_td_mobile">
                        <p className="title_mobile">Oder By</p>
                        <p>
                            {row.employee && row.employee.firstName
                                ? row.employee.firstName
                                : "Can't get first name employee"}
                        </p>
                    </div>
                </>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (value) => (
                <>
                    <div className="content_td_mobile">
                        <p className="title_mobile">Date</p>
                        <p>{moment(value).format(FORMAT.DATE)}</p>
                    </div>
                </>
            ),

            sorter: (record1, record2) => {
                return record1.date > record2.date;
            },
        },
        {
            title: 'From',
            dataIndex: 'from',
            key: 'from',
            render: (from) => (
                <>
                    <div className="content_td_mobile">
                        <p className="title_mobile">From</p>
                        <p>{from ? <p>{from}</p> : ''}</p>
                    </div>
                </>
            ),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount) => (
                <>
                    <div className="content_td_mobile">
                        <p className="title_mobile">Amount</p>
                        <p>{amount ? <p>{amount}</p> : "Can't get amount"}</p>
                    </div>
                </>
            ),
        },
        {
            title: 'Paid By',
            dataIndex: 'paidBy',
            key: 'paidBy',
            render: (paidBy) => (
                <>
                    <div className="content_td_mobile">
                        <p className="title_mobile">Paid By</p>
                        <p>{paidBy ? <p>{paidBy}</p> : "Can't get paid by"}</p>
                    </div>
                </>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (value) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Status</p>
                    <p className={value === EXPENSE_CLAIM_STATUS.APPROVE ? 'text-primary' : 'text-danger'}>{value}</p>
                </div>
            ),
        },
        {
            title: 'Actions',
            key: '_id',
            dataIndex: '_id',
            fixed: 'right',
            render: (id, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Actions</p>
                    <Space size="middle">
                        {(user.role === ROLE.COMPANY ||
                            user.role === ROLE.COMPANYSTAFF ||
                            user.position.expenseClaim.includes('1') ||
                            (user.employee &&
                                user.employee.controls &&
                                user.employee.controls.expenseClaim &&
                                user.employee.controls.expenseClaim.includes('1')) ||
                            (user && user.employee && user.employee.isApproverExpense)) && (
                            <Link to={`#`} onClick={() => onOpen(id)}>
                                <span className="material-icons icon_eye">visibility</span>
                            </Link>
                        )}

                        {(user.role === ROLE.COMPANY ||
                            user.role === ROLE.COMPANYSTAFF ||
                            user.position.expenseClaim.includes('2') ||
                            (user.employee &&
                                user.employee.controls &&
                                user.employee.controls.expenseClaim &&
                                user.employee.controls.expenseClaim.includes('2')) ||
                            (user && user.employee && user.employee.isApproverExpense)) && (
                            <Link to={`#`} onClick={() => onOpen(id, FORM_TYPE.EDIT)}>
                                <span className="material-icons icon_edit">edit</span>
                            </Link>
                        )}
                    </Space>
                </div>
            ),
        },
    ];

    const deleteExClaim = async (id) => {
        try {
            const { data } = await expenseClaimAPI._delete(id);
            if (data) {
                toast.success('Deleted successfully!');
                fetchExpenseClaims();
            } else {
                toast.error('Deleted failed!');
            }
        } catch (error) {
            console.log(error);
        }
    };

    const columnsManager = [
        {
            title: 'ID',
            key: 'employee.employeesId',

            render: (_, row, i) => row.employee.employeesId,

            sorter: (record1, record2) => {
                return record1.employee.employeesId > record2.employee.employeesId;
            },
        },
        ...initColumns,
    ];

    const [columns, setColumns] = useState(initColumns);

    useEffect(() => {
        if (
            (user.role && user.role !== ROLE.USER) ||
            (user.position && user.position.expenseClaim && user.position.expenseClaim.length > 0) ||
            (user.employee &&
                user.employee.controls &&
                user.employee.controls.expenseClaim &&
                user.employee.controls.expenseClaim.length > 0) ||
            (user && user.employee && user.employee.isApproverExpense)
        ) {
            setColumns(columnsManager);
        }
    }, [user]);

    useEffect(() => {
        (async () => {
            if (Object.keys(user) === 0) await getMe();
        })();
    }, [user]);

    const handleDelete = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        form.resetFields();
        setIsModalVisible(false);
    };

    const onOpen = (id, type = FORM_TYPE.VIEW) => {
        setExClaimSelected(id);
        setVisible(true);
        setType(type);
    };

    const onClose = () => {
        setVisible(false);
        setExClaimSelected();
        form.resetFields();
    };

    const onChange = (e) => {};

    useEffect(() => {
        if (expenseClaims) {
            let tmp = [];
            expenseClaims.items.forEach((item, i) => {
                tmp.push({
                    ...item,
                    key: ((expenseClaims.paginate.page || 1) - 1) * 10 + i + 1,
                });
            });
            setDataTable(tmp);
        }
    }, [expenseClaims]);

    const onChangePage = (page) => {
        setPage(page);
        const filter = { ...filterExpenseClaim, page };
        setFilterExpenseClaim(filter);
        fetchExpenseClaims(filter);
    };

    const showModalDelete = (data) => {
        setDatatDelete(data);
        setModalDelete(true);
    };

    const handleOkDelete = () => {
        if (dataDelete) {
            deleteExClaim(dataDelete._id);
            setModalDelete(false);
        }
    };

    const handleCancelDelete = () => {
        setModalDelete(false);
    };

    return (
        <div className="overflow-hidden">
            <Table
                className="ct-table-data"
                size="small"
                columns={columns}
                dataSource={dataTable}
                pagination={{
                    onChange: onChangePage,
                    pageSize:
                        expenseClaims && expenseClaims.paginate && expenseClaims.paginate.size
                            ? expenseClaims.paginate.size
                            : 10,
                    total: expenseClaims && expenseClaims.paginate ? expenseClaims.paginate.count : 0,
                }}
                value={value}
            />

            <ExClaimCreateForm
                visible={visible}
                id={exClaimSelected}
                onCancel={() => {
                    onClose();
                }}
                type={type}
                pageHistory={page}
                value={value}
            />
            <Modal
                title="Are you sure to approve this request?"
                open={isModalVisible}
                onDelete={handleDelete}
                onCancel={handleCancel}
                zIndex={999}
            >
                <Row form>
                    <Col lg="12" className="form-group">
                        <Form.Item
                            label="Reason"
                            name="reason"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            <TextArea
                                showCount
                                maxLength={100}
                                rows={4}
                                placeholder="Please input the reason why you approve this request."
                                onChange={onChange}
                                width="100%"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Modal>
            <Modal
                title={
                    <>
                        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.4167 0.833344H2.58333C1.56583 0.833344 0.75 1.65834 0.75 2.66668V15.5C0.75 16.5083 1.56583 17.3333 2.58333 17.3333H6.25L9 20.0833L11.75 17.3333H15.4167C16.425 17.3333 17.25 16.5083 17.25 15.5V2.66668C17.25 1.65834 16.425 0.833344 15.4167 0.833344ZM9.91667 15.5H8.08333V13.6667H9.91667V15.5ZM11.8142 8.39584L10.9892 9.23918C10.3292 9.90834 9.91667 10.4583 9.91667 11.8333H8.08333V11.375C8.08333 10.3667 8.49583 9.45001 9.15583 8.78084L10.2925 7.62584C10.6317 7.29584 10.8333 6.83751 10.8333 6.33334C10.8333 5.32501 10.0083 4.50001 9 4.50001C7.99167 4.50001 7.16667 5.32501 7.16667 6.33334H5.33333C5.33333 4.30751 6.97417 2.66668 9 2.66668C11.0258 2.66668 12.6667 4.30751 12.6667 6.33334C12.6667 7.14001 12.3367 7.87334 11.8142 8.39584Z"
                                fill="#FCBB0A"
                            />
                        </svg>

                        <p className="title-modal-custom">Are you sure delete this expense claim?</p>
                    </>
                }
                open={modalDelete}
                onOk={handleOkDelete}
                onCancel={handleCancelDelete}
            >
                <p>
                    By delete <strong>{dataDelete && dataDelete.itemName}</strong> expense claim?
                </p>
            </Modal>
        </div>
    );
};

export default TableExHistory;
