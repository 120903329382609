import axios from 'axios';

const path = 'branch/';

const add = async (payload) => {
    return await axios.post(path, payload);
};

const getByCompany = async (id) => {
    return await axios.get(path + 'company/' + id);
};

export const branchAPI = {
    add,
    getByCompany,
};
