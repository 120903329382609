import axios from 'axios';

const path = 'expense-claims/';

const get = async (filter = {}) => {
    return await axios.get(path, {
        params: filter,
    });
};

const getById = async (id) => {
    return await axios.get(path + id);
};

const add = async (payload) => {
    return await axios.post(path, payload);
};

const update = async (id, payload) => {
    return await axios.patch(path + id, payload);
};

const _delete = async (id) => {
    return await axios.delete(path + id);
};

export const expenseClaimAPI = {
    get,
    getById,
    add,
    update,
    _delete,
};
