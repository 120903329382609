import { Form, Input, Modal, Space, Table, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'shards-react';
import { LEAVE_STATUS, LEAVE_TYPE } from '../../global/leave';
import { FORMAT, FORM_TYPE, ROLE } from '../../helpers/const';
import { useUser } from '../../hooks';
import { useLeave } from '../../hooks/useLeave';
import IMG_AVT from '../../images/avatars/0.jpg';
import LeaveCreateForm from './leaveCreateForm';

const TableLeaveHistory = ({ setRender, value, setValue }) => {
    const { leaves, filterLeave, setFilterLeave, fetchLeaves } = useLeave();
    const { user } = useUser();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [dataTable, setDataTable] = useState([]);
    const [leaveSelected, setLeaveSelected] = useState();
    const [visible, setVisible] = useState();
    const [type, setType] = useState(FORM_TYPE.VIEW);
    const [page, setPage] = useState(1);
    const { TextArea } = Input;

    const initColumns = [
        {
            title: ' Name',
            render: (_, row) => {
                return (
                    <div className="content_td_mobile_db d-flex flex-start align-items-center">
                        <p className="title_mobile">
                            Name
                            {/* <RiArrowUpDownFill /> */}
                        </p>
                        <div className="avt_leave_requ mr-3">
                            <img
                                src={
                                    row.employee && row.employee.user && row.employee.user.avatar
                                        ? row.employee.user.avatar
                                        : IMG_AVT
                                }
                                alt=""
                            />
                        </div>
                        <p>
                            {row.employee && row.employee.firstName
                                ? row.employee.firstName
                                : "Can't get first name employee"}
                        </p>
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.employee.firstName > record2.employee.firstName;
            },
        },
        {
            title: 'Leave Type',
            dataIndex: 'type',
            key: 'type',
            render: (_, row) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Leave Type</p>

                    <Tag color={type === LEAVE_TYPE[0].value ? 'blue' : 'orange'} key={type}>
                        {row.type.description}
                    </Tag>
                </div>
            ),
        },
        {
            title: 'From',
            dataIndex: 'from',
            key: 'from',
            render: (value) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">From</p>
                        {moment(new Date(new Date(value).setHours(12, 0, 0, 0))).format(FORMAT.DATE)}
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.from > record2.from;
            },
        },
        {
            title: 'To',
            dataIndex: 'to',
            key: 'to',
            render: (value) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">To</p>
                        {moment(new Date(new Date(value).setHours(12, 0, 0, 0))).format(FORMAT.DATE)}
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.to > record2.to;
            },
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
            render: (value) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Reason</p>
                    {/* {getEllipsisTxt(value)} */}
                    {value}
                </div>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (value) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Status</p>
                    <span className={value === LEAVE_STATUS.APPROVE ? 'text-primary' : 'text-danger'}>{value}</span>
                </div>
            ),
        },
    ];
    const columnsManager = [
        ...initColumns,
        {
            title: 'Actions',
            key: '_id',
            fixed: 'right',
            dataIndex: '_id',
            render: (id, record) => (
                <div className="content_td_mobile">
                    <p className="title_mobile">Actions</p>
                    <Space size="middle">
                        {(user.role === ROLE.COMPANY ||
                            user.role === ROLE.COMPANYSTAFF ||
                            user.position.leave.includes('1') ||
                            (user.employee &&
                                user.employee.controls &&
                                user.employee.controls.leave &&
                                user.employee.controls.leave.includes('1')) ||
                            (user && user.employee && user.employee.isApproverLeave)) && (
                            <Link to={`#`} onClick={() => onOpen(id)}>
                                <span className="material-icons icon_eye">visibility</span>
                            </Link>
                        )}

                        {(user.role === ROLE.COMPANY ||
                            user.role === ROLE.COMPANYSTAFF ||
                            user.position.leave.includes('2') ||
                            (user.employee &&
                                user.employee.controls &&
                                user.employee.controls.leave &&
                                user.employee.controls.leave.includes('2')) ||
                            (user && user.employee && user.employee.isApproverLeave)) && (
                            <Link to={`#`} onClick={() => onOpen(id, FORM_TYPE.EDIT)}>
                                <span className="material-icons icon_edit">edit</span>
                            </Link>
                        )}
                    </Space>
                </div>
            ),
        },
    ];
    const columnsUser = [
        {
            title: 'ID',

            render: (_, row, i) => {
                return row.employee.employeesId;
            },
            sorter: (record1, record2) => {
                return record1.employee.employeesId > record2.employee.employeesId;
            },
        },
        ...initColumns,
        {
            title: 'Actions',
            key: '_id',
            fixed: 'right',
            dataIndex: '_id',
            render: (id, record) => (
                <Space size="middle">
                    <Link to={`#`} onClick={() => onOpen(id, FORM_TYPE.VIEW)}>
                        <span className="material-icons icon_eye">visibility</span>
                    </Link>
                </Space>
            ),
        },
    ];
    const [columns, setColumns] = useState(initColumns);

    useEffect(() => {
        if (
            (user.role && user.role !== ROLE.USER) ||
            (user.position && user.position.leave && user.position.leave.length > 0) ||
            (user.employee &&
                user.employee.controls &&
                user.employee.controls.leave &&
                user.employee.controls.leave.length > 0) ||
            (user && user.employee && user.employee.isApproverLeave)
        ) {
            setColumns(columnsManager);
        } else {
            setColumns(columnsUser);
        }
        // if (
        //     user.role &&
        //     user.role === ROLE.USER &&
        //     user.position &&
        //     user.position.leave &&
        //     user.position.leave.length === 0
        // ) {
        //     setColumns(columnsUser);
        // }
    }, [user]);

    const handleDelete = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onOpen = (id, type = FORM_TYPE.VIEW) => {
        setLeaveSelected(id);
        setVisible(true);
        setType(type);
    };

    const onClose = () => {
        setVisible(false);
        setLeaveSelected();
    };

    const onChange = (e) => {};

    useEffect(() => {
        if (leaves) {
            let tmp = [];
            leaves.items.forEach((item, i) => {
                tmp.push({
                    ...item,
                    key: i,
                    page: leaves.paginate.page,
                });
            });
            setDataTable(tmp);
        }
    }, [leaves]);

    const onChangePage = (page) => {
        setPage(page);
        const filter = { ...filterLeave, page };
        setFilterLeave(filter);
        fetchLeaves(filter);
    };

    return (
        <>
            <Table
                className="ct-table-data"
                columns={columns}
                dataSource={dataTable}
                pagination={{
                    onChange: onChangePage,
                    pageSize: leaves && leaves.paginate && leaves.paginate.size ? leaves.paginate.size : 10,
                    total: leaves && leaves.paginate ? leaves.paginate.count : 0,
                }}
            />
            <LeaveCreateForm
                visible={visible}
                id={leaveSelected}
                onCancel={() => {
                    onClose();
                }}
                type={type}
                setRender={setRender}
                value={value}
                setValue={setValue}
                pageHistory={page}
            />
            <Modal
                title="Are you sure to approve this request?"
                open={isModalVisible}
                onDelete={handleDelete}
                onCancel={handleCancel}
                zIndex={999}
            >
                <Row form>
                    <Col lg="12" className="form-group">
                        <Form.Item
                            label="Reason"
                            name="reason"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your username!',
                                },
                            ]}
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            <TextArea
                                showCount
                                maxLength={100}
                                rows={4}
                                placeholder="Please input the reason why you approve this request."
                                onChange={onChange}
                                width="100%"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default TableLeaveHistory;
