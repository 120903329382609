import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// const firebaseConfig = {
//     apiKey: 'AIzaSyAM5ZF6M0kbLLouANPS4ly6C_MnAYd3yf0',
//     authDomain: 'hrm-projects.firebaseapp.com',
//     projectId: 'hrm-projects',
//     storageBucket: 'hrm-projects.appspot.com',
//     messagingSenderId: '289687542078',
//     appId: '1:289687542078:web:539d81b060e4d6b668746f',
//     measurementId: 'G-NX56EP1J9B',
// };

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY_FIREBASE,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    // storageBucket: 'hrm-projects.appspot.com',
    // messagingSenderId: '289687542078',
    // appId: '1:289687542078:web:539d81b060e4d6b668746f',
    // measurementId: 'G-NX56EP1J9B',
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
