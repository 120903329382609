import { EXPENSE_CLAIM_PAID_BY, EXPENSE_CLAIM_STATUS } from './expenseClaimEnum';

export const EXPENSE_CLAIM = {
    employee: '',
    itemName: '',
    from: '',
    date: new Date(),
    amount: '',
    paidBy: EXPENSE_CLAIM_PAID_BY.CASH,
    attachments: '',
    status: EXPENSE_CLAIM_STATUS.PENDING,
    reasonApprove: '',
};
