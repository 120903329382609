import { DatePicker, Form, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Row } from 'shards-react';
import { useLoading, useUser } from '../../hooks';
import moment from 'moment';
import { getError } from '../../helpers/getErrorServer';
import { Option } from 'antd/lib/mentions';
import { Select, Space } from 'antd';
import { employeesAPI } from '../../apis/employeesAPI';
import { payRollAPI } from '../../apis/payRollAPI';
import { historiesAPI } from '../../apis/historiesAPI';
import { EMPLOYEES_TYPE_VALUE, FORMAT, ROLE, TYPE_NOTIFICATION } from '../../helpers/const';

const AddPayrollForm = ({ visible, setVisible, setReload, refetchPage, setRefetchPage }) => {
    const [form] = Form.useForm();
    const { user, getMe } = useUser();
    const { setLoading } = useLoading();
    const [payload, setPayload] = useState();
    const [staffList, setStaffList] = useState([]);
    const [search, setSearch] = useState([]);
    const [typeStaff, setTypeStaff] = useState('');
    const [typeWork, setTypeWork] = useState('');
    useEffect(() => {
        (async () => {
            if (Object.keys(user).length === 0) await getMe();
            if (user.role === ROLE.COMPANY) {
                const { data } = await employeesAPI.findSearchEmployeesOfCompany(user.company._id, search);
                setStaffList(data);
            } else if (user.role === ROLE.COMPANYSTAFF) {
                const { data } = await employeesAPI.findSearchEmployeesOfCompany(user.employee.company._id, search);
                setStaffList(data);
            }
        })();
    }, [user, search]);
    const handleChangeStaff = async (value) => {
        setPayload({ ...payload, employee: value });
        const { data } = await employeesAPI.findById(value);
        setTypeStaff(data.type);
        setTypeWork('');
    };
    const handleChangeDailyMonthly = async (value) => {
        setTypeWork(value);
        setPayload({ ...payload, dailyMonthly: value });
    };
    const handleOnChangeSearchStaff = async (input) => {
        console.log(input);
        setSearch(input);
    };
    const getHistories = async (id, employee) => {
        await historiesAPI.add({
            title: 'PAY ROLL',
            desc: 'Your salary just reviewed',
            employee,
            type: TYPE_NOTIFICATION.PAYROLL,
            idRequest: id,
            toEmployee: true,
        });
    };
    const handleOk = async () => {
        if (!payload || !payload.employee) {
            toast.error('Please choose staff');
        } else if (!payload.dayWork && !payload.month) {
            toast.error('Please choose month or day work');
        } else if (typeStaff && typeStaff === EMPLOYEES_TYPE_VALUE.WORKPERMIT && !payload.dailyMonthly) {
            toast.error('Please choose type work permit');
        } else {
            try {
                setLoading(true);
                if (!payload.dailyMonthly) {
                    payload.dailyMonthly = 'monthly';
                } else {
                }
                const { data } = await payRollAPI.createByCompany(payload);
                setReload((e) => e + 10);
                setRefetchPage(refetchPage + 1);
                if (data && data._id && data.employeesId) {
                    getHistories(data._id, data.employeesId);
                }
                setLoading(false);
                toast.success('Add pay-roll successfully');
                form.resetFields();
                setTypeStaff('');
                setPayload({});
                setTypeWork('');
                setVisible(false);
            } catch (error) {
                setLoading(false);
                setTypeStaff('');
                setTypeWork('');
                toast.error(getError(error));
                form.resetFields();
                setPayload({});
                setVisible(false);
            }
        }
    };
    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <Modal title="Add Salary" open={visible} onOk={handleOk} zIndex={999} onCancel={handleCancel} width="700px">
            <Form form={form} layout="vertical" name="form_in_modal">
                <Row>
                    <Col lg="12">
                        <div className=" mb-4">
                            <Form.Item name="staff" label="Staff">
                                <Space style={{ width: '100%' }} direction="vertical">
                                    <Select
                                        showSearch
                                        style={{ width: '100%', zIndex: 999 }}
                                        onChange={handleChangeStaff}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {staffList.map((item) => (
                                            <Select.Option value={item._id} label={item.firstName}>
                                                {item.firstName}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                    {/* <Select
                                        allowClear
                                        onChange={handleChangeStaff}
                                        optionLabelProp="label"
                                        style={{ width: '100%', zIndex: 999 }}
                                        placeholder="Please select employees of your company"
                                        maxTagCount="responsive"
                                    >
                                        {staffList.map((item) => (
                                            <Option value={item._id} label={item.lastName}>
                                                <Space>
                                                    {item.lastName} - {item.email}
                                                </Space>
                                            </Option>
                                        ))}
                                    </Select> */}
                                </Space>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                {typeStaff && typeStaff === EMPLOYEES_TYPE_VALUE.WORKPERMIT ? (
                    <>
                        <Row>
                            <Col lg="12">
                                <div className=" mb-4">
                                    <Form.Item name="dailyMonthly" label="Type Work">
                                        <Space style={{ width: '100%' }} direction="vertical">
                                            <Select
                                                style={{ width: '100%', zIndex: 999 }}
                                                onChange={handleChangeDailyMonthly}
                                            >
                                                <Select.Option value="daily">Daily</Select.Option>
                                                <Select.Option value="monthly">Monthly</Select.Option>
                                            </Select>
                                        </Space>
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                        {typeWork === '' ? (
                            <></>
                        ) : typeWork === 'daily' ? (
                            <Row>
                                <Col lg="12">
                                    <div className="mb-4">
                                        <Form.Item name="Month" label="Month">
                                            <DatePicker
                                                picker="month"
                                                format={'YYYY-MM'}
                                                style={{ width: '100%' }}
                                                id="Month"
                                                onChange={(_, dateString) => {
                                                    setPayload({ ...payload, month: new Date(dateString).getTime() });
                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            <Row>
                                <Col lg="12">
                                    <div className="mb-4">
                                        <Form.Item name="Month" label="Month">
                                            <DatePicker
                                                picker="month"
                                                format={'YYYY-MM'}
                                                style={{ width: '100%' }}
                                                id="Month"
                                                onChange={(_, dateString) => {
                                                    setPayload({ ...payload, month: new Date(dateString).getTime() });
                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </>
                ) : (
                    <>
                        <Row>
                            <Col lg="12">
                                <div className="mb-4">
                                    <Form.Item name="Month" label="Month">
                                        <DatePicker
                                            picker="month"
                                            format={'YYYY-MM'}
                                            style={{ width: '100%' }}
                                            id="Month"
                                            onChange={(_, dateString) => {
                                                setPayload({ ...payload, month: new Date(dateString).getTime() });
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                    </>
                )}
            </Form>
        </Modal>
    );
};

export default AddPayrollForm;
