import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'shards-react';
import PageTitle from './../components/common/PageTitle';
import ListDepartments from './../components/departments/ListDepartments';

import { AiFillPlusCircle } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import DepartmentCreateForm from '../components/departments/DepartmentCreateForm';
import { ROLE } from '../helpers/const';
import { useUser } from '../hooks';
import DepartmentProvider from '../providers/departmentProvider';

function Departments() {
    const [visible, setVisible] = useState(false);

    const { user, getMe } = useUser();
    const history = useHistory();
    const [isChange, setIsChange] = useState(false);
    const [add, setAdd] = useState(1);

    useEffect(() => {
        if (!user) {
            getMe();
        }
        user &&
            user.role &&
            user.role !== ROLE.COMPANY &&
            user.role !== ROLE.COMPANYSTAFF &&
            user.role !== ROLE.USER &&
            history.push('/login');
    }, [user]);

    const onCreate = (values) => {
        setVisible(false);
    };

    // window.onbeforeunload = function() {
    //     if (!localStorage.remember) {
    //         jwtManager.clear();
    //     } else {
    //         return;
    //     }
    // };

    return (
        <DepartmentProvider>
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4">
                    <Col lg="6">
                        <PageTitle
                            title="Departments"
                            subtitle={<span className="route-main">Departments</span>}
                            className="text-sm-left mb-3 "
                        />
                    </Col>
                    {user.role !== ROLE.USER && (
                        <Col lg="6" className="d-flex justify-content-end">
                            <div
                                className="text-center rounded p-2 d-flex justify-content-center align-items-center button__warning"
                                onClick={() => {
                                    setVisible(true);
                                }}
                            >
                                <AiFillPlusCircle size={25} />
                                <span className="ml-2">Add Department</span>
                            </div>

                            <DepartmentCreateForm
                                visible={visible}
                                onCreate={onCreate}
                                onCancel={() => {
                                    setVisible(false);
                                }}
                                isChange={isChange}
                                setIsChange={setIsChange}
                                setAdd={setAdd}
                            />
                        </Col>
                    )}
                </Row>
                <Row className="mt-2">
                    <Col lg="12" md="12" sm="12" className="mb-4">
                        <ListDepartments isChange={isChange} setIsChange={setIsChange} add={add} />
                    </Col>
                </Row>
            </Container>
        </DepartmentProvider>
    );
}

export default Departments;
