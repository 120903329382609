import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/index.css';
import { jwtManager } from './helpers/jwtManager';
import useLocalStorage from './hooks/useLocalStorage';
import { AuthContextProvider } from './providers/authProvider';
import LoadingProvider from './providers/loadingProvider';
import UserProvider from './providers/userProvider';
import routes from './routes';
import './shards-dashboard/styles/shards-dashboards.1.1.0.min.css';

export default () => {
    const [saved, setSaved] = useLocalStorage('saved', true);

    const remember = () => {
        setSaved(true);
        if (performance.navigation.type !== performance.navigation.TYPE_RELOAD && !localStorage.remember) {
            jwtManager.clear();
        }
    };

    useEffect(() => {
        window.addEventListener('beforeunload', remember);
        return () => {
            window.removeEventListener('beforeunload', remember);
        };
    });

    return (
        <LoadingProvider>
            <AuthContextProvider>
                <UserProvider>
                    <Router>
                        <div>
                            {routes.map((route, index) => {
                                return (
                                    <Route key={index} path={route.path} exact={route.exact}>
                                        <route.layout>
                                            <route.component />
                                        </route.layout>
                                    </Route>
                                );
                            })}
                            <ToastContainer />
                        </div>
                    </Router>
                </UserProvider>
            </AuthContextProvider>
        </LoadingProvider>
    );
};
