import axios from 'axios';

const path = 'leaves/';

const get = async (filter = {}) => {
    return await axios.get(path, {
        params: filter,
    });
};

const getById = async (id) => {
    return await axios.get(path + id);
};

const add = async (payload) => {
    return await axios.post(path, payload);
};

const update = async (id, payload) => {
    return await axios.patch(path + id, payload);
};

const _delete = async (id) => {
    return await axios.delete(path + id);
};

const statistic = async (filter) => {
    return await axios.get(path + 'statistic/', { params: filter });
};

const checkSpamHours = async (filter) => {
    return await axios.get(path + 'checkSpamHours/', { params: filter });
};

const statisticDashboard = async (filter) => {
    return await axios.get(path + 'statisticDashboard/', { params: filter });
};

const isLeave = async (from, to, employee) => {
    return await axios.get(path + from + '/' + to + '/' + employee);
};

const getAllForSummaryLeaves = async (filter) => {
    return await axios.get(path + 'findAllForSummaryLeaves', { params: filter });
};

export const leaveAPI = {
    get,
    getById,
    add,
    update,
    _delete,
    statistic,
    statisticDashboard,
    isLeave,
    checkSpamHours,
    getAllForSummaryLeaves,
};
