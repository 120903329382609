import { MinusCircleOutlined } from '@ant-design/icons';
import { DatePicker, Form, Input, Modal, Select, Space, Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'shards-react';
import { employeesAPI } from '../../apis/employeesAPI';
import { jobPositionAPI } from '../../apis/jobPositionAPI';
import { payRollAPI } from '../../apis/payRollAPI';
import { PAYROLL_CURENTCY } from '../../global/payroll';
import { FORMAT, ROLE } from '../../helpers/const';
import { getError } from '../../helpers/getErrorServer';
import { useUser } from '../../hooks';
import { useLoading } from '../../hooks';
const PayrollList = ({ onCreate, tab, setReloadPageDelete, reloadPageDelete }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [load, setReload] = useState(0);
    const [payRolls, setPayRolls] = useState();
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [size, setSize] = useState(0);
    const [name, setName] = useState('');
    const [role, setRole] = useState('');
    const [positions, setPositions] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [dataFrom, setDataForm] = useState([]);
    const [dataDelete, setDataDelete] = useState([]);
    const [date, setDate] = useState();
    const { user, getMe } = useUser();
    const { setLoading } = useLoading();
    const [form] = Form.useForm();
    const { type } = useParams();
    const { Option } = Select;
    const { RangePicker } = DatePicker;
    let history = useHistory();

    const SGDDollar = new Intl.NumberFormat('en-SG', { style: 'currency', currency: 'SGD' });

    useEffect(() => {
        (async () => {
            if (!user._id) {
                await getMe();
            }
            setLoading(true);
            if (user.role === ROLE.COMPANY) {
                const { data } = await payRollAPI.getByCompanyId(user.company._id, { type, page, limit: 10 });
                let rs = data;
                if (rs.items.length < 1 && page > 1) {
                    setPage((prev) => prev - 1);
                    const { data: tmp } = await payRollAPI.getByCompanyId(user.company._id, {
                        type,
                        page: page - 1,
                        limit: 10,
                    });
                    rs = tmp;
                }
                setPayRolls(rs.items);
                setPagination(rs);
            } else if (
                tab === 1 &&
                (user.role === ROLE.COMPANYSTAFF ||
                    (((user && user.position && user.position.payroll && user.position.payroll.includes('1')) ||
                        (user &&
                            user.employee &&
                            user.employee.controls &&
                            user.employee.controls.payroll &&
                            user.employee.controls.payroll.includes('1'))) &&
                        user &&
                        user.employee &&
                        user.employee.company &&
                        user.employee.company._id))
            ) {
                const { data } = await payRollAPI.getByCompanyId(user.employee.company._id, { type, page, limit: 10 });
                let rs = data;
                if (rs.items.length < 1 && page > 1) {
                    setPage((prev) => prev - 1);
                    const { data: tmp } = await await payRollAPI.getByCompanyId(user.employee.company._id, {
                        type,
                        page: page - 1,
                        limit: 10,
                    });
                    rs = tmp;
                }
                setPayRolls(rs.items);
                setPagination(rs);
            } else {
                if (user && user.employee && user.employee._id) {
                    const { data } = await payRollAPI.getByEmployeeId(user.employee._id, { type, page, limit: 10 });
                    let rs = data;
                    if (rs.items.length < 1 && page > 1) {
                        setPage((prev) => prev - 1);
                        const { data: tmp } = await payRollAPI.getByEmployeeId(user.employee._id, {
                            type,
                            page: page - 1,
                            limit: 10,
                        });
                        rs = tmp;
                    }
                    setPayRolls(rs.items);
                    setPagination(rs);
                }
            }
            setLoading(false);
            // if (
            //     user.role === ROLE.USER &&
            //     user &&
            //     user.position &&
            //     user.position.payroll &&
            //     !user.position.payroll.includes('1')
            // ) {
            //     const { data } = await payRollAPI.getByEmployeeId(user.employee._id, { type, page, limit: 10 });
            //     setPayRolls(data.items);
            //     setPagination(data);
            // }
        })();
    }, [onCreate, type, page, user, load, reloadPageDelete]);

    useEffect(() => {
        setReload((e) => e + 1);
    }, [onCreate]);

    useEffect(() => {
        (async () => {
            if (Object.keys(user).length === 0) await getMe();
            if (user.company && user.company._id) {
                const { data } = await jobPositionAPI.getAllById(user.company._id);
                setPositions(data);
            }
            fetchEmployees(user);
        })();
    }, [user, onCreate, reloadPageDelete]);

    const setPagination = (data) => {
        setTotal(data.paginate.count);
        setPage(data.paginate.page);
        setSize(data.paginate.size);
    };

    // Modal Edit
    const showModal = (data) => {
        form.setFieldsValue({
            effectiveDate: moment(new Date(data.effectiveDate), FORMAT.DATE),
            employeesId: data.employee._id,
            earning: data.earning,
            deductions: data.deductions,
        });
        setDataForm(data);
        setIsModalVisible(true);
    };

    const onCancel = () => {
        setIsModalVisible(false);
        setDataForm([]);
    };

    //    Modal Delete
    const showModalDelete = (data) => {
        setDataDelete(data);
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        if (dataDelete) {
            await payRollAPI._delete(dataDelete._id);
            history.push(`/payroll/${type}`);
            // onChangePage(1);
            setReloadPageDelete((e) => e + 1);
            setIsModalOpen(false);
            toast.success('Success');
        }
    };

    const handleCancel = () => {
        setDataForm([]);
        setIsModalOpen(false);
    };

    //

    const initColumns = [
        {
            title: ' ID',
            dataIndex: 'key',
            key: 'id',
            render: (_, record) => record.employee.employeesId,
        },
        {
            title: ' Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => record.employee.firstName,
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render: (_, record) => record.employee.jobPosition,
        },
        {
            title: 'E-Mail',
            dataIndex: 'email',
            key: 'email',
            render: (_, record) => record.employee.email,
        },
        {
            title: 'Month',
            dataIndex: 'TheMonthPaySlip',
            key: 'TheMonthPaySlip',
            render: (_, record) => (record.month ? moment(record.month).format('MMM YY') : '--'),
        },
        {
            title: 'Salary',
            dataIndex: 'salary',
            key: 'salary',
            render: (_, record) => {
                // let earning = 0;
                // let deductions = 0;

                // record.earning.map((item) => {
                //     earning += item.amount * 1;
                // });
                // record.deductions.map((item) => {
                //     deductions += item.amount * 1;
                // });
                return (
                    record.summary.netPay &&
                    record.summary.netPay &&
                    record.summary.netPay &&
                    SGDDollar.format(record.summary.netPay)
                );
            },
        },

        {
            title: 'Payslip',
            dataIndex: 'paySlip',
            key: 'paySlip',
            render: (_, record) => {
                if (record.paySlip === 'genarate-slip') {
                    return <Link to={`/pay-slip/${record._id}`}>{record.paySlip}</Link>;
                }
            },
        },

        // {
        //     title: 'Payment Voucher',
        //     dataIndex: 'voucher',
        //     key: 'voucher',
        //     render: (_, record) => {
        //         return (
        //             <Link to={`/voucher/${record._id}`}>
        //                 <p>voucher</p>
        //             </Link>
        //         );
        //     },
        // },
    ];

    const managerColumns = [
        ...initColumns,
        {
            title: 'Actions',
            key: 'action',
            fixed: 'right',

            render: (_, record) => (
                <Space size="middle">
                    {(user.role === ROLE.ADMIN ||
                        user.role === ROLE.COMPANY ||
                        user.role === ROLE.SYSTEMSTAFF ||
                        user.role === ROLE.COMPANYSTAFF ||
                        (user && user.position && user.position.payroll && user.position.payroll.includes('2'))) && (
                        <span className="material-icons icon_edit" onClick={() => showModal(record)}>
                            edit
                        </span>
                    )}

                    {(user.role === ROLE.ADMIN ||
                        user.role === ROLE.COMPANY ||
                        user.role === ROLE.SYSTEMSTAFF ||
                        (user && user.position && user.position.payroll && user.position.payroll.includes('4'))) && (
                        <span className="material-icons icon_delete" onClick={() => showModalDelete(record)}>
                            delete_forever
                        </span>
                    )}
                </Space>
            ),
        },
    ];
    const [columns, setColumns] = useState(initColumns);

    useEffect(() => {
        if (
            (user.role && user.role !== ROLE.USER) ||
            (user && user.position && user.position.payroll && user.position.payroll.length > 0)
        ) {
            setColumns(managerColumns);
        }
    }, [user, onCreate, reloadPageDelete]);

    const submitSearch = async () => {
        let from = date ? moment(date[0]).format('YYYY-MM-DD') : undefined;
        let to = date ? moment(date[1]).format('YYYY-MM-DD') : undefined;
        setLoading(true);
        if (user.role === ROLE.ADMIN) {
            const { data } = await payRollAPI.get({ from, to, role, search: name, type, page, limit: 5 });
            setPayRolls(data.items);
            setTotal(data.paginate.count);
            setSize(data.paginate.size);
        }
        if (user.role === ROLE.COMPANY) {
            const { data } = await payRollAPI.getByCompanyId(user.company._id, {
                search: name,
                from,
                to,
                role,
                type,
                page,
                limit: 10,
            });
            setPayRolls(data.items);
            setTotal(data.paginate.count);
            setSize(data.paginate.size);
        }
        if (
            user.role === ROLE.COMPANYSTAFF ||
            (user && user.position && user.position.payroll && user.position.payroll.includes('1'))
        ) {
            const { data } = await payRollAPI.getByCompanyId(user.employee.company._id, {
                search: name,
                from,
                to,
                role,
                type,
                page,
                limit: 10,
            });
            setPayRolls(data.items);
            setTotal(data.paginate.count);
            setSize(data.paginate.size);
        }
        setLoading(false);
    };

    const fetchEmployees = async (user) => {
        try {
            let tmpUser = user;
            if (!tmpUser) tmpUser = await getMe();
            if (tmpUser.role === ROLE.COMPANY) {
                const { data } = await employeesAPI.getByCompany(tmpUser.company._id);
                setEmployees(data.items);
            }
            if (
                tmpUser.role === ROLE.COMPANYSTAFF ||
                (tmpUser && tmpUser.position && tmpUser.position.payroll && tmpUser.position.payroll.includes('2'))
            ) {
                const { data } = await employeesAPI.getByCompany(tmpUser.employee.company._id);
                setEmployees(data.items);
            }
            if (tmpUser.role === ROLE.ADMIN) {
                const { data } = await employeesAPI.get();
                setEmployees(data.items);
            }
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const onSubmitFrom = async (value) => {
        try {
            if (dataFrom) {
                value.summary = dataFrom.summary;
                value.employeeType = dataFrom.employee.type;
                await payRollAPI.update(dataFrom._id, value);
                toast.success('Edit pay-roll successfully');
                form.resetFields();
                setReload((e) => e + 1);
                onCancel();
            }
        } catch (error) {
            toast.error(getError(error));
        }
    };

    const onchange = (value, key) => {
        if (value && key) {
            const fields = form.getFieldsValue();

            const { projects } = fields;

            if (projects) {
                Object.assign(projects[key], { type: value });
                form.setFieldsValue({ projects });
            }
        }
    };

    const onChangePage = (page) => {
        setPage(page);
    };

    return (
        <>
            <div>
                <Row form className="mt-1">
                    {user && user.role && user.role !== ROLE.USER && (
                        <>
                            <Col lg="3" md="3" className="form-group">
                                <Input placeholder="Search by name" onChange={(e) => setName(e.target.value)} />
                            </Col>
                            <Col md="3" className="form-group">
                                <Select style={{ width: '100%' }} onChange={(value) => setRole(value)}>
                                    {positions &&
                                        positions.map((item) => (
                                            <Option key={item._id} value={item.jobPositionName}>
                                                {item.jobPositionName}
                                            </Option>
                                        ))}
                                </Select>
                            </Col>
                        </>
                    )}
                    <Col md="3" className="form-group">
                        <RangePicker style={{ width: '100%' }} onChange={(date) => setDate(date)} />
                    </Col>
                    <Col md="3" className="d-flex justify-content-end align-items-start">
                        {/* <Button type="submit" className=" btn-warning px-5" >
                            Search
                        </Button> */}
                        <button type="submit" className="button__search" onClick={() => submitSearch()}>
                            Search
                        </button>
                    </Col>
                </Row>
            </div>
            <Table
                className="ct-table-data"
                columns={columns}
                dataSource={payRolls}
                pagination={{
                    current: page,
                    onChange: onChangePage,
                    pageSize: size,
                    total,
                }}
            />

            <Modal
                open={isModalVisible}
                title="Salary"
                okText="Submit"
                cancelText="Cancel"
                onCancel={onCancel}
                zIndex={998}
                width="700px"
                height="500px"
                onOk={form.submit}
            >
                <Form form={form} layout="vertical" name="form_in_modal" onFinish={onSubmitFrom}>
                    {dataFrom && (
                        <>
                            <Row lg="12" md="8" sm="6">
                                <Col lg="6">
                                    <Form.Item
                                        name="effectiveDate"
                                        label="Effective date"
                                        // initialValue={
                                        //     dataFrom &&
                                        //     dataFrom.effectiveDate &&
                                        //     moment(new Date(dataFrom.effectiveDate), FORMAT.DATE)
                                        // }
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input the name of collection!',
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            format={FORMAT.DATE}
                                            style={{ width: '100%' }}
                                            disabled={true}
                                            // defaultValue={
                                            //     dataFrom &&
                                            //     dataFrom.effectiveDate &&
                                            //     moment(new Date(dataFrom.effectiveDate), FORMAT.DATE)
                                            // }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg="6">
                                    <Form.Item
                                        name="employeesId"
                                        label="Select staff"
                                        rules={[
                                            {
                                                required: true,
                                                min: 0,
                                                message: 'Please select employee!',
                                            },
                                        ]}
                                    >
                                        <Select
                                            style={{ width: '100%' }}
                                            showSearch
                                            disabled={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().includes(input.toLowerCase())
                                            }
                                        >
                                            {employees.map((item) => (
                                                <Option value={item._id} key={item._id}>
                                                    {item.firstName}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row lg="12" md="8" sm="6">
                                <Col lg="6">
                                    <Form.Item
                                        name="currency"
                                        label="Currency"
                                        initialValue={'sgd'}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select currency!',
                                            },
                                        ]}
                                    >
                                        <Select style={{ width: '100%' }} disabled={true}>
                                            {PAYROLL_CURENTCY.map((item) => (
                                                <Option value={'sgd'} key={item.value}>
                                                    {'SGD'}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <hr />
                            <Row lg="12" md="8" sm="6">
                                <Col lg="6" md="12">
                                    <h6>Earnings</h6>

                                    <Form.List name="earning">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map((field) => (
                                                    <Space key={field.key} align="baseline">
                                                        <Form.Item
                                                            noStyle
                                                            shouldUpdate={(prevValues, curValues) =>
                                                                prevValues.area !== curValues.area ||
                                                                prevValues.sights !== curValues.sights
                                                            }
                                                        >
                                                            {() => (
                                                                <Form.Item
                                                                    {...field}
                                                                    label="Title"
                                                                    name={[field.name, 'title']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please enter title',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        onChange={(e) => onchange(e, field.key)}
                                                                        disabled={field.key === 0}
                                                                    />
                                                                </Form.Item>
                                                            )}
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...field}
                                                            label="Amount"
                                                            name={[field.name, 'amount']}
                                                            rules={[{ required: true, message: 'Please enter amount' }]}
                                                        >
                                                            <Input disabled={field.key === 0} />
                                                        </Form.Item>

                                                        {field.key !== 0 && (
                                                            <MinusCircleOutlined onClick={() => remove(field.name)} />
                                                        )}
                                                    </Space>
                                                ))}
                                                <Form.Item>
                                                    <div
                                                        className="d-flex justify-content-start align-items-center"
                                                        onClick={() => add()}
                                                    >
                                                        <span className="material-icons">add_circle_outline</span>
                                                        <p className="p-0 m-0">Other</p>
                                                    </div>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Col>
                                <Col lg="6">
                                    <h6>Deductions</h6>
                                    <Form.List name="deductions">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map((field) => (
                                                    <Space key={field.key} align="baseline">
                                                        <Form.Item
                                                            noStyle
                                                            shouldUpdate={(prevValues, curValues) =>
                                                                prevValues.area !== curValues.area ||
                                                                prevValues.sights !== curValues.sights
                                                            }
                                                        >
                                                            {() => (
                                                                <Form.Item
                                                                    {...field}
                                                                    label="Title"
                                                                    name={[field.name, 'title']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please enter title!',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input disabled={field.key === 0} />
                                                                </Form.Item>
                                                            )}
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...field}
                                                            label="Amount"
                                                            name={[field.name, 'amount']}
                                                            rules={[{ required: true, message: 'Please enter amount' }]}
                                                        >
                                                            <Input disabled={field.key === 0} />
                                                        </Form.Item>

                                                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                                                    </Space>
                                                ))}
                                                <Form.Item>
                                                    <div
                                                        className="d-flex justify-content-start align-items-center"
                                                        onClick={() => add()}
                                                    >
                                                        <span className="material-icons">add_circle_outline</span>
                                                        <p className="p-0 m-0">Other</p>
                                                    </div>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Col>
                            </Row>
                            <hr />
                            <Row lg="12" md="8" sm="6">
                                <Col lg="12" md="12" sm="12">
                                    {dataFrom && dataFrom.cdfEmployer && (
                                        <div>CDF payable by employer: {SGDDollar.format(dataFrom.cdfEmployer)}</div>
                                    )}

                                    <div>
                                        SDL payable by employer:{' '}
                                        {dataFrom.summary &&
                                            dataFrom.summary.employerSDL &&
                                            SGDDollar.format(dataFrom.summary.employerSDL)}
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}
                </Form>
            </Modal>

            <Modal
                open={isModalOpen}
                title={
                    <>
                        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.4167 0.833344H2.58333C1.56583 0.833344 0.75 1.65834 0.75 2.66668V15.5C0.75 16.5083 1.56583 17.3333 2.58333 17.3333H6.25L9 20.0833L11.75 17.3333H15.4167C16.425 17.3333 17.25 16.5083 17.25 15.5V2.66668C17.25 1.65834 16.425 0.833344 15.4167 0.833344ZM9.91667 15.5H8.08333V13.6667H9.91667V15.5ZM11.8142 8.39584L10.9892 9.23918C10.3292 9.90834 9.91667 10.4583 9.91667 11.8333H8.08333V11.375C8.08333 10.3667 8.49583 9.45001 9.15583 8.78084L10.2925 7.62584C10.6317 7.29584 10.8333 6.83751 10.8333 6.33334C10.8333 5.32501 10.0083 4.50001 9 4.50001C7.99167 4.50001 7.16667 5.32501 7.16667 6.33334H5.33333C5.33333 4.30751 6.97417 2.66668 9 2.66668C11.0258 2.66668 12.6667 4.30751 12.6667 6.33334C12.6667 7.14001 12.3367 7.87334 11.8142 8.39584Z"
                                fill="#FCBB0A"
                            />
                        </svg>

                        <p className="title-modal-custom">Are you sure delete this salary?</p>
                    </>
                }
                okText="Delete"
                cancelText="Cancel"
                onCancel={handleCancel}
                zIndex={998}
                width="450px"
                onOk={handleOk}
            >
                <p>Deleted data cannot be recovered? Do you want to continue deleting?</p>
            </Modal>
        </>
    );
};

export default PayrollList;
