import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { leaveAPI } from '../apis/leaveAPI';
import { LeaveContext } from '../contexts/leaveContext';
import { LEAVE_FILTER, LEAVE_STATUS } from '../global/leave';
import { ROLE, VISIBLEEMPLOYEELEAVE } from '../helpers/const';
import { getError } from '../helpers/getErrorServer';
import { RESPONSE } from '../helpers/response';
import { useLoading, useUser } from '../hooks';

export default function LeaveProvider({ children }) {
    const { user, getMe } = useUser();
    const { setLoading } = useLoading();
    const [leaves, setLeaves] = useState(RESPONSE);
    const [leavesPending, setLeavesPending] = useState(RESPONSE);
    const [filterLeave, setFilterLeave] = useState(LEAVE_FILTER);

    const getFilter = async (filter, user) => {
        if (!user._id) {
            user = await getMe();
        }

        if (user.role === ROLE.COMPANY) {
            return {
                ...filter,
                company: user && user.company && user.company._id ? user.company._id : '',
            };
        } else if (
            user.role === ROLE.COMPANYSTAFF ||
            (user && user.position && user.position.leave && user.position.leave.includes('1')) ||
            (user && user.employee && user.employee.isApproverLeave) ||
            (user &&
                user.employee &&
                user.employee.controls &&
                user.employee.controls.leave &&
                user.employee.controls.leave.includes('1')) ||
            (user.employee &&
                user.employee.company &&
                user.employee.company.visibleEmployeeLeave &&
                user.employee.company.visibleEmployeeLeave === VISIBLEEMPLOYEELEAVE.ALL)
        ) {
            return {
                ...filter,
                company:
                    user && user.employee && user.employee.company && user.employee.company._id
                        ? user.employee.company._id
                        : '',
            };
        } else if (
            user.role === ROLE.USER &&
            user.employee &&
            user.employee.company &&
            user.employee.company.visibleEmployeeLeave &&
            user.employee.company.visibleEmployeeLeave === VISIBLEEMPLOYEELEAVE.DEPARTMENT &&
            user.employee.department
        ) {
            return {
                ...filter,
                department: user && user.employee && user.employee.department ? user.employee.department : '',
            };
        } else if (
            user.role === ROLE.USER &&
            user.employee &&
            user.employee.company &&
            user.employee.company.visibleEmployeeLeave &&
            user.employee.company.visibleEmployeeLeave === VISIBLEEMPLOYEELEAVE.BRANCH &&
            user.employee.placement &&
            user.employee.placement.branch
        ) {
            return {
                ...filter,
                branch:
                    user && user.employee && user.employee.placement && user.employee.placement.branch
                        ? user.employee.placement.branch
                        : '',
            };
        } else if (
            user.role === ROLE.USER &&
            user.employee &&
            user.employee.company &&
            user.employee.company.visibleEmployeeLeave &&
            user.employee.company.visibleEmployeeLeave === VISIBLEEMPLOYEELEAVE.DEPARTMENTBRACH &&
            (user.employee.department || (user.employee.placement && user.employee.placement.branch))
        ) {
            return {
                ...filter,
                departmentBranch:
                    user && user.employee
                        ? ((user && user.employee && user.employee.department) || '') +
                          'and' +
                          ((user && user.employee && user.employee.placement && user.employee.placement.branch) || '')
                        : '',
            };
        } else {
            return {
                ...filter,
                employee: user && user.employee && user.employee._id ? user.employee._id : '',
            };
        }
    };

    const fetchLeaves = async (filter = LEAVE_FILTER) => {
        try {
            filter = {
                ...LEAVE_FILTER,
                ...filter,
            };
            filter = await getFilter(filter, user);

            setLoading(true);
            const { data } = await leaveAPI.get(filter);
            if (filter.status === LEAVE_STATUS.PENDING) {
                setLeavesPending(data);
            }

            setLeaves(data);
            setFilterLeave(filter);
            setLoading(false);
        } catch (error) {
            toast(getError(error));
            setLoading(false);
            console.log(error);
        }
    };

    const getLeaveById = (id) => {
        return new Promise((resolve, reject) => {
            leaveAPI
                .getById(id)
                .then((rs) => {
                    resolve(rs.data);
                })
                .catch((er) => reject(er));
        });
    };

    return (
        <LeaveContext.Provider
            value={{
                leaves,
                fetchLeaves,
                leavesPending,
                filterLeave,
                setFilterLeave,
                getLeaveById,
            }}
        >
            {children}
        </LeaveContext.Provider>
    );
}
