import React, { useEffect, useState } from 'react';
import { employeesAPI } from '../apis/employeesAPI';
import { EmployeeContext } from '../contexts/employeeContext';
import { ROLE } from '../helpers/const';
import { RESPONSE } from '../helpers/response';
import { useUser } from '../hooks';

export default function EmployeeProvider({ children }) {
    const { user } = useUser();
    const [employees, setEmployees] = useState(RESPONSE);

    useEffect(() => {
        (() => {
            fetchEmployee();
        })();
    }, [user]);

    // const fetchEmployee = async () => {
    //     if (user.role === ROLE.ADMIN) {
    //         const { data } = await employeesAPI.get({});
    //         setEmployees(data);
    //     }
    //     if (user.role === ROLE.COMPANY) {
    //         const { data } = await employeesAPI.getByCompany(user.company._id, {});
    //         setEmployees(data);
    //     }
    //     if (user.role === ROLE.COMPANYSTAFF) {
    //         const { data } = await employeesAPI.getByCompany(user.employee.company._id, {});
    //         setEmployees(data);
    //     }
    // };

    const fetchEmployee = async () => {
        try {
            if (user.role === ROLE.ADMIN) {
                const { data } = await employeesAPI.get({});
                setEmployees(data);
            }
            if (user.role === ROLE.COMPANY) {
                const { data } = await employeesAPI.getByCompany(user.company._id, {});
                setEmployees(data);
            }
            if (user.role === ROLE.COMPANYSTAFF) {
                const { data } = await employeesAPI.getByCompany(user.employee.company._id, {});
                setEmployees(data);
            }
        } catch (error) {
            console.log(error);
        }
    };
    return <EmployeeContext.Provider value={{ employees }}>{children}</EmployeeContext.Provider>;
}
