import React from 'react';
import PropTypes from 'prop-types';

// import { Link } from "react-router-dom";

import FormCompaines from './FormCompaines';

import {
    Card,
    CardHeader,
    CardBody,

    // CardFooter
} from 'shards-react';

class ListCompanies extends React.Component {
    constructor(props) {
        super(props);

        this.canvasRef = React.createRef();
    }

    render() {
        const { title } = this.props;
        return (
            <Card small className="h-100">
                <CardHeader className="border-bottom">
                    <h6 className="m-0">{title}</h6>
                </CardHeader>

                <CardBody className="col-xl-12 col-lg-12">
                    <FormCompaines />
                </CardBody>
            </Card>
        );
    }
}

ListCompanies.propTypes = {
    /**
     * The component's title.
     */
    title: PropTypes.string,
};

ListCompanies.defaultProps = {
    title: 'List of company',
};

export default ListCompanies;
