export const PAYROLL_CURENTCY = [
    {
        label: 'SGD',
        value: 'sgd',
    },
    {
        label: 'USD',
        value: 'usd',
    },
    {
        label: 'EUR',
        value: 'eur',
    },
];
