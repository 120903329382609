import React, { useEffect, useState } from 'react';
import PageTitle from './../common/PageTitle';
import {
    Card,
    CardHeader,
    CardBody,
    Form,
    FormInput,
    FormGroup,
    Button,
    Row,
    Col,
    Container,
    // CardFooter
} from 'shards-react';

import { useLoading, useUser } from '../../hooks';
import { ROLE } from '../../helpers/const';
import { companiesAPI } from '../../apis/companiesAPI';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Select } from 'antd';
import { countriesAPI } from '../../apis/countriesAPI';
import { getError } from '../../helpers/getErrorServer';

export default function Infomation() {
    const { user, getMe } = useUser();
    const [data, setData] = useState();
    const history = useHistory();
    const [countries, setCountries] = useState([]);
    const [codeCountry, setCodeCountry] = useState();
    const [state, setState] = useState([]);
    const { setLoading } = useLoading();

    useEffect(() => {
        if (!user) {
            getMe();
        }
        user &&
            user.role &&
            user.role !== ROLE.COMPANY &&
            user.role !== ROLE.COMPANYSTAFF &&
            user.role !== ROLE.USER &&
            history.push('/login');
    }, [user]);

    useEffect(() => {
        (async () => {
            const { data } = await countriesAPI.getCountries();
            setCountries(data);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const { data } = await countriesAPI.getStates(codeCountry);
            setState(data);
        })();
    }, [codeCountry]);

    useEffect(() => {
        getCompany();
    }, [user]);

    const getCompany = async () => {
        if (!user) {
            getMe();
        }
        if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
            const { data } = await companiesAPI.getById(user.company._id);
            setData(data);
            setCodeCountry(data.country);
        }
        if (
            user &&
            user.role &&
            (user.role === ROLE.COMPANYSTAFF || user.role === ROLE.USER) &&
            user.employee &&
            user.employee.company &&
            user.employee.company._id
        ) {
            const { data } = await companiesAPI.getById(user.employee.company._id);
            setData(data);
            setCodeCountry(data.country);
        }
    };

    const handleClick = async () => {
        try {
            setLoading(true);
            if (data.companyName === '') {
                toast.error('Company name should not be empty!');
            } else if (data.contactPerson === '') {
                toast.error('Contact person should not be empty!');
            } else if (data.email.length < 10) {
                toast.error('Email must be greater than or equal to 10!');
            }
            await companiesAPI.update(data._id, data);
            setLoading(false);
            toast.success('Update success!');
            history.push('/employers/infomation');
        } catch (error) {
            setLoading(false);
            toast.error(getError(error));
        }
    };

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle
                    title="Company information"
                    subtitle={
                        <>
                            <span className="route-extra">Company settings / </span>
                            <span className="route-main">Company information settings</span>
                        </>
                    }
                    className="text-sm-left mb-3"
                />
            </Row>
            <Row className="mt-2 w-100" lg="12">
                <Card className="h-100 w-100">
                    <CardHeader className="border-bottom">
                        <span className="ct-title">Company information settings</span>
                    </CardHeader>

                    <CardBody className="col-xl-12 col-lg-12">
                        <Row>
                            <Col lg="12" md="12">
                                <Form>
                                    <Row form>
                                        <Col md="6" className="form-group">
                                            <label htmlFor="feCompanieName" className="font-weight-bold text-black">
                                                Company Name
                                            </label>
                                            <FormInput
                                                id="feCompanieName"
                                                type="text"
                                                placeholder="Dreamguy's Technologies"
                                                onChange={(e) => {
                                                    if (
                                                        user &&
                                                        user.role &&
                                                        (user.role === ROLE.USER || user.role === ROLE.COMPANYSTAFF)
                                                    ) {
                                                        toast.error('You are not authorized to update');
                                                        return;
                                                    }
                                                    if (user && user.role && user.role === ROLE.COMPANY) {
                                                        setData({ ...data, companyName: e.target.value });
                                                    }
                                                }}
                                                value={data && data.companyName && data.companyName}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: `Please Input company information settings`,
                                                    },
                                                ]}
                                            />
                                        </Col>
                                        <Col md="6">
                                            <label htmlFor="feContactPerson" className="font-weight-bold text-black">
                                                Contact Person
                                            </label>
                                            <FormInput
                                                id="feContactPerson"
                                                type="text"
                                                placeholder="Daniel Porter"
                                                onChange={(e) => setData({ ...data, contactPerson: e.target.value })}
                                                value={data && data.contactPerson && data.contactPerson}
                                            />
                                        </Col>
                                    </Row>

                                    <FormGroup className="mt-4">
                                        <label htmlFor="feInputAddress" className="font-weight-bold text-black">
                                            Address
                                        </label>
                                        <FormInput
                                            id="feInputAddress"
                                            placeholder="3864 Quiet Valley Lane, Sherman Oaks, CA, 91403"
                                            onChange={(e) => setData({ ...data, address: e.target.value })}
                                            value={data && data.address && data.address}
                                        />
                                    </FormGroup>

                                    <Row form className="mt-4">
                                        <Col md="3" className="form-group">
                                            <label htmlFor="feCountry" className="font-weight-bold text-black">
                                                Country
                                            </label>
                                            {/* <FormInput
                                                id="feCountry"
                                                type="text"
                                                placeholder="Singapore"
                                                onChange={(e) => setData({ ...data, country: e.target.value })}
                                                value={data && data.country && data.country}
                                            /> */}
                                            <Select
                                                showSearch
                                                style={{ width: '100%' }}
                                                value={data && data.country && data.country}
                                                onChange={(value) => {
                                                    setData({ ...data, country: value, state: '' });
                                                    setCodeCountry(value);
                                                }}
                                                id="feCountry"
                                                placeholder="Search to Select"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().includes(input.toLowerCase())
                                                }
                                            >
                                                {countries &&
                                                    countries.map((item, idx) => (
                                                        <Select.Option key={item._id} value={item.iso2}>
                                                            {item.name}
                                                        </Select.Option>
                                                    ))}
                                            </Select>
                                        </Col>
                                        <Col md="3" className="form-group">
                                            <label htmlFor="feCity" className="font-weight-bold text-black">
                                                City
                                            </label>
                                            <FormInput
                                                id="feCity"
                                                type="text"
                                                placeholder="Sherman Oaks"
                                                onChange={(e) => setData({ ...data, city: e.target.value })}
                                                value={data && data.city && data.city}
                                            />
                                        </Col>
                                        <Col md="3" className="form-group">
                                            <label htmlFor="feState" className="font-weight-bold text-black">
                                                State/Province
                                            </label>
                                            {/* <FormInput
                                                id="feState"
                                                type="text"
                                                placeholder="California"
                                                onChange={(e) => setData({ ...data, state: e.target.value })}
                                                value={data && data.state && data.state}
                                            /> */}
                                            <Select
                                                showSearch
                                                style={{ width: '100%' }}
                                                value={data && data.state && data.state}
                                                defaultValue={data && data.state && data.state}
                                                onChange={(value) => setData({ ...data, state: value })}
                                                id="feState"
                                                placeholder="Search to Select"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().includes(input.toLowerCase())
                                                }
                                            >
                                                {state &&
                                                    state.map((item, idx) => (
                                                        <Select.Option key={item._id} value={item.iso2}>
                                                            {item.name}
                                                        </Select.Option>
                                                    ))}
                                            </Select>
                                        </Col>
                                        <Col md="3" className="form-group">
                                            <label htmlFor="fePostal" className="font-weight-bold text-black">
                                                Postal code
                                            </label>
                                            <FormInput
                                                id="fePostal"
                                                type="text"
                                                placeholder="91403"
                                                onChange={(e) => setData({ ...data, postalCode: e.target.value })}
                                                value={data && data.postalCode && data.postalCode}
                                            />
                                        </Col>
                                    </Row>

                                    <Row form className="mt-4">
                                        <Col md="6" className="form-group">
                                            <label htmlFor="feEmailAddress" className="font-weight-bold text-black">
                                                Email
                                            </label>
                                            <FormInput
                                                id="feEmailAddress"
                                                type="email"
                                                placeholder="Danielporter@example.com"
                                                value={data && data.email && data.email}
                                                disabled={true}
                                            />
                                        </Col>
                                        <Col md="6" className="form-group">
                                            <label htmlFor="fePhone" className="font-weight-bold text-black">
                                                Phone Number
                                            </label>
                                            <FormInput
                                                id="fePhone"
                                                type="text"
                                                placeholder="818-978-7102"
                                                onChange={(e) => setData({ ...data, phoneNumber: e.target.value })}
                                                value={data && data.phoneNumber && data.phoneNumber}
                                            />
                                        </Col>
                                        <Col md="6" className="form-group mt-4">
                                            <label htmlFor="feMobile" className="font-weight-bold text-black">
                                                Mobile Number
                                            </label>
                                            <FormInput
                                                id="feMobile"
                                                type="text"
                                                placeholder="818-635-5579"
                                                onChange={(e) => setData({ ...data, mobileNumber: e.target.value })}
                                                value={data && data.mobileNumber && data.mobileNumber}
                                            />
                                        </Col>
                                        <Col md="6" className="form-group mt-4">
                                            <label htmlFor="feFax" className="font-weight-bold text-black">
                                                Fax
                                            </label>
                                            <FormInput
                                                id="feFax"
                                                type="text"
                                                placeholder="818-635-5579"
                                                onChange={(e) => setData({ ...data, fax: e.target.value })}
                                                value={data && data.fax && data.fax}
                                            />
                                        </Col>
                                    </Row>

                                    <FormGroup className="mt-4">
                                        <label htmlFor="feInputUrl" className="font-weight-bold text-black">
                                            Website Url
                                        </label>
                                        <FormInput
                                            id="feInputUrl"
                                            placeholder="https://www.example.com"
                                            onChange={(e) => setData({ ...data, websiteUrl: e.target.value })}
                                            value={data && data.websiteUrl && data.websiteUrl}
                                        />
                                    </FormGroup>

                                    {user && user.role && user.role === ROLE.COMPANY && (
                                        <Row className="d-flex justify-content-center align-items-center mt-5">
                                            <div
                                                onClick={handleClick}
                                                className="text-center btn-save-attendanceSetting rounded py-2 px-4 d-flex justify-content-center align-items-center button__warning"
                                            >
                                                <span className="text">Save</span>
                                            </div>
                                        </Row>
                                    )}
                                </Form>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Row>
        </Container>
    );
}
