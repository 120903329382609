import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, NavbarBrand } from 'shards-react';

import { Dispatcher, Constants } from '../../../flux';

class SidebarMainNavbar extends React.Component {
    constructor(props) {
        super(props);

        this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
    }

    handleToggleSidebar() {
        Dispatcher.dispatch({
            actionType: Constants.TOGGLE_SIDEBAR,
        });
    }

    render() {
        const { hideLogoText } = this.props;
        return (
            <div
                style={{
                    backgroundColor: '#fff',
                    zIndex: 2,
                    position: 'relative',
                }}
            >
                <div
                    className="main-navbar"
                    style={{
                        borderRadius: '0 24px 0 0 ',
                    }}
                >
                    <Navbar className="align-items-stretch flex-md-nowrap p-0 " type="light">
                        <NavbarBrand className="w-100 mr-0" href="#" style={{ lineHeight: '25px' }}>
                            <div className="d-table m-auto">
                                {!hideLogoText && (
                                    <span className="d-none d-md-inline ml-1 ct_name-logo">Logo here</span>
                                )}
                            </div>
                        </NavbarBrand>
                        {/* eslint-disable-next-line */}
                        <a
                            className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
                            onClick={this.handleToggleSidebar}
                        >
                            <i className="material-icons">&#xE5C4;</i>
                        </a>
                    </Navbar>
                </div>
            </div>
        );
    }
}

SidebarMainNavbar.propTypes = {
    /**
     * Whether to hide the logo text, or not.
     */
    hideLogoText: PropTypes.bool,
};

SidebarMainNavbar.defaultProps = {
    hideLogoText: false,
};

export default SidebarMainNavbar;
