import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Col, Container, Row } from 'shards-react';
import { leaveAPI } from '../apis/leaveAPI';
import LeaveCreateForm from '../components/leave/leaveCreateForm';
import { FORM_TYPE, ROLE } from '../helpers/const';
import { useLeave, useUser } from '../hooks';
import LeaveProvider from '../providers/leaveProvider';
import PageTitle from './../components/common/PageTitle';
import TabLeave from './../components/leave/TabLeave';

import { AiFillPlusCircle } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';

function Leave() {
    const [visible, setVisible] = useState(false);
    const { leaves } = useLeave();
    const [statistic, setStatistic] = useState();
    const { user, getMe } = useUser();
    const [render, setRender] = useState(0);
    const [pending, setPending] = useState(0);
    const history = useHistory();

    useEffect(() => {
        if (!user) {
            getMe();
        }
        user &&
            user.role &&
            user.role !== ROLE.COMPANY &&
            user.role !== ROLE.COMPANYSTAFF &&
            user.role !== ROLE.USER &&
            history.push('/login');
    }, [user]);
    useEffect(() => {
        loadStatistic();
    }, [user, leaves, render]);

    const loadStatistic = async () => {
        try {
            if (!user) {
                await getMe();
            }

            if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
                const { data } = await leaveAPI.statistic({ role: user.role, id: user.company._id });
                setStatistic(data);
            } else if (
                (user &&
                    user.role &&
                    user.role === ROLE.COMPANYSTAFF &&
                    user.employee &&
                    user.employee.company &&
                    user.employee.company._id) ||
                (user && user.position && user.position.leave && user.position.leave.length > 0) ||
                (user &&
                    user.employee &&
                    user.employee.controls &&
                    user.employee.controls.leave &&
                    user.employee.controls.leave.length > 0) ||
                (user && user.employee && user.employee.isApproverLeave)
            ) {
                const { data } = await leaveAPI.statistic({ role: user.role, id: user.employee.company._id });
                setStatistic(data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <LeaveProvider>
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4">
                    <Col lg="6">
                        <PageTitle
                            title="Leave"
                            subtitle={<span className="route-main">Leave</span>}
                            className="text-sm-left mb-3 "
                        />
                    </Col>
                    <Col lg="6" className="d-flex justify-content-end">
                        <Col xl="4" lg="6" md="6">
                            <div
                                className="text-center rounded p-2 d-flex justify-content-center align-items-center button__warning"
                                onClick={() => {
                                    setVisible(true);
                                }}
                            >
                                <AiFillPlusCircle size={25} />
                                <span className="ml-2"> Leave Request</span>
                            </div>
                        </Col>
                        <LeaveCreateForm
                            visible={visible}
                            type={FORM_TYPE.CREATE}
                            onCancel={() => {
                                setVisible(false);
                            }}
                        />
                    </Col>
                </Row>
                <Card small className="card_ovv_employees">
                    <CardHeader className="border-bottom">
                        <span className="ct-title">Overview</span>
                    </CardHeader>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 bg-white rounded-bottom">
                        <Row lg="12" sm="6" md="8" xl="12">
                            <Col lg="3" className="pt-3 pb-3 border-right">
                                <div className="col p-0">
                                    <div className=" widget-flat h-100 statistical__box">
                                        <div className="statistical">
                                            <div className="float-end">
                                                <i className="mdi mdi-account-multiple widget-icon" />
                                            </div>
                                            <span className="" title="Number of Customers">
                                                Today Presents
                                            </span>
                                            <span className="">
                                                {statistic ? statistic.present : 0}/ {statistic ? statistic.total : 0}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="3" className="pt-3 pb-3 border-right">
                                <div className="col p-0">
                                    <div className=" widget-flat h-100  ">
                                        <div className="statistical">
                                            <div className="float-end">
                                                <i className="mdi mdi-account-multiple widget-icon" />
                                            </div>
                                            <span className="" title="Number of Customers">
                                                Today Planned Leaves
                                            </span>
                                            <span className="">{statistic ? statistic.planned : 0}</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="3" className="pt-3 pb-3 border-right">
                                <div className="col p-0">
                                    <div className=" widget-flat h-100  ">
                                        <div className="statistical">
                                            <div className="float-end">
                                                <i className="mdi mdi-account-multiple widget-icon" />
                                            </div>
                                            <span className="" title="Number of Customers">
                                                Today Unplanned Leaves
                                            </span>
                                            <span className="">{statistic ? statistic.unPlanned : 0}</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="3" className="pt-3 pb-3 border-right">
                                <div className="col p-0">
                                    <div className=" widget-flat h-100  ">
                                        <div className="statistical color__red">
                                            <div className="float-end">
                                                <i className="mdi mdi-account-multiple widget-icon" />
                                            </div>
                                            <span className="" title="Number of Customers">
                                                Pending Request
                                            </span>
                                            <span className="">{pending}</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Card>
                <Row>
                    <TabLeave setRender={setRender} render={render} setPending={setPending} />
                </Row>
            </Container>
        </LeaveProvider>
    );
}

export default Leave;
