import React from 'react';
import FilterExClaim from './FilterExClaim';
import TableExHistory from './TableExHistory';

function TabExClaimHistory({ tabSelected, value }) {
    return (
        <>
            <FilterExClaim tabSelected={tabSelected} />

            <TableExHistory value={value} />
        </>
    );
}

export default TabExClaimHistory;
