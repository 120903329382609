import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    // Row,
    // Col,
    // FormSelect,
    Card,
    CardHeader,
    CardBody,
    // CardFooter
} from 'shards-react';
import { useAttendance, useLeave, useUser } from '../../hooks';
import { ROLE } from '../../helpers/const';
import { employeesAPI } from '../../apis/employeesAPI';
import { leaveAPI } from '../../apis/leaveAPI';
import { useLocation } from 'react-router-dom';
import { LEAVE_STATUS } from '../../global/leave';

const EmployeeAvailable = () => {
    const { user, getMe } = useUser();
    const { attendances } = useAttendance();
    const [attendance, setAttendance] = useState(0);
    const [overtime, setOvertime] = useState(0);
    const { leaves } = useLeave();
    const [leaveApply, setLeaveApply] = useState(0);
    const [absent, setAbsent] = useState(0);
    const [totalEmployee, setTotalEmployee] = useState(0);
    const location = useLocation();

    useEffect(() => {
        getStatistic();
    }, [user, attendances, leaves, location]);

    useEffect(() => {
        const tmp = totalEmployee - attendance;
        tmp >= 0 ? setAbsent(tmp) : setAbsent(0);
    }, [attendance, totalEmployee]);

    const getStatistic = async () => {
        if (!user) {
            getMe();
        }

        if (user && user.role && (user.role === ROLE.ADMIN || user.role === ROLE.SYSTEMSTAFF)) {
            const { data: attendance } = await employeesAPI.statisticDashboard({ role: user.role, isAttendance: true });

            setAttendance(attendance.length);

            const { data: overtime } = await employeesAPI.statisticDashboard({ role: user.role, isOvertime: true });

            setOvertime(overtime.length);

            const { data: leaveApply } = await leaveAPI.get({
                status: LEAVE_STATUS.APPROVE,
                from: new Date(),
                to: new Date(),
            });

            setLeaveApply(leaveApply.items.length);

            const { data: employee } = await employeesAPI.percent({ role: user.role });

            setTotalEmployee(employee.total);
        }

        if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
            const { data: attendance } = await employeesAPI.statisticDashboard({
                role: user.role,
                idCompany: user.company._id,
                isAttendance: true,
            });

            setAttendance(attendance.length);

            const { data: overtime } = await employeesAPI.statisticDashboard({
                role: user.role,
                idCompany: user.company._id,
                isOvertime: true,
            });

            setOvertime(overtime.length);

            const { data: leaveApply } = await leaveAPI.get({
                status: LEAVE_STATUS.APPROVE,
                from: new Date(),
                to: new Date(),
                company: user.company._id,
            });

            setLeaveApply(leaveApply.items.length);

            const { data } = await employeesAPI.percent({ role: user.role, idCompany: user.company._id });

            setTotalEmployee(data.total);
        }

        if (
            user &&
            user.role &&
            user.role === ROLE.COMPANYSTAFF &&
            user.employee &&
            user.employee.company &&
            user.employee.company._id
        ) {
            const { data: attendance } = await employeesAPI.statisticDashboard({
                role: user.role,
                idCompany: user.employee.company._id,
                isAttendance: true,
            });
            setAttendance(attendance.length);

            const { data: overtime } = await employeesAPI.statisticDashboard({
                role: user.role,
                idCompany: user.employee.company._id,
                isOvertime: true,
            });
            setOvertime(overtime.length);

            const { data: leaveApply } = await leaveAPI.get({
                status: LEAVE_STATUS.APPROVE,
                from: new Date(),
                to: new Date(),
                company: user.employee.company._id,
            });

            setLeaveApply(leaveApply.items.length);

            const { data } = await employeesAPI.percent({ role: user.role, idCompany: user.employee.company._id });

            setTotalEmployee(data.total);
        }
    };

    return (
        <Card small className="h-100">
            <CardHeader className="border-bottom">
                <span className="title__h5">Employees Availability</span>
            </CardHeader>

            <CardBody className="col-xl-12 col-lg-12 col-md-12 pt-0 pb-0">
                <div className="row h-50">
                    <div className="col border-right">
                        <div className="h-100 d-flex justify-content-start align-items-center">
                            <div className="card-body">
                                {/* <div className="float-end">
                                    <i className="mdi mdi-account-multiple widget-icon" />
                                </div> */}
                                <div>
                                    <span className="ct-dasbroad" title="Number of Customers">
                                        Attendance
                                    </span>
                                    <span className="ct-number">{attendance}</span>
                                </div>
                            </div>
                            {/* end card-body*/}
                        </div>
                        {/* end card*/}
                    </div>
                    {/* end col*/}
                    <div className="col border-bottom">
                        <div className="h-100 d-flex justify-content-start align-items-center">
                            <div className="card-body">
                                {/* <div className="float-end">
                                    <i className="mdi mdi-cart-plus widget-icon" />
                                </div> */}
                                <div>
                                    <span className="ct-dasbroad" title="Number of Orders">
                                        Overtime
                                    </span>
                                    <span className="ct-number">{overtime}</span>
                                </div>
                            </div>
                            {/* end card-body*/}
                        </div>
                        {/* end card*/}
                    </div>
                    {/* end col*/}
                </div>
                <div className="row h-50">
                    <div className="col border-top">
                        <div className="h-100 d-flex justify-content-start align-items-center">
                            <div className="card-body">
                                {/* <div className="float-end">
                                    <i className="mdi mdi-currency-usd widget-icon" />
                                </div> */}
                                <div>
                                    <span className="ct-dasbroad" title="Average Revenue">
                                        Absent
                                    </span>
                                    <span className="ct-number">{absent}</span>
                                </div>
                            </div>
                            {/* end card-body*/}
                        </div>
                    </div>
                    {/* end col*/}
                    <div className="col border-left">
                        <div className="h-100 d-flex justify-content-start align-items-center">
                            <div className="card-body">
                                {/* <div className="float-end">
                                    <i className="mdi mdi-pulse widget-icon" />
                                </div> */}
                                <div>
                                    <span className="ct-dasbroad" title="Growth">
                                        Leave Apply
                                    </span>
                                    <span className="ct-number">{leaveApply}</span>
                                </div>
                            </div>
                            {/* end card-body*/}
                        </div>
                        {/* end card*/}
                    </div>
                    {/* end col*/}
                </div>
            </CardBody>
        </Card>
    );
};

EmployeeAvailable.propTypes = {
    /**
     * The component's title.
     */
    title: PropTypes.string,
};

EmployeeAvailable.defaultProps = {
    title: 'Employees Availability',
};

export default EmployeeAvailable;
