import axios from 'axios';

const path = 'permanent-residents/';

const getByEmployee = async (id) => {
    return await axios.get(path + 'employee/' + id);
};

const create = async (payload) => {
    return await axios.post(path, payload);
};

const update = async (payload) => {
    return await axios.patch(path, payload);
};

export const permanentResidentsAPI = {
    getByEmployee,
    update,
    create,
};
