import axios from 'axios';

const path = 'history-ot/';

const find = async (payload) => {
    return await axios.get(path, { params: payload });
};

export const historyOTAPI = {
    find,
};
