import { List } from 'antd';
import VirtualList from 'rc-virtual-list';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'shards-react';
import { useUser } from '../../hooks';
import PageTitle from './../common/PageTitle';

import { AiFillPlusCircle } from 'react-icons/ai';
import { useHistory, useLocation } from 'react-router-dom';
import { jobPositionAPI } from '../../apis/jobPositionAPI';
import { ROLE } from '../../helpers/const';
const ContainerHeight = 500;

const JobPossition = () => {
    const [data, setData] = useState([]);
    const { user, getMe } = useUser();
    const history = useHistory();

    useEffect(() => {
        if (!user) {
            getMe();
        }
        user &&
            user.role &&
            user.role !== ROLE.COMPANY &&
            user.role !== ROLE.COMPANYSTAFF &&
            user.role !== ROLE.USER &&
            history.push('/login');
    }, [user]);

    const appendData = async () => {
        if (!user) {
            getMe();
        }
        if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
            const { data } = await jobPositionAPI.getAllById(user.company._id);
            setData(data);
        }
        if (
            user &&
            user.role &&
            (user.role === ROLE.COMPANYSTAFF || user.role === ROLE.USER) &&
            user.employee &&
            user.employee.company &&
            user.employee.company._id
        ) {
            const { data } = await jobPositionAPI.getAllById(user.employee.company._id);
            setData(data);
        }
    };

    let location = useLocation();

    useEffect(() => {
        appendData();
    }, [location, user]);

    // useEffect(() => {
    //     appendData();
    // }, []);

    const onScroll = (e) => {
        if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight) {
            appendData();
        }
    };
    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <Col>
                    <PageTitle
                        title="Job Positions"
                        subtitle={
                            <>
                                <span className="route-extra">Company settings/ Job Positions </span>
                            </>
                        }
                        className="text-sm-left mb-3"
                    />
                </Col>

                {user && user.role && user.role === ROLE.COMPANY && (
                    <Col xl="2" lg="3" md="6" sm="12">
                        <div
                            className="text-center rounded p-2 d-flex justify-content-center align-items-center button__warning"
                            onClick={() => history.push('/employers/add-job-position')}
                        >
                            <AiFillPlusCircle size={25} />
                            <span className="ml-2"> Add Job Position</span>
                        </div>
                    </Col>
                )}
            </Row>
            <Row className="mt-2 w-100" lg="12" xl="12" md="12">
                <Card small className="h-100 w-100">
                    <CardHeader className="border-bottom">
                        <span className="ct-title">Job Positions</span>
                    </CardHeader>

                    <CardBody className="col-xl-12 col-lg-12 col-md-12">
                        <Row className="d-flex justify-content-center align-items-center mt-3">
                            <Col lg="8">
                                <List>
                                    <VirtualList
                                        data={data}
                                        height={ContainerHeight}
                                        itemHeight={60}
                                        itemKey="email"
                                        onScroll={onScroll}
                                    >
                                        {(item, index) => (
                                            <List.Item
                                                key={index}
                                                onClick={() =>
                                                    history.push(
                                                        `/job-position-setting/job-position-detail/${item._id}`,
                                                    )
                                                }
                                            >
                                                <List.Item.Meta
                                                    title={<h5>{item.jobPositionName && item.jobPositionName}</h5>}
                                                    description={item.code && item.code}
                                                />
                                                <div>
                                                    <p className="material-icons">arrow_forward_ios</p>
                                                </div>
                                            </List.Item>
                                        )}
                                    </VirtualList>
                                </List>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Row>
        </Container>
    );
};

export default JobPossition;
