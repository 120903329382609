import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Badge, Collapse, DropdownItem, DropdownMenu, Dropdown, NavLink } from 'shards-react';
import { historiesAPI } from '../../../../apis/historiesAPI';
import { FORM_TYPE, ROLE, TRAINING_TYPE, TYPE_NOTIFICATION } from '../../../../helpers/const';
import { getError } from '../../../../helpers/getErrorServer';
import { useUser } from '../../../../hooks';
import ExClaimCreateForm from '../../../expense-claim/ExClaimCreateForm';
import LeaveCreateForm from '../../../leave/leaveCreateForm';
import TrainingNotificationForm from '../../../training/TrainingNotificationForm';
import { noticeAPI } from '../../../../apis/noticeAPI';
import { messagesAPI } from '../../../../apis/messagesAPI';
import { Input, Modal } from 'antd';
function Notifications() {
    const { user, getMe } = useUser();
    const [data, setData] = useState([]);
    const [amount, setAmount] = useState();
    const [visible, setVisible] = useState();
    const [notifications, setNotifications] = useState();
    const [idLeave, setIdLeave] = useState();
    const [idEx, setIdEx] = useState();
    const history = useHistory();
    const [visibleLeave, setVisibleLeave] = useState(false);
    const [visibleEx, setVisibleEx] = useState(false);
    const [type, setType] = useState();

    //
    const [visibleTraining, setVisibleTraining] = useState(false);
    const [idTraining, setIdTraining] = useState();
    //
    //
    const [visibleMessages, setVisibleMessages] = useState(false);
    const [dataMessages, setDataMessages] = useState({});
    //
    useEffect(() => {
        let aboutController = new AbortController();
        getNoTi();
        return () => {
            aboutController.abort();
        };
    }, [user]);

    useEffect(() => {
        const timeID = setInterval(() => {
            getNoTi();
        }, 60000);
        return () => clearInterval(timeID);
    }, [user]);

    const getNoTi = async () => {
        try {
            if (!user) {
                getMe();
            }

            if (user && user.role && (user.role === ROLE.ADMIN || user.role === ROLE.SYSTEMSTAFF)) {
                const { data } = await historiesAPI.get({ role: user.role });
                setData(data.items);
            }

            if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
                const { data } = await historiesAPI.get({ role: user.role, idCompany: user.company._id });
                setData(data.items);
            }

            if (
                user &&
                user.role &&
                user.role === ROLE.COMPANYSTAFF &&
                user.employee &&
                user.employee.company &&
                user.employee.company._id
            ) {
                const { data } = await historiesAPI.get({ role: user.role, idCompany: user.employee.company._id });
                setData(data.items);
            }

            if (user && user.role && user.role === ROLE.USER) {
                let arr = [];

                if (user.employee && user.employee._id) {
                    const { data } = await historiesAPI.get({ role: user.role, idEmployee: user.employee._id });
                    arr = [...data.items];
                }

                if (user.employee && user.employee.isApproverExpense === true) {
                    const { data } = await historiesAPI.get({
                        role: ROLE.COMPANY,
                        type: TYPE_NOTIFICATION.EXPENSECLAIM,
                    });
                    arr = [...data.items];
                }

                if (
                    user &&
                    user.role &&
                    user.role === ROLE.USER &&
                    user.employee &&
                    user.employee.isApproverLeave === true
                ) {
                    const { data } = await historiesAPI.get({ role: ROLE.COMPANY, type: TYPE_NOTIFICATION.LEAVE });
                    arr = [...data.items];
                }

                setData(arr);
            }
        } catch (error) {
            toast.error(getError(error));
        }
    };

    useEffect(() => {
        let aboutController = new AbortController();
        if (data) {
            setAmount(data.length);
        }
        if (data) {
            setNotifications(data);
        }
        return () => {
            aboutController.abort();
        };
    }, [data]);

    const readNotification = async (id) => {
        if (id) {
            const { data } = await historiesAPI.getById(id);

            if (data) {
                if (data.type && data.type === TYPE_NOTIFICATION.EMPLOYEE) {
                    data.requestDelete === false
                        ? history.push(`/edit-employee/${data.idRequest}`)
                        : history.push('/employees/local-staff');
                }
                if (data.type && data.type === TYPE_NOTIFICATION.LEAVE) {
                    setIdLeave(data.idRequest);
                    setVisibleLeave(true);
                    if (
                        user &&
                        user.role &&
                        (user.role === ROLE.COMPANY ||
                            user.role === ROLE.COMPANYSTAFF ||
                            (user.role === ROLE.USER && user.employee && user.employee.isApproverLeave === true))
                    ) {
                        setType(FORM_TYPE.EDIT);
                    } else if (
                        user &&
                        user.role &&
                        user.role === ROLE.USER &&
                        user.employee &&
                        user.employee.isApproverLeave !== true
                    ) {
                        setType(FORM_TYPE.VIEW);
                    }
                }
                if (data.type && data.type === TYPE_NOTIFICATION.EXPENSECLAIM) {
                    setIdEx(data.idRequest);
                    setVisibleEx(true);
                    if (
                        user &&
                        user.role &&
                        (user.role === ROLE.COMPANY ||
                            user.role === ROLE.COMPANYSTAFF ||
                            (user.role === ROLE.USER && user.employee && user.employee.isApproverLeave === true))
                    ) {
                        setType(FORM_TYPE.EDIT);
                    } else if (
                        user &&
                        user.role &&
                        user.role === ROLE.USER &&
                        user.employee &&
                        user.employee.isApproverLeave !== true
                    ) {
                        setType(FORM_TYPE.VIEW);
                    }
                }

                if (data.type && data.type === TYPE_NOTIFICATION.PAYROLL) {
                    history.push(`/pay-slip/${data.idRequest}`);
                }

                if (data.type && data.type === TYPE_NOTIFICATION.COMPANY) {
                    data.requestDelete === false
                        ? history.push(`/edit-companies/${data.idRequest}`)
                        : history.push('/companies');
                }

                if (data.type && data.type === TYPE_NOTIFICATION.DEPARTMENT) {
                    history.push(`/departments`);
                }

                if (data.type && data.type === TYPE_NOTIFICATION.HOLIDAY) {
                    history.push(`/public-holiday`);
                }

                if (data.type && data.type === TYPE_NOTIFICATION.CERTIFICATE) {
                    history.push(`/certificate`);
                }

                if (data.type && data.type === TYPE_NOTIFICATION.TRAINING) {
                    if (data.title === 'Training update') {
                        setIdTraining(data.idRequest);
                        setType(TRAINING_TYPE.UPDATE);
                        setVisibleTraining(true);
                    } else if (data.title === 'Request Join Training') {
                        setIdTraining(data.idRequest);
                        setType(TRAINING_TYPE.REQUEST);
                        setVisibleTraining(true);
                    } else if (data.title === 'Training finish') {
                        setIdTraining(data.idRequest);
                        setType(TRAINING_TYPE.FINISH);
                        setVisibleTraining(true);
                    }
                }

                if (data.type && data.type === TYPE_NOTIFICATION.MESSAGES) {
                    if (data.idMessages) {
                        const result = await noticeAPI.getByID(data.idMessages);
                        setDataMessages({ ...result.data, role: result.data.creator.role });
                        setVisibleMessages(true);
                    }
                }

                await historiesAPI.update(id);
            }
        }
        getNoTi();
    };

    const onCloseLeave = async () => {
        setVisibleLeave(false);
        setIdLeave();
    };

    const onCloseEx = async () => {
        setVisibleEx(false);
        setIdEx();
    };

    const toggleNoitiActions = () => {
        setVisible(!visible);
    };

    const handleCancel = async () => {
        setVisibleMessages(false);
        setDataMessages({});
    };

    const handleOkDetail = async () => {
        setVisibleMessages(false);
        setDataMessages({});
        history.push(`/notice`);
    };

    return (
        <>
            <Dropdown
                open={visible}
                toggle={toggleNoitiActions}
                className="border-right dropdown notifications d-flex justify-content-center align-items-center"
            >
                <NavLink className="nav-link-icon text-center">
                    <div className="nav-link-icon__wrapper h-100" onClick={() => toggleNoitiActions()}>
                        <NotificationsNoneIcon />
                        <Badge pill theme="danger">
                            {amount && +amount > 0 ? amount : ''}
                        </Badge>
                    </div>
                </NavLink>
                <DropdownMenu open={visible} className="dropdown-menu dropdown-menu-small">
                    <div className="notification-box">
                        {notifications &&
                            notifications.map((item, index) => (
                                <DropdownItem
                                    key={index}
                                    onClick={() => {
                                        item._id && readNotification(item._id);
                                    }}
                                >
                                    <div className="notification__icon-wrapper">
                                        <div className="notification__icon">
                                            <i className="material-icons">&#xE8D1;</i>
                                        </div>
                                        <div className="notification__content">
                                            <span className="notification__category">{item.title && item.title}</span>
                                            <p>{item.desc && item.desc}</p>
                                        </div>
                                    </div>
                                </DropdownItem>
                            ))}
                    </div>
                    {/* <DropdownItem className="notification__all text-center">View all Notifications</DropdownItem> */}
                </DropdownMenu>
            </Dropdown>

            <LeaveCreateForm
                visible={visibleLeave}
                id={idLeave}
                onCancel={() => {
                    onCloseLeave();
                }}
                type={type}
            />
            <ExClaimCreateForm
                visible={visibleEx}
                id={idEx}
                onCancel={() => {
                    onCloseEx();
                }}
                type={type}
            />
            <TrainingNotificationForm
                visible={visibleTraining}
                id={idTraining}
                setVisible={setVisibleTraining}
                type={type}
            />

            <Modal title="Messenger" open={visibleMessages} onOk={handleOkDetail} onCancel={handleCancel}>
                <div className="form-add-holiday">
                    <div className="mb-4">
                        <label>From</label>
                        <Input.TextArea autoSize={{ minRows: 1 }} value={dataMessages.role || '--'} />
                    </div>
                    <div className="mb-4">
                        <label>Title</label>
                        <Input.TextArea autoSize={{ minRows: 2 }} value={dataMessages.title} />
                    </div>
                    <div className="mb-4">
                        <label>Content</label>
                        <Input.TextArea autoSize={{ minRows: 5 }} value={dataMessages.messages} width="100%" />
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default Notifications;
