import { Space, Table, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { RiArrowUpDownFill } from 'react-icons/ri';
import { Link, useLocation } from 'react-router-dom';
import { leaveAPI } from '../../apis/leaveAPI';
import { LEAVE_STATUS, LEAVE_TYPE } from '../../global/leave';
import { FORMAT, FORM_TYPE, ROLE } from '../../helpers/const';
import { useLeave, useUser } from '../../hooks';
import IMG_AVT from '../../images/avatars/0.jpg';
import LeaveCreateForm from '../leave/leaveCreateForm';

const LeaveRequest = () => {
    const { user, getMe } = useUser();
    const { leaves } = useLeave();
    const [leavesDB, setLeavesDB] = useState();
    const [data, setData] = useState();
    const [count, setCount] = useState(0);
    const [size, setSize] = useState(4);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState({
        sortBy: 'createdAt',
        status: LEAVE_STATUS.PENDING,
        limit: size,
        page: 1,
        from: new Date(),
        to: new Date(),
    });
    const [visible, setVisible] = useState();
    const [type, setType] = useState(FORM_TYPE.VIEW);
    const [leaveSelected, setLeaveSelected] = useState();

    const columns = [
        {
            title: () => (
                <div className="cursor-pointer">
                    Name <RiArrowUpDownFill />
                </div>
            ),
            dataIndex: '',
            key: 'name',
            render: (_, row) => {
                return (
                    <div
                        className="content_td_mobile_db d-flex justify-content-center align-items-center"
                        onClick={() => onOpen(row._id)}
                    >
                        <p className="title_mobile">
                            Name
                            <RiArrowUpDownFill />
                        </p>
                        <div className="avt_leave_requ mr-3">
                            <img
                                src={
                                    row.employee && row.employee.user && row.employee.user.avatar
                                        ? row.employee.user.avatar
                                        : IMG_AVT
                                }
                                alt=""
                            />
                        </div>
                        <p>
                            {row.employee && row.employee.firstName
                                ? row.employee.firstName
                                : "Can't get first name employee"}
                        </p>
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.employee.firstName > record2.employee.firstName;
            },
        },
        {
            title: 'Leave Type',
            dataIndex: 'type',
            key: 'type',
            render: (type, row) => (
                <div className="content_td_mobile" onClick={() => onOpen(row._id)}>
                    <p className="title_mobile">Leave Type</p>

                    <Tag color={type === LEAVE_TYPE[0].value ? 'blue' : 'orange'}>{type.description}</Tag>
                </div>
            ),
        },
        {
            title: 'From',
            dataIndex: 'from',
            key: 'from',

            render: (value, row) => {
                return (
                    <div className="content_td_mobile" onClick={() => onOpen(row._id)}>
                        <p className="title_mobile">From</p>
                        {moment(value).format(FORMAT.DATE)}
                    </div>
                );
            },
        },
        {
            title: 'To',
            dataIndex: 'to',
            key: 'to',
            render: (value, row) => {
                return (
                    <div className="content_td_mobile" onClick={() => onOpen(row._id)}>
                        <p className="title_mobile">To</p>
                        {moment(value).format(FORMAT.DATE)}
                    </div>
                );
            },
        },
        // {
        //     title: 'Actions',
        //     key: '_id',
        //     fixed: 'right',
        //     dataIndex: '_id',
        //     render: (id, record) => (
        //         <div className="content_td_mobile">
        //             <p className="title_mobile">Actions</p>
        //             <Space size="middle">
        //                 <Link to={`#`} onClick={() => onOpen(id)}>
        //                     <span className="material-icons icon_eye">visibility</span>
        //                 </Link>
        //             </Space>
        //         </div>
        //     ),
        // },
    ];

    const location = useLocation();
    useEffect(() => {
        request(filter);
    }, [user, leaves, location]);

    useEffect(() => {
        if (leavesDB) {
            setData(leavesDB.items);
            setPaginate(leavesDB);
        }
    }, [leavesDB]);

    // useEffect(() => {
    //     if (leavesDB && leavesDB.paginate && leavesDB.paginate.count && leavesDB.paginate.size) {
    //         setCount(leavesDB.paginate.count);
    //         setSize(leavesDB.paginate.size);
    //     }
    // }, [leavesDB]);

    const setPaginate = (data) => {
        if (data && data.paginate && data.paginate.count && data.paginate.size) {
            setCount(data.paginate.count);
            setSize(data.paginate.size);
        }
    };

    const request = async (filter) => {
        if (!user) {
            getMe();
        }

        if (user && user.role && (user.role === ROLE.ADMIN || user.role === ROLE.SYSTEMSTAFF)) {
            const { data } = await leaveAPI.get(filter);
            setLeavesDB(data);
        }

        if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
            const { data } = await leaveAPI.get({
                ...filter,
                fromStart: new Date(filter.from).setHours(0),
                fromEnd: new Date(filter.from).setHours(23),
                toStart: new Date(filter.to).setHours(0),
                toEnd: new Date(filter.to).setHours(23),
                company: user.company._id,
            });

            setLeavesDB(data);
        }

        if (
            user &&
            user.role &&
            user.role === ROLE.COMPANYSTAFF &&
            user.employee &&
            user.employee.company &&
            user.employee.company._id
        ) {
            const { data } = await leaveAPI.get({
                ...filter,
                company: user.employee.company._id,
            });
            setLeavesDB(data);
        }
    };

    const onChangePage = (page) => {
        setPage(page);
        const newFilter = { ...filter, page };
        request(newFilter);
        setFilter(newFilter);
    };

    useEffect(() => {
        (async () => {
            const newFilter = { ...filter, page, size };
            await request(newFilter);
            setFilter(newFilter);
        })();
    }, [page, size]);

    const onOpen = (id, type = FORM_TYPE.VIEW) => {
        setLeaveSelected(id);
        setVisible(true);
        setType(type);
    };

    const onClose = () => {
        setVisible(false);
        setLeaveSelected();
    };

    return (
        <>
            <Table
                dataSource={
                    data &&
                    data.map((item) => {
                        return { ...item, key: item._id };
                    })
                }
                columns={columns}
                pagination={{ onChange: onChangePage, pageSize: size, total: count }}
            />
            <LeaveCreateForm
                visible={visible}
                id={leaveSelected}
                onCancel={() => {
                    onClose();
                }}
                type={type}
            />
        </>
    );
};

export default LeaveRequest;
