import React from 'react';
import FilterExClaim from './FilterExClaim';
import TablePenddingExClaim from './TablePenddingExClaim';

function TabPendingExpenseClaim({ tabSelected, value }) {
    return (
        <>
            <FilterExClaim tabSelected={tabSelected} />
            <TablePenddingExClaim value={value} />
        </>
    );
}

export default TabPendingExpenseClaim;
