import axios from 'axios';

const path = 'file-management/';

const add = async (payload) => {
    return await axios.post(path, payload);
};

const softDelete = async (id) => {
    return await axios.delete(path + id);
};

const statistic = async (id) => {
    return await axios.get(path + 'statistic/' + id);
};

const get = async (filter = {}) => {
    return await axios.get(path, {
        params: filter,
    });
};

export const fileManagementAPI = {
    add,
    get,
    softDelete,
    statistic,
};
