import { createContext } from 'react';
import { RESPONSE } from '../helpers/response';
import { EXPENSE_CLAIM, EXPENSE_CLAIM_FILTER } from '../global/expenseClaim';

const initValue = {
    expenseClaims: RESPONSE,
    expenseClaimsPending: RESPONSE,
    filterExpenseClaim: EXPENSE_CLAIM_FILTER,
    getExpenseClaimByID: (id) => {
        return EXPENSE_CLAIM;
    },
    setFilterExpenseClaim: (filter = EXPENSE_CLAIM_FILTER) => {},
    fetchExpenseClaims: (filter = EXPENSE_CLAIM_FILTER) => {},
};
export const ExpenseClaimContext = createContext(initValue);
