import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ROLE } from '../../../helpers/const';
import { useUser } from '../../../hooks';
import { FormGroup, FormInput, Col, Row } from 'shards-react';
import { cdacAPI } from '../../../apis/payroll-settings/cdacAPI';

const initValue = [
    {
        from: 0,
        to: 2000,
        percent: 0.5,
    },
    {
        from: 2001,
        to: 3500,
        percent: 1,
    },
    {
        from: 3501,
        to: 5000,
        percent: 1.5,
    },
    {
        from: 5001,
        to: 7500,
        percent: 2,
    },
    {
        from: 7501,
        to: Math.pow(10, 20),
        percent: 3,
    },
];

function CDAC() {
    const { user, getMe } = useUser();
    const [dataCDAC, setDataCDAC] = useState(initValue);
    const [data, setData] = useState({});

    useEffect(() => {
        getData();
    }, [user]);

    const getData = async () => {
        if (!user) {
            getMe();
        }
        if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
            const { data } = await cdacAPI.getByCompany(user.company._id);
            setDataCDAC(data.cdac ? data.cdac : initValue);
            setData(data);
        }
        if (user && user.role && user.role === ROLE.COMPANYSTAFF && user.employee && user.employee.company._id) {
            const { data } = await cdacAPI.getByCompany(user.employee.company._id);
            setDataCDAC(data.cdac ? data.cdac : initValue);
            setData(data);
        }
    };

    const handleChange = async (from, to, title, value) => {
        setDataCDAC((prev) => {
            return prev.map((item) => {
                if (item.from === from && item.to === to) {
                    return { ...item, [title]: +value };
                }
                return item;
            });
        });
    };

    const handleSubmit = async () => {
        try {
            await cdacAPI.updateByCompany(data._id.toString(), { cdac: dataCDAC });
            toast.success('Update successful');
        } catch (error) {
            const message = error.response.data.message;
            if (message && typeof message === 'string') {
                toast.error(<p className="text-capitalize mt-3">{message}</p>);
            } else if (message && typeof message === 'object') {
                toast.error(<p className="text-capitalize mt-3">{message[0]}</p>);
            }
        }
    };

    return (
        <div className="padding_100">
            <Row>
                <Col lg="12">
                    <div className="d-flex justify-content-between align-items-center mb-1 mt-4">
                        <h5 className="title-font">{'CDAC (paid by staff)'}</h5>
                    </div>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            Gross Wages/Month
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            Monthly contribution ($)
                        </span>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            $2000 and below
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="0"
                            type="number"
                            min="0"
                            max="100"
                            value={dataCDAC[0].percent}
                            onChange={(e) => {
                                handleChange(0, 2000, 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            $2000 to $3500
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="1"
                            type="number"
                            min="0"
                            max="100"
                            value={dataCDAC[1].percent}
                            onChange={(e) => {
                                handleChange(2001, 3500, 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            $3500 to $5000
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="2"
                            type="number"
                            min="0"
                            max="100"
                            value={dataCDAC[2].percent}
                            onChange={(e) => {
                                handleChange(3501, 5000, 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            $5000 to $7500
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="3"
                            type="number"
                            min="0"
                            max="100"
                            value={dataCDAC[3].percent}
                            onChange={(e) => {
                                handleChange(5001, 7500, 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="mt-4">
                        <span htmlFor="da" className="text bold">
                            Above $7500
                        </span>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-4">
                        <FormInput
                            id="4"
                            type="number"
                            min="0"
                            max="100"
                            value={dataCDAC[4].percent}
                            onChange={(e) => {
                                handleChange(7501, Math.pow(10, 20), 'percent', e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            {user && user.role && user.role === ROLE.COMPANY && (
                <div className="mt-2">
                    <button onClick={handleSubmit} className="ct_btn px-4 next none-border btn-middle" id="save">
                        <span>Save</span>
                    </button>
                </div>
            )}
        </div>
    );
}

export default CDAC;
