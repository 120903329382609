import '../../assets/components/page-title.css';
import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'shards-react';

const PageTitle = ({ title, subtitle, className, ...attrs }) => {
    return (
        <Col xs="12" sm="12" {...attrs}>
            <span className="page-subtitle">
                <span className="route-extra">/</span> {subtitle && subtitle}
            </span>
            <h3 className="page-title mt-2">{title && title}</h3>
        </Col>
    );
};

PageTitle.propTypes = {
    /**
     * The page title.
     */
    title: PropTypes.string,
    /**
     * The page subtitle.
     */
    subtitle: PropTypes.object,
};

export default PageTitle;
