import axios from 'axios';

const path = 'public-holidays/';

const create = async (payload) => {
    return await axios.post(path, payload);
};

const getAll = async (query = {}) => {
    return await axios.get(path, { params: query });
};

const getByCompany = async (id) => {
    return await axios.get(path + 'company/' + id);
};

const update = async (id, payload) => {
    return await axios.patch(path + id, payload);
};

export const publicHolidayAPI = {
    create,
    getAll,
    update,
    getByCompany,
};
