export const EXPENSE_CLAIM_STATUS = {
    APPROVE: 'approve',
    REJECT: 'reject',
    PENDING: 'pending',
};

export const EXPENSE_CLAIM_PAID_BY = [
    {
        label: 'Cash',
        value: 'cash',
    },
    {
        label: 'Credit card',
        value: 'credit-cash',
    },
];
