import axios from 'axios';

const path = 'ecf/';

const updateByCompany = async (id, payload) => {
    return await axios.patch(path + id, payload);
};

const getByCompany = async (id) => {
    return await axios.get(path + 'company/' + id);
};

export const ecfAPI = {
    updateByCompany,
    getByCompany,
};
