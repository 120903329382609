import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'shards-react';
import ExClaimCreateForm from '../components/expense-claim/ExClaimCreateForm';
import { FORM_TYPE, ROLE } from '../helpers/const';
import { useUser } from '../hooks';
import ExpenseClaimProvider from '../providers/expenseClaimProvider';
import PageTitle from './../components/common/PageTitle';
import TabExpenseClaim from './../components/expense-claim/TabExpenseClaim';

import { AiFillPlusCircle } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';

function ExpenseClaim() {
    const [visible, setVisible] = useState(false);

    const { user, getMe } = useUser();

    // window.onbeforeunload = function() {
    //     if (!localStorage.remember) {
    //         jwtManager.clear();
    //     } else {
    //         return;
    //     }
    // };
    const history = useHistory();
    useEffect(() => {
        if (!user) {
            getMe();
        }
        user &&
            user.role &&
            user.role !== ROLE.COMPANY &&
            user.role !== ROLE.COMPANYSTAFF &&
            user.role !== ROLE.USER &&
            history.push('/login');
    }, [user]);

    return (
        <ExpenseClaimProvider>
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4">
                    <Col lg="6">
                        <PageTitle
                            title="Expense Claim"
                            subtitle={<span className="route-main">Expense Claim</span>}
                            className="text-sm-left mb-3 "
                        />
                    </Col>
                    <Col lg="6" className="d-flex justify-content-end">
                        <div
                            className="text-center rounded p-2 d-flex justify-content-center align-items-center button__warning"
                            onClick={() => {
                                setVisible(true);
                            }}
                        >
                            <AiFillPlusCircle size={25} />
                            <span className="ml-2"> Add expense</span>
                        </div>
                    </Col>
                    <ExClaimCreateForm
                        visible={visible}
                        type={FORM_TYPE.CREATE}
                        onCancel={() => {
                            setVisible(false);
                        }}
                    />
                </Row>

                <Row>
                    <TabExpenseClaim />
                </Row>
            </Container>
        </ExpenseClaimProvider>
    );
}

export default ExpenseClaim;
