import { DatePicker, Input, Table, Tag, Button } from 'antd';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { employeesAPI } from '../../apis/employeesAPI';
import moment from 'moment';
import { Box } from '@mui/material';
import { Col, Row } from 'shards-react';
import { ROLE } from '../../helpers/const';
import { useUser } from '../../hooks';
import { getHour } from '../../helpers/getHours';

const TableEmployeeAttendance = () => {
    const [employees, setEmployees] = useState([]);
    const [total, setTotal] = useState(0);
    const [size, setSize] = useState(0);
    const [page, setPage] = useState(1);
    const [searchDate, setSearchDate] = useState();
    const [name, setName] = useState('');
    const { user, getMe } = useUser();

    const setPaginate = (data) => {
        if (data && data.paginate && data.paginate.count && data.paginate.size) {
            setTotal(data.paginate.count);
            setSize(data.paginate.size);
        }
    };

    useEffect(() => {
        (async () => {
            if (!user._id) {
                await getMe();
            }
            if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
                const { data } = await employeesAPI.get({ company: user.company._id, page, limit: 10 });
                setEmployees(data.items);
                setPaginate(data);
            } else if (
                ((user && user.role && user.role === ROLE.COMPANYSTAFF) ||
                    (user &&
                        user.role &&
                        user.role === ROLE.USER &&
                        user.position.attendance &&
                        user.position.attendance.includes('1')) ||
                    (user &&
                        user.employee &&
                        user.employee.controls &&
                        user.employee.controls.attendance &&
                        user.employee.controls.attendance.includes('1'))) &&
                user &&
                user.employee &&
                user.employee.company &&
                user.employee.company._id
            ) {
                const { data } = await employeesAPI.getByCompany(user.employee.company._id, { page, limit: 10 });
                setEmployees(data.items);
                setPaginate(data);
            } else if (user && user.employee && user.employee._id) {
                const { data } = await employeesAPI.getById(user.employee._id);
                setEmployees(data);
            }

            // if (
            //     user &&
            //     user.role &&
            //     user.role === ROLE.USER &&
            //     user.position &&
            //     user.position.attendance &&
            //     !user.position.attendance.includes('1')
            // ) {
            //     const { data } = await employeesAPI.getById(user.employee._id);
            //     setEmployees(data);
            // }
        })();
    }, [page]);

    const columns = [
        {
            id: 1,
            title: 'Name',
            dataIndex: 'firstName',
            filter: false,
            sorter: true,
            render: (name) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Name</p>
                        <span>{name}</span>
                    </div>
                );
            },
            sorter: (record1, record2) => {
                return record1.firstName > record2.firstName;
            },
        },
        {
            id: 2,
            title: 'Check In',
            dataIndex: 'checkInTime',
            editable: true,
            render: (_, record) => {
                let data = record.attendance;
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Check In</p>
                        <span>
                            {data.length > 0 && data[0].checkInTime
                                ? moment(data[0].checkInTime).format('HH:mm')
                                : data.length > 0 && data[0].checkInTime2
                                ? moment(data[0].checkInTime2).format('HH:mm')
                                : '--'}
                        </span>
                    </div>
                );
            },
        },
        {
            id: 3,
            title: 'Check Out',
            dataIndex: 'checkOutTime',

            editable: true,
            render: (_, record) => {
                let data = record.attendance;
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Check Out</p>
                        <span>
                            {data.length > 0 && data[0].checkOutTime2
                                ? moment(data[0].checkOutTime2).format('HH:mm')
                                : data.length > 0 && data[0].checkOutTime
                                ? moment(data[0].checkOutTime).format('HH:mm')
                                : '--'}
                        </span>
                    </div>
                );
            },
        },
        {
            id: 4,
            title: 'Overtime',
            dataIndex: 'overtime',

            editable: true,
            render: (_, record) => {
                let data = record.attendance;
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Overtime</p>
                        <span>
                            {data.length > 0 && data[0].overtime ? moment(data[0].overtime).format('HH:mm') : '--'}
                        </span>
                    </div>
                );
            },
        },
        {
            id: 5,
            title: 'Working Hours',
            dataIndex: 'workinghours',

            editable: false,
            render: (_, record) => {
                let data = record.attendance;

                if (data && data[0] && data[0].workingHours) {
                    let { hour, minute } = getHour(data[0].workingHours);

                    return (
                        <div className="content_td_mobile">
                            <p className="title_mobile">Working Hours</p>
                            <span>
                                {hour === '0' ? '00' : +hour < 10 ? '0' + hour : hour}:
                                {minute === '0' ? '00' : +minute < 10 ? '0' + minute : minute}
                            </span>
                        </div>
                    );
                } else {
                    return (
                        <div className="content_td_mobile">
                            <p className="title_mobile">Working Hours</p>
                            <span>--</span>
                        </div>
                    );
                }
            },
        },
        {
            id: 6,
            title: 'Overtime  Hours',
            dataIndex: 'overtimehours',
            editable: false,
            render: (_, record) => {
                let data = record.attendance;

                if (data.length > 0 && data[0].overtimeHours) {
                    let { hour, minute } = getHour(data[0].overtimeHours);
                    return (
                        <div className="content_td_mobile">
                            <p className="title_mobile">Overtime Hours</p>
                            <span>
                                {hour === '0' ? '00' : +hour < 10 ? '0' + hour : hour}:
                                {minute === '0' ? '00' : +minute < 10 ? '0' + minute : minute}
                            </span>
                        </div>
                    );
                } else {
                    return (
                        <div className="content_td_mobile">
                            <p className="title_mobile">Overtime</p>
                            <span>--</span>
                        </div>
                    );
                }
            },
        },
        {
            id: 7,
            title: 'Status',
            dataIndex: 'status',

            editable: false,
            render: (_, record) => {
                let color = '';
                let data = record.attendance;
                if (data.length > 0 && data[0].status === 'present') {
                    color = 'blue';
                } else if (data.length > 0 && data[0].status === 'absent') {
                    color = 'green';
                } else {
                    color = 'red';
                }

                return (
                    <>
                        <div className="content_td_mobile">
                            <p className="title_mobile">Status</p>
                            <span>{data.length > 0 ? <Tag color={color}>{data[0].status}</Tag> : <span></span>}</span>
                        </div>
                    </>
                );
            },
        },
        {
            id: 8,
            title: 'Actions',
            dataIndex: 'action',
            fixed: 'right',
            render: (_, record) => {
                return (
                    <div className="content_td_mobile">
                        <p className="title_mobile">Actions</p>
                        <span>
                            <Link to={`/attendance-view/${record._id}`}>View</Link>
                        </span>
                    </div>
                );
            },
        },
    ];

    const submitSearch = async () => {
        if (user && user.role && user.role === ROLE.COMPANY && user.company && user.company._id) {
            const { data } = await employeesAPI.get({ company: user.company._id, name, searchDate, page, limit: 10 });
            setEmployees(data.items);
            setPaginate(data);
        } else if (
            (user && user.role && user.role === ROLE.COMPANYSTAFF) ||
            (user &&
                user.role &&
                user.role === ROLE.USER &&
                user.position &&
                user.position.attendance &&
                user.position.attendance.includes('1')) ||
            (user &&
                user.employee &&
                user.employee.controls &&
                user.employee.controls.attendance &&
                user.employee.controls.attendance.includes('1'))
        ) {
            const { data } = await employeesAPI.getByCompany(user.employee.company._id, {
                name,
                searchDate,
                page,
                limit: 10,
            });
            setEmployees(data.items);
            setPaginate(data);
        }
    };

    const onChangePage = (page) => {
        setPage(page);
    };

    return (
        <>
            <Box sx={{ marginTop: 2, marginBottom: 2, padding: '0 20px' }}>
                {!(
                    user &&
                    user.role &&
                    user.role === ROLE.USER &&
                    user.position &&
                    user.position.attendance &&
                    user.position.attendance.length === 0 &&
                    (!user.employee ||
                        !user.employee.controls ||
                        !user.employee.controls.attendance ||
                        user.employee.controls.attendance.length === 0)
                ) && (
                    <Row>
                        <Col lg="3" md="3" sm="12">
                            <Input placeholder="Search by name" onChange={(e) => setName(e.target.value)} />
                        </Col>
                        <Col lg="3" md="3" sm="12">
                            <DatePicker
                                style={{ width: '100%' }}
                                format="YYYY-MM-DD"
                                onChange={(e) => setSearchDate(e)}
                            />
                        </Col>
                        <Col lg="6" md="5" sm="12">
                            <Button className="btn-yeallow-block" onClick={submitSearch}>
                                Search
                            </Button>
                        </Col>
                    </Row>
                )}
            </Box>
            <Table
                className="ct-table-data"
                pagination={{
                    onChange: onChangePage,
                    pageSize: size || 1,
                    total: total || 1,
                }}
                dataSource={employees}
                columns={columns}
                rowClassName="editable-row"
            />
        </>
    );
};

export default TableEmployeeAttendance;
