import axios from 'axios';

const path = 'resettokens/';

const getToken = async (data) => {
    return await axios.post(path, data);
};

const deleteToken = async (id) => {
    return await axios.delete(path + id);
};

export const resetTokenAPI = {
    getToken,
    deleteToken,
};
