import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, Col, FormInput, InputGroup, InputGroupAddon, InputGroupText, Row } from 'shards-react';
import { companiesAPI } from '../../apis/companiesAPI';
import { employeesAPI } from '../../apis/employeesAPI';
import { userAPI } from '../../apis/userAPI';
import ExportCSV from '../companies/CompaniesCSV';
import TableCompanies from './TableCompanies';

import { Modal } from 'antd';
import { AiFillPlusCircle } from 'react-icons/ai';
import { MdDeleteForever } from 'react-icons/md';
import { ROLE } from '../../helpers/const';
import { useUser } from '../../hooks';
import { getError } from '../../helpers/getErrorServer';

function ListCompanies() {
    const [data, setData] = useState([]);
    const [search, setSearchValue] = useState();
    const [page, setPage] = useState(1);
    const studentId = window.location.href.split('/')[4];
    const [deleteList, setDeleteList] = useState([]);
    const [open, setOpen] = useState(false);
    const { user } = useUser();

    if (studentId) {
        alert(studentId);
    }

    const fetchData = async () => {
        try {
            if (user && user.role && (user.role === ROLE.ADMIN || user.role === ROLE.SYSTEMSTAFF)) {
                const { data } = await companiesAPI.get({ page, limit: 10 });
                let rs = data;
                if (data.items.length < 1 && page > 1) {
                    setPage((prev) => prev - 1);
                    const { data: tmp } = await companiesAPI.get({ page: page - 1, limit: 10 });
                    rs = tmp;
                }
                setData(rs);
            }
        } catch (error) {
            toast.error(getError(error));
        }
    };
    useEffect(() => {
        fetchData();
    }, [search, page]);

    const showModal = () => {
        setOpen(true);
    };

    const hideModal = () => {
        DeleteRows();
        setOpen(false);
    };

    const warning = () => {
        showModal();
    };

    const DeleteRows = () => {
        deleteList.map(async (item) => {
            try {
                const { data } = await employeesAPI.getByCompany(item);

                let userList = [];
                let employeeList = [];
                data.items.map((i) => {
                    userList = [...userList, i.user._id];
                    employeeList = [...employeeList, i._id];
                });
                await Promise.all(
                    userList.map(async (u) => {
                        await userAPI._delete(u);
                    }),
                );

                await Promise.all(
                    employeeList.map(async (e) => {
                        await employeesAPI._delete(e);
                    }),
                );
                await companiesAPI.deleteCompany(item);

                toast.success('Deleted successfully!');
                fetchData();
            } catch (error) {
                console.log(error);
            }
        });
    };

    const onSearch = async () => {
        if (user) {
            const { data } = await companiesAPI.get({ search });
            setData(data);
        }
    };

    return (
        <Card small className="">
            <div className="title__header">
                <span className="title__h5">List of company</span>
            </div>

            <div className="mt-4 pl-4 pr-4">
                <Row className="p-0" xl="12">
                    <Col xl="4" lg="4" md="4" sm="12" className="mb-2">
                        <InputGroup>
                            <FormInput
                                placeholder="Input Search Text"
                                onChange={(e) => setSearchValue(e.target.value)}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        onSearch();
                                    }
                                }}
                            />
                            <InputGroupAddon type="append" onClick={onSearch}>
                                <InputGroupText className="pointer">
                                    <i className="material-icons">search</i>
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                    <Col xl="8" lg="8" md="8" sm="12">
                        <Row>
                            {user && user.role && user.role === ROLE.ADMIN && (
                                <Col xl="3" lg="4" md="3" sm="4" className="mb-2">
                                    <button className="button_delete" onClick={warning}>
                                        <MdDeleteForever size={20} />
                                        <span className="text__red">Delete</span>
                                    </button>
                                </Col>
                            )}

                            <Col xl="3" lg="4" md="4" sm="4" className="mb-2">
                                <div>
                                    <ExportCSV />
                                </div>
                            </Col>

                            <Col xl="3" lg="4" md="4" sm="4" className="mb-2">
                                <div>
                                    <Link
                                        to="/create-company"
                                        className="text-center rounded p-2 d-flex justify-content-center align-items-center button__warning"
                                    >
                                        <AiFillPlusCircle size={25} />
                                        <span className="ml-2">New Company</span>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className="p-0">
                        <TableCompanies
                            tableData={data}
                            fetchData={fetchData}
                            deleteList={deleteList}
                            setDeleteList={setDeleteList}
                            setPage={setPage}
                            page={page}
                            mobileBreakPoint={768}
                        />
                    </Col>
                </Row>
            </div>
            <Modal
                title={
                    <>
                        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.4167 0.833344H2.58333C1.56583 0.833344 0.75 1.65834 0.75 2.66668V15.5C0.75 16.5083 1.56583 17.3333 2.58333 17.3333H6.25L9 20.0833L11.75 17.3333H15.4167C16.425 17.3333 17.25 16.5083 17.25 15.5V2.66668C17.25 1.65834 16.425 0.833344 15.4167 0.833344ZM9.91667 15.5H8.08333V13.6667H9.91667V15.5ZM11.8142 8.39584L10.9892 9.23918C10.3292 9.90834 9.91667 10.4583 9.91667 11.8333H8.08333V11.375C8.08333 10.3667 8.49583 9.45001 9.15583 8.78084L10.2925 7.62584C10.6317 7.29584 10.8333 6.83751 10.8333 6.33334C10.8333 5.32501 10.0083 4.50001 9 4.50001C7.99167 4.50001 7.16667 5.32501 7.16667 6.33334H5.33333C5.33333 4.30751 6.97417 2.66668 9 2.66668C11.0258 2.66668 12.6667 4.30751 12.6667 6.33334C12.6667 7.14001 12.3367 7.87334 11.8142 8.39584Z"
                                fill="#FCBB0A"
                            />
                        </svg>

                        <p className="title-modal-custom">Are you sure delete this company?</p>
                    </>
                }
                open={open}
                onOk={hideModal}
                onCancel={() => setOpen(false)}
            >
                <p>By delete companies</p>
            </Modal>
        </Card>
    );
}
ListCompanies.propTypes = {
    /**
     * The component's title.
     */
    title: PropTypes.string,
};

export default ListCompanies;
