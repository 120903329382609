/* eslint-disable react/jsx-pascal-case */
import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { AiFillPlusCircle } from 'react-icons/ai';
import { useHistory, useLocation } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'shards-react';
import { jobPositionAPI } from '../../apis/jobPositionAPI';
import { ROLE } from '../../helpers/const';
import { useUser } from '../../hooks';
import PageTitle from './../common/PageTitle';
import Administrator from './TabRolePermisstion/Administrator';
import CEO from './TabRolePermisstion/CEO';

const { TabPane } = Tabs;

const RolePermisstion = () => {
    const history = useHistory();
    const [jobPosition, setJobPosition] = useState([]);

    const { user, getMe } = useUser();
    const location = useLocation();

    useEffect(() => {
        if (!user) {
            getMe();
        }
        user &&
            user.role &&
            user.role !== ROLE.COMPANY &&
            user.role !== ROLE.COMPANYSTAFF &&
            user.role !== ROLE.USER &&
            history.push('/login');
    }, [user]);

    useEffect(() => {
        (async () => {
            if (!user) {
                getMe();
            }
            if (user && user.role === ROLE.COMPANY && user.company && user.company._id) {
                const { data } = await jobPositionAPI.getActive(user.company._id);
                setJobPosition(data);
            }

            if (
                user &&
                (user.role === ROLE.COMPANYSTAFF || user.role === ROLE.USER) &&
                user.employee &&
                user.employee.company &&
                user.employee.company._id
            ) {
                const { data } = await jobPositionAPI.getActive(user.employee.company._id);
                setJobPosition(data);
            }
        })();
    }, [user, location]);

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <Col>
                    <PageTitle
                        title="Role & permissions"
                        subtitle="Company settings/ Role & permissions"
                        className="text-sm-left mb-3"
                    />
                </Col>

                {user && user.role && user.role === ROLE.COMPANY && (
                    <Col xl="2" lg="3" md="6" sm="12">
                        <div
                            className="text-center rounded p-2 d-flex justify-content-center align-items-center button__warning"
                            onClick={() => {
                                history.push('/employers/add-job-position');
                            }}
                        >
                            <AiFillPlusCircle size={25} />
                            <span className="ml-2"> Add Role</span>
                        </div>
                    </Col>
                )}
            </Row>
            <Row className="mt-2 w-100" lg="12" xl="12" md="12" sm="12">
                <Card small className="h-100 w-100">
                    <CardHeader className="border-bottom">
                        <span className="ct-title">Role & permissions</span>
                    </CardHeader>

                    <CardBody className="col-xl-12 col-lg-12 ">
                        <Row>
                            <Col>
                                <Tabs tabPosition="left">
                                    <TabPane tab={<span className="title-tab">Administrator</span>} key="0">
                                        <div className="padding_100">
                                            <Administrator />
                                        </div>
                                    </TabPane>
                                    {jobPosition &&
                                        jobPosition.map((item, index) => (
                                            <TabPane
                                                tab={<span className="title-tab">{item.jobPositionName}</span>}
                                                key={index + 1}
                                            >
                                                <div className="padding_100">
                                                    <CEO item={item} />
                                                </div>
                                            </TabPane>
                                        ))}
                                </Tabs>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Row>
        </Container>
    );
};

export default RolePermisstion;
